import React from "react";
import ErrorImageBg from "../../images/error_page_bg.png";

export const ErrorPage = () => {
  return (
    <div className="flex w-screen h-screen justify-center items-center">
      <img className="absolute h-[80%]" src={ErrorImageBg} />
      <div className="text-center z-10 mr-8">
        <p className="text-[120px] text-white p-[0px] m-[0px] font-bold inline-block ">
          404
        </p>
        <p className="text-white text-lg p-[0px] m-[0px]">
          Something Went Wrong.
        </p>
        <div>
          <div className="flex justify-center mb-12">
            <p className="text-white text-sm mt-2 w-[70%]">
              We are sorry, the page you requested cannot be found.
            </p>
          </div>
          <a
            href="/"
            className="bg-[#40AE49] w-[156px] py-2 px-8 text-sm text-white rounded-md"
          >
            Go Back
          </a>
        </div>
      </div>
    </div>
  );
};
