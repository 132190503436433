import React, { useEffect, useState } from "react";
import { _asyncGetMerchandiserUnassigned, _asyncMerchandiserAssignUnassign } from "../api";
import closeImg from "../images/FarmPipeLine/close.png";
import Multiselect from "multiselect-react-dropdown";
import crossImg from "../images/FarmPipeLine/cross.png";

export const AssignMerchandiserModal = ({
  hideModal,
  modalData
}) => {
    
  const [unassignMerchandisers, setUnassignMerchandisers] = useState([]);

  const [assignMerchandisers, setassignMerchandisers] = useState(modalData?.merchandisers || []);
  const [unassignList, setUnassignList] = useState([]);
  const [assignList, setAssignList] = useState([]);

  useEffect(() => {
    _getGetAllMerchandiserUnassigned();
  }, []);

  const handleOnChange = (event) => {
    let userList = [];
    event.map((item) => {
      userList.push(item);
    });
    setAssignList(userList);
  };

  const handleOnUnassigned = (id) => {
    let newUnassignList = [...unassignList];
    newUnassignList.push(id);
    const updatedAssignMerchandisers = assignMerchandisers.filter(item => item.id !== id);
    setassignMerchandisers(updatedAssignMerchandisers)
    setUnassignList(newUnassignList)
  };


  console.log("assignListassignList", unassignList)

  async function _getGetAllMerchandiserUnassigned() {
    const data = await _asyncGetMerchandiserUnassigned(modalData?.id);
    if (!data) return;
    setUnassignMerchandisers(data);
  }

  const onAssign = async () =>{
    let body = {}
    if(unassignList.length > 0){
      body.to_unassign = unassignList;
    }
    if(assignList.length > 0){
      body.to_assign = assignList.map(({ id }) => id);
    }
    let response = await _asyncMerchandiserAssignUnassign(modalData?.id, body);
    if(response.status === 200){
      hideModal();
    }
  }

  return (
    <>
      <div className="flex overflow-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center">
        <div className="w-[45%] relative mt-[3%] mb-[3%]">
          {/*content*/}
          <div className="h-full first-letter:rounded-lg shadow-lg relative flex flex-col w-full outline-none focus:outline-none rounded-lg">
            <div className="flex items-center relative pl-6 pr-6 overflow-auto pt-3 bg-white rounded-t-lg h-full">
              <p className="text-[18px] w-full font-bold mb-5 mt-3">
                Assign Merchandiser
              </p>
              <img
                className="float-right w-[30px] h-[30px]"
                src={closeImg}
                alt="closeImg"
                onClick={() => {
                  hideModal();
                }}
              />
            </div>
            {/*footer*/}
            <div className="py-3 rounded-b-lg bg-white w-full px-6"> 
             <div className="py-2"> 
             <p className="font-semibold">Supermarket</p>
              <p className="text-[#808080] py-1 text-[12px]">{modalData?.name}</p>
              </div> 
              <div className="py-2">
              <p className="font-semibold">Assigned Merchandiser</p>
                <div className="flex text-[#808080] text-[12px] py-2">
                {assignMerchandisers.length > 0 ? 
                  assignMerchandisers?.map(merchandiser => {
                    return(
                    <div className="flex flex-row justify-center items-center border border-[#252525] px-3 py-1 rounded-sm ml-2">
                    <img
                      className="w-[15px] h-[15px] mr-2"
                      src={crossImg}
                      onClick={() => handleOnUnassigned(merchandiser?.id)}
                      alt="crossImg"
                    />
                    <p className="text-[14px] font-normal">{merchandiser?.user_name}</p>
                  </div>
                    )
                  })
                : <p className="text-[#808080] text-[12px] py-1">Not yet assigned merchandiser, please choose merchandiser bellow</p>
                } 
                </div>
              </div>
              <hr class="h-px my-4 bg-gray-400 border-0 "></hr>
              <p className="font-semibold">Assign Merchandiser</p>
              <div className="mt-3 relative">
                <Multiselect
                  displayValue="user_name"
                  chipColor="#40AE49"
                  selectedValues={assignList || []}
                  options={unassignMerchandisers || []}
                  onSelect={handleOnChange}
                  onRemove={handleOnChange}
                  showCheckbox
                  style={{ chips: { background: "#40AE49" } }}
                />
              </div>
              <div className="flex justify-end mt-10">
                <p
                  onClick={() => onAssign()}
                  className="bg-[#3EB049] text-white px-10 py-2 border border-[#3EB049] rounded-md cursor-pointer"
                >
                  Save
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>{" "}
    </>
  );
};
