import React, { useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";
import DropDownComp from "../components/DropDownComp";
import {
  axiosClient,
  _asyncGetAllProvince,
  _asyncGetDistrictByAbv,
  _apiFetchProduct,
  _asyncGetAllProducts,
  _apiGetVendors,
  _asyncGetFarmDocsData,
  _updateDocs,
  _asyncGetFaasPics,
} from "../api";
import closeImg from "../images/FarmPipeLine/close.png";
import { GoogleMapComp } from "../config/GoogleMaps";
import { EditFarmtabsList } from "../config/localdata";
import { InputTextComp } from "../components/InputTextComp";
import { AppUsersTab } from "./AppUsersTab";
import SearchDropDownComp from "../components/SearchDropDownComp";
import { DocumentsTab } from "./DocumentsTab";
import { InputFieldComp } from "../components/InputFieldComp";
import DeleteButton from "../images/Delete.png";
import Blank from "../images/blank.png";
import AddButton from "../images/AddButton.png";
import Multiselect from "multiselect-react-dropdown";

export const EditFarmModal = ({
  hideModal,
  metaData,
  selectedFarmData,
  visible,
  token,
}) => {
  const tabsList = EditFarmtabsList;
  const configFetchRef = useRef(false);
  const [provinceList, setProvinceList] = useState([]);
  const [selectedProvince, setSelectedProvince] = useState(selectedFarmData?.farm_loc_province);
  const [selectedDistrict, setSelectedDistrict] = useState(selectedFarmData?.farm_loc_district);
  const [districtList, setDistrictList] = useState([]);

  const [faasPicList, setFaasPicList] = useState([]);
  const [selectedFaasPic, setSelectedFaasPic] = useState();

  const [farmName, setFarmName] = useState({
    value: selectedFarmData.farm_name,
    error: false,
  });
  const [selectedTab, updateSelectedTab] = useState(EditFarmtabsList[0]);
  const [selectedFarmOwnerShip, setSelectedFarmOwnerShip] = useState(
    selectedFarmData.is_mitra_farm ? selectedFarmData.ownership : ""
  );
  const [farmAddress, setFarmAddress] = useState({
    value: selectedFarmData.farm_loc_address,
    error: false,
  });
  const [companyName, setCompanyName] = useState({
    value: selectedFarmData.company_name,
    error: false,
  });
  const [farmContactName, setFarmContactName] = useState({
    value: selectedFarmData.contact_name,
    error: false,
  });
  const [farmContactNumber, setFarmContactNumber] = useState({
    value: selectedFarmData.contact_number,
    error: false,
  });

  const [Hydroponics, setHydroponics] = useState([{}]);
  const [Soilless, setSoilless] = useState([{}]);
  const [OpenField, setOpenField] = useState([{}]);

  const [HydroponicsDeleted, setHydroponicsDeleted] = useState([]);
  const [SoillessDeleted, setSoillessDeleted] = useState([]);
  const [OpenFieldDeleted, setOpenFieldDeleted] = useState([]);

  const handleAddOpenField = () => {
    const item = {};
    setOpenField([...OpenField, item]);
  };

  const updateOpenField = (e, idx, column) => {
    let fieldValue = e; 
    let fieldName = column;
    // if(fieldName === "farmed_area" || fieldName === "farmed_area" || fieldName === "irrigated_area"){
    //   fieldValue=parseFloat(fieldValue)||0
    // }
    const tempRows = [...OpenField];
    const tempObj = OpenField[idx]; 
    tempObj[fieldName] = fieldValue;
    tempRows[idx] = tempObj;
    setOpenField(tempRows); 
  };


  const handleAddHydroponics = () => {
    const item = {};
    setHydroponics([...Hydroponics, item]);
  };

  const updateHydroponics = (e, idx, column) => {
    let fieldValue = e; 
    let fieldName = column;
    // if(fieldName === "floor_area" || fieldName === "greenhouse_holes"){
    //   fieldValue=parseFloat(fieldValue)||0
    // }
    const tempRows = [...Hydroponics];
    const tempObj = Hydroponics[idx]; 
    tempObj[fieldName] = fieldValue;
    tempRows[idx] = tempObj;
    setHydroponics(tempRows); 
    
  };

  const handleAddSoilless = () => {
    const item = {};
    setSoilless([...Soilless, item]);
  };

  async function _fetchFaasPic() {
    let data = {
      farm_id:selectedFarmData?.id
    }
    let list = await _asyncGetFaasPics(data);
    if (list.length > 0) {
      let assigned = [];
      let unassigned = [];
      list.map((item)=>item.is_assigned ? assigned.push(item):unassigned.push(item))
      setFaasPicList([...unassigned, ...assigned]);
      setSelectedFaasPic(assigned);
    }
  }

  const handleRemoveSoilless = (idx) => {
    const tempRows = [...Soilless];
    const tempDeletedArray = [...SoillessDeleted]
    if(tempRows.length === 1){
      return;
    }
   
    let filteredArray = tempRows.filter((element, index) => index === idx);
    let newArray = filteredArray.map(({ id }) => ({ id }));
    tempRows.splice(idx, 1);
    if(newArray[0].id){
      setSoillessDeleted([...newArray, ...tempDeletedArray]);
    }
    setSoilless(tempRows);
  };

  const handleRemoveHydroponics = (idx) => {
    const tempRows = [...Hydroponics];
    const tempDeletedArray = [...HydroponicsDeleted]
    if(tempRows.length === 1){
      return;
    }
    let filteredArray = tempRows.filter((element, index) => index === idx);
    let newArray = filteredArray.map(({ id }) => ({ id }));
    tempRows.splice(idx, 1);
    if(newArray[0].id){
      setHydroponicsDeleted([...newArray, ...tempDeletedArray]);
    }
    setHydroponics(tempRows);
  };

  const handleRemoveOpenField = (idx) => {
    const tempRows = [...OpenField];
    const tempDeletedArray = [...OpenFieldDeleted]
    if(tempRows.length === 1){
      return;
    }
    let filteredArray = tempRows.filter((element, index) => index === idx);
    let newArray = filteredArray.map(({ id }) => ({ id }));
    tempRows.splice(idx, 1);
    if(newArray[0].id){
      setOpenFieldDeleted([...newArray, ...tempDeletedArray]);
    }
    setOpenField(tempRows);
  };



  const updateSoilless = (e, idx, column) => {
    let fieldValue = e; 
    let fieldName = column;
    // if(fieldName === "floor_area" || fieldName === "greenhouse_holes"){
    //   fieldValue=parseFloat(fieldValue)||0
    // }
    const tempRows = [...Soilless];
    const tempObj = Soilless[idx]; 
    tempObj[fieldName] = fieldValue;
    tempRows[idx] = tempObj;
    setSoilless(tempRows); 
    
  };

  function isEmpty(obj) {
    for(var prop in obj) {
        if(obj.hasOwnProperty(prop))
            return false;
    }
    return true;
}
// documents tab code start

  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const [farmLatLong, setFarmLatLong] = useState({
    lat: Number(selectedFarmData?.farm_loc_lat),
    lng: Number(selectedFarmData?.farm_loc_long),
  });

  const [docsData, setDocsData] = useState({
    agreement_letter_pks_validity:'',
    agreement_letter_pks:'',
    land_certificate_validity:'',
    land_certificate:'',
    is_owner_married:false,
    business_certificate:'',
    family_doc_kk:'',
    ktp_id_card:'',
    spouse_id_card:'',
  })

const keysArray = ['spouse_id_card','agreement_letter_pks_validity', 'land_certificate_validity', 'agreement_letter_pks', 'land_certificate','is_owner_married','business_certificate','family_doc_kk','ktp_id_card'];


  const handleMapClick = (e) =>{
    setFarmLatLong({
      lat:e.latLng.lat(),
      lng:e.latLng.lng()
    })
  }

   const onChangeValue = (event)=> {
    setDocsData({...docsData,
      is_owner_married: event.target.defaultValue === "true" ? true:false
      })
    }

    const handleFileChange = (event) => {
      setDocsData({ ...docsData, [event.target.name]: event.target.files[0] });
    };

    const dateAgreementLetterValidityChange = (event) =>{
      setDocsData({ ...docsData, agreement_letter_pks_validity: event });
    }
    const dateLandCertificateValidityChange = (event) =>{
      setDocsData({ ...docsData, land_certificate_validity: event });
    }

    const onSubmitClick = () => {
      // will place all the validations here 
  
      // if (docsData?.ktp_id_card === "") {
      //   toast.error("Please upload ID Card (KTP) document", {autoClose: 2000});
      //   return;
      // }
      // if (docsData.is_owner_married && docsData?.spouse_id_card === "") {
      //   toast.error("Please upload Spouse ID Card (KTP) document", {autoClose: 2000});
      //   return;
      // }
      // if (docsData?.family_doc_kk === "") {
      //   toast.error("Please upload Family Document (KK) document", {autoClose: 2000});
      //   return;
      // }
  
      // if (docsData?.business_certificate === "") {
      //   toast.error("Please upload Business Certificate (Surat Keterangan Usaha) document", {autoClose: 2000});
      //   return;
      // }
  
      // if (docsData?.agreement_letter_pks === "") {
      //   toast.error("Please upload Agreement Letter (PKS) document", {autoClose: 2000});
      //   return;
      // }
  
      // if (docsData?.agreement_letter_pks_validity === "") {
      //   toast.error("Please select Agreement Letter (PKS) validity date ", {autoClose: 2000});
      //   return;
      // }
  
      // if (docsData?.land_certificate === "") {
      //   toast.error("Please upload Land Certificate (Surat Kepemilikan Lahan / Surat Sewa Lahan) document", {autoClose: 2000});
      //   return;
      // }
  
      // if (docsData?.land_certificate_validity === "") {
      //   toast.error("Please select Land Certificate validity date ", {autoClose: 2000});
      //   return;
      // }
      setShowConfirmModal(true);
    };
  
   const _addDocsData = async () =>{
      let formData = new FormData();
      keysArray.forEach(key =>{
        if(docsData[key]){
          formData.append(key, docsData[key]);
        }
      })
      // formData.append('spouse_id_card', docsData?.spouse_id_card);
      // if(docsData?.agreement_letter_pks_validity){
      //   formData.append('agreement_letter_pks_validity', docsData?.agreement_letter_pks_validity);
      // }
      // if(docsData?.land_certificate_validity){
      //   formData.append('land_certificate_validity', docsData?.land_certificate_validity);
      // }

      // formData.append('agreement_letter_pks', docsData?.agreement_letter_pks);
      // formData.append('land_certificate', docsData?.land_certificate);
      // formData.append('is_owner_married', docsData?.is_owner_married);
      // formData.append('business_certificate', docsData?.business_certificate);
      // formData.append('family_doc_kk', docsData?.family_doc_kk);
      // formData.append('ktp_id_card', docsData?.ktp_id_card);
    let res = await _updateDocs(selectedFarmData?.id, formData);
    if (res.status === 200 && res.statusText === "OK") {
      setShowSuccessModal(true);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
   }

  // documents tab code end 

  const [vendorList, setVendorList] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState();
  const [docs, setDocs] = useState([]);

  useEffect(() => {
    if (configFetchRef.current) return;
    configFetchRef.current = true;
    _setUpPage();
    _fetchVendors();
  });

  async function _fetchDocs() {
    let data = await _asyncGetFarmDocsData(selectedFarmData?.id);
    setDocs(data)
  }

  useEffect(()=>{
    _fetchDocs();
    _fetchFaasPic();
},[selectedFarmData?.id])


  async function _fetchVendors() {
    let list = await _apiGetVendors();
    if (list.length > 0) {
      setVendorList([{id: selectedFarmData?.odoo_vendor_id,partner_name: selectedFarmData?.odoo_vendor_name} ,...list]);
    }
  }

  async function _setUpPage() {
    updateSelectedTab(EditFarmtabsList[0]);

    if (selectedFarmData.facility["1"]) {
      let greenHouseArray = [];
      selectedFarmData?.facility["1"]?.facility_info.forEach((element) => {
        greenHouseArray.push({
          id: element.id,
          greenhouse_name: element.greenhouse_name,
          floor_area: element.floor_area,
          greenhouse_holes: element.greenhouse_holes,
          dosing_type_id: element.dosing,
          dosing_type_name: element.dosing.type_name,
        });
      });
      setHydroponics(greenHouseArray);
    }

    if (selectedFarmData.facility["3"]) {
      let openFieldArray = [];
      selectedFarmData?.facility["3"]?.facility_info.forEach((element) => {
        openFieldArray.push({
          id: element.id,
          farmable_area: element.farmable_area,
          farmed_area: element.farmed_area,
          irrigation_type_id: element.irrigation,
          irrigation_type_name: element.irrigation.type_name,
          irrigated_area: element.irrigated_area,
        });
      });
      setOpenField(openFieldArray);
    }

    if (selectedFarmData.facility["2"]) {
      let soillesArray = [];
      selectedFarmData?.facility["2"]?.facility_info.forEach((element) => {
        soillesArray.push({
          id: element.id,
          greenhouse_name: element.greenhouse_name,
          floor_area: element.floor_area,
          greenhouse_holes: element.greenhouse_holes,
          dosing_type_id: element.dosing,
          dosing_type_name: element.dosing.type_name,
        });
      });
      setSoilless(soillesArray);
    }
    // if (selectedFarmData.products) {
    //   let historicalYieldArray = [];
    //   selectedFarmData?.products?.historic_yield?.forEach((element) => {
    //     historicalYieldArray.push({
    //       id: element.id,
    //       product_id: element.product.id,
    //       product_name: element.product.product_name,
    //       farm_type_id: element.farm_type.id,
    //       farm_type_name: element.farm_type.farm_type_name,
    //       quantity_produced: element.quantity_produced,
    //       uom_id: element.uom.id,
    //       uom_name: element.uom.uom,
    //       harvest_frequency_id: element.harvest_frequency.id,
    //       harvest_frequency_name: element.harvest_frequency.frequency,
    //       farmed_area: element.farmed_area,
    //       area_unit_id: element.farm_area_unit.id,
    //       area_unit_name: element.farm_area_unit.unit_name,
    //     });
    //   });
    //   setTHistoricalYield(historicalYieldArray);

    //   let contractedProductArray = [];
    //   selectedFarmData?.products?.contracted_products?.forEach((element) => {
    //     contractedProductArray.push({
    //       id: element.id,
    //       product_id: element.product.id,
    //       product_name: element.product.product_name,
    //       farm_type_id: element.farm_type.id,
    //       farm_type_name: element.farm_type.farm_type_name,
    //       agreed_price: element.agreed_price,
    //       agreed_min_yield: element.agreed_min_yield,
    //       uom_id: element.uom.id,
    //       uom_name: element.uom.uom,
    //     });
    //   });
    //   setTContractedProducts(contractedProductArray);
    // }

    await _getProvince(true);
  }

  function onNextClick() {
    if (selectedTab === tabsList[0]) {
      // if (productsList.length > 0) {
        updateSelectedTab(tabsList[1]);
      // } else {
      //   toast.error("No Products found. Please create any product first");
      // }
    } else if (selectedTab === tabsList[1]) {
      updateSelectedTab(tabsList[2]);
    } else if (selectedTab === tabsList[2]) {
      updateSelectedTab(tabsList[3]);
    }
  }

  function handleBackClick() {
    if (selectedTab === tabsList[2]) {
      updateSelectedTab(tabsList[1]);
    } else if (selectedTab === tabsList[3]) {
      updateSelectedTab(tabsList[2]);
    } else if (selectedTab === tabsList[1]) {
      updateSelectedTab(tabsList[0]);
    }
  }

  const handleFaasOnChange = (event) =>{
    let userList = [];
      event.map((item) => {
        userList.push(item);
      });
      setSelectedFaasPic(userList);
  }


  async function _getProvince(onLoad = false) {
    const data = await _asyncGetAllProvince();
    if (!data) return;
    setProvinceList(data);

    if (selectedFarmData.farm_loc_province) {
      let selectedProvObj = null;
      data.forEach((element) => {
        if (element.province === selectedFarmData.farm_loc_province) {
          selectedProvObj = element;
        }
      });
      if (selectedProvObj == null) {
        setSelectedProvince(data[0]);
        _getDistrictByAbv(data[0].abv, onLoad);
      } else {
        setSelectedProvince(selectedProvObj);
        _getDistrictByAbv(selectedProvObj.abv);
      }
    } else {
      setSelectedProvince(data[0]);
      _getDistrictByAbv(data[0].abv, onLoad);
    }
  }

  async function _getDistrictByAbv(abv, onLoad = false) {
    const data = await _asyncGetDistrictByAbv(abv);
    if (!data) return;
    setDistrictList(data);

    if (selectedFarmData.farm_loc_district) {
      let selectedObj = null;
      data.forEach((element) => {
        if (element.district === selectedFarmData.farm_loc_district) {
          selectedObj = element;
        }
      });
      setSelectedDistrict(selectedObj);
    } else {
      setSelectedDistrict(data[0]);
    }
  }

  async function _saveEditChangesApi(type) {
    let apiData = {};
    // let TGH = apiGreenHouse;
    // debugger;
    // if (type === "Hydroponics") {
    //   apiData = {
    //     facility: {
    //       1: TGH,
    //     },
    //   };
    // } else if (type === "Open Field") {
    //   let TOF = apiOpenField;
    //   apiData = {
    //     facility: {
    //       3: TOF,
    //     },
    //   };
    // } else if (type === "Soilless") {
    //   let TSL = apiSoilles;
    //   apiData = {
    //     facility: {
    //       2: TSL,
    //     },
    //   };
    // } else 
    
    if (type === "farmData") {
      if (selectedFarmOwnerShip.ownership_type === "Company") {
        if (companyName.value === "") {
          toast.error("please provide company name");
          return;
        }
      }

      if (farmName.value === "") {
        setFarmName({ ...farmName, error: true });
        return;
      }

      if (farmContactName.value === "") {
        setFarmContactName({ ...farmContactName, error: true });
        return;
      }

      if (farmContactNumber.value === "") {
        setFarmContactNumber({ ...farmContactNumber, error: true });
        return;
      }

      if (farmAddress.value === "") {
        setFarmContactNumber({ ...farmAddress, error: true });
        return;
      }

      let facility = {};
      Hydroponics.forEach((item)=>{
        if(!isEmpty(item)){
          item.dosing_type_id = item?.dosing_type_id?.id
        }
      })
      Soilless.forEach((item)=>{
        if(!isEmpty(item)){
        item.dosing_type_id = item?.dosing_type_id?.id
        }
      })
      OpenField.forEach((item)=>{
        if(!isEmpty(item)){
        item.irrigation_type_id = item?.irrigation_type_id?.id
        }
      })
  
      let HydroponicsArray = Hydroponics.filter(value => Object.keys(value).length !== 0);
      let SoillessArray = Soilless.filter(value => Object.keys(value).length !== 0);
      let OpenFieldArray = OpenField.filter(value => Object.keys(value).length !== 0);
  
      if(HydroponicsArray.length > 0){
        facility["1"] = [...HydroponicsArray, ...HydroponicsDeleted]
      }
      if(SoillessArray.length > 0){
        facility["2"] = [...SoillessArray, ...SoillessDeleted] 
      }
      if(OpenFieldArray.length > 0){
        facility["3"] = [...OpenFieldArray, ...OpenFieldDeleted] 
      }

      let faasPic = [] 
      if(selectedFaasPic.length > 0){
        faasPic = selectedFaasPic.map(({ id }) => id)
      }

      apiData = {
        company_name: companyName.value,
        contact_name: farmContactName.value,
        contact_number: farmContactNumber.value,
        farm_name: farmName.value,
        address: farmAddress.value,
        lat: farmLatLong.lat,
        long: farmLatLong.lng,
        province: selectedProvince.province,
        district: selectedDistrict.district,
        ownership_type: selectedFarmOwnerShip.id,
        facility:facility,
        pic_ids:faasPic
      };

      console.log("+++++++++++++++++IIIIII",apiData);

      if (selectedVendor !== undefined) {
        apiData.vendor_name = selectedVendor?.partner_name;
        apiData.vendor_id = selectedVendor?.id;
      }
    } 
    
    // else if (type === "Feature Governance") {
    //   apiData = {
    //     farm_function: farmFunction.id,
    //     batch_tracking_method: FTMethod.id,
    //   };
    // } else if (type === "Historic Yields") {
    //   apiData = {
    //     products: {
    //       historic_yield: apiHistoricalYield,
    //     },
    //   };
    // } else if (type === "Contracted Products") {
    //   let TCP = apiContractedProduct;
    //   apiData = {
    //     products: {
    //       contracted_products: TCP,
    //     },
    //   };
    // }

    axiosClient
      .put(`admin/farm/${selectedFarmData.id}`, apiData, {
        headers: {
          mitra: true,
          token,
        },
      })
      .then((res) => {
        console.log(res);
        if (res.status === 200 && res.statusText === "OK") {
          toast.success("Farm Updated Successfully", {
            autoClose: 2000,
          });
          setTimeout(() => {}, 3000);
        }
      })
      .catch((error) => {
        toast.error(error.response, {
          autoClose: 2000,
        });
        console.log("ERROR", error.response);
      });
  }

  const TabsUI = () => {
    const Tabs = () => {
      return tabsList.map((item, i) => {
        let styling = "";

        if (item === selectedTab) {
          styling = "bg-white cursor-pointer rounded-t-2xl px-4 text-[#3EB049]";
        } else {
          styling = "bg-[#F4F4F4] cursor-pointer rounded-t-2xl px-4";
        }

        return (
          <div key={`tbs-${i}`} className={`${styling}`} style={{}}>
            <li>
              <p className={"p-4 text-sm"}>{item}</p>
            </li>
          </div>
        );
      });
    };

    return (
      <ul className="flex">
        <Tabs />
      </ul>
    );
  };

  return (
    <>
      {visible ? (
        <>
          <div className="flex overflow-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-full mt-[3%] ml-[10%] mr-[10%] mb-[3%]">
              {/*content*/}
              <div className="h-full border-0 rounded-lg shadow-lg relative flex flex-col w-full outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between border-slate-200 rounded-t">
                  <TabsUI />
                </div>
                {/*body*/}
                <div className="relative pl-6 pr-6 overflow-auto pt-3 bg-white rounded-tr-lg h-full">
                  <img
                    className="float-right w-[30px] h-[30px]"
                    src={closeImg}
                    alt="closeImg"
                    onClick={() => {
                      hideModal();
                    }}
                  />
                  {selectedTab === tabsList[0] ? (
                    <>
                      <div className="flex justify-between mt-14">
                        <p className="font-bold underline underline-offset-8 decoration-green decoration-[3px]">
                          Basic Data
                        </p>
                        {/* <div>
                          <p
                            onClick={() => _saveEditChangesApi("farmData")}
                            className="bg-green text-sm text-white px-2 rounded-full cursor-pointer"
                          >
                            save
                          </p>
                        </div> */}
                      </div>
                      <div className="mt-5 grid grid-cols-2 gap-2">
                        {selectedFarmData.is_mitra_farm ? (
                          <div>
                            <DropDownComp
                              label={"Ownership"}
                              listData={metaData.farmOwnershipTypes}
                              active={selectedFarmOwnerShip}
                              type={"ownership_type"}
                              onSelected={(item) =>
                                setSelectedFarmOwnerShip(item)
                              }
                              customStyles={
                                "rounded-md justify-between text-[#3E4347] text-[14px]"
                              }
                              customMenuStyle={
                                "relative inline-block text-left w-full"
                              }
                            />
                          </div>
                        ) : null}
                        <div>
                          {selectedFarmOwnerShip.ownership_type !==
                          "Private" ? (
                            <InputTextComp
                              label={"Company Name"}
                              compulsory={true}
                              placeholder={"Company Name"}
                              value={companyName.value}
                              error={companyName.error}
                              onChange={(text) =>
                                setCompanyName({
                                  ...companyName,
                                  value: text,
                                  error: false,
                                })
                              }
                            />
                          ) : null}
                        </div>
                      </div>

                      <div className="mt-3">
                        <InputTextComp
                          compulsory={true}
                          label={"Farm Name"}
                          placeholder={"Farm Name"}
                          value={farmName.value}
                          error={farmName.error}
                          onChange={(text) =>
                            setFarmName({
                              ...farmName,
                              value: text,
                              error: false,
                            })
                          }
                        />
                      </div>
                      <div className="mt-5 grid grid-cols-2 gap-2">
                        <div>
                          <InputTextComp
                            label={"Farm Contact"}
                            placeholder={"Carnegie Trihandono"}
                            value={farmContactName.value}
                            compulsory={true}
                            error={farmContactName.error}
                            onChange={(text) =>
                              setFarmContactName({
                                ...farmContactName,
                                value: text,
                                error: false,
                              })
                            }
                          />
                        </div>
                        <div>
                          <InputTextComp
                            label={"Contact Number"}
                            value={farmContactNumber.value}
                            compulsory={true}
                            placeholder={"+62 81239045004"}
                            error={farmContactNumber.error}
                            onChange={(text) =>
                              setFarmContactNumber({
                                ...farmContactNumber,
                                value: text,
                                error: false,
                              })
                            }
                          />
                        </div>
                        <div className="mt-5">
                          <p className="pl-1 pb-1 text-[#8A9099] text-sm">
                            Link Farm Data To Odoo
                          </p>
                          <SearchDropDownComp
                            list={vendorList}
                            active={vendorList[0] || []}
                            type="partner_name"
                            onSelected={(item) => {
                              setSelectedVendor(item);
                            }}
                          />
                        </div>
                        <div className="mt-7">
                        <p className="pl-1 pb-1 text-[#8A9099] text-sm">
                          FaaS PIC
                        </p>
                        <Multiselect
                          displayValue="user_name"
                          chipColor="#40AE49"
                          selectedValues={selectedFaasPic || []}
                          options={faasPicList || []}
                          onSelect={handleFaasOnChange}
                          onRemove={handleFaasOnChange}
                          showCheckbox
                          style={{chips:{background: "#40AE49" }}}
                        />
                        </div>


                      </div>
                      <div className="mt-5">
                        <p className="font-bold underline underline-offset-8 decoration-green decoration-[3px]">
                          Location & Facilities
                        </p>
                      </div>
                      <div className="mt-5 grid grid-cols-2 gap-2">
                        <div>
                          <div className="flex">
                            <p className="text-[#8A9099] text-sm">Province</p>
                            <p className="text-red-500">*</p>
                          </div>
                          {provinceList.length > 0 && selectedProvince ? (
                            <SearchDropDownComp
                              list={provinceList}
                              active={selectedProvince}
                              type={"province"}
                              onSelected={(item) => {
                                setSelectedProvince(item);
                                _getDistrictByAbv(item?.abv);
                              }}
                              customStyles={
                                "rounded-md justify-between text-[#3E4347] text-[14px]"
                              }
                              customMenuStyle={
                                "relative inline-block text-left w-full"
                              }
                            />
                          ) : null}
                        </div>
                        <div>
                          <div className="flex">
                            <p className="text-[#8A9099] text-sm">District</p>
                            <p className="text-red-500">*</p>
                          </div>
                          {districtList.length > 0 ? (
                            <SearchDropDownComp
                              list={districtList}
                              active={selectedDistrict}
                              onSelected={(item) => {
                                setSelectedDistrict(item);
                              }}
                              type={"district"}
                              customStyles={
                                "rounded-md justify-between text-[#3E4347] text-[14px]"
                              }
                              customMenuStyle={
                                "relative inline-block text-left w-full"
                              }
                            />
                          ) : null}
                        </div>
                      </div>
                      <div className="mt-5 grid grid-cols-2 gap-2">
                        <div>
                          <div className="flex">
                            <p className="text-[#8A9099] text-sm">
                              Farm Address
                            </p>
                            <p className="text-red-500">*</p>
                          </div>
                          <div>
                            <InputTextComp
                              placeholder={"Farm Address"}
                              value={farmAddress.value}
                              error={farmAddress.error}
                              onChange={(text) =>
                                setFarmAddress({
                                  ...farmAddress,
                                  value: text,
                                  error: false,
                                })
                              }
                            />
                          </div>
                        </div>
                        <div>
                          <div className="flex">
                            <p className="text-[#8A9099] text-sm">
                              Latitude/ Longitude
                            </p>
                            <p className="text-red-500">*</p>
                          </div>
                          <div>
                            <InputFieldComp
                              value={farmLatLong.lat +', '+ farmLatLong.lng}
                              placeholder={"6.5414° S, 106.7456° E"}
                              //onChange={(text) => console.log(text)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="mt-5">
                        <GoogleMapComp
                           location={farmLatLong}
                           handleMapClick={handleMapClick}
                        />
                      </div>
                      <div>
                      <p className="text-lg mb-2 ml-2 mt-3 text-black"><b>Hydroponics</b></p>
                    {Hydroponics.map((item, idx) => (
              <div className={`flex space-x-4`} key={idx}>
              <div className="mt-3 grid grid-cols-4 gap-4" key={idx}>
                  <div>
                    <InputFieldComp
                      label={idx === 0 && "Greenhouse Name"}
                      type="text"
                      value={Hydroponics[idx]["greenhouse_name"]}
                      compulsory={false}
                      placeholder={"Greenhouse Name"}
                      onChange={(e) => updateHydroponics(e, idx, "greenhouse_name")}
                    />
                  </div>
                  <div>
                  <InputFieldComp
                    label={idx === 0 && `Floor Area (Meter)`}
                    type="text"
                    value={Hydroponics[idx]["floor_area"]}
                    maxLength={8}
                    compulsory={false}
                    placeholder={"Floor Area"}
                    onChange={(e) => updateHydroponics(e, idx, "floor_area")}                      
                    />
                  </div>
                  <div>
                  <InputFieldComp
                    label={idx === 0 && `Number of Holes`}
                    type="text"
                    value={Hydroponics[idx]["greenhouse_holes"]}
                    maxLength={8}
                    compulsory={false}
                    placeholder={"Floor Area"}
                    onChange={(e) => updateHydroponics(e, idx, "greenhouse_holes")}                      
                    />
                  </div>
                  <div className="">
                  {idx === 0 && <p className="text-sm text-black">Dosing</p>}
                  <div className="relative">
                  <SearchDropDownComp
                    list={ metaData?.farmDosingTypes || []}
                    label={"Select"}
                    active={Hydroponics[idx]["dosing_type_id"] || "select"}
                    width="w-[180px]"
                    type={"type_name"}
                    onSelected={(item) => updateHydroponics(item, idx, "dosing_type_id")}
                    />
                </div>
                  </div>
                </div>
                <div>
               {idx !== 0 && 
                <img className={idx === 0 ? `mt-12`:`mt-6` }
                  alt="Delete button"
                  src={DeleteButton}
                  onClick={()=>handleRemoveHydroponics(idx)}/>}

              {idx === 0 && 
                <img className="mt-12 h-5 w-8"
                  alt=""
                  src={Blank}
                  />}     

                </div>
              </div>
            ))}
                <div className="flex mt-5 justify-center">
                  <img
                    className="w-[32px] h-[32px]"
                    alt="Add button"
                    src={AddButton}
                    onClick={()=>handleAddHydroponics()}
                  />
                  </div>


                    <hr className="mt-10"/>
                    <p className="text-lg mb-2 ml-2 mt-3 text-black"><b>Soilless</b></p>
                    {Soilless.map((item, idx) => (
              <div className={`flex space-x-4`} key={idx}>
              <div className="mt-3 grid grid-cols-4 gap-4" key={idx}>
                  <div>
                    <InputFieldComp
                      label={idx === 0 && "Greenhouse Name"}
                      type="text"
                      value={Soilless[idx]["greenhouse_name"]}
                      compulsory={false}
                      placeholder={"Greenhouse Name"}
                      onChange={(e) => updateSoilless(e, idx, "greenhouse_name")}
                    />
                  </div>
                  <div>
                  <InputFieldComp
                    label={idx === 0 && `Floor Area (Meter)`}
                    type="text"
                    value={Soilless[idx]["floor_area"]}
                    maxLength={8}
                    compulsory={false}
                    placeholder={"Floor Area"}
                    onChange={(e) => updateSoilless(e, idx, "floor_area")}                      
                    />
                  </div>
                  <div>
                  <InputFieldComp
                    label={idx === 0 && `Number of Holes`}
                    type="text"
                    value={Soilless[idx]["greenhouse_holes"]}
                    maxLength={8}
                    compulsory={false}
                    placeholder={"Floor Area"}
                    onChange={(e) => updateSoilless(e, idx, "greenhouse_holes")}                      
                    />
                  </div>
                  <div className="">
                  {idx === 0 && <p className="text-sm text-black">Dosing</p>}
                  <div className="relative">
                  <SearchDropDownComp
                    list={metaData?.farmDosingTypes || []}
                    label={"Select"}
                    active={Soilless[idx]["dosing_type_id"] || "select"}
                    width="w-[180px]"
                    type={"type_name"}
                    onSelected={(item) => updateSoilless(item, idx, "dosing_type_id")}
                    />
                </div>
                  </div>
                </div>
                <div>
               {idx !== 0 && 
                <img className={idx === 0 ? `mt-12`:`mt-6` }
                  alt="Delete button"
                  src={DeleteButton}
                  onClick={()=>handleRemoveSoilless(idx)}/>}

              {idx === 0 && 
                <img className="mt-12 h-5 w-8"
                  alt=""
                  src={Blank}
                  />}     

                </div>
              </div>
            ))}
                <div className="flex mt-5 justify-center">
                  <img
                    className="w-[32px] h-[32px]"
                    alt="Add button"
                    src={AddButton}
                    onClick={()=>handleAddSoilless()}
                  />
                  </div>



                    <hr className="mt-10"/>
                    <p className="text-lg mb-2 ml-2 mt-3 text-black"><b>Open Field</b></p>

                    {OpenField.map((item, idx) => (
              <div className={`flex space-x-4`} key={idx}>
              <div className="mt-3 grid grid-cols-4 gap-4" key={idx}>
                  <div>
                    <InputFieldComp
                      label={idx === 0 && "Farmable Land Area (Meter)"}
                      type="text"
                      value={OpenField[idx]["farmable_area"]}
                      compulsory={false}
                      placeholder={"Farmable Land Area"}
                      onChange={(e) => updateOpenField(e, idx, "farmable_area")}
                    />
                  </div>
                  <div>
                  <InputFieldComp
                    label={idx === 0 && `Farmed Land Area (Meter)`}
                    type="text"
                    value={OpenField[idx]["farmed_area"]}
                    maxLength={8}
                    compulsory={false}
                    placeholder={"Farmed Land Area"}
                    onChange={(e) => updateOpenField(e, idx, "farmed_area")}                      
                    />
                  </div>
                  <div className="">
                  {idx === 0 && <p className="text-sm text-black">Irigation</p>}
                  <div className="relative">
                  <SearchDropDownComp
                    list={ metaData?.farmIrrigationTypes || []}
                    label={"Select"}
                    active={OpenField[idx]["irrigation_type_id"] || "select"}
                    width="w-[180px]"
                    type={"type_name"}
                    onSelected={(item) => updateOpenField(item, idx, "irrigation_type_id")}
                    />
                </div>
                  </div>
                  <div>
                  <InputFieldComp
                    label={idx === 0 && `Land Area Irigation (Meter)`}
                    type="text"
                    value={OpenField[idx]["irrigated_area"]}
                    maxLength={8}
                    compulsory={false}
                    placeholder={"Irrigated Area"}
                    onChange={(e) => updateOpenField(e, idx, "irrigated_area")}                      
                    />
                  </div>
                </div>
                <div>
               {idx !== 0 && 
                <img className={idx === 0 ? `mt-12`:`mt-6` }
                  alt="Delete button"
                  src={DeleteButton}
                  onClick={()=>handleRemoveOpenField(idx)}/>}

              {idx === 0 && 
                <img className="mt-12 h-5 w-8"
                  alt=""
                  src={Blank}
                  />}     

                </div>
              </div>
            ))}
                <div className="flex mt-5 justify-center">
                  <img
                    className="w-[32px] h-[32px]"
                    alt="Add button"
                    src={AddButton}
                    onClick={()=>handleAddOpenField()}
                  />
                  </div>
                      </div>
                      {/* <div className="mt-20">
                        <div className="flex justify-between">
                          <p className="font-bold underline underline-offset-8 decoration-green decoration-[3px]">
                            Hydroponics
                          </p>
                          <div>
                            <p
                              onClick={() => _saveEditChangesApi("Hydroponics")}
                              className="bg-green text-sm text-white px-2 rounded-full cursor-pointer"
                            >
                              save
                            </p>
                          </div>
                        </div>
                        <HydroponicsAddedItemsComp
                          data={totalGreenHouse}
                          apiGreenHouse={apiGreenHouse}
                          metaData={metaData}
                          totalItems={(i, apiArray) => {
                            setTotalGreenHouse(i);
                            console.log(apiArray);
                            setApiGreenHouse(apiArray);
                          }}
                        />
                      </div> */}
                      {/* <div className="mt-20">
                        <div className="flex justify-between">
                          <p className="font-bold underline underline-offset-8 decoration-green decoration-[3px]">
                            Open Field
                          </p>
                          <div>
                            <p
                              onClick={() => _saveEditChangesApi("Open Field")}
                              className="bg-green text-sm text-white px-2 rounded-full cursor-pointer"
                            >
                              save
                            </p>
                          </div>
                        </div>
                        <OpenFieldAddedItemsComp
                          data={totalOpenField}
                          apiOpenField={apiOpenField}
                          metaData={metaData}
                          totalItems={(i, apiArray) => {
                            setTotalOpenField(i);
                            setApiOpenField(apiArray);
                          }}
                        />
                      </div> */}
                      {/* <div className="mt-20 mb-10">
                        <div className="flex justify-between">
                          <p className="font-bold underline underline-offset-8 decoration-green decoration-[3px]">
                            Soilless
                          </p>
                          <div>
                            <p
                              onClick={() => _saveEditChangesApi("Soilless")}
                              className="bg-green text-sm text-white px-2 rounded-full cursor-pointer"
                            >
                              save
                            </p>
                          </div>
                        </div>

                        <HydroponicsAddedItemsComp
                          data={totalSoilles}
                          apiGreenHouse={apiSoilles}
                          metaData={metaData}
                          totalItems={(i, apiArray) => {
                            setTotalSoilles(i);
                            console.log(apiArray);
                            setApiSoilles(apiArray);
                          }}
                        />
                      </div> */}
                      {/* <div className="mt-5">
                        <div className="flex justify-between">
                          <p className="font-bold underline underline-offset-8 decoration-green decoration-[3px]">
                            Feature Governance
                          </p>
                          <div>
                            <p
                              onClick={() =>
                                _saveEditChangesApi("Feature Governance")
                              }
                              className="bg-green text-sm text-white px-2 rounded-full cursor-pointer"
                            >
                              save
                            </p>
                          </div>
                        </div>
                      </div> */}
                      {/* <div className="mt-5">
                        <div>
                          <p>Farm Function</p>
                          <DropDownComp
                            listData={metaData.farmFunctions}
                            active={
                              selectedFarmData.farm_function === null
                                ? metaData.farmFunctions[0]
                                : selectedFarmData.farm_function
                            }
                            onSelected={(item) => {
                              setFarmFunction(item);
                            }}
                            type={"function"}
                            customStyles={
                              "rounded-md justify-between text-[#3E4347] text-[14px]"
                            }
                            customMenuStyle={
                              "relative inline-block text-left w-full"
                            }
                          />
                        </div>
                        <div className="mt-5 mb-10">
                          <p>Batch Tracking Method</p>
                          <DropDownComp
                            listData={metaData.batchTrackingMethods}
                            active={
                              selectedFarmData.atch_tracking_method === null
                                ? metaData.batchTrackingMethods[0]
                                : selectedFarmData.atch_tracking_method
                            }
                            onSelected={(item) => {
                              setFTMethod(item);
                            }}
                            type={"method_name"}
                            customStyles={
                              "rounded-md justify-between text-[#3E4347] text-[14px]"
                            }
                            customMenuStyle={
                              "relative inline-block text-left w-full"
                            }
                          />
                        </div>
                      </div> */}
                    </>
                  ) : null}
                  {/* {selectedTab === tabsList[1] ? (
                    <>
                      <div className="mt-14">
                        <div className="flex justify-between">
                          <p className="font-bold underline underline-offset-8 decoration-green decoration-[3px]">
                            Historic Yields
                          </p>
                          <div>
                            <p
                              onClick={() =>
                                _saveEditChangesApi("Historic Yields")
                              }
                              className="bg-green text-sm text-white px-2 rounded-full cursor-pointer"
                            >
                              save
                            </p>
                          </div>
                        </div>

                        <HistoricalYeildAddedItemsComp
                          data={THistoricalYield}
                          productsList={productsList}
                          metaData={metaData}
                          apiHistoricalYield={apiHistoricalYield}
                          totalItems={(i, apiArray) => {
                            setTHistoricalYield(i);
                            setApiHistoricalYield(apiArray);
                          }}
                        />
                      </div>
                      <div className="mt-20">
                        <div className="flex justify-between">
                          <p className="font-bold underline underline-offset-8 decoration-green decoration-[3px]">
                            Contracted Products
                          </p>
                          <div>
                            <p
                              onClick={() =>
                                _saveEditChangesApi("Contracted Products")
                              }
                              className="bg-green text-sm text-white px-2 rounded-full cursor-pointer"
                            >
                              save
                            </p>
                          </div>
                        </div>

                        <ContractedProductAddedItemsComp
                          data={TContractedProducts}
                          productsList={productsList}
                          apiContractedProduct={apiContractedProduct}
                          metaData={metaData}
                          totalItems={(i, apiArray) => {
                            console.log("HY=>", i);
                            console.log("API=>", apiArray);
                            setTContractedProducts(i);
                            setApiContractedProduct(apiArray);
                          }}
                        />
                      </div>
                      <div className="w-[100%] bg-gray-300 h-[1px] mt-5"></div>
                    </>
                  ) : null} */}
                  {selectedTab === tabsList[1] ? (
                    <>
                      <AppUsersTab
                        token={token}
                        selectedFarm={selectedFarmData}
                      />
                    </>
                  ) : null}

                  {selectedTab === tabsList[2] ? (
                    <>
                    {/* {docs?.length === 0 ? */}
                       <DocumentsTab 
                       docsData={docsData}
                       onChangeValue={onChangeValue}
                       handleFileChange={handleFileChange}
                       dateAgreementLetterValidityChange={dateAgreementLetterValidityChange}
                       dateLandCertificateValidityChange={dateLandCertificateValidityChange}
                       showSuccessModal={showSuccessModal}
                       showConfirmModal={showConfirmModal}
                       setShowConfirmModal={setShowConfirmModal}
                       _addDocsData={_addDocsData}
                       docs={docs}
                       />
                      {/* : <DocsViewTab docs={docs}/> */}
                    {/* } */}
                    </>
                  ) : null}
                </div>
                {/*footer*/}
                <div className="flex items-center p-6 border-t border-solid border-slate-200 rounded-b-lg bg-[#EDEDED] w-full">
                  <div className="grid grid-cols-2 gap-2 w-full">
                    <div></div>
                    <div className="justify-end flex">
                    
                      {selectedTab !== tabsList[0] ? (
                        <button
                          className="border border-green text-green mr-5 px-5 py-1 rounded-full hover:bg-green hover:text-white"
                          type="button"
                          onClick={() => handleBackClick()}
                        >
                          Back
                        </button>
                      ) : null}

                      {selectedTab !== tabsList[2] ? (
                        <button
                          className="border border-green text-green mr-5 px-5 py-1 rounded-full hover:text-white hover:bg-green"
                          type="button"
                          onClick={() => onNextClick()}
                        >
                          Next
                        </button>
                      ) : null}

                    {/* {docs?.length === 0 && selectedTab === tabsList[2] ? ( */}
                        {selectedTab === tabsList[2] ? (
                        <button
                          className="border border-green text-green mr-5 px-5 py-1 rounded-full hover:text-white hover:bg-green"
                          type="button"
                          onClick={() => onSubmitClick()}
                        >
                          Submit
                        </button>
                      ) : null}

                    {selectedTab === tabsList[0] ? (
                        <button
                          className="border border-green text-green mr-5 px-5 py-1 rounded-full hover:text-white hover:bg-green"
                          type="button"
                          onClick={() => _saveEditChangesApi("farmData")}
                        >
                          Submit
                        </button>
                      ) : null}



                      <button
                        className="border border-green text-green mr-10 px-5 py-1 rounded-full hover:bg-green hover:text-white"
                        type="button"
                        onClick={() => hideModal()}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                  <div />
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>{" "}
        </>
      ) : null}
    </>
  );
};
