import React, { useEffect, useState } from "react";
import { MITRA_LIST_TB_COL } from "..";
import { axiosClient, axiosHeader } from "../../../api";
import { BsFillCaretDownFill } from "react-icons/bs";

export const MitraListTable = ({ selectedStation }) => {
  let colums = MITRA_LIST_TB_COL;
  const [totalPage, setTotalPage] = useState();
  const [selectedPage, setSelectedPage] = useState(0);
  const [tableDataList, setTableDataList] = useState([]);

  useEffect(() => {
    _fetchData(selectedPage);
  }, [selectedStation]);

  async function _fetchData(page) {
    setTableDataList([]);
    axiosClient
      .get(
        `admin/weather_station/${selectedStation.id}/?&sort_by=id&sort_order=ASC`,
        axiosHeader()
      )
      .then((res) => {
        if (res?.status === 200) {
          if (res?.data?.weather_station_info?.farmDeviceRelation) {
            let list = res?.data?.weather_station_info?.farmDeviceRelation;
            if (list.length > 0) {
              setTableDataList(list);
            } else {
              setTableDataList([]);
            }
          }
        } else {
          console.log("=> error users api:");
        }
      });
  }

  return (
    <div className="h-[270px]">
      <div className="h-[240px] overflow-auto scrollbar-hide mt-5">
        <table className="w-full">
          <thead>
            <tr className="sticky top-0">
              {colums.map((col, i) => {
                return (
                  <td
                    className="text-sm text-center p-[0px] m-[0px] bg-white"
                    key={`tfhk-${i}`}
                  >
                    <div className="flex justify-center items-center py-2 border-gray-200 border-b-[1px]">
                      <p className="text-sm text-[#3EB049]">{col.Header}</p>
                      {/* {col.Header !== "FARM ID" ? (
                        <BsFillCaretDownFill className="ml-2" size={10} />
                      ) : null} */}
                    </div>
                  </td>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {tableDataList.map((data, i2) => {
              return (
                <tr
                  key={`row-${i2}`}
                  className="border-gray-200 border-b-[1px]"
                >
                  {colums.map((col, i) => {
                    if (col.Header === "Mitra") {
                      return (
                        <td className="py-2" onClick={() => console.log(data)}>
                          <p className="text-sm text-center">
                            {data[col.accessor].user_name}
                          </p>
                        </td>
                      );
                    } else if (col.Header === "Farm Name") {
                      return (
                        <td className="py-2" onClick={() => console.log(data)}>
                          <p className="text-sm text-center">
                            {data[col.accessor].farm_name}
                          </p>
                        </td>
                      );
                    } else if (col.Header === "Farm Address") {
                      return (
                        <td className="py-2" onClick={() => console.log(data)}>
                          <p className="text-sm text-center">
                            {data[col.accessor]?.farm_loc_address === null
                              ? "--"
                              : data[col.accessor]?.farm_loc_address}
                          </p>
                        </td>
                      );
                    } else if (col.Header === "No.") {
                      return (
                        <td className="py-2" onClick={() => console.log(data)}>
                          <p className="text-sm text-center">{i2 + 1}</p>
                        </td>
                      );
                    } else {
                      return (
                        <td className="py-2">
                          <p
                            title={
                              data[col.accessor] == null
                                ? ""
                                : data[col.accessor].toString()
                            }
                            className="text-sm text-center"
                          >
                            {data[col.accessor] == null
                              ? ""
                              : data[col.accessor].toString().slice(0, 10)}
                          </p>
                        </td>
                      );
                    }
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {/* <div className="flex justify-end items-center h-[30px] text-sm px-5">
        {totalPage !== undefined ? (
          <TablePaginationComp
            totalPages={totalPage}
            active={selectedPage}
            selectedPage={(p) => {
              setSelectedPage(p);
              _fetchData(p);
            }}
          />
        ) : null}
      </div> */}
    </div>
  );
};
