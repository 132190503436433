import React, { useEffect, useState } from "react";
import profileImage from "../images/Profile.png";
import navImg from "../images/FarmPipeLine/nav.png";
import beleafLogoImg from "../images/FarmPipeLine/beleaf_logo.png";
import bellImg from "../images/bell.png";
import { BsFillCaretDownFill } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { showHideNavIcon } from "../config/states/reducers/appReducer";
import { _asyncGetNotifications, _asyncGetUnreadTotalNotifications, _asyncReadNotifications } from "../api";
import { getDateFormatMoment } from "../config/localdata";

export const Header = ({ user, logout, navClick }) => {
  const [showLogout, setShowLogout] = useState(false);
  const [count, setCount] = useState(0);
  const [showNotification, setShowNotification] = useState(false);

  const [notification, setNotification] = useState([]);

  const dispatch = useDispatch();


  async function _fetchNotificationCount() {
    const res = await _asyncGetUnreadTotalNotifications();
    setCount(res?.total_unread_notifications);
  }

  async function _fetchNotification() {
    const res = await _asyncGetNotifications();
    setNotification(res);
  }

  async function _readNotification(ids) {
    let body = {
      notification_ids:ids
    }
    if(ids.length > 0){
      await _asyncReadNotifications(body);
    }
  }

  const handleNotificationBell = () =>{
    if(showNotification === false){
      _fetchNotification();
    }
    if(showNotification === true){
      let ids = [];
      notification.map((item)=>{
        if(!item?.is_read){
          ids.push(item?.id)
        }
      })
      _readNotification(ids)
    }
    setShowNotification(!showNotification)
  }

  useEffect(()=>{
    _fetchNotificationCount();
  })

  return (
    <div className="grid grid-cols-3 h-[68px] border border-b-[#E8E9EB]">
      {showLogout ? (
        <div className="bg-white absolute right-5 z-50 mt-[68px] w-[200px] rounded-2xl px-3 h-[124px]">
          <p className="text-[15px] mt-6 font-[450px] font-poppins px-3 py-2 text-center mb-3 cursor-pointer hover:bg-gray-200 hover:rounded-full">
            Profile
          </p>
          <div
            onClick={() => {
              logout();
              setShowLogout(false);
            }}
            className="flex items-center justify-center cursor-pointer  hover:bg-gray-200 hover:rounded-full"
          >
            <p className="text-[15px] px-3 py-2 text-center font-poppins">
              Sign Out
            </p>
            {/* <FiLogOut /> */}
          </div>
        </div>
      ) : null}
      <div className="flex items-center">
        <div>
          <img
            onClick={() => {
              dispatch(showHideNavIcon());
            }}
            className="w-[20px] h-[20px] ml-[34px] cursor-pointer"
            src={navImg}
            alt="logo"
          />
        </div>
        <div>
          <img
            className="w-[140px] h-[56px] ml-[21px]"
            src={beleafLogoImg}
            alt="logo"
          />
        </div>
      </div>
      <div className="  col-span-2 flex">
        <div className="flex w-full  justify-end items-center">
          <div class="p-5">
              <strong class="relative inline-flex items-center text-xs font-medium">
              {count ? <span class="absolute -bottom-1 -right-0 h-5 w-5 bg-[#F491A3] text-white rounded-full flex justify-center items-center "><span>{count}</span></span>:null}
                <img 
                  className="w-[24px] h-[24px] ml-[45px] mr-[20px]"
                  src={bellImg}
                  onClick={handleNotificationBell}
                  alt="logo"
                />
              </strong>
        </div>

          <div className="w-[1px] bg-[#E8E9EB] mr-[40px] mt-[19px] mb-[17px] h-[35px]"></div>
          <div
            onClick={() => setShowLogout(!showLogout)}
            className="flex items-center mr-[29px] cursor-pointer"
          >
            <img
              className="w-[32px] h-[32px] ml-3"
              src={profileImage}
              alt="profileImage"
            />
            <div className="mx-[12px]">
              <p className="text-black text-[12px]">{user?.user_name}</p>
              <p className="text-black text-[12px]">{user?.role?.role}</p>
            </div>
            <BsFillCaretDownFill className="ml-[6px] w-[10px] h-[10px] " />
          </div>
        </div>
        <div class={`absolute mt-9 z-50 ml-80  max-w-[420px] max-h-[220px] p-6 bg-white border border-gray-300 rounded-lg overflow-scroll shadow ${showNotification ? '': 'hidden'}`}>
                <a href="#">
                    <h5 class="mb-2 text-md font-bold">Notification</h5>
                </a>
                {notification?.map((item)=>{
                  return(
                    <>
                      <p class="mb-1 font-normal text-gray-700"><a className="mb-4 text-sm italic text-[#0090FF] underline cursor-pointer text-center" href={item?.notification_details?.data?.screen}>{item?.notification_details?.data?.body}</a></p>
                      <p class="mb-4 text-sm italic text-gray-700">{getDateFormatMoment(item?.updated_at, true)}</p>
                     
                    </>
                  )
                })}
            </div>
      </div>
    </div>
  );
};
