import React, { useEffect, useState } from "react";
// import { _apiLogout, _fetchMetaData } from "../../api";
import { useNavigate, useParams } from "react-router-dom";
import { DiscardBOMModal } from "../Models/DiscardBOMModal";
import { SaveBOMModal } from "../Models/SaveBOMModal";
import { SuccessBOMModal } from "../Models/SuccessBOMModal";
import {
  _asyncCreateNewBOM,
  _asyncGetAllProducts,
  _asyncGetAllSKU,
  _asyncGetBOMProductDetail,
  _asyncGetOdooProductList,
  _asyncGetPCsBOM,
  _asyncUpdateBOM,
  _fetchMetaData,
} from "../api";
import deleteIcon from "../images/deleteIcon.png";
import { PageLayoutComp } from "./PageLayoutComp";
import SearchDropDownComp from "./SearchDropDownComp";
import { toast } from "react-toastify";

function BomProductDetail() {
  const { id } = useParams();
  const location = useNavigate();
  const size = 10;
  let user = JSON.parse(localStorage.getItem("user_data"));
  const [showSaveBOMModal, setShowSaveBOMModal] = useState(false);
  const [showDiscardBOMModal, setDiscardSaveBOMModal] = useState(false);
  const [showSuccessBOMModal, setSuccessSaveBOMModal] = useState(false);
  const [allProducts, updateAllProducts] = useState([]);
  const [uomValue, setUomValue] = useState("");
  const [selectedProduct, setSelectedProduct] = useState();
  const [pcsData, setPcsData] = useState([]);
  const [mpcData, setMpcData] = useState([]);
  const [PCUomValue, setPCUomValue] = useState("");
  const [selectedMPC, setSelectedMPC] = useState({});
  const [BomTypes, setBomTypes] = useState([]);
  const [selectedPCS, setSelectedPCS] = useState({});
  const [QuantityValue, setQuantityValue] = useState("");
  const [selectedBOMType, setSelectedBOMType] = useState({});
  const [ReferenceValue, setReferenceValue] = useState("");
  const [bomDetail, setBomDetail] = useState();
  const [deletedSku, setDeletedSku] = useState([])
  // console.log('sku uom ----',JSON.stringify(pcsData));

  const [skuDetails, setSkuDetails] = useState([
    {  name: "", default_code: "", id: "", quantity: "", uom: "",isNew:true },
  ]);
  //   const [first, setfirst] = useState(second)
  const addSku = () => {
    setSkuDetails([
      ...skuDetails,
      { name: "", default_code: "", id: "", quantity: "", uom: "",isNew:true },
    ]);
  };
  const deleteSku = (index,deleted_id) => {
    let arr = [...deletedSku,{id:deleted_id}]
    setDeletedSku(arr)
    // const newSkuDetails = skuDetails.filter((_, i) => i !== index);
    const newSkuDetails = [...skuDetails];
    newSkuDetails.splice(index, 1);
    setSkuDetails(newSkuDetails);
  };
  useEffect(() => {
    _fetchData();
    _getGetAllProducts();
    _getPCsBOM();
    _fetchProducts();
  }, []);

  async function _fetchBOMDetail() {
    let Data = await _asyncGetBOMProductDetail(id);
    setBomDetail(Data?.data);
    setReferenceValue(Data?.data?.ref);
    setQuantityValue(Data?.data?.product_qty);
    setSelectedPCS(Data?.data?.pc_info);
    setSelectedMPC(Data?.data?.mpc);
    setPCUomValue(Data?.data?.pc_info?.uom);
    setSelectedBOMType({ name: Data?.data?.type });
    let skuUpdate = Data?.data?.sku?.map((obj, i) => {
      return {
        name: obj?.sku_info?.name,
        default_code: obj?.sku_info?.default_code,
        id: obj?.id,
        quantity: obj?.product_qty,
        uom: obj?.sku_info?.uom,
        isNew:false
      };
    });
    setSkuDetails(skuUpdate);
  }

  useEffect(() => {
    if (id) {
      _fetchBOMDetail();
    }
  }, [id]);
  async function _fetchData() {
    let metaData = await _fetchMetaData();
    let bomType = metaData?.bomTypes?.map((key, i) => {
      return {
        name: key,
      };
    });
    setBomTypes(bomType);
  }

  async function _getGetAllProducts() {
    const data = await _asyncGetAllSKU(500);
    if (!data) return;
    updateAllProducts(data);

  }
  async function _fetchProducts(page) {
    let params = {
      size: size,
      page: page + 1,
    };
    const res = await _asyncGetOdooProductList(params);
    setMpcData(res?.data?.data?.data);
  }

  async function _getPCsBOM() {
    const pcsBOM = await _asyncGetPCsBOM();
    setPcsData(pcsBOM?.data?.data);
  }

  const handleChange = (value, field, index) => {
    // console.log(value,'----val----');
    
    if (field == "sku") {
      setUomValue(value?.uom);
      setSelectedProduct(value);
      setSkuDetails(
        skuDetails.map((item, i) =>
          i === index
            ? {
                name: value?.name,
                default_code: value?.default_code,
                id: value?.id,
                quantity: item?.quantity,
                uom: value?.uom,
                isNew:item?.isNew
              }
            : item
        )
      );
    } else if (field === "quantity") {
      setSkuDetails(
        skuDetails.map((item, i) =>
          i === index
            ? {
                name: item?.name,
                default_code: item?.default_code,
                id: item?.id,
                quantity: value,
                uom: item?.uom,
                isNew:item?.isNew
              }
            : item
        )
      );
    } else if (field === "uom") {
      setSkuDetails(
        skuDetails.map((item, i) =>
          i === index
            ? {
              name: item?.name,
              default_code: item?.default_code,
                id: item?.id,
                quantity: item?.quantity,
                uom: value,
                isNew:item?.isNew
              }
            : item
        )
      );
    }
  };

  const showModal = () => {
    if (!selectedPCS?.id) {
      toast.error("Please Select PC Product", {autoClose: 2000});
      return;
    }
    if (!selectedMPC?.id) {
      toast.error("Please Select MPC Product", {autoClose: 2000});
      return;
    }

    if (QuantityValue === "") {
      toast.error("Please Enter Quantity", {autoClose: 2000});
      return;
    }
    if (ReferenceValue === "") {
      toast.error("Please Enter Reference", {autoClose: 2000});
      return;
    }
    if (!selectedBOMType?.name) {
      toast.error("Please Enter BOM Type", {autoClose: 2000});
      return;
    }
    const isSkuSelected = skuDetails.every((sku)=>sku?.name)
    if (!isSkuSelected) {
      toast.error("Please Enter Sku Product", {autoClose: 2000});
      return;
    }
    const isQuantity = skuDetails.every((sku)=>sku?.quantity && sku?.quantity > 0)
    if (!isQuantity) {
      toast.error("Please Enter Sku Quantity", {autoClose: 2000});
      return;
    }
    const isUoM = skuDetails.every((sku)=>sku?.uom)
    if (!isUoM) {
      toast.error("Please Enter Sku UoM", {autoClose: 2000});
      return;
    }
    setShowSaveBOMModal(true);
  };

  const showDiscardModal = () => {
    setDiscardSaveBOMModal(true);
  };

  const successModal = () => {
    setSuccessSaveBOMModal(true);
    setShowSaveBOMModal(false);
    setTimeout(() => {
      {
        closeSuccessModal();
      }
    }, 3000);
  };
  const closeSuccessModal = () => {
    setSuccessSaveBOMModal(false);
    location("/budget-plan-rab/bom-master-data-list");
  };
  const handleDiscard = () => {
    location("/budget-plan-rab/bom-master-data-list");
  };
  const handleSubmit = async () => {
    
    if (id) {
      let body = {
        mpc_id: selectedMPC?.id,
        product_qty: QuantityValue,
        type: selectedBOMType?.name,
        ref: ReferenceValue,
        sku: skuDetails?.map((key) => {
          if (!key.isNew) {
            return {
              id: key.id,
              product_qty: key.quantity,
            };
          } else {
            return {
              sku_id: key.id,
              product_qty: key.quantity,
            };
          }
        }),
      
      };
 
      deletedSku.forEach(item => {
        body.sku.push(item);
    });
    // console.log(JSON.stringify(body),'00---',skuDetails);
    // return
      let res = await _asyncUpdateBOM(body, id);
      console.log(res,'----');
      setShowSaveBOMModal(false);
      if (res?.status == 200) {
        successModal();
      }
    } else {
      let body = {
        pc_id: selectedPCS?.id,
        mpc_id: selectedMPC?.id,
        product_qty: QuantityValue,
        type: selectedBOMType?.name,
        ref: ReferenceValue,
        sku: skuDetails?.map((key) => {
          return {
            sku_id: key?.id,
            product_qty: key?.quantity,
          };
        }),
      };
      let response = await _asyncCreateNewBOM(body);
      setShowSaveBOMModal(false);
      if (response?.status == 200 ||response?.status == 201) {
        successModal();
      }
    }
  };

  const onChangePCS = (item) => {
    setPCUomValue(item?.uom);
    setSelectedPCS(item);
  };

  return (
    <div className="w-screen h-screen">
      <PageLayoutComp user={user}>
        <div className="h-[100%] bg-[#E6F1ED] p-4">
          {showSaveBOMModal ? (
            <SaveBOMModal
              hideModal={() => {
                setShowSaveBOMModal(false);
              }}
              onConfirm={() => handleSubmit()}
            />
          ) : null}
          {showDiscardBOMModal ? (
            <DiscardBOMModal
              hideModal={() => {
                setDiscardSaveBOMModal(false);
              }}
              onConfirm={() => handleDiscard()}
            />
          ) : null}
          {showSuccessBOMModal ? (
            <SuccessBOMModal closeSuccessModal={closeSuccessModal} />
          ) : null}

          <div className="h-[7%]">
            <div className="flex justify-between">
              <p className="text-3xl font-bold mb-0">
                {id ? "Edit" : "Add"} - Bill of Materials
              </p>
              <div className="flex"></div>
            </div>
          </div>
          {/* <div className="h-[100%]"> */}
          <div className="h-[calc(100%-20px)] bg-white rounded-lg overflow-hidden">
            <div class="container mx-auto h-[calc(90%)]  overflow-scroll">
              <div class="grid grid-cols-1 md:grid-cols-2 h-[calc(100%)]">
                <div class="bg-white p-6  shadow-md ">
                  <h2 class="text-3xl mb-6">Product Details (PC)</h2>
                  <div class="mb-4">
                    <label class="block text-gray-700">Product (PC)</label>
                    <SearchDropDownComp
                      list={pcsData}
                      active={selectedPCS}
                      label="Select Product"
                      type={"product_code"}
                      // disabled={disabledForm}
                      onSelected={(item) => onChangePCS(item)}
                    />
                  </div>
                  <div class="mb-4">
                    <label class="block text-gray-700">Product (MPC)</label>
                    <SearchDropDownComp
                      list={mpcData}
                      active={selectedMPC}
                      label="Select Product"
                      type={"production_code"}
                      // disabled={disabledForm}
                      onSelected={(item) => {
                        setSelectedMPC(item);
                      }}
                    />
                  </div>
                  <div class="mb-4 flex">
                    <div class="w-1/2 mr-2">
                      <label class="block text-gray-700">Quantity</label>
                      <input
                        value={QuantityValue}
                        onChange={(e) => setQuantityValue(e.target.value)}
                        class="w-full mt-1 p-2 border border-gray-300 rounded-md"
                        placeholder="Input quantity..."
                      />
                    </div>
                    <div class="w-1/2 ml-2">
                      <label class="block text-gray-700">UoM</label>

                      <input
                        disabled
                        value={PCUomValue}
                        onChange={(e) => setPCUomValue(e.target.value)}
                        class="w-full mt-1 p-2 border border-gray-300 rounded-md bg-gray-200"
                        placeholder="Kg"
                      />
                    </div>
                  </div>
                  <div class="mb-4">
                    <label class="block text-gray-700">Reference</label>
                    <input
                      value={ReferenceValue}
                      onChange={(e) => setReferenceValue(e.target.value)}
                      class="w-full mt-1 p-2 border border-gray-300 rounded-md"
                      placeholder="Input quantity..."
                    />
                  </div>
                  <div class="mb-4">
                    <label class="block text-gray-700">BOM Type</label>
                    <SearchDropDownComp
                      list={BomTypes}
                      active={selectedBOMType}
                      label="Select BOM Type"
                      type={"bom_Type"}
                      // disabled={disabledForm}
                      onSelected={(item) => {
                        setSelectedBOMType(item);
                      }}
                    />
                  </div>
                </div>

                <div class="bg-gray-100 p-6 shadow-md overflow-scroll">
                  <h2 class="text-2xl font-bold mb-6">SKU Details</h2>
                  {skuDetails.map((sku, index) => (
                    <div class="mb-4 flex">
                      <div class="w-full mr-[7px]">
                        <label class="block text-gray-700">SKU</label>
                        <div>
                          <SearchDropDownComp
                          disabled ={!skuDetails[index]?.isNew}
                            list={allProducts}
                            //{"id":"684c7b88-c75d-469c-a3c7-c9b55e8c4f73","o_id":12221,"name":"Agristic - Bayer 1L","default_code":"PR-AGRIT-BY-1LT","image_url":null,"uom":"ml"}
                            active={skuDetails[index]}
                            label="Select Product"
                            type={"sku_name"}
                            // disabled={disabledForm}
                            // onSelected={(item) => productOnChange(item,index)}
                            onSelected={(e) => handleChange(e, "sku", index)}
                          />
                        </div>
                      </div>
                      <div class="w-1/2 mr-2">
                        <label class="block text-gray-700">Item Quantity</label>
                        <input
                          type="number"
                          onChange={(e) =>
                            handleChange(e.target.value, "quantity", index)
                          }
                          value={skuDetails[index]?.quantity}
                          class="w-full mt-1 p-2 border border-gray-300 rounded-md"
                          placeholder="Input quantity..."
                        />
                      </div>
                      <div class="w-1/2 ml-2">
                        <label class="block text-gray-700">UoM</label>
                        <input
                          value={skuDetails[index]?.uom}
                          // onChange={(e) => setUomValue(e.target.value)}
                          onChange={(e) =>
                            handleChange(e.target.value, "uom", index)
                          }
                          class="w-full mt-1 p-2 border border-gray-400 rounded-md bg-gray-200"
                          placeholder="Kg"
                        />
                      </div>
                      <img
                        className="h-[4vh] w-[4%] mt-[5%] ml-[2%]"
                        src={deleteIcon}
                        onClick={() => deleteSku(index,sku?.id)}
                        alt="Delete Icon"
                      />
                    </div>
                  ))}
                  <button
                    class="w-full mt-4 py-2 bg-green text-white rounded-md"
                    onClick={addSku}
                  >
                    + Add SKU
                  </button>
                </div>
              </div>
            
            </div>
            <div class="mt-3 bg-white flex justify-end mr-5">
                <button
                  class="mr-2 py-2 px-4 bg-white border-green border-[1px] text-black rounded-lg"
                  onClick={() => showDiscardModal()}
                >
                  Cancel
                </button>
                <button
                  class="py-2 px-6 bg-green text-white rounded-lg"
                  onClick={() => {
                    showModal();
                  }}
                >
                  Save
                </button>
              </div>
          </div>
          {/* </div> */}
        </div>
      </PageLayoutComp>
    </div>
  );
}

export default BomProductDetail;
