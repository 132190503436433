import React, { useState } from "react";

export const InputTextComp = ({
  label,
  placeholder,
  onChange,
  extraStyle = "h-[40px]",
  compulsory = false,
  image,
  error = false,
  value = "",
}) => {
  const [valueText, setValueText] = useState(value);
  return (
    <div className="h-full">
      {label === undefined ? null : (
        <>
          <div className="flex items-center">
            {image ? (
              <img className="float-right w-[25px] h-[25px] mr-2" src={image} />
            ) : null}
            <p className="pl-1 pb-1 text-[#8A9099] text-sm">{label}</p>
            {compulsory ? <p className="text-red-500">*</p> : null}
          </div>
        </>
      )}
      <div
        className={`${extraStyle} border border-gray-300 rounded-lg flex items-center`}
      >
        <input
          className="ml-[12px] w-full mr-[12px] outline-none border-none"
          type="text"
          value={valueText}
          placeholder={placeholder.toString()}
          onChange={(text) => {
            setValueText(text.target.value);
            onChange(text.target.value);
          }}
        />
      </div>
      {error ? (
        <p className="text-red-400 text-sm ml-2">Field Can not be Empty</p>
      ) : null}
    </div>
  );
};
