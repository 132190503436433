import React, { useEffect, useRef, useState } from "react";
import { PageLayoutComp } from "./PageLayoutComp";
import { BsDot } from "react-icons/bs";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/Page/AnnotationLayer.css";
import EMateraiPic from "./../../src/images/ematerai.png";
import EMateraiPicGrey from "./../../src/images/ematerai_grey.png";
import { ResizeObserver } from "@juggle/resize-observer";
import MouseTooltip from "react-sticky-mouse-tooltip";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  _asyncAssignSignaturePosition,
  _asyncDoEmaterai,
  _asyncGetTaskDetails,
  _asyncGetTaskDocument,
  _asyncGetTaskRecipients,
} from "../api";
import useMeasure from "react-use-measure";
import { MdDeleteForever } from "react-icons/md";
import { SuccessfullMesg } from "../Models/SuccessfullMesg";
import { ConfirmationModal } from "../Models/ConfirmationModal";
import { isEmpty } from "lodash";
import { toast } from "react-toastify";
import { LoaderModal } from "../Models/LoaderModal";


export function EMateraiUpload() {
  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [taskDetails, setTaskDetails] = useState();
  const [taskDocument, setTaskDocument] = useState();
  const [ref, bounds] = useMeasure({ scroll: true, polyfill: ResizeObserver });
  const [xy, setXY] = useState([0, 0]);
  const [hovered, setHover] = useState(false);
  const [result, setResult] = useState([]);
  const [positions, setPositions] = useState({});
  const [isEMateraiSelected, setIsEMateraiSelected] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const location = useNavigate();
  let user = JSON.parse(localStorage.getItem("user_data"));


  const generateKey = (pre) => {
    return `${pre}_${new Date().getTime()}`;
  };

  const handleSendButton = async () => {
    let body = { ...positions };
    setIsLoading(true);
    let response = await _asyncDoEmaterai(params?.id, body);
    setIsLoading(false);
    if (response.status === 200) {
      setShowSuccessModal(true);
      setTimeout(() => {
        setShowSuccessModal(false);
        if(taskDetails?.esign_type_id === 3){
          location("/farm-pipeline/e-sign-placing/"+params?.id);
        }else{
          location("/farm-pipeline/e-sign-dashboard");
        }
      }, 2000);
    }
  };

  const params = useParams();

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  useEffect(() => {
    _getTaskDetails(params?.id);
    _getDocument(params?.id);
  }, []);

  async function _getTaskDetails(id) {
    const data = await _asyncGetTaskDetails(id);
    if (!data) return;
    setTaskDetails(data);
  }

  function base64ToArrayBuffer(base64) {
    var binaryString = atob(base64);
    var bytes = new Uint8Array(binaryString.length);
    for (var i = 0; i < binaryString.length; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
  }

  async function _getDocument(id) {
    const response = await _asyncGetTaskDocument(id);
    const arr = base64ToArrayBuffer(response);
    setTaskDocument(arr);
  }
  console.log("position", positions);

  const handleResultClickUpdation = (e, pageNumber) => {
    setResult((prevResult) => {
      // Check if an entry with the same user_id and pageNumber already exists
      const exists = prevResult.some((entry) => entry.page === pageNumber);

      if (exists) {
        return prevResult; // If entry exists, return previous state
      }

      // If entry does not exist, add new entry
      return [
        ...prevResult,
        {
          id: generateKey(e.pageX),
          x: e.pageX,
          y: e.pageY,
          page: pageNumber,
        },
      ];
    });
  };

  const updatePosition = (page, newX, newY) => {
    setPositions((prevPositions) => {
      // Check if the page already exists in pagewise_positions
      if (prevPositions?.pagewise_positions?.[page]) {
        return prevPositions; // If page exists, return previous state without changes
      }

      // If page does not exist, update the state
      return {
        ...prevPositions,
        pagewise_positions: {
          ...prevPositions?.pagewise_positions,
          [page]: {
            x: newX,
            y: newY,
          },
        },
      };
    });
  };

  const handleUpdate = (pagenumber, x, y) => {
    updatePosition(pagenumber, x, y);
  };

  const deletePositionByPage = (page) => {
    setPositions((prevPositions) => {
      const newPagewisePositions = { ...prevPositions.pagewise_positions };
      delete newPagewisePositions[page];
      return {
        ...prevPositions,
        pagewise_positions: newPagewisePositions,
      };
    });
  };

  return (
    <div>
      <PageLayoutComp user={user}>
        <div className="h-[100%] w-full">
        {isLoading && 
         <LoaderModal/>
      }
          {showConfirmModal ? (
            <ConfirmationModal
              label={`Are you sure want to place e-Materai on this document ?`}
              label2="No"
              type="planting"
              customStyles={true}
              hideModal={() => setShowConfirmModal(false)}
              onConfirm={() => {
                setShowConfirmModal(false);
                handleSendButton();
              }}
            />
          ) : null}

          {showSuccessModal ? (
            <SuccessfullMesg label={`e-Materai has been placed`} />
          ) : null}

          <div className="h-10">
            <div className="flex justify-between">
              <p className="text-3xl font-bold mb-0">
                Administration - E-Signature
              </p>
            </div>
          </div>

          <div className="m-4   flex  justify-center rounded-lg overflow-hidden">
            <div className="pdf-viewer w-[80%]  bg-[#f9f9f9]  h-[100%] overflow-hidden">
              <div className="w-full flex gap-5 pt-2 pl-4 pb-4 items-center bg-white">
                <p className="">{taskDetails?.doc_name}</p>
                <div className="flex  items-center text-[#808080]">
                  <BsDot />
                  <p>{taskDetails?.total_pages} Pages</p>
                </div>
              </div>
              <div className="document rounded-xl w-[50%] ml-[25%] mr-[25%] my-0 items-center justify-center">
                <div
                  className="reference"
                  id="refid"
                  ref={ref}
                  onMouseEnter={() => setHover(true)}
                  onMouseLeave={() => setHover(false)}
                  onMouseMove={({ clientX, clientY }) =>
                    setXY([clientX, clientY])
                  }
                  onClick={(e) => {
                    handleResultClickUpdation(e, pageNumber);

                    handleUpdate(
                      pageNumber,
                      xy[0] - bounds.left,
                      bounds.bottom - xy[1]
                    );
                    console.log("actual", xy[0], xy[1]);
                    console.log(
                      "relative sign: ",
                      xy[0] - bounds.left,
                      bounds.bottom-xy[1] 
                    );
                  }}
                >
                  {result.map((res) => {
                    let isShowing = "hidden";
                    if (res.page === pageNumber) {
                      isShowing = "visible";
                    }
                    return (
                      <div
                        className="border-2 z-10 flex w-fit flex-col items-center relative justify-center border-green  text-sm"
                        style={{
                          visibility: isShowing,
                          left: res.x + "px",
                          top: res.y -50 + "px",
                          zIndex: 10,
                          position: "absolute",
                        }}
                      >
                        {isEMateraiSelected && (
                          <MdDeleteForever
                            color={"#f58989"}
                            size={18}
                            className="z-10 cursor-pointer absolute top-[-10px] right-[-18px]"
                            onClick={(e) => {
                              e.stopPropagation();
                              deletePositionByPage(res?.page);
                              setResult((result) => {
                                return result.filter(
                                  (item) => item.id !== res.id
                                );
                              });
                            }}
                          />
                        )}

                        <img src={EMateraiPic} className="w-16"></img>
                      </div>
                    );
                  })}
                  {isEMateraiSelected && (
                    <MouseTooltip
                      className="text-sm px-2 bg-[#ECF7ED] rounded-md"
                      visible={hovered}
                      offsetX={-60}
                      offsetY={-20}
                      style={{ "z-index": "10" }}
                    >
                      <span className="text-xs">
                        <span>📝</span> Click Here for e-Materai
                      </span>
                    </MouseTooltip>
                  )}

                  <Document
                    file={taskDocument}
                    onLoadSuccess={onDocumentLoadSuccess}
                  >
                    <Page
                      size={"A4"}
                      pageNumber={pageNumber}
                      renderAnnotationLayer={false}
                      renderTextLayer={false}
                    />
                  </Document>
                </div>
              </div>
              <div className=" mt-2 p-3 flex items-center justify-center">
                <div className="">
                  <nav aria-label="Page navigation example ">
                    <ul class="flex items-center -space-x-px h-8 text-sm">
                      <li>
                        <button
                          onClick={() => {
                            if (pageNumber > 1) {
                              setPageNumber((prev) => --prev);
                            }
                          }}
                          class="flex items-center justify-center px-3 h-8 ms-0 leading-tight text-gray-500 bg-[#E6F1ED] border border-e-0 border-gray-300 rounded-s-lg hover:bg-gray-100  "
                        >
                          <span class="sr-only">Previous</span>
                          <svg
                            class="w-2.5 h-2.5 rtl:rotate-180"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 6 10"
                          >
                            <path
                              stroke="currentColor"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M5 1 1 5l4 4"
                            />
                          </svg>
                        </button>
                      </li>

                      <li>
                        <p
                          aria-current="page"
                          class="z-10 flex items-center justify-center p-2 h-8 leading-tight text-white border border-green-300 bg-green "
                        >
                          {pageNumber} <span className=" ml-2 mr-2"> of </span>{" "}
                          {numPages}
                        </p>
                      </li>

                      <li>
                        <button
                          onClick={() => {
                            if (pageNumber < numPages) {
                              setPageNumber((prev) => prev + 1);
                            }
                          }}
                          class="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-[#E6F1ED] border border-gray-300 rounded-e-lg"
                        >
                          <span class="sr-only">Next</span>
                          <svg
                            class="w-2.5 h-2.5 rtl:rotate-180"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 6 10"
                          >
                            <path
                              stroke="currentColor"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="m1 9 4-4-4-4"
                            />
                          </svg>
                        </button>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
            <div className=" side-panel w-[20%]  bg-white  flex flex-col justify-between">
              <div>
                <p className="font-semibold p-2 pb-4">Digital Signature</p>
                <div className="w-full p-2 bg-[#F1F1F1]">e-Materai</div>
                <div onClick={() => setIsEMateraiSelected(!isEMateraiSelected)}>
                  {isEMateraiSelected ? (
                    <img
                      className={`rounded-md w-24 mx-4 bg-[#9FD7A4] mt-2 mb-2 p-1`}
                      src={EMateraiPic}
                    ></img>
                  ) : (
                    <img
                      className={` rounded-md w-24 mx-4 mt-2 mb-2 p-1`}
                      src={EMateraiPicGrey}
                    ></img>
                  )}
                </div>

                <div className="w-full mt-4 p-[0.7px] bg-slate-300"> </div>
                <p className="text-xs text-[#252525] p-2">
                  *Select to place e-Materai on document.
                </p>
              </div>
              <div
                onClick={() => {
                  if (
                    isEmpty(positions) ||
                    isEmpty(positions.pagewise_positions)
                  ) {
                    toast.info("Please place e-Materai on document", {
                      autoClose: 2000,
                    });
                    return;
                  } else {
                    setShowConfirmModal(true);
                  }
                }}
                className="flex bg-green100 items-center mb-5 text-white p-2 m-2 justify-center rounded-md cursor-pointer"
              >
                <p className="text-sm">Send</p>
              </div>
            </div>
          </div>
        </div>
      </PageLayoutComp>
    </div>
  );
}

export function blobToURL(blob) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = function () {
      const base64data = reader.result;
      resolve(base64data);
    };
  });
}
