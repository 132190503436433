import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import calendarImg from "../images/FarmPipeLine/calendar.png";

export const DatePickerRangeComp = ({
  onSelected,
  dateRange,
  pastOnly = false,
}) => {
  let dt = new Date();
  dt.setDate(dt.getDate());

  const [startDate, endDate] = dateRange;
  return (
    <>
      <div className="flex w-full h-[45px] items-center px-2 border border-gray-300 rounded-lg">
        <DatePicker
          className="outline-none border-none w-full"
          selectsRange={true}
          startDate={startDate}
          endDate={endDate}
          onChange={(update) => {
            onSelected(update);
          }}
          placeholderText="Select date"
          isClearable={true}
          maxDate={pastOnly ? dt : undefined}
        />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="#808080"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5"
          />
        </svg>
      </div>
    </>
  );
};
