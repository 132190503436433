import beleaf_logo from "../../images/FarmPipeLine/beleaf_logo.png";
import login_screen from "../../images/login_screen.png";
import { useNavigate } from "react-router-dom";
import { useRef, useState } from "react";
import { _asyncSendOtp, _asyncVerifyGuestOtp, _login } from "../../api";
import { AppSpinner } from "../../Models/AppSpinner";
import { GoogleLogin } from "@react-oauth/google";
import { EmailOtpModal } from "./EmailOtpModal";
import { toast } from "react-toastify";


function LoginPage() {
  const location = useNavigate();
  const [loading, setLoading] = useState(false);
  const [errMesg, setErrMesg] = useState("");
  const [isGuestButtonVisible, setIsGuestButtonVisible] = useState(true);
  const [email, setEmail] = useState('')
  const [inputValues, setInputValues] = useState(Array(6).fill(""))
  const [otp, setOtp] = useState([])
  const inputRefs = useRef([]);

const handleInputChange = (index, event) => {
  const newValues = [...inputValues];
  newValues[index] = event.target.value;
  setInputValues(newValues);
  const inputLength = event.target.value.length;
  if (inputLength === 1 && index < inputRefs.current.length - 1) {
      inputRefs.current[index + 1].focus();
  } else if (inputLength === 0 && index > 0) {
      inputRefs.current[index - 1].focus();
  }
  const concatenatedString = newValues.join('');
  setOtp(concatenatedString);
};


const handleSubmit = async () => {
  const verifyData = await _asyncVerifyGuestOtp({ email, otp });
  if (verifyData) {
    toast.success("Successfully Logged In");
    setShowOTPModal(false);
    localStorage.setItem("user_data", JSON.stringify(verifyData));
    location("/planting");
  }
};

  const handleGuestLoginClick = () => {
    setIsGuestButtonVisible(false);
  };
const [showOTPModal, setShowOTPModal] = useState(false)
  const emailRegex = /^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,6}$/;

  async function _sendOtp() {
    if (!email) {
      toast.error("Please enter email.");
      return
    }
    try {
      
      if (emailRegex.test(email)) {
        setLoading(true)
        const data = await _asyncSendOtp({email}); 
        setLoading(false)
        if (data) {
          setShowOTPModal(true)
        }
      }else{
        toast.error("Please enter a valid email.");
      }
    } catch (error) {
     console.log(error,'----------------------------->'); 
    }
  }
const requestAgain = async() => {
  setLoading(true)
  const data = await _asyncSendOtp({email});
  setLoading(false)
}
  const login = async (credentialResponse) => {
    setLoading(true);
    let data = {
      g_token: credentialResponse.credential,
    };
    let err = await _login(data, location);
    setErrMesg(err);
    setLoading(false);
  };

  return (
    <>
      <AppSpinner visible={loading} />
      {showOTPModal && <EmailOtpModal hideModal={()=>setShowOTPModal(false)}
            inputRefs={inputRefs}
            handleInputChange={handleInputChange}
            onConfirm={handleSubmit}
            label4={email}
            requestAgain={requestAgain}
            />}
      <div className="flex h-screen w-screen">
        <div className="w-[22%]">
          <div className="flex h-full justify-center items-center">
            <div className=" px-8">
              <div className="flex justify-center mb-32">
                <img src={beleaf_logo} width="150px" height="150px"></img>
              </div>
              <div className="self-center">
                <div className="text-center text-[24px] font-bold mb-3">
                  Login into BeleafOS
                </div>
                <div className="text-center text-[16px] text-[#808080]">
                  Continue using your google account (Using @beleaf.co.id
                  domain){" "}
                </div>
                <div className="flex mt-10 justify-center">
                  <GoogleLogin
                    onSuccess={(credentialResponse) =>
                      login(credentialResponse)
                    }
                    onError={(err) => {
                      console.log("Login Failed", err);
                    }}
                  />
                </div>
                <div className="flex mt-5 justify-center">
                  {isGuestButtonVisible ? (
                    <button
                      onClick={handleGuestLoginClick}
                      className="border border-green rounded-md w-[65%] p-[7px] text-green"
                    >
                      Login as a guest
                    </button>
                  ) : (
                    <div className="w-[65%] flex flex-col items-center">
                      <input
                        type="text"
                        placeholder="Enter your E-mail"
                        // value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="border border-gray-300 rounded-md w-full p-[7px] mb-2"
                      />
                      <button
                        className="border border-gray-300 bg-green text-white rounded-md w-full p-[7px]"
                        onClick={() => _sendOtp()}
                      >
                        Login
                      </button>
                    </div>
                  )}
                </div>
                <h5 className="text-center text-[16px] text-[#F491A3]">
                  {errMesg}
                </h5>
              </div>
            </div>
          </div>
        </div>
        <div
          className="w-[78%] h-full bg-cover"
          style={{
            backgroundImage: `url("${login_screen}")`,
          }}
        ></div>
      </div>
    </>
  );
}

export default LoginPage;
