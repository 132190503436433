import React, { useState } from "react";
import { useEffect } from "react";
import { PageLayoutComp } from "./PageLayoutComp";
import DropDownComp from "./DropDownComp";
import { HiDownload } from "react-icons/hi";
import { ConfirmationModal } from "../Models/ConfirmationModal";
import { SuccessfullMesg } from "../Models/SuccessfullMesg";
import {
  _asyncCreateWeatherReport,
  _asyncGetBOMWeatherReport,
  _asyncGetWeatherReportRequest,
  _fetchMetaData,
} from "../api";
import { useParams } from "react-router-dom";
import { AppSpinner } from "../Models/AppSpinner";

export const WeatherStationReport = () => {
  const params = useParams() || {};
  // alert(params?.id)
  let user = JSON.parse(localStorage.getItem("user_data"));
  const [dataFrequency, setDataFrequency] = useState({ name: "Hourly" });
  const [month, setMonth] = useState({ name: "January" });
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [report, setReport] = useState([]);
  console.log(' data report ----->>>>',report);
  const [loading, setLoading] = useState(false);
  const [checkboxes, setCheckboxes] = useState([])

  const [checkboxState, setCheckboxState] = useState(
    checkboxes.reduce((state, checkbox) => {
      state[checkbox] = false;
      return state;
    }, {})
  );
  const handleCheckboxChange = (checkbox) => {
    setCheckboxState((prevState) => ({
      ...prevState,
      [checkbox]: !prevState[checkbox],
    }));
  };
  useEffect(() => {
    _fetchWeatherReport();
    _fetchData()
    // handleRequestData()
  }, []);

  async function _fetchData() {
    let metaData = await _fetchMetaData();
    setCheckboxes(metaData?.weatherReportParameters)
  }

  const _fetchWeatherReport = async () => {
    let Data = await _asyncGetWeatherReportRequest(params?.id);
    setReport(Data);
  };
  const _fetchWeatherReportDownload = async (docInfo) => {
    let res = await _asyncGetBOMWeatherReport(docInfo?.id);
    if (!res) return
    const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${res}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = 'Weather Station Report_' + docInfo?.request_data?.frequency + '_' + docInfo?.request_data?.month + '_'+Date.now();
    downloadLink.click();
  };

  const handleRequestData = async () => {
    const parameters = Object.keys(checkboxState)
      .map((key) => key)
      .filter((key) => checkboxState[key]);
    const body = {
      frequency: dataFrequency?.name,
      month: month?.name,
      parameters: parameters,
    };
    setLoading(true);
    let response = await _asyncCreateWeatherReport(params?.id, body);
    setLoading(false);
    if (response) {
      _fetchWeatherReport();
      setShowSuccessModal(true);
    }
  };

  const handleOnClick = () => {
    setShowConfirmModal(true);
  };

  const handleDownload = (event, url = "https://via.placeholder.com/150") => {
    event.preventDefault();

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "placeholder-image.jpg");
    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  };

  return (
    <div className="w-screen h-screen">
      <AppSpinner visible={loading} />
      <PageLayoutComp user={user}>
        <div className="h-[100%]">
          {showSuccessModal ? (
            <SuccessfullMesg label={`Successfully Requested`} />
          ) : null}

          {showConfirmModal ? (
            <ConfirmationModal
              label={`Are you sure you want to request for this data?`}
              label2="No"
              type="planting"
              customStyles={true}
              hideModal={() => setShowConfirmModal(false)}
              onConfirm={() => {
                handleRequestData();
                setShowConfirmModal(false);
                setTimeout(() => {
                  setShowSuccessModal(false);
                }, 2000);
              }}
            />
          ) : null}
          <div className="h-[10%]">
            <div className="flex justify-between">
              <p className="text-3xl font-bold">Weather Station - Get Report</p>
            </div>
          </div>
          <div className="h-[90%] accent-green">
            <div className="h-full bg-white">
              <div className="report-page h-[100%] flex">
                <div className="left-section flex flex-col justify-between flex-[50%] ">
                  <div className="m-6 ml-12 text-2xl">
                    Weather Station Semplak
                  </div>
                  <div className="w-full h-full mt-10 justify-center gap-10 flex ">
                    <div className="parameters ">
                      <p className="pb-4 text-xl text-left">Parameter</p>
                      {checkboxes.map((item, index) => {
                        return (
                          <div className="checkbox">
                            <input
                              className="relative float-left -ml-[1.5rem] mr-[6px] mt-[0.15rem] h-[1.125rem] w-[1.125rem] appearance-none rounded-[0.25rem] border-[0.125rem] border-solid border-neutral-300 outline-none before:pointer-events-none before:absolute before:h-[0.875rem] before:w-[0.875rem] before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] checked:border-green checked:bg-green checked:before:opacity-[0.16] checked:after:absolute checked:after:-mt-px checked:after:ml-[0.25rem] checked:after:block checked:after:h-[0.8125rem] checked:after:w-[0.375rem] checked:after:rotate-45 checked:after:border-[0.125rem] checked:after:border-l-0 checked:after:border-t-0 checked:after:border-solid checked:after:border-white checked:after:bg-transparent checked:after:content-[''] hover:cursor-pointer hover:before:opacity-[0.04] hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:shadow-none f   focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-[0.875rem] focus:after:w-[0.875rem] focus:after:rounded-[0.125rem] focus:after:content-[''] checked:focus:before:scale-100 checked:focus:before:shadow-[0px_0px_0px_13px_#3bca8a] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] checked:focus:after:-mt-px checked:focus:after:ml-[0.25rem] checked:focus:after:h-[0.8125rem] checked:focus:after:w-[0.375rem] checked:focus:after:rotate-45 checked:focus:after:rounded-none checked:focus:after:border-[0.125rem] checked:focus:after:border-l-0 checked:focus:after:border-t-0 checked:focus:after:border-solid checked:focus:after:border-white "
                              type="checkbox"
                              checked={checkboxState[item]}
                              onChange={() => handleCheckboxChange(item)}
                            />
                            <label
                              className="inline-block pl-[0.15rem] text-md hover:cursor-pointer font-poppins"
                              htmlFor="checkboxDefault"
                            >
                              {item}
                            </label>
                          </div>
                        );
                      })}
                    </div>
                    <div className="dropdowns text-xl">
                      <p>Data Frequency</p>
                      <DropDownComp
                        listData={[{ name: "Hourly" }, { name: "Daily" }]}
                        active={dataFrequency}
                        onSelected={(item) => {
                          setDataFrequency(item);
                        }}
                        type={"name"}
                        customStyles={
                          "rounded-md justify-between my-4 text-[#3E4347] text-[14px]"
                        }
                        customMenuStyle={
                          "relative inline-block text-left w-full"
                        }
                      />

                      <p className="mt-2">Month</p>
                      <DropDownComp
                        listData={[
                          { name: "January" },
                          { name: "February" },
                          { name: "March" },
                          { name: "April" },
                          { name: "May" },
                          { name: "June" },
                          { name: "July" },
                          { name: "August" },
                          { name: "September" },
                          { name: "October" },
                          { name: "November" },
                          { name: "December" },
                        ]}
                        active={month}
                        onSelected={(item) => {
                          setMonth(item);
                        }}
                        type={"name"}
                        customStyles={
                          "rounded-md justify-between my-4 text-black text-[14px]"
                        }
                        customMenuStyle={
                          "relative inline-block text-left  text-black w-full"
                        }
                      />
                    </div>
                  </div>
                  <div className="w-full">
                    <div
                      onClick={handleOnClick}
                      className="bg-[#3EB049] float-right w-fit text-xs text-white m-2 px-3 py-3 border-[#3EB049] rounded-xl cursor-pointer"
                    >
                      Request Data
                    </div>
                  </div>
                </div>
                <div className="right-section flex-[50%] flex flex-col w-full h-full bg-[#F9F9F9]">
                  <div className="text-xl">
                    <p className="p-5"> Request Status </p>
                  </div>
                  <div className="relative overflow-x-auto shadow-md mx-4 sm:rounded-lg">
                    <table class="w-full text-sm text-left rtl:text-right">
                      <thead class="text-xs text-white uppercase bg-green">
                        <tr>
                          <th scope="col" class="px-3 py-3">
                            #
                          </th>
                          <th scope="col" class="px-3 py-3">
                            Parameter
                          </th>
                          <th scope="col" class="px-3 py-3">
                            Data Frequency
                          </th>
                          <th scope="col" class="px-3 py-3">
                            Month
                          </th>
                          <th scope="col" class="px-3 py-3">
                            Status
                          </th>
                          <th scope="col" class="px-3 py-3">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody className="text-xs">
                        {report?.data &&
                          report?.data?.map((obj, i) => {
                            return (
                              <tr className="">
                                <td className="text-center ">{i + 1}.</td>
                                <td className="">
                                  {obj?.request_data?.parameters?.length > 0
                                    ? obj?.request_data?.parameters.join(", ")
                                    : "-"}
                                </td>
                                <td>{obj?.request_data?.frequency || "-"}</td>
                                <td>{obj?.request_data?.month || "-"}</td>
                                { obj?.is_failed == true ?
                                 <td>{obj?.faliure_details || "-"}</td>
                                : <td>{obj?.status?.name || "-"}</td>
                              }
                                <td align="center" className="cursor-pointer">
                                  {" "}
                                  {/* {obj?.status?.id == 1 &&   <a href={'https://via.placeholder.com/150'} download onClick={(e) => handleDownload(e, 'https://via.placeholder.com/150')}>

                                  <HiDownload/> </a> } */}
                                  {obj?.status?.id == 3 && <HiDownload 
                                  onClick={()=>_fetchWeatherReportDownload(obj)}/>}
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageLayoutComp>
    </div>
  );
};
