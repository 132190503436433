import React, { useEffect, useState } from "react";
import {_fetchMetaData } from "../api";
import { InventoryLists } from "../config/tables/InventoryList";

export const InventoryList = () => {
  let user = JSON.parse(localStorage.getItem("user_data"));
  const [selectedFilter, setSelectedFilter] = useState({id:'', status_name:'All'});
  const [metaData, setMetaData] = useState();

  useEffect(() => {
    _fetchData()
  }, []);

  async function _fetchData() {
    let metaData = await _fetchMetaData();
    setMetaData(metaData);
    
  }

  return (
    <div className="h-[80%]">
      <InventoryLists
        metaData={metaData}
        selectedFilter={selectedFilter}
        />
    </div>
  );
};
