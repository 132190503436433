import React from "react";
import Success from "../images/Success.png";

export const SuccessBOMModal = () => {
  
  let mainDiv = "w-[100%] px-5 bg-white";

  return (
    <>
      <div className="flex overflow-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center">
        <div className="flex justify-center ml-[20%]">
          {/*content*/}
          <div
            className={`h-full bg-white w-[85%] first-letter:rounded-lg shadow-lg relative flex flex-col ${mainDiv} outline-none focus:outline-none  rounded-lg`}
          >
            <div className="relative pl-6 pr-6 pt-3  ">
              <p className="text-[15px] text-center">New BOM successfully saved</p>
            </div>

            <div className="flex justify-center items-center py-6 rounded-b-lg bg-white w-full">
            <img
                      src={Success}
                    //   alt="checking"
                      className="w-15 h-12 "
                    />
              <div />
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>{" "}
    </>
  );
};
