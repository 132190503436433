export const  MERCHANDISE_TRACKER_TB_COL = [
    {
      Header: "PRODUCT INFO",
      accessor: "product_into",
    },
    {
      Header: "STOCK",
      accessor: "stock",
    },
    {
      Header: "MERCHANDISER",
      accessor: "merchandiser",
    },
    {
      Header: "LAST UPDATE",
      accessor: "last_update",
    }
  ];