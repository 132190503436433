import React, { useEffect, useState } from "react";
import { PageLayoutComp } from "../../../components/PageLayoutComp";
import { _fetchMetaData } from "../../../api";
import check_fill from "../../../../src/images/check_fill.png";
import mobile_key from "../../../../src/images/mobile_key.png";
import CameraIcon from "../../../../src/images/CameraIcon.png";
import id_card from "../../../../src/images/id_card.png";
import time_line from "../../../../src/images/time_line.png";
import { useNavigate } from "react-router-dom";


export const ESignatureHomePage = () => {
  const location = useNavigate();
    const [isWaiting, setIsWaiting] = useState(true)
    const [metaData, setMetaData] = useState();
   
    const [isComplete, setIsComplete] = useState(false)
    let user = JSON.parse(localStorage.getItem("user_data"));
 
    const routeChange = () => {
      location("/farm-pipeline/e-sign-phone-verification");
    };

    const handleBackButton = ()=> {
      location("/farm-pipeline/e-sign-dashboard")
    }
   
  return (
    <div className="w-screen h-screen">
         <PageLayoutComp user={user}>

               <div className="h-[7%]">
            <div className="flex justify-between">
              <p className="text-3xl font-bold mb-0">
              Administration - E Signature
                </p>
                
            </div>
           
          </div>
          <div className="bg-gray-200 w-[100%]  rounded-lg" >

               
                <div className="w-[100%] h-[80vh] flex flex-row ">
            <div className="w-[50%] h-[80vh]  justify-center p-[100px] pt-[180px]">
                <p className=" font-bold text-[18px]">Please Verify Your Identity to</p>
                <p className="font-bold text-[18px]"> Unlock Digital Signatures </p>
                <p className="text-[18px] pt-[20px]" >Please have the following ready and </p>
                 <p className=" text-[18px]">start your authentication!</p>
                 <div className="w-[100%] mt-[40px] h-[22vh] flex flex-row">
                  <div className="bg-white w-[30%]  rounded-lg h-[22vh] p-[4px] " >
                  <img
                      src={check_fill}
                    //   alt="checking"
                      className="w-5 h-5 ml-[100px]"
                    />
                     <img
                      src={mobile_key}
                    //   alt="checking"
                      className="w-12 h-12 ml-[40px] mt-[10px]"
                    />
                    <p className="text-[13px] mt-[9px] font-bold text-center">
                        Access to mobile device for receiving OTP
                    </p>
                  </div>
                  <div className="bg-white w-[30%]  rounded-lg h-[22vh] p-[4px] ml-[12px]" >
                  <img
                      src={check_fill}
                    //   alt="checking"
                      className="w-5 h-5 ml-[100px]"
                    />
                     <img
                      src={id_card}
                    //   alt="checking"
                      className="w-12 h-12 ml-[40px] mt-[10px]"
                    />
                    <p className="text-[13px] mt-[9px] font-bold text-center">
                        Access to your ID card
                    </p>
                  </div>
                  <div className="bg-white w-[30%]  rounded-lg h-[22vh] p-[4px] ml-[12px]" >
                  <img
                      src={check_fill}
                    //   alt="checking"
                      className="w-5 h-5 ml-[100px]"
                    />
                     <img
                      src={CameraIcon}
                    //   alt="checking"
                      className="w-12 h-12 ml-[40px] mt-[10px]"
                    />
                    <p className="text-[13px] mt-[9px] font-bold text-center">
                        Device with camera
                    </p>
                  </div>
                 </div>
               
            </div>
            <div className="w-[50%] bg-white h-[80vh] rounded-lg p-[150px] pt-[90px]">

          <div className="flex">
          <div>
                <p className="text-green font-bold text-[55px]">1</p>
                <p className="text-green text-[30px] ml-[5px]">|</p>
                <p className="text-green font-bold text-[55px]">2</p>
                <p className="text-green text-[30px] ml-[5px]">|</p>
                <p className="text-green font-bold text-[55px]">3</p>
             </div>
             <div className=" pl-[50px]">
                <div className="mt-[19px]">
                <p className="font-bold text-[15px]">Phone Number Verification</p>
                <p className=" text-[14px]">You will be asked to verify your </p>
                <p className=" text-[14px]">phone number before continue</p>
                <div className="flex">
                <img
                      src={time_line}
                    //   alt="checking"
                      className="w-5 h-5"
                    />
                    <p className="ml-[10px] text-[14px] text-gray-400">1 minute</p>
                </div>
                </div>
                <div className="mt-[19px]">
                <p className="font-bold text-[15px]">Id Info Validation</p>
                <p className=" text-[14px]">You will be asked to enter your legal  </p>
                <p className=" text-[14px]">name, NIK, date of birth</p>
                <div className="flex">
                <img
                      src={time_line}
                    //   alt="checking"
                      className="w-5 h-5 "
                    />
                    <p className="ml-[10px] text-[14px] text-gray-400">1 minute</p>
                </div>
                </div>
                <div className="mt-[19px]">
                <p className="font-bold text-[15px]">Face Authentication</p>
                <p className=" text-[14px]">You will be asked to look into the camera</p>
                <p className=" text-[14px]">frame and capture a selfie</p>
                <div className="flex">
                <img
                      src={time_line}
                    //   alt="checking"
                      className="w-5 h-5"
                    />
                    <p className="ml-[10px] text-[14px] text-gray-400">1 minute</p>
                </div>
                </div>
             </div>
          </div>
          <div className="bg-green100 text-center  text-white p-2 w-[90%] pr-3 mt-[30px] rounded-md cursor-pointer"
                 onClick={()=>routeChange()}
                 >
                    <p className="text-sm">Verify for Digital Signature</p>
                  </div>
                  <div className="border border-[#40AE49] text-[#40AE49] border-[1px] text-center  text-white p-2 w-[90%] pr-3 mt-[15px] rounded-md cursor-pointer"
                  >
                    <p onClick={handleBackButton} className=" text-sm">Back</p>
                  </div>
            </div>
      
                </div>

            
             
             
          </div>


         </PageLayoutComp>
    </div>
  );
};
