import React, { useEffect, useState } from "react";
import { PRODUCT_TB_COL } from "..";
import { _asyncGetOdooProductList } from "../../../api";
import { TableSearchComp } from "../../../components/TableSearchComp";
import { TablePaginationComp } from "../../../components/TablePaginationComp";
import { BsFillCaretDownFill, BsArchiveFill, BsArchive } from "react-icons/bs";
import { toast } from "react-toastify";
import { FiltersModal } from "../../../Models/FiltersModal";
import unavailable from "../../../images/unavailable.png";

export const TableProducts = ({ metaData, token, refreshTab }) => {
  const size = 10;
  let colums = PRODUCT_TB_COL;
  const configFetchRef = React.useRef(false);
  const [totalPage, setTotalPage] = useState();
  const [totalItems, setTotalItems] = useState();
  const [selectedPage, setSelectedPage] = useState(0);
  const [tableDataList, setTableDataList] = useState([]);
  const [allProductData, setAllProductData] = useState([]);
  const [allSelectedForArchive, updateAllSelected] = useState(false);
  const [someSelectedForArchive, updateSomeSelected] = useState(false);
  const [isViewEditModalOpened, setViewEditModalOpen] = useState(false);
  const [selectedProductDetails, setSelectedProductDetails] = useState(null);
  const [showFilter, setShowFilter] = useState(false);
  const [action, setAction] = useState("");
  const [filterData, setFilterData] = useState({});
  const [searchText, setSearchText] = useState("")

  useEffect(() => {
    if (configFetchRef.current) return;
    configFetchRef.current = true;
    _fetchProducts(selectedPage);
  }, []);

  useEffect(() => {
    _fetchProducts(selectedPage);
  }, [refreshTab]);

  function _getMaturity(id) {
    let mat = "";
    mat = metaData?.cropMaturities.filter((e) => e.id === id);
    return mat && mat[0].maturity_type;
  }


  useEffect(() => {
    _fetchProducts(selectedPage)
  }, [action]);

  function _getCategory(id) {
    let cat = "";
    cat = metaData?.cropCategories.filter((e) => e.id === id);
    return cat && cat[0]?.category_name;
  }

  useEffect(() => {
    const allSelected =
      tableDataList && tableDataList.length
        ? tableDataList.every((ele) => ele.is_product_archived === true)
        : false;
    const allUnSelected =
      allSelected === false
        ? tableDataList && tableDataList.length
          ? tableDataList.every((ele) => ele.is_product_archived === false)
          : true
        : false;
    updateAllSelected(allSelected);
    updateSomeSelected(!allUnSelected);
  }, [tableDataList]);

  async function _fetchProducts(page) {
    let params = {
      size: size,
      page: page + 1
    }
    if (searchText) {
      params['product_name'] = searchText;
    }
    const res = await _asyncGetOdooProductList(params);
    if (res.data && res.data.data && res.data.data.data) {
      const displayData = res.data.data.data;
      setTableDataList(
        displayData.map((d) => {
          d.is_product_archived = false;
          return d;
        })
      );
      setAllProductData(
        displayData.map((d) => {
          d.is_product_archived = false;
          return d;
        })
      );
      setTotalPage(res.data.data.last_page);
      setTotalItems(res.data.data.totalItems);
    }
  }

  const confirmBeforeArchive = async () => {
    //TODO : write confirmation code here...
    console.log(`archive button clicked`);
    alert("We are currently working on this functionality...");
    // await doArchive();
  };

  function openEditProductInfoModal(selectedProductData) {
    setSelectedProductDetails(selectedProductData);
    setViewEditModalOpen(true);
  }

  const handleSearchSubmit = (action) => {
    setAction(action)
    setSelectedPage(0)
    if (action === "reset") {
      setSearchText("");
      _fetchProducts(0)
    }
    if (searchText.length > 2 && action !== "reset") {
      _fetchProducts(0)
    }
  }

  // function _filterData(text) {
  //   let newArray = allProductData.filter((item) =>
  //     item.product_name.toLowerCase().includes(text.toLowerCase())
  //   );
  //   setTableDataList(newArray);
  // }

  return (
    <div className="h-[100%] mt-4">
      {/* {isViewEditModalOpened === true && selectedProductDetails !== null ? (
        <EditProductModal
          hideModal={() => setViewEditModalOpen(false)}
          selectedProductData={selectedProductDetails}
          metaData={metaData}
          visible={isViewEditModalOpened}
        />
      ) : null} */}

      {showFilter ? (
        <FiltersModal
          hideModal={() => setViewEditModalOpen(false)}
          metaData={metaData}
        />
      ) : null}

      <div className="h-[7%]">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {/* <input
            type="checkbox"
            checked={allSelectedForArchive}
            className="mt-4"
            onChange={() => {
              if (tableDataList && tableDataList.length)
                setTableDataList(
                  tableDataList.map((tData) => {
                    tData.is_product_archived = !allSelectedForArchive;
                    return tData;
                  })
                );
            }}
          ></input> */}
          {/* <div className="ml-5 mt-4">
            {someSelectedForArchive === true ? (
              <BsArchiveFill
                size={15}
                onClick={() => {
                  confirmBeforeArchive();
                }}
              />
            ) : (
              <BsArchive size={15} />
            )}
          </div> */}
          <div className="w-[100%]">
            <TableSearchComp
              handleSearchSubmit={handleSearchSubmit}
              onChange={(text) => setSearchText(text)}
              showFilterBtn={false}
              searchText={searchText}
              showTypeDropDown={false}
            />
          </div>
        </div>
      </div>
      <div className="h-[80%] overflow-auto scrollbar-hide mt-5">
        <table className="w-full">
          <thead>
            <tr className="sticky top-0">
              {/* <td></td> */}
              {colums.map((col, i) => {
                if (col.Header === "ID") {
                  return <td key={`tfhk-${i}`}></td>;
                }
                return (
                  <td
                    className="text-sm text-center p-[0px] m-[0px] bg-white"
                    key={`tfhk-${i}`}
                  >
                    <div className="flex justify-center items-center py-2 border-gray-200 border-b-[1px]">
                      <p className="text-sm text-[#3EB049]">{col.Header}</p>
                      {/* {col.Header !== "FARM ID" ? (
                        <BsFillCaretDownFill className="ml-2" size={10} />
                      ) : null} */}
                    </div>
                  </td>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {tableDataList.map((data, i) => {
              return (
                <tr key={`row-${i}`} className="border-gray-200 border-b-[1px]">
                  {/* <td>
                    <input
                      type="checkbox"
                      checked={data.is_product_archived}
                      onChange={() => {
                        const updatedData = [...tableDataList];
                        updatedData[i].is_product_archived =
                          !updatedData[i].is_product_archived;
                        setTableDataList(updatedData);
                      }}
                    ></input>
                  </td> */}
                  {colums.map((col, i) => {
                    if (col.Header === "PRODUCT CODE") {
                      return (
                        <td
                          key={`tbfc-${i}`}
                          className="py-2"
                          onClick={() => openEditProductInfoModal(data)}
                        >
                          <p className="text-sm text-[#0090FF] underline cursor-pointer text-center">
                            {data[col.accessor]}
                          </p>
                        </td>
                      );
                    } else if (col.Header === "PRODUCT IMAGE") {
                      return (
                        <td
                          key={`tbfc-${i}`}
                          className="py-2 flex justify-center"
                          onClick={() => openEditProductInfoModal(data)}
                        >
                          <img
                            src={data[col.accessor] || unavailable}
                            className="w-[50px] h-[50px] text-sm text-[#0090FF] underline cursor-pointer text-center"
                          />
                        </td>
                      );
                    } else if (col.Header === "CATEGORY") {
                      return (
                        <td key={`tbfc-${i}`} className="py-2">
                          <p className="text-sm text-center">
                            {data[col.accessor] === null
                              ? "-"
                              : _getCategory(data[col.accessor])}
                          </p>
                        </td>
                      );
                    } else if (col.Header === "MATURITY") {
                      return (
                        <td key={`tbfc-${i}`} className="py-2">
                          <p className="text-sm text-center">
                            {data[col.accessor] === null
                              ? "-"
                              : _getMaturity(data[col.accessor])}
                          </p>
                        </td>
                      );
                    } else if (col.Header === "VARIANT") {
                      return (
                        <td key={`tbfc-${i}`} className="py-2">
                          <p className="text-sm text-center">
                            {data[col.accessor] === null
                              ? "-"
                              : data[col.accessor]}
                          </p>
                        </td>
                      );
                    } else if (col.Header === "PRODUCT") {
                      return (
                        <td key={`tbfc-${i}`} className="py-2">
                          <p className="text-sm text-center">
                            {data[col.accessor] === null
                              ? "-"
                              : data[col.accessor]}
                          </p>
                        </td>
                      );
                    } else {
                      return (
                        <td key={`tbfc-${i}`} className="py-2">
                          <p
                            title={
                              data[col.accessor] == null
                                ? ""
                                : data[col.accessor].toString()
                            }
                            className="text-sm text-center"
                          >
                            {data[col.accessor] == null
                              ? ""
                              : data[col.accessor].toString()}
                          </p>
                        </td>
                      );
                    }
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="flex justify-end items-center h-[10%] text-sm px-5">
        {totalPage !== undefined ? (
          <TablePaginationComp
            totalPages={totalPage}
            active={selectedPage}
            selectedPage={(p) => {
              setSelectedPage(p);
              _fetchProducts(p);
            }}
            totalItems={totalItems}
          />
        ) : null}
      </div>
    </div>
  );
};
