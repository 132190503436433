import React, { useState, useEffect } from "react";
import { BsPlus } from "react-icons/bs";
import { TablePlants } from "../../config/tables/PlantingTable";
import { AddNewPlantModal } from "../../Models/AddNewPlantModal";
import { _apiLogout, _fetchMetaData } from "../../api";
import { SuccessfullMesg } from "../../Models/SuccessfullMesg";
import { PageLayoutComp } from "../../components/PageLayoutComp";

export const PlantingList = () => {

  let user = JSON.parse(localStorage.getItem("user_data"));
  const [refreshTab, setRefreshTab] = useState(false);
  const [showAddNewPlanting, setShowAddNewPlanting] = useState(false);
  const [metaData, setMetaData] = useState();
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  useEffect(() => {
   _fetchData();
  }, []);


  async function _fetchData() {
    let metaData = await _fetchMetaData();
    setMetaData(metaData);
  }

  
  const hideModalHandle = (flag = false) => {
    setShowAddNewPlanting(false);
    setRefreshTab(!refreshTab);
    if (flag) {
      setShowSuccessModal(true);
      setTimeout(() => {
        window.location.reload();
      }, 600);
    }
  };

  function handleAddButton() {
    if (!user?.permissions?.create_planting_data) {
      alert("Don't have Create Permission");
      return;
    }
    setShowAddNewPlanting(true);
  }

  return (
    <div className="w-screen h-screen">
      {showSuccessModal ? (
        <SuccessfullMesg label={`Planting data successfully created`} />
      ) : null}

      {metaData !== undefined ? (
        showAddNewPlanting === true ? (
          <AddNewPlantModal
            token={user.token}
            metaData={metaData}
            hideModal={hideModalHandle}
          />
        ) : (
          ""
        )
      ) : null}
      <PageLayoutComp metaData={metaData} user={user}>
        <div className="h-[100%] bg-[#E6F1ED] p-4">
          <div className="h-[7%]">
            <div className="flex justify-between">
              <p className="text-3xl font-bold mb-0">
                Planting List
              </p>
                <div className="flex">
                  <div
                    onClick={() => handleAddButton()}
                    className="flex bg-green100 items-center text-white pl-1 pr-3 rounded-md cursor-pointer"
                  >
                    <BsPlus size={25} />
                    <p className="ml-3 text-sm">Add New Planting</p>
                  </div>
                </div>
            </div>
          </div>
          <div className="h-[100%]">
            <div className="h-[calc(100%-50px)] bg-white p-3">
                <TablePlants
                  token={user.token}
                  metaData={metaData}
                  refreshTab={refreshTab}
                />
            </div>
          </div>
        </div>
      </PageLayoutComp>
    </div>
  );
};
