import React from "react";
import closeImg from "../images/FarmPipeLine/close.png";
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import { getDateFormatMoment } from "../config/localdata";


export const ImageSliderModal = ({
  hideModal,
  images,
}) => {

  console.log("imagesimages", images)

  return (
    <>
      <div className="flex overflow-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center">
        <div className="relative">
          {/*content*/}
          <div style={{'overflow':'hidden'}} className="first-letter:rounded-lg shadow-lg relative flex flex-col w-[610px] outline-none focus:outline-none rounded-lg">
            <div className="relative bg-white rounded-t-lg h-full w-full">
            <div className="">
            <img
className="float-right w-[30px] h-[30px]"
                src={closeImg}
                alt="closeImg"
                onClick={() => {
                  hideModal();
                }}
              />
              
                </div>
                <div className="mt-5 ml-4">

                
                <Slide>
                {images?.map((item, idx)=>{
                  return(
                  <div className="each-slide-effect">
                    <div style={{ 'backgroundImage': `url(${item.doc_link})`, 'backgroundSize': '600px 500px', 'backgroundRepeat': 'no-repeat' , height:'520px', padding:'10px' }}>
                        <span className="text-white text-[20px]">{getDateFormatMoment(item.created_at, true)}</span>
                        {/* <span className="text-white text-[20px]">{ JSON.parse(item.geo_tag).lat }</span> */}
                    </div>
                  </div>
                  )
                })}
              </Slide>
        </div>
                </div>
              </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>{" "}
    </>
  );
};
