import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { _apiLogout, _asyncGetMerchandiserLogs, _asyncGetMerchandiserProducts, _asyncGetSuperMarkets, _fetchMetaData } from "../../api";
import { PageLayoutComp } from "../../components/PageLayoutComp";
import { TableSearchComp } from "../../components/TableSearchComp";
import { TablePaginationComp } from "../../components/TablePaginationComp";
import { AssignMerchandiserModal } from "../../Models/AssignMerchandiserModal";



export const MerchandiseMapping = () => {
  const size = 10;
  const [selectedPage, setSelectedPage] = useState(0);
  const location = useNavigate();
  let user = JSON.parse(localStorage.getItem("user_data"));
  const [metaData, setMetaData] = useState();
  const [supermarketList, setSupermarketList] = useState([]);
  const [totalPage, setTotalPage] = useState();
  const [totalItems, setTotalItems] = useState();
  const [modalData, setModalData] = useState();
  const [showModal, setShowModal] = useState(false);

  const sortType = [
    { id: "1", name: "A-Z" },
    { id: "2", name: "Z-A" },
  ];

  const [sortValue, setSortValue] = useState({ id: "0", name: "Sort By" });
  const [searchText, setSearchText] = useState("");
  const [action, setAction] = useState("");
  const [searchType, setSearchType] = useState("");



  console.log("totalPagetotalPage", totalPage)

  useEffect(() => {
    _fetchData();
  }, []);

  useEffect(() => {
    _fetchSuperMarketData(selectedPage);
  }, [sortValue]);

  async function _fetchData() {
    let metaData = await _fetchMetaData();
    setMetaData(metaData)
    _fetchSuperMarketData(selectedPage);
  }

  async function _fetchSuperMarketData(selectedPage) {
    let params = {
      size: size,
      page: selectedPage + 1
    }
    if(sortValue?.id !== "0"){
      params.sort_order = sortValue?.name === "A-Z" ? "ASC" : "DESC";
    }else{
      params.sort_by = 'name'
      params.sort_order = "ASC"
    }
    if(searchText){
      params.search_text = searchText
    }
    let superMarkets = await _asyncGetSuperMarkets(params);
    setSupermarketList(superMarkets?.displayData)
    setTotalPage(superMarkets?.totalPage);
    setTotalItems(superMarkets?.totalItems);
  }

  const handleAssignModal = (data) =>{
    setShowModal(true)
    setModalData(data)
  }

  useEffect(()=>{
    _fetchSuperMarketData(selectedPage)
  },[action])


  const handleSearchSubmit = (action) => {
    setAction(action);
    setSelectedPage(0);
    if (action === "reset") {
      setSearchText("");
      setSearchType("");
      _fetchSuperMarketData(0);
    }
    if (searchText.length > 2 && action !== "reset") {
      _fetchSuperMarketData(0);
    }
  };

  return (
    <div className="w-screen h-screen">
      <PageLayoutComp metaData={metaData} user={user}>
      {showModal ? (
        <AssignMerchandiserModal
          hideModal={() => { 
            setShowModal(false)
            _fetchData(); 
          }}
          modalData={modalData}

        />
      ) : null}
        <div className=" h-[100%] bg-[#E6F1ED] p-4">
          <div className="h-[7%]">
            <div className="flex justify-between">
              <p className="text-3xl font-bold mb-0">Merchandiser Mapping</p>
            </div>
          </div>
          <div className="relative h-[97.5%]">
            <div className="  h-[calc(99%-6%)] bg-white p-3">
              <div className="flex flex-row">
                <div className="ml-5 w-[100%]">
                  <TableSearchComp
                    onChange={(text) => setSearchText(text)}
                    sortBy={true}
                    sortType={sortType}
                    sortValue={sortValue}
                    handleSortBy={(item) => setSortValue(item)}
                    searchText={searchText}
                    handleSearchSubmit={handleSearchSubmit}
                    searchTypeValues={""}
                    searchType={{display_name:"SuperMarket"}}
                    onSearchChangeType={(item) => setSearchType(item)}
                    showFilterBtn={false}
                  />
                </div>
              </div>
              <div className=" relative mt-2 mb-5 h-[100%] ">
                <div className={`h-[80%] overflow-auto scrollbar-hide mt-5`}>
                  <table className="w-full">
                    <thead>
                      <tr className="sticky">
                            <td className="text-sm bg-white">
                              <div className="flex justify-start ml-4  items-center py-2 border-gray-200 border-b-[1px] cursor-pointer">
                                <p className="justify-center text-[18px] font-bold text-[#3EB049]">
                                  Supermarket
                                </p>
                              </div>
                            </td>
                            <td className="text-sm bg-white">
                              <div className="flex justify-start ml-4  items-center py-2 border-gray-200 border-b-[1px] cursor-pointer">
                                <p className="justify-center text-[18px] font-bold text-[#3EB049]">
                                  Merchandiser
                                </p>
                              </div>
                            </td>
                      </tr>
                    </thead>
                    <tbody>
                      {supermarketList?.map((data, index) => {
                        return (
                          <tr class="odd:bg-white  even:bg-gray-50  border-b">
                            <td className="p-3 text-[#40AE49] cursor-pointer" onClick={()=>handleAssignModal(data)}>
                              {data?.name}
                            </td>
                            <td className="p-3">
                              <p className="font-poppins font-normal">{data?.merchandisers?.length > 0 ? data?.merchandisers?.map(merchandiser => merchandiser?.user_name).join(", "): 'Not yet assigned merchandiser'}</p>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <div className="flex justify-end items-center h-[10%] mt-5 text-sm px-5">
                {totalPage !== undefined ? (
                  <TablePaginationComp
                    totalPages={totalPage}
                    active={selectedPage}
                    selectedPage={(p) => {
                      setSelectedPage(p);
                      _fetchSuperMarketData(p);
                    }}
                    totalItems={totalItems}
                  />
                ) : null}
              </div>
              </div>
            </div>
          </div>
        </div>
      </PageLayoutComp>
    </div>
  );
};
