import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
export const PieChartComp = ({
  categories,
  targetHarvests,
  actualHarvests,
}) => {
  const chartOptions = {
    credits: {
      enabled: false,
    },
    title: {
      text: null,
    },
    xAxis: {
      title: {
        text: "Months",
      },
      categories: categories,
    },
    yAxis: {
      title: {
        text: "Target (Kg) and Actual (Kg)",
      },
    },

    series: [
      {
        name: "Target",
        data: targetHarvests,
        color: "#40AE49",
      },
      {
        name: "Actual",
        data: actualHarvests,
        color: "#F491A3",
      },
    ],
  };

  return (
    <div className="">
      <HighchartsReact
        containerProps={{ className: "h-[300px]" }}
        highcharts={Highcharts}
        options={chartOptions}
      />
    </div>
  );
};
