import { useState, useEffect, useRef } from "react";
import { axiosClient, axiosHeader } from "../api";
import { TableSearchComp } from "../components/TableSearchComp";
import { toast } from "react-toastify";
import DropDownComp from "../components/DropDownComp";
import { BsPlus } from "react-icons/bs";
import { RiDeleteBin5Line } from "react-icons/ri";
import { ConfirmationModal } from "./ConfirmationModal";
import SearchDropDownComp from "../components/SearchDropDownComp";

export const AppUsersTab =({ token, selectedFarm }) => {
  const metaDataJson = localStorage.getItem("metadata");
  let metaData = null;
  if (metaDataJson) {
    metaData = JSON.parse(metaDataJson);
  }
  const userRolesList = metaData.userRoles;

  let selectedFarmDetails = null;
  let allNonLinkedUsers = null;
  const configFetchRef = useRef(false);
  const [isEditUserModeActive, toggleEditUserMode] = useState(false);
  const [nonLinkedUsersListToDisplay, updatenonLinkedUsersListToDisplay] =
    useState([]);
  const [newLinkedUsers, updateNewLinkedUsers] = useState([]);
  const [archiveAppUser, setArchiveAppUser] = useState();
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const colums = [
    {
      Header: "USER ID",
      accessor: "id",
    },
    {
      Header: "NAME",
      accessor: "user_name",
    },
    {
      Header: "CONTACT NO.",
      accessor: "contact_number",
    },
    {
      Header: "NIK NUMBER",
      accessor: "nik",
    },
    {
      Header: "ROLE",
      accessor: "role",
    },
    {
      Header: "APP STATUS",
      accessor: "app_status",
    },
    {
      Header: "REMOVE",
      accessor: "remove",
    },
  ];
  const [tableDataList, setTableDataList] = useState([]);

  useEffect(() => {
    if (configFetchRef.current) return;
    configFetchRef.current = true;
    _fetchFarmDetailedInfo();
    fetchNonLinkedUsers();
  });

  async function _deleteAppUser() {
    try {
      const body = [];
      body.push({
        farm_id: selectedFarm.id,
        user_id: archiveAppUser.id,
      });

      if (body.length > 0) {
        let apiURL = "admin/archive-user-farm";
        const apiResult = await axiosClient.put(apiURL, body, axiosHeader());
        if (apiResult && apiResult.status === 200) {
          console.log(apiResult);
          toast.success(`User Archived Successfully!`, {
            autoClose: 1400,
          });
          setTimeout(() => {
            window.location.reload();
          }, 1500);
        }
      }
    } catch (error) {
      console.log(`Something went wrong when archiving user farms -->>> `);
      console.log(error);
    }
  }

  async function _fetchFarmDetailedInfo() {
    axiosClient
      .get(`admin/farm/${selectedFarm.id}`, axiosHeader())
      .then((res) => {
        if (res.status === 200 && res.data && res.data.data) {
          selectedFarmDetails = res.data.data;
          if (Array.isArray(selectedFarmDetails.farm_users)) {
            selectedFarmDetails.farm_users = selectedFarmDetails.farm_users.map(
              (fu) => {
                fu.updated_user_name = fu.user_name;
                fu.updated_role = { ...fu.role };
                return fu;
              }
            );
            setTableDataList([...selectedFarmDetails.farm_users]);
          }
        } else {
          toast.error("No data found for the selected farm!");
        }
      });
  }

  const updatedNonLinkUsersDisplayList = () => {
    const alreadyLinkedUserIds = {};
    if (newLinkedUsers && newLinkedUsers.length) {
      newLinkedUsers.forEach((lu) => {
        alreadyLinkedUserIds[lu.id] = true;
      });
    }
    if (Array.isArray(allNonLinkedUsers)) {
      const updatedList = allNonLinkedUsers.filter(
        (nlu) => !alreadyLinkedUserIds[nlu.id]
      );
      updatenonLinkedUsersListToDisplay(updatedList);
    }
  };

  async function fetchNonLinkedUsers() {
    axiosClient
      .get(`admin/non-linked-users/${selectedFarm.id}`, axiosHeader())
      .then((res) => {
        console.log(res);
        if (res.status === 200 && res.data) {
          allNonLinkedUsers = res.data;
          updatedNonLinkUsersDisplayList();
        } else {
          console.log("=> No data found in farm details api.");
          toast.error("No data found for the selected farm!");
        }
      });
  }

  const saveChanges = async () => {
    const updatedData = [];
    if (tableDataList.length) {
      tableDataList.forEach((dt) => {
        const updatedObj = {};
        // if (dt.updated_user_name.length > 0 && dt.updated_user_name !== dt.user_name) {
        //   updatedObj.user_name = dt.updated_user_name;
        // }
        if (dt.updated_role.id !== dt.role.id) {
          updatedObj.role_id = dt.updated_role.id;
        }
        if (Object.keys(updatedObj).length > 0) {
          updatedObj.user_id = dt.id;
          updatedObj.role_id = dt.updated_role.id;
          updatedData.push(updatedObj);
        }
      });
    }
    // handling new user linking
    const usersToLink = [];
    if (newLinkedUsers.length > 0) {
      newLinkedUsers.forEach((dt) => {
        const updatedObj = {};
        if (dt.role_id && dt.user_id) {
          updatedObj.user_id = dt.user_id;
          updatedObj.role_id = dt.role_id;
          usersToLink.push(updatedObj);
        }
      });
    }

    console.log(updatedData,"usersToLink++++++++>>>>>",usersToLink);

    const apiCallPromises = [];
    if (updatedData.length > 0) {
      updatedData.forEach((dt)=>{
        apiCallPromises.push(
          axiosClient.put(`admin/link-users/${selectedFarm.id}`, {users: updatedData}, axiosHeader())
        );
      })
    }
    if (usersToLink.length > 0) {
      apiCallPromises.push(
        axiosClient.put(
          `admin/link-users/${selectedFarm.id}`,
          {
            users: usersToLink,
          },
          axiosHeader()
        )
      );
    }
    try {
      const upadtedData = await Promise.all(apiCallPromises);
      toast.success("Data Updated Successfully!", {
        autoClose: 1000,
      });
      setTimeout(() => {
        window.location.reload();
      }, 1100);
    } catch (error) {
      toast.error("Something went wrong! Please try again later.", {
        autoClose: 1000,
      });
    }
    toggleEditUserMode(false);
    if (usersToLink.length > 0) {
      fetchNonLinkedUsers();
    }
  };

  const FarmUsersListUI = () => {
    return (
      <div className="h-[100%]">
        <div className="h-[7%]">
          {/* <TableSearchComp /> */}
          <div className="flex justify-between mt-5 border-b-[1px] border-b-gray-300">
            <div className="border-b-2 border-b-[#3EB049] mt-2">
              <p className=" font-semibold text-lg">App Users</p>
            </div>
            <div>
              <span>
                {!isEditUserModeActive ? (
                  <div
                    onClick={() => toggleEditUserMode(true)}
                    className="flex bg-gray-500 items-center text-white pl-4 pr-4 pt-0.5 pb-0.5 rounded-2xl cursor-pointer text-sm"
                  >
                    Edit
                  </div>
                ) : (
                  <div
                    onClick={() => saveChanges()}
                    className="flex bg-green100 items-center text-white pl-4 pr-4 pt-0.5 pb-0.5 rounded-md cursor-pointer text-sm"
                  >
                    Save
                  </div>
                )}
              </span>
            </div>
          </div>
        </div>
        <div className="h-[80%] scrollbar-hide mt-5">
          <table className="w-full">
            <thead>
              <tr className="sticky top-0">
                {colums.map((col, i) => {
                  return (
                    <td
                      className="text-sm text-center p-[0px] m-[0px] bg-white"
                      key={`tfhk-${i}`}
                    >
                      <div className="flex items-center py-2 border-gray-200 border-b-[1px]">
                        <p className="text-sm text-[#3EB049]">{col.Header}</p>
                      </div>
                    </td>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {tableDataList.map((data, i) => {
                return (
                  <tr
                    key={`row-${i}`}
                    className="border-gray-200 border-b-[1px]"
                  >
                    {colums.map((col, i) => {
                      return (
                        <td key={`tbfc-${i}`} className="py-2 ">
                          {col.Header === "USER ID" ? (
                            <p className="text-sm text-[#0090FF] underline cursor-pointer">
                              {data[col.accessor]}
                            </p>
                          ) : col.Header === "ROLE" ? (
                            !isEditUserModeActive ? (
                              <p className="text-sm">
                                {data[col.accessor]?.role}
                              </p>
                            ) : (
                              <DropDownComp
                                listData={userRolesList}
                                type={"role"}
                                active={
                                  !data["updated_role"]
                                    ? userRolesList[0]
                                    : userRolesList[
                                        userRolesList.findIndex(
                                          (r) =>
                                            r.role ===
                                              data["updated_role"]?.role ||
                                            r.role_bahasa ===
                                              data["updated_role"]?.role_bahasa
                                        )
                                      ]
                                }
                                onSelected={(item) => {
                                  data.updated_role = {
                                    ...item,
                                  };
                                  setTableDataList(tableDataList);
                                }}
                                customStyles={
                                  "rounded-md justify-between text-[#3E4347] text-[14px]"
                                }
                                customMenuStyle={
                                  "relative inline-block text-left w-full"
                                }
                              />
                            )
                          ) : col.Header === "NAME" ? (
                              <p
                                title={
                                  data[col.accessor] == null
                                    ? ""
                                    : data[col.accessor].toString()
                                }
                                className="text-sm"
                              >
                                {data[col.accessor] == null
                                  ? ""
                                  : data[col.accessor].toString()}
                              </p>
                          ) : col.Header === "APP STATUS" ? (
                            <p className="text-sm">
                              {data[col.accessor]?.status}
                            </p>
                          ) : col.Header === "REMOVE" ? (
                            <div
                              onClick={() => {
                                setArchiveAppUser(data);
                                setShowConfirmModal(true);
                              }}
                              className="ml-2 text-red-400 cursor-pointer"
                            >
                              <RiDeleteBin5Line size={20} />
                            </div>
                          ) : (
                            <p
                              title={
                                data[col.accessor] == null
                                  ? ""
                                  : data[col.accessor].toString()
                              }
                              className="text-sm"
                            >
                              {data[col.accessor] == null
                                ? ""
                                : data[col.accessor].toString()}
                            </p>
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  const newlyLinkedUserRowsUI = () => {
    return newLinkedUsers.map((nlu) => {
      return (
        <div className="mt-5 grid grid-cols-3 gap-2">
          <div>
            <div className="flex items-center">
              <p className="pl-1 pb-1 text-[#8A9099] text-sm">User Name</p>
              <p className="text-red-500">*</p>
            </div>

            {<div className={`h-[40px] rounded- relative`}>
              <SearchDropDownComp
                list={nonLinkedUsersListToDisplay || []}
                type={"non-linked-users"}
                onSelected={(data) => {
                  nlu.user_id = data.id;
                  nlu.user_name = data.user_name;
                  nlu.contact_number = data.contact_number;
                  updateNewLinkedUsers([...newLinkedUsers]);
                }}
                customStyles={
                  "rounded-md justify-between text-[#3E4347] text-[14px]"
                }
                customMenuStyle={"text-left w-full"}
              />
            </div> }
          </div>
          <div>
            <div className="flex">
              <p className="text-[#8A9099] text-sm">Role</p>
              <p className="text-red-500">*</p>
            </div>
            <div className="relative">
            <SearchDropDownComp
              list={userRolesList || []}
              type={"role"}
              label="Select Role"
              active={
                nlu.role_id
                  ? userRolesList[
                      userRolesList.findIndex((ele) => ele.id === nlu.role_id)
                    ]
                  : userRolesList[0]
              }
              onSelected={(item) => {
                nlu.role_id = item.id;
                updateNewLinkedUsers([...newLinkedUsers]);
              }}
              customStyles={
                "rounded-md justify-between text-[#3E4347] text-[14px]"
              }
              customMenuStyle={"relative inline-block text-left w-full"}
            />
            </div>
          </div>
          <div
            onClick={() => {
              updateNewLinkedUsers((prevData) => {
                const updated = prevData.filter(
                  (d) => d.user_id !== nlu.user_id
                );
                return updated;
              });
            }}
            className="flex items-end ml-2 mb-4 text-red-400 cursor-pointer"
          >
            <RiDeleteBin5Line size={20} />
          </div>
        </div>
      );
    });
  };

  return (
    <div className="mt-10">
      {showConfirmModal ? (
        <ConfirmationModal
          label={`Do you really want to archive this?`}
          hideModal={() => setShowConfirmModal(false)}
          onConfirm={() => {
            setShowConfirmModal(false);
            _deleteAppUser();
          }}
        />
      ) : null}
      <div className="mt-2">
        <FarmUsersListUI />
        {isEditUserModeActive ? (
          <div className="mb-10">
            {newlyLinkedUserRowsUI()}
            {nonLinkedUsersListToDisplay.length > 0 ? (
              <div
                className="flex mt-10 cursor-pointer text-green bg-gray100/20 justify-center items-center py-2"
                onClick={() => {
                  const data = nonLinkedUsersListToDisplay[0];
                  const role = userRolesList[0];
                  newLinkedUsers.push({
                    user_id: data.id,
                    user_name: data.user_name,
                    contact_number: data.contact_number,
                    role_id: role.id,
                  });
                  updateNewLinkedUsers([...newLinkedUsers]);
                }}
              >
                <p className="mr-2">Link User</p>
                <BsPlus size={22} />
              </div>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
