import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import * as Sentry from "@sentry/react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { ErrorBoundary } from "react-error-boundary";
import { useErrorBoundary } from "react-error-boundary";
import { ErrorPage } from "./pages/Error";
import { Provider } from "react-redux";
import store from "./config/states/store";

// Sentry.init({
//   dsn: process.env.REACT_APP_SENTRY_DSN,
//   integrations: [
//     new Sentry.BrowserTracing({
//       // tracePropagationTargets: [
//       //   "https://admin.beleafmanagement.io/",
//       //   "https://api.beleafmanagement.io/",
//       // ],
//     }),
//     new Sentry.Replay(),
//   ],
//   tracesSampleRate: 1.0,
//   replaysSessionSampleRate: 0.1,
//   replaysOnErrorSampleRate: 1.0,
// });

function ErrorFallback({ error }) {
  const { resetBoundary } = useErrorBoundary();
  return <ErrorPage />;
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ErrorBoundary FallbackComponent={ErrorFallback}>
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_OAUTH_PROVIDER}>
      <React.StrictMode>
        <BrowserRouter>
          <Provider store={store}>
            <App />
          </Provider>
          <ToastContainer autoClose={3000} />
        </BrowserRouter>
      </React.StrictMode>
    </GoogleOAuthProvider>
  </ErrorBoundary>
);
