import React from "react";
import { _asyncGetWarehouses } from "../api";
import closeImg from "../images/FarmPipeLine/close.png";

export const PreviewModel = ({ hideModal, imgSrc }) => {
  return (
    <>
      <div className="flex overflow-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center">
        <div className="w-[50%] h-[80%] relative mt-[3%] mb-[3%]">
          <div className="h-full first-letter:rounded-lg shadow-lg relative flex flex-col w-full outline-none focus:outline-none rounded-lg">
            <div className="relative p-10 overflow-auto pt-3 bg-white rounded-t-lg h-full">
              <img
                className="float-right w-[30px] h-[30px] mt-3"
                src={closeImg}
                alt="closeImg"
                onClick={() => {
                  hideModal();
                }}
              />
              <img
                class="border border-solid border-black rounded-[8px] overflow-scroll ml-0 mr-0"
                src={imgSrc}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>{" "}
    </>
  );
};
