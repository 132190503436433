
import React, { useState } from "react";
import { archiveTypeList } from "../../config/localdata";
import { TableFarms } from "../../config/tables/FarmTable";
import { TableUsers } from "../../config/tables/UserTable";
import { _apiLogout, _fetchMetaData } from "../../api";
import DropDownComp from "../../components/DropDownComp";
import { PageLayoutComp } from "../../components/PageLayoutComp";
import { useEffect } from "react";

export const Archived = () => {
  let user = JSON.parse(localStorage.getItem("user_data"));
  const [metaData, setMetaData] = useState();
  const [currentArchiveType, setCurrentArchiveType] = useState(
    archiveTypeList[0]
  );
  
  useEffect(()=>{
    _fetchData();
  },[])

  async function _fetchData() {
    let metaData = await _fetchMetaData();
    setMetaData(metaData);
  }


  return (
    <div className="w-screen h-screen">
      <PageLayoutComp metaData={metaData} user={user}>
        <div className="h-[100%]">
          <div className="h-[7%]">
            <div className="flex justify-between">
              <p className="text-3xl font-bold mb-0">
                Administration - Archived
              </p>
            </div>
          </div>
          <div className="h-[100%]">
            <div className="h-[calc(100%-50px)] bg-white p-3">
                <>
                  <DropDownComp
                    label={"Archive Type"}
                    listData={archiveTypeList}
                    active={currentArchiveType}
                    type={"name"}
                    onSelected={(item) => setCurrentArchiveType(item)}
                    customStyles={
                      "rounded-md justify-between text-[#3E4347] text-[14px]"
                    }
                    customMenuStyle={"relative inline-block text-left w-full"}
                  />
                  {currentArchiveType.name === "Farms" ? (
                    <TableFarms
                      token={user.token}
                      metaData={metaData}
                      archived={true}
                    />
                  ) : (
                    <TableUsers
                      archive={true}
                      token={user.token}
                      metaData={metaData}
                    />
                  )}
                </>
            </div>
          </div>
        </div>
      </PageLayoutComp>
    </div>
  );
};
