import { Fragment, useEffect, useState } from "react";
import { Combobox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";

export default function SearchDropDownComp({
  list,
  onSelected,
  active,
  type = "product_name",
  label = "Search..",
  width="w-[360px]",
  disabled = false
}) {
  const [dataList, setDataList] = useState(list);
  const [selected, setSelected] = useState([]);
  const [query, setQuery] = useState("");
  const [filteredPeople, setFilteredPeople] = useState([]);

  useEffect(() => {
    setDataList(list);
    setFilteredPeople(list);
    //setSelected(active ? active: list[0]);
    setSelected(active ? active: null);
  }, [list, active]);

  useEffect(() => {
    let search = query?.toLowerCase()?.replace(/\s+/g, "");
    let filteredList = dataList?.filter((item) => {
      return _getDisplayName(item)
        .toLowerCase()
        .replace(/\s+/g, "")
        .includes(search);
    });
    setFilteredPeople(filteredList);
  }, [query]);

  function _getDisplayName(item) {
    let val = "";
    if (type === "product_name") {
      val =
        item?.product_name &&
        item?.production_code &&
        item?.product_name + " (" + item?.production_code + ")" || item?.product_name;
    } else if (type === "category") {
      val = item?.category_name;
    } else if (type === "province") {
      val = item?.province;
    } else if (type === "district") {
      val = item?.district;
    } else if (type === "FarmType") {
      val = item?.farm_type_name;
    } else if (type === "relation") {
      val = item?.ownership_type;
    } else if (type === "farmFunctions") {
      val = item?.function;
    } else if (type === "farm") {
      val = item?.farm_name;
    } else if (type === "plantingStatus") {
      val = item?.status;
    } else if (type === "mitra") {
      val = item?.user_name;
    } else if (type === "used") {
      val = item?.name;
    } else if (type === "type") {
      val = item?.class_name;
    } else if (type === "internal_farm_type") {
      val = item?.internal_farm_type;
    }else if (type === "partner_name") {
      val = item?.partner_name;
    }else if (type === "farm_name") {
      val = item?.farm_name;
    }else if (type === "tenor") {
      val = item?.tenor;
    }else if (type === "payment_type_id") {
      val = item?.payment_type;
    }
    else if (type === "sku_id") {
      val = item?.name;
    }
    else if (type === "type_name") {
      val = item?.type_name;
    }
    else if (type === "state") {
      val = item?.state;
    }
    else if (type === "areaUom") {
      val = item;
    }
    else if (type === "psic_name") {
      val = item?.name;
    }
    else if (type === "role") {
      val = item?.role;
    }
    else if (type === "warehouse_name") {
      val = item?.warehouse_name;
    }
    else if (type === "bom") {
      val = item?.display_name;
    }
    else if (type === "rab_name") {
      val = item?.rab_name;
    }
    else if (type === "nameDocument"){
      val = item?.nameDocument;
    }
    else if (type === "product_code"){
      val = item?.default_code;
    }
    else if (type === "production_code"){
      val = item?.production_code;
    }
    else if (type ==='bom_Type'){
      val = item?.name
    }
    else if (type ==='team_name') {
      val = item?.name
    }
    else if(type ==='sku_name'){
      val =   item?.name &&
      item?.default_code &&
      item?.name + " (" + item?.default_code + ")";
    }

    

    
    else if(type === "non-linked-users"){
      val = item?.user_name ?`${item?.user_name} / ${item?.contact_number}`:'';
    }
    return val;
  }


  return (
    <div>
      <Combobox
        value={selected}
        disabled={disabled}
        onChange={(item) => {
          setSelected(item);
          onSelected(item);
        }}
      >
        <div className="mt-1">
          <div className="relative flex h-[50px] w-full border border-gray-300 cursor-default overflow-hidden rounded-lg bg-white text-left focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
            <Combobox.Input
              className="w-full border-none outline-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
              displayValue={(item) => _getDisplayName(item)}
              onChange={(event) => setQuery(event.target.value)}
              placeholder={label}
            />
            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronUpDownIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </Combobox.Button>
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setQuery("")}
          >
            <Combobox.Options className={`absolute mt-1 max-h-60 ${width} z-10 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm`}>
              {filteredPeople?.length === 0 && query !== "" ? (
                <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                  Nothing found.
                </div>
              ) : (
                filteredPeople?.map((person) => (
                  <Combobox.Option
                    key={person.id}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2 pl-10 pr-4 ${
                        active ? "bg-teal-600 text-white" : "text-gray-900"
                      }`
                    }
                    value={person}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={`block truncate ${
                            selected ? "font-medium" : "font-normal"
                          }`}
                        >
                          {_getDisplayName(person)}
                        </span>
                        {selected ? (
                          <span
                            className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                              active ? "text-white" : "text-teal-600"
                            }`}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
    </div>
  );
}
