import React, { useEffect, useState, useRef } from "react";
import { PageLayoutComp } from "../../../components/PageLayoutComp";
import { _asyncRequestOtp, _asyncVerifyOtp, _fetchMetaData } from "../../../api";
import back from "../../../../src/images/back.png";
import { InputOtpModal } from "../../../Models/InputOtpModal";
import { useNavigate } from "react-router-dom";
import closeImg from "../../../images/FarmPipeLine/close.png";


export const PhoneNumberVerification = () => {
  let user = JSON.parse(localStorage.getItem("user_data"));
  const [ph, setPh] = useState(user?.contact_number);
  const [showGeneratePo, setShowGeneratePo] = useState(false);
  const inputRefs = useRef([]);
  const [inputValues, setInputValues] = useState(Array(6).fill(""));
  const [otp,setotp] = useState("");
  const [startVerificationFlag, setStartVerificationFlag] = useState(false);
  const [kycUrl, setKycUrl] = useState(null);
  const [startKycFlag, setStartKycFlag] = useState(false);
  const location = useNavigate();

  const handleChange = (event) => {
    setPh(event.target.value);
  };
   
  const submitOtpDeatails = async () =>{
    await _asyncRequestOtp();
  }
   
  const handleOTPdetails = () => {
    submitOtpDeatails();
    setShowGeneratePo(true);
  };

  const handleInputChange = (index, event) => {
    const newValues = [...inputValues];
    newValues[index] = event.target.value;
    setInputValues(newValues);
    const inputLength = event.target.value.length;
    if (inputLength === 1 && index < inputRefs.current.length - 1) {
        inputRefs.current[index + 1].focus();
    } else if (inputLength === 0 && index > 0) {
        inputRefs.current[index - 1].focus();
    }
    const concatenatedString = newValues.join('');
    setotp(concatenatedString);
};

const onConfirm = async () =>{
  if(otp.length === 6){
    const response = await _asyncVerifyOtp({otp});
    if(response.status === 201){
      setShowGeneratePo(false);
      setStartVerificationFlag(true);
      setKycUrl(response?.data?.data?.iFrame_url)
    }
  }
}

const backVidaIframe = () =>{
  location("/farm-pipeline/e-sign-start-kyc")
}

const doKyc = () =>{
  setStartKycFlag(true)
}

const handleBackButton = ()=> {
  location("/farm-pipeline/e-sign-start-kyc")
}

  return (
    <div className="w-screen h-screen">
      <PageLayoutComp user={user}>
        {showGeneratePo ? (
          <InputOtpModal
            inputRefs={inputRefs}
            requestAgain={submitOtpDeatails}
            onConfirm={onConfirm}
            handleInputChange={handleInputChange}
            label4={ph}
            hideModal={() => {
              setShowGeneratePo(false);
            }}
          />
        ) : null}
        <div className="h-[7%]">
          <div className="flex justify-between">
            <p className="text-3xl font-bold mb-0">
              Administration - E Signature
            </p>
          </div>
        </div>
        <div className="bg-gray-200 w-[100%]  rounded-lg flex">
          <div className="w-full h-[84vh] flex justify-center items-center bg-white">
          {startKycFlag ? 
         <div className="flex w-full justify-center"> 
           <div className="w-[67%] flex  overflow-auto  bg-white h-[75vh] rounded-lg py-[10px] border border-gray-500 border-[1px]">
            <iframe
              className="w-full h-full"
              allow="camera"
              
              src={kycUrl}
            ></iframe>
            
           </div>
           <div className="ml-2">
            <img
                className="w-[40px] h-[40px]"
                src={closeImg}
                alt="closeImg"
                onClick={() => backVidaIframe()}
              />
          </div>
          </div>:
          <div className="w-[40%] bg-white 2xl:w-[40%]  2xl:h-[70vh] h-[60vh] lg:h-[75vh] lg:w-[50%] rounded-lg  p-[80px]  border border-gray-500 border-[1px]">
              <div className="">
                <div className="">
                  <p className="font-bold text-[20px]">
                    What is your phone number
                  </p>
                  <p className=" text-[10px] pt-[10px]">
                    Enter the phone number you want to use for create your
                    digital certificate. Make sure to use a phone number that
                    belongs to you, since code will send to you for OTP.
                  </p>
                </div>
                <div className="mt-[59px] flex">
                  <div className="flex border border-gray-500 border-[1px] w-[70%] rounded-md p-[5px] items-center">
                    <p className="text-center text-[12px] border-r-[1px]  border-black pr-[5px]">
                      +62
                    </p>
                    <input
                      className="w-[140px] ml-[4px] focus:border-transparent focus:outline-none appearance-none"
                      type="text"
                      maxLength={11}
                      value={user?.contact_number}
                      disabled={true}
                      onKeyDown={(event) => {
                        if (
                          !/^[0-9]$/.test(event.key) &&
                          event.key !== "Backspace" &&
                          event.key !== "Delete"
                        ) {
                          event.preventDefault();
                        }
                      }}
                      placeholder="Phone #"
                      onChange={handleChange}
                    ></input>
                  </div>
                  <button
                    onClick={() => handleOTPdetails()}
                    disabled={startVerificationFlag}
                    className={`border-black border-[1px] ml-[20px] w-[30%] rounded-md text-[11px] p-[5px] font-bold ${startVerificationFlag && `bg-[#808080]`} items-center`}
                  >
                    Send Code
                  </button>
                </div>
              </div>
              <div className=" w-[100%]  lg:mt-[90px] xl:mt-[180px] 2xl:mt-[200px] rounded-md flex">
                <div className="flex items-center text-black pl-1 w-[50%] pr-3 rounded-md cursor-pointer">
                  <img className="h-4 w-4 self-center ml-1" src={back} />
                  <p className="ml-3 text-sm" onClick={handleBackButton}>Back</p>
                </div>
                <button disabled={!startVerificationFlag} onClick={doKyc} className={`w-[50%] ${startVerificationFlag ? `bg-[#40AE49]`:`bg-[#808080]`} p-[5px] rounded-lg`}>
                  Start Verification
                </button>
              </div>
            </div>
          }
          
          </div>
        </div>
      </PageLayoutComp>
    </div>
  );
};
