import { useEffect, useState } from "react";
import {
  _asyncAddNewRAB,
  _asyncRABCalculationPreview,
  _asyncGetAllFarms,
  _asyncGetAllProducts,
  _asyncGetAllSKU,
  _asyncGetRabDetails,
  _fetchMetaData,
  _updateRabData,
  _asyncGetBOMProducts,
} from "../api";
import { DatePickerComp } from "./DatePickerComp";
import { InputFieldComp } from "./InputFieldComp";
import SearchDropDownComp from "./SearchDropDownComp";
import AddButton from "../images/AddButton.png";
import Blank from "../images/blank.png";
import DeleteButton from "../images/Delete.png";
import Multiselect from "multiselect-react-dropdown";
import { toast } from "react-toastify";
import { SuccessfullMesg } from "../Models/SuccessfullMesg";
import { ConfirmationModal } from "../Models/ConfirmationModal";
import { PageLayoutComp } from "./PageLayoutComp";
import { useNavigate, useParams } from "react-router-dom";
import AsyncSelect from 'react-select/async';



let timeoutRef = null

export const CreateRab = () => {
  let user = JSON.parse(localStorage.getItem("user_data"));
  const location = useNavigate();
  const params = useParams();
  const [rabData, setRabData] = useState({});


  const [allFarms, updateAllFarms] = useState([]);
  const [allTenor, updateTenor] = useState([]);
  const [selectedFarm, setSelectedFarm] = useState();
  const [allProducts, updateAllProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState();
  const [plantingDate, setPlantingDate] = useState(new Date());

  const [unitPriceA, setUnitPriceA] = useState("");
  const [targetA, setTargetA] = useState("");

  const [unitPriceB, setUnitPriceB] = useState("");
  const [targetB, setTargetB] = useState("");

  const [unitPriceC, setUnitPriceC] = useState("");
  const [targetC, setTargetC] = useState("");

  const [totalTarget, setTotalTarget] = useState(0);
  const [searchProduct, setSearchProduct] = useState("");



  const [tenor, setTenor] = useState("");
  const [insurance, setInsurance] = useState([]);
  const [allSKU, updateAllSKU] = useState([]);
  const [allInsurance, updateAllInsurance] = useState([]);
  const [selectedSKU, setSelectedSKU] = useState("");
  // const [selectedBom, setSelectedBom] = useState("");
  const [selectedPaymentType, setSelectedPaymentType] = useState("");

  const [disabledForm, setDisabledForm] = useState(false);
  

  const [metaData, setMetaData] = useState();
  const [isDraft, setIsDraft] = useState(false);

  const [isInternalFarm, setIsInternalFarm] = useState(false);

  const [operationalBudget, setOperationalBudget] = useState([{}]);
  const columnsOperationalBudgetArray = ["details", "quantity", "uom","unit_price", "notes"];
  const [plantingBudget, setPlantingBudget] = useState([{}]);
  const columnsPlantingBudgetArray = ["sku_id","quantity","unit_price", "notes", "payment_type_id"];

  const columnsPlantingBudgetInternalArray = ["sku_id","quantity","uom","unit_price", "notes"];
  const [plantingBudgetInternal, setPlantingBudgetInternal] = useState([]);

  const columnsPlantingBudgetInternalBomArray = ["name","product_qty","uom_name","unit_price", "notes"];
  const [plantingBudgetInternalBom, setPlantingBudgetInternalBom] = useState([]);

  const [manpowerBudget, setManpowerBudget] = useState([{}]);
  const columnsManpowerBudgetArray = ["details", "quantity", "unit_price", "notes"];
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showConfirmModalBack, setShowConfirmModalBack] = useState(false);

  const [removedPlantingBudgetInternal, setRemovedPlantingBudgetInternal] = useState([]);
  const [removedPlantingBudgetInternalBom, setRemovedPlantingBudgetInternalBom] = useState([]);
  const [removedPlantingBudget, setRemovedPlantingBudget] = useState([]);
  const [removedOperationalBudget, setRemovedOperationalBudget] = useState([]);
  const [removedManpowerBudget, setRemovedManpowerBudget] = useState([]);

  const [profit, setProfit] = useState({});


  const fillEmptyString = (value) =>{
    if(!value || isNaN((value+"")) || !(+(value+""))){
      value = "";
    }else{
      value = +(value+"")
    }
    return value;
  }

  const onBackButtonEvent = (e) => {
    e.preventDefault();
    setShowConfirmModalBack(true)
}

useEffect(() => {
  window.history.pushState(null, null, window.location.pathname);
  window.addEventListener('popstate', onBackButtonEvent);
  return () => {
    window.removeEventListener('popstate', onBackButtonEvent);  
  };
}, [showConfirmModalBack]);

  
  const handleAddOperational = () => {
    const item = {};
    setOperationalBudget([...operationalBudget, item]);
  };

  const handleRemoveOperational = (idx) => {
    const tempRows = [...operationalBudget];
    if(tempRows.length === 1){
      return;
    }
    const temp = tempRows.splice(idx, 1);
    if(temp[0]?.id){
      const finalRows = [...removedOperationalBudget]
      setRemovedOperationalBudget([...finalRows,{id:temp[0]?.id}])
    }
    setOperationalBudget(tempRows);
  };
  const  addDotsFromBack = (number) => {
    const numStr = String(number)?.replace('.', '');

    const chunks = [];
    for (let i = numStr.length; i > 0; i -= 3) {
        chunks.unshift(numStr.substring(Math.max(0, i - 3), i));
    }
    return chunks.join('.');
}
  const updateOperationalState = (e, idx) => {
    let fieldValue = e.target.value;
    let fieldName = e.target.name;
    if(fieldName === "quantity"){
      fieldValue=parseFloat(fieldValue) || ''
    }
    if(fieldName === "unit_price"){
      fieldValue=parseFloat(fieldValue.replace(/\./g, '')) || ''
      fieldValue = addDotsFromBack(fieldValue||'')
    }
    const tempRows = [...operationalBudget];
    const tempObj = operationalBudget[idx]; 
    tempObj[fieldName] = fieldValue;
    tempRows[idx] = tempObj;
    setOperationalBudget(tempRows);
  };


  const handleAddPlanting = () => {
    const item = {};
    setPlantingBudget([...plantingBudget, item]);
  };

  const handleAddPlantingInternal = () => {
    const item = {};
    setPlantingBudgetInternal([...plantingBudgetInternal, item]);
  };

  useEffect(()=>{
    let sum = (Number(targetA)+Number(targetB)+Number(targetC)) || 0;
    setTotalTarget(sum)
  },[targetA,targetB,targetC])

  const handleRemovePlanting = (idx) => {
    const tempRows = [...plantingBudget];
    if(tempRows.length === 1){
      return;
    }
    const temp = tempRows.splice(idx, 1);
    if(temp[0]?.id){
      const finalRows = [...removedPlantingBudget]
      setRemovedPlantingBudget([...finalRows,{id:temp[0]?.id}])
    }
    setPlantingBudget(tempRows);
  };

  const handleRemovePlantingInternal = (idx) => {
    const tempRows = [...plantingBudgetInternal];
    // if(tempRows.length === 1){
    //   return;
    // }
    const temp = tempRows.splice(idx, 1);
    if(temp[0]?.id){
      const finalRows = [...removedPlantingBudgetInternal]
      setRemovedPlantingBudgetInternal([...finalRows,{id:temp[0]?.id}])
    }
    setPlantingBudgetInternal(tempRows);
  };

  const handleRemovePlantingInternalBom = (idx) => {
    const tempRows = [...plantingBudgetInternalBom];
    // if(tempRows.length === 1){
    //   return;
    // }
    const temp = tempRows.splice(idx, 1);
    if(temp[0]?.id){
      const finalRows = [...removedPlantingBudgetInternalBom]
      setRemovedPlantingBudgetInternalBom([...finalRows,{id:temp[0]?.id}])
    }
    setPlantingBudgetInternalBom(tempRows);
  };

  

  const updatePlantingState = (e, idx, column) => {
    let fieldValue = null; 
    let fieldName = null;
    if(column === "sku_id" || column === "payment_type_id"){
      fieldValue = e.id;
      fieldName = column;
    }else{
      fieldValue = e.target.value;
      fieldName = e.target.name;
    }
    if(fieldName === "quantity"){
      fieldValue=parseFloat(fieldValue)||''
    }
    if(fieldName === "unit_price"){
      fieldValue=parseFloat(fieldValue.replace(/\./g, '')) || ''
      fieldValue = addDotsFromBack(fieldValue||'')
    }
    const tempRows = [...plantingBudget];
    const tempObj = plantingBudget[idx]; 
    tempObj[fieldName] = fieldValue;
    tempRows[idx] = tempObj;
    setPlantingBudget(tempRows);
  };

    const updatePlantingStateInternal = (e, idx, column) => {
      let fieldValue = null; 
      let fieldName = null;
      if(column === "sku_id"){
        fieldValue = e.id;
        fieldName = column;

      }else{
        fieldValue = e.target.value;
        fieldName = e.target.name;
      }
      if(fieldName === "quantity"){
        fieldValue=parseFloat(fieldValue)||''
      }
      if(fieldName === "unit_price"){
        fieldValue=parseFloat(fieldValue.replace(/\./g, '')) || ''
        fieldValue = addDotsFromBack(fieldValue||'')
      }

    const tempRows = [...plantingBudgetInternal];
    const tempObj = plantingBudgetInternal[idx]; 
    tempObj[fieldName] = fieldValue;
    if(column === "sku_id"){
      tempObj["uom"] = e.uom;
    }
    tempRows[idx] = tempObj;
    setPlantingBudgetInternal(tempRows);
  };

  const updatePlantingStateBom = (e, idx, column) => {
    let fieldValue = null; 
    let fieldName = null;
      fieldValue = e.target.value;
      fieldName = e.target.name;

    if(fieldName === "quantity"){
      fieldValue=parseFloat(fieldValue)||''
    }
    if(fieldName === "unit_price"){
      fieldValue=parseFloat(fieldValue.replace(/\./g, '')) || ''
      fieldValue = addDotsFromBack(fieldValue||'')
    }

  const tempRows = [...plantingBudgetInternalBom];
  const tempObj = plantingBudgetInternalBom[idx]; 
  tempObj[fieldName] = fieldValue;
  tempRows[idx] = tempObj;
  setPlantingBudgetInternalBom(tempRows);
};

  const handleAddManpowerBudget = () => {
    const item = {};
    setManpowerBudget([...manpowerBudget, item]);
  };

  const handleRemoveManpowerBudget = (idx) => {
    const tempRows = [...manpowerBudget];
    if(tempRows.length === 1){
      return;
    }
    const temp = tempRows.splice(idx, 1);
    if(temp[0]?.id){
      const finalRows = [...removedManpowerBudget]
      setRemovedManpowerBudget([...finalRows,{id:temp[0]?.id}])
    }
    setManpowerBudget(tempRows);
  };

  const updateManpowerBudgetState = (e, idx) => {
    let fieldValue = e.target.value;
    let fieldName = e.target.name;
    if(fieldName === "quantity"){
      fieldValue=parseFloat(fieldValue)||''
    }
    if(fieldName === "unit_price"){
      fieldValue=parseFloat(fieldValue.replace(/\./g, '')) || ''
      fieldValue = addDotsFromBack(fieldValue||'')
    }
    const tempRows = [...manpowerBudget];
    const tempObj = manpowerBudget[idx]; 
    tempObj[fieldName] = fieldValue;
    tempRows[idx] = tempObj;
    setManpowerBudget(tempRows); 
  };


  async function _fetchData() {
    let metaData = await _fetchMetaData();
    updateTenor(metaData?.rabPayLaterTenors)
    updateAllInsurance(metaData?.rabInsuranceTypes)
    setMetaData(metaData);
    if(params?.id){
      let details = await _asyncGetRabDetails(params.id);
      getDataFilled(details)
      setRabData(details)
      setDisabledForm(user?.admin_role_id === 4)
    }
  }

  const getDataFilled = (data) =>{
    setIsInternalFarm(data?.data?.is_internal_farm_rab);
    setManpowerBudget(data.data.manpower_budget_info)
    setOperationalBudget(data.data.operational_budget_info)
    setPlantingBudget(data.data.planting_budget_info)
    updateAllFarms([{ id: data?.data?.farm?.id, farm_name: data?.data?.farm?.farm_name }])
    setSelectedFarm({ id: data?.data?.farm?.id, farm_name: data?.data?.farm?.farm_name });
  
    updateAllProducts([{ id: data?.data?.product?.id, product_name: data?.data?.product?.product_name }]);
    setSelectedProduct({ id: data?.data?.product?.id, product_name: data?.data?.product?.product_name });
    let date = new Date(data.data.planting_date)
    setPlantingDate((date.getMonth() +1) + "/" + date.getDate()  + "/" + date.getFullYear());

    setUnitPriceA(fillEmptyString(data?.data?.target_unit_price_A));
    setTargetA(fillEmptyString(data?.data?.target_harvest_quantity_A));

    setUnitPriceB(fillEmptyString(data?.data?.target_unit_price_B));
    setTargetB(fillEmptyString(data?.data?.target_harvest_quantity_B));
    
    setUnitPriceC(fillEmptyString(data?.data?.target_unit_price_C));
    setTargetC(fillEmptyString(data?.data?.target_harvest_quantity_C));

    setTenor(data?.data?.tenor);

    let internal = [];
    let bom = [];
    let sku = [];
    data?.data?.planting_budget_info.map((item)=>{
      if(item?.bom_data){
        bom.push({
          ...item?.bom_data,
          unit_price:item?.unit_price,
          notes:item?.notes,
          quantity:item?.quantity,
          id:item?.id
        });
      } else {
        internal.push(item);
        sku.push({...item?.sku})
      }
    })


    setPlantingBudgetInternal(internal);
    setPlantingBudgetInternalBom(bom);

    
    data?.data?.planting_budget_info.map((item)=>{
      sku.push({...item?.sku})
    })
    setSelectedSKU(sku);

    let paymentType = [];
    data?.data?.planting_budget_info.map((item)=>{
      paymentType.push({...item?.payment_type})
    })
    setSelectedPaymentType(paymentType);


    let temp1 = []
    data?.data?.insurances.map((item)=>{
      temp1.push({...item.insurance_info})
    }) 
    setInsurance(temp1);

  }


  async function _getGetAllFarms() {
    const data = await _asyncGetAllFarms();
    if (!data) return;
    let temp = [{ id: "", farm_name: "Select Farm" }];
    updateAllFarms([...temp, ...data]);
  }

  async function _getGetAllSKU() {
    const data = await _asyncGetAllSKU();
    if (!data) return;
    let temp = [{ id: "", name: "Select SKU" }];
    updateAllSKU([...temp, ...data]);
    console.log('skudetail---',data);
  }

  async function _getRabCalucationPreview() {
    let insList = [];
    let data = {}
    insurance?.map((item) => {
      insList.push(item.id);
    });

    let newPlantingBudget = [];
    if(!isInternalFarm){
       newPlantingBudget = plantingBudget.map(obj =>
        ({ ...obj,
          unit_price:obj?.unit_price.replaceAll('.', '')
        }));
    }
   

    let newoOerationalBudget;
    if(operationalBudget && operationalBudget.length){
      const finalArray = [];
      operationalBudget.forEach(item=>{
        Object.keys(item).forEach(key=>{
          if(item[key] == ""){
            delete item[key];
          }
        })
      })
      operationalBudget.forEach(item=>{
        if(Object.keys(item).length){
          finalArray.push(item)
        }
      })

      if(finalArray.length){
        newoOerationalBudget = finalArray.map(obj =>
          ({ ...obj,
            //unit_price:obj?.unit_price.replaceAll('.', '')
            unit_price:obj?.unit_price && String(obj?.unit_price).replaceAll('.', ''),
          }))
      }
    }

    let newManpowerBudget;
    if(manpowerBudget && manpowerBudget.length){
      const finalArray = [];
      manpowerBudget.forEach(item=>{
        Object.keys(item).forEach(key=>{
          if(item[key] == ""){
            delete item[key];
          }
        })
      })
      manpowerBudget.forEach(item=>{
        if(Object.keys(item).length){
          finalArray.push(item)
        }
      })

      if(finalArray.length){
        newManpowerBudget = finalArray.map(obj =>
          ({ ...obj,
            //unit_price:obj?.unit_price.replaceAll('.', '')
            unit_price:obj?.unit_price && String(obj?.unit_price).replaceAll('.', ''),
          }))
      }
    }


  let output_data = [];
  if(isInternalFarm){
    output_data = getRequiredData();
  }

  let finalOpBudgetData;
  if(newoOerationalBudget || removedOperationalBudget.length){
    finalOpBudgetData = [...removedOperationalBudget]
    if(newoOerationalBudget && newoOerationalBudget.length){
      finalOpBudgetData.push(...newoOerationalBudget);
    }
  }

  let finalManpowerData;
  if(newManpowerBudget || removedManpowerBudget.length){
    finalManpowerData = [...removedManpowerBudget]
    if(newManpowerBudget && newManpowerBudget.length){
      finalManpowerData.push(...newManpowerBudget);
    }
  }

    
    if(targetA && plantingDate && unitPriceA && ((plantingBudgetInternalBom[0]?.unit_price || plantingBudgetInternal[0]?.unit_price ) || (plantingBudget[0]?.quantity && plantingBudget[0]?.unit_price))){
      data = {
      target_harvest_quantity_A: targetA,
      target_unit_price_A: unitPriceA ? String(unitPriceA).replaceAll('.', '') : unitPriceA,
      target_harvest_quantity_B: targetB,
      target_unit_price_B: unitPriceB ? String(unitPriceB).replaceAll('.', '') : unitPriceB,
      target_harvest_quantity_C: targetC,
      target_unit_price_C: unitPriceC ? String(unitPriceC).replaceAll('.', '') : unitPriceC,
      planting_date: plantingDate,
      // operational_budget_info: removedOperationalBudget.length  ? newoOerationalBudget:[...newoOerationalBudget, ...removedOperationalBudget],
      planting_budget_info: isInternalFarm ? [...output_data, ...plantingBudgetInternal]:removedPlantingBudget.length ? newPlantingBudget:[...newPlantingBudget, ...removedPlantingBudget],
      // manpower_budget_info: removedManpowerBudget.length ? newManpowerBudget: [...newManpowerBudget, ...removedManpowerBudget],
      manpower_budget_info:finalManpowerData,
      operational_budget_info:finalOpBudgetData,
      tenor_id: tenor?.id,
      insurance_ids: insList,
    }
    let response = await _asyncRABCalculationPreview(data, isInternalFarm);
      setProfit({
        profit:`${response.profit}`,
        profitPercent:`${response.profit_percent} %`,
        cogm:`${response.cogm}`,
        roi:`${response.roi}`,

      })
  }
} 


const getRequiredData = () =>{
  let output_data = [];
  if(isInternalFarm){
    plantingBudgetInternalBom.forEach(item => {
      const output_item = {
          bom_data: {
              o_id: item?.o_id,
              o_product_id: item?.o_product_id,
              name: item?.name,
              display_name: item?.display_name,
              product_qty: item?.product_qty,
              product_uom_id: item?.product_uom_id,
              uom_name: item?.uom_name
          },
          quantity: item?.quantity,
          unit_price: parseFloat(item?.unit_price),
          notes: item?.notes,
          id:item?.id
      };
      output_data.push(output_item);
    });
  }
    return output_data;
}


  const _getGetAllProducts = async (name = null) => {
    let params = {
      product_name: name
    }
    const data = await _asyncGetAllProducts(params)
    if (!data) return;
    updateAllProducts(data);
   
  }

  const loadOptions = (searchValue, callBack) => {
    clearTimeout(timeoutRef);
    timeoutRef = setTimeout(async () => {
      let params = {
        product_name: searchValue,
      };
      const data = await _asyncGetAllProducts(params);
      if (!data) return;
      updateAllProducts(data);
      callBack(data);
    }, 1000);
  };



  async function _getGetBomData(code) {
    const data = await _asyncGetBOMProducts(code);
    if (!data) return;
    setPlantingBudgetInternalBom(data)
  }

  const onInsuranceChange = (event) => {
   let insList = [];
    event.map((item) => {
      insList.push(item);
    });
    setInsurance(insList);
  };

  useEffect(() => {
    _getGetAllProducts();
    _getGetAllFarms();
    _fetchData();
    _getGetAllSKU();
  }, []);

  const createRab = (isDraft) => {
      setIsDraft(isDraft);
    if (!selectedFarm?.id) {
      toast.error("Please Select Farm", {autoClose: 2000});
      return;
    }
    if (!selectedProduct?.id) {
      toast.error("Please Select Product", {autoClose: 2000});
      return;
    }

    if (targetA === "") {
      toast.error("Please Enter target", {autoClose: 2000});
      return;
    }

    if (plantingDate === "") {
      toast.error("Please Select Planting Date", {autoClose: 2000});
      return;
    }

    if (unitPriceA === "") {
      toast.error("Please Enter Unit Price", {autoClose: 2000});
      return;
    }

    let validation = plantingBudget.filter(e => e.payment_type_id === 2).length > 0;
    if (validation && !tenor?.id) {
      toast.error("Please Select Tenor", {autoClose: 2000});
      return;
    }
     
    if (validation && insurance.length === 0) {
      toast.error("Please Select Insurance", {autoClose: 2000});
      return;
    }
    let riskCategoryID = insurance.filter(e => e.risk_category_id === 2).length;
    if ((validation && riskCategoryID !== 2)) {
      toast.error("Please select 2 natural disaster risk for insurance", {autoClose: 2000});
      return;
    }
    setShowConfirmModal(true);
  };

  const handleSelectChange = (item) => {
    setSelectedProduct(item);
    if(isInternalFarm && item?.production_code){
      _getGetBomData(item?.production_code)
    }
  };

  const handleSelectSearch = (item) => {
    setSearchProduct(item);
  };


  const productOnChange = (item) =>{
    setSelectedProduct(item)
    if(isInternalFarm){
      _getGetBomData(item?.production_code)
    }
  }

  const _addRabData = async () =>{
    let insList = [];
    insurance?.map((item) => {
      insList.push(item.id);
    });
    let newPlantingBudget = [];
    if(!isInternalFarm){
       newPlantingBudget = plantingBudget.map(obj =>
        ({ ...obj,
          unit_price:obj?.unit_price.replaceAll('.', '')
        }));
    }
  
    let newoOerationalBudget;
    if(operationalBudget && operationalBudget.length){
      const finalArray = [];
      operationalBudget.forEach(item=>{
        Object.keys(item).forEach(key=>{
          if(item[key] == ""){
            delete item[key];
          }
        })
      })
      operationalBudget.forEach(item=>{
        if(Object.keys(item).length){
          finalArray.push(item)
        }
      })

      if(finalArray.length){
        newoOerationalBudget = finalArray.map(obj =>
          ({ ...obj,
            //unit_price:obj?.unit_price.replaceAll('.', '')
            unit_price:obj?.unit_price && String(obj?.unit_price).replaceAll('.', ''),
          }))
      }
    }

    let newManpowerBudget;
    if(manpowerBudget && manpowerBudget.length){
      const finalArray = [];
      manpowerBudget.forEach(item=>{
        Object.keys(item).forEach(key=>{
          if(item[key] == ""){
            delete item[key];
          }
        })
      })
      manpowerBudget.forEach(item=>{
        if(Object.keys(item).length){
          finalArray.push(item)
        }
      })

      if(finalArray.length){
        newManpowerBudget = finalArray.map(obj =>
          ({ ...obj,
            //unit_price:obj?.unit_price.replaceAll('.', '')
            unit_price:obj?.unit_price && String(obj?.unit_price).replaceAll('.', ''),
          }))
      }
    }

  let output_data = [];
  if(isInternalFarm){
    output_data = getRequiredData();
  }

  let finalOpBudgetData;
  if(newoOerationalBudget || removedOperationalBudget.length){
    finalOpBudgetData = [...removedOperationalBudget]
    if(newoOerationalBudget && newoOerationalBudget.length){
      finalOpBudgetData.push(...newoOerationalBudget);
    }
  }

  let finalManpowerData;
  if(newManpowerBudget || removedManpowerBudget.length){
    finalManpowerData = [...removedManpowerBudget]
    if(newManpowerBudget && newManpowerBudget.length){
      finalManpowerData.push(...newManpowerBudget);
    }
  }

    let data = {
      is_draft: isDraft,
      farm_id: selectedFarm?.id,
      product_id: selectedProduct?.id,
      target_harvest_quantity_A:targetA, 
      target_unit_price_A:unitPriceA ? String(unitPriceA).replaceAll('.', '') : unitPriceA,
      target_harvest_quantity_B:targetB, 
      target_unit_price_B:unitPriceB ? String(unitPriceB).replaceAll('.', '') : unitPriceB,
      target_harvest_quantity_C:targetC, 
      target_unit_price_C:unitPriceC ? String(unitPriceC).replaceAll('.', '') : unitPriceC,
      planting_date: plantingDate,
      //operational_budget_info: removedOperationalBudget.length > 0  ? [...newoOerationalBudget, ...removedOperationalBudget]:newoOerationalBudget,
      planting_budget_info: isInternalFarm ? [...output_data, ...plantingBudgetInternal, ...removedPlantingBudgetInternalBom, ...removedPlantingBudgetInternal]:removedPlantingBudget.length > 0 ? [...newPlantingBudget, ...removedPlantingBudget]:newPlantingBudget,
      //manpower_budget_info: removedManpowerBudget.length > 0 ? [...newManpowerBudget, ...removedManpowerBudget]:newManpowerBudget,
      manpower_budget_info:finalManpowerData,
      operational_budget_info:finalOpBudgetData,
      tenor_id: tenor?.id,
      insurance_ids: insList,
     
    };
    let response;
      if(params.id){
        response = await _updateRabData(params.id, data)
      }else{
        response = await _asyncAddNewRAB(data);
      }
    if ((response.status === 201 && response.statusText === "Created") || (response.status === 200 && response.statusText === "OK")) {
      setShowSuccessModal(true);
      setTimeout(() => {
        location('/budget-plan-rab');
      }, 1000);
    }
  }

  return (
    <PageLayoutComp metaData={metaData} user={user}>
    <div className="h-[100%] bg-[#E6F1ED] p-4">
      {showSuccessModal ? (
        <SuccessfullMesg label={`RAB Successfully Created`} />
      ) : null}


      {showConfirmModal ? (
        <ConfirmationModal
          label={isDraft ? `Are you sure want to save this RAB as Draft ?`: params.id ? `Are you sure want to Update this RAB ?`: `Are you sure want to Create this RAB ?`}
          label2="No"
          type="planting"
          customStyles={true}
          hideModal={() => setShowConfirmModal(false)}
          onConfirm={() => {
            setShowConfirmModal(false);
            _addRabData();
          }}
        />
      ) : null}

    {showConfirmModalBack ? (
        <ConfirmationModal
          label={`Are you sure want to go back and discard this RAB ?`}
          label2="No"
          type="planting"
          customStyles={true}
          hideModal={() => setShowConfirmModalBack(false)}
          onConfirm={() => {
            setShowConfirmModalBack(false);
            location('/budget-plan-rab');
          }}
        />
      ) : null}

      <div className="h-[7%]">
        <div className="flex justify-between">
          <p className="text-3xl font-bold mb-0">{params?.id ? 'Draft/Edit RAB':'Create New RAB'}  </p>
        
        </div>
      </div>
      <div className="h-[100%]">
        {/* <div className="flex bg-[#E6F1ED] h-[50px]">
          <div className="flex bg-white text-[#3EB049] px-7 py-2 justify-between items-center rounded-tl-lg rounded-tr-xl cursor-pointer">
            <p className="text-[15px] font-normal">New RAB</p>
          </div>
        </div> */}
        <div className="h-[calc(100%-50px)] bg-white p-3">
          <div class="border border-solid border-black h-full w-full rounded-[8px] overflow-scroll">
          <div className="flex">
            <div className="w-[40%] ">
            <div className="flex space-x-10 mt-6">
              <div className="w-[28%]">
                <p className="text-black text-sm ml-10 mt-1">Farm :</p>
              </div>
              <div className="w-[62%]">
                <div className="relative">
                  <SearchDropDownComp
                    list={allFarms}
                    disabled={disabledForm}
                    active={selectedFarm}
                    label="Select Farm"
                    type={"farm_name"}
                    onSelected={(item) => {
                      setSelectedFarm(item);
                      setIsInternalFarm(item?.ownership_type === "2");
                      setSelectedProduct();
                      setPlantingBudgetInternalBom([])
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="flex space-x-10 mt-3">
              <div className="w-[28%]">
                <p className="text-black text-sm ml-10 mt-1"> Product :</p>
              </div>
              <div className="w-[62%] relative">
                <div>
                  {/* <SearchDropDownComp
                    list={allProducts}
                    active={selectedProduct}
                    label="Select Product"
                    type={"product_name"}
                    disabled={disabledForm}
                    onSelected={(item) => productOnChange(item)}
                  /> */}
                  <AsyncSelect
                        cacheOptions
                        value={selectedProduct}
                        placeholder="Search Product"
                        inputValue={searchProduct}
                        isClearable={true}
                        filterOption={false}
                        disabled={disabledForm}
                        loadOptions={loadOptions}
                        getOptionLabel={(allProducts) => allProducts.product_name}
                        getOptionValue={(allProducts) => allProducts.id}
                        defaultOptions={allProducts}
                        onInputChange={handleSelectSearch}
                        onChange={handleSelectChange}
                      />
                </div>
              </div>
            </div>

            <div className="flex space-x-10 mt-4">
              <div className="w-[28%]">
                <p className="text-black text-sm ml-10 mt-1">Planting Date :</p>
              </div>
              <div className="w-[40%]">
                <div>
                  <DatePickerComp
                    onSelected={(item) => {
                      setPlantingDate(item);
                    }}
                    value={plantingDate}
                    allowPastDate={false}
                    disabled={disabledForm}
                  />
                </div>
              </div>
            </div>
            </div>
            <div className="w-[30%]">
            <div className="flex space-x-10 mt-6 ">
              <div className="w-[62%]">
                <p className="text-black text-sm ml-10 mt-1">Area of ​​Cultivation (Ha) : </p>
              </div>
              <div className="w-[40%]">
                <div className="relative">
                  <SearchDropDownComp
                    list={allFarms}
                    disabled={disabledForm}
                    active={selectedFarm}
                    label="Select Farm"
                    type={"farm_name"}
                    onSelected={(item) => {
                      setSelectedFarm(item);
                      setIsInternalFarm(item?.ownership_type === "2");
                      setSelectedProduct();
                      setPlantingBudgetInternalBom([])
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="flex space-x-10 mt-3 ">
              <div className="w-[62%]">
                <p className="text-black text-sm ml-10 mt-1">Cultivation Number :</p>
              </div>
              <div className="w-[40%] relative">
                <div>
                  <SearchDropDownComp
                    list={allProducts}
                    active={selectedProduct}
                    label="Select Product"
                    type={"product_name"}
                    disabled={disabledForm}
                    onSelected={(item) => productOnChange(item)}
                  />
                </div>
              </div>
            </div>

            <div className="flex space-x-10 mt-4">
              <div className="w-[62%]">
                <p className="text-black text-sm ml-10 mt-1">Population :</p>
              </div>
              <div className="w-[40%]">
              <div>
                  <SearchDropDownComp
                    list={allProducts}
                    active={selectedProduct}
                    label="Select Product"
                    type={"product_name"}
                    disabled={disabledForm}
                    onSelected={(item) => productOnChange(item)}
                  />
                </div>
              </div>
            </div>
            </div>
            <div className="w-[30%]">
            <div className="flex space-x-10 mt-6 ">
              <div className="w-[52%]">
                <p className="text-black text-sm ml-10 mt-1">Champion Farmers :</p>
              </div>
              <div className="w-[45%]">
                <div className="relative">
                  <SearchDropDownComp
                    list={allFarms}
                    disabled={disabledForm}
                    active={selectedFarm}
                    label="Select Farm"
                    type={"farm_name"}
                    onSelected={(item) => {
                      setSelectedFarm(item);
                      setIsInternalFarm(item?.ownership_type === "2");
                      setSelectedProduct();
                      setPlantingBudgetInternalBom([])
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="flex space-x-10 mt-3 ">
              <div className="w-[52%]">
                <p className="text-black text-sm ml-10 mt-1">Conditions :</p>
              </div>
              <div className="w-[45%] relative">
                <div>
                 <input
                 className="rounded-lg pb-[60px] pl-[] w-[99%] border-gray-200 border-[1px] "
                 placeholder="Type conditions.."
                 >
                 </input>
                </div>
              </div>
            </div>

            </div>
          </div>


<p className="ml-5 mb-3 mt-5 h-[18px] top-0 left-0 [font-family:'Poppins-Bold',Helvetica] font-bold text-black text-[14px]">
            Target
            </p>
            <div className="mb-0 ml-5 mr-5 relative w-[96%] rounded-[8px] overflow-scroll border border-solid border-black">
              <div className="flex space-x-4 mt-2">
                <p className="w-[18%] ml-[3%]">
                Grade
                </p>
                <p className="w-[25%]">
                Target
                </p>
                <p className="w-[10%]">
                Unit Price
                </p>
              </div>

  <div>
  <div className={`flex space-x-4 mt-1`}>
                <div className="w-[12%] px-6 py-2">
                <div>
                  <InputFieldComp
                    compulsory={false}
                    type="text"
                    placeholder={""}
                    disabled
                    value={"A"}
                  />
                </div> 
                </div>
                <div className="w-[25%] px-6 py-2">
              <div>
                  <InputFieldComp
                    compulsory={false}
                    type="text"
                    placeholder={""}
                    value={targetA}
                    disabled={disabledForm}
                    maxLength={11}
                    onChange={(text) => {
                      if (/^[0-9.]*$/.test(text)) {
                        setTargetA(text);
                      }
                    }}
                  />
                </div>
                </div>
                <div className="w-[25%] px-6 py-2">
              <div>
              <InputFieldComp
                compulsory={false}
                type="text"
                placeholder={""}
                value={unitPriceA}
                disabled={disabledForm}
                maxLength={11}
                onChange={(text) => {
                  let fieldValue
                  if (/^[0-9.]*$/.test(text)) {
                    fieldValue=parseFloat(text.replace(/\./g, '')) || ''
                      fieldValue = addDotsFromBack((fieldValue||''))
                    setUnitPriceA(fieldValue);
                  }
                }}
              />
            </div>
                </div>
              </div>

              <div className={`flex space-x-4 mt-1`}>
              <div className="w-[12%] px-6 py-2">
                <div>
                  <InputFieldComp
                    compulsory={false}
                    type="text"
                    placeholder={""}
                    disabled
                    value={"B"}
                  />
                </div> 
              </div>
              <div className="w-[25%] px-6 py-2">
              <div>
                  <InputFieldComp
                    compulsory={false}
                    type="text"
                    placeholder={""}
                    value={targetB}
                    disabled={disabledForm}
                    maxLength={11}
                    onChange={(text) => {
                      if (/^[0-9.]*$/.test(text)) {
                        setTargetB(text);
                      }
                    }}
                  />
                </div>
              </div>
              <div className="w-[25%] px-6 py-2">
              <div>
              <InputFieldComp
                compulsory={false}
                type="text"
                placeholder={""}
                value={unitPriceB}
                disabled={disabledForm}
                maxLength={11}
                onChange={(text) => {
                  let fieldValue
                  if (/^[0-9.]*$/.test(text)) {
                    fieldValue=parseFloat(text.replace(/\./g, '')) || ''
                      fieldValue = addDotsFromBack((fieldValue||''))
                    setUnitPriceB(fieldValue);
                  }
                }}
              />
            </div>
              </div>
              </div>

              <div className={`flex space-x-4 mt-1 mb-3`}>
              <div className="w-[12%] px-6 py-2">
                <div>
                  <InputFieldComp
                    compulsory={false}
                    type="text"
                    placeholder={""}
                    disabled
                    value={"C"}
                  />
                </div> 
              </div>
              <div className="w-[25%] px-6 py-2">
              <div>
                  <InputFieldComp
                    compulsory={false}
                    type="text"
                    placeholder={""}
                    value={targetC}
                    disabled={disabledForm}
                    maxLength={11}
                    onChange={(text) => {
                      if (/^[0-9.]*$/.test(text)) {
                        setTargetC(text);
                      }
                    }}
                  />
                </div>
              </div>
              <div className="w-[25%] px-6 py-2">
              <div>
              <InputFieldComp
                compulsory={false}
                type="text"
                placeholder={""}
                value={unitPriceC}
                disabled={disabledForm}
                maxLength={11}
                onChange={(text) => {
                  let fieldValue
                  if (/^[0-9.]*$/.test(text)) {
                    fieldValue=parseFloat(text.replace(/\./g, '')) || ''
                      fieldValue = addDotsFromBack((fieldValue||''))
                    setUnitPriceC(fieldValue);
                  }
                }}
              />
            </div>
              </div>
              </div>
              <div className="ml-[15%]">
              {/* <p className="px-2 py-2 font-bold">Total Target: {(Number(targetA)+Number(targetB)+Number(targetC)) || 0}</p> */}
               <p className="px-2 py-2 font-bold">Total Target: {totalTarget || 0}</p>

              </div>
  </div>
  {/* <img
                className="w-[32px] h-[32px] mb-[2%] ml-[50%] mt-[2%] justify-center"
                alt="Add button"
                src={AddButton}
                onClick={()=>''}
              />       */}
            </div>

            <p className="ml-5 mb-3 mt-5 h-[18px] top-0 left-0 [font-family:'Poppins-Bold',Helvetica] font-bold text-black text-[14px]">
              Operational Budget
            </p>
            <div className="mb-0 ml-5 mr-5 relative w-[96%] rounded-[8px] overflow-scroll border border-solid border-black">
              <div className="px-4 py-4 font-bold">
                Field
              </div>
              <div className="flex space-x-4">
                <p className="w-[8%] ml-[2%]">
                No.
                </p>
                <p className="w-[21%]">
                Details
                </p>
                <p className="w-[21%]">
                Quantity
                </p>
                <p className="w-[21%]">
                UoM
                </p>
                <p className="w-[21%]">
                Unit Price
                </p>
                <p className="w-[21%]">
                Notes
                </p>
              </div>
            {operationalBudget.map((item, idx) => (
              <div className={`flex space-x-4`} key={idx}>
                <div className="w-[2%] mt-8 ml-5">{idx+1}</div>
                {columnsOperationalBudgetArray.map((column, index) => (
                  
                  <div className="w-[21%] mt-5" key={index}>
                    <input
                    className="flex w-full h-[50px] py-4 border rounded-lg border-gray-300 items-center px-2 font-medium shadow-sm hover:bg-gray-50 focus:outline-0"
                    type="text"
                    name={column}
                    disabled={disabledForm}
                    maxLength={column === 'quantity' ? 7:column === 'details' ? 160:column ==='unit_price' ? 11 : undefined}
                    value={operationalBudget[idx][column]}
                    placeholder={``}
                    onChange={(e) => updateOperationalState(e, idx)} 
                  />
                </div>
                ))}

              {idx !== 0 &&
               <div className="w-[5%] mt-6"> 
                <img className="w-[24px] h-[24px] mt-[12%]"
                  alt="Delete button"
                  src={DeleteButton}
                  onClick={()=> !disabledForm && handleRemoveOperational(idx)}/>
                  </div>
                  }
              {idx === 0 && 
              <div className="w-[5%] mt-6"> 
                <img className="w-[24px] h-[24px] mt-[12%]"
                  alt=""
                  src={Blank}
                  />
                  </div>
                  }
              </div>
            ))}

              <img
                className="w-[32px] h-[32px] mb-[2%] ml-[50%] mt-[2%] justify-center"
                alt="Add button"
                src={AddButton}
                onClick={()=>!disabledForm && handleAddOperational()}
              />
            </div>

            <p className="ml-5 mb-3 mt-5 h-[18px] top-0 left-0 [font-family:'Poppins-Bold',Helvetica] font-bold text-black text-[14px]">
              Planting Budget
            </p>
            {isInternalFarm ?
            <div className="mb-0 ml-5 mr-5 relative w-[96%] rounded-[8px] overflow-scroll border border-solid border-black">
            <div className="px-4 py-4 font-bold">
            Planting
            </div>
            {
            //plantingBudgetInternalBom.length > 0 && 
            <div className="flex space-x-4">
              <p className="w-[8%] ml-[2%]">
              No.
              </p>
              <p className="w-[15%]">
              Details
              {/* Products */}
              </p>
              <p className="w-[15%]">
              Quantity
              </p>
              <p className="w-[15%]">
              UoM
              </p>
              <p className="w-[18%]">
              Unit Price
              </p>
              <p className="w-[15%]">
              Notes
              </p>
            </div>
            }

            {plantingBudgetInternalBom.map((item, idx) => (
            <div className={`flex space-x-4`} key={idx}>
              <div className="w-[2%] mt-8 ml-5">{idx+1}</div>
              {columnsPlantingBudgetInternalBomArray.map((column, index) => (
              <div className="w-[25%] mt-5" key={index}>
                <input
                  className="flex w-full h-[50px] py-4 border rounded-lg border-gray-300 items-center px-2 font-medium shadow-sm hover:bg-gray-50 focus:outline-0"
                  type="text"
                  name={column}
                  disabled={column === "unit_price" || column === "notes" ? false: true}
                  value={column === "product_qty" ? (totalTarget > 0 ? totalTarget*Number(plantingBudgetInternalBom[idx][column]):plantingBudgetInternalBom[idx][column]):plantingBudgetInternalBom[idx][column]}
                  placeholder={``}
                  maxLength={column === 'quantity' ? 7:column ==='unit_price' ? 11 :undefined}
                  onChange={(e) => updatePlantingStateBom(e, idx)} 
                />
              </div>
              ))}

            
             <div className="w-[5%] mt-6"> 
              <img className="w-[24px] h-[24px] mt-[12%]"
                alt="Delete button"
                src={DeleteButton}
                onClick={()=> !disabledForm && handleRemovePlantingInternalBom(idx)}/>
                </div>
              
            
            <div className="w-[5%] mt-6"> 
              <img className="w-[24px] h-[24px] mt-[12%]"
                alt=""
                src={Blank}
                />
                </div>
                
            </div>
          ))}

          {plantingBudgetInternal.map((item, idx) => (
            <div className={`flex space-x-4`} key={idx}>
              <div className="w-[2%] mt-8 ml-5">{plantingBudgetInternalBom.length+idx+1}</div>
              {columnsPlantingBudgetInternalArray.map((column, index) => (
              <div className="w-[25%] mt-5" key={index}>
                {column === "sku_id" ?
                <SearchDropDownComp
                  list={allSKU || []}
                  label={"Select"}
                  disabled={disabledForm}
                  active={selectedSKU[idx]}
                  width="w-[450px]"
                  type={column}
                  onSelected={(item) => updatePlantingStateInternal(item, idx, column)}
                  />
                  :
                <input
                  className="flex w-full h-[50px] py-4 border rounded-lg border-gray-300 items-center px-2 font-medium shadow-sm hover:bg-gray-50 focus:outline-0"
                  type="text"
                  name={column}
                  disabled={column ==='unit_price' ? false: column === 'uom' ? true:disabledForm}
                  value={plantingBudgetInternal[idx][column]}
                  placeholder={``}
                  maxLength={column === 'quantity' ? 7:column ==='unit_price' ? 11 :undefined}
                  onChange={(e) => updatePlantingStateInternal(e, idx)} 
                />}

              </div>
              ))}

            
             <div className="w-[5%] mt-6"> 
              <img className="w-[24px] h-[24px] mt-[12%]"
                alt="Delete button"
                src={DeleteButton}
                onClick={()=> !disabledForm && handleRemovePlantingInternal(idx)}/>
                </div>
            <div className="w-[5%] mt-6"> 
              <img className="w-[24px] h-[24px] mt-[12%]"
                alt=""
                src={Blank}
                />
                </div>
            </div>
          ))}

            <img
              className="w-[32px] h-[32px] mb-[2%] ml-[50%] mt-[2%] justify-center"
              alt="Add button"
              src={AddButton}
              onClick={()=>!disabledForm && handleAddPlantingInternal()}
            />
          </div>
          :
          <div className="mb-0 ml-5 mr-5 relative w-[96%] rounded-[8px] overflow-scroll border border-solid border-black">
            <div className="px-4 py-4 font-bold">
            Planting
            </div>
            <div className="flex space-x-4">
              <p className="w-[8%] ml-[2%]">
              No.
              </p>
              <p className="w-[15%]">
              Details
              </p>
              <p className="w-[15%]">
              Quantity
              </p>
              <p className="w-[18%]">
              Unit Price
              </p>
              <p className="w-[15%]">
              Notes
              </p>
              <p className="w-[15%]">
              Payment
              </p>
            </div>

          {plantingBudget.map((item, idx) => (
            <div className={`flex space-x-4`} key={idx}>
              <div className="w-[2%] mt-8 ml-5">{idx+1}</div>
              {columnsPlantingBudgetArray.map((column, index) => (
              <div className="w-[18%] mt-5" key={index}>
                {column === "sku_id" || column === "payment_type_id" ?
                <SearchDropDownComp
                  list={column === "sku_id" ? allSKU : metaData?.rabPaymentTypes || []}
                  label={"Select"}
                  disabled={disabledForm}
                  active={column === "sku_id" ? selectedSKU[idx]:selectedPaymentType[idx]}
                  width="w-[450px]"
                  type={column}
                  onSelected={(item) => updatePlantingState(item, idx, column)}
                  />
                  :
                <input
                  className="flex w-full h-[50px] py-4 border rounded-lg border-gray-300 items-center px-2 font-medium shadow-sm hover:bg-gray-50 focus:outline-0"
                  type="text"
                  name={column}
                  disabled={column ==='unit_price' ? false: disabledForm}
                  value={plantingBudget[idx][column]}
                  placeholder={``}
                  maxLength={column === 'quantity' ? 7:column ==='unit_price' ? 11 :undefined}
                  onChange={(e) => updatePlantingState(e, idx)} 
                />}

              </div>
              ))}

            {idx !== 0 &&
              <div className="w-[5%] mt-6"> 
              <img className="w-[24px] h-[24px] mt-[12%]"
                alt="Delete button"
                src={DeleteButton}
                onClick={()=> !disabledForm && handleRemovePlanting(idx)}/>
                </div>
                }
            {idx === 0 && 
            <div className="w-[5%] mt-6"> 
              <img className="w-[24px] h-[24px] mt-[12%]"
                alt=""
                src={Blank}
                />
                </div>
                }
            </div>
          ))}

            <img
              className="w-[32px] h-[32px] mb-[2%] ml-[50%] mt-[2%] justify-center"
              alt="Add button"
              src={AddButton}
              onClick={()=>!disabledForm && handleAddPlanting()}
            />
            {plantingBudget.filter(e => e.payment_type_id === 2).length > 0 && <div className="flex space-x-10 mt-3">
              <div className="w-[15%]">
                <p className="text-black text-sm ml-10 mt-1">Tenor :</p>
              </div>
              <div className="w-[30%]">
                <div>
                  <SearchDropDownComp
                    type="tenor"
                    list={allTenor}
                    active={tenor}
                    onSelected={(item) => setTenor(item)}
                  />
                </div>
              </div>
            </div>}

            {plantingBudget.filter(e => e.payment_type_id === 2).length > 0
            
            && <div className="flex space-x-10 mt-3 mb-10">
              <div className="w-[15%]">
                <p className="text-black text-sm ml-10 mt-1">Insurance :</p>
              </div>
              <div className="w-[30%] ">
                <div>
                  <Multiselect
                    displayValue="loss_reason"
                    chipColor="red"
                    selectedValues={insurance}
                    groupBy="risk_category_name"
                    options={allInsurance}
                    onSelect={onInsuranceChange}
                    onRemove={onInsuranceChange}
                    showCheckbox
                    style={{chips:{background: "#F491A3" }}}
                  />
                </div>
              </div>
            </div>}
          </div>
        }

    
            <p className="ml-5 mb-3 mt-5 h-[18px] top-0 left-0 [font-family:'Poppins-Bold',Helvetica] font-bold text-black text-[14px]">
              Manpower Budget
            </p>

            <div className="mb-0 ml-5 mr-5 relative w-[96%] rounded-[8px] overflow-scroll border border-solid border-black">
              <div className="px-4 py-4 font-bold">
                Manpower
              </div>

              <div className="flex space-x-4">
                <p className="w-[8%] ml-[2%]">
                No.
                </p>
                <p className="w-[21%]">
                Details
                </p>
                <p className="w-[21%]">
                Quantity
                </p>
                <p className="w-[21%]">
                Unit Price
                </p>
                <p className="w-[21%]">
                Notes
                </p>
              </div>
              {manpowerBudget.map((item, idx) => (
              <div className={`flex space-x-4`} key={idx}>
                <div className="w-[2%] mt-8 ml-5">{idx+1}</div>
                {columnsManpowerBudgetArray.map((column, index) => (
                  <div className="w-[21%] mt-5" key={index}>
                    <input
                    className="flex w-full h-[50px] py-4 border rounded-lg border-gray-300 items-center px-2 font-medium shadow-sm hover:bg-gray-50 focus:outline-0"
                    type="text"
                    disabled={disabledForm}
                    name={column}
                    value={manpowerBudget[idx][column]}
                    maxLength={column === 'quantity' ? 7:column === 'details' ? 160: column ==='unit_price' ? 11 :undefined}
                    placeholder={``}
                    onChange={(e) => updateManpowerBudgetState(e, idx)} 
                  />
                </div>
                ))}

              {idx !== 0 &&
               <div className="w-[5%] mt-6"> 
                <img className="w-[24px] h-[24px] mt-[12%]"
                  alt="Delete button"
                  src={DeleteButton}
                  onClick={()=>!disabledForm && handleRemoveManpowerBudget(idx)}/>
                  </div>
                  }
              {idx === 0 && 
              <div className="w-[5%] mt-6"> 
                <img className="w-[24px] h-[24px] mt-[12%]"
                  alt=""
                  src={Blank}
                  />
                  </div>
                  }
              </div>
            ))}

              <img
                className="w-[32px] h-[32px] mb-[2%] ml-[50%] mt-[2%] justify-center"
                alt="Add button"
                src={AddButton}
                onClick={()=>!disabledForm && handleAddManpowerBudget()}
              />
            </div>

            <p className="ml-5 mb-3 mt-5 h-[15px] top-0 left-0 [font-family:'Poppins-Bold',Helvetica] font-bold text-black text-[14px]">
              Profit
            </p>

            <div className="ml-4 border border-solid border-black h-[88px] w-[97%] rounded-[8px]">
              <div className="flex space-x-4 mt-2">
                <div className="w-[5%]">
                  <p className="text-black text-sm mt-5 ml-2">
                  Rp.
                  </p>
                </div>
                <div className="w-[25%]">
                  <p  className="text-black text-sm text-center">Profit</p>
                  <div>
                  <input
                    className="flex w-[270px] h-[20px] py-4 border rounded-lg border-gray-300 items-center px-2 font-medium shadow-sm hover:bg-gray-50 focus:outline-0"
                    type='text'
                    //value={profit?.profit}
                    value={addDotsFromBack(parseFloat(profit?.profit?.replace(/\./g, '')) || '')}
                    disabled={true}
                    />
                  </div>
                </div>
                <div className="w-[6%]">
                  <div className=" ml-3">
                <p  className="text-black text-sm text-center">%</p>
                  <input
                    className="flex w-[60px] h-[20px] py-4 border rounded-lg border-gray-300 items-center px-2 font-medium shadow-sm hover:bg-gray-50 focus:outline-0"
                    type='text'
                    value={profit?.profitPercent}
                    //value={profit?.profitPercent?.replace(/\./g, ',') || ''}
                    disabled={true}
                    />
                  </div>
                  
                </div>
                

                { isInternalFarm && <div className="w-[30%]">
                <p className="ml-4 text-black text-sm">Cost of Goods Manufactured (COGM)</p>
                  <div className="mt-1 ml-4">
                  <input
                    className="flex w-[260px] h-[20px] py-4 border rounded-lg border-gray-300 items-center px-2 font-medium shadow-sm hover:bg-gray-50 focus:outline-0"
                    type='text'
                    value={profit?.cogm}
                    //value={profit?.profitPercent?.replace(/\./g, ',') || ''}
                    disabled={true}
                    
                    />
                  </div>
                </div>}
                {isInternalFarm && <div className="w-[20%]">
                <p  className="text-black text-sm">Return of Investment (ROI)</p>
                  <div className="mt-1">
                  <input
                    className="flex w-[180px] h-[20px] py-4 border rounded-lg border-gray-300 items-center px-2 font-medium shadow-sm hover:bg-gray-50 focus:outline-0"
                    type='text'
                    value={profit?.roi}
                    //value={profit?.profitPercent?.replace(/\./g, ',') || ''}
                    disabled={true}
                    
                    />
                  </div>
                </div>}

                {!isInternalFarm && <div className="w-[50%]"></div> }
                <div
              onClick={() => _getRabCalucationPreview()}
              className="flex float-right bg-gray-600 text-white mt-8 rounded-md cursor-pointer"
            >
              <p className="text-sm p-2">Calculate</p>
            </div>
              </div>
            </div>
            <div
              onClick={() => setShowConfirmModalBack(true)}
              className=" flex float-left bg-[#12312B] text-white mb-5 mt-5 pl-3 pr-3 ml-14 rounded-md cursor-pointer"
            >
              <p className="text-sm p-2">Discard</p>
            </div>
           <div>
          
              <div
              onClick={() => createRab(false)}
              className="flex float-right bg-[#40AE49] text-white  mt-5 mb-5 pl-3 pr-3 mr-14 rounded-md cursor-pointer"
            >
              <p className="text-sm p-2">{params.id ? 'Update RAB':'Create RAB'}</p>
            </div>
            <div className="">
            {
              // !params?.id &&
              rabData?.data?.current_state_id > 1 ?
              null:
              <div
              onClick={() => createRab(true)}
              className="flex float-right bg-[#F491A3] text-white  mt-5 mb-5 pl-3 pr-3 mr-5 rounded-md cursor-pointer"
            >
              <p className="text-sm p-2">Save as Draft</p>
            </div>
            }
            
          </div>
           </div>
            
          </div>
        </div>
      </div>
    </div>
    </PageLayoutComp>
  );
};
