import React, { useEffect, useRef, useState } from "react";
import { PageLayoutComp } from "./PageLayoutComp";
import { BsDot } from "react-icons/bs";
import { Document, Page, pdfjs } from "react-pdf";
import { PDFDocument, StandardFonts } from "pdf-lib";
import "react-pdf/dist/Page/AnnotationLayer.css";
import { ResizeObserver } from "@juggle/resize-observer";
import MouseTooltip from "react-sticky-mouse-tooltip";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  _asyncAssignSignaturePosition,
  _asyncGetTaskDetails,
  _asyncGetTaskDocument,
  _asyncGetTaskRecipients,
  _asyncSendEsignDocument,
} from "../api";
import useMeasure from "react-use-measure";
import { MdDeleteForever } from "react-icons/md";
import { SuccessfullMesg } from "../Models/SuccessfullMesg";
import { ConfirmationModal } from "../Models/ConfirmationModal";
import { LoaderModal } from "../Models/LoaderModal";
import { Resizable, ResizableBox } from "react-resizable";
import "../../node_modules/react-resizable/css/styles.css";

// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//   "pdfjs-dist/build/pdf.worker.min.js",
//   import.meta.url
// ).toString();

export function ESignatureUploadRND() {
  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [taskDetails, setTaskDetails] = useState();
  const [taskRecipients, setTaskRecipients] = useState([]);
  const [taskDocument, setTaskDocument] = useState();
  const [ref, bounds] = useMeasure({ scroll: true, polyfill: ResizeObserver });
  const [xy, setXY] = useState([0, 0]);
  const [hovered, setHover] = useState(false);
  const [selectedSignState, setSelectedSignState] = useState();
  const [result, setResult] = useState([]);
  const [positions, setPositions] = useState({});
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [base64, setBase64] = useState();
  const [documentBlob, setDocumentBlob] = useState();
  let user = JSON.parse(localStorage.getItem("user_data"));
  const location = useNavigate();

  const generateKey = (pre) => {
    return `${pre}_${new Date().getTime()}`;
  };

  const handleSendButton = async () => {
    let body = { ...positions };
    setIsLoading(true);
    let response = await _asyncAssignSignaturePosition(params?.id, body);
    setIsLoading(false);

    // debugger;
    if (response.status === 200) {
      setShowSuccessModal(true);
      setTimeout(() => {
        setShowSuccessModal(false);
        location("/farm-pipeline/e-sign-dashboard");
      }, 2000);
    }
  };

  const handleSendDocument = async () => {
    let formData = new FormData();
    formData.append("file", documentBlob);
    setIsLoading(true);
    let response = await _asyncSendEsignDocument(params?.id, formData);
    setIsLoading(false);
    if (response.status === 200) {
      setShowSuccessModal(true);
      setTimeout(() => {
        setShowSuccessModal(false);
        location("/farm-pipeline/e-sign-dashboard");
      }, 2000);
    }
  };

  const params = useParams();

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  useEffect(() => {
    _getTaskDetails(params?.id);
    _getDocument(params?.id);
    _getRecipientList(params?.id);
  }, []);

  async function _getTaskDetails(id) {
    const data = await _asyncGetTaskDetails(id);
    if (!data) return;
    setTaskDetails(data);
  }

  async function _getRecipientList(id) {
    const data = await _asyncGetTaskRecipients(id);
    setTaskRecipients(data?.data);
  }

  function base64ToArrayBuffer(base64) {
    var binaryString = atob(base64);
    var bytes = new Uint8Array(binaryString.length);
    for (var i = 0; i < binaryString.length; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
  }

  async function _getDocument(id) {
    const response = await _asyncGetTaskDocument(id);
    const arr = base64ToArrayBuffer(response);
    setTaskDocument(arr);
    setBase64(response);
  }

  const handleSetSignState = (data) => {
    setSelectedSignState({
      id: data?.id,
      name: data?.user_name,
    });
  };

  console.log("positions", positions);
  const updatePosition = (id, page, newX, newY) => {
    setPositions((prevPositions) => {
      if (prevPositions[id]?.pagewise_positions?.[page]) {
        return prevPositions; // If position exists, return previous state without changes
      }

      return {
        ...prevPositions,
        [id]: {
          ...prevPositions[id],
          pagewise_positions: {
            ...prevPositions[id]?.pagewise_positions,
            [page]: {
              x: newX,
              y: newY,
            },
          },
        },
      };
    });
  };

  const handleResultClickUpdation = (e, selectedSignState, pageNumber) => {
    setResult((prevResult) => {
      // Check if an entry with the same user_id and pageNumber already exists
      const exists = prevResult.some(
        (entry) =>
          entry.user_id === selectedSignState?.id && entry.page === pageNumber
      );

      if (exists) {
        return prevResult; // If entry exists, return previous state
      }

      // If entry does not exist, add new entry
      return [
        ...prevResult,
        {
          id: generateKey(e.pageX),
          x: e.pageX,
          y: e.pageY,
          text: selectedSignState?.name,
          user_id: selectedSignState?.id,
          page: pageNumber,
        },
      ];
    });
  };

  const handleUpdate = (id, pagenumber, x, y) => {
    updatePosition(id, pagenumber, x, y);
  };

  const deletePositionById = (id, pageNumber) => {
    setPositions((prevPositions) => {
      const newPositions = { ...prevPositions };
      // Check if the id exists in the current positions
      if (newPositions[id] && newPositions[id].pagewise_positions) {
        const { pagewise_positions } = newPositions[id];

        // Delete the specific page position
        delete pagewise_positions[pageNumber];

        // If pagewise_positions becomes empty, delete the whole id
        if (Object.keys(pagewise_positions).length === 0) {
          delete newPositions[id];
        } else {
          newPositions[id] = {
            ...newPositions[id],
            pagewise_positions,
          };
        }
      }

      return newPositions;
    });
  };

  const updateHeightAndWidth = (id, page, newHeight, newWidth) => {
    setPositions((prevPositions) => {
      // Ensure the position exists before updating
      if (!prevPositions[id]?.pagewise_positions?.[page]) {
        return prevPositions; // If position doesn't exist, return previous state without changes
      }

      return {
        ...prevPositions,
        [id]: {
          ...prevPositions[id],
          pagewise_positions: {
            ...prevPositions[id].pagewise_positions,
            [page]: {
              ...prevPositions[id].pagewise_positions[page],
              height: newHeight,
              width: newWidth,
            },
          },
        },
      };
    });
  };

  const onResize = (event, { node, size, handle }) => {
    updateHeightAndWidth(
      selectedSignState.id,
      pageNumber,
      size.height,
      size.width
    );
  };
  async function modifyPdf() {
    const pdfDoc = await PDFDocument.load(base64);
    const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);
    const pages = pdfDoc.getPages();
    const pageHeight = pages[0].getHeight();
    console.log(pageHeight);
    const textSize = 10;
    for (let i = 0; i < pages.length; i++) {
      pages[i].drawText(`BeleafOS_Signature_${params?.id}`, {
        x: 10,
        y: pageHeight - textSize - 5,
        size: textSize,
        font: helveticaFont,
      });
    }

    const pdfBytes = await pdfDoc.save();
    let blob = new Blob([pdfBytes], { type: "application/pdf" });
    /**
     * to create a downloadable pdf file
     * 
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        let fileName = "na2";
        link.download = fileName;
        link.click();
     */
    setDocumentBlob(blob);
  }

  return (
    <div>
      <PageLayoutComp user={user}>
        {isLoading && <LoaderModal />}
        <div className="h-[100%] w-full">
          {showSuccessModal ? (
            <SuccessfullMesg
              label={`Document has been sent to the recipient`}
            />
          ) : null}

          {showConfirmModal ? (
            <ConfirmationModal
              label={`Are you sure want to send this document to recipients ?`}
              label2="No"
              type="planting"
              customStyles={true}
              hideModal={() => setShowConfirmModal(false)}
              onConfirm={() => {
                setShowConfirmModal(false);
                handleSendButton();
                handleSendDocument();
              }}
            />
          ) : null}

          <div className="h-10">
            <div className="flex justify-between">
              <p className="text-3xl font-bold mb-0">
                Administration - E-Signature
              </p>
            </div>
          </div>

          <div className="m-4   flex  justify-center rounded-lg overflow-hidden">
            <div className="pdf-viewer w-[80%]  bg-[#f9f9f9]  h-[100%] overflow-hidden">
              <div className="w-full flex gap-5 pt-2 pl-4 pb-4 items-center bg-white">
                <p className="">{taskDetails?.doc_name}</p>
                <div className="flex  items-center text-[#808080]">
                  <BsDot />
                  <p>{taskDetails?.total_pages} Pages</p>
                </div>
              </div>
              <div className="document rounded-xl w-[50%] ml-[25%] mr-[25%] my-0 items-center justify-center">
                <div className="absolute z-10 text-xs m-2">
                  <p>BeleafOS_Signature_{params?.id}</p>
                </div>
                <div
                  className="reference"
                  id="refid"
                  ref={ref}
                  onMouseEnter={() => setHover(true)}
                  onMouseLeave={() => setHover(false)}
                  onMouseMove={({ clientX, clientY }) =>
                    setXY([clientX, clientY])
                  }
                  onClick={(e) => {
                    if (selectedSignState) {
                      handleResultClickUpdation(
                        e,
                        selectedSignState,
                        pageNumber
                      );
                      handleUpdate(
                        selectedSignState?.id,
                        pageNumber,
                        xy[0] - bounds.left,
                        bounds.bottom - xy[1]
                      );
                    }
                    modifyPdf();
                  }}
                >
                  {result.map((res) => {
                    let isShowing = "hidden";
                    if (res.page === pageNumber) {
                      isShowing = "visible";
                    }
                    return (
                      <ResizableBox
                        className="border-2  border-green p-1 bg-[#9FD7A4] text-sm flex flex-col items-center relative justify-center"
                        height={50}
                        onResize={onResize}
                        width={150}
                        resizeHandles={["se"]}
                        axis="both"
                        style={{
                          visibility: isShowing,
                          left: res.x + "px",
                          top: res.y - 47.2 + "px",
                          zIndex: 10,
                          position: "absolute",
                        }}
                      >
                        <div className=" flex flex-col items-center justify-center">
                          {selectedSignState?.id === res?.user_id && (
                            <MdDeleteForever
                              color={"#f58989"}
                              size={18}
                              className="z-10 cursor-pointer absolute top-[-10px] right-[-18px]"
                              onClick={(e) => {
                                e.stopPropagation();
                                deletePositionById(res?.user_id, pageNumber);
                                setResult((result) => {
                                  return result.filter(
                                    (item) => item.id !== res.id
                                  );
                                });
                              }}
                            />
                          )}
                          <p className="text-xs">{res.text}</p>
                          <p className="text-[0.6rem]">(Sign Placeholder)</p>
                        </div>
                      </ResizableBox>
                    );
                  })}
                  {selectedSignState && (
                    <MouseTooltip
                      className="text-sm px-2 bg-[#ECF7ED] rounded-md"
                      visible={hovered}
                      offsetX={-60}
                      offsetY={-20}
                      style={{ "z-index": "10" }}
                    >
                      <span className="text-xs">
                        <span>🖋️</span> Click Here for Sign
                      </span>
                    </MouseTooltip>
                  )}
                  <Document
                    file={taskDocument}
                    onLoadSuccess={onDocumentLoadSuccess}
                  >
                    <Page
                      size={"A4"}
                      pageNumber={pageNumber}
                      renderAnnotationLayer={false}
                      renderTextLayer={false}
                    />
                  </Document>
                </div>
              </div>
              <div className=" mt-2 p-3 flex items-center justify-center">
                <div className="">
                  <nav aria-label="Page navigation example ">
                    <ul class="flex items-center -space-x-px h-8 text-sm">
                      <li>
                        <button
                          onClick={() => {
                            if (pageNumber > 1) {
                              setPageNumber((prev) => --prev);
                            }
                          }}
                          class="flex items-center justify-center px-3 h-8 ms-0 leading-tight text-gray-500 bg-[#E6F1ED] border border-e-0 border-gray-300 rounded-s-lg hover:bg-gray-100  "
                        >
                          <span class="sr-only">Previous</span>
                          <svg
                            class="w-2.5 h-2.5 rtl:rotate-180"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 6 10"
                          >
                            <path
                              stroke="currentColor"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M5 1 1 5l4 4"
                            />
                          </svg>
                        </button>
                      </li>

                      <li>
                        <p
                          aria-current="page"
                          class="z-10 flex items-center justify-center p-2 h-8 leading-tight text-white border border-green-300 bg-green "
                        >
                          {pageNumber} <span className=" ml-2 mr-2"> of </span>{" "}
                          {numPages}
                        </p>
                      </li>

                      <li>
                        <button
                          onClick={() => {
                            if (pageNumber < numPages) {
                              setPageNumber((prev) => prev + 1);
                            }
                          }}
                          class="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-[#E6F1ED] border border-gray-300 rounded-e-lg"
                        >
                          <span class="sr-only">Next</span>
                          <svg
                            class="w-2.5 h-2.5 rtl:rotate-180"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 6 10"
                          >
                            <path
                              stroke="currentColor"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="m1 9 4-4-4-4"
                            />
                          </svg>
                        </button>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
            <div className=" side-panel w-[20%]  bg-white  flex flex-col justify-between">
              <div>
                <p className="font-semibold p-2 pb-4">Digital Signature</p>
                <div className="w-full p-2 bg-[#F1F1F1]">e-Sign</div>
                {taskRecipients.map((data, idx) => {
                  return (
                    <div
                      className={` cursor-pointer rounded-md mx-4 mt-4 ${
                        selectedSignState?.id === data?.id
                          ? "bg-[#9FD7A4]"
                          : "bg-[#F4F4F4]"
                      } mt-2 mb-2 p-1`}
                      onClick={() => handleSetSignState(data)}
                    >
                      <div className="font-normal p-2  text-black text-[12px] text-center">
                        {idx + 1}. {data?.user_name} ({data?.role})
                      </div>
                    </div>
                  );
                })}
                <div className="w-full mt-4 p-[0.7px] bg-slate-300"> </div>
                <p className="text-xs text-[#252525] p-2">
                  *Select any name for placing the signature.
                </p>
              </div>
              <div
                onClick={() => setShowConfirmModal(true)}
                className="flex bg-green100 items-center mb-5 text-white p-2 m-2 justify-center rounded-md cursor-pointer"
              >
                <p className="text-sm">Send</p>
              </div>
            </div>
          </div>
        </div>
      </PageLayoutComp>
    </div>
  );
}

export function blobToURL(blob) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = function () {
      const base64data = reader.result;
      resolve(base64data);
    };
  });
}
