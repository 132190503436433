import { useState, useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import { InputFieldComp } from "../components/InputFieldComp";
import DropDownComp from "../components/DropDownComp";
import closeImg from "../images/FarmPipeLine/close.png";
import AddButton from "../images/AddButton.png";

import {
  _asyncAddNewPlant,
  _asyncGetAllProducts,
  _asyncGetAllFarms,
  _asyncGetMitrasByFarm,
  _asyncGetAllRabForPlanting,
} from "../api";
import { DatePickerComp } from "../components/DatePickerComp";
import { ConfirmationModal } from "./ConfirmationModal";
import { useNavigate } from "react-router-dom";
import SearchDropDownComp from "../components/SearchDropDownComp";
import DeleteButton from "../images/Delete.png";
import Blank from "../images/blank.png";
import AsyncSelect from 'react-select/async';

let timeoutRef = null

export const AddNewPlantModal = ({ hideModal, metaData }) => {
  const [allFarms, updateAllFarms] = useState([]);
  const [allProducts, updateAllProducts] = useState([]);
  const [allRab, updateRab] = useState([]);
  const location = useNavigate();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const plantingDurationList = ["Daily", "Weekly"]
  const [plantingDuration, setPlantingDuration] = useState("Daily");
  const [selectedRab, setSelectedRab] = useState("");
  const [selectedProduct, setSelectedProduct] = useState();
  const [selectedFarm, setSelectedFarm] = useState();
  const [mitra, setMitra] = useState({ id: "", name: "" });
  const [isRabEnabled, setIsRabEnabled] = useState(false);
  const [searchProduct, setSearchProduct] = useState("");

  const [operationalBudget, setOperationalBudget] = useState([{}]);

  const handleAddPlanting = () => {
    const item = {};
    setOperationalBudget([...operationalBudget, item]);
  };

  const handleRemoveOperational = (idx) => {
    const tempRows = [...operationalBudget];
    if(tempRows.length === 1){
      return;
    }
    tempRows.splice(idx, 1);
    setOperationalBudget(tempRows);
  };


  const updatePlantingState = (e, idx, column) => {
    let fieldValue = e;
    let fieldName = column;
    if(fieldName === "area_qty" || fieldName === "target_harvest_qty"){
      fieldValue=parseFloat(fieldValue)||0
    }
    const tempRows = [...operationalBudget];
    const tempObj = operationalBudget[idx];
    tempObj[fieldName] = fieldValue;
    tempRows[idx] = tempObj;
    setOperationalBudget(tempRows); 
    setOperationalBudget(tempRows); 
    setOperationalBudget(tempRows);
    
  };

  const _getGetAllProducts = async (name = null) => {
    let params = {
      product_name: name
    }
    const data = await _asyncGetAllProducts(params)
    if (!data) return;
    updateAllProducts(data);
   
  }
  
   console.log("Checking all Product",allProducts);

  async function _getGetAllRab() {
    let body = {page: 1,size:1000}
    let data = await _asyncGetAllRabForPlanting(body);
    if (!data?.displayData) return;
    let temp = [{ id: null, rab_name: "Select RAB" }];
    updateRab([...temp,...data?.displayData]);
  }

  async function _getGetAllFarms() {
    const data = await _asyncGetAllFarms();
    if (!data) return;
    let temp = [{ id: null, farm_name: "Select Farm" }];
    updateAllFarms([...temp, ...data]);
  }

  const handleFarmChange = async (item) => {
    let mitraName = "";
    let mintraId = "";
    if (item?.id !== "" || item?.id !== null) {
      setSelectedFarm(item);
      setIsRabEnabled(item?.is_rab_enabled);
      const data = item?.id && await _asyncGetMitrasByFarm(item?.id);
      if (!data) return;
      mitraName = data?.user_name && data?.user_name;
      mintraId = data?.id && data?.id;
    }
    setMitra({ name: mitraName, id: mintraId });
  };

  
  const handleRabChange = async (data) => {
    if (!data?.id) {
      setSelectedRab("");
      setSelectedFarm("");
      setSelectedProduct("");
      setMitra({ name: "", id: "" });
      setOperationalBudget([{}])
      _getGetAllProducts();
      _getGetAllFarms();
      return;
    }
    setMitra({ name: data?.mitra_info?.user_name, id: data?.mitra_info?.id });
    updateAllFarms([{ id: data?.farm?.id, farm_name: data?.farm?.farm_name }]);
    updateAllProducts([
      { id: data?.product?.id, product_name: data?.product?.product_name },
    ]);
    setSelectedProduct(data?.product);
    setSelectedFarm(data?.farm);
    setSelectedRab(data);
    let totalTargetQuantity=(parseFloat(data?.target_harvest_quantity_A))+(parseFloat(data?.target_harvest_quantity_B))+(parseFloat(data?.target_harvest_quantity_C));
    let date = new Date(data.planting_date);
    const tempRows = [...operationalBudget];
    const tempObj = operationalBudget[0];
    tempObj["planting_date"] =
      date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear();
    tempObj["target_harvest_qty"]=totalTargetQuantity;
    tempRows[0] = tempObj;
    setOperationalBudget(tempRows);
  };

  useEffect(() => {
    _getGetAllProducts();
    _getGetAllFarms();
    _getGetAllRab();
  }, []);

  const handleSelectChange = (item) => {
    setSelectedProduct(item);
  };

  const handleSelectSearch = (item) => {
    setSearchProduct(item);
  };
  
  const loadOptions = (searchValue, callBack) => {
    clearTimeout(timeoutRef);
    timeoutRef = setTimeout(async () => {
      let params = {
        product_name: searchValue,
      };
      const data = await _asyncGetAllProducts(params);
      if (!data) return;
      updateAllProducts(data);
      callBack(data);
    }, 1000);
  };


  const TabsUI = () => {
    let styling = "bg-white cursor-pointer rounded-t-2xl px-4 text-[#3EB049]";
    return (
      <ul className="flex">
        <li>
          <div className={`${styling}`} style={{}}>
            {/* <li> */}
            <p className={"p-4 text-[15px] font-normal font-poppins"}>
              Add New
            </p>
            {/* </li> */}
          </div>
        </li>
      </ul>
    );
  };

  const _addPlantData = async () => {
    let body = {
      rab_id: selectedRab?.id,
      product_id: selectedProduct?.id,
      farm_id: selectedFarm?.id,
      is_weekly_planting: plantingDuration === "Weekly" ? true : false,
      mitra_id: mitra?.id,
      planting_data: operationalBudget,
    };

    const apiResponse = await _asyncAddNewPlant(body);
    if (apiResponse === 1) {
      hideModal(true);
    }
  };

  const submitAndAddNewPlant = () => {
    if (!selectedProduct) {
      toast.error(`Please select a valid product.`);
      return;
    }

    if (!selectedFarm) {
      toast.error(`Please select a valid farm.`);
      return;
    }

    if (!operationalBudget[0].area_qty) {
      toast.error(`Please enter valid quantity.`);
      return;
    }

    if (!operationalBudget[0].planting_date) {
      toast.error(`Please select a valid planting date.`);
      return;
    }

    if (!operationalBudget[0].area_uom) {
      toast.error(`Please select valid area uom`);
      return;
    }

    if (!operationalBudget[0].target_harvest_qty) {
      toast.error(`Please enter valid target.`);
      return;
    }


    setShowConfirmModal(true);
  };
 
  return (
    <>
      <div className="flex overflow-auto fixed inset-0 z-50 outline-none focus:outline-none">
        {showConfirmModal ? (
          <ConfirmationModal
            label={`Are you sure with this data ?`}
            label2="No"
            type="planting"
            customStyles={true}
            hideModal={() => setShowConfirmModal(false)}
            onConfirm={() => {
              setShowConfirmModal(false);
              _addPlantData();
            }}
          />
        ) : null}
        <div className="relative w-full mt-[3%] ml-[10%] mr-[10%] mb-[5%]">
          {/*content*/}
          <div className=" border-0 rounded-lg shadow-lg relative flex flex-col w-full outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-start justify-between border-slate-200 rounded-t">
              <TabsUI />
            </div>
            {/*body*/}
            <div className="relative overflow-auto p-10 bg-white rounded-tr-lg h-full">
              <img
                className="float-right w-[30px] h-[30px]"
                src={closeImg}
                alt="closeImg"
                onClick={() => hideModal()}
              />
              <p className="text-[32px] mb-5">Add New Planting</p>
              <>
                <div className="flex mb-5">
                  <div className="flex border border-gray-300 px-1 py-1 rounded-full">
                    {plantingDurationList?.map((item, i) => {
                      return (
                        <div
                          key={i}
                          onClick={() => setPlantingDuration(item)}
                          className={`${
                            plantingDuration === item
                              ? "bg-[#3EB049] text-white"
                              : ""
                          } px-3 py-2 rounded-full cursor-pointer text-sm`}
                        >
                          <p>{item}</p>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-2">
                {/* <div className="mt-3 grid grid-cols-2 gap-2"> */}
                  <div>
                    <p className="text-sm text-black">Farm</p>
                    <SearchDropDownComp
                      list={allFarms}
                      label="Farm"
                      width="w-[50%]"
                      disabled={selectedRab !== "" ? true : false}
                      active={selectedFarm}
                      type={"farm_name"}
                      onSelected={(item) => {
                        handleFarmChange(item);
                      }}
                      customStyles={
                        "rounded-md justify-between text-[#3E4347] text-[14px]"
                      }
                      customMenuStyle={"relative inline-block text-left w-full"}
                    />
                  </div>
                  <div>
                    <InputFieldComp
                      label={"Mitra"}
                      placeholder={"Mitra"}
                      compulsory={false}
                      disabled={true}
                      value={mitra?.name}
                    />
                  </div>
                {/* </div> */}
                  {isRabEnabled && <div>
                    <p className="text-sm text-black">RAB</p>
                    <SearchDropDownComp
                      list={allRab}
                      label="RAB"
                      active={selectedRab}
                      type={"rab_name"}
                      width="w-[50%]"
                      onSelected={(item) => handleRabChange(item)}
                    />
                  { selectedRab &&  <div className="text-green font-bold text-right p-1 cursor-pointer" onClick={()=>location('/budget-plan-rab-preview/'+selectedRab?.id)} >Preview RAB</div>}
                  </div>}

                  <div>
                    <p className="text-sm text-black">Product </p>
                      <AsyncSelect
                        cacheOptions
                        value={selectedProduct}
                        placeholder="Search Product"
                        inputValue={searchProduct}
                        isClearable={true}
                        filterOption={false}
                        loadOptions={loadOptions}
                        getOptionLabel={(allProducts) => allProducts.product_name}
                        getOptionValue={(allProducts) => allProducts.id}
                        defaultOptions={allProducts}
                        onInputChange={handleSelectSearch}
                        onChange={handleSelectChange}
                      />
                  </div>
                </div>
                <hr className="mt-5 mb-5"/>
                {operationalBudget.map((item, idx) => (
                 <div className={`flex space-x-4`} key={idx}>
                    <div className= {plantingDuration === "Weekly" ? "mt-3 grid grid-cols-5 gap-5":"mt-3 grid grid-cols-4 gap-4"} key={idx}>
                      <div>
                        <InputFieldComp
                          label={idx === 0 && "Quantity"}
                          type="text"
                          value={operationalBudget[idx]["area_qty"]}
                          maxLength={8}
                          compulsory={false}
                          placeholder={"Quantity"}
                          onChange={(e) => updatePlantingState(e, idx, "area_qty")}
                        />
                      </div>
                      <div>
                        {idx === 0 && <p className="text-sm text-black">Area Uom</p>}
                        <SearchDropDownComp
                          list={metaData.growingAreaUom || []}
                          //active={operationalBudget[idx]["area_uom"] || "select"}
                          active={operationalBudget[idx]["area_uom"]}
                          width="w-[180px]"
                          type={"areaUom"}
                          onSelected={(item) => updatePlantingState(item, idx, "area_uom")}
                        />
                      </div>
                      <div className="mt-2">
                        {idx === 0 && <p className="text-sm text-black">{plantingDuration === "Weekly" ? 'Start Planting Date':'Planting Date'}</p>}
                        <DatePickerComp
                          onSelected={(e) => updatePlantingState(e, idx, "planting_date")}
                          value={operationalBudget[idx]["planting_date"]}
                          disabled={selectedRab !== "" && idx === 0 ? true:false}
                        />
                      </div>
                  {plantingDuration === "Weekly" && 
                  <div className="mt-2">
                  {idx === 0 && <p className="text-sm text-black">End Planting Date</p>}
                    <DatePickerComp
                      onSelected={(e) => updatePlantingState(e, idx, "end_planting_date")}
                      value={operationalBudget[idx]["end_planting_date"]}
                      disabled={selectedRab !== "" && idx === 0 ? true:false}
                    />
                  </div>}
                  <div>
                    <InputFieldComp
                    label={idx === 0 && "Target (Kg)"}
                    type="text"
                    value={operationalBudget[idx]["target_harvest_qty"]}
                    maxLength={8}
                    compulsory={false}
                    placeholder={"Input target"}
                    onChange={(e) => updatePlantingState(e, idx, "target_harvest_qty")}                      
                    />
                  </div>
                </div>
                <div>
               {idx !== 0 && 
                <img className={idx === 0 && plantingDuration === "Daily" ? `mt-12`:`mt-6` }
                  alt="Delete button"
                  src={DeleteButton}
                  onClick={()=>handleRemoveOperational(idx)}/>}

              {idx === 0 && 
                <img className="mt-12 h-5 w-8"
                  alt=""
                  src={Blank}
                  />}     

                </div>
              </div>
            ))}
                <div className="flex mt-5 justify-center">
                  <img
                    className="w-[32px] h-[32px]"
                    alt="Add button"
                    src={AddButton}
                    onClick={()=>handleAddPlanting()}
                  />
                  </div>
              </>
            </div>
            {/*footer*/}
            <div className="flex items-center p-6 rounded-b-lg bg-white w-full">
              <div className="grid grid-cols-2 gap-2 w-full">
                <div></div>
                <div className="justify-end flex">
                  <button
                    className="text-white bg-[#3EB049] mr-10 px-5 py-1 rounded-full"
                    type="button"
                    onClick={submitAndAddNewPlant}
                  >
                    Create
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};