import React from "react";
import closeImg from "../images/FarmPipeLine/close.png";
import { _asyncUpdateProductGeneralInfo } from "../api";
import SearchDropDownComp from "../components/SearchDropDownComp";

export const FiltersModal = ({ hideModal, metaData }) => {
  const metaDataDetails = metaData;
  const productCategories = metaDataDetails.cropCategories;
  const maturityList = ["Baby", "Mini", "Full Grown"];
  return (
    <>
      <div className="flex overflow-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-full mt-[3%] ml-[7%] mr-[7%] mb-[3%]">
          {/*content*/}
          <div className="h-full border-0 rounded-lg shadow-lg relative flex flex-col w-full outline-none focus:outline-none">
            <div className="relative p-8 overflow-auto pt-3 bg-white rounded-tr-lg h-full">
              <img
                className="float-right w-[30px] h-[30px]"
                src={closeImg}
                alt="closeImg"
                onClick={() => {
                  hideModal();
                }}
              />
              <p className="font-poppins font-semibold text-[24px]">FILTER</p>
              <div class="grid grid-cols-4 gap-6 mt-6">
                <div>
                  <p className="mb-8 font-poppins font-medium ">Category</p>
                  <SearchDropDownComp
                    type="category"
                    list={productCategories}
                    onSelected={(item) => console.log(item)}
                  />
                </div>
                <div>
                  <p className="mb-8 font-poppins font-medium">Product</p>
                  <SearchDropDownComp
                    type="category"
                    list={productCategories}
                    onSelected={(item) => console.log(item)}
                  />
                </div>
                <div>
                  <p className="mb-8 font-poppins font-medium">Variant</p>
                  <SearchDropDownComp
                    type="category"
                    list={productCategories}
                    onSelected={(item) => console.log(item)}
                  />
                </div>
                <div>
                  <p className="mb-8 font-poppins font-medium">Maturity</p>
                  <div className="flex flex-wrap">
                    {maturityList.map((item, i) => {
                      return (
                        <p className="flex cursor-pointer font-poppins justify-center items-center bg-[#F4F4F4] mr-2 mb-2 w-[130px] px-3 text-[16px] font-normal h-[32px]">
                          {item}
                        </p>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-center p-6 border-t border-solid border-slate-200 rounded-b-lg bg-white w-full">
              <div className="grid grid-cols-2 gap-2 w-full">
                <div></div>
                <div className="justify-end flex">
                  <button
                    className="border border-green text-green mr-10 px-5 py-1 rounded-full hover:text-white hover:bg-green"
                    type="button"
                    onClick={() => console.log()}
                  >
                    RESET
                  </button>
                  <button
                    className="bg-[#3EB049] text-white mr-10 px-5 py-1 rounded-full"
                    type="button"
                    onClick={() => console.log()}
                  >
                    APPLY
                  </button>
                </div>
              </div>
              <div />
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>{" "}
    </>
  );
};
