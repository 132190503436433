import React, { useState } from "react";
import { BsCalendarDateFill, BsThreeDotsVertical } from "react-icons/bs";

export const DosingMachineListComp = ({
  item,
  onSelection,
  selectedDosing,
  assigned,
  onScheduleClick
}) => {
  const [showAssign, setShowAssign] = useState(false);
  const ParentDiv = {
    position: "relative",
  };

  const childDiv = {
    position: "absolute",
    top: 28,
    bottom: 0,
  };

  const twoDiv = {
    display: "inline",
    padding: "2px",
  };

  return (
    <div
      onClick={() => {
        onSelection(item);
      }}
      style={ParentDiv}
      key={item.id}
      className={`flex cursor-pointer justify-between shadow-sm ${
        selectedDosing?.id === item?.id ? "bg-[#E7F2EE] text-black" : "bg-white"
      } mr-3 py-2 w-[300px] h-[122px] px-2 rounded-md shadow-sm`}
    >
      {showAssign ? (
        <div className="bg-white rounded-md w-[150px] shadow-md py-3 px-2 text-black absolute right-4 top-6 z-50 text-sm">
          <p
            onClick={() => {
              setShowAssign(false);
              assigned(item, true);
            }}
            className="hover:bg-gray-100 p-2"
          >
            Assign
          </p>
          {item?.farm_info?.farm_name && <p
            onClick={() => {
              setShowAssign(false);
              assigned(item, false);
            }}
            className="hover:bg-gray-100 p-2"
          >
            Un Assign
          </p>}
        </div>
      ) : null}

      <p className={`text-[16px] text-[#252525] font-semibold`}>
        {item?.identifier}
      </p>
      <BsThreeDotsVertical onClick={() => setShowAssign(!showAssign)} />
      <div style={childDiv}>
        <div className="text-[12px] text-[#252525]">
          {item?.farm_info?.farm_name ? item?.farm_info?.farm_name.toString().trim(): "."}
        </div>
        <div className="mt-3">
          <p className="text-[12px]" style={twoDiv}>
            {"Status"}&emsp;
          </p>
          <p
            className={`text-[10px] font-[600] text-white text-center px-2 inline ${
              item?.is_active ? "bg-[#40AE49]" : "bg-[#F491A3]"
            } px-2 py-1`}
          >
            {item?.is_active ? "online" : "offline"}
          </p>
        </div>
        {item?.is_dft && <div
            onClick={() => onScheduleClick()}
            className="flex ml-40 w-[120px] h-[36px] bg-green text-white p-2 rounded-md cursor-pointer"
          >
            <BsCalendarDateFill size={20} />
            <p className="ml-3 text-sm">Scheduler</p>
          </div>}
      </div>
    </div>
  );
};
