import React, { useState } from "react";
import { AiOutlineUpload } from "react-icons/ai";
import { _asyncGetDocDownload } from "../api";
import { DatePickerComp } from "../components/DatePickerComp";
import docuploadGuide1 from "../images/docuploadGuide1.png";
import docuploadGuide2 from "../images/docuploadGuide2.png";
import docuploadGuide3 from "../images/docuploadGuide3.png";
import { ConfirmationModal } from "./ConfirmationModal";
import { SuccessfullMesg } from "./SuccessfullMesg";
import { PreviewModel } from "../Models/PreviewModel";

export const DocumentsTab = ({
  docsData,
  onChangeValue,
  handleFileChange,
  dateAgreementLetterValidityChange,
  dateLandCertificateValidityChange,
  showSuccessModal,
  showConfirmModal,
  setShowConfirmModal,
  _addDocsData,
  docs = [],
}) => {
  const [previewDoc, setPreviewDoc] = useState(false);
  const [imageSrc, setimageSrc] = useState("");
  const onDownloadDoc = async (docType) => {
    const item = docs.filter((data) => data.doc_type === docType);
    let list = await _asyncGetDocDownload(item[0]?.id);
    const linkSource = `data:${item[0]?.doc_mime_type};base64,${list.data}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = item[0]?.doc_type;
    downloadLink.click();
  };
  async function previewFile(docType) {
    setPreviewDoc(true);
    const item = docs.filter((data) => data.doc_type === docType);
    let list = await _asyncGetDocDownload(item[0]?.id);
    const linkSource = `data:${item[0]?.doc_mime_type};base64,${list.data}`;
    if (list.data) {
      const byteCharacters = atob(list.data);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: item[0]?.doc_mime_type });
      const reader = new FileReader();
      reader.onload = function (event) {
        const previewUrl = event.target.result;
        // openNewWindowWithPreview(previewUrl);
        setimageSrc(previewUrl);
      };
      reader.readAsDataURL(blob);
    }
  }

  function openNewWindowWithPreview(previewUrl) {
    const newWindow = window.open("", "_self", "width=600,height=400");
    if (newWindow) {
      newWindow.document.write(
        '<html><head><title>File Preview</title></head><body><img src="' +
          previewUrl +
          '" /></body></html>'
      );
      newWindow.document.close();
      newWindow.focus();
    } else {
      alert(
        "Popup blocker may be preventing the preview. Please check your browser settings."
      );
    }
  }

  return (
    <>
      {showSuccessModal ? <SuccessfullMesg label={`Document Updated`} /> : null}
      {showConfirmModal ? (
        <ConfirmationModal
          label={`Are you sure want to update your document ?`}
          label2="No"
          type="planting"
          customStyles={true}
          hideModal={() => setShowConfirmModal(false)}
          onConfirm={() => {
            setShowConfirmModal(false);
            _addDocsData();
          }}
        />
      ) : null}
      {previewDoc ? (
        <PreviewModel
          hideModal={() => {
            setPreviewDoc(false);
          }}
          imgSrc={imageSrc}
        />
      ) : null}
      <div>
        <p className="text-sm">
          ID Card (KTP) {/*<span style={{ color: "red" }}>*</span>*/}
        </p>
        <div>
          <input
            className="mt-3 mb-3 file:h-[45px] h-[45px] text-sm text-grey-500 file:mr-5 file:py-2 file:px-6 file:rounded-lg file:border-0 file:text-sm file:font-medium file:bg-[#12312B] file:text-white file:cursor-pointer text-sm w-[45%] text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none"
            id="file_input"
            type="file"
            onChange={handleFileChange}
            name="ktp_id_card"
          />
          {docs.filter((data) => data.doc_type === "ID Card (KTP)").length ? (
            <>
              <span
                onClick={() => previewFile("ID Card (KTP)")}
                className=" bg-[#F491A3] ml-5 text-white px-4 py-2  rounded-lg cursor-pointer"
              >
                Preview
              </span>
              <span
                onClick={() => onDownloadDoc("ID Card (KTP)")}
                className=" bg-[#F491A3] ml-5 text-white px-4 py-2  rounded-lg cursor-pointer"
              >
                Download
              </span>
            </>
          ) : null}
        </div>
      </div>
      <div class="flex mt-3 mb-3">
        <div class="flex items-center mr-4">
          <input
            id="inline-radio"
            type="radio"
            defaultChecked={docsData.is_owner_married === false}
            value={false}
            onChange={onChangeValue}
            name="inline-radio-group"
            className="w-4 h-4"
          />
          <label
            for="inline-radio"
            className="ml-2 text-sm font-medium text-gray-900"
          >
            Single
          </label>
        </div>
        <div class="flex items-center mr-4">
          <input
            id="inline-2-radio"
            type="radio"
            defaultChecked={docsData.is_owner_married === true}
            value={true}
            onChange={onChangeValue}
            name="inline-radio-group"
            className="w-4 h-4"
          />
          <label
            for="inline-2-radio"
            className="ml-2 text-sm font-medium text-gray-900 "
          >
            Married
          </label>
        </div>
      </div>

      {docsData?.is_owner_married === true ? (
        <div>
          <p className="text-sm">
            Spouse ID Card (KTP) {/*<span style={{ color: "red" }}>*</span>*/}
          </p>
          <input
            className="mt-3 mb-3 file:h-[45px] h-[45px] text-sm text-grey-500 file:mr-5 file:py-2 file:px-6 file:rounded-lg file:border-0 file:text-sm file:font-medium file:bg-[#12312B] file:text-white file:cursor-pointer text-sm w-[45%] text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none"
            id="file_input"
            type="file"
            onChange={handleFileChange}
            name="spouse_id_card"
          />
          {docs.filter((data) => data.doc_type === "Spouse ID Card").length ? (
            <>
              <span
                onClick={() => previewFile("Spouse ID Card")}
                className=" bg-[#F491A3] ml-5 text-white px-4 py-2  rounded-lg cursor-pointer"
              >
                Preview
              </span>
              <span
                onClick={() => onDownloadDoc("Spouse ID Card")}
                className=" bg-[#F491A3] ml-5 text-white px-4 py-2  rounded-lg cursor-pointer"
              >
                Download
              </span>
            </>
          ) : null}
        </div>
      ) : null}

      <div>
        <p className="text-sm">
          Family Document (KK) {/*<span style={{ color: "red" }}>*</span>*/}
        </p>
        <input
          className="mt-3 mb-3 file:h-[45px] h-[45px] text-sm text-grey-500 file:mr-5 file:py-2 file:px-6 file:rounded-lg file:border-0 file:text-sm file:font-medium file:bg-[#12312B] file:text-white file:cursor-pointer text-sm w-[45%] text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none"
          id="file_input"
          onChange={handleFileChange}
          name="family_doc_kk"
          type="file"
        />
        {docs.filter((data) => data.doc_type === "Family Document (KK)")
          .length ? (
          <>
            <span
              onClick={() => previewFile("Family Document (KK)")}
              className=" bg-[#F491A3] ml-5 text-white px-4 py-2  rounded-lg cursor-pointer"
            >
              Preview
            </span>
            <span
              onClick={() => onDownloadDoc("Family Document (KK)")}
              className=" bg-[#F491A3] ml-5 text-white px-4 py-2  rounded-lg cursor-pointer"
            >
              Download
            </span>
          </>
        ) : null}
      </div>

      <div>
        <p className="text-sm">
          Business Certificate (Surat Keterangan Usaha)
          {/*<span style={{ color: "red" }}>*</span>*/}
        </p>
        <input
          className="mt-3 mb-3 file:h-[45px] h-[45px] text-sm text-grey-500 file:mr-5 file:py-2 file:px-6 file:rounded-lg file:border-0 file:text-sm file:font-medium file:bg-[#12312B] file:text-white file:cursor-pointer text-sm w-[45%] text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none"
          id="file_input"
          type="file"
          onChange={handleFileChange}
          name="business_certificate"
        />
        {docs.filter((data) => data.doc_type === "Business Certificate")
          .length ? (
          <>
            <span
              onClick={() => previewFile("Business Certificate")}
              className=" bg-[#F491A3] ml-5 text-white px-4 py-2  rounded-lg cursor-pointer"
            >
              Preview
            </span>
            <span
              onClick={() => onDownloadDoc("Business Certificate")}
              className=" bg-[#F491A3] ml-5 text-white px-4 py-2  rounded-lg cursor-pointer"
            >
              Download
            </span>
          </>
        ) : null}
      </div>

      <div className="flex">
        <div className="w-[45%]">
          <p className="text-sm">
            Agreement Letter (PKS) {/*<span style={{ color: "red" }}>*</span>*/}
          </p>
          <input
            className="w-full mt-3 mb-3 text-sm text-grey-500 file:mr-5 file:py-2 file:h-[45px] h-[45px] file:px-6 file:rounded-lg file:border-0 file:text-sm file:font-medium file:bg-[#12312B] file:text-white file:cursor-pointer text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none"
            id="file_input"
            type="file"
            onChange={handleFileChange}
            name="agreement_letter_pks"
          />
        </div>

        {docs.filter((data) => data.doc_type === "Agreement Letter (PKS)")
          .length ? (
          <div className="w-[12%] mt-10 mb-3">
            <>
              <span
                onClick={() => previewFile("Agreement Letter (PKS)")}
                className=" bg-[#F491A3] ml-5 text-white px-4 py-2 mt-10  rounded-lg cursor-pointer"
              >
                Preview
              </span>
              <span
                onClick={() => onDownloadDoc("Agreement Letter (PKS)")}
                className=" bg-[#F491A3] ml-5 text-white px-4 py-2  rounded-lg cursor-pointer"
              >
                Download
              </span>
            </>
          </div>
        ) : null}
        <div className="ml-[15%] w-[20%]">
          <p className="text-sm">
            Valid Date{/*<span style={{ color: "red" }}>*</span>*/}
          </p>
          <div className="mt-3 mb-3">
            <DatePickerComp
              onSelected={(item) => dateAgreementLetterValidityChange(item)}
              value={docsData.agreement_letter_pks_validity}
              allowPastDate={false}
            />
          </div>
        </div>
      </div>

      <div className="flex">
        <div className="w-[45%]">
          <p className="text-sm">
            Land Certificate (Surat Kepemilikan Lahan / Surat Sewa Lahan)
            {/*<span style={{ color: "red" }}>*</span>*/}
          </p>
          <input
            className="w-full h-[45px] mt-3 mb-3 text-sm text-grey-500 file:mr-5 file:py-2 file:h-[45px] file:px-6 file:rounded-lg file:border-0 file:text-sm file:font-medium file:bg-[#12312B] file:text-white file:cursor-pointer text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none"
            id="file_input"
            type="file"
            onChange={handleFileChange}
            name="land_certificate"
          />
        </div>
        {docs.filter((data) => data.doc_type === "Land Certificate").length ? (
          <div className="w-[12%] mt-10 mb-3">
            <span
              onClick={() => previewFile("Land Certificate")}
              className=" bg-[#F491A3] ml-5 text-white px-4 py-2  rounded-lg cursor-pointer"
            >
              Preview
            </span>
            <span
              onClick={() => onDownloadDoc("Land Certificate")}
              className=" bg-[#F491A3] ml-5 text-white px-4 py-2  rounded-lg cursor-pointer"
            >
              Download
            </span>
          </div>
        ) : null}
        <div className="ml-[15%] w-[20%]">
          <p className="text-sm">
            Valid Date{/*<span style={{ color: "red" }}>*</span>*/}
          </p>
          <div className="mt-3 mb-3">
            <DatePickerComp
              onSelected={(item) => dateLandCertificateValidityChange(item)}
              value={docsData?.land_certificate_validity}
              allowPastDate={false}
            />
          </div>
        </div>
      </div>

      <div className="mt-20">
        <p>Guide to Upload Document :</p>
        <div className="flex space-x-20">
          <div>
            <img
              className="w-[210px] h-[136px]"
              src={docuploadGuide1}
              alt="logo"
            />
            <p className="max-w-[210px]">
              Make sure you upload your original document (ex. ID Card), not a
              scan or copy.
            </p>
          </div>
          <div>
            <img
              className="w-[210px] h-[136px]"
              src={docuploadGuide2}
              alt="logo"
            />
            <p className="max-w-[210px]">
              Make sure the document is clearly visible in the photo box
            </p>
          </div>
          <div>
            <img
              className="w-[210px] h-[136px]"
              src={docuploadGuide3}
              alt="logo"
            />
            <p className="max-w-[210px]">
              Make sure the photos you upload are not blurry and the lighting is
              good
            </p>
          </div>
        </div>
      </div>
      <p className="mt-10 text-gray-400 text-sm">*PNG, JPG or PDF</p>
    </>
  );
};
