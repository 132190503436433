export function camelize(str) {
  return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, index) {
    if (+match === 0) return ""; // or if (/\s+/.test(match)) for white spaces
    return index === 0 ? match.toLowerCase() : match.toUpperCase();
  });
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const findIndexByLink = (data, link) => {
  for (let i = 0; i < data.length; i++) {
    if (data[i].link === link) {
      return i;
    }
  }
  return -1; // Return -1 if link is not found
};

const findSubMenu = (link, menu) => {
  for (const item of menu) {
    if (item.link === link) {
      return menu;
    }
    if (item?.submenus?.length > 0) {
      const result = findSubMenu(link, item.submenus);
      if (result) {
        return result;
      }
    }
  }
  return null;
};

export const findParentMenu = (link, data) => {
  console.log(data, "data");
  for (const [index, item] of data.entries()) {
    const result = findSubMenu(link, [item]);
    if (result) {
      return index;
    }
  }
  return null;
};
