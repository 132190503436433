import React, { useEffect, useState } from "react";
import DropDownComp from "../components/DropDownComp";
import {_asyncGetPCPSIC, _asyncGetWarehouses } from "../api";
import closeImg from "../images/FarmPipeLine/close.png";
import { DatePickerComp } from "../components/DatePickerComp";
import { _convertCommaSaparate } from "../config/localdata";
import SearchDropDownComp from "../components/SearchDropDownComp";


export const GeneratePoModal = ({
  hideModal,
  metaData,
  generatePoBody,
  submitPo
}) => {

  const [allWarehouse, updateAllWarehouse] = useState([]);
  const [warehouse, setWarehouse] = useState({"warehouse_name":"Select.."});
  const [paymentMethod, setPaymentMethod] = useState({"method_name":"Select.."});
  const [arrivalDate, setArrivalDate] = useState();
  const [items, setItems] = useState(generatePoBody);
  const [allPsic, updateAllPsic] = useState([]);

  
  async function _getGetAllWarehouse() {
    const data = await _asyncGetWarehouses();
    if (!data) return;
      updateAllWarehouse([...data]);
  }

  async function _getPCPSIC(params) {
    const data = await _asyncGetPCPSIC(params);
    console.log(data,'-----------data---');
    console.log(generatePoBody,'-----------genert---');

    if (!data) return;
    let items = []
    let productionUniqueCode = Object.values(
      generatePoBody.reduce((acc, item) => {
          acc[item.production_code] = item;
          return acc;
      }, {})
     );
    if(productionUniqueCode.length>0){
      for(let i = 0; i<productionUniqueCode.length; i++){
        items.push(...data[productionUniqueCode[i]?.production_code])
      }
    }


    console.log(productionUniqueCode,'ppproduct');
    console.log(items,'----itemss');
    updateAllPsic([...items]);
  }

  useEffect(() => {
    // console.log('---generatePoBody----',generatePoBody);
    _getGetAllWarehouse();
    let productionCode = []
    if(generatePoBody.length>0){
      productionCode = generatePoBody.map((item)=>item.production_code)
      productionCode = productionCode.filter((ite,inde)=>productionCode.indexOf(ite) === inde)
    }
    let data = {
      product_codes:productionCode,
      vendor_id:generatePoBody[0]?.odoo_vendor_id
    }
    // console.log('data----',data);
    _getPCPSIC(data)
  },[]);

  const handleItemChange = (index, key, value) => {
    if(value !== "" && isNaN(value)){
      return;
    }
    if(value < 0){
      return;
    }
    setItems((prevItems) => {
      const updatedItems = [...prevItems];
      updatedItems[index] = { ...updatedItems[index], [key]: value }
      updatedItems[index] = { ...updatedItems[index], ['total_harvest_price']: (value * updatedItems[index]['harvest_amount']) }
      return updatedItems;
    });
  };
  const handleItemDropDownChange = (index, key, value) => {
    setItems((prevItems) => {
      const updatedItems = [...prevItems];
      updatedItems[index] = { ...updatedItems[index], [key]: [value] }
      updatedItems[index] = { ...updatedItems[index], ['decided_unit_price']: value?.price_unit }
      updatedItems[index] = { ...updatedItems[index], ['total_harvest_price']: (value?.price_unit * updatedItems[index]['harvest_amount']) }
      return updatedItems;
    });
  };

  const calculateTotal = (item) => {
    return item.decided_unit_price * item.harvest_amount;
  };

  const grandTotal = items.reduce((total, item) => total + calculateTotal(item),0);


  return (
    <>
      <div className="flex overflow-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center">
        <div className="w-[70%] h-[70%] relative mt-[3%] mb-[3%]">
          {/*content*/}
          <div className="h-full first-letter:rounded-lg shadow-lg relative flex flex-col w-full outline-none focus:outline-none rounded-lg">
          <div className="relative p-10 overflow-auto pt-3 bg-white rounded-t-lg h-full">
              <img
                className="float-right w-[30px] h-[30px] mt-3"
                src={closeImg}
                alt="closeImg"
                onClick={() => {
                  hideModal();
                }}
              />
              <div className="mt-6">
                 <p className="font-semibold text-[15px] text-[#40AE49]">Automatic PO</p>
                </div>
                <div className="mt-5 grid grid-rows-6">

                </div>
                <div className="flex flex-row justify-between">
                    <div className="">
                    <p className="text-black text-[15px] mt-2 mr-3">Vendor Name: {generatePoBody[0]?.odoo_vendor_name}</p>
                  </div>
                <div className="flex">
                    <p className="text-black text-[15px] mt-2 mr-3">
                        Expected Arrival Date 
                      </p>
                      <div className="w-[200px]">
                      {/* <DatePickerComp onSelected={(item) => setArrivalDate(item)}/> */}
                      <DatePickerComp
                        onSelected={(item) => {
                          setArrivalDate(item);
                        }}
                        value={arrivalDate}
                        allowPastDate={true}
                      />
                     </div>
                   
                  </div>
                </div>
                <div className="flex rounded-lg border border-gray-500 px-3 mt-4">
                <table style={{width:'90%'}}>
            <tr>
                <th style={{backgroundColor: '#fff',color: 'black',textAlign: 'center', padding:'7px'}}>PC/PSIC</th>
                <th style={{backgroundColor: '#fff',color: 'black',textAlign: 'center', padding:'7px' }}>Code</th>
                <th style={{backgroundColor: '#fff',color: 'black',textAlign: 'center', padding:'7px' }}>Harvest Batch</th>
                <th style={{backgroundColor: '#fff',color: 'black',textAlign: 'center', padding:'7px' }}>Quantity</th>
                <th style={{backgroundColor: '#fff',color: 'black',textAlign: 'center', padding:'7px' }}>UoM</th>
                <th style={{backgroundColor: '#fff',color: 'black',textAlign: 'center', padding:'7px' }}>Unit Price</th>
                <th style={{backgroundColor: '#fff',color: 'black',textAlign: 'center', padding:'7px' }}>Price</th>
	        </tr>
            {items.map((item, index)=>{
                return(
                <tr key={item.product_id}>
                {/* <td  style={{textAlign: 'center',padding:'7px', backgroundColor: 'white'}}>{item?.product_name}</td> */}
                <td style={{backgroundColor: 'white'}}>
                <SearchDropDownComp
                    list={allPsic.filter((ite)=>ite.mpc === item?.production_code)}
                    label={"Select"}
                    width="w-[400px]"
                    active={item?.psic && item.psic[0]}
                    type={"psic_name"}
                    onSelected={(e) => handleItemDropDownChange(index, "psic", e)}
                    />
                </td>
                <td  style={{textAlign: 'center',padding:'7px', backgroundColor: 'white'}}>{item?.production_code}</td>
                <td  style={{textAlign: 'center',padding:'7px', backgroundColor: 'white'}}>{item?.harvest_batch_id}</td>
                <td  style={{textAlign: 'center',padding:'7px', backgroundColor: 'white'}}>{item?.harvest_amount}</td>
                <td  style={{textAlign: 'center',padding:'7px', backgroundColor: 'white'}}>Kg</td>
                <td  style={{textAlign: 'center',padding:'7px', backgroundColor: 'white'}}>
                    <input
                        type="text"
                        value={item.decided_unit_price}
                        onChange={(e) =>
                          handleItemChange(
                            index,
                            "decided_unit_price",
                            e.target.value=parseInt(e.target.value) || 0
                          )
                        }
                        className="border border-gray-700 w-[70px] text-center"
                      />
                  </td>
                <td  style={{textAlign: 'center',padding:'7px', backgroundColor: 'white'}}>{_convertCommaSaparate(item?.total_harvest_price) || 0}</td>
            </tr>
                )
            })} 
        </table>
        </div>
        <div className="float-right mr-32 mt-3">
        <p className="font-bold">Total: Rp. {_convertCommaSaparate(grandTotal)}</p>
        </div>
        <div className="mt-12 grid grid-cols-5">
        <div>
        <p className="font-bold">Deliver to :</p>
        </div>
        <div>
        <DropDownComp
          listData={allWarehouse}
          active={warehouse}
          type={"warehouse_name"}
          onSelected={(item) => setWarehouse(item)}
          customStyles={"rounded-lg justify-between text-black text-[10px]"}
          customMenuStyle={"relative inline-block text-left w-[250px] px-5"}/>
        </div>
    </div>
      <div className="mt-5 grid grid-cols-5">
      <div>
        <p className="font-bold">Purchase Method :</p>
        </div>
        <div>
      <DropDownComp
          listData={metaData?.odooPurchaseMethods}
          active={paymentMethod}
          type={"method_name"}
          onSelected={(item) => setPaymentMethod(item)}
          customStyles={"rounded-lg justify-between text-black text-[10px]"}
          customMenuStyle={"relative inline-block text-left w-[250px] px-5"}/>
      </div>
      </div>
            </div>
            {/*footer*/}
            <div className="py-4 rounded-b-lg bg-white w-full px-10">
              <div className="flex justify-end">
                <p
                  onClick={() => submitPo({arrivalDate, warehouse, paymentMethod, items, grandTotal})}
                  className="bg-[#3EB049] text-white px-10 py-2 border border-[#3EB049] rounded-full cursor-pointer">
                  Submit to Odoo
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>{" "}
    </>
  );
};
