import React, { useState, useEffect } from "react";
import { PageLayoutComp } from "./PageLayoutComp";
import { IoIosArrowRoundBack } from "react-icons/io";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import submitted from "../images/submitted.png";
import unsubmitted from "../images/unsubmitted.png";
import { ImageSliderModal } from "../Models/ImageSlider";

import { _fetchMetaData } from "../api";

import { getDateFormatMoment } from "../config/localdata";

const WeeklyReportDetail = ({ handleShowDetail, detailPageData, weekData }) => {
  console.log("++++++++++XXXX?+++++++++", weekData);

  const [selectedCrop, setSelectedCrop] = useState("");
  const [selectedVeg, setSelectedVeg] = useState({});
  const [selectedGen, setSelectedGen] = useState({});
  const [isVegitative, setIsvegitative] = useState(false);
  const generalReport = detailPageData?.detailed_report[1];
  const vegitativeReport = detailPageData?.detailed_report[2];
  const generativeReport = detailPageData?.detailed_report[3];
  const [metaData, setMetaData] = useState();
  const [showCropReport, setShowCropReport] = useState(false);
  const [images, setImages] = useState([]);



  const CropsChunks = [];
  for (let i = 0; i < detailPageData?.products_info?.length; i += 4) {
    CropsChunks.push(detailPageData?.products_info.slice(i, i + 4));
  }

  useEffect(() => {
    _fetchData();
  }, []);

  const handleImgClick = (item) => {
    setImages(item);
    setShowCropReport(true);
  };


  async function _fetchData() {
    let metaData = await _fetchMetaData();
    setMetaData(metaData);
  }

  const getMetadataName = (id) => {
    let data = "";
    data = metaData?.weeklyReportSubTypes?.filter((e) => e.id == id);
    return data && data[0] && data[0]?.sub_type_name;
  };

  const getUIWithDetails = (mt, subTypeId, notes, pictures, details, rowIndex=0) => {
    return (
      //${rowIndex%2==0 ? `bg-white`:``}
      <div className={`bg-white py-2 px-4 mt-2`}>
        <div className={`mt-${mt}`}>
          <p className="text-sm font-bold text-green">
            {getMetadataName(subTypeId)}
          </p>
        </div>
        <div className="flex mt-2 ">
          <div className="w-1/2 pr-4">
            <h2 className="text-sm font-semibold mb-2">Notes</h2>
            <p className="text-xs">{notes}</p>
          </div>
          <div className="w-1/2 pr-4">
            <h2 className="font-semibold mb-2">Pictures</h2>
            <div className="flex gap-3 overflow-x-auto">
              {pictures?.map((pic) => {
                return (
                  <img
                    className="w-[90px] h-[90px] rounded-[10px] "
                    key={pic?.id}
                    src={pic?.doc_link}
                    onClick={() => {
                      handleImgClick(
                        pictures
                      );
                    }}
                  />
                );
              })}
            </div>
          </div>
        </div>

        {details ? (
          details.issue_mitigation_notes ? (
            <>
              <div>
                <div className="mt-2">
                  <p className="text-sm font-bold">Mitigation</p>
                </div>
                <div className="flex mt-2 ">
                  <div className="w-1/2 pr-4">
                    <h2 className="text-sm font-semibold mb-2">Notes</h2>
                    <p className="text-xs">{details?.issue_mitigation_notes}</p>
                  </div>
                </div>
              </div>
            </>
          ) : details.is_rain_reported !== null ? (
            <>
              <div>
                <div className="mt-2">
                  <p className="text-sm font-bold">
                    {!!details.is_rain_reported ? "Rained" : "No Rain"}
                  </p>
                </div>
              </div>
            </>
          ) : details.affected_crops_info !== null ? (
            <>
              <div>
                <div className="flex mt-2 ">
                  <div className="w-1/2 pr-4">
                    <p className="text-xs">
                      {details?.affected_crops_info}% Crops Affected
                    </p>
                  </div>
                </div>
              </div>
            </>
          ) : details.dosage_info !== null ? (
            <>
              <div>
                <div className="mt-2">
                  <p className="text-sm font-bold">Dosage</p>
                </div>
                <div className="flex mt-2 ">
                  <div className="w-1/2 pr-4">
                    <p className="text-xs">{details?.dosage_info}</p>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <></>
          )
        ) : (
          <></>
        )}
      </div>
    );
  };

  const getUIWithoutNotes = (mt, subTypeId, reportData, rowIndex=0) => {
    const fertilizers = reportData?.fertilizers_used;
    const details = reportData?.weekly_report_detail;
    return (
      <div className={`bg-white py-2 px-4 mt-2`}>
        <div className={`mt-${mt}`}>
          <p className="text-sm font-bold text-green">
            {getMetadataName(subTypeId)}
          </p>
        </div>
        {details && details.is_rain_reported !== null ? (
          <>
            <div>
              <div className="mt-2">
                <p className="text-sm font-bold">
                  {!!details.is_rain_reported ? "Rained" : "No Rain"}
                </p>
              </div>
            </div>
          </>
        ) : fertilizers && fertilizers.length ? (
          <div className="flex mt-2 ">
            <div className="w-1/2 pr-4">
              {fertilizers.map((fr, index) => {
                const item = fr.item_info;
                const displayData =
                  item?.bom_data?.display_name || item?.sku?.name;
                return (
                  <p className="text-xs">
                    {index + 1}. {displayData}
                  </p>
                );
              })}
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  };

  const getUIforYieldEstimation = (mt, subTypeId, reportData, rowIndex=0) => {
    const fertilizers = reportData?.future_harvests;
    return (
      <div className={`bg-white py-2 px-4 mt-2`}>
        <div className={`mt-${mt}`}>
          <p className="text-sm font-bold text-green">
            {getMetadataName(subTypeId)}
          </p>
        </div>
        <div className="flex mt-2 ">
          <div className="w-1/2 pr-4">
            <h2 className="text-sm font-semibold mb-2">Notes</h2>
            <p className="text-xs">{reportData?.notes}</p>
          </div>
        </div>
        {fertilizers && fertilizers.length ? (
          <div className="flex mt-2 ">
            <div className="w-1/2 pr-4">
              {fertilizers.map((item) => {  
                return (
                  <p className="text-xs">
                    Week {item?.future_week_number} : {item?.harvest_estimate}
                  </p>
                );
              })}
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  };

  const getVegitativeReport = () => {
    const selectedProductVegReport =
      vegitativeReport && vegitativeReport[selectedCrop];
    const reports =
      selectedProductVegReport && selectedProductVegReport["reports"];
    console.log(`reports----->>>`);
    console.log(reports);
    if (reports && Object.keys(reports).length) {
      return Object.keys(reports).map((reportSubTypeId, index) => {
        const reportData = reports[reportSubTypeId];
        const reportDetails = reportData?.weekly_report_detail;
        const mt = index == 0 ? 2 : 8;
        return ![2, 9].includes(+reportSubTypeId)
          ? getUIWithDetails(
              mt,
              reportSubTypeId,
              reportData?.notes,
              reportData?.pictures,
              reportDetails,
              index
            )
          : getUIWithoutNotes(mt, reportSubTypeId, reportData, index);
      });
    } else {
      return <p>No Reports Available!</p>;
    }
  };
  const getGenerativeReport = () => {
    const selectedProductVegReport =
    generativeReport && generativeReport[selectedCrop];
    const reports =
      selectedProductVegReport && selectedProductVegReport["reports"];
    console.log(`reports----->>>`);
    console.log(reports);
    if (reports && Object.keys(reports).length) {
      return Object.keys(reports).map((reportSubTypeId, index) => {
        const reportData = reports[reportSubTypeId];
        const reportDetails = reportData?.weekly_report_detail;
        const mt = index == 0 ? 2 : 8;
        return ![9, 8].includes(+reportSubTypeId)
          ? getUIWithDetails(
              mt,
              reportSubTypeId,
              reportData?.notes,
              reportData?.pictures,
              reportDetails,
              index
            )
          : +reportSubTypeId === 8 ? getUIforYieldEstimation(mt, reportSubTypeId, reportData, index)
          :getUIWithoutNotes(mt, reportSubTypeId, reportData, index)
      });
    } else {
      return <p>No Reports Available!</p>;
    }
  };

  const handleCropSelect = (event) => {
    setSelectedCrop(event?.target?.value);
    setSelectedVeg(vegitativeReport[event.target.value]);
    setSelectedGen(generativeReport[event.target.value]);
  };

  return (
    <div className="h-[100%] ">
      {showCropReport ? (
        <ImageSliderModal
          hideModal={() => {
            setShowCropReport(false);
          }}
          images={images}
        />
      ) : null}
      <div className="flex justify-between mb-2">
        <p className="text-2xl pt-2 font-bold ">Weekly Report Detail</p>

        <div className="flex">
          <span className="bg-white border w-[240px] border-[#808080] py-2 px-4 pr-8 rounded-[10px] ">
            {" "}
            <p className="font-bold inline ">
              Week :&nbsp;
              <span className="inline-element">
                {weekData?.week_number}
              </span>{" "}
              &nbsp;&nbsp;&nbsp;{" "}
            </p>
            <span className=" font-medium text-gray-500   text-sm">
              {" "}
              {weekData?.week_date_range}
            </span>
          </span>
          <div class="w-0.5 ml-2 mr-2 bg-[#D0D0D0]"></div>
          <span className=" bg-white border border-[#808080] py-2 px-4 pr-8 rounded-[10px] ">
            <p className="font-bold inline ">
              Year :&nbsp;
              <span className="inline-element">
                {weekData?.year_number}
              </span>{" "}
              &nbsp;&nbsp;&nbsp;{" "}
            </p>
          </span>
        </div>

        <div
          onClick={() => handleShowDetail(null)}
          className="flex cursor-pointer"
        >
          <IoIosArrowRoundBack size={22} color={"green"} className="mt-2" />
          <p className="text-green text-md mt-2 cursor-pointer font-bold">
            Back to List
          </p>
        </div>
      </div>

      <div className=" rounded-2xl bg-white p-2 ">
        <div className="flex justify-between bg-white p-3 rounded-t-2xl">
          <div className="flex basis-1/2 justify-between">
            <p className="text-[20px] font-bold">
              {detailPageData?.summary?.farm_info?.farm_name}
            </p>
            <p>
              FaaS PIC :{" "}
              <span className="text-xl font-semibold">
                {detailPageData?.summary?.general_report_created_by?.user_name}
              </span>
            </p>
          </div>
          <div>
            <p className="text-[#808080]">
              Submitted at{" "}
              {getDateFormatMoment(
                detailPageData?.summary?.general_report_last_updated,
                true
              )}
            </p>
          </div>
        </div>
        <div className="flex h-[20%] flex-col p-2  w-full">
        <div className=" border-[#9FD7A4] border-b pb-2 h-36">
          <p className="text-[#252525] font-medium ml-3">Crops</p>
          <div className="flex flex-wrap  gap-x-6 gap-y-4 w-full ">
                  {CropsChunks.map((item) => (
                    <div className="flex flex-col text-xs break-all ">
                      {item.map((farm) => (
                        <div className="mb-[0.1rem] flex items-center">
                          <img
                            className="w-4 h-4"
                            src={farm?.is_report_submitted ? submitted : unsubmitted}
                            style={{ display: "inline", marginRight: "0.1rem" }}
                          />
                          {farm?.product_name} ({farm?.production_code})
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
        </div>
        </div>
        <div className="px-4 pt-5">
          <p className="text-lg font-semibold">Farm General Report</p>
        </div>
        <div className="flex mt-2 px-4  ">
          <div className="w-1/2 pr-4">
            <h2 className="font-semibold mb-2">Notes</h2>
            <p>{generalReport?.notes}</p>
          </div>
          <div className="w-1/2 pr-4">
            <h2 className="font-semibold mb-2">Pictures</h2>
            <div className="flex gap-3 overflow-x-auto">
              {generalReport.pictures?.map((pic) => {
                return (
                  <img
                    className="w-[90px] h-[90px] rounded-[10px] "
                    key={pic?.id}
                    src={pic?.doc_link}
                    onClick={() => {
                      handleImgClick(
                        generalReport?.pictures
                      );
                    }}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>

      <div className="mt-3   rounded-2xl ">
        <div className="rounded-2xl">
          <div className="flex justify-between bg-white p-3 ">
            {detailPageData?.products_info.length > 0 && (
              <div>
                <select
                  onChange={handleCropSelect}
                  className=" ml-5 border w-[300px] h-[44px]   border-[#808080] py-2 px-4 pr-8 rounded-[10px] "
                  value={selectedCrop}
                >
                  <option value="" disabled selected>
                    Select Crop{" "}
                  </option>
                  {detailPageData?.products_info
                    ?.filter((item) => !!item?.is_report_submitted)
                    .map((item) => {
                      return (
                        <option key={item?.id} value={`${item?.id}`}>
                          {item?.product_name}
                        </option>
                      );
                    })}
                </select>
              </div>
            )}
            <div className="flex ">
              <div
                className={`flex -mr-2 z-10  ${
                  isVegitative ? "bg-[#3EB049]" : "bg-gray-400"
                } ] border-[#9FD7A4] text-white px-4 py-2  justify-between items-center  rounded-[8px] cursor-pointer`}
              >
                <p
                  className="text-[15px] font-normal"
                  onClick={() => setIsvegitative(true)}
                >
                  Vegetative Phase
                </p>
              </div>
              <div
                className={`flex bg-[ ${
                  !isVegitative ? "bg-[#3EB049]" : "bg-gray-400"
                } ] text-gray-100 px-4 py-2  rounded-r-[8px] justify-between items-center  cursor-pointer`}
              >
                <p
                  className="text-[15px] font-normal"
                  onClick={() => setIsvegitative(false)}
                >
                  Generative Phase
                </p>
              </div>
            </div>
            <div>
              <p className="text-[#808080]">
                {/* Submitted at 15 January 2024 : 11:35 */}
              </p>
            </div>
          </div>
        </div>
        <div className="bg-[#E7F2EE] rounded-xl p-2">
          {isVegitative ? getVegitativeReport() : getGenerativeReport()}
        </div>
      </div>
    </div>
  );
};

export default WeeklyReportDetail;
