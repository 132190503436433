import React, { useState } from "react";
import { Header } from "./Header";
import { SideNavComp } from "./SideNavComp";
import { ConfirmationModal } from "../Models/ConfirmationModal";
import { _apiLogout } from "../api";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

//showNavIcons passing this old
export const PageLayoutComp = ({ children, user }) => {
  const location = useNavigate();
  const appStates = useSelector((state) => state.appStoredData);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showNavIcons, setShowNavIcons] = useState(false);

  async function _logout() {
    await _apiLogout(location);
  }

  return (
    <div className="w-screen h-screen">
      {showConfirmModal ? (
        <ConfirmationModal
          label={`Are you sure want to Sign Out ?`}
          label2={"No, Later"}
          label3={"Yes, Sign Out"}
          type="signOut"
          hideModal={() => setShowConfirmModal(false)}
          onConfirm={() => {
            _logout();
          }}
        />
      ) : null}
      <Header
        user={user}
        logout={() => setShowConfirmModal(true)}
        navClick={() => setShowNavIcons(!showNavIcons)}
      />
      <div className="flex w-screen h-[calc(100vh-68px)]">
        <SideNavComp/>
        <div
          className={`${
            appStates.navIcon ? "w-full" : "w-[85%]"
          }  bg-[#E6F1ED] p-4`}
        >
          {children}
        </div>
      </div>
    </div>
  );
};
