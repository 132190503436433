import React, { useState } from "react";
import closeImg from "../images/FarmPipeLine/close.png";
import { formatDateGetDayOnly, getDateFormatMoment } from "../config/localdata";
import { ImageSliderModal } from "./ImageSlider";


export const CropsReportModal = ({
  hideModal,
  logData,
}) => {
const [showCropReport, setShowCropReport] = useState(false);
const [images, setImages] = useState([]);

const handleImgClick = (item) =>{
    setImages(item)
    setShowCropReport(true)
}

const styleDot = {
  height: '10px',
  width: '10px',
  borderRadius: '50%',
  display: 'inline-block'
}


  return (
<>
  <div className="flex overflow-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center">
  {showCropReport ? (
        <ImageSliderModal
          hideModal={() => {setShowCropReport(false)}}
          images={images}
        />
 ) : null}
    <div className="relative mt-[3%] mb-[3%]">
      {/*content*/}
      <div className="h-full first-letter:rounded-lg shadow-lg relative flex flex-col w-full outline-none focus:outline-none rounded-lg">
        <div className="flex items-center relative pl-4 pr-4 overflow-auto pt-3 bg-[#40AE49] rounded-t-lg h-full w-[500px]">
          <p className="text-[16px] w-full font-bold text-white text-center mb-2 mt-2">
            REPORT
          </p>
          <img
            className="float-right w-[30px] h-[30px]"
            src={closeImg}
            alt="closeImg"
            onClick={() => {
              hideModal();
            }}
          />
        </div>
        
        <div
          className="rounded-b-lg bg-white w-[500px]"
          style={{ overflow: "auto", maxHeight: "500px" }}
        >
          {/* {logData?.map((data, index)=>{
         return(
          <div style={{marginLeft:'20px'}}>
            <div style={{...styleDot, backgroundColor: '#40AE49'}}></div>
            {index !== 0 && logData.length - 1 === index ? null: <div style={{borderLeft: '3px solid #40AE49', height: '50px', marginTop:'-7px',marginBottom:'-8px', marginLeft:'3px'}}></div>}
          </div> 
    )
          })} */}
          

          <div className="w-full">
            <p className="[font-family:'Poppins-Bold',Helvetica] font-bold text-[#252525] ml-10 p-2 text-[16px]">
              {logData[0]?.planting_activity?.rab_info?.rab_name}
            </p>
          </div>


          
        
        {logData?.map((data, index)=>{
        return(
          <div key={index} className="ml-[14%] mb-5 mt-5 w-[350px]  p-1 h-[250px] bg-white rounded-[10px] overflow-hidden border border-solid border-grey">
            <p className="[font-family:'Poppins-Medium',Helvetica] p-2 font-bold font-medium text-[#40AE49] text-[16px]">
              {formatDateGetDayOnly(new Date(data?.created_at)) +', '+  getDateFormatMoment(data?.created_at, true)}
            </p>
            <p className="[font-family:'Poppins-Regular',Helvetica] ml-5 font-normal text-[#808080] text-[14px]">
              {data?.notes}
            </p>
            
            <div className="flex ml-5 mt-10 w-[120px] h-[25px] items-center justify-center px-[5px] py-[2px] border border-solid border-black">
              <div className="w-fit [font-family:'Poppins-Regular',Helvetica] font-normal text-black text-[10px] text-center">
                {data?.current_growth_stage?.stage_name}
              </div>
             
            </div>
            
            <div className="flex overflow-auto mt-5">
            {data?.planting_pictures.map((item, idx)=>{
                return(
                <div key={idx}>
                    <img
                    className="p-1 w-[75px] h-[62px] rounded-lg"
                    alt="Kangkung"
                    src={item.doc_link}
                    onClick={()=>{handleImgClick(data?.planting_pictures)}}
                />
                </div>
                )
            })}
            </div>
          </div>)
         })}
         </div>
         </div>
        </div>
  </div>
  <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>{" "}
</>
);
};
