import { useEffect, useState } from "react";
import { _asyncGetRabDetails, _fetchMetaData, _updateRabStatus, _updateRabRate } from "../api";
import { PageLayoutComp } from "./PageLayoutComp";
import { useNavigate, useParams } from "react-router-dom";
import stamp from "../images/stamp.png";
import { getDateFormatMoment, _convertCommaSaparate } from "../config/localdata";
import { ConfirmationModal } from "../Models/ConfirmationModal";
import { EditRateModal } from "../Models/EditRateModal";
import { SuccessfullMesg } from "../Models/SuccessfullMesg";
import Beleaf_Logo from "../../src/images/Beleaf_Logo.png"
import rdIcon from "../../src/images/rdIcon.png"
export const PreviewRab = () => {
  let user = JSON.parse(localStorage.getItem("user_data"));
  const location = useNavigate();
  const params = useParams();
  const [metaData, setMetaData] = useState();
  const [rabData, setRabData] = useState({});
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showConfirmModalRate, setShowConfirmModalRate] = useState(false);
  const [openEditRateModal, setOpenEditRateModal] = useState(false);
  const [rate, setRate] = useState();
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  useEffect(() => {
    _fetchData();
  }, []);

  console.log("dsahgdgjahgdjhsa",rabData?.data?.is_internal_farm_rab)
  // rabData?.planting_budget_info.filter(e => e.payment_type_id === 2).length > 0


  async function _fetchData() {
    let metaData = await _fetchMetaData();
    setMetaData(metaData);
    let details = await _asyncGetRabDetails(params?.id);
    setRabData(details)
  }

  const onBackClick = () => {
    location('/budget-plan-rab');
  }

  const styles = {
    '*': {
      padding: '0',
      margin: '0',
    },
    maincontainer: {
      width: '50%',
      display: 'flex',
      justifyContent: 'center',
      padding: '50px'
    },
    container: {
      width: '100%',
      backgroundColor: '#fff',
      padding: '10px 0',
      position: 'relative'
    },
    heading: {
      display: 'flex',
      // alignItems: 'center',
      // justifyContent: 'center',
      fontFamily: 'sans-serif',
      fontSize: '11px',
      fontWeight: '600',
    },
    maindiv: {
      display: 'flex',
    },
    Kebun1: {
      fontFamily: 'sans-serif',
      fontWeight: '600',
      fontSize: '7px',
      width: '20%',
    },
    Kebun: {
      fontFamily: 'sans-serif',
      fontWeight: '600',
      fontSize: '7px',
      // background-color: 'red',
    },
    table: {
      border: '1px solid black',
      borderCollapse: 'collapse',
    },
    mainparagraphdiv: {
      padding: '15px',
    },
    tableheading: {
      backgroundColor: '#D0D0D0',
    },
    td: {
      fontSize: '9px',
      fontWeight: '500',
      fontFamily: 'sans-serif',
      padding: '2px',
      border: '1px solid black',
      borderCollapse: 'collapse',
    },
    th: {
      fontSize: '7px',
      fontWeight: '600',
      fontFamily: 'sans-serif',
      padding: '4px 1px',
      border: '1px solid black',
      borderCollapse: 'collapse',
    },
    th1: {
      fontSize: '7px',
      fontWeight: '600',
      fontFamily: 'sans-serif',
      padding: '4px 1px',
      backgroundColor: '#E7F2EE',
      //backgroundColor: 'rgb(203, 227, 203)',
      border: '1px solid black',
      borderCollapse: 'collapse',
    },
    th2: {
      fontSize: '7%',
      fontWeight: '600',
      fontFamily: 'sans-serif',
      padding: '4px 1px',
      backgroundColor: '#9FD7A4'
    },
    tabledive: {
      marginTop: '9px',
    },
    tabledive1: {
      marginTop: '6px',
    },
    table: {
      width: '100%',
    },
    lasttable: {
      border: '1px solid black',
      width: '32%',
      //height: '10vh',
      textAlign: 'center',
      borderRadius: '7px',
    },
    Mitra2: {
      display: 'flex',
      height: '5vh',
      alignItems: 'flex-end',
      justifyContent: 'center',
      borderTop: '1px solid black',
    },
    Mitra3: {
      width: '100%',
      borderTop: '1px solid black',
    },
    MitraContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: '1%',
      padding: '0px 15px'
      //height: '7vh',
    },
    table: {
      marginTop: '1%',
    },
    '@media screen and (max-width: 1800px) and (min-width: 400px)': {
      MitraContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '1%',
        //height: '20vh',
      },
      lasttable: {
        border: '1px solid black',
        width: '32%',
        //height: '100%',
        textAlign: 'center',
        borderRadius: '7px',
      },
      Mitra2: {
        height: '14.8vh',
      },
    },
  };

  const _addRabData = async () => {
    let res = await _updateRabStatus(params?.id, 'approve', {})
    //setShowDetailsRab(false);
  }

  const _updateRate = async () => {
    let body = {
      rate: rate?.name
    }
    let response = await _updateRabRate(params?.id, body);
    if (response.status === 200 && response.statusText === "OK") {
      setShowSuccessModal(true);
      setTimeout(() => {
        location('/budget-plan-rab-preview/' + params?.id)
        setShowSuccessModal(false);
      }, 1000);
    }
  }

  return (
    <PageLayoutComp metaData={metaData} user={user}>
      {showSuccessModal ? (
        <SuccessfullMesg label={`Rate Updated`} />
      ) : null}
      {openEditRateModal ? (
        <EditRateModal
          hideModal={() => setOpenEditRateModal(false)}
          setRate={setRate}
          submitRate={() => setShowConfirmModalRate(true)}
        />
      ) : null}
      {showConfirmModal ? (
        <ConfirmationModal
          label={'Are you sure want to Approve this RAB ?'}
          label2="No"
          type="planting"
          customStyles={true}
          hideModal={() => setShowConfirmModal(false)}
          onConfirm={() => {
            setShowConfirmModal(false);
            _addRabData();
          }}
        />
      ) : null}

      {showConfirmModalRate ? (
        <ConfirmationModal
          label={'Are you sure want to update rate ?'}
          label2="No"
          type="planting"
          customStyles={true}
          hideModal={() => setShowConfirmModalRate(false)}
          onConfirm={() => {
            setShowConfirmModalRate(false);
            setOpenEditRateModal(false)
            _updateRate();
          }}
        />
      ) : null}

      <div className="bg-[#E6F1ED] p-2">
        <div className="">
          <div className="flex justify-between">
            <p className="text-3xl font-bold mb-0">RAB</p>
          </div>
        </div>
        <div className="">
          <div className="flex bg-[#E6F1ED]">
            <div className="flex bg-white text-[#3EB049] px-7 py-2 justify-between items-center rounded-lg rounded-tr-3xl cursor-pointer">
              <p className="text-[15px] font-normal">Preview</p>
            </div>
          </div>

          <div className="bg-white px-5 py-5" >
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div style={styles.maincontainer} className="shadow-xl">
                <div style={styles.container}>
                  {/* <img src={stamp} style={{ 'opacity': .15, position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, zIndex: 1 }} /> */}
                  <div className="flex justify-space-between">
                    <div className="w-[70%]">
                    <p style={styles.heading}>COMPACT INVESTMENT 1 Ha</p>
                    <p style={styles.heading}>AGRIBISNIS HONEY SWEET POTATO PT BELEAF KEBUN INDONESIA</p>
                    </div>
                <div className="flex w-[30%] h-[10vh] justify-end">
                <img
                    src={Beleaf_Logo}
                    className="h-[56%] w-[50%]"/>
                        <img
                    src={rdIcon}
                    className="h-[45%] w-[28%] ml-[6px]"/>
                </div>
                  </div>
                  <div style={styles.mainparagraphdiv}>
                    <div style={styles.maindiv}>
                      <p style={styles.Kebun1}>Kebun</p>
                      <p style={styles.Kebun}>: {rabData?.data?.farm?.farm_name}</p>
                    </div>
                    <div style={styles.maindiv}>
                      <p style={styles.Kebun1}>Perkiraan Produksi</p>
                      <p style={styles.Kebun}>: {_convertCommaSaparate(rabData?.data?.total_produce_quantity)} Kg</p>
                    </div>
                    <div style={styles.maindiv}>
                      <p style={styles.Kebun1}>Tanaman</p>
                      <p style={styles.Kebun}>: {rabData?.data?.product?.product_name + ` (` + rabData?.data?.product?.production_code + `)`}</p>
                    </div>
                    {/* <div style={styles.maindiv}>
                        <p style={styles.Kebun1}>Perkiraan Panen</p>
                        <p style={styles.Kebun}>: {rabData?.data?.expected_harvest_date}</p>
                    </div> */}
                    <div style={styles.maindiv}>
                      <p style={styles.Kebun1}>Tanggal Tanam</p>
                      <p style={styles.Kebun}>: {getDateFormatMoment(rabData?.data?.planting_date)}</p>
                    </div>

                    <div style={{ width: '100%', ...styles.tabledive }}>
                      <p style={styles.Kebun}>A. Target</p>
                      <div >
                        <table style={{ width: '100%', ...styles.table }}>
                          <thead>
                            <tr style={styles.tableheading}>
                              {/* <th style={{ ...styles.th, width: '7%' }}>No.</th> */}
                              <th style={{ ...styles.th, width: '23%' }}>Uraian</th>
                              <th style={{ ...styles.th, width: '12%' }}>Vol.(Kg)</th>
                              <th style={{ ...styles.th, width: '17%' }}>Harga</th>
                              <th style={{ ...styles.th, width: '17%' }}>Nilai</th>
                              <th style={{ ...styles.th, width: '26%' }}>Keterangan</th>
                            </tr>
                          </thead>
                          <tbody>

                            {/* {rabData?.data?.operational_budget_info.map((item, index)=>{
                                    return( */}
                            { Number(rabData?.data?.target_harvest_quantity_A) ?
                              <tr>
                              {/* <td style={{ width: '7%', ...styles.td }}>{1}</td> */}
                              <td style={{ ...styles.td, width: '23%' }}>Grade A</td>
                              <td style={{ ...styles.td, width: '12%' }}>{_convertCommaSaparate(rabData?.data?.target_harvest_quantity_A)}</td>
                              <td style={{ ...styles.td, width: '17%' }}>Rp. {_convertCommaSaparate(rabData?.data?.target_unit_price_A)}</td>
                              <td style={{ ...styles.td, width: '17%' }}>Rp. {_convertCommaSaparate(rabData?.data?.total_sales_target_A)}</td>
                              <td style={{ ...styles.td, width: '26%' }}>{ }</td>
                            </tr>:null}

                            { Number(rabData?.data?.target_harvest_quantity_B) ?
                            <tr>
                              {/* <td style={{ width: '7%', ...styles.td }}>{2}</td> */}
                              <td style={{ ...styles.td, width: '23%' }}>Grade B</td>
                              <td style={{ ...styles.td, width: '12%' }}>{_convertCommaSaparate(rabData?.data?.target_harvest_quantity_B)}</td>
                              <td style={{ ...styles.td, width: '17%' }}>Rp. {_convertCommaSaparate(rabData?.data?.target_unit_price_B)}</td>
                              <td style={{ ...styles.td, width: '17%' }}>Rp. {_convertCommaSaparate(rabData?.data?.total_sales_target_B)}</td>
                              <td style={{ ...styles.td, width: '26%' }}>{ }</td>
                            </tr>:null}

                            {Number(rabData?.data?.target_harvest_quantity_C) ? <tr>
                              {/* <td style={{ width: '7%', ...styles.td }}>{3}</td> */}
                              <td style={{ ...styles.td, width: '23%' }}>Grade C</td>
                              <td style={{ ...styles.td, width: '12%' }}>{_convertCommaSaparate(rabData?.data?.target_harvest_quantity_C)}</td>
                              <td style={{ ...styles.td, width: '17%' }}>Rp. {_convertCommaSaparate(rabData?.data?.target_unit_price_C)}</td>
                              <td style={{ ...styles.td, width: '17%' }}>Rp. {_convertCommaSaparate(rabData?.data?.total_sales_target_C)}</td>
                              <td style={{ ...styles.td, width: '26%' }}>{ }</td>
                            </tr>:null}

                            {/* )})} */}

                            <tr>
                              <td colSpan="3" style={{ textAlign: 'right', ...styles.th1 }}>Jumlah penjualan</td>
                              <td style={styles.th1} colSpan="2">Rp. {_convertCommaSaparate(rabData?.data?.total_sales_target)}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div style={styles.tabledive1}>
                      <p style={styles.Kebun}>B. Rincian Biaya Operasional Lahan</p>
                      <div>
                        <table style={{ width: '100%', ...styles.table }}>
                          <thead>
                            <tr style={styles.tableheading}>
                              <th style={{ ...styles.th, width: '7%' }}>No.</th>
                              <th style={{ ...styles.th, width: '23%' }}>Uraian</th>
                              <th style={{ ...styles.th, width: '12%' }}>Vol.(Kg)</th>
                              <th style={{ ...styles.th, width: '17%' }}>Harga</th>
                              <th style={{ ...styles.th, width: '17%' }}>Nilai</th>
                              <th style={{ ...styles.th, width: '26%' }}>Keterangan</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td style={styles.td} colSpan="1">I</td>
                              <td style={styles.td} colSpan="5">Lahan</td>
                            </tr>
                            {rabData?.data?.operational_budget_info.map((item, index) => {
                              return (

                                <tr>
                                  <td style={{ width: '7%', ...styles.td }}>{index + 1}</td>
                                  <td style={{ ...styles.td, width: '23%' }}>{item.details}</td>
                                  
                                  <td style={{ ...styles.td, width: '12%' }}>{_convertCommaSaparate(item.quantity)}</td>
                                  <td style={{ ...styles.td, width: '17%' }}>Rp.{_convertCommaSaparate(item.unit_price)}</td>
                                  <td style={{ ...styles.td, width: '17%' }}>Rp.{_convertCommaSaparate(item.quantity * item.unit_price)}</td>
                                  <td style={{ ...styles.td, width: '26%' }}>{item.notes}</td>
                                </tr>
                              )
                            })}
                            <tr>
                              <td colSpan="4" style={{ textAlign: 'right', ...styles.th1 }}>Jumlah Sewa Lahan</td>
                              <td style={styles.th1} colSpan="2">Rp.{_convertCommaSaparate(rabData?.data?.total_field_rent)}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div style={styles.tabledive1}>
                      <table style={{ width: '100%', ...styles.table }}>
                        <tbody>
                          <tr>
                            <td style={styles.td} colSpan="1">II</td>
                            <td style={styles.td} colSpan="5">Bahan Lahan</td>
                          </tr>
                          {rabData?.data?.planting_budget_info.map((item, index) => {
                            return (
                              <tr>
                                <td style={{ width: '7%', ...styles.td }}>{index + 1}</td>
                                <td style={{ width: '23%', ...styles.td }}>{item?.bom_data?.display_name || item?.sku?.name}</td>
                                <td style={{ width: '12%', ...styles.td }}>{_convertCommaSaparate(item.quantity)}</td>
                                <td style={{ width: '17%', ...styles.td }}>Rp.{_convertCommaSaparate(item.unit_price)}</td>
                                <td style={{ ...styles.td, width: '17%' }}>Rp.{_convertCommaSaparate(item.target_product)}</td>
                                <td style={{ ...styles.td, width: '26%' }}>{item.notes}</td>
                              </tr>
                            )
                          })}
                          <tr>
                            <td colSpan="4" style={{ textAlign: 'right', width: '60%', ...styles.th1 }}>Jumlah Bahan-bahan</td>
                            <td colSpan="2" style={{ width: '40%', ...styles.th1 }}>Rp.{_convertCommaSaparate(rabData?.data?.total_consumables)}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div style={styles.tabledive1}>
                      <table style={{ width: '100%', border: '1px solid black', borderCollapse: 'collapse' }}>
                        <tbody>
                          <tr>
                            <td style={styles.td} colSpan="1">III</td>
                            <td style={styles.td} colSpan="5">Biaya Tenaga Kerja</td>
                          </tr>
                          {rabData?.data?.manpower_budget_info.map((item, index) => {
                            return (

                              <tr>
                                <td style={{ width: '7%', ...styles.td }}>{index + 1}</td>
                                <td style={{ ...styles.td, width: "23%" }}>{item.details}</td>
                                <td style={{ ...styles.td, width: "12%" }}>{_convertCommaSaparate(item.quantity)}</td>
                                <td style={{ ...styles.td, width: "17%" }}>Rp.{_convertCommaSaparate(item.unit_price)}</td>
                                <td style={{ ...styles.td, width: "17%" }}>Rp.{_convertCommaSaparate(item.quantity * item.unit_price)}</td>
                                <td style={{ ...styles.td, width: "26%" }}>{item.notes}</td>
                              </tr>
                            )
                          })}

                          <tr>
                            <td colSpan="4" style={{ textAlign: 'right', ...styles.th1 }}>Jumlah Tenaga Kerja</td>
                            <td style={styles.th1} colSpan="2">Rp.{_convertCommaSaparate(rabData?.data?.total_manpower)}</td>
                          </tr>
                          <tr>
                            <td colSpan="4" style={{ textAlign: 'right', width: '60%', ...styles.th1 }}>Jumlah Biaya Operasional</td>
                            <td colSpan="2" style={{ width: '40%', ...styles.th1 }}>Rp. {_convertCommaSaparate(rabData?.data?.total_operational_cost)}</td>
                          </tr>
                          {!!rabData?.data?.beleaf_mgt_fee && <tr>
                            <td colSpan="4" style={{ textAlign: 'right', width: '60%', ...styles.th1 }}>Beleaf Service Management fee</td>
                            <td colSpan="2" style={{ width: '40%', ...styles.th1 }}>Rp. {_convertCommaSaparate(rabData?.data?.beleaf_mgt_fee)}</td>
                          </tr>}
                          <tr>
                            <td className="th2" colSpan="4" style={{ textAlign: 'right', width: '60%', ...styles.th2 }}>Grand Total</td>
                            <td className="th2" colSpan="2" style={{ width: '40%', ...styles.th2 }}>Rp. {_convertCommaSaparate(rabData?.data?.grand_total)}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div style={styles.tabledive1}>
                      <p style={styles.Kebun}>C. Keuntungan</p>
                      {rabData?.data?.is_internal_farm_rab ?

<table style={{ width: '100%', border: '1px solid black', borderCollapse: 'collapse' }}>
<tbody>
  <tr>
    <td style={styles.td} colSpan="1">I.</td>
    <td style={styles.td} colSpan="6">Parameter</td>
  </tr>
      <tr>
        <td style={{ width: '7%', ...styles.td }}>1</td>
        <td style={{ width: '7%', ...styles.td }}>HPP per kg</td>
        <td style={{ width: '7%', ...styles.td }}></td>
        <td style={{ width: '15%', ...styles.td }}>Rp. {_convertCommaSaparate(rabData?.data?.cogm)}</td>
        <td style={{ width: '7%', ...styles.td }}></td>
        <td style={{ width: '7%', ...styles.td }}></td>
      </tr>
      <tr>
        
      </tr>
      <tr>
        <td style={{ width: '7%', ...styles.td }}>2</td>
        <td style={{ width: '7%', ...styles.td }}>Return of Investment</td>
        <td style={{ width: '7%', ...styles.td }}></td>
        <td style={{ width: '15%', ...styles.td }}>{_convertCommaSaparate(rabData?.data?.roi)}</td>
        <td style={{ width: '7%', ...styles.td }}></td>
        <td style={{ width: '7%', ...styles.td }}></td>
      </tr>
      <tr>
        <td colSpan="4" style={{ textAlign: 'right', width: '70%', ...styles.th1 }}>Keuntungan</td>
        <td colSpan="1" style={{ width: '15%', ...styles.th1 }}>Rp. {_convertCommaSaparate(Number(rabData?.data?.profit))}</td>
        <td colSpan="1" style={{ width: '17%', ...styles.th1 }}>{Number(rabData?.data?.profit_percent).toFixed(2)} %</td>
      </tr>
</tbody>
</table>

                        :<table style={{ width: '100%', backgroundColor: '#E7F2EE', ...styles.table }}>
                        <tbody>
                          <tr>
                            <td colSpan="4" style={{ textAlign: 'right', width: '70%', ...styles.th1 }}>Keuntungan</td>
                            <td colSpan="2" style={{ width: '15%', ...styles.th1 }}>Rp. {_convertCommaSaparate(Number(rabData?.data?.profit))}</td>
                            <td colSpan="1" style={{ width: '17%', ...styles.th1 }}>{Number(rabData?.data?.profit_percent).toFixed(2)} %</td>
                          </tr>
                        </tbody>
                      </table>}
                    </div>
                  </div>

                  {!rabData?.data?.is_internal_farm_rab && <div style={styles.MitraContainer}>
                    <div style={styles.lasttable}>
                      <div style={{ 'height': '2vh', borderBottom: '1px solid' }}><p className="text-[11px]">Mitra</p></div>
                      <div style={{ 'flex': 1, height: '6vh' }}></div>
                      <div style={{ 'height': '2vh', borderTop: '1px solid' }}><p className="text-[11px]">{rabData?.data?.mitra_info?.user_name}</p></div>
                    </div>
                    <div style={styles.lasttable}>
                      <div style={{ 'height': '2vh', borderBottom: '1px solid' }}><p className="text-[11px]">FaaS PIC</p></div>
                      <div style={{ 'flex': 1, height: '6vh' }}></div>
                      <div style={{ 'height': '2vh', borderTop: '1px solid' }}><p className="text-[11px]">{rabData?.data?.created_by?.user_name}</p></div>
                    </div>
                    <div style={styles.lasttable}>
                      <div style={{ 'height': '2vh', borderBottom: '1px solid' }}><p className="text-[11px]">Head of FaaS</p></div>
                      <div style={{ 'flex': 1, height: '6vh' }}></div>
                      <div style={{ 'height': '2vh', borderTop: '1px solid' }}><p className="text-[11px]">Elvindo Pratama</p></div>
                    </div>
                  </div>}
                </div>

              </div>

            </div>
          </div>

          <div className="absolute float-right" style={{ bottom: 130, right: 100 }}>
            {rabData?.data?.planting_budget_info.filter(e => e.payment_type_id === 2).length > 0 && rabData?.data?.current_state_id === 7 && user?.admin_role_id === 8 &&
              <div>
                <p
                  onClick={() => setOpenEditRateModal(true)}
                  className="bg-[#F491A3] text-white px-5 py-2 w-[140px] text-[16px] font-[600px] text-center  rounded-lg cursor-pointer">
                  Edit Rate
                </p>
              </div>}
            {!rabData?.data?.is_internal_farm_rab && (rabData?.data?.current_state_id > 1 && rabData?.data?.current_state_id < 12) &&
              <div className="mt-10">
                <p
                  onClick={() => setShowConfirmModal(true)}
                  className="bg-[#40AE49] text-white text-center px-5 py-2 w-[140px] text-[16px] font-[600px]  rounded-lg cursor-pointer">
                  Approve
                </p>
              </div>}
          </div>
          <div className="absolute float-right" style={{ bottom: 50, right: 100 }}>
            <button
              className="border border-[#40AE49] text-[#40AE49] border-[1px] text-[16px] font-[600px] text-center px-5 py-2 w-[140px] rounded-lg"
              type="button"
              onClick={() => onBackClick()}
            >
              Back
            </button>
          </div>

        </div>
      </div>

    </PageLayoutComp>
  );
};

