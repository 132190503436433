import React, { Fragment, useEffect, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function DropDownComp({
  listData,
  Icon,
  compulsory,
  label,
  customStyles,
  customMenuStyle,
  onSelected,
  type = "name",
  lableStyle = "text-sm",
  active = listData && listData[0],
  custom = false,
}) {
  const [selectedItem, setSelectedItem] = useState(active);
  const menuButtonStyle =
    customStyles +
    ` flex w-full  ${type === 'sort_by' ? 'h-[40px]': 'h-[45px]'}  py-4 border border-gray-300 items-center px-2 font-medium shadow-sm hover:bg-gray-50 focus:outline-0`;

  const isArray = Array.isArray(listData);

  useEffect(() => {
    setSelectedItem(active);
  }, [active]);

  const renderItems = () => {
    if (isArray) {
      return listData.map((item, i) => (
        <Menu.Item key={i}>
          {({ active }) => (
            <a
              href="#"
              onClick={() => {
                setSelectedItem(item);
                onSelected(item);
              }}
              className={classNames(
                active ? "bg-gray-100 text-[#3E4347]" : "text-[#3E4347]",
                "block px-2 py-2 text-sm"
              )}
            >
              {type === "name" ? item.name : null}
              {type === "sort_by" ? item.name : null}
              {type === "warehouse_name" ? item.warehouse_name : null}
              {type === "contact_name" ? item.internal_contact_name : null}         
              {type === "sku" ? item.name : null}         
              {type === "farm_name" ? item?.farm_name : null}         
              {type === "psic_name" ? item.psic_name : null}
              {type === "province" ? item.province : null}
              {type === "district" ? item.district : null}
              {type === "role" ? item.role : null}
              {type === "farm_type_name" ? item.farm_type_name : null}
              {type === "uom" ? item.uom : null}
              {type === "frequency" ? item.frequency : null}
              {type === "unit_name" ? item.unit_name : null}
              {type === "type_name" ? item.type_name : null}
              {type === "function" ? item.function : null}
              {type === "method_name" ? item.method_name : null}
              {type === "ownership_type" ? item.ownership_type : null}
              {type === "non-linked-users"
                ? `${item?.user_name} / ${item?.contact_number}`
                : null}
              {type === "product_category" ? item.category_name : null}
              {type === "product_maturity" ? item.maturity_type : null}
              {type === "status" ? item.status : null}
              {type === "crop_type_name" ? item.crop_type_name : null}
              {type === "class_name" ? item.class_name : null}
              {type === "media_name" ? item.media_name : null}
              {type === "product_name"
                ? `${item.product_name}  ${
                    item?.production_code
                      ? `(` + item.production_code + `)`
                      : ""
                  }`
                : null}
              {type === "farm_name" ? item?.farm_name : null}
              {type === "assignWS" ? item?.farm_name : null}
              {type === "greenhouse_name" ? item?.greenhouse_name : null}
              {type === "rab_name" ? item?.rab_name : null}
              {type === "sku_id" ? item?.name:null}
              {type === "internal_transfer_locations" ? item?.display_name : null}
              {type === "searchType" ? item?.display_name:null}
            </a>
          )}
        </Menu.Item>
      ));
    }
  };

  return (
    <Menu as="div" className={customMenuStyle}>
      <div className="h-full w-full">
        {label === undefined ? null : (
          <>
            <div className="flex items-center">
              <p className={`pl-1 pb-1 text-[#8A9099] ${lableStyle}`}>
                {label}
              </p>
              {compulsory ? <p className="text-red-500">*</p> : null}
            </div>
          </>
        )}
        <Menu.Button className={menuButtonStyle}>
          {Icon}
          <p className="text-[#979797] text-sm">
            {type === "name" ? selectedItem.name : null}
            {type === "sort_by" ? selectedItem.name : null}
            {type === "warehouse_name" ? selectedItem.warehouse_name : null}
            {type === "contact_name" ? selectedItem.internal_contact_name : null}
            {type === "sku" ? selectedItem.name : null}
            {type === "farm_name" ? selectedItem?.farm_name : null}
            {type === "province" ? selectedItem.province : null}{" "}
            {type === "district" ? selectedItem.district : null}
            {type === "role" ? selectedItem.role : null}
            {type === "farm_type_name" ? selectedItem.farm_type_name : null}
            {type === "uom" ? selectedItem.uom : null}
            {type === "frequency" ? selectedItem.frequency : null}
            {type === "unit_name" ? selectedItem.unit_name : null}
            {type === "type_name" ? selectedItem.type_name : null}
            {type === "function" ? selectedItem.function : null}
            {type === "method_name" ? selectedItem.method_name : null}
            {type === "ownership_type" ? selectedItem.ownership_type : null}
            {type === "non-linked-users"
              ? `${selectedItem.user_name} / ${selectedItem.contact_number}`
              : null}
            {type === "product_category" ? selectedItem.category_name : null}
            {type === "product_maturity" ? selectedItem.maturity_type : null}
            {type === "status" ? selectedItem.status : null}
            {type === "crop_type_name" ? selectedItem.crop_type_name : null}
            {type === "class_name" ? selectedItem.class_name : null}
            {type === "media_name" ? selectedItem.media_name : null}
            {type === "product_name" ? selectedItem?.product_name : null}
            {type === "farm_name" ? selectedItem?.farm_name : null}
            {type === "assignWS" ? selectedItem?.farm_name : null}
            {type === "greenhouse_name" ? selectedItem?.greenhouse_name : null}
            {type === "rab_name" ? selectedItem?.rab_name : null}
            {type === "sku_id" ? selectedItem?.name:null}
            {type === "internal_transfer_locations" ? selectedItem?.display_name:null}
            {type === "searchType" ? selectedItem?.display_name:null}
          </p>
          <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
        </Menu.Button>
      </div>
      <Transition as={Fragment}>
        <Menu.Items className="absolute w-[100%] scrollbar-hide max-h-[350%] overflow-scroll float-right z-10 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1  overflow-auto">{renderItems()}</div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
