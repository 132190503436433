import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: "bmos-5c50e.firebaseapp.com",
    projectId: "bmos-5c50e",
    storageBucket: "bmos-5c50e.appspot.com",
    messagingSenderId: "551868038245",
    appId: "1:551868038245:web:adfca4281360d28256da8c",
    measurementId: "G-F2C0ZKLE1Y"
  };

  export const app = initializeApp(firebaseConfig)
  export const messaging = getMessaging(app);