import React from "react";
import docuploadGuide1 from "../images/docuploadGuide1.png";
import docuploadGuide2 from "../images/docuploadGuide2.png";
import docuploadGuide3 from "../images/docuploadGuide3.png";
import { ConfirmationModal } from "./ConfirmationModal";
import { SuccessfullMesg } from "./SuccessfullMesg";
import closeImg from "../images/FarmPipeLine/close.png";



export const RabDocUpload = ({hideModal, handleFileChange, showSuccessModal, showConfirmModal, setShowConfirmModal, _addDocsData, onSubmitClick }) => {
  return (
<>

  

      <div className="flex overflow-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center">
      {showSuccessModal ? (
    <SuccessfullMesg label={`Document Updated`} />
  ) : null}
      {showConfirmModal ? (
    <ConfirmationModal
      label={`Are you sure want to update your document ?`}
      label2="No"
      type="planting"
      customStyles={true}
      hideModal={() => setShowConfirmModal(false)}
      onConfirm={() => {
        setShowConfirmModal(false);
        _addDocsData();
      }}
    />
  ) : null}
        <div className="w-[55%] relative mt-[3%] mb-[3%]">
          {/*content*/}
          <div className="first-letter:rounded-lg shadow-lg relative flex flex-col w-full outline-none focus:outline-none rounded-lg">
            <div className="flex items-center relative pl-6 pr-6 overflow-auto pt-6 bg-white rounded-t-lg h-full">
              <p className="text-[18px] w-full font-bold text-black text-start mb-2 mt-2">
                    Upload Document
              </p>
              <img
                className="float-right w-[30px] h-[30px]"
                src={closeImg}
                alt="closeImg"
                onClick={() => {
                  hideModal();
                }}
              />
            </div>
            {/*footer*/}
            <div className="rounded-b-lg bg-white py-5 px-8">
            <div className="">
    <p className="text-sm">
        Rab Budget Plan <span style={{ color: "red" }}>*</span>
    </p>
    <input
      className="mt-3 mb-3 file:h-[45px] h-[45px] text-sm text-grey-500 file:mr-5 file:py-2 file:px-6 file:rounded-lg file:border-0 file:text-sm file:font-medium file:bg-[#12312B] file:text-white file:cursor-pointer text-sm w-[60%] text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none"
      id="file_input"
      type="file"
      onChange={handleFileChange}
      name="rab_budget_plan"
    />
  </div>
  
  <div className="mt-20">
    <p>Guide to Upload Document :</p>
    <div className="flex space-x-20">
      <div>
        <img className="w-[210px] h-[136px]" src={docuploadGuide1} alt="logo" />
        <p className="max-w-[210px]">
          Make sure you upload your original document (ex. ID Card), not a scan
          or copy.
        </p>
      </div>
      <div>
        <img className="w-[210px] h-[136px]" src={docuploadGuide2} alt="logo" />
        <p className="max-w-[210px]">
          Make sure the document is clearly visible in the photo box
        </p>
      </div>
      <div>
        <img className="w-[210px] h-[136px]" src={docuploadGuide3} alt="logo" />
        <p className="max-w-[210px]">
          Make sure the photos you upload are not blurry and the lighting is
          good
        </p>
      </div>
    </div>
  </div>
  

  <p className="mt-10 text-gray-400 text-sm">*PNG, JPG or PDF</p>
  <div className="flex justify-end items-end py-0 rounded-b-lg bg-white w-full">
    <div>
    <button
        className="border border-[#40AE49] text-[#40AE49] border-[2px] text-[14px] font-[450px] text-center px-5 py-2 w-[140px] rounded-full ml-5"
        type="button"
        onClick={() => onSubmitClick()}
    >
        {"Sent"}
    </button>
    </div>
 </div>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>{" "}
    </>);
};
