import React, { useEffect, useState } from "react";
import { _asyncGetUserRoles, _asyncGetUserRoleSubordinates, _asyncGetUserSubordinates, _updateUserDetails } from "../api";
import { HiOutlineMail } from "react-icons/hi";
import { FiPhoneCall } from "react-icons/fi";
import { HiOutlineUsers } from "react-icons/hi";
import { GrUserExpert } from "react-icons/gr";
import closeImg from "../images/FarmPipeLine/close.png";
import {_convertCommaSaparate } from "../config/localdata";
import { ConfirmationModal } from "./ConfirmationModal";
import { SuccessfullMesg } from "./SuccessfullMesg";
import DropDownComp from "../components/DropDownComp";
import { InputTextComp } from "../components/InputTextComp";
import Multiselect from "multiselect-react-dropdown";
import SearchDropDownComp from "../components/SearchDropDownComp";

export const UserEditModal = ({
  hideModal,
  metaData,
  userBody,
  checkBeleafe
}) => {
const [showConfirmModal, setShowConfirmModal] = useState(false);
const [showSuccessModal, setShowSuccessModal] = useState(false);
const [isEdit, setIsEdit] = useState(false);
const [userName, setUserName] = useState(userBody?.user_name);
const [userEmail, setUserEmail] = useState(userBody?.email);
const [userRole, setUserRole] = useState();
const [userContact, setUserContact] = useState(userBody?.contact_number);
const [userRolesList, setUserRolesList] = useState();
const [selectedSubordinates, setSelectedSubordinates] = useState([]);
const [showSubordinates, setShowSubordinates] = useState(false);
const [subordinates, setSubordinates] = useState([]);




const onUserChange = async (event) => {
  setUserRole([event]);
  setShowSubordinates(event.can_have_sub_ordinates);
    if(event?.can_have_sub_ordinates){
      let res  = await _asyncGetUserRoleSubordinates(event.id)
      setSubordinates(res);
    }
};

const handleSubordinatesOnChange = (event) =>{
  let userList = [];
    event.map((item) => {
      userList.push(item);
    });
  setSelectedSubordinates(userList);
}


   async function _getUserRoles() {
    const data = await _asyncGetUserRoles();
    if (!data) return;
    setUserRolesList([...data]);
    setUserRole([userBody?.admin_role_id].map(item => data.find(obj => (item === obj.id) && (item === obj.id))))
  }
  async function _getUserSubordinates() {
    const data = await _asyncGetUserSubordinates(userBody?.id);
    if (!data) return;
    setSubordinates(data);
    const filterList = data.filter(item=>item.manager_id === userBody?.id)
    setSelectedSubordinates([...filterList]);
  }

   useEffect(() => {
    _getUserRoles();
    if(checkBeleafe){
      _getUserSubordinates();
    }
  }, []);


   const getRoleByRoleIds = (str) => {
    let roleArray = [];
    if (checkBeleafe) {
      roleArray = metaData.adminRoles;
      return roleArray.filter((item)=>item.id === str)[0]?.role;
    } else {
      roleArray = metaData.userRoles;
    }
  };


 const _requestSubmit = async () => {
    let body = {
        user_name:userName,
        email:userEmail,
        contact_number:userContact,
        role_id:userRole[0]?.id
    }
    if(selectedSubordinates.length > 0){
      body.subordinate_ids = selectedSubordinates.map(({ id }) => id)
    }
    const apiResponse = await _updateUserDetails(userBody?.id, body, checkBeleafe);
    if (apiResponse === 1) {
        setShowSuccessModal(true)
        setIsEdit(false)
    }
    setTimeout(() => {
      setShowSuccessModal(false);
      setShowConfirmModal(false);
      hideModal();
      }, 600);
      return;
  };

  return (
    <>
      <div className="flex overflow-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center">
      {showSuccessModal ? (<SuccessfullMesg label={`Saved Successfully`} />) : null}
        {showConfirmModal ? (
          <ConfirmationModal
            label={`Do you want to update this user ?`}
            label2="No"
            type="planting"
            customStyles={true}
            hideModal={() => setShowConfirmModal(false)}
            onConfirm={() => {
              setShowConfirmModal(false);
              _requestSubmit();
            }}
          />
        ) : null}
        <div className="w-[50%] h-[50%] relative mt-[3%] mb-[3%]">
          {/*content*/}
          <div className="h-full first-letter:rounded-lg shadow-lg relative flex flex-col w-full outline-none focus:outline-none rounded-lg">
          <div className="relative p-10 overflow-auto pt-3 bg-white rounded-lg h-full">
              <img
                className="float-right w-[30px] h-[30px] mt-3"
                src={closeImg}
                alt="closeImg"
                onClick={() => {
                  hideModal();
                }}
              />
              <div className="float-right w-[30px] mt-20">
              { isEdit ?
              <span
                onClick={() => setShowConfirmModal(true)}
                className="bg-[#40AE49] text-white px-5 py-1 border border-[#40AE49] rounded-full cursor-pointer">
                Save
              </span>:
              <span
                onClick={() => setIsEdit(true)}
                className="bg-[#D0D0D0] text-[#808080] px-6 py-1 border border-[#D0D0D0] rounded-full cursor-pointer">
                Edit
                </span>}
              </div>
              <div className="mt-6">
                 <div className="w-full mt-2">
                    <p className={`text-[24px] p-1 font-bold text-black`}>
                      User Information
                    </p>
                    {isEdit ? 
                    <div className="grid grid-cols-2 gap-2 ml-7">
                    <div>
                      <InputTextComp
                        value={userName}
                        placeholder=""
                        onChange={(text) =>setUserName(text)}
                      />
                    </div></div>:
                    <p className={`text-[18px] p-3 text-[#000] w-full`}>{userBody?.user_name}</p>}
                    
                    {/* {isEdit ? 
                    <div className="grid grid-cols-2 gap-2">
                    <div className="flex mt-2">
                    <span className="flex p-1"><HiOutlineMail className="mt-1.5" /></span>
                      <InputTextComp
                        value={userEmail}
                        placeholder=""
                        onChange={(text) =>setUserEmail(text)}
                      />
                    </div></div>: */}
                    {checkBeleafe && <span className="flex p-1"><HiOutlineMail className="mt-1.5" /><p className={`text-[14px] p-1 text-[#000] w-full`}>{userBody?.email}</p></span>}
                    {/* } */}
                    {isEdit ? 
                    <div className="grid grid-cols-2 gap-2">
                    <div className="flex mt-2">
                    <span className="flex p-1"><FiPhoneCall className="mt-1.5" /></span>
                      <InputTextComp
                        value={userContact}
                        placeholder=""
                        onChange={(text) =>setUserContact(text)}
                      />
                    </div></div>:
                    <span className="flex p-1"><FiPhoneCall className="mt-1.5" /><p className={`text-[14px] p-1 text-[#000] w-full`}>{userBody?.contact_number}</p></span>}

            {isEdit && checkBeleafe ? 
                <div className="grid grid-cols-2 gap-2">
                    <div className="flex mt-2">
                    <span className="flex p-1"><GrUserExpert className="mt-1.5" /></span>
                      <SearchDropDownComp
                          list={userRolesList || []}
                          type={"role"}
                          active={userRole[0]}
                          onSelected={(item) => onUserChange(item)}
                          customStyles={"rounded-md justify-between text-[#3E4347] text-[14px]"}
                          customMenuStyle={"relative inline-block text-left w-full"}
                        />
                  </div>
                </div>:
                    checkBeleafe && <span className="flex p-1"><GrUserExpert className="mt-1.5" /><p className={`text-[14px] p-1 text-[#000] w-full`}>{getRoleByRoleIds(userBody?.admin_role_id)}</p></span>}

                {(showSubordinates && isEdit) || (checkBeleafe && isEdit && selectedSubordinates.length > 0) ? 
                      <div>
                        <HiOutlineUsers className="mt-1.5" />
                        <div className="flex">
                          <p className="text-[14px] text-black">Subordinates</p>
                          {/* <p className="text-red-500">*</p> */}
                        </div>
                     
                            <div className="w-full">
                            <Multiselect
                              displayValue="user_name"
                              chipColor="#40AE49"
                              selectedValues={selectedSubordinates || []}
                              options={subordinates || []}
                              onSelect={handleSubordinatesOnChange}
                              onRemove={handleSubordinatesOnChange}
                              showCheckbox
                              style={{chips:{background: "#40AE49" }}}
                            />
                          </div>
                    </div>:
                    checkBeleafe && selectedSubordinates.length > 0 && <span className="flex p-1"><HiOutlineUsers className="mt-1.5" /><p className={`text-[14px] p-1 text-[#000] w-full`}>{selectedSubordinates.map(({ user_name }) => user_name).join(', ')}</p></span>
                    }
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>{" "}
    </>
  );
};
