import React, { useEffect, useState } from "react";
import {
  _asyncGetAllCropHarvested,
  _asyncGetAllCropTraker,
  _asyncGeneratePO,
} from "../api";
import { TableSearchComp } from "../components/TableSearchComp";
import { TablePaginationComp } from "../components/TablePaginationComp";
import { toast } from "react-toastify";
import { FiltersModal } from "../Models/FiltersModal";
import { LogDataModal } from "../Models/LogDataModal";
import { GeneratePoModal } from "../Models/GeneratePoModal";
import { ConfirmationModal } from "../Models/ConfirmationModal";
import { getDateFormatMoment } from "../config/localdata";
import { SuccessfullMesg } from "../Models/SuccessfullMesg";
import unavailable from "../images/unavailable.png";
import { DashboardFilterModal } from "../Models/DashboardFilterModal";
import { useNavigate } from "react-router-dom";

export const DraftHarvested = ({ metaData }) => {
  const size = 10;
  const configFetchRef = React.useRef(false);
  const [totalPage, setTotalPage] = useState();
  const [totalItems, setTotalItems] = useState();
  const [selectedPage, setSelectedPage] = useState(0);
  const [setRowsPerPage, setSetRowsPerPage] = useState(0);
  const [tableDataList, setTableDataList] = useState([]);
  const [allSelectedFor, updateAllSelected] = useState(false);
  const [someSelected, updateSomeSelected] = useState(false);
  const [isViewEditModalOpened, setViewEditModalOpen] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [showGeneratePo, setShowGeneratePo] = useState(false);
  const [generatePoBody, setGeneratePoBody] = useState(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [submitOodoData, setSubmitOodoData] = useState({});
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const [checkedFarmName, setCheckedFarmName] = useState("");

  const [logData, setLogData] = useState();
  const [showLogData, setShowLogData] = useState(false);

  const [selectedProduct, setSelectedProduct] = useState();
  const [selectedFarm, setSelectedFarm] = useState();
  const [selectedMitra, setSelectedMitra] = useState();
  const [filterData, setFilterData] = useState({});
  const [searchText, setSearchText] = useState("");
  const [sortValue, setSortValue] = useState({ id: "3", name: "Sort By" });
  const [action, setAction] = useState("");
  const [searchType, setSearchType] = useState('');
  const location = useNavigate();
  let user = JSON.parse(localStorage.getItem("user_data"));

  const sortType = [
    { id: "1", name: "Latest" },
    { id: "2", name: "Earliest" },
  ];

  const addFilters = () => {
    let product_ids = [];
    let farm_ids = [];
    let mitra_ids = [];

    if (selectedProduct !== undefined) {
      product_ids.push(selectedProduct.id);
    }
    if (selectedFarm !== undefined) {
      farm_ids.push(selectedFarm.id);
    }
    if (selectedMitra !== undefined) {
      mitra_ids.push(selectedMitra.id);
    }
    setFilterData({ product_ids, farm_ids, mitra_ids });
    setSelectedPage(0);
    _fetchProducts(0, null, { product_ids, farm_ids, mitra_ids });
  };

  const resetFilter = () => {
    setShowFilter(false);
    _fetchProducts(selectedPage, null, {});
    setFilterData({});
    setSelectedProduct();
    setSelectedFarm();
    setSelectedMitra();
  };
   
  useEffect(()=>{
    _fetchProducts(selectedPage, null, filterData)
  },[action])

  const handleSearchSubmit = (action) => {
    setSelectedPage(0);
    if (action === "reset") {
      setSearchText("");
      setSearchType("");
      setAction(action)
      _fetchProducts(0, null, filterData, "");
    }
    if (searchText.length > 2 && action !== "reset") {
      _fetchProducts(0, null, filterData, searchText, searchType);
    }
  };

  useEffect(() => {
    if (configFetchRef.current) return;
    configFetchRef.current = true;
    _fetchProducts(selectedPage);
  }, []);

  useEffect(() => {
    _fetchProducts(selectedPage);
  }, [setRowsPerPage]);

  useEffect(() => {
    let sort_order = sortValue?.name === "Earliest" ? "ASC" : "DESC";
    _fetchProducts(selectedPage, sort_order, filterData, searchText, searchType);
  }, [sortValue]);

  const handleLogdata = (data) => {
    setLogData(data);
    setShowLogData(true);
  };

  useEffect(() => {
    const allSelected =
      tableDataList && tableDataList.length
        ? tableDataList.every((ele) => ele.isSelectedForPO === true)
        : false;
    const allUnSelected =
      allSelected === false
        ? tableDataList && tableDataList.length
          ? tableDataList.every((ele) => ele.isSelectedForPO === false)
          : true
        : false;
    updateAllSelected(allSelected);
    updateSomeSelected(!allUnSelected);
  }, [tableDataList]);

  const generatePO = () => {
    if (!user?.permissions?.generate_po) {
      alert("Don't have Generate PO Permission");
      return;
    }
    let flag = true;
    tableDataList.forEach((item) => {
      if (item.isSelectedForPO === true && checkedFarmName !== item.farm_name) {
        flag = false;
        toast.error(`Please select same farms to generate PO`);
        return;
      }
    });
    const body = [];
    tableDataList.forEach((item) => {
      if (item.isSelectedForPO === true) {
        item.decided_unit_price = null;
        item.total_harvest_price = null;
        body.push(item);
      }
    });
    if (body.length > 0 && flag) {
      setGeneratePoBody(body);
      setShowGeneratePo(true);
    }
  };

  const submitPo = async (obj) => {
    if (!obj?.items[0]?.psic) {
      toast.error(`Please select PSIC Info`);
      return;
    }
    if (!obj?.arrivalDate) {
      toast.error(`Please select expected arrival date`);
      return;
    }
    if (!obj?.warehouse.id) {
      toast.error(`Please select deliver to`);
      return;
    }
    if (!obj?.paymentMethod.id) {
      toast.error(`Please select purchase method `);
      return;
    }

    if (!obj?.items[0]?.decided_unit_price) {
      toast.error(`Please enter unit price`);
      return;
    }

    let crops_data = [];
    obj?.items?.map((item) => {
      crops_data.push({
        harvest_id: item.harvest_id,
        decided_unit_price: item.decided_unit_price,
        total_harvest_price: item.total_harvest_price,
        pc_psic_info: item?.psic[0],
      });
    });
    let data = {
      expected_arrival_date: obj.arrivalDate,
      total_po_cost: obj.grandTotal,
      odoo_warehouse_id: obj.warehouse.id,
      odoo_warehouse_name: obj.warehouse.warehouse_name,
      purchase_method_id: obj.paymentMethod.id,
      crops_data,
    };
    setShowGeneratePo(false);
    setShowConfirmModal(true);
    setSubmitOodoData(data);
  };

  const _addOodoData = async () => {
    const apiResponse = await _asyncGeneratePO(submitOodoData);
    if (apiResponse === 1) {
      setShowSuccessModal(true);
      setShowConfirmModal(false);
      setSubmitOodoData({});
      setTimeout(() => {
        window.location.reload();
      }, 600);
      return;
    }
    toast.error(apiResponse);
    return;
  };

  function _getHarvestClasses(id) {
    let data = "";
    data = metaData?.cropHarvestClasses?.filter((e) => e.id === id);
    return  data && data[0]?.class_name;
  }

  async function _fetchProducts(page, sort_order, item, searchText, searchType = null) {
    setShowFilter(false);
    let params = {
      size: setRowsPerPage > 10 ? setRowsPerPage : size,
      page: page + 1,
      ...item,
    };
    
    if(searchType?.search_by_key && searchText){
      params[searchType?.search_by_key] = searchText;
    }
    const res = await _asyncGetAllCropHarvested(params, location, sort_order);
    setTableDataList(res?.displayData);
    setTotalPage(res?.totalPage);
    setTotalItems(res?.totalItems);
  }

  return (
    <div className="h-[100%]">
      {showSuccessModal ? (
        <SuccessfullMesg label={`Success submit data to Odoo`} />
      ) : null}

      {showConfirmModal ? (
        <ConfirmationModal
          label={`Are you sure to send this data to generate PO ?`}
          label2="No"
          type="planting"
          customStyles={true}
          hideModal={() => setShowConfirmModal(false)}
          onConfirm={() => {
            setShowConfirmModal(false);
            _addOodoData();
          }}
        />
      ) : null}

      {showLogData ? (
        <LogDataModal
          hideModal={() => {
            setShowLogData(false);
          }}
          logData={logData}
        />
      ) : null}

      {showGeneratePo ? (
        <GeneratePoModal
          hideModal={() => {
            setShowGeneratePo(false);
          }}
          metaData={metaData}
          generatePoBody={generatePoBody}
          submitPo={(obj) => submitPo(obj)}
        />
      ) : null}

      {showFilter ? (
        <DashboardFilterModal
          hideModal={() => setShowFilter(false)}
          resetFilter={resetFilter}
          addFilters={addFilters}
          selectedProduct={selectedProduct}
          selectedFarm={selectedFarm}
          selectedMitra={selectedMitra}
          setSelectedProduct={setSelectedProduct}
          setSelectedFarm={setSelectedFarm}
          setSelectedMitra={setSelectedMitra}
        />
      ) : null}

      <div className="">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className="w-[100%] mt-4">
            <TableSearchComp
              onChange={(text) => setSearchText(text)}
              openFilter={() => setShowFilter(true)}
              sortBy={true}
              sortType={sortType}
              sortValue={sortValue}
              handleSortBy={(item) => setSortValue(item)}
              searchText={searchText}
              handleSearchSubmit={handleSearchSubmit}
              searchTypeValues={metaData?.searchableFeaturesWithOptions?.harvest_list}
              searchType={searchType}
              onSearchChangeType={(item) => setSearchType(item)}
            />
            
          </div>
        </div>
      </div>
      <div className="h-[10%]">
      <div className="flex py-2 float-right">
        <p
          onClick={generatePO}
          className="bg-[#3EB049] text-white m-2 px-2 py-2 float-right border-[#3EB049] rounded-xl cursor-pointer"
        > Generate PO
        </p>
      </div>
      </div>
      
      <div className="h-[70%] overflow-auto scrollbar-hide mt-6">
        {tableDataList.map((data, i) => {
          return (
            <div className="flex w-[100%] box-border h-18  mt-2 p-4 border-2 border-slate-10 rounded-xl">
              <input
                type="checkbox"
                disabled={
                  checkedFarmName === ""
                    ? false
                    : checkedFarmName === data?.farm_name
                    ? false
                    : true
                }
                checked={data.isSelectedForPO}
                onChange={() => {
                  const updatedData = [...tableDataList];
                  updatedData[i].isSelectedForPO =
                    !updatedData[i].isSelectedForPO;
                  setCheckedFarmName(
                    data.isSelectedForPO
                      ? data?.farm_name
                      : tableDataList.filter((item) => item.isSelectedForPO)
                          .length > 0
                      ? data?.farm_name
                      : ""
                  );
                  setTableDataList(updatedData);
                }}
              />

              <div className="flex flex-row w-[100%]">
                <img
                  src={data?.product_image || unavailable}
                  className="w-[70px] h-[70px] ml-4 mt-3 text-sm text-[#0090FF] underline cursor-pointer text-center"
                />

                <div className="basis-1/2 ml-10 mt-2">
                  <p className="text-[14px] font-bold">{data?.product_name}</p>
                  <p className="text-[12px] font-[600]">
                    {data?.production_code}
                  </p>
                  <div className="w-[70%]">
                    <p className="text-[10px] p-0 font-[600] text-black text-center bg-[#fff] border border-[#808080]">
                      {_getHarvestClasses(data?.harvest_class_id)}
                    </p>
                  </div>
                  <p className="text-gray text-[10px] font-[600]">
                    Harvested at {getDateFormatMoment(data?.harvest_created_at)}
                  </p>
                </div>
              </div>

              <div className="basis-1/2 mt-2">
                <p className="text-[16px] font-[800] text-[#40AE49]">
                  {data?.harvest_amount} Kg
                </p>
              </div>

              <div className="basis-1/2 mt-2">
                <p className="text-[16px] font-[800]">{data?.farm_name}</p>
                <p className=" text-[12px] font-[600]">{data?.user_name}</p>
              </div>
              <div className="basis-1/2 mt-2">
                <p className=" text-gray text-[12px] font-[600]">
                  {data?.harvest_batch_id}
                </p>
                <p className=" text-gray text-[12px] font-[600]">
                  {data?.planting_batch_id}
                </p>
              </div>
            </div>
          );
        })}
      </div>
      <div className="flex justify-end items-center h-[10%] text-sm px-5">
        {totalPage !== undefined ? (
          <TablePaginationComp
            totalPages={totalPage}
            active={selectedPage}
            setRowsPerPage={(r) => {
              setSetRowsPerPage(r);
            }}
            selectedPage={(p) => {
              setSelectedPage(p);
              _fetchProducts(p,null, filterData, searchText, searchType)
            }}
            totalItems={totalItems}
            showPerPageDropdown={true}
          />
        ) : null}
      </div>
    </div>
  );
};
