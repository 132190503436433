import React, { useEffect, useState } from "react";
import { TableSearchComp } from "../../../components/TableSearchComp";
import { TablePaginationComp } from "../../../components/TablePaginationComp";
import { _asyncGetContactEnquiryList } from "../../../api";
import { CONTACT_ENQUIRY_TB_COL } from "..";

export const ContactEnquiryTable = ({ metaData, token, refreshTab }) => {
    const size = 10;
    let colums = CONTACT_ENQUIRY_TB_COL;
    const configFetchRef = React.useRef(false);
    const [totalPage, setTotalPage] = useState();
    const [totalItems, setTotalItems] = useState();
    const [selectedPage, setSelectedPage] = useState(0);
    const [action, setAction] = useState("");
    const [data, setData] = useState({});
    const [searchText, setSearchText] = useState("")


    useEffect(() => {
        _fetchContactEnquiryList(selectedPage)
    }, [action]);

    async function _fetchContactEnquiryList(page, size) {
        let params = {
            size: 10,
            page: page + 1
        }
        if (searchText) {
            params['search_text'] = searchText;
        }
        const res = await _asyncGetContactEnquiryList(params);
        setData(res);
        setTotalPage(res.last_page);
        setTotalItems(res.totalItems);
        console.log('jkl', res?.totalItems);
    }


    const handleSearchSubmit = (action) => {
        setAction(action)
        setSelectedPage(0)
        if (action === "reset") {
            setSearchText("");
            _fetchContactEnquiryList(0)
        }
        if (searchText.length > 2 && action !== "reset") {
            _fetchContactEnquiryList(0)
        }
    }

    return (
        <div className="h-[100%] mt-4">

            <div className="h-[7%]">
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >


                    <div className="w-[100%]">
                        <TableSearchComp
                            handleSearchSubmit={handleSearchSubmit}
                            onChange={(text) => setSearchText(text)}
                            showFilterBtn={false}
                            searchText={searchText}
                            showTypeDropDown={false}
                        />
                    </div>
                </div>
            </div>
            <div className="h-[80%] overflow-auto scrollbar-hide mt-5">
                <table className="w-full">
                    <thead>
                        <tr className="sticky top-0">
                            {/* <td></td> */}
                            {colums.map((col, i) => {
                                if (col.Header === "ID") {
                                    return <td key={`tfhk-${i}`}></td>;
                                }
                                return (
                                    <td
                                        className="text-sm text-center p-[0px] m-[0px] bg-white"
                                        key={`tfhk-${i}`}
                                    >
                                        <div className="flex justify-center items-center py-2 border-gray-200 border-b-[1px]">
                                            <p className="text-sm text-[#3EB049]">{col.Header}</p>
                                            {/* {col.Header !== "FARM ID" ? (
                        <BsFillCaretDownFill className="ml-2" size={10} />
                      ) : null} */}
                                        </div>
                                    </td>
                                );
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {data?.data?.map((data, i) => {
                            return (
                                <tr key={`row-${i}`} className="border-gray-200 border-b-[1px]">
                                    {colums.map((col, i) => {
                                        if (col.Header === "FULL NAME") {
                                            return (
                                                <td key={`tbfc-${i}`} className="py-2">
                                                    <p className="text-sm text-center">
                                                        {data[col.first_name] === null
                                                            ? "-"
                                                            : (data[col.first_name]) + " " + (data[col.last_name])}
                                                    </p>
                                                </td>
                                            );
                                        } else if (col.Header === "Email") {
                                            return (
                                                <td key={`tbfc-${i}`} className="py-2">
                                                    <p className="text-sm text-center">
                                                        {data[col.accessor] === null
                                                            ? "-"
                                                            : (data[col.accessor])}
                                                    </p>
                                                </td>
                                            );
                                        } else if (col.Header === "PHONE NUMBER") {
                                            return (
                                                <td key={`tbfc-${i}`} className="py-2">
                                                    <p className="text-sm text-center">
                                                        {data[col.accessor] === null
                                                            ? "-"
                                                            : (data[col.accessor])}
                                                    </p>
                                                </td>
                                            );
                                        } else if (col.Header === "COMPANY NAME") {
                                            return (
                                                <td key={`tbfc-${i}`} className="py-2">
                                                    <p className="text-sm text-center">
                                                        {data[col.accessor] === null
                                                            ? "-"
                                                            : (data[col.accessor])}
                                                    </p>
                                                </td>
                                            );
                                        } else if (col.Header === "COMPANY EMAIL") {
                                            return (
                                                <td key={`tbfc-${i}`} className="py-2">
                                                    <p className="text-sm text-center">
                                                        {data[col.accessor] === null
                                                            ? "-"
                                                            : data[col.accessor]}
                                                    </p>
                                                </td>
                                            );
                                        } else if (col.Header === "COUNTRY") {
                                            return (
                                                <td key={`tbfc-${i}`} className="py-2">
                                                    <p className="text-sm text-center">
                                                        {data[col.accessor] === null
                                                            ? "-"
                                                            : data[col.accessor]}
                                                    </p>
                                                </td>
                                            );
                                        } else if (col.Header === "MESSAGE") {
                                            return (
                                                <td key={`tbfc-${i}`} className="py-2">
                                                    <p className="text-sm text-center">
                                                        {data[col.accessor] === null
                                                            ? "-"
                                                            : data[col.accessor]}
                                                    </p>
                                                </td>
                                            );
                                        } else {
                                            return (
                                                <td key={`tbfc-${i}`} className="py-2">
                                                    <p
                                                        title={
                                                            data[col.accessor] == null
                                                                ? ""
                                                                : data[col.accessor].toString()
                                                        }
                                                        className="text-sm text-center"
                                                    >
                                                        {data[col.accessor] == null
                                                            ? ""
                                                            : data[col.accessor].toString()}
                                                    </p>
                                                </td>
                                            );
                                        }
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
            <div className="flex justify-end items-center h-[10%] text-sm px-5">
                {totalPage !== undefined ? (
                    <TablePaginationComp
                        totalPages={totalPage}
                        active={selectedPage}
                        selectedPage={(p) => {
                            setSelectedPage(p);
                            _fetchContactEnquiryList(p);
                        }}
                        totalItems={totalItems}
                    />
                ) : null}
            </div>
        </div>
    );
};
