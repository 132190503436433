import React, { useEffect, useState } from "react";
import {
  _asyncGetAllHarvest,
  _asyncGetAllPlanting,
  _asyncGetDashboardData,
} from "../api";
import { PieChartComp } from "./PieChartComp";
import { getDateFormatMoment, _convertCommaSaparate } from "../config/localdata";
import { useLocation } from "react-router-dom";
import filters_img from "../images/FarmPipeLine/filters.png";
import { DashboardFilterModal } from "../Models/DashboardFilterModal";
import moment from "moment";


export const DasboardTab = ({ metaData }) => {
  const [dashboardData, setDashboardData] = useState({});
  const location = useLocation();

  const [categories, setCategories] = useState([]);
  const [targetHarvests, setTarget] = useState([]);
  const [actualHarvests, setActual] = useState([]);
  const [highPlantingTargets, setHighPlantingTargets] = useState([]);
  const [recentPlanting, setRecentPlanting] = useState([]);
  const [upcomingPlanting, setUpcomingPlanting] = useState([]);
  const [upHarvestSize, setUpHarvestSize] = useState(4);
  const [recentPlantingSize, setRecentPlantingSize] = useState(4);


  const [showFilter, setShowFilter] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState();
  const [selectedFarm, setSelectedFarm] = useState();
  const [selectedMitra, setSelectedMitra] = useState();

console.log(selectedFarm,'selected farm');
  const addFilters = () =>{
    let product_ids = []
    let farm_ids = []
    let mitra_ids = []

    if(selectedProduct !== undefined){
        product_ids.push(selectedProduct.id)
    }
    if(selectedFarm !== undefined){
        farm_ids.push(selectedFarm.id)
    }
    if(selectedMitra !== undefined){
        mitra_ids.push(selectedMitra.id)
    }
    setCategories([]);
    setActual([]);
    setTarget([]);
    _fetchData({product_ids, farm_ids, mitra_ids})
    _fetcRecentPlantingData({product_ids, farm_ids, mitra_ids})
  }

  const resetFilter = () =>{
    setShowFilter(false);
    _fetchData()
    setSelectedProduct()
    setSelectedFarm()
    setSelectedMitra()
  }

  useEffect(() => {
    _fetchData();
  }, []);

  useEffect(() => {
    let product_ids = []
    let farm_ids = []
    let mitra_ids = []

    if(selectedProduct !== undefined){
        product_ids.push(selectedProduct.id)
    }
    if(selectedFarm !== undefined){
        farm_ids.push(selectedFarm.id)
    }
    if(selectedMitra !== undefined){
        mitra_ids.push(selectedMitra.id)
    }
    _fetchUpcomingHarvestData({product_ids, farm_ids, mitra_ids});
  }, [upHarvestSize]);

  useEffect(() => {
    let product_ids = []
    let farm_ids = []
    let mitra_ids = []

    if(selectedProduct !== undefined){
        product_ids.push(selectedProduct.id)
    }
    if(selectedFarm !== undefined){
        farm_ids.push(selectedFarm.id)
    }
    if(selectedMitra !== undefined){
        mitra_ids.push(selectedMitra.id)
    }
    _fetcRecentPlantingData({product_ids, farm_ids, mitra_ids});
  }, [recentPlantingSize]);

  function formatDate(newDate) {
    const d = newDate;
    const year = d.getFullYear();
    const date = d.getDate();
    const monthIndex = d.getMonth();
    const formatted = `${monthIndex + 1 + "/"}${date + "/"}${year}`;
    return formatted.toString();
  }

  function getDateAfterDays(i, past = false) {
    let dt = new Date();
    if (past) {
      dt.setDate(dt.getDate() - i);
    } else {
      dt.setDate(dt.getDate() + i);
    }

    return formatDate(dt);
  }

  function _getDaysBtwTwoDates(date1, date2) {
    let Difference_In_Time = date2.getTime() - date1.getTime();
    let dif = Difference_In_Time / (1000 * 3600 * 24);
    return Math.round(dif);
  }

  async function _fetchData(items = null) {
    setShowFilter(false);
    let dashData = await _asyncGetDashboardData(items);
    setDashboardData(dashData);
    const monthArray = dashData?.graphData && Object.keys(dashData?.graphData) || [];
    const target = [];
    const actual = [];
    const filtered = monthArray.length && (monthArray.length > 11 ? monthArray.slice(monthArray.length - 12, monthArray.length):monthArray);
    console.log(filtered);
    setCategories(filtered);
    if (filtered.length) {
      filtered.forEach((item) => {
          target.push(parseInt(dashData?.graphData[item].targetHarvest));
          actual.push(parseInt(dashData?.graphData[item].totolHarvest));
      });
      setActual(actual);
      setTarget(target);
    }

    let plantingParam = {
      size: 4,
      sort_by: "target_harvest_quantity",
      ...items
    }
    let highPlantingTargetRes = await _asyncGetAllPlanting(plantingParam,location,"DESC")
    setHighPlantingTargets(highPlantingTargetRes.displayData);
    _fetchUpcomingHarvestData(items);
  }

  async function _fetchUpcomingHarvestData(items) {
    let upcomingPlantingParams = {
      size:upHarvestSize,
      sort_by:"expected_harvest_date",
      expected_harvest_date_range:[formatDate(new Date()), getDateAfterDays(7)],
      ...items
    }
    let upcomingPlantingActRes = await _asyncGetAllHarvest(upcomingPlantingParams,location,"ASC");
    setUpcomingPlanting(upcomingPlantingActRes.displayData);
  }

  //&planting_status_ids=2&planting_status_ids=3
  async function _fetcRecentPlantingData(item) {
    let recentPlantingParams = {
      ...item,
      size:recentPlantingSize,
      sort_by:'activity_updated_at',
      activity_updated_date_range:[getDateAfterDays(2,true), formatDate(new Date())],
      be_specific:true,
      planting_status_ids:[2,3],
    }
    let recentPlantingActRes = await _asyncGetAllPlanting(
      recentPlantingParams,
      location,
      "DESC"
    );
    setRecentPlanting(recentPlantingActRes.displayData);
  }

  return (
    <div className="bg-white h-[83%] p-3">
      {showFilter ? (
        <DashboardFilterModal
          hideModal={() => setShowFilter(false)}
          resetFilter={resetFilter}
          addFilters={addFilters}
          selectedProduct={selectedProduct}
          selectedFarm={selectedFarm}
          selectedMitra={selectedMitra}
          setSelectedProduct={setSelectedProduct}
          setSelectedFarm={setSelectedFarm}
          setSelectedMitra={setSelectedMitra}
        />
      ) : null}
      <div
        style={{
          display: "flex",
          alignItems: "end",
          justifyContent: "flex-end",
        }}
      >
        <div className=" ml-5">
          <div
            onClick={() => setShowFilter(true)}
            className="flex justify-center items-center ml-5 rounded-lg cursor-pointer h-[39px] border border-gray-200 px-8"
          >
            <img src={filters_img} className="w-5 h-5" />
          </div>
        </div>
      </div>
      <div className="grid grid-cols-4 gap-4 h-[15%]">
        <div className="shadow-md shadow-gray-200 rounded-lg p-5">
          <p className="font-semibold text-[16px]">Number of Mitra</p>
          <p className="font-semibold text-[30px] text-[#40AE49]">
            {dashboardData?.totalMitra ? dashboardData?.totalMitra : "-"}
          </p>
        </div>

        <div className="shadow-md shadow-gray-200 rounded-lg p-5">
          <p className="font-semibold text-[16px]">Planting in Progress</p>
          <p className="font-semibold text-[30px] text-[#40AE49]">
            {dashboardData?.totalPlantingInProgress
              ? dashboardData?.totalPlantingInProgress
              : "-"}
          </p>
        </div>

        <div className="shadow-md shadow-gray-200 rounded-lg p-5">
          <p className="font-semibold text-[16px]">Product Grown</p>
          <p className="font-semibold text-[30px] text-[#40AE49]">
            {dashboardData?.totalActualHarvest
              ? _convertCommaSaparate(parseInt(dashboardData?.totalActualHarvest)) + " Kg"
              : "-"}
          </p>
        </div>

        <div className="shadow-md shadow-gray-200 rounded-lg p-5">
          <p className="font-semibold text-[16px]">Planting Performance</p>
          <p className="font-semibold text-[30px] text-[#40AE49]">
            {dashboardData?.performance == "NaN %" ? "-" : dashboardData?.performance ? dashboardData?.performance: "-"}
          </p>
        </div>
      </div>
      <div className="h-[90%] my-5 overflow-scroll mt-14 scrollbar-hide">
        <div className="shadow-md shadow-gray-200 p-2">
          <PieChartComp
            categories={categories}
            targetHarvests={targetHarvests}
            actualHarvests={actualHarvests}
          />
        </div>

        <div className="grid grid-cols-3 gap-3 mt-5 mb-2">
          <div className="shadow-md shadow-gray-300 rounded-lg p-5">
            <p className="font-bold text-[16px] text-[#252525]">
              Highest Planting Target
            </p>
            {highPlantingTargets.map((item) => {
              return (
                <div key={item.id} className="flex mt-2 justify-between">
                  <div>
                    <p className="text-[14px]">{item?.product?.product_name}</p>
                    <div className="text-[#40AE49] text-[14px]">
                      {item.product.production_code}
                    </div>
                  </div>
                  <div>
                    <p className="font-semibold text-[20px]">
                      {_convertCommaSaparate(item?.target_harvest_quantity)}
                      {" " + item.target_harvest_uom}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="shadow-md rounded-lg p-5">
            <div className="h-[98%] mb-2">
              <p className="font-bold text-[16px] text-[#252525]">
                Recent Planting Activities
              </p>
              {recentPlanting.map((item) => {
                const foodBar = metaData?.plantingStatus.find(
                  (item2) => item2.id === item?.current_planting_status_id
                );
                let style = "bg-[#E7F2EE] text-[#40AE49]";
                if (foodBar?.status === "Harvested") {
                  style = "bg-[#FFE1E7] text-[#F491A3]";
                } else if (foodBar?.status === "Upcoming") {
                  style = "bg-[#E7F2EE] text-[#12312B]";
                }
                return (
                  <div key={item.id} className="mt-2 grid grid-cols-2 gap-2">
                    <div>
                      <p className="text-[12px]">
                        {item.mitra.user_name} - {item.farm.farm_name}
                      </p>
                      <p className="text-[12px]">{item.product.product_name}</p>
                      <div className="text-[#40AE49] text-[10px]">
                        {getDateFormatMoment(item.activity_updated_at)}
                      </div>
                    </div>
                    <div className="flex justify-end items-start">
                      <p
                        className={`font-semibold text-[8px] w-[100px] text-center py-2 ${style}`}
                      >
                        {foodBar?.status}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="flex justify-end items-end h-[2%]">
              {recentPlantingSize === 4 ? (
                <p
                  onClick={() => setRecentPlantingSize(1000)}
                  className="text-sm text-right text-[#40AE49] cursor-pointer"
                >
                  View more
                </p>
              ) : (
                <p
                  onClick={() => setRecentPlantingSize(4)}
                  className="text-sm text-right text-[#40AE49] cursor-pointer"
                >
                  show less
                </p>
              )}
            </div>
          </div>
          <div className="shadow-md rounded-lg p-5">
            <div className="h-[98%] mb-2">
              <p className="font-bold text-[16px] text-[#252525]">
                Upcoming Harvesting Activities
              </p>
              {upcomingPlanting.map((item) => {
                return (
                  <div key={item?.id} className="flex mt-2 justify-between">
                    <div className="w-[80%]">
                      <p className="text-[14px]">
                        {item.mitra?.user_name} - {item?.product.product_name} -{" "}
                        {item?.farm?.farm_name}
                      </p>
                      <div className="text-[#40AE49] text-[10px]">
                        {item.expected_harvest_date !== null
                          ? getDateFormatMoment(item?.expected_harvest_date)
                          : ""}
                      </div>
                    </div>
                    <div className="flex w-[20%] justify-end">
                      <p className="text-[12px] text-[#F491A3]">
                        {`In ${moment(item?.expected_harvest_date).diff(new Date(), 'days')} Days`}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="flex justify-end items-end h-[2%]">
              {upHarvestSize === 4 ? (
                <p
                  onClick={() => setUpHarvestSize(1000)}
                  className="text-sm text-right text-[#40AE49] cursor-pointer"
                >
                  View more
                </p>
              ) : (
                <p
                  onClick={() => setUpHarvestSize(4)}
                  className="text-sm text-right text-[#40AE49] cursor-pointer"
                >
                  show less
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
