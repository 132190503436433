import React from "react";

export const InputOtpModal = ({
  hideModal,
  onConfirm,
  label4 = "9999999999",
  inputRefs,
  requestAgain,
  handleInputChange
}) => {
  
  let mainDiv = "w-[100%] px-5 bg-white";
  let btnYesStyle= "border border-[#40AE49] text-[#40AE49] border-[2px] text-[14px] font-[450px] text-center px-5 py-2 w-[140px] rounded-full ml-5";
  let btnNoStyle = "border border-[#F491A3] text-[#F491A3] border-[2px] text-[14px] font-[450px] text-center px-5 py-2 w-[140px] rounded-full";
  
  return (
    <>
      <div className="flex overflow-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center">
        <div className="flex justify-center mt-[3%] mb-[3%]">
          {/*content*/}
          <div
            className={`h-full bg-white w-[35%] first-letter:rounded-lg shadow-lg relative flex flex-col ${mainDiv} outline-none focus:outline-none  rounded-lg`}
          >
            {/* <div className="relative pl-6 pr-6 overflow-auto pt-3 bg-white rounded-t-lg h-full">
            </div> */}
            <div className="relative pl-6 pr-6 overflow-auto pt-3 bg-white rounded-t-lg ">
              <p className="text-[18px] text-center mb-2 mt-3 font-[450px] font-bold">Input OTP</p>
              <p className="text-[13px] text-center mb-5 mt-3"> We are sending a OTP to perform your digital sign.</p>
            </div>
            <div className="flex justify-center bg-white mt-3 ">
            {[...Array(6)].map((_, index) => (
              <input
              key={index}
              ref={(ref) => (inputRefs.current[index] = ref)}
              maxLength={1}
              className={`h-[7vh] ml-2 w-[12%] border-black border-[1px] rounded-lg text-center`}
              onChange={(event) => handleInputChange(index, event)}
              onKeyDown={(event) => {
                if (
                  !/^[0-9]$/.test(event.key) &&
                  event.key !== "Backspace" 
                  //&&
                  //event.key !== "Delete"
                ) {
                  event.preventDefault(); 
                }
              }}
              inputMode="numeric"
                />
              ))}
            </div>
            <div className="relative pl-6 pr-6 overflow-auto pt-3 bg-white rounded-t-lg ">
              <p className="text-[12px] text-center mb-2 mt-3 font-[450px] flex justify-center">A Message has been sent to
              <p className="text-[14px] text-center ml-2 font-bold">{label4}</p>
              </p>
              <p className="text-[12px] text-center mb-2 mt-3 font-[450px] flex justify-center">Don't receive code?
              <button onClick={requestAgain} className="text-[14px] text-center justify-center ml-2 text-green border-b-[1px]  border-green">Request again</button>
              </p>
            </div>
            {/*footer*/}

            <div className="flex justify-center items-center py-6 rounded-b-lg bg-white w-full">
              <div>
                <button
                  className={btnNoStyle}
                  type="button"
                  onClick={() => hideModal()}
                >
                 Cancel
                </button>
                <button
                  className={btnYesStyle}
                  type="button"
                  onClick={() => onConfirm()}
                >
                 Confirm
                </button>
              </div>
              <div />
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>{" "}
    </>
  );
};
