import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { PageLayoutComp } from "./PageLayoutComp";
import { IoIosArrowRoundBack } from "react-icons/io";
import { useDropzone } from "react-dropzone";
import {
  _asyncGetAllFarms,
  _asyncGetEMateraiDocTypes,
  _asyncGetRecipientList,
  _asynceSignDocUpload,
  _fetchMetaData,
} from "../api";
import SearchDropDownComp from "./SearchDropDownComp";
import Multiselect from "multiselect-react-dropdown";
import { toast } from "react-toastify";
import { isArray, isEmpty } from "lodash";
import { MdDragIndicator } from "react-icons/md";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "rgb(209 213 219)",
  borderStyle: "dashed",
  backgroundColor: "#ffffff",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const focusedStyle = {
  borderColor: "rgb(229, 233, 239)",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

export const AddNewESignature = () => {
  const [mitraRelated, setMitraRelated] = useState(true);
  const location = useNavigate();
  const [metaData, setMetaData] = useState();
  const [allFarms, updateAllFarms] = useState([]);
  const [selectedFarm, setSelectedFarm] = useState();
  const [documentName, setDocumentName] = useState();
  const [documentType, setDocumentType] = useState();
  const [eMateraiDocTypes, seteMateraiDocTypes] = useState();
  const [selectedeMateraiDocTypes, setSelectedeMateraiDocTypes] = useState();
  let user = JSON.parse(localStorage.getItem("user_data"));

  const [recipients, setRecipients] = useState([]);
  const [esigntype, setEsignType] = useState();
  const [selectedRecipients, setSelectedRecipients] = useState([]);
  const [isIamSigner, setIsIamSigner] = useState(false);
  const [disabledCheck, setDisabledCheck] = useState(false);
  const draggingPos = useRef(null);
  const dragOverPos = useRef(null);

  const handleDragStart = (position) => {
    draggingPos.current = position;
  };

  const handleDragEnter = (position) => {
    dragOverPos.current = position;
    const newItems = [...selectedRecipients];
    const draggingItem = newItems[draggingPos.current];
    if (!draggingItem) return;

    newItems.splice(draggingPos.current, 1);
    newItems.splice(dragOverPos.current, 0, draggingItem);

    const reorderedItems = newItems.map((item, index) => ({
      ...item,
     
    }));

    draggingPos.current = position;
    dragOverPos.current = null;

    setSelectedRecipients(reorderedItems);
  };

  //adding data to sent to the API

  async function onSubmitClick() {
    let formData = new FormData();
    let recipientList = [];
    //formData for eSign & eMaterai
    if (esigntype?.id === metaData?.eSignTypes[2]?.id) {
      //adding validation checks
      if (mitraRelated && isEmpty(selectedFarm?.id)) {
        toast.error("Please enter farm name", { autoClose: 2000 });
        return;
      }
      if (isEmpty(documentName)) {
        toast.error("Please enter document name", { autoClose: 2000 });
        return;
      }
      if (isEmpty(documentType)) {
        toast.error("Please select document type", { autoClose: 2000 });
        return;
      }
      if (!isArray(selectedRecipients)) {
        toast.error("Please select Recipients", { autoClose: 2000 });
        return;
      }
      if (
        isEmpty(selectedeMateraiDocTypes?.codeDocument) ||
        isEmpty(selectedeMateraiDocTypes?.nameDocument)
      ) {
        toast.error("Please select e-matrai document type", {
          autoClose: 2000,
        });
        return;
      }

      //data preparation and POST to the API

      if (selectedRecipients?.length > 0) {
        recipientList = selectedRecipients.map((item, index) => {
          return {
            signer_id: item.id,
            signing_order: index + 1,
          };
        });
      }

      if (isIamSigner) {
        recipientList = [
          ...recipientList,
          { signer_id: user.id, signing_order: recipientList.length + 1 },
        ];
      }

      console.log("recipients: ", recipientList);

      formData.append("is_related_to_mitra", mitraRelated);
      if (mitraRelated) {
        formData.append("farm_id", selectedFarm?.id);
      }
      formData.append("doc_name", documentName);
      formData.append("esign_type_id", esigntype?.id);
      formData.append("esign_doc_type_id", documentType?.id);
      formData.append(
        "ematerai_doc_type_name",
        selectedeMateraiDocTypes?.nameDocument
      );
      formData.append(
        "ematerai_doc_type_id",
        selectedeMateraiDocTypes?.codeDocument
      );
      formData.append("file", myFiles[0]);

      if (recipientList.length > 0) {
        recipientList.map((item) => {
          formData.append("recipients", JSON.stringify(item));
        });
      }
    }

    if (esigntype == metaData?.eSignTypes[1]) {
      //adding validation checks
      if (mitraRelated && isEmpty(selectedFarm?.id)) {
        toast.error("Please enter farm name", { autoClose: 2000 });
        return;
      }

      if (isEmpty(documentName)) {
        toast.error("Please enter document name", { autoClose: 2000 });
        return;
      }

      if (
        isEmpty(selectedeMateraiDocTypes?.codeDocument) ||
        isEmpty(selectedeMateraiDocTypes?.nameDocument)
      ) {
        toast.error("Please select e-matrai document type", {
          autoClose: 2000,
        });
        return;
      }

      //data preparation and POST to the API

      formData.append("is_related_to_mitra", mitraRelated);
      formData.append("doc_name", documentName);
      formData.append("esign_type_id", esigntype?.id);
      // formData.append("esign_doc_type_id", documentType?.id);
      formData.append(
        "ematerai_doc_type_name",
        selectedeMateraiDocTypes?.nameDocument
      );
      if (mitraRelated) {
        formData.append("farm_id", selectedFarm?.id);
      }
      formData.append(
        "ematerai_doc_type_id",
        selectedeMateraiDocTypes?.codeDocument
      );
      formData.append("file", myFiles[0]);
    }

    // formadata for eSign only
    if (esigntype == metaData?.eSignTypes[0]) {
      //adding validation checks
      if (mitraRelated && isEmpty(selectedFarm?.id)) {
        toast.error("Please enter farm name", { autoClose: 2000 });
        return;
      }
      if (isEmpty(documentName)) {
        toast.error("Please enter document name", { autoClose: 2000 });
        return;
      }
      if (isEmpty(documentType)) {
        toast.error("Please select document type", { autoClose: 2000 });
        return;
      }
      if (!isArray(selectedRecipients)) {
        toast.error("Please select Recipients", { autoClose: 2000 });
        return;
      }

      //data preparation and POST to the API
      let recipientList = [];
      if (selectedRecipients?.length > 0) {
        recipientList = selectedRecipients.map((item, index) => {
          return {
            signer_id: item.id,
            signing_order: index + 1,
          };
        });
      }

      if (isIamSigner) {
        recipientList = [
          ...recipientList,
          { signer_id: user.id, signing_order: recipientList.length + 1 },
        ];
      }
      console.log("recipients: ", recipientList);

      formData.append("is_related_to_mitra", mitraRelated);
      if (mitraRelated) {
        formData.append("farm_id", selectedFarm?.id);
      }
      formData.append("doc_name", documentName);
      formData.append("esign_type_id", esigntype?.id);
      formData.append("esign_doc_type_id", documentType?.id);
      formData.append("file", myFiles[0]);

      if (recipientList.length > 0) {
        if (recipientList.length == 1) {
          const finalrecipentList = recipientList.map((item) => {
            return JSON.stringify(item);
          });

          formData.append("recipients[]", finalrecipentList);
        } else {
          recipientList.map((item) => {
            formData.append("recipients", JSON.stringify(item));
          });
        }
      }
    }

    let response = await _asynceSignDocUpload(formData);
    let task_id = response?.data?.task_id;
    console.log("response = ", response);
    console.log("task id-", task_id);
    if (
      response?.status == 200 ||
      (response?.status == 201 &&
        response?.data?.message == "Task Created Successfully!")
    ) {
      if (esigntype?.id === 1) {
        location("/farm-pipeline/e-sign-placing/" + task_id);
      } else {
        location("/farm-pipeline/e-materai-placing/" + task_id);
      }
    }
  }

  const handleRecipientsOnChange = (event) => {
    let userList = [];
    event.map((item) => {
      userList.push(item);
    });
    setSelectedRecipients(userList);
  };

  async function _getGetAllFarms() {
    const data = await _asyncGetAllFarms();
    if (!data) return;
    let temp = [{ id: "", farm_name: "Select Farm" }];
    updateAllFarms([...temp, ...data]);
  }

  async function _fetchData() {
    let metaData = await _fetchMetaData();

    setMetaData(metaData);

    setEsignType(metaData?.eSignTypes[0]);
  }

  async function _getEMateraiDocTypes() {
    let eMateraiDocTypes = await _asyncGetEMateraiDocTypes();
    seteMateraiDocTypes(eMateraiDocTypes);
    console.log("eMaeraiDocTypes", eMateraiDocTypes);
  }

  const handleDocChange = async (item) => {
    if (item?.id !== "" || item?.id !== null) {
      setDocumentType(item);
      setSelectedRecipients([]);
      const data = item?.id && (await _asyncGetRecipientList(item?.id));
      if (!data) return;
      // if (item?.type_name === "RAB") {
      //   const filteredData = data.filter(
      //     (user) => user.admin_role.role === "Head of Supply"
      //   );
      //   setDisabledCheck(true);
      //   setIsIamSigner(false);
      //   setSelectedRecipients(filteredData);
      // } else {
        setRecipients(data);
        setDisabledCheck(false);
        setSelectedRecipients([]);
      //}
    }
  };

  const handleeMateraiChange = async (item) => {
    if (item?.id !== "" || item?.id !== null) {
      setSelectedeMateraiDocTypes(item);
    }
  };
  useEffect(() => {
    _getGetAllFarms();
    _fetchData();
    _getEMateraiDocTypes();
  }, []);

  const [myFiles, setMyFiles] = useState([]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      setMyFiles([...myFiles, ...acceptedFiles]);
    },
    [myFiles]
  );

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      onDrop,
      accept: {
        "application/pdf": [],
      },
    });

  const removeFile = (file) => () => {
    const newFiles = [...myFiles];
    newFiles.splice(newFiles.indexOf(file), 1);
    setMyFiles(newFiles);
  };

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  function handleBackButton() {
    location("/farm-pipeline/e-sign-dashboard");
  }

  const changeDataType = (type) => {
    setMitraRelated(type == true);
  };

  return (
    <div>
      <PageLayoutComp user={user}>
        <div className="flex justify-between">
          <p className="text-3xl font-bold mb-0">
            Administration - E Signature
          </p>
          <div className="flex">
            <div
              onClick={() => handleBackButton()}
              className="flex cursor-pointer"
            >
              <IoIosArrowRoundBack size={22} color={"green"} className="mt-2" />
              <p className="text-green text-md mt-2 cursor-pointer font-bold">
                Back to List
              </p>
            </div>
          </div>
        </div>
        <div className="bg-white w-full  mt-2 rounded-lg">
          <p className="p-4 font-poppins text-2xl">
            Document e-Sign & e-Materai
          </p>
          <div className="w-full py-5  rounded-lg mt-2 flex divide-x-2 divide-stone-300">
            <div className=" w-[50%]">
              <div className="px-4 mx-[5%]">
                <div className="flex justify-between flex-nowrap">
                  <div className="flex w-[70%] flex-nowrap">
                    <button
                      type="button"
                      onClick={() => {
                        changeDataType(true);
                        //add logic
                      }}
                      className={`${
                        mitraRelated
                          ? "bg-[#40AE49] text-white z-20"
                          : "bg-[#D0D0D0] text-black -z-10"
                      } px-3 py-1 border border-gray-300 rounded-lg `}
                      style={{
                        zIndex: mitraRelated ? 2 : 1,
                        marginRight: "-0.5rem",
                      }}
                    >
                      Related to Mitra
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        changeDataType(false);
                        //add logic
                      }}
                      className={`${
                        !mitraRelated
                          ? "bg-[#40AE49] text-white z-20"
                          : "bg-[#D0D0D0] text-black -z-10"
                      } px-3 py-1 border border-gray-300 rounded-lg`}
                      style={{ zIndex: !mitraRelated ? 2 : 1 }}
                    >
                      Not Related to Mitra
                    </button>
                  </div>
                  <div className="ml-4 relative">
                    <SearchDropDownComp
                      type="type_name"
                      list={metaData?.eSignTypes}
                      width="w-full"
                      onSelected={(item) => setEsignType(item)}
                      active={metaData?.eSignTypes[0]}
                    />
                  </div>
                </div>
                {mitraRelated && (
                  <>
                    <div>
                      <p className="mt-5 font-extralight">
                        Farm<span className="text-red-500">*</span>
                      </p>
                    </div>

                    <div className="relative">
                      <SearchDropDownComp
                        width="w-full"
                        list={allFarms}
                        active={selectedFarm}
                        label="Select Farm"
                        type={"farm_name"}
                        onSelected={(item) => {
                          setSelectedFarm(item);
                        }}
                      />
                    </div>
                  </>
                )}
                <div>
                  <p className="mt-5 font-extralight">
                    Document Name<span className="text-red-500">*</span>
                  </p>
                </div>
                <input
                  type="text"
                  id="first_name"
                  onChange={(e) => {
                    setDocumentName(e.target.value);
                  }}
                  class=" mt-[0.2rem] bg-white border border-gray-300 text-black placeholder-black text-sm rounded-lg  block w-full p-2.5 "
                  placeholder="Type Document Name"
                  required
                />

                {esigntype !== metaData?.eSignTypes[1] && (
                  <>
                    {" "}
                    <div>
                      <p className="mt-5 font-extralight">
                        Document Type<span className="text-red-500">*</span>
                      </p>
                    </div>
                    <div className="relative">
                      <SearchDropDownComp
                        type="type_name"
                        list={metaData?.eSignDocTypes}
                        width="w-full"
                        onSelected={(item) => handleDocChange(item)}
                      />
                    </div>
                  </>
                )}

                {esigntype !== metaData?.eSignTypes[1] && (
                  <>
                    {" "}
                    <div>
                      <p className="mt-5 font-extralight">
                        Recipients<span className="text-red-500">*</span>
                      </p>
                    </div>
                    <div className="relative mb-4">
                      <Multiselect
                        displayValue="user_name"
                        chipColor="#40AE49"
                        //disable={disabledCheck}
                        selectedValues={selectedRecipients || []}
                        options={recipients || []}
                        onSelect={handleRecipientsOnChange}
                        onRemove={handleRecipientsOnChange}
                        showCheckbox
                        style={{ chips: { background: "#40AE49" } }}
                      />
                    </div>
                  </>
                )}

                {selectedRecipients.map((item, index) => {
                  console.log("selectedRecipients", selectedRecipients);

                  return (
                    <div
                      className="recipient-list w-[90%] cursor-grab flex justify-between text-sm mt-2 p-1 ml-4"
                      draggable
                      onDragStart={() => handleDragStart(index)}
                      onDragEnter={() => handleDragEnter(index)}
                      onDragOver={(e) => e.preventDefault()}
                    >
                      <p>
                        {index + 1}. {item.user_name} ({item.admin_role.role})
                      </p>

                      <MdDragIndicator />
                    </div>
                  );
                })}

                {esigntype !== metaData?.eSignTypes[0] && (
                  <>
                    {" "}
                    <div>
                      <p className="mt-5 font-extralight">
                        e-Materai Document Type
                        <span className="text-red-500">*</span>
                      </p>
                    </div>
                    <div className="relative">
                      <SearchDropDownComp
                        type="nameDocument"
                        list={eMateraiDocTypes}
                        width="w-full"
                        onSelected={(item) => handleeMateraiChange(item)}
                      />
                    </div>
                  </>
                )}
              </div>

              {esigntype !== metaData?.eSignTypes[1] && (
                <>
                  {" "}
                  <div className="px-4 mx-[5%] mt-[8%]">
                    <div className="mb-[0.125rem] block min-h-[1.5rem] pl-[1.5rem]">
                      <input
                        className="relative float-left -ml-[1.5rem] mr-[6px] mt-[0.15rem] h-[1.125rem] w-[1.125rem] appearance-none rounded-[0.25rem] border-[0.125rem] border-solid border-neutral-300 outline-none before:pointer-events-none before:absolute before:h-[0.875rem] before:w-[0.875rem] before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] checked:border-green checked:bg-green checked:before:opacity-[0.16] checked:after:absolute checked:after:-mt-px checked:after:ml-[0.25rem] checked:after:block checked:after:h-[0.8125rem] checked:after:w-[0.375rem] checked:after:rotate-45 checked:after:border-[0.125rem] checked:after:border-l-0 checked:after:border-t-0 checked:after:border-solid checked:after:border-white checked:after:bg-transparent checked:after:content-[''] hover:cursor-pointer hover:before:opacity-[0.04] hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:shadow-none focus:transition-[border-color_0.2s] focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-[0.875rem] focus:after:w-[0.875rem] focus:after:rounded-[0.125rem] focus:after:content-[''] checked:focus:before:scale-100 checked:focus:before:shadow-[0px_0px_0px_13px_#3bca8a] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] checked:focus:after:-mt-px checked:focus:after:ml-[0.25rem] checked:focus:after:h-[0.8125rem] checked:focus:after:w-[0.375rem] checked:focus:after:rotate-45 checked:focus:after:rounded-none checked:focus:after:border-[0.125rem] checked:focus:after:border-l-0 checked:focus:after:border-t-0 checked:focus:after:border-solid checked:focus:after:border-white "
                        type="checkbox"
                        disabled={disabledCheck}
                        checked={isIamSigner}
                        onChange={() => setIsIamSigner(!isIamSigner)}
                        id="checkboxDefault"
                      />
                      <label
                        className="inline-block pl-[0.15rem] hover:cursor-pointer font-poppins"
                        htmlFor="checkboxDefault"
                      >
                        I am also the signer
                      </label>
                    </div>
                  </div>
                </>
              )}
            </div>

            <div className=" w-[48%]">
              <div class="flex flex-col mt-[5rem] items-center justify-center w-full  p-4 ">
                <div className="container">
                  <div
                    className="h-64 justify-center items-center"
                    {...getRootProps({ style })}
                  >
                    <svg
                      class="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 20 16"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                      />
                    </svg>
                    <p class="mb-2 text-sm text-black ">
                      <span class="">Select a File</span> or drag and drop here
                    </p>
                    <p class="text-xs text-gray-500 dark:text-gray-400">
                      PDF file size no more than 10MB
                    </p>
                    <p className="inline-block mt-6 cursor-pointer rounded border-2 border-[#40AE49] px-4 py-2 text-base font-medium uppercase leading-normal text-[#40AE49] ">
                      Select File
                    </p>
                    <input {...getInputProps()} />
                  </div>
                </div>

                <div className="items-start text-sm justify-start pt-4 w-full p-2">
                  {myFiles.length > 0 && <p> Files Added </p>}
                  <div className="pt-4 ">
                    {myFiles.map((item) => {
                      return (
                        <div className="flex justify-between">
                          <div className="flex gap-4">
                            <p>{item.name}</p>
                            <p
                              className="text-red-400 cursor-pointer"
                              onClick={removeFile(item)}
                            >
                              {" "}
                              Delete{" "}
                            </p>
                          </div>
                          <p>{(item.size / 1048576).toFixed(2) + " MB"}</p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-[#F9F9F9] mt-[-1%] flex p-6 rounded-b-lg justify-end">
          <button
            onClick={() => onSubmitClick()}
            className="bg-green  px-5 font-poppins text-white py-2 rounded-lg"
          >
            Next
          </button>
        </div>
      </PageLayoutComp>
    </div>
  );
};
