import dashboardImg from "../../images/FarmPipeLine/dashboard.png";
import filterImg from "../../images/FarmPipeLine/filter.png";
import taskImg from "../../images/FarmPipeLine/task.png";
import harvestImg from "../../images/FarmPipeLine/harvest.png";
import farmsImg from "../../images/FarmPipeLine/farms.png";
import farmerImg from "../../images/FarmPipeLine/farmer.png";
import strategyImg from "../../images/FarmPipeLine/strategy.png";
import moment from "moment";

export const NavItemsData = [
  { icon: dashboardImg, name: "Dashboard" },
  { icon: filterImg, name: "Farm Pipeline" },
  { icon: taskImg, name: "Task Manager" },
  { icon: harvestImg, name: "Harvest Management" },
  { icon: farmsImg, name: "Crop Playbooks" },
  { icon: farmerImg, name: "Farms " },
  { icon: strategyImg, name: "Farmer" },
];

export const interestedList = [
  { id: 1, name: "Interested" },
  { id: 2, name: "Not Interested" },
];

export const didTheyRespond = [
  { id: 1, name: "Yes" },
  { id: 2, name: "No" },
];

export const localDataOwnerShipList = ["Private", "Company"];
export const localDataYesNoList = ["Yes", "No"];
export const DataTabletabList = [
  { id: 1, name: "Farms" },
  { id: 2, name: "Products" },
  { id: 3, name: "Users" },
  { id: 4, name: "Archived" },
  { id: 5, name: "Career" },
  { id: 6, name: "MitraEnquiry" },

];
export const archiveTypeList = [
  { id: 1, name: "Farms" },
  { id: 2, name: "User" },
];
export const addedGuestList = [
  "Regina Cooper",
  "Dustin Williamson",
  "Jane Wilson",
  "Brandon Pena",
];
export const SSTabList = [
  "Mitra Data",
  "Location & Facilities",
  "Products & Production",
  "Beleaf PIC",
];
export const NLtabsList = ["Mitra Data", "Location & Facilities", "Documents"];

export const InternalFarmtabsList = [
  "Farm Data",
  "Location & Facilities",
  //"Features Governance",
];
export const EditFarmtabsList = ["Master Data", "App Users", "Documents"];
export const ICStabsList = [
  "Mitra Data",
  "Location & Facilities",
  "Products & Production",
  "Beleaf PIC",
];
export const DDTabList = [
  "Mitra Data",
  "Location & Facilities",
  "Products & Production",
  "Legal & Contractual",
  "Beleaf PIC",
];

export const DOSING_LIST = [
  { id: 1, name: "Automatic" },
  { id: 2, name: "Mannual" },
];

export const EditProductTabsList = ["General", "Cultivation"];

export function formatDate(
  newDate,
  includeYear = true,
  includeDayName = false
) {
  const months = {
    0: "January",
    1: "February",
    2: "March",
    3: "April",
    4: "May",
    5: "June",
    6: "July",
    7: "August",
    8: "September",
    9: "October",
    10: "November",
    11: "December",
  };
  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const d = newDate;
  const year = d.getFullYear();
  const date = d.getDate();
  const monthIndex = d.getMonth();
  const monthName = months[d.getMonth()];
  const dayName = days[d.getDay()]; // Thu
  const formatted = `${includeDayName ? dayName + "," : ""
    } ${date} ${monthName} ${includeYear ? year : ""}`;
  return formatted.toString();
}

export function formatDateBackSlash(newDate) {
  const d = newDate;
  const year = d.getFullYear();
  const date = d.getDate();
  const formatted = `${d.getMonth() + 1}/${date}/${year}`;
  return formatted.toString();
}

export function formatDateGetDayOnly(newDate) {
  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const d = newDate;
  const dayName = days[d.getDay()]; // Thu
  const formatted = `${dayName}`;
  return formatted.toString();
}

export function convertTZ(date, tzString) {
  return new Date(
    (typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {
      timeZone: tzString,
    })
  );
}

export function _getDaysBtwTwoDates(date1, date2) {
  let Difference_In_Time = date2.getTime() - date1.getTime();
  let dif = Difference_In_Time / (1000 * 3600 * 24);
  return Math.round(dif);
}

export function getDateFormatMoment(date, showTime = false) {
  if (!date) return date;
  return showTime ? moment(date).format('DD MMMM YYYY HH:mm') : moment(date).format('DD MMM YYYY')
}

export function getDateFormatMomentWithoutYear(date, showTime = false) {
  if (!date) return date;
  return showTime ? moment(date).format('DD MMMM') : moment(date).format('DD MMM')
}

export function _convertCommaSaparate(val) {
  let val1 = Number(val);
  return new Intl.NumberFormat('id-ID').format(val1);
}
