import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  _apiLogout,
  _asyncGetMerchandiserLogs,
  _asyncGetMerchandiserProducts,
  _asyncGetSuperMarkets,
  _fetchMetaData,
  _asyncGetSMCsvData,
} from "../../api";
import { PageLayoutComp } from "../../components/PageLayoutComp";
import { TableSearchComp } from "../../../src/components/TableSearchComp";
import { MERCHANDISE_TRACKER_TB_COL } from "../../../src/config/tables/MerchandiseTrackerTable";
import { ReactComponent as DailyReportIcon } from "../../../src/images/dailyReportIcon.svg";
import { ReactComponent as ReportIconDownload } from "../../../src/images/ICONS/reportIcon.svg";
import { TablePaginationComp } from "../../components/TablePaginationComp";
import {
  formatDateGetDayOnly,
  getDateFormatMoment,
} from "../../config/localdata";
import unavailable from "../../images/unavailable.png";
import { MerchandiseLogs } from "../../Models/MerchandiseLogs";
import { GetReportModal } from "../../Models/GetReportModal";

export const MerchandiseTracker = () => {
  const size = 10;
  const [selectedPage, setSelectedPage] = useState(0);

  let colums = MERCHANDISE_TRACKER_TB_COL;
  const location = useNavigate();
  let user = JSON.parse(localStorage.getItem("user_data"));
  const [metaData, setMetaData] = useState();
  const [supermarketList, setSupermarketList] = useState([]);
  const [productList, setProductList] = useState([]);
  const [selectedSupermarket, setSelectedSupermarket] = useState("");
  const [selectedSuperMarketForReport, setSelectedSuperMarketForReport] =
    useState();
  const [totalPage, setTotalPage] = useState();
  const [totalItems, setTotalItems] = useState();
  const [logData, setLogData] = useState();
  const [showLogData, setShowLogData] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState();
  const [months, setMonth] = useState([
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]);


  const sortType = [
    { id: "1", name: "Stock: Highest to Lowest" },
    { id: "2", name: "Stock: Lowest to Highest" },
    { id: "3", name: "Updated: Latest to Earliest" },
    { id: "4", name: "Updated: Earliest to Latest" },
  ];

  const MerchandiseTrackerDisplayname = ["SuperMarket"];

  const [sortValue, setSortValue] = useState({ id: "0", name: "Sort By" });

  
  const [showFilter, setShowFilter] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [action, setAction] = useState("");
  const [searchType, setSearchType] = useState("");
  const [getReport, setGetReport] = useState(false);

  useEffect(() => {
    _fetchData();
  }, []);

  useEffect(() => {
    _fetchProducts(selectedPage, selectedSupermarket,searchText,sortValue);
  }, [sortValue]);

  function handleGetReportButtonButton() {
    setGetReport(true);
  }

  async function _fetchData() {
    let metaData = await _fetchMetaData();
    setMetaData(metaData);
    let params = {
      size:1000
    }
    let superMarkets = await _asyncGetSuperMarkets(params);
    setSupermarketList(superMarkets?.displayData);
  }

  const handleSuperMarketChange = async (e) => {
    setSelectedSupermarket(e.target.value);
    if(e.target.value !== ""){
      _fetchProducts(selectedPage,e.target.value)
    }else{
    setProductList([]);
    setTotalPage();
    setTotalItems();
    }
  };


  const _fetchProducts = async (selectedPage, supermarketId,searchText,sortValue=null) =>{
    let params = {
      size: size,
      page: selectedPage + 1,
      search_text: searchText
    };
    
    if(sortValue){
      if(sortValue.name === "Stock: Highest to Lowest" ){
        params.sort_by="product_qty";
        params.sort_order="DESC";
        }

      else  if(sortValue.name === "Stock: Lowest to Highest" ){
        params.sort_by="product_qty";
        params.sort_order="ASC";
        }

      else if(sortValue.name === "Updated: Latest to Earliest" ){
          params.sort_by="stock_last_updated_at";
          params.sort_order="DESC";
         } 
      else if(sortValue.name === "Updated: Earliest to Latest" ){
        params.sort_by="stock_last_updated_at";
        params.sort_order="ASC";
        }      

    }
    
    
    let data = await _asyncGetMerchandiserProducts(supermarketId, params);
    setProductList(data?.data);
    setTotalPage(data?.last_page);
    setTotalItems(data?.totalItems);
  }

  const handleLogdata = async (id) => {
    let logs = await _asyncGetMerchandiserLogs(id);
    setLogData(logs);
    setShowLogData(true);
  };

  const handleSearchSubmit = (action) => {
    setAction(action);
    setSelectedPage(0);
    if (action === "reset") {
      setSearchText("");
      setSearchType("");
       _fetchProducts(0, selectedSupermarket, null)
    }
    if (searchText.length > 2 && action !== "reset") {
        _fetchProducts(selectedPage,selectedSupermarket, searchText)
    }
  };
  

  const convertToCSV = (jsonData) => {
    const headers = Object.keys(jsonData[0]).join(",");
    const rows = jsonData.map((row) => Object.values(row).join(","));
    return [headers, ...rows].join("\n");
  };

  const downloadReport = async () => {
    let params = {
      year: new Date().getFullYear(),
      month: selectedMonth,
      sm_ids: [selectedSuperMarketForReport],
    };
    let data = await _asyncGetSMCsvData(params);
    const csvData = convertToCSV(data);
    if (csvData.length > 0) {
      const blob = new Blob([csvData], { type: "text/csv" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `Super_Market_Report_${new Date().getDate()}_${new Date().getMonth()}_${new Date().getFullYear()}.csv`
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    }
  };

  const handleSuperMarketDownloadReport = (e) => {
    setSelectedSuperMarketForReport(e.target.value);
  };
  const handleMonthDownloadReport = (e) => {
    setSelectedMonth(e.target.value);
  };

  const handleDetails = () => {
    if (selectedSupermarket) {
      location("daily-report-detail/" + selectedSupermarket);
    }
  };

  const handleProductDetails = (id) => {
    if (selectedSupermarket) {
      location("product-detail/" + id);
    }
  };

  return (
    <div className="w-screen h-screen">
      {getReport === true ? (
        <GetReportModal
          supermarketList={supermarketList}
          hideModal={() => setGetReport(false)}
          handleSuperMarketDownloadReport={handleSuperMarketDownloadReport}
          selectedSuperMarketForReport={selectedSuperMarketForReport}
          handleMonthDownloadReport={handleMonthDownloadReport}
          downloadReport={downloadReport}
          months={months}
          selectedMonth={selectedMonth}
        />
      ) : null}

      <PageLayoutComp metaData={metaData} user={user}>
        {showLogData ? (
          <MerchandiseLogs
            hideModal={() => {
              setShowLogData(false);
            }}
            logData={logData}
          />
        ) : null}
        <div className=" h-[100%] bg-[#E6F1ED] p-4">
          <div className="h-[7%]">
            <div className="flex justify-between">
              <p className="text-3xl font-bold mb-0">Merchandise Tracker</p>
            </div>
          </div>
          <div className="relative h-[100%]">
            <div className="h-[calc(99%-6%)] bg-white p-3">
              <div className="flex flex-row">
                <div>
                  <select
                    className=" border h-[2.5rem] border-[#808080] py-2 px-4 pr-8 rounded-[10px] "
                    value={selectedSupermarket}
                    onChange={handleSuperMarketChange}
                  >
                    <option selected value={""}>Select Supermarket</option>
                    {supermarketList.map((item) => {
                      return (
                        <option value={`${item?.id}`}>{item?.name}</option>
                      );
                    })}
                  </select>
                </div>
                <div className="ml-5 w-[100%] pr-3">
                  <TableSearchComp
                    onChange={(text) => setSearchText(text)}
                    openFilter={() => setShowFilter(false)}
                    sortBy={true}
                    sortType={sortType}
                    sortValue={sortValue}
                    handleSortBy={(item) => {
                    setSortValue(item)
                    setSelectedPage(0) }}
                    searchText={searchText}
                    handleSearchSubmit={handleSearchSubmit}
                    searchTypeValues={""}
                    searchType={{display_name:"Product"}}
                    onSearchChangeType={(item) => setSearchType(item)}
                    showFilterBtn={false}
                  />
                </div>
              </div>
              <div className="flex justify-between">
                {selectedSupermarket ? (
                  <div
                    onClick={handleDetails}
                    className="flex w-[15%] flex-row bg-green p-2 mt-1 ml-1 rounded-lg cursor-pointer"
                  >
                    <div className="flex flex-row items-center justify-center">
                      <DailyReportIcon></DailyReportIcon>
                      <div className="ml-1">
                        <p className="text-zinc-50 ">Daily Report</p>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div style={{ width: "15%" }}></div>
                )}
                <div className="flex flex-row float-right bg-[#9FD7A4] p-2 mt-1 mr-4 rounded-lg cursor-pointer">
                  <div
                    onClick={() => handleGetReportButtonButton()}
                    className="flex flex-row items-center justify-center"
                  >
                    <ReportIconDownload></ReportIconDownload>
                    <div className="ml-1">
                      <p className="text-black ">Get Report</p>
                    </div>
                  </div>
                  {/* <p className=" mt-3 px-[1rem] py-[0.5rem] bg-green200">check</p> */}
                </div>
              </div>
              <div className=" relative mt-2 mb-5 h-[90%] ">
                <div className={`h-[80%] overflow-auto scrollbar-hide mt-5`}>
                  <table className="w-full">
                    <thead>
                      <tr className="sticky">
                        {colums.map((col, i) => {
                          return (
                            <td className="text-sm bg-white" key={`tfhk-${i}`}>
                              <div className="flex justify-center items-center py-2 border-gray-200 border-b-[1px] cursor-pointer">
                                <p className="justify-center text-md font-bold text-[#3EB049]">
                                  {col.Header}
                                </p>
                              </div>
                            </td>
                          );
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {productList?.map((data, index) => {
                        return (
                          <tr class="odd:bg-white  even:bg-gray-50  border-b">
                            <td className="px-2 py-2">
                              <div className="flex flex-row">
                                <img
                                  src={
                                    data?.final_product?.image_url ||
                                    unavailable
                                  }
                                  className="w-[65px] h-[65px] text-sm text-[#0090FF] underline cursor-pointer text-center mt-3"
                                />
                                <div className="mt-2 ml-2 flex flex-col ">
                                  <p className="[font-family:'Poppins',Helvetica]  ml-5 mt-auto font-semibold text-[1rem] cursor-pointer" onClick={()=>handleProductDetails(data?.id)}>
                                    {data?.final_product?.name}
                                  </p>
                                  <p className="[font-family:'Poppins',Helvetica] text-[#6d6968] ml-5 text-[14px]">
                                    SKU: {data?.final_product?.default_code}
                                  </p>
                                </div>
                              </div>
                            </td>
                            <td className="px-2 py-2 font-bold">
                              <p>{data?.product_qty}</p>
                            </td>
                            <td className="px-2 py-2 font-bold ">
                              <p className="text-center">
                                {data?.updated_by_merchandiser?.user_name}
                              </p>
                            </td>
                            <td className="px-2 py-2">
                              <p className="[font-family:'Poppins',Helvetica] text-[#6d6968] ml-5 p-2 text-[14px]">
                                {formatDateGetDayOnly(
                                  new Date(data?.stock_last_updated_at)
                                ) +
                                  ", " +
                                  getDateFormatMoment(
                                    data?.stock_last_updated_at,
                                    true
                                  )}
                              </p>
                            </td>
                            <td className="px-2 py-2">
                              <div className="float-right">
                                <span
                                  onClick={() => handleLogdata(data?.id)}
                                  style={{ height: "8px", marginLeft: "35px" }}
                                  className="justify-center p-2  items-center mt-4 rounded-lg cursor-pointer h-[25px] border border-gray-400"
                                >
                                  Log
                                </span>
                              </div>
                            </td>
                          </tr>
                        );
                      })}

                      <td></td>
                    </tbody>
                  </table>
                </div>
                <div className="flex justify-end items-center h-[10%] mt-5 text-sm px-5">
                  {totalPage !== undefined ? (
                    <TablePaginationComp
                      totalPages={totalPage}
                      active={selectedPage}
                      selectedPage={(p) => {
                        setSelectedPage(p);
                        _fetchProducts(p, selectedSupermarket,searchText,sortValue);
                      }}
                      totalItems={totalItems}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageLayoutComp>
    </div>
  );
};
