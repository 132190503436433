import React, { useEffect, useState } from "react";
import {
  _asyncCancelPO,
  _asyncGetWarehouses,
  _asyncSubmitPOBill,
} from "../api";
import closeImg from "../images/FarmPipeLine/close.png";
import { formatDate, _convertCommaSaparate } from "../config/localdata";
import { ConfirmationModal } from "./ConfirmationModal";
import { SuccessfullMesg } from "./SuccessfullMesg";
import { toast } from "react-toastify";

export const GenerateBillModal = ({ hideModal, metaData, generatePoBody }) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);


  function _getPlantingStatus(id) {
    let data = "";
    data = metaData?.allowedBillStatus?.filter((e) => e.id == id);
    return data && data[0] && data[0]?.status_name;
  }

  console.log("+++++++++++++++++", generatePoBody)


  const colorObject = {
    5: "border-[#40AE49] bg-[#40AE49]",
    2: "border-[#12312B] bg-[#12312B]",
    1: "border-[#F491A3] bg-[#F491A3]",
    4: "border-[#F491A3] bg-[#F491A3]",
    3: "border-[#12312B] bg-[#12312B]",
  };

  const _cancelPO = async () => {
    let body = {
      draft_bill_ids: generatePoBody.map(item =>item.bill_id),
    };
    const apiResponse = await _asyncSubmitPOBill(body);
    if (apiResponse === 1) {
      setShowSuccessModal(true);
      setShowConfirmModal(false);
      setTimeout(() => {
        window.location.reload();
      }, 600);
      return;
    }
    toast.error(apiResponse);
    return;
  };

  return (
    <>
      <div className="flex overflow-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center">
        {showSuccessModal ? (
          <SuccessfullMesg label={`Success submit bill`} />
        ) : null}
        {showConfirmModal ? (
          <ConfirmationModal
            label={`Are you sure to submit this bill ?`}
            label2="No"
            type="planting"
            customStyles={true}
            hideModal={() => setShowConfirmModal(false)}
            onConfirm={() => {
              setShowConfirmModal(false);
              _cancelPO();
            }}
          />
        ) : null}
        <div className="w-[70%] h-[75%] relative mt-[3%] mb-[3%]">
          {/*content*/}
          <div className="h-full first-letter:rounded-lg shadow-lg relative flex flex-col w-full outline-none focus:outline-none rounded-lg">
            <div className="relative p-10 overflow-auto pt-3 bg-white rounded-t-lg h-full">
              <img
                className="float-right w-[30px] h-[30px] mt-3"
                src={closeImg}
                alt="closeImg"
                onClick={() => {
                  hideModal();
                }}
              />
              {generatePoBody?.map((poData, num)=>{
                return(<>
              <div className="mt-6">
                <p className="font-semibold text-[15px] text-black">
                  {poData?.bill_name}
                </p>
                <div className="w-[100px] mt-2">
                  <p
                    className={`text-[10px] p-0 font-[600] text-white text-center border ${
                      colorObject[poData?.bill_state]
                    }`}
                  >
                    {_getPlantingStatus(poData?.bill_state)}
                  </p>
                </div>
              </div>
              <div className="mt-5 grid grid-rows-6"></div>
              <div className="flex flex-row justify-between">
                <div className="">
                  <p className="text-black text-[15px] mt-2 mr-3">
                    Vendor Name: {poData?.odoo_vendor_name}
                  </p>
                </div>
                <div className="flex">
                  <p className="text-black text-[15px] mt-2 mr-3">
                    Created Date :{" "}
                    {formatDate(
                      new Date(poData?.bill_created_at),
                      true,
                      false
                    )}
                  </p>
                </div>
              </div>
              <div className="flex rounded-lg border border-gray-500 px-3 mt-4">
                <table style={{ width: "90%", textAlign: "center" }}>
                  <tr>
                    <th style={{backgroundColor: "#fff",color: "black",textAlign: "center",padding: "2px"}}>PO</th>
                    <th style={{backgroundColor: "#fff", color: "black", textAlign: "center",padding: "2px"}}>Product</th>
                    {poData?.po_s[0]?.inspection_info.length > 0 ? null:<th style={{backgroundColor: "#fff", color: "black", textAlign: "center",padding: "2px"}}>Code</th>}
                    {poData?.po_s[0]?.inspection_info.length > 0 ? null: <th style={{backgroundColor: "#fff", color: "black", textAlign: "center",padding: "2px"}}>Harvest Batch</th>}
                    <th style={{backgroundColor: "#fff", color: "black", textAlign: "center",padding: "2px"}}>Quantity</th>
                    <th style={{backgroundColor: "#fff", color: "black", textAlign: "center",padding: "2px"}}>UoM</th>
                    <th style={{backgroundColor: "#fff", color: "black", textAlign: "center",padding: "2px"}}>Unit Price</th>
                    <th style={{backgroundColor: "#fff", color: "black", textAlign: "center",padding: "2px"}}>Price</th>
                  </tr>
                  {poData?.po_s?.map((item, index) => {
                    
                    return(
                  item?.inspection_info.length > 0 ?
                  <>
                  {item?.inspection_info?.map((podata) => {
                    return (
                      <tr key={podata.production_code}>
                        <td style={{textAlign: "center", padding: "2px", backgroundColor: "white"}}>
                          {item?.po_name}
                        </td>
                        <td style={{textAlign: "center", padding: "2px", backgroundColor: "white"}}>
                                {podata?.display_name}
                              </td>
                              {/* <td style={{textAlign: "center", padding: "2px", backgroundColor: "white"}}>
                                {podata?.production_code}
                              </td> */}
                              {/* <td style={{textAlign: "center", padding: "2px", backgroundColor: "white"}}>
                                {podata?.harvest_batch_id}
                              </td> */}
                              <td style={{textAlign: "center", padding: "2px", backgroundColor: "white"}}>
                                {_convertCommaSaparate(podata?.accepted_qty)}
                              </td>
                              <td style={{textAlign: "center", padding: "2px", backgroundColor: "white"}}>
                                {podata?.uom}
                              </td>
                              <td style={{textAlign: "center", padding: "2px", backgroundColor: "white"}}>
                                {_convertCommaSaparate(podata?.unit_price)}
                              </td>
                              <td style={{textAlign: "center", padding: "2px", backgroundColor: "white"}}>
                                {_convertCommaSaparate(podata?.unit_price * podata?.accepted_qty) || 0}
                              </td>
                              </tr>)
                  })}
                </>
                  :<>
                  {item?.harvests?.map((podata) => {
                    return (
                      <tr key={podata.production_code}>
                        <td style={{textAlign: "center", padding: "2px", backgroundColor: "white"}}>
                          {item?.po_name}
                        </td>
                        <td style={{textAlign: "center", padding: "2px", backgroundColor: "white"}}>
                                {podata?.product_name}
                              </td>
                              <td style={{textAlign: "center", padding: "2px", backgroundColor: "white"}}>
                                {podata?.production_code}
                              </td>
                              <td style={{textAlign: "center", padding: "2px", backgroundColor: "white"}}>
                                {podata?.harvest_batch_id}
                              </td>
                              <td style={{textAlign: "center", padding: "2px", backgroundColor: "white"}}>
                                {_convertCommaSaparate(podata?.received_harvest_amount)}
                              </td>
                              <td style={{textAlign: "center", padding: "2px", backgroundColor: "white"}}>
                                {podata?.display_uom}
                              </td>
                              <td style={{textAlign: "center", padding: "2px", backgroundColor: "white"}}>
                                {_convertCommaSaparate(podata?.decided_unit_price_for_po)}
                              </td>
                              <td style={{textAlign: "center", padding: "2px", backgroundColor: "white"}}>
                                {_convertCommaSaparate(podata?.total_received_harvest_price) || 0}
                              </td>
                              </tr>)
                  })}
                </>
                )
                  })}
                </table>
              </div>
              <div className="float-right mr-32 mt-3">
                <p className="font-bold">
                  Total: Rp. {poData?.po_s[0]?.inspection_info.length > 0 ? _convertCommaSaparate(poData?.total_bill_cost_after_inspecting_po):_convertCommaSaparate(poData?.total_bill_cost_after_receciving_po)}
                  {/* total_cost_for_inspected_po
                  total_bill_cost_after_receciving_po
                  total_bill_cost_after_inspecting_po */}
                </p>
              </div>
              <hr className={`h-px mt-20 w-full bg-gray-400 border-0 ${num >= 0 ? '':'hidden'}`}/>
              </>)
              })}
              <div className="float-right mr-32 mt-3">
                <p className="font-bold">
                  Grand Total: Rp. {generatePoBody[0]?.po_s[0]?.inspection_info.length > 0 ? _convertCommaSaparate(generatePoBody?.reduce( ( sum, { total_bill_cost_after_inspecting_po } ) => sum + total_bill_cost_after_inspecting_po , 0)) :_convertCommaSaparate(generatePoBody?.reduce( ( sum, { total_bill_cost } ) => sum + total_bill_cost , 0))}
                </p>
              </div>
            </div>
            {/*footer*/}
            <div className="py-4 rounded-b-lg bg-white w-full px-10">
              {generatePoBody[0]?.bill_state === 1 && (
                <div className="flex justify-end">
                  <p
                    onClick={() => setShowConfirmModal(true)}
                    className="bg-[#40AE49] text-white px-6 py-1 border border-[#40AE49] rounded-lg cursor-pointer"
                  >
                    Submit Bill
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>{" "}
    </>
  );
};
