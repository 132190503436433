export const USERS_TB_COL = [
  {
    Header: "USER ID",
    accessor: "id",
  },
  {
    Header: "NAME",
    accessor: "user_name",
  },
  {
    Header: "CONTACT NO",
    accessor: "contact_number",
  },
  // {
  //   Header: "ROLE",
  //   accessor: "admin_role_id",
  // },
];

export const USERS_TB_COL_BELEAF = [
  {
    Header: "USER ID",
    accessor: "id",
  },
  {
    Header: "EMAIL",
    accessor: "email",
  },
  {
    Header: "NAME",
    accessor: "user_name",
  },
  {
    Header: "CONTACT NO",
    accessor: "contact_number",
  },
  {
    Header: "ROLE",
    accessor: "admin_role_id",
  },
];

export const CAREER_TB_COL = [
  {
    Header: "USER ID",
    accessor: "id",
  },
  {
    Header: "JOB POSITION",
    accessor: "job_Position",
  },
  {
    Header: "PLACEMENT",
    accessor: "placement",
  },
  {
    Header: "TEAM",
    accessor: "team",
  },
  {
    Header: "EXPERIENCE",
    accessor: "experience",
  },
];

export const PRODUCT_TB_COL = [
  {
    Header: "PRODUCT IMAGE",
    accessor: "product_image",
  },
  {
    Header: "PRODUCT CODE",
    accessor: "production_code",
  },
  {
    Header: "PRODUCT NAME",
    accessor: "product_name",
  },
  {
    Header: "CATEGORY",
    accessor: "category_id",
  },
  {
    Header: "PRODUCT",
    accessor: "product_general_name",
  },
  {
    Header: "VARIANT",
    accessor: "variant_name",
  },
  {
    Header: "MATURITY",
    accessor: "maturity_id",
  },
];
export const MITRA_ENQUIRY_TB_COL = [

  {
    Header: "FULL NAME",
    accessor: "full_name",
  },
  {
    Header: "Email",
    accessor: "email",
  },
  {
    Header: "PHONE NUMBER",
    accessor: "phone_number",
  },
  {
    Header: "COMPANY",
    accessor: "company",
  },
  {
    Header: "PRODUCT",
    accessor: "product",
  },
  {
    Header: "LOCATION",
    accessor: "location",
  },
  {
    Header: "SUBJECT",
    accessor: "subject",
  },
  {
    Header: "DETAILS",
    accessor: "details",
  },
];
export const CONTACT_ENQUIRY_TB_COL = [

  {
    Header: "FULL NAME",
    first_name: "first_name",
    last_name: "last_name"
  },
  {
    Header: "Email",
    accessor: "email",
  },
  {
    Header: "PHONE NUMBER",
    accessor: "phone_number",
  },
  {
    Header: "COMPANY NAME",
    accessor: "company_name",
  },
  {
    Header: "COMPANY EMAIL",
    accessor: "company_email",
  },
  {
    Header: "COUNTRY",
    accessor: "country",
  },
  {
    Header: "MESSAGE",
    accessor: "message",
  },
];
export const ESIGNATURE_TB_COL = [
  {
    Header: "Document Name",
    accessor: "name",
  },
  {
    Header: "Document Type",
    accessor: "dc_type",
  },
  {
    Header: "Created By",
    accessor: "created_by",
  },
  {
    Header: "Last Update At",
    accessor: "last_updated",
  },
  {
    Header: "Recipient",
    accessor: "recipients",
  },
  {
    Header: "Actions",
    accessor: "action",
  },
];

export const FARM_PIPELINE_TB_COL = ["#", "FARM NAME", "FARM CONTACT", "FAAS PIC", "FARM TYPES", "PROVINCE", "DISTRICT", "OWNERSHIP", "RAB"];

export const PLANT_TB_COL = [
  {
    Header: "Plant",
    accessor: "product_name",
  },
  {
    Header: "Mitra",
    accessor: "user_name",
  },
  {
    Header: "Farm",
    accessor: "farm_name",
  },
  {
    Header: "FaaS PIC",
    accessor: "user_name",
  },
  {
    Header: "Target (Kg)",
    accessor: "target_harvest_quantity",
  },
  {
    Header: "UoM",
    accessor: "area_uom",
  },
  {
    Header: "Planting Date",
    accessor: "planting_date",
  },
  {
    Header: "Notes",
    accessor: "comments_on_planting_start",
  },
  {
    Header: "Actual Planting Date",
    accessor: "actual_planting_date",
  },
  {
    Header: "Expected Harvest Date",
    accessor: "expected_harvest_date",
  },
  {
    Header: "Stage",
    accessor: "current_growth_stage_id",
  },
  {
    Header: "Status",
    accessor: "current_planting_status_id",
  },
  {
    Header: "RAB",
    accessor: "rab_id",
  },
  {
    Header: "Log",
    accessor: "log",
  },
];

export const RAB_TB_COL = [
  // {
  //   Header: "#",
  //   accessor: "s_no",
  // },
  {
    Header: "RAB",
    accessor: "rab_name",
  },
  {
    Header: "Farm",
    accessor: "farm_name",
  },
  {
    Header: "Mitra",
    accessor: "user_name",
  },
  {
    Header: "Created at",
    accessor: "created_at",
  },
  // {
  //   Header: "Planting No",
  //   accessor: "planting_number",
  // },
  {
    Header: "Status",
    accessor: "state",
  },
  {
    Header: ".",
    accessor: "detail",
  },
];

export const BOM_TB_COL = [
  // {
  //   Header: "#",
  //   accessor: "s_no",
  // },
  {
    Header: "Product",
    accessor: "product_name",
  },
  {
    Header: "Reference",
    accessor: "reference_name",
  },
  {
    Header: "BOM Type",
    accessor: "bom_type_name",
  },
  // {
  //   Header: "Created at",
  //   accessor: "created_at",
  // },
  // {
  //   Header: "Planting No",
  //   accessor: "planting_number",
  // },
  // {
  //   Header: "Status",
  //   accessor: "state",
  // },
  // {
  //   Header: ".",
  //   accessor: "detail",
  // },
];

export const HARVST_TB_COL = [
  {
    Header: "Plant",
    accessor: "product_name",
  },
  {
    Header: "Mitra",
    accessor: "user_name",
  },
  {
    Header: "Farm",
    accessor: "farm_name",
  },

  {
    Header: "Target (Kg)",
    accessor: "target_harvest_quantity",
  },
  {
    Header: "UoM",
    accessor: "area_uom",
  },
  {
    Header: "Planting Date",
    accessor: "actual_planting_date",
  },
  {
    Header: "Harvest Date",
    accessor: "harvest_complete_date",
  },
  {
    Header: "Notes",
    accessor: "comments_on_planting_start",
  },
  {
    Header: "Amount  Harvested",
    accessor: "total_actual_harvest",
  },
  {
    Header: "Performance",
    accessor: "current_growth_stage_id",
  },
  {
    Header: "Type",
    accessor: "harvest_class_id",
  },
  {
    Header: "Status",
    accessor: "current_harvest_status_id",
  },
];

export const CARDS_DATA = [
  {
    title: "Average Conversion Period",
    days: "19 days",
    percentage: "4.85%",
    progress: true,
  },
  {
    title: "Rejection Rate",
    days: "28%",
    percentage: "5.25%",
    progress: false,
  },
  {
    title: "Total Hectares in Pipeline",
    days: "3.500K",
    percentage: "3.55%",
    progress: true,
  },
  {
    title: "Active leads",
    days: "28",
    percentage: "10.30%",
    progress: false,
  },
];

export const MITRA_LIST_TB_COL = [
  {
    Header: "No.",
    accessor: "id",
  },
  {
    Header: "Mitra",
    accessor: "mitra",
  },
  {
    Header: "Farm Name",
    accessor: "farm",
  },
  {
    Header: "Farm Address",
    accessor: "farm",
  },
];
