import React, { useEffect, useState } from "react";
import { RAB_TB_COL } from "..";
import {_asyncGetAllPlanting, _asyncGetAllRab, _asyncGetRabDetails, _asyncGetRabDocDownload, _asyncGetRabLogs, _updateRabDocs, _updateRabStatus} from "../../../api";
import { TableSearchComp } from "../../../components/TableSearchComp";
import { TablePaginationComp } from "../../../components/TablePaginationComp";
import { useNavigate } from "react-router-dom";
import { PlantFilterModal } from "../../../Models/PlantFilterModal";
import { RabStatusModal } from "../../../Models/RabStatusModal";
import { LogDataModal } from "../../../Models/LogDataModal";
import { getDateFormatMoment } from "../../localdata";
import { ConfirmationModal } from "../../../Models/ConfirmationModal";
import { ReviseReasonRabModal } from "../../../Models/ReviseReasonRabModal";
import { RabDocUpload } from "../../../Models/RabDocUpload";
import { toast } from "react-toastify";
import { RabFilterModal } from "../../../Models/RabFilterModal";

export const RABTable = ({metaData, archive = false}) => {
  const size = 10;
  let colums = RAB_TB_COL;
  const location = useNavigate();
  const configFetchRef = React.useRef(false);
  const [totalPage, setTotalPage] = useState();
  const [selectedPage, setSelectedPage] = useState(0);
  const [tableDataList, setTableDataList] = useState([]);
  const [allFarmsData, setAllFarmsData] = useState([]);
   const [showFilter, setShowFilter] = useState(false);
   const [filterData, setFilterData] = useState({});
   const [showDetailsRab, setShowDetailsRab] = useState(false);
   const [showLogData, setShowLogData] = useState(false);
   const [rabData, setRabData] = useState();
   const [logData, setLogData] = useState();
   const [showConfirmModal, setShowConfirmModal] = useState(false);
   const [showConfirmModalDoc, setShowConfirmModalDoc] = useState(false);
   const [showDocUploadModal, setShowDocUploadModal] = useState(false);
   const [showReviseModal, setShowReviseModal] = useState(false);
   const [reviseReason, setReviseReason] = useState('');
   const [rabBudgetPlanDoc, setRabBudgetPlanDoc] = useState('');
   const [showSuccessModal, setShowSuccessModal] = useState(false);
   const [action, setAction] = useState("");
   const [confirmMsg, setConfirmMsg] = useState("");
   const [rabObject, setRabObject] = useState();
   const [searchText, setSearchText] = useState("")
   const [totalItems, setTotalItems] = useState();
   const [searchType, setSearchType] = useState('');
   const [sortValue, setSortValue] = useState({id: "3",name: "Sort By"});
   let user = JSON.parse(localStorage.getItem("user_data"));



   const sortType = [
    { id: "1", name: "Latest" },
    { id: "2", name: "Earliest" },
  ];


   // filter data
  const [selectedProduct, setSelectedProduct] = useState();
  const [selectedFarm, setSelectedFarm] = useState();
  const [selectedStatus, setSelectedStatus] = useState();
  const [selectedMitra, setSelectedMitra] = useState();
  const [selectedUsed, setSelectedUsed] = useState();
  

  useEffect(() => {
    // if (configFetchRef.current) return;
    // configFetchRef.current = true;
    let sort_order = sortValue?.name === "Earliest" ? "ASC":"DESC"
    _fetchPlant(selectedPage,filterData, sort_order);
  }, [sortValue]);


  const resetFilter = () =>{
    setShowFilter(false);
    setFilterData({})
    _fetchPlant(selectedPage, {});
    setSelectedProduct()
    setSelectedFarm()
    setSelectedStatus()
    setSelectedMitra()
    setSelectedUsed()
  }

  const handleDetaildata = async (data) =>{
    let details = await _asyncGetRabDetails(data?.id);
    setRabObject(details)
    setRabData(data)
    setShowDetailsRab(true);
  }
  
  const addFilters = () =>{
    let product_ids = []
    let farm_ids = []
    let rab_state_ids = []
    let mitra_ids = []
    let linked_to_planting = []
    if(selectedProduct !== undefined){
        product_ids.push(selectedProduct.id)
    }
    if(selectedFarm !== undefined){
        farm_ids.push(selectedFarm.id)
    }
    if(selectedStatus !== undefined){
      rab_state_ids.push(selectedStatus.id)
    }
    if(selectedMitra !== undefined){
        mitra_ids.push(selectedMitra.id)
    }
    if(selectedUsed !== undefined){
      linked_to_planting = selectedUsed.typeValue
  }
    setSelectedPage(0)
    _applyFilters({product_ids, farm_ids, rab_state_ids, mitra_ids, linked_to_planting})
  }

   const _fetchPlant = async (page, filterData, sort_order = null)=> {   
    let data = {
      page: page+1,
      size:size,
      ...filterData,
    }
    if(searchType?.search_by_key && searchText){
      data[searchType?.search_by_key] = searchText;
    }
    let res = await _asyncGetAllRab(data, sort_order);
    let { displayData, totalPage, totalItems } = res;
    setTableDataList(
      displayData.map((d) => {
        d.isSelectedForArchive = false;
        return d;
      })
    );
    setAllFarmsData(
      displayData.map((d) => {
        d.isSelectedForArchive = false;
        return d;
      })
    );
    setTotalPage(totalPage);
    setTotalItems(totalItems)
  }

  async function _applyFilters(items) {
    setShowFilter(false);
    setFilterData({...items})
    let data = {
      page: 0,
      size:size,
      ...items
    }
    let res = await _asyncGetAllRab(data);
    let { displayData, totalPage, totalItems } = res;
    setTableDataList(
      displayData.map((d) => {
        d.isSelectedForArchive = false;
        return d;
      })
    );
    setAllFarmsData(
      displayData.map((d) => {
        d.isSelectedForArchive = false;
        return d;
      })
    );
    setTotalPage(totalPage);
    setTotalItems(totalItems);
  }

  

  const onStatusClick = async(status) => {
    let msg = '';
    if(status === 'logs'){
      let res = await _asyncGetRabLogs(rabData?.id);
      setLogData(res)
      setShowLogData(true)
    }
    if(status === 'upload'){
      setShowDocUploadModal(true)
    }
    if(status === 'preview'){
      location('/budget-plan-rab-preview/'+rabData?.id)
    }
    if(status === 'draft' || status === 'editRevision'){
      if (!user?.permissions?.update_rab) {
        alert("Don't have update RAB Permission");
        return;
      } 
      location('/budget-plan-rab-create/'+rabData?.id)
    }
    
    if(status === 'revise'){
      if (!user?.permissions?.revise_rab) {
        alert("Don't have Revise RAB Permission");
        return;
      } 
      msg = 'Are you sure want to Revise this RAB ?';
      setShowReviseModal(true);
    }
    if(status === 'approve'){

      if (!user?.permissions?.approve_rab) {
        alert("Don't have Approve RAB Permission");
        return;
      } 
      msg = 'Are you sure want to Approve this RAB ?';
      setShowConfirmModal(true);
    }
    if(status === 'download'){
      let res = await _asyncGetRabDocDownload(rabObject?.rab_doc_info?.id);
        const linkSource = `data:${rabObject?.rab_doc_info?.doc_mime_type};base64,${res.data}`;
        // console.log('ooooooooooo',rabObject?.rab_doc_info?.doc_mime_type);
        const downloadLink = document.createElement("a");
        downloadLink.href = linkSource;
        downloadLink.download = rabObject?.data?.rab_name;
        downloadLink.click();
    }

    
    setAction(status)
    setConfirmMsg(msg)
  }

  const _addRabData = async () =>{
    let body = {}
    if(action === 'revise'){
      body.reason = reviseReason;
    }
    let res = await _updateRabStatus(rabData?.id, action, body)
    setShowReviseModal(false);
    setShowDetailsRab(false);
    _fetchPlant(selectedPage, {});
  }

  const handleFileChange = (event) => {
    setRabBudgetPlanDoc(event.target.files[0]);
  };

  const _addDocsData = async () =>{
    let formData = new FormData();
    formData.append('rab_budget_plan', rabBudgetPlanDoc);
    let res = await _updateRabDocs(rabData?.id, formData);
  if (res.status === 200 && res.statusText === "OK") {
    setShowSuccessModal(true);
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }
  _fetchPlant(selectedPage);
 }


 useEffect(()=>{
  _fetchPlant(selectedPage)
},[action])


const handleSearchSubmit = (action) => {
  setSelectedPage(0);
  setAction(action)
  if (action === "reset") {
    setSearchText("");
    setSearchType("");
    _fetchPlant(0, filterData)
  }
  if (searchText.length > 2 && action !== "reset") {
    _fetchPlant(0, filterData)
  }
}

 const onSubmitClick = () => {
  if (rabBudgetPlanDoc === "") {
    toast.error("Please upload rab document", {autoClose: 2000});
    return;
  }
  setShowConfirmModalDoc(true);
};

  return (
    <div className={`${archive ? "h-[85%]" : "h-[100%]"}`}>
      {showFilter ? (
        <RabFilterModal
          metaData={metaData}
          filterData={filterData}
          hideModal={() => setShowFilter(false)}
          resetFilter={resetFilter}
          addFilters={addFilters}
          selectedProduct={selectedProduct}
          selectedFarm={selectedFarm}
          selectedStatus={selectedStatus}
          selectedMitra={selectedMitra}
          selectedUsed={selectedUsed}
          setSelectedProduct={setSelectedProduct}
          setSelectedFarm={setSelectedFarm}
          setSelectedStatus={setSelectedStatus}
          setSelectedMitra={setSelectedMitra}
          setSelectedUsed={setSelectedUsed}
        />
      ) : null}

      {showDetailsRab ?
      <RabStatusModal
       hideModal={() => {setShowDetailsRab(false)}}
       rabData={rabObject}
       onStatusClick={onStatusClick}
      />:null}

      {showLogData ? (
        <LogDataModal
          hideModal={() => {setShowLogData(false)}}
          logData={logData}
        />
      ) : null}

    {showReviseModal ? (
        <ReviseReasonRabModal
          hideModal={() => {
            setShowReviseModal(false)
            setShowConfirmModal(false)
          }}
          setReviseReason={setReviseReason}
          reviseReason={reviseReason}
          submitRevision={() => {setShowConfirmModal(true)}}
        />
      ) : null}

{showConfirmModal ? (
        <ConfirmationModal
          label={confirmMsg}
          label2="No"
          type="planting"
          customStyles={true}
          hideModal={() => setShowConfirmModal(false)}
          onConfirm={() => {
            setShowConfirmModal(false);
            _addRabData();
          }}
        />
      ) : null}

{showDocUploadModal ? (
    <RabDocUpload
    hideModal={() => setShowDocUploadModal(false)}
    handleFileChange={handleFileChange}
    showSuccessModal={showSuccessModal}
    showConfirmModal={showConfirmModalDoc}
    setShowConfirmModal={setShowConfirmModalDoc}
    _addDocsData={_addDocsData}
    onSubmitClick={onSubmitClick}
    />
  ):null}
     

      <div className="h-[100%]">
        <div className="h-[10%]">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div className="w-[100%] mt-4">
              <TableSearchComp
              onChange={(text) => setSearchText(text)}
              openFilter={() => setShowFilter(true)}
              sortBy={true}
              sortType={sortType}
              sortValue={sortValue}
              handleSortBy={(item) => setSortValue(item)}
              searchText={searchText}
              handleSearchSubmit={handleSearchSubmit}
              searchTypeValues={metaData?.searchableFeaturesWithOptions?.rab_list}
              searchType={searchType}
              onSearchChangeType={(item) => setSearchType(item)}
            />
            </div>
          </div>
        </div>
        <div className={`h-[80%] overflow-auto scrollbar-hide mt-5`}>
          <table className="w-full">
            <thead>
              <tr className="sticky">
                
                {colums.map((col, i) => {
                  return (
                    <td className="text-sm bg-white" key={`tfhk-${i}`}>
                      <div className="flex justify-center items-center py-2 border-gray-200 border-b-[1px] cursor-pointer">
                        <p className="justify-center text-md font-bold text-[#3EB049]">{col.Header}</p>
                      </div>
                    </td>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {tableDataList.map((data, index) => {
                return (
                  <tr
                    key={`row-${index}`}
                    className="border-gray-200 border-b-[1px]"
                  >
                    {colums.map((col, i) => {
                      if (col.Header === "Mitra") {
                        return (
                          <td key={`tbfc-${i}`} className="py-2">
                            <p className="text-sm flex justify-center items-center">
                              {data?.mitra_info[col.accessor]}
                            </p>
                          </td>
                        );
                      } else if (col.Header === "Farm") {
                        return (
                          <td key={`tbfc-${i}`} className="py-2">
                            <p className="text-sm flex justify-center items-center">
                              {data?.farm[col.accessor]}
                            </p>
                          </td>
                        );
                      } 

                      // else if (col.Header === "#") {
                      //   return (
                      //     <td key={`tbfc-${i}`} className="py-2">
                      //       <b className="text-sm flex">
                      //         {(index+1)}
                      //       </b>
                      //     </td>
                      //   );
                      // } 

                      else if (col.Header === "Created at") {
                        return (
                          <td key={`tbfc-${i}`} className="py-2">
                            <p className="text-sm">
                              {data?.created_at ? getDateFormatMoment(data?.created_at): '-'}
                            </p>
                          </td>
                        );
                      } 

                      else if (col.Header === "Planting No") {
                        return (
                          <td key={`tbfc-${i}`} className="py-2">
                            <p className="text-sm flex justify-center items-center">
                              {data?.planting_activities[0]?.planting_batch_id ? data?.planting_activities[0].planting_batch_id: '-'}
                            </p>
                          </td>
                        );
                      } 

                      else if (col.Header === "Status") {
                        return (
                          <td key={`tbfc-${i}`} className="py-2">
                         

                    <div style={{'backgroundColor':data?.current_rab_state?.highlight_color}} className={`flex justify-center items-center`}>
                      <p className={`font-semibold text-[10px] w-[160px] ${data?.current_rab_state?.state === 'Created' ? 'text-black':'text-white'} text-center py-2`}>
                      {data?.current_rab_state?.state}
                      </p>
                    </div>
                    </td>
                    );
                      } else if (col.accessor === "detail") {
                        return (
                          <td key={`tbfc-${i}`} className="px-4">
                            <div onClick={() => handleDetaildata(data)} className="flex w-[64px] h-[24px] items-center justify-center gap-[10px] px-[17px] py-[4px] relative bg-white rounded-[5px] border border-solid border-grey cursor-pointer">
                              <div className="relative w-fit mt-[-1.50px] ml-[-1.00px] mr-[-1.00px] [font-family:'Poppins-Medium',Helvetica] font-medium text-black text-[11px] text-center tracking-[0] leading-[normal]">
                                Detail
                              </div>
                            </div>
                          </td>
                          );
                      } else {
                        return (
                          <td key={`tbfc-${i}`} className="py-2">
                            <p
                              title={
                                data[col.accessor] == null
                                  ? ""
                                  : data[col.accessor].toString()
                              }
                              className="text-sm justify-center items-center max-w-sm ml-2"
                            >
                              {data[col.accessor] == null
                                ? "-"
                                : data[col.accessor].toString()}
                            </p>
                          </td>
                        );
                      }
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="flex justify-end items-center h-[10%] text-sm px-5">
          {totalPage !== undefined ? (
            <TablePaginationComp
              totalPages={totalPage}
              active={selectedPage}
              selectedPage={(p) => {
                setSelectedPage(p);
                _fetchPlant(p, filterData)
              }}
              totalItems={totalItems}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};
