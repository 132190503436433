import { useEffect, useState } from "react";
import {
  _asyncGetAllConsumablesInventory,
  _fetchMetaData,
} from "../api";
import { PageLayoutComp } from "./PageLayoutComp";
import { useNavigate, useParams } from "react-router-dom";
import { IoIosArrowRoundBack } from "react-icons/io";
import { getDateFormatMoment } from "../config/localdata";
import pending from "../images/pending.png";
import completed from "../images/completed.png";


export const InventoryDetails = () => {
  let user = JSON.parse(localStorage.getItem("user_data"));
  const location = useNavigate();
  const params = useParams();
  const [metaData, setMetaData] = useState();
  const [data, setData] = useState();

  function _getDeliveryStatus(id) {
    let data = "";
    data = metaData?.consumablesDeliveryTypes?.filter((e) => e.id === id);
    return data && data[0] && data[0]?.delivery_type;
  }

async function _fetchInventoryDetails() {
  let data = {
    rab_id:params?.id
  }
  const res = await _asyncGetAllConsumablesInventory(data,location);
  setData(res?.displayData[0])
}

useEffect(() => {
  _fetchData();
  _fetchInventoryDetails();
}, []);

  async function _fetchData() {
    let metaData = await _fetchMetaData();
    setMetaData(metaData);
  }

  const goback = () =>{
    location('/budget-plan-rab/inventory-list');
  }

  return (
    <PageLayoutComp metaData={metaData} user={user}>
    <div className="h-[100%] bg-[#E6F1ED] p-2">
      <div className="h-[7%]">
        <div className="flex justify-between">
          <p className="text-xl font-bold mb-0">{data?.rab_name}</p>
          <div className="flex">
              <IoIosArrowRoundBack size={25} color={'green'} className="mt-2"/>
            <p onClick={()=>goback()} className="text-green mt-2 cursor-pointer underline">Go to Consumables</p>
          </div>
        </div>
      </div>
      <div className="h-[90%]">
        <div className="flex bg-[#E6F1ED] h-[10px]">
         
        </div>
        <div className="h-[calc(100%-10px)] bg-white p-3">
        <div className="flex flex-row w-full">
                  <div className="basis-1/2 mt-0">
                    <p className="text-[12px] font-bold">
                      Product :<span className="ml-7">{data?.product?.product_name}</span>
                    </p>
                    <p className="text-[12px] font-bold">
                      Farm : <span className="ml-10">{data?.farm?.farm_name}</span>
                    </p>
                    <p className="text-[12px] font-bold">
                      Mitra : <span className="ml-10">{data?.farm?.odoo_vendor_name}</span>
                    </p>
                  </div>

                  <div className="basis-1/3 mt-0">
                    <p className="text-[14px] font-bold">
                      Consumables 
                    </p>
                    {data?.consumables_inventory_info.map((item)=>{
                      return(
                        <p className="text-[10px] font-bold w-full">
                          {item?.sku?.name} : <span className="text-[12px] text-[#000] font-bold ml-3">{item?.req_quantity ? item?.req_quantity:0} / {item?.total_qty}</span>
                        </p>
                      )
                    })}
                  </div>
          </div>
          <div class="relative overflow-x-auto max-h-[420px] w-full mt-5">
          <table className=" w-full table-auto overflow-scroll text-sm text-left rtl:text-right text-gray-500 mt-5">
                      <thead class="text-xs text-gray-700 bg-gray-50">
                          <tr>
                              <th scope="col" class="px-3 py-3 text-center">
                              Request Number
                              </th>
                              <th scope="col" class="px-3 py-3 text-center">
                                Item
                              </th>
                              <th scope="col" class="px-3 py-3 text-center">
                                Amount
                              </th>
                              <th scope="col" class="px-3 py-3 text-center">
                              Delivery Method
                              </th>
                              <th scope="col" class="px-3 py-3 text-center">
                              Pick Up Location
                              </th>
                              <th scope="col" class="px-3 py-3 text-center">
                              Requested at
                              </th>
                              <th scope="col" class="px-3 py-3 text-center">
                              Delivered at
                              </th>
                              <th scope="col" class="px-3 py-3 text-center">
                              Expected Pickup/Delivery Date
                              </th>
                          </tr>
                      </thead>
                      <tbody>
                      {data?.consumable_requests?.map((item, index)=>{
                      return(
                          <tr class="odd:bg-white even:bg-gray-50 border-b">
                              <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap text-center">
                                {item?.request_number} {item?.current_state_id === 12 ? <img src={completed} className="w-3 h-3 ml-1 inline" />:<img src={pending} className="w-3 h-3 ml-1 inline" />}
                              </th>
                              <td class="px-6 py-4">
                                {item?.items.map((req)=>{return(
                                  <p>{req?.sku?.name}</p>
                                )})}
                              </td>
                              <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap text-center">
                              {item?.items.map((req)=>{return(
                                  <p>{req.req_quantity}</p>
                              )})}
                              </th>
                              <td class="px-6 py-4">
                              {_getDeliveryStatus(item?.delivery_type_id)}
                              </td>
                              <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap text-center">
                               {item?.delivery_warehouse_name ? item?.delivery_warehouse_name:'-'}
                              </th>
                              <td class="px-6 py-4">
                              {getDateFormatMoment(item.created_at)}
                              </td>
                              <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap text-center">
                                {item?.current_state_id === 12 ? getDateFormatMoment(item.updated_at):'-'}
                              </th>
                              <td class="px-6 py-4 text-center">
                                {getDateFormatMoment(item.expected_date)}
                              </td>
                          </tr>
                          )
                        })}
                      </tbody>
                  </table>
              </div>
        </div>
      </div>
    </div>
    </PageLayoutComp>
  );
};

