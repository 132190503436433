import React, { useEffect, useState } from "react";
import closeImg from "../images/FarmPipeLine/close.png";
import crossImg from "../images/FarmPipeLine/cross.png";
import {
    _apiFetchUsers,
    _asyncGetAllFarms,
  _asyncGetAllProducts,
  _asyncGetMitras,
} from "../api";
import SearchDropDownComp from "../components/SearchDropDownComp";
import { InputFieldComp } from "../components/InputFieldComp";
import { DatePickerRangeComp } from "../components/DatePickerRangeComp";
import AsyncSelect from 'react-select/async';


let timeoutRef = null

export const HarvestFilterModal = ({
  hideModal,
  metaData,
  applyFilters,
  resetFilter,
  addFilters,
  selectedProduct, 
  setSelectedProduct,
  selectedFarm, 
  setSelectedFarm,
  selectedStatus, 
  setSelectedStatus,
  selectedType, 
  setSelectedType,
  selectedMitra, 
  setSelectedMitra,
  selectedTarget, 
  setSelectedTarget,
  selectedHoles, 
  setSelectedHoles,
  selectedAmtHarvested, 
  setSelectedAmtHarvested,
  slectedPlantingDate, 
  setSlectedPlantingDate,
  slectedExpectedHarvestDate, 
  setSlectedExpectedHarvestDate,
}) => {
  const metaDataDetails = metaData;

  const [allFarms, updateAllFarms] = useState([]);
  const [allProducts, updateAllProducts] = useState([]);
  const [allMitras, updateAallMitras] = useState([]);
  let user = JSON.parse(localStorage.getItem("user_data"));

  const [searchProduct, setSearchProduct] = useState("");



  // const [selectedProduct, setSelectedProduct] = useState();
  // const [selectedFarm, setSelectedFarm] = useState();
  // const [selectedStatus, setSelectedStatus] = useState();
  // const [selectedType, setSelectedType] = useState();

  // const [selectedMitra, setSelectedMitra] = useState();
  // const [selectedTarget, setSelectedTarget] = useState();
  // const [selectedHoles, setSelectedHoles] = useState();
  // const [selectedAmtHarvested, setSelectedAmtHarvested] = useState();


  // const [slectedPlantingDate, setSlectedPlantingDate] = useState([null, null]);
  // const [slectedExpectedHarvestDate, setSlectedExpectedHarvestDate] = useState([null, null]);


  const makeDateRange = (data) =>{
    if(data[0] && data[1]){
      let startDate = new Date(data[0]);
      let endDate = new Date(data[1]);
      let s = (startDate.getMonth() +1) + "/" + startDate.getDate()  + "/" + startDate.getFullYear();
      let e = (endDate.getMonth() +1) + "/" + endDate.getDate()  + "/" + endDate.getFullYear();
      return s +'-'+ e;
    }else{
      return null;
    }
  }


  // const addFilters = () =>{
  //   let product_ids = []
  //   let farm_ids = []
  //   let harvest_status_ids = []
  //   let mitra_ids = []
  //   let target_harvests = [];
  //   let planting_date_range = [];
  //   let actual_harvest_date_range = [];
  //   let holes = [];
  //   let total_actual_harvests = []
  //   let harvest_class_ids = []


  //   if(selectedProduct !== undefined){
  //       product_ids.push(selectedProduct.id)
  //   }
  //   if(selectedFarm !== undefined){
  //       farm_ids.push(selectedFarm.id)
  //   }
  //   if(selectedStatus !== undefined){
  //     harvest_status_ids.push(selectedStatus.id)
  //   }
  //   if(selectedType !== undefined){
  //     harvest_class_ids.push(selectedType.id)
  //   }
  //   if(selectedMitra !== undefined){
  //       mitra_ids.push(selectedMitra.id)
  //   }
  //   if(selectedTarget !== undefined){
  //       target_harvests.push(selectedTarget)
  //   }

  //   if(selectedHoles !== undefined){
  //     holes.push(selectedHoles)
  //   }

  //   if(selectedAmtHarvested !== undefined){
  //     total_actual_harvests.push(selectedAmtHarvested)
  //   }

    

  //   if(slectedPlantingDate !== undefined){
  //     let startDate = new Date(slectedPlantingDate[0]);
  //     let endDate = new Date(slectedPlantingDate[1]);
  //     let s = (startDate.getMonth() +1) + "/" + startDate.getDate()  + "/" + startDate.getFullYear();
  //     let e = (endDate.getMonth() +1) + "/" + endDate.getDate()  + "/" + endDate.getFullYear();
  //       planting_date_range.push(s);
  //       planting_date_range.push(e);
  //   }

  //   if(slectedExpectedHarvestDate !== undefined){
  //     let startDate = new Date(slectedExpectedHarvestDate[0]);
  //     let endDate = new Date(slectedExpectedHarvestDate[1]);
  //     let s = (startDate.getMonth() +1) + "/" + startDate.getDate()  + "/" + startDate.getFullYear();
  //     let e = (endDate.getMonth() +1) + "/" + endDate.getDate()  + "/" + endDate.getFullYear();
  //     actual_harvest_date_range.push(s);
  //     actual_harvest_date_range.push(e);
  //   }
  //   applyFilters({
  //     product_ids, 
  //     farm_ids, 
  //     harvest_class_ids, 
  //     mitra_ids, 
  //     target_harvests, 
  //     planting_date_range, 
  //     actual_harvest_date_range, 
  //     holes,
  //     total_actual_harvests
  //   })
  // }

  const _getGetAllProducts = async (name = null) => {
    let params = {
      product_name: name
    }
    const data = await _asyncGetAllProducts(params)
    if (!data) return;
    updateAllProducts(data);
   
  }


  const handleSelectChange = (item) => {
    setSelectedProduct(item);
  };

  const handleSelectSearch = (item) => {
    setSearchProduct(item);
  };

  const loadOptions = (searchValue, callBack) => {
    clearTimeout(timeoutRef);
    timeoutRef = setTimeout(async () => {
      let params = {
        product_name: searchValue,
      };
      const data = await _asyncGetAllProducts(params);
      if (!data) return;
      updateAllProducts(data);
      callBack(data);
    }, 1000);
  };

  async function _getGetAllMitras() {
    const data = await _asyncGetMitras();
    if (!data) return;
		//let temp = [{id: "",user_name: "Select Mitra"}]
        updateAallMitras([...data]);
  }

  async function _getGetAllFarms() {
    const data = await _asyncGetAllFarms();
    if (!data) return;
		//let temp = [{id: "",farm_name: "Select Farm"}]
    updateAllFarms([...data]);
  }  

  useEffect(() => {
    _getGetAllProducts();
    _getGetAllFarms();
    if(user.role.role !== "Guest"){
      _getGetAllMitras();
    }
  },[]);


  if (allProducts.length == 0 || allFarms.length === 0) return null;

  return (
    <>
      <div className="flex overflow-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-full mt-[3%] ml-[7%] mr-[7%] mb-[3%]">
          {/*content*/}
          <div className="h-full border-0 rounded-lg shadow-lg relative flex flex-col w-full outline-none focus:outline-none">
            <div className="relative p-10 overflow-auto pt-3 bg-white rounded-t-lg h-full">
              <img
                className="float-right w-[30px] h-[30px] mt-3"
                src={closeImg}
                alt="closeImg"
                onClick={() => {
                  hideModal();
                }}
              />
              <p className="font-poppins font-semibold text-[24px] mt-3">
                FILTER
              </p>
              <div class="grid grid-cols-5 gap-6 mt-6">
                <div className=" z-10">
                  <p className="mb-8 font-poppins font-medium ">Plant</p>
                  {/* <SearchDropDownComp
                    type="product_name"
                    list={allProducts}
                    onSelected={(item) => {setSelectedProduct(item)}}
                  /> */}
                   <AsyncSelect
                        cacheOptions
                        value={selectedProduct}
                        placeholder="Search Product"
                        inputValue={searchProduct}
                        isClearable={true}
                        filterOption={false}
                        loadOptions={loadOptions}
                        getOptionLabel={(allProducts) => allProducts.product_name}
                        getOptionValue={(allProducts) => allProducts.id}
                        defaultOptions={allProducts}
                        onInputChange={handleSelectSearch}
                        onChange={handleSelectChange}
                      />
                </div>
               { user.role.role !== "Guest" && <div>
                  <p className="mb-8 font-poppins font-medium">Mitra</p>
                  <SearchDropDownComp
                    type="mitra"
                    list={allMitras}
                    onSelected={(item) => setSelectedMitra(item)}
                  />
                </div>}
                <div>
                  <p className="mb-8 font-poppins font-medium">Farm</p>
                  <SearchDropDownComp
                    type="farm"
                    list={allFarms}
                    onSelected={(item) => setSelectedFarm(item)}
                  />
                </div>
                <div>
                  <p className="mb-8 font-poppins font-medium">Holes</p>
                    <InputFieldComp
                      type="text"
                      compulsory={false}
                      placeholder={"input holes"}
                      value={selectedHoles}
                      onChange={(text) => {
                        if (/^[0-9]*$/.test(text)) {
                          setSelectedHoles(text)
                        }
                      }}
                    />
                </div>


                <div>
                  <p className="mb-8 font-poppins font-medium">Status</p>
                  <SearchDropDownComp
                    type="plantingStatus"
                    list={metaDataDetails.plantingStatus}
                    onSelected={(item) => setSelectedStatus(item)}
                  />
                </div>

                <div>
                  <p className="mb-8 font-poppins font-medium">Type</p>
                  <SearchDropDownComp
                    type="type"
                    list={metaDataDetails.cropHarvestClasses}
                    onSelected={(item) => setSelectedType(item)}
                  />
                </div>

          

                <div>
                  <p className="mb-8 font-poppins font-medium">Target</p>
                    <InputFieldComp
                      type="text"
                      compulsory={false}
                      placeholder={"input (Kg)"}
                      value={selectedTarget}
                      onChange={(text) => {
                        if (/^[0-9.]*$/.test(text)) {
                          setSelectedTarget(text)
                        }
                      }}
                    />
                </div>

                <div>
                  <p className="mb-8 font-poppins font-medium">Amount Harvested</p>
                    <InputFieldComp
                      type="text"
                      compulsory={false}
                      placeholder={"input (Kg)"}
                      value={selectedAmtHarvested}
                      onChange={(text) => {
                        if (/^[0-9.]*$/.test(text)) {
                          setSelectedAmtHarvested(text)
                        }
                      }}
                    />
                </div>

                <div>
                  <p className="mb-8 font-poppins font-medium">Planting Date</p>
                  <DatePickerRangeComp dateRange={slectedPlantingDate} onSelected={(item) => {setSlectedPlantingDate(item)}} />
                </div>

                <div>
                  <p className="mb-8 font-poppins font-medium">Harvest Date</p>
                  <DatePickerRangeComp dateRange={slectedExpectedHarvestDate} onSelected={(item) => {setSlectedExpectedHarvestDate(item)}} />
                </div>

              </div>
              <div class="grid grid-cols-5 gap-6 mt-6">
                <div className="flex">
                {selectedProduct  ? (
                    <div className="flex justify-center items-center border border-[#252525] px-3 py-1 rounded-lg">
                      <img
                        className="w-[15px] h-[15px] mr-2"
                        src={crossImg}
                        alt="crossImg"
                        onClick={() => {setSelectedProduct(undefined)}}
                      />
                      <p className="text-[14px] font-normal">
                        {selectedProduct?.product_name.toString()}
                      </p>
                    </div>
                  ) : null}
                </div>
                <div className="flex">
                  {selectedFarm !== undefined ? (
                    <div className="flex justify-center items-center border border-[#252525] px-3 py-1 rounded-lg">
                      <img
                        className="w-[15px] h-[15px] mr-2"
                        src={crossImg}
                        alt="crossImg"
                      />
                      <p className="text-[14px] font-normal">
                        {selectedFarm.farm_name.toString()}
                      </p>
                    </div>
                  ) : null}
                </div>
                <div className="flex">
                  {selectedStatus !== undefined ? (
                    <div className="flex justify-center items-center border border-[#252525] px-3 py-1 rounded-lg">
                      <img
                        className="w-[15px] h-[15px] mr-2"
                        src={crossImg}
                        alt="crossImg"
                      />
                      <p className="text-[14px] font-normal">
                        {selectedStatus.status.toString()}
                      </p>
                    </div>
                  ) : null}
                </div>
                <div className="flex">
                  {selectedMitra !== undefined ? (
                    <div className="flex justify-center items-center border border-[#252525] px-3 py-1 rounded-lg">
                      <img
                        className="w-[15px] h-[15px] mr-2"
                        src={crossImg}
                        alt="crossImg"
                      />
                      <p className="text-[14px] font-normal">
                        {selectedMitra.user_name.toString()}
                      </p>
                    </div>
                  ) : null}
                </div>
                <div className="flex">
                  {selectedTarget !== undefined ? (
                    <div className="flex justify-center items-center border border-[#252525] px-3 py-1 rounded-lg">
                      <img
                        className="w-[15px] h-[15px] mr-2"
                        src={crossImg}
                        alt="crossImg"
                      />
                      <p className="text-[14px] font-normal">
                        {selectedTarget.toString()}
                      </p>
                    </div>
                  ) : null}
                </div>

                <div className="flex">
                  {makeDateRange(slectedPlantingDate) ? (
                    <div className="flex justify-center items-center border border-[#252525] px-3 py-1 rounded-lg">
                      <img
                        className="w-[15px] h-[15px] mr-2"
                        src={crossImg}
                        alt="crossImg"
                      />
                      <p className="text-[14px] font-normal">
                        {makeDateRange(slectedPlantingDate)}
                      </p>
                    </div>
                  ) : null}
                </div>

                <div className="flex">
                  {makeDateRange(slectedExpectedHarvestDate) ? (
                    <div className="flex justify-center items-center border border-[#252525] px-3 py-1 rounded-lg">
                      <img
                        className="w-[15px] h-[15px] mr-2"
                        src={crossImg}
                        alt="crossImg"
                      />
                      <p className="text-[14px] font-normal">
                        {makeDateRange(slectedExpectedHarvestDate)}
                      </p>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="flex items-center p-6 border-t border-solid border-slate-200 rounded-b-lg bg-white w-full">
              <div className="grid grid-cols-2 gap-2 w-full">
                <div></div>
                <div className="justify-end flex">
                  <button
                    className="border border-green text-green mr-10 px-5 py-1 rounded-full hover:text-white hover:bg-green"
                    type="button"
                    onClick={() => resetFilter()}
                  >
                    RESET
                  </button>
                  <button
                    className="bg-[#3EB049] text-white mr-10 px-5 py-1 rounded-full"
                    type="button"
                    onClick={addFilters}>
                    APPLY
                  </button>
                </div>
              </div>
              <div />
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>{" "}
    </>
  );
};