import React, { useEffect, useState } from "react";
import DropDownComp from "../components/DropDownComp";
import {
  _asyncGetAllContact,
  _asyncGetAllFarmInfo,
  _asyncGetAllProductList,
  _asyncGetPCPSIC,
  _asyncGetWarehouses,
  _asyncSubmitInternalTransfer,
} from "../api";
import closeImg from "../images/FarmPipeLine/close.png";
import { DatePickerComp } from "../components/DatePickerComp";
import { _convertCommaSaparate } from "../config/localdata";
import SearchDropDownComp from "../components/SearchDropDownComp";
import { toast } from "react-toastify";

export const InternalTransferModal = ({
  hideModal,
  metaData,
  data,
  submitPo,
}) => {
  let dataArray = [data]
  const [allWarehouse, updateAllWarehouse] = useState([]);
  const [contactList, updateContactList] = useState([]);
  const [productsList, updateProductsList] = useState([]);
  const [farmInfo, updateFarmInfo] = useState({});
  const [productListArray, setProductListArray] = useState([
    {
      product: null,
      qty: "",
    },
  ]);
  const [warehouse, setWarehouse] = useState({ warehouse_name: "Select.." });
  const [contacts, setContacts] = useState({ internal_contact_name: "Select.." });
  const [farms, setFarms] = useState({ farm_name: "Select.." });
  const [paymentMethod, setPaymentMethod] = useState({
    method_name: "Select..",
  });
  const [arrivalDate, setArrivalDate] = useState();

  async function _getGetAllWarehouse() {
    const data = await _asyncGetWarehouses();
    if (!data) return;
    updateAllWarehouse([...data]);
  }
  async function _getAllContactList() {
    const data = await _asyncGetAllContact();
    if (!data) return;
    updateContactList([...data]);
  }
  async function _getAllProductList() {
    const data = await _asyncGetAllProductList();
    // console.log(data,'----data----');
    if (!data) return;
    updateProductsList([...data]);
  }
  async function _getAllFarmInfo(farm_id) {
    const data = await _asyncGetAllFarmInfo(farm_id);
    await localStorage.setItem(
      "location_ids",
      JSON.stringify(data?.location_ids)
    );
    if (!data) return;
    updateFarmInfo(data);
  }

  useEffect(() => {
    _getGetAllWarehouse();
    _getAllContactList();
    _getAllProductList();
  }, []);

  useEffect(() => {
    _getAllFarmInfo(data?.farm_id);
  }, [data?.farm_id]);

  const handleChange = (type = "input", value, index) => {
    switch (type) {
      case "input":
        let oldData = [...productListArray];
        let obj = oldData[index];
        obj.qty = value;
        oldData[index] = obj;
        setProductListArray(oldData);
        break;
      case "picker":
        let oldDataPicker = [...productListArray];
        let objPicker = oldDataPicker[index];
        objPicker.product = value;
        oldDataPicker[index] = objPicker;
        setProductListArray(oldDataPicker);
        break;
    }
  };
  const [selectedOperationType, setSelectedOperationType] = useState({
    display_name: "Select..",
  });
  const [selectedSourceLocation, setSelectedSourceLocation] = useState({
    display_name: "Select..",
  });
  const [selectedDestinationLocation, setSelectedDestinationLocation] =
    useState({ display_name: "Select.." });
  const [allPsic, updateAllPsic] = useState([]);

  const submitInternalTransfer = async () => {
    if (contacts?.internal_contact_name === "Select..") {
      alert("Please select contact.");
      return;
    }
    if (!arrivalDate) {
      alert("Please select date.");
      return;
    }
    const error = productListArray.some((item) => {
      return item?.product == null;
    });
    if (error) {
      alert("Please select a product.");
      return;
    }
    const error2 = productListArray.some((item) => item?.qty == null);
    if (error2) {
      alert("Please enter product quantity.");
      return;
    }
    if (selectedOperationType?.display_name === "Select..") {
      alert("Please select Operation type.");
      return;
    }
    if (selectedSourceLocation?.display_name === "Select..") {
      alert("Please select SourceLocation.");
      return;
    }
    if (selectedDestinationLocation?.display_name === "Select..") {
      alert("Please select DestinationLocation.");
      return;
    }


    const body = {
      crops_data: productListArray.map((obj) => {
        return {
          harvest_id: data?.harvest_id,
          quantity: obj?.qty,
          pc_psic_info: {
            name: obj?.product?.name,
            code: obj?.product?.code,
            uom: obj?.product?.uom,
            o_uom_id:obj?.product?.o_uom_id,
            o_id: obj?.product?.o_id,
            
          },
        };
      }),
      schedule_date: arrivalDate,
      contact_id: contacts?.id,
      contact_name: contacts?.internal_contact_name,
      operation_type_id: selectedOperationType?.o_id,
      operation_type_name: selectedOperationType?.display_name,
      src_location_id: selectedSourceLocation?.o_id,
      src_location_name: selectedSourceLocation?.display_name,
      dest_location_id: selectedDestinationLocation?.o_id,
      dest_location_name: selectedDestinationLocation?.display_name,
    };
// console.log(body,'-----datttttt');
// return
    const response = await _asyncSubmitInternalTransfer(body);
    if (response) {
      toast.success('Transferred successfully!!')
      hideModal();
    }
  };
  async function _getPCPSIC(params) {
    const data = await _asyncGetPCPSIC(params);
    if (!data) return;
    let items = [];
    let productionUniqueCode = Object.values(
      dataArray?.reduce((acc, item) => {
        acc[item.production_code] = item;
        return acc;
      }, {})
    );
    if (productionUniqueCode.length > 0) {
      for (let i = 0; i < productionUniqueCode.length; i++) {
        items.push(...data[productionUniqueCode[i]?.production_code]);
      }
    }
    updateAllPsic([...items]);
  }
  useEffect(() => {
    let productionCode = []
    
    if(dataArray.length>0){
      productionCode = dataArray.map((item)=>item.production_code)
      productionCode = productionCode.filter((ite,inde)=>productionCode.indexOf(ite) === inde)
    }
    let Data = {
      product_codes:productionCode,
      vendor_id:dataArray[0]?.odoo_vendor_id
    }
    _getPCPSIC(Data)
  },[]);
 

  return (
    <>
      <div className="flex overflow-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center">
        <div className="w-[70%] h-[70%] relative mt-[3%] mb-[3%]">
          {/*content*/}
          <div className="h-full first-letter:rounded-lg shadow-lg relative flex flex-col w-full outline-none focus:outline-none rounded-lg">
            <div className="relative p-10 overflow-auto pt-3 bg-white rounded-t-lg h-full">
              <img
                className="float-right w-[30px] h-[30px] mt-3"
                src={closeImg}
                alt="closeImg"
                onClick={() => {
                  hideModal();
                }}
              />
              <div className="mt-6">
                <p className="font-semibold text-[15px] text-[#40AE49]">
                  INTERNAL TRANSFER
                </p>
              </div>
              <div className="">
                <p className="text-black text-[12px] mt-2 mr-3 font-bold">
                  {data?.farm_name}
                </p>
              </div>
              <div className="mt-5 grid grid-rows-6"></div>
              <div className="mt-4 grid grid-cols-6">
                <div>
                  <p className="font-bold">Contact</p>
                </div>
                <div>
                  <DropDownComp
                    listData={contactList}
                    active={contacts}
                    type={"contact_name"}
                    onSelected={(item) => setContacts(item)}
                    customStyles={
                      "rounded-lg justify-between text-black text-[10px] h-[7vh] ml-5"
                    }
                    customMenuStyle={
                      "relative inline-block text-left w-[200px] px-3"
                    }
                  />
                </div>
              </div>
              <div className="mt-3 grid grid-cols-5">
                <div>
                  <p className="font-bold">Schedule Date</p>
                </div>
                <div>
                  <DatePickerComp
                    onSelected={(item) => {
                      setArrivalDate(item);
                    }}
                    value={arrivalDate}
                    allowPastDate={true}
                  />
                </div>
              </div>

              <div className=" rounded-lg border border-gray-500  mt-4">
                <div className="flex w-full bg-[#D7EFD9] h-[7vh] rounded-lg border">
                  <div className="w-[60%] h-[7vh] flex justify-center items-center font-bold">
                    <p>PC/PSIC</p>
                  </div>
                  <div className="w-[20%] h-[7vh] flex justify-center items-center font-bold">
                    <p>Quantity</p>
                  </div>
                  <div className="w-[20%] h-[7vh] flex justify-center items-center font-bold">
                    <p>UoM</p>
                  </div>
                </div>

                {productListArray?.map((obj, i) => {
                  return (
                    <ProductCard
                      handleChange={handleChange}
                      listData={allPsic}
                      key={obj?.id}
                      index={i}
                      obj={obj}
                    />
                  );
                })}

                {/* <div className="flex justify-center mt-[15px] mb-[15px]">
                  <p
                    onClick={() => {
                      let arr = [...productListArray];
                      arr.push({
                        product: {},
                        qty: null,
                      });
                      setProductListArray(arr);
                    }}
                    className="bg-[#3EB049] text-white px-4 border border-[#3EB049] rounded-full cursor-pointer text-[37px]"
                  >
                    +
                  </p>
                </div> */}
              </div>

              <div className="mt-12 grid grid-cols-4">
                <div>
                  <p className="font-bold">Operation Type</p>
                </div>
                <div>
                  <DropDownComp
                    listData={farmInfo?.picking_type_ids}
                    active={selectedOperationType}
                    type={"internal_transfer_locations"}
                    onSelected={(item) => setSelectedOperationType(item)}
                    customStyles={
                      "rounded-lg justify-between text-black text-[10px]"
                    }
                    customMenuStyle={
                      "relative inline-block text-left w-[350px] px-5"
                    }
                  />
                </div>
              </div>
              <div className="mt-5 grid grid-cols-4">
                <div>
                  <p className="font-bold">Source Location</p>
                </div>
                <div>
                  <DropDownComp
                    listData={farmInfo?.location_ids}
                    active={selectedSourceLocation}
                    type={"internal_transfer_locations"}
                    onSelected={(item) => setSelectedSourceLocation(item)}
                    customStyles={
                      "rounded-lg justify-between text-black text-[10px]"
                    }
                    customMenuStyle={
                      "relative inline-block text-left w-[350px] px-5"
                    }
                  />
                </div>
              </div>
              <div className="mt-5 grid grid-cols-4">
                <div>
                  <p className="font-bold">Destination Location</p>
                </div>
                <div>
                  <DropDownComp
                    listData={farmInfo?.location_ids}
                    active={selectedDestinationLocation}
                    type={"internal_transfer_locations"}
                    onSelected={(item) => setSelectedDestinationLocation(item)}
                    customStyles={
                      "rounded-lg justify-between text-black text-[10px]"
                    }
                    customMenuStyle={
                      "relative inline-block text-left w-[350px] px-5"
                    }
                  />
                </div>
              </div>
            </div>
            {/*footer*/}
            <div className="py-4 rounded-b-lg bg-white w-full px-10">
              <div className="flex justify-end">
                <p
                  onClick={submitInternalTransfer}
                  className="bg-[#3EB049] text-white px-10 py-2 border border-[#3EB049] rounded-[10px] cursor-pointer"
                >
                  Submit
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>{" "}
    </>
  );
};

const ProductCard = ({ handleChange, listData, index, obj }) => {
  const [selected, setSelected] = useState({ name: "Select.." });
  return (
    <div className="flex w-full  mt-[12px] mb-[12px] h-[7vh]">
      <div className="w-[60%] h-[7vh] flex justify-center items-center">
        <DropDownComp
          listData={listData}
          active={selected}
          type={"sku"}
          onSelected={(item) => {
            setSelected(item);
            handleChange("picker", item, index);
          }}
          customStyles={
            "rounded-lg justify-between text-black text-[10px] h-[5vh]"
          }
          customMenuStyle={"relative inline-block text-left w-[100%] px-3"}
        />
      </div>
      <div className="w-[20%] h-[7vh] flex justify-center items-center">
        <input
          className="flex w-[50%] h-[5vh] justify-center py-3 border rounded-lg border-gray-300 items-center px-5 font-medium"
          //   type={"number"}
          value={obj.qty}
          //   maxLength={maxLength}
          placeholder={"input..."}
          onChange={(text) => handleChange("input", text.target.value, index)}
        />
      </div>
      <div className="w-[20%] h-[7vh] flex justify-center items-center">
        <p>{selected?.uom || "-"}</p>
      </div>
    </div>
  );
};
