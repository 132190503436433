import React from "react";

export const ConfirmationModal = ({
  hideModal,
  onConfirm,
  label,
  label2 = "Later",
  label3 = "Yes",
  customStyles = null,
  type = "signOut",
}) => {
  let btnYesStyle = "";
  let btnNoStyle = "";
  let customStylesForYes = "";
  let mainDiv = "w-full";
  if (type === "signOut") {
    mainDiv = "w-full";
    btnYesStyle =
      "bg-[#F491A3] text-[14px] font-[450px] text-center text-white px-5 py-2 w-[140px] rounded-full ml-5";
    btnNoStyle =
      "bg-[#808080] text-[14px] font-[450px] text-center text-white px-5 py-2 w-[140px] rounded-full";
  }
  if (type === "weatherStation") {
    mainDiv = "w-[60%]";
    btnYesStyle =
      "border border-[#40AE49] text-[#40AE49] border-[2px] text-[14px] font-[450px] text-center px-5 py-2 w-[140px] rounded-full ml-5";
    btnNoStyle =
      "border border-[#F491A3] text-[#F491A3] border-[2px] text-[14px] font-[450px] text-center px-5 py-2 w-[140px] rounded-full";
  }
  if (type === "planting") {
    mainDiv = "w-[100%] px-5 bg-white";
    btnYesStyle =
      "border border-[#40AE49] text-[#40AE49] border-[2px] text-[14px] font-[450px] text-center px-5 py-2 w-[140px] rounded-full ml-5";
    btnNoStyle =
      "border border-[#F491A3] text-[#F491A3] border-[2px] text-[14px] font-[450px] text-center px-5 py-2 w-[140px] rounded-full";
  }

  return (
    <>
      <div className="flex overflow-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center">
        <div className="flex justify-center mt-[3%] mb-[3%]">
          {/*content*/}
          <div
            className={`h-full first-letter:rounded-lg shadow-lg relative flex flex-col ${mainDiv} outline-none focus:outline-none border-black border-[1px] rounded-lg`}
          >
            <div className="relative pl-6 pr-6 overflow-auto pt-3 bg-white rounded-t-lg h-full">
              <p className="text-[18px] text-center mb-5 mt-3">{label}</p>
            </div>
            {/*footer*/}
            <div className="flex justify-center items-center py-6 rounded-b-lg bg-white w-full">
              <div>
                <button
                  className={btnNoStyle}
                  type="button"
                  onClick={() => hideModal()}
                >
                  {label2}
                </button>
                <button
                  className={btnYesStyle}
                  type="button"
                  onClick={() => onConfirm()}
                >
                  {label3}
                </button>
              </div>
              <div />
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>{" "}
    </>
  );
};
