import React, { useEffect, useState } from "react";
import {_fetchMetaData } from "../api";
import { CropTracker } from "../config/tables/CropTracker";

  export const CropsTracker = () => {
  let user = JSON.parse(localStorage.getItem("user_data"));
  const [selectedFilter, setSelectedFilter] = useState({status_name:"All"});
  const [metaData, setMetaData] = useState();

  useEffect(() => {
    _fetchData()
  }, []);

  async function _fetchData() {
    let metaData = await _fetchMetaData();
    setMetaData(metaData);
  }

  return (
    <div className="h-[96%]">
      <div className="bg-[#D0D0D0] scrollbar-hide pl-2 py-2 flex flex-no-wrap overflow-x-scroll scrolling-touch items-start mb-8">
        {metaData?.cropsTrackerFiltersWeb.map((item) => {
          return (
            <div
              onClick={() => {
                setSelectedFilter(item)
              }}
              key={item?.status_name}
              className={`flex-none cursor-pointer items-center justify-between ${
                selectedFilter?.status_name === item?.status_name
                  ? "bg-[#40AE49] text-white"
                  : "bg-[#808080] text-[#D0D0D0]"
              } mr-3 py-2 ${item?.status_name === "PURCHASE ORDER" || item?.status_name === "Payment Requested" ? 'w-[190px]':'w-[130px]'} px-2 rounded-xl shadow-sm`}
            >
              <div style={{textAlign:'center'}}>
                <p className={`text-[16px] font-semibold`}>
                  {item?.status_name}
                </p>
              </div>
            </div>
          );
        })}
      </div>
      <CropTracker
        metaData={metaData}
        selectedFilter={selectedFilter}
        />
    </div>
  );
};
