import React from "react";
import closeImg from "../images/FarmPipeLine/close.png";
import { formatDate, getDateFormatMoment } from "../config/localdata";


export const RequestLogsModal = ({
  hideModal,
  logData,
}) => {

  return (
    <>
      <div className="flex overflow-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center">
        <div className="w-[50%] relative mt-[3%] mb-[3%]">
          {/*content*/}
          <div className="h-full first-letter:rounded-lg shadow-lg relative flex flex-col w-full outline-none focus:outline-none rounded-lg">
            <div className="flex items-center relative pl-6 pr-6 overflow-auto pt-3 bg-[#40AE49] rounded-t-lg h-full">
              <p className="text-[18px] w-full font-bold text-white text-center mb-4 mt-4">
                REQUEST LOG
              </p>
              <img
                className="float-right w-[30px] h-[30px]"
                src={closeImg}
                alt="closeImg"
                onClick={() => {
                  hideModal();
                }}
              />
            </div>
            {/*footer*/}
            <div className="rounded-b-lg bg-white" style={{'overflow':'auto', 'maxHeight':'500px'}}>
            <table style={{width:'100%', textAlign: 'center',backgroundColor: 'white'}}>
            <tr>
                <th style={{backgroundColor: '#40AE49',color: 'white',textAlign: 'center', padding:'10px'}}>Action</th>
                <th style={{backgroundColor: '#40AE49',color: 'white',textAlign: 'center', padding:'10px' }}>Event Description</th>
                <th style={{backgroundColor: '#40AE49',color: 'white',textAlign: 'center', padding:'10px' }}>Modified by</th>
                <th style={{backgroundColor: '#40AE49',color: 'white',textAlign: 'center', padding:'10px' }}>Created by</th>
                <th style={{backgroundColor: '#40AE49',color: 'white',textAlign: 'center', padding:'10px' }}>Timestamp</th>
	        </tr>
            {logData.map((data)=>{
                return(
                    <tr>
                        <td  style={{textAlign: 'center',padding:'10px', backgroundColor: 'white'}}>{data.action}</td>
                        <td  style={{textAlign: 'center',padding:'10px', backgroundColor: 'white'}}>{data?.current_state?.status ? data?.current_state?.status : '-'}</td>
                        <td  style={{textAlign: 'center',padding:'10px', backgroundColor: 'white'}}>{data?.action === "Update" && data?.updated_by?.user_name ? data?.updated_by?.user_name:'-'}</td>
                        <td  style={{textAlign: 'center',padding:'10px', backgroundColor: 'white'}}>{data?.action === "Create" && data?.updated_by?.user_name ? data?.updated_by?.user_name:'-' }</td>
                        <td  style={{textAlign: 'center',padding:'10px', backgroundColor: 'white'}}>{data?.timestamp ? getDateFormatMoment(+(data.timestamp+''), true) : '-'}</td>
                    </tr>
                )
            })}
        </table>      
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>{" "}
    </>
  );
};
