import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { PageLayoutComp } from "./PageLayoutComp";
import { IoIosArrowRoundBack } from "react-icons/io";
import { useDropzone } from "react-dropzone";
import {
  _asyncGetAllFarms,
  _asyncGetAllMasterData,
  _asyncGetEMateraiDocTypes,
  _asyncGetRecipientList,
  _asyncNewJob,
  _asynceSignDocUpload,
  _fetchMetaData,
} from "../api";
import SearchDropDownComp from "./SearchDropDownComp";
import Multiselect from "multiselect-react-dropdown";
import { toast } from "react-toastify";
import { isArray, isEmpty } from "lodash";
import { MdDragIndicator } from "react-icons/md";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "rgb(209 213 219)",
  borderStyle: "dashed",
  backgroundColor: "#ffffff",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};
const custom_placeholder = {};

const focusedStyle = {
  borderColor: "rgb(229, 233, 239)",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

export const AddNewJobOpening = () => {
  const location = useNavigate();
  const [allFarms, updateAllFarms] = useState([]);
  const [responsibilities, setResponsibilities] = useState('')
  const [qualifications, setQualifications] = useState('')
  const [description, setDescription] = useState('')
  const [selectedDepartment, setSelectedDepartment] = useState({})
  const [selectedPosition, setSelectedPosition] = useState("")
  const [experience, setExperience] = useState({})
  const [selectedLocation, setSelectedLocation] = useState({})
  const [selectedJobType, setSelectedJobType] = useState({})
  const [selectedJobLocationType, setSelectedJobLocationType] = useState({})
  const [MasterData, setMasterData] = useState({})

  let user = JSON.parse(localStorage.getItem("user_data"));

 

 
  //adding data to sent to the API

  async function getMasterData(){
    try {
      const response = await fetch(
        'https://api.beleafmanagement.io/rest/career/master_data',
      );
      const json = await response.json();
      setMasterData(json)
      return json?.data;
    } catch (error) {
    }

  }
  async function onSubmitClick() {
    if (!selectedDepartment?.id) {
      toast.error("Please Select Team", {autoClose: 2000});
      return;
    }
    if (!selectedPosition.trim()) {
      toast.error("Please Enter Position", {autoClose: 2000});
      return;
    }

    if (!experience?.id) {
      toast.error("Please Select Experiences", {autoClose: 2000});
      return;
    }
    if (!selectedLocation?.id) {
      toast.error("Please Select Placement", {autoClose: 2000});
      return;
    }
    if (!selectedJobType?.id) {
      toast.error("Please Select Job Type", {autoClose: 2000});
      return;
    }
    if (!selectedJobLocationType?.id) {
      toast.error("Please Select Type", {autoClose: 2000});
      return;
    }
    if (description == "") {
      toast.error("Please Enter Description", {autoClose: 2000});
      return;
    }
    if (responsibilities == "") {
      toast.error("Please Enter Key Responsibilities", {autoClose: 2000});
      return;
    }  
    if (qualifications == "") {
      toast.error("Please Enter Qualifications", {autoClose: 2000});
      return;
    }   
    let body = {
      department_id: selectedDepartment?.id,
      job_position: selectedPosition,
      experience_id: experience?.id,
      location_id: selectedLocation?.id,
      job_type_id: selectedJobType?.id,
      job_location_type_id: selectedJobLocationType?.id,
      job_key_responsibilities: responsibilities,
      job_qualifications: qualifications,
      job_desc: description,
    };
  let res = await _asyncNewJob(body)
   let response = await res
   if (response) {
    toast.info(response?.message)    
    location("/farm-pipeline/career")
   }
   
  }
  // /farm-pipeline/career
 

  async function _getGetAllFarms() {
    const data = await _asyncGetAllFarms();
    if (!data) return;
    let temp = [{ id: "", farm_name: "Select Farm" }];
    updateAllFarms([...temp, ...data]);
  }




  useEffect(() => {
    getMasterData()
    //_getGetAllFarms();

  }, []);

  const [myFiles, setMyFiles] = useState([]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      setMyFiles([...myFiles, ...acceptedFiles]);
    },
    [myFiles]
  );

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      onDrop,
      accept: {
        "application/pdf": [],
      },
    });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  function handleBackButton() {
    location("/farm-pipeline/career");
  }
  return (
    <div>
      <PageLayoutComp user={user}>
        <div className="flex justify-between">
          <p className="text-3xl font-bold mb-0">Career - Add Job Opening</p>
          <div className="flex">
            <div
              onClick={() => handleBackButton()}
              className="flex cursor-pointer"
            >
              <IoIosArrowRoundBack size={22} color={"green"} className="mt-2" />
              <p className="text-green text-md mt-2 cursor-pointer font-bold">
                Back to Dashboard
              </p>
            </div>
          </div>
        </div>
        <div className="bg-white w-full  mt-2 rounded-lg">
          <p className="p-4 font-poppins text-2xl">Job Detail</p>
          <div className="w-full py-5  rounded-lg mt-2 flex divide-x-2 divide-stone-300">
            <div className=" w-[50%]">
              <div className="px-4 mx-[5%]">
                <div>
                  <p className="mt-5 font-extralight">
                    Team<span className="text-red-500">*</span>
                  </p>
                </div>

                <div className="relative">
                  <SearchDropDownComp
                    width="w-full"
                    list={MasterData?.departments}
                    active={selectedDepartment}
                    label="Select Team"
                    type={"team_name"}
                      onSelected={(item) => {
                        setSelectedDepartment(item);
                      }}
                  />
                </div>
              </div>

              <div className="px-4 mx-[5%]">
                <div>
                  <p className="mt-5 font-extralight">
                    Position<span className="text-red-500">*</span>
                  </p>
                </div>

                <div className="relative">
                  {/* <SearchDropDownComp
                    width="w-full"
                    list={MasterData?.positions}
                    active={selectedPosition}
                    label="Select Position"
                    type={"team_name"}
                      onSelected={(item) => {
                        setSelectedPosition(item);
                      }}
                  /> */}
                  <input
                  className="relative flex h-[50px] w-full border border-gray-300 p-4 overflow-hidden rounded-lg bg-white focus:outline-none sm:text-sm"
                  value={selectedPosition}
                  onChange={(e)=>setSelectedPosition(e?.target?.value)}
                  placeholder="Enter Position"/>
                </div>
              </div>

              <div className="px-4 mx-[5%]">
                <div>
                  <p className="mt-5 font-extralight">
                    Experience<span className="text-red-500">*</span>
                  </p>
                </div>

                <div className="relative">
                  <SearchDropDownComp
                    width="w-full"
                    list={MasterData?.experiences}
                    active={experience}
                    label="Select experience"
                    type={"team_name"}
                      onSelected={(item) => {
                        setExperience(item);
                      }}
                  />
                </div>
              </div>

              <div className="px-4 mx-[5%]">
                <div>
                  <p className="mt-5 font-extralight">
                    Placement<span className="text-red-500">*</span>
                  </p>
                </div>

                <div className="relative">
                  <SearchDropDownComp
                    width="w-full"
                    list={MasterData?.jobLocations}
                    active={selectedLocation}
                    label="Select placement*"
                    type={"team_name"}
                      onSelected={(item) => {
                        setSelectedLocation(item);
                      }}
                  />
                </div>
              </div>

              <div className="px-4 mx-[5%]">
                <div>
                  <p className="mt-5 font-extralight">
                    Type<span className="text-red-500">*</span>
                  </p>
                </div>

                <div className="relative">
                  <SearchDropDownComp
                    width="w-full"
                    list={MasterData?.jobTypes}
                    active={selectedJobType}
                    label="Select type*"
                    type={"team_name"}
                      onSelected={(item) => {
                        setSelectedJobType(item);
                      }}
                  />
                </div>
              </div>

              <div className="px-4 mx-[5%]">
                <div>
                  <p className="mt-5 font-extralight">
                    Type<span className="text-red-500">*</span>
                  </p>
                </div>

                <div className="relative">
                  <SearchDropDownComp
                    width="w-full"
                    list={MasterData?.jobLocationTypes}
                    active={selectedJobLocationType}
                    label="Select type*"
                    type={"team_name"}
                      onSelected={(item) => {
                        setSelectedJobLocationType(item);
                      }}
                  />
                </div>
              </div>
            </div>

            <div className=" w-[48%]">
              <div className="px-4 mx-[5%]">
                <div>
                  <p className="mt-5 font-extralight">
                    Description<span className="text-red-500">*</span>
                  </p>
                </div>

                {/* <div className="relative"> */}
                <textarea
                value={description}
                  type="text"
                  id="first_name"
                  onChange={(e) => {
                     setDescription(e.target.value);
                  }}
                  class=" mt-[0.2rem] relative tp-5 bg-white pb-22 border border-gray-300 text-black placeholder-black text-sm rounded-lg h-[14.815vh] block w-full p-2.5 "
                  placeholder="type here.."
                  required
                />
                {/* </div> */}
              </div>

              <div className="px-4 mx-[5%]">
                <div>
                  <p className="mt-5 font-extralight">
                    Key Responsibilities<span className="text-red-500">*</span>
                  </p>
                </div>

                {/* <div className="relative"> */}
                <textarea
                  type="text"
                  id="first_name"
                  value={responsibilities}
                  onChange={(e) => {
                     setResponsibilities(e.target.value);
                  }}
                  class=" mt-[0.2rem] relative tp-5 bg-white border border-gray-300 text-black placeholder-black text-sm rounded-lg h-[14.815vh] block w-full p-2.5 pb-22"
                  placeholder="type here.."
                  required
                />
                {/* </div> */}
              </div>

              <div className="px-4 mx-[5%]">
                <div>
                  <p className="mt-5 font-extralight">
                    Qualifications<span className="text-red-500">*</span>
                  </p>
                </div>

                {/* <div className="relative"> */}
                <textarea
                value={qualifications}
                  type="text"
                  id="first_name"
                  onChange={(e) => {
                    setQualifications(e.target.value);
                  }}
                  class=" mt-[0.2rem] relative tp-5 bg-white pb-22 border border-gray-300 text-black placeholder-black text-sm rounded-lg h-[14.815vh] block w-full p-2.5 "
                  placeholder="type here.."
                  required
                />
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="bg-[#F9F9F9] mt-[-1%] flex p-6 rounded-b-lg justify-end">
          <button
            onClick={() => {onSubmitClick()
              }
            }
            className="bg-green  px-5 font-poppins text-white py-2 rounded-lg"
          >
            Next
          </button>
        </div>
      </PageLayoutComp>
    </div>
  );
};
