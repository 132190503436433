import React, { useState } from "react";
import { _apiLogout, _fetchMetaData } from "../../api";
import { DasboardTab } from "../../components/DasboardTab";
import { PageLayoutComp } from "../../components/PageLayoutComp";
import { useEffect } from "react";

export const PlantingDashboard = () => {
  let user = JSON.parse(localStorage.getItem("user_data"));
  const [metaData, setMetaData] = useState();

  useEffect(()=>{
    _fetchData();
  },[])

  async function _fetchData() {
    let metaData = await _fetchMetaData();
    setMetaData(metaData);
  }

  return (
    <div className="w-screen h-screen">
      <PageLayoutComp metaData={metaData} user={user}>
        <div className="h-[100%] bg-[#E6F1ED] p-4">
          <div className="h-[7%]">
            <div className="flex justify-between">
              <p className="text-3xl font-bold mb-0">
                Planting Dasboard
              </p>
            </div>
          </div>
          <div className="h-[100%]">
            <div className="h-[calc(100%-50px)] bg-white p-3">
                <DasboardTab
                  metaData={metaData}
                />
            </div>
          </div>
        </div>
      </PageLayoutComp>
    </div>
  );
};
