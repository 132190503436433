import React, { useEffect, useState } from "react";
import {_asyncGetWarehouses } from "../api";
import closeImg from "../images/FarmPipeLine/close.png";
import { formatDate, getDateFormatMoment } from "../config/localdata";
import { InputFieldComp } from "../components/InputFieldComp";

export const CancelReasonModal = ({
  hideModal,
  setCancelReason,
  cancelReason,
  cancelPoWithReason
}) => {

  return (
    <>
      <div className="flex overflow-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center">
        <div className="w-[45%] h-[30%] relative mt-[3%] mb-[3%]">
          {/*content*/}
          <div className="h-full first-letter:rounded-lg shadow-lg relative flex flex-col w-full outline-none focus:outline-none rounded-lg">
          <div className="relative p-10 overflow-auto pt-3 bg-white rounded-t-lg h-full">
              <img
                className="float-right w-[30px] h-[30px] mt-3"
                src={closeImg}
                alt="closeImg"
                onClick={() => {
                  hideModal();
                }}
              />
              <div className="mt-6">
                 <p className="text-[15px] font-bold text-black">Input cancelation reason</p>
                </div>
                <div className="mt-5">
                <input
                className="flex w-full h-[90px] py-4 border rounded-lg border-gray-500 items-center px-2 font-medium shadow-sm hover:bg-gray-50 focus:outline-0"
                type="text"
                value={cancelReason}
                placeholder="Planting Canceled / Bad Weather"
                onChange={(text) => {
                    setCancelReason(text.target.value);
                }}
                />
                </div>
                
            </div>
            {/*footer*/}
            <div className="py-2 rounded-b-lg bg-white w-full px-4">
            <div className="flex justify-start">
                <p className="text-[14px] mt-2 mr-6">Cancelation date</p>
                <p className="bg-[#808080] text-white px-2 py-1 border border-[##808080] rounded-sm cursor-pointer">
                  {getDateFormatMoment(new Date())}
                </p>
              </div>
              <div className="flex justify-end">
                <p
                  onClick={() => cancelPoWithReason()}
                  className="bg-[#F491A3] text-white px-8 py-1 border border-[#F491A3] rounded-lg cursor-pointer">
                  Cancel PO
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>{" "}
    </>
  );
};
