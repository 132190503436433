import React, { useEffect, useState } from "react";
import { HighchartsReact } from "highcharts-react-official";
import Highcharts from "highcharts";
import {
  _getDaysBtwTwoDates
} from "../config/localdata";
import { axiosClient, axiosHeader } from "../api";
import DropDownComp from "./DropDownComp";
import loaderImage from "../images/loader_icon.gif";
import moment from "moment";


export const LogDataComp = ({
  selectedStation,
  selectedStationData,
  showLogData,
}) => {
  const [hoursValues, setHoursValues] = useState([]);
  const [days, setDays] = useState([]);
  const [daysValues, setDaysValues] = useState([]);
  const [windSpeedValue, setWindSpeedValue] = useState([])
  const [humidityValue, setHumidityValue ] = useState([])

  const [showLoader, setShowLoader] = useState(false);

  const allTime = [
    { id: 1, name: "Last 15 Minutes", filter_type: 'minutes',time_amount:15},
    { id: 2, name: "Last 30 Minutes", filter_type: 'minutes',time_amount:30 },
    { id: 3, name: "Last 45 Minutes", filter_type: 'minutes',time_amount:45 },
    { id: 4, name: "Last 1 Hours", filter_type: 'hours',time_amount:1 },
    { id: 5, name: "Last 3 Hours", filter_type: 'hours',time_amount:3 },
    { id: 6, name: "Last 6 Hours", filter_type: 'hours',time_amount:6 },
    { id: 7, name: "Last 12 Hours", filter_type: 'hours',time_amount:12 },
    { id: 8, name: "Last 24 Hours", filter_type: 'hours',time_amount:24 },
    { id: 9, name: "Last 2 Days", filter_type: 'days',time_amount:2 },
    { id: 10, name: "Last 3 Days", filter_type: 'days',time_amount:3 },
    { id: 11, name: "Last 4 Days", filter_type: 'days',time_amount:4 },
    { id: 12, name: "Last 5 Days", filter_type: 'days',time_amount:5 },
    { id: 13, name: "Last 6 Days", filter_type: 'days',time_amount:6 },
    { id: 14, name: "Last 7 Days", filter_type: 'days',time_amount:7 },
  ];
  const [filterValue, setFilterValue] = useState({
    // id: 1, 
    // name: "Last 15 Minutes", 
    // filter_type: 'minutes',
    // time_amount:15
    id: 14, 
    name: "Last 7 Days", 
    filter_type: 'days',
    time_amount:7
  });


  useEffect(() => {
    _getWeatherLogsData();
  }, [selectedStationData, filterValue]);

  async function _getWeatherLogsData() {
    try {
      setShowLoader(true)
      let res = await axiosClient.get(
        `admin/weather_info/${selectedStation.id}?filter_type=${filterValue.filter_type}&time_amount=${filterValue.time_amount}`,
        axiosHeader()
      );
      // console.log('-------id',selectedStation.id);
      // console.log('-------filtter',filterValue.filter_type);
      // console.log('-------time',filterValue.time_amount);
      if (res.status === 200) {
        setShowLoader(false);
        if (res?.data) {
          let dayList = [];
          let dayValueList = [];
          let dayValueList2 = [];
          let windSpeedValueList = [];
          let humidityValueList = [];
          res?.data?.map((item) =>{
            dayList.push(filterValue?.id > 9 ? moment(item?.currDate_updated).format('DD/MM/YYYY'):moment(item?.currDate_updated).format('HH:mm'))
            dayValueList.push({
              y: Number(item?.external_temperature),
              marker: {
                symbol:`url(${item?.condition?.icon})`,
                width: 35,
                height: 35
              },
              cursor: "pointer",
              point: {
                events: {
                  click: (e) => {
                    //alert("test");
                  },
                },
              },
            });
            console.log('temp000',item);
            dayValueList2.push({
              y: Number(item?.precip_mm),
              marker: {
                symbol:`url(${item?.condition?.icon})`,
                width: 35,
                height: 35
              },
              cursor: "pointer",
              point: {
                events: {
                  click: (e) => {
                    //alert("test");
                  },
                },
              },
            });
            windSpeedValueList.push({
              y: Number(item?.wind_kph),
              marker: {
                symbol:`url(${item?.condition?.icon})`,
                width: 35,
                height: 35
              },
              cursor: "pointer",
              point: {
                events: {
                  click: (e) => {
                    //alert("test");
                  },
                },
              },
            });
            humidityValueList.push({
              y: Number(item?.humidity),
              marker: {
                symbol:`url(${item?.condition?.icon})`,
                width: 35,
                height: 35
              },
              cursor: "pointer",
              point: {
                events: {
                  click: (e) => {
                    //alert("test");
                  },
                },
              },
            });

          });
          setDays(dayList);
          setDaysValues(dayValueList);
          setHoursValues(dayValueList2)
          setWindSpeedValue(windSpeedValueList)
          setHumidityValue(humidityValueList)
          setShowLoader(false)
        }
      }
    } catch (error) {
      setShowLoader(false)
      console.log("eeror=>", error);
    }
  }
  const PieChartComp = (data) => {
    // console.log('-------00000-----',data);
    const chartOptions = {
      credits: {
        enabled: false,
      },
      chart: {
        type: "spline",
      },
      title: {
        text: data?.data?.title,
      },
      legend: {
        enabled: false,
      },
      xAxis: {
        title: {
          text: data?.data?.xAxisLabel,
        },
        categories: data?.data?.xAxisData,
      },
      yAxis: {
        title: {
          text: data?.data?.ytitle,
        },
      },

      series: [
        {
          name: data?.data?.lagendName,
          cursor: "pointer",
          data: [...data?.data?.yAxisData],
          color: "#40AE49",
          point: {
            events: {
              click: function () {
                //alert('Category: ' + this.category + ', value: ' + this.y);
                //_getHours(this.category);
              },
            },
          },
        },
      ],
    };

    return (
      <div className="w-full">
        <HighchartsReact
          containerProps={{ className: "h-[350px] w-[100%]" }}
          highcharts={Highcharts}
          options={chartOptions}
        />
      </div>
    );
  };

  if (showLoader)
    return (
      <div className="flex h-[100%] justify-center items-center">
        <img src={loaderImage} />
      </div>
    );

  return (
    <div className="mt-3 h-[88%] overflow-auto scrollbar-hide ">
      <div className="flex float-right justify-center items-center">
        {/* <div className=" flex justify-center items-center">
          <span className="mr-3 text-[16px] font-medium">Rain Only</span>
          <label className="relative inline-flex items-center cursor-pointer">
            <input type="checkbox" value="" className="sr-only peer" />
            <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
          </label>
        </div> */}
        {/* <div className="ml-5 w-[260px]">
          <DatePickerRangeComp
            pastOnly={true}
            dateRange={slectedPlantingDate}
            onSelected={(item) => {
              if (item[0] !== null && item[1] !== null) {
                let totalDays = _getDaysBtwTwoDates(
                  new Date(item[0]),
                  new Date(item[1])
                );
                if (totalDays > 29) {
                  toast.error("You can only select 30 days");
                  return;
                }
                _getWeatherLogsData(
                  formatDateBackSlash(item[0]),
                  formatDateBackSlash(item[1])
                );
              } else {
                let dt = new Date();
                dt.setDate(dt.getDate() - 6);
                let dateEnd = formatDateBackSlash(new Date());
                let dateStart = formatDateBackSlash(dt);
                _getWeatherLogsData(dateStart, dateEnd);
              }
              setSlectedPlantingDate(item);
            }}
          />
        </div> */}
        <div className="flex justify-end w-[350px]">
              <DropDownComp
                custom={true}
                listData={allTime}
                active={filterValue}
                type={"name"}
                onSelected={(item) => {
                  setFilterValue(item);
                }}
                customStyles={
                  "rounded-md justify-between text-[#3E4347] text-[14px]"
                }
                customMenuStyle={"relative inline-block text-left w-1/2"}
              />
          </div>
      </div>
      <div className="mt-10">
        <p className="font-semibold mt-3">Weather Log</p>
        <div className="flex items-center mt-3 rounded-2xl border border-gray-600 h-[400px] shadow-sm p-3">
          <PieChartComp
            data={{
              xAxisLabel: "days/hours",
              xAxisData: days,
              yAxisData: daysValues,
              title: "Weather - Days",
              ytitle:"Temperature",
              lagendName: "Temperature",
            }}
          />
        </div>
      </div>
      <div className="mt-5">
        <p className="font-semibold mt-3">Rainfall</p>
        <div className="flex items-center mt-3 rounded-2xl border border-gray-600 h-[400px] shadow-sm p-3">
          <PieChartComp
            data={{
              xAxisLabel: "days/hours",
              xAxisData: days,
              yAxisData: hoursValues,
              title: "Precipitation - Time",
              ytitle:"Precipitation",
              lagendName: "Rainfall",
            }}
          />
        </div>
      </div>
      <div className="mt-5">
        <p className="font-semibold mt-3">Wind Speed</p>
        <div className="flex items-center mt-3 rounded-2xl border border-gray-600 h-[400px] shadow-sm p-3">
          <PieChartComp
            data={{
              xAxisLabel: "days/hours",
              xAxisData: days,
              yAxisData: windSpeedValue,
              title: "Wind Speed",
              ytitle:"KM-Time",
              lagendName: "Wind Speed",
            }}
          />
        </div>
      </div>
      <div className="mt-5">
        <p className="font-semibold mt-3">Humidity</p>
        <div className="flex items-center mt-3 rounded-2xl border border-gray-600 h-[400px] shadow-sm p-3">
          <PieChartComp
            data={{
              xAxisLabel: "days/hours",
              xAxisData: days,
              yAxisData: humidityValue,
              title: "Humidity",
              ytitle:"RH-Time",
              lagendName: "Humidity",
            }}
          />
        </div>
      </div>
      <div className="p-2 mt-3">
        <div className="flex items-center justify-center bg-white rounded-2xl shadow-md py-3">
          <p>Want to see weather Real-Time ?</p>
          <p
            onClick={() => showLogData()}
            className="bg-[#40AE49] text-white px-5 py-1 rounded-full ml-5 text-sm cursor-pointer"
          >
            Real-Time
          </p>
        </div>
      </div>
    </div>
  );
};
