import { GoogleMap, MarkerF, useJsApiLoader } from "@react-google-maps/api";

const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
const libraries = ["places"];
const containerStyle = {
  width: "100%",
  height: "300px",
};
export const GoogleMapComp = ({ location, handleMapClick }) => {
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: 'AIzaSyAsLV_1qA4lSKrRLYFw27jEUBWrD2OXr18',
    libraries,
  });

  const renderMap = () => {
    return (
      <GoogleMap
        zoom={10}
        center={location}
        onClick={handleMapClick}
        mapContainerStyle={containerStyle}
        options={{
          streetViewControl: false,
          mapTypeControl: false,
          fullscreenControl: false,
        }}
      >
        {<MarkerF position={location} zIndex={4} />}
      </GoogleMap>
    );
  };

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>;
  }

  return isLoaded ? renderMap() : <p>loading google maps...</p>;
};
