import React, { useEffect, useState } from "react";
import { _asyncConsumablesRequest, _asyncGetPaymentProofDownload, _asyncGetWarehouses } from "../api";
import closeImg from "../images/FarmPipeLine/close.png";
import { getDateFormatMoment, _convertCommaSaparate } from "../config/localdata";
import { ConfirmationModal } from "./ConfirmationModal";
import { SuccessfullMesg } from "./SuccessfullMesg";
import { toast } from "react-toastify";
import DropDownComp from "../components/DropDownComp";

export const GenerateRequestModal = ({
  hideModal,
  metaData,
  generatePoBody
}) => {

const [showConfirmModal, setShowConfirmModal] = useState(false);
const [showSuccessModal, setShowSuccessModal] = useState(false);
const [showSuccessDocModal, setShowSuccessDocModal] = useState(false);
const [showDocModal, setShowDocModal] = useState(false);
const [imageData, setImageData] = useState();


const [allWarehouse, updateAllWarehouse] = useState([]);
const [warehouse, setWarehouse] = useState({"warehouse_name":"Select.."});

function _getDeliveryStatus(id) {
  let data = "";
  data = metaData?.consumablesDeliveryTypes?.filter((e) => e.id === id);
  return data && data[0] && data[0]?.delivery_type;
}


async function _getGetAllWarehouse() {
  const data = await _asyncGetWarehouses();
  if (!data) return;
    updateAllWarehouse([...data]);
}

useEffect(() => {
  _getGetAllWarehouse();
},[]);

 const oodoRequest = () =>{
  setShowConfirmModal(true);
}

const handlePaymentView = async () =>{
  
  if(generatePoBody?.doc_mime_type !== null){
    let response = await _asyncGetPaymentProofDownload(generatePoBody?.id);
    if(!response){
      setShowSuccessDocModal(true)
    }else{
      setShowDocModal(true)
      setImageData(response?.data)
      console.log("+++++++++++++++++++++++", response)
    }
  }else{
    setShowSuccessDocModal(true)
  }
}

 const _requestSubmit = async () => {
    let body = {
      request_id:generatePoBody?.id,
      odoo_warehouse_id:warehouse?.id,
      odoo_warehouse_name:warehouse?.warehouse_name
    }
    const apiResponse = await _asyncConsumablesRequest(body);
    if (apiResponse === 1) {
      setShowSuccessModal(true)
      setShowConfirmModal(false)
      setTimeout(() => {
        window.location.reload();
      }, 600);
      return;
    }
    toast.error(apiResponse);
    return;
  };

  console.log("000)))))))________", generatePoBody?.doc_mime_type)


  return (
    <>
      <div className="flex overflow-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center">
      {/* {showDocModal ? (<ShowImageModal hideModal={() => setShowDocModal(false)} imageData={imageData} />):null} */}
      {showSuccessModal ? (<SuccessfullMesg label={`Consumables Inventory Submitted`} />) : null}
      {showSuccessDocModal ? (<SuccessfullMesg tick={false} type="weatherStation" onSubmit={()=>setShowSuccessDocModal(false)} label={`Mitra not yet upload the payment evidence`} />) : null}
        {showConfirmModal ? (
          <ConfirmationModal
            label={`Do you want to Submit this data to Odoo ?`}
            label2="No"
            type="planting"
            customStyles={true}
            hideModal={() => setShowConfirmModal(false)}
            onConfirm={() => {
              setShowConfirmModal(false);
              _requestSubmit();
            }}
          />
        ) : null}
        <div className="w-[40%] h-[60%] relative mt-[3%] mb-[3%]">
          {/*content*/}
          {showDocModal ?

<div className="h-full first-letter:rounded-lg shadow-lg relative flex flex-col w-full outline-none focus:outline-none rounded-lg">
<div className="relative p-10 overflow-auto pt-3 bg-white rounded-t-lg h-full">
    <img
      className="float-right w-[30px] h-[30px] mt-3"
      src={closeImg}
      alt="closeImg"
      onClick={() => {
        hideModal();
      }}
    />
      {/* <img className="mt-2" src={`data:${generatePoBody?.doc_mime_type};base64,${imageData}
      `}/> */}
      <embed className="mt-2" width="400" height="400" src={`data:${generatePoBody?.doc_mime_type};base64,${imageData}`} />
    </div>
    </div>
           :
        <div className="h-full first-letter:rounded-lg shadow-lg relative flex flex-col w-full outline-none focus:outline-none rounded-lg">
          <div className="relative p-10 overflow-auto pt-3 bg-white rounded-t-lg h-full">
              <img
                className="float-right w-[30px] h-[30px] mt-3"
                src={closeImg}
                alt="closeImg"
                onClick={() => {
                  hideModal();
                }}
              />
              <div className="mt-6">
                 <div className="w-full mt-2">
                    <p className={`text-[16px] p-1 font-bold text-[#40AE49]`}>
                      {generatePoBody?.request_number}
                    </p>
                    <p className={`text-[16px] p-1 font-bold text-[#000] w-full`}>{generatePoBody?.rab_info?.rab_name}</p>
                    <p className={`text-[12px] p-1 text-[#000] w-full`}>{generatePoBody?.rab_info?.product?.product_name}</p>
                    <p className={`text-[12px] p-1 text-[#000] w-full`}>{generatePoBody?.farm?.farm_name}</p>

                    <p className={`text-[12px] p-1 text-[#000] w-full`}>Time Requested : {getDateFormatMoment(generatePoBody?.created_at)}</p>

                    <p className={`text-[12px] p-1 text-[#000] w-full`}>Delivery Method : {_getDeliveryStatus(generatePoBody?.delivery_type_id)}</p>

                    <p className={`text-[12px] p-1 text-[#000] w-full`}>Expected Pickup/Delivery Date : {getDateFormatMoment(generatePoBody?.expected_date)}</p>
                    </div>
                </div>
              <div class="relative overflow-x-auto shadow-sm sm:rounded-lg border-collapse border border-slate-400 w-full mt-5">
                  <table class="w-full text-sm text-left rtl:text-right text-gray-500">
                      <thead class="text-xs text-gray-700 bg-gray-50">
                          <tr>
                              <th scope="col" class="px-3 py-3 text-center">
                                  Item
                              </th>
                              <th scope="col" class="px-3 py-3 text-center">
                                Amount
                              </th>
                          </tr>
                      </thead>
                      <tbody>
                      {generatePoBody?.consumables?.map((item, index)=>{
                      return(
                          <tr class="odd:bg-white even:bg-gray-50 border-b">
                              <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                {item?.sku?.name}
                              </th>
                              <td class="px-6 py-4">
                                {item?.req_quantity}
                              </td>
                          </tr>
                          )
                        })}
                      </tbody>
                  </table>
              </div>
              <div className="mt-10 grid grid-cols-6">
              <div>
              <p className="font-bold">Source :</p>
              </div>
              <div>
              <DropDownComp
                listData={allWarehouse}
                active={warehouse}
                type={"warehouse_name"}
                onSelected={(item) => setWarehouse(item)}
                customStyles={"rounded-lg justify-between text-black text-[10px]"}
                customMenuStyle={"relative inline-block text-left w-[250px] px-5"}/>
              </div>
              </div>
            </div>
            <div className="py-4 rounded-b-lg bg-white w-full px-10">
              <div className="flex justify-end">
              <p className="text-[14px] font-bold text-[#40AE49] mr-20 cursor-pointer" onClick={()=>handlePaymentView()}>Preview Payment Evidence</p>
                <p
                  onClick={() => oodoRequest()}
                  className="bg-[#808080] text-white px-6 py-1 border border-[#808080] rounded-lg cursor-pointer">
                  Submit to Odoo
                </p>
              </div>
            </div>
          </div>}
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>{" "}
    </>
  );
};
