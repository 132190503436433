import React, { useEffect, useState } from "react";
import { TableSearchComp } from "../../../components/TableSearchComp";
import { TablePaginationComp } from "../../../components/TablePaginationComp";
import { getDateFormatMoment } from "../../localdata";
import { _asyncCancelPO, _asyncConsumablesLogs, _asyncGetAllConsumablesRequests } from "../../../api";
import { useLocation } from "react-router-dom";
import { DashboardFilterModal } from "../../../Models/DashboardFilterModal";
import { IoIosArrowForward } from 'react-icons/io';
import { GenerateRequestModal } from "../../../Models/generateRequest";
import { RequestLogsModal } from "../../../Models/RequestLogs";




export const RequestLists = ({ metaData,selectedFilter}) => {
  const size = 10;
  const configFetchRef = React.useRef(false);
  const [totalPage, setTotalPage] = useState();
  const [totalItems, setTotalItems] = useState();
  const [selectedPage, setSelectedPage] = useState(0);
  const [tableDataList, setTableDataList] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState();
  const [selectedFarm, setSelectedFarm] = useState();
  const [selectedMitra, setSelectedMitra] = useState();
  const [filterData, setFilterData] = useState({})
  const location = useLocation();
  const [searchText, setSearchText] = useState("")
  const [logData, setLogData] = useState();
  const [showLogData, setShowLogData] = useState(false);
  const [showMore, setShowMore] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [searchType, setSearchType] = useState('');
  const [action, setAction] = useState("");

  const [showGeneratePo, setShowGeneratePo] = useState(false);
  const [generatePoBody, setGeneratePoBody] = useState(null);

  const sortType = [
    { id: "1", name: "Latest" },
    { id: "2", name: "Earliest" },
  ];

  const colorObject = {
    1:'border-[#F491A3] text-[#F491A3]',
    2:'border-[#252525] text-[#252525]',
    3:'border-[#252525] text-[#252525]',
    4:'border-[#252525] text-[#252525]',
    5:'border-[#252525] text-[#252525]',
    6:'border-[#252525] text-[#252525]',
    7:'border-[#252525] text-[#252525]',
    8:'border-[#252525] text-[#252525]',
    9:'border-[#252525] text-[#252525]',
    10:'border-[#252525] text-[#252525]',
    11:'border-[#252525] text-[#252525]',
    12:'border-[#40AE49] text-[#40AE49]',
  }

  const [sortValue, setSortValue] = useState({id: "3",name: "Sort By"});

  useEffect(() => {
    if (configFetchRef.current) return;
    configFetchRef.current = true;
    _fetchProducts(selectedPage, null, filterData);
  }, []);


  useEffect(() => {
    let sort_order = sortValue?.name === "Earliest" ? "ASC":"DESC"
    _fetchProducts(selectedPage, sort_order, filterData);
  }, [selectedFilter, sortValue]);



function _getPlantingStatus(id) {
  let data = "";
  data = metaData?.allowedPOStatus?.filter((e) => e.id == id);
  return data && data[0] && data[0]?.status_name;
}


const handlePoDetails = (item) =>{
    if(item){
      setGeneratePoBody(item)
      setShowGeneratePo(true)
    }
}

const handleLogdata = async (item) =>{
  let res = await _asyncConsumablesLogs(item?.id)
  if(res.length > 0){
    setLogData(res)
    setShowLogData(true)
  }
}


  async function _fetchProducts(page, sort_order, item) {
    setShowFilter(false);
    let params = {
      size:size,
      page:page+1,
      status_ids:selectedFilter?.id !== '' ? [selectedFilter?.id]:null,
      ...item
    }
    
    if(searchType?.search_by_key && searchText){
      params[searchType?.search_by_key] = searchText;
    }
    const res = await _asyncGetAllConsumablesRequests(params,location, sort_order);
    setTableDataList(res?.displayData)
    setTotalPage(res?.totalPage)
    setTotalItems(res?.totalItems)
  }
  
  useEffect(()=>{
    _fetchProducts(selectedPage, null, filterData)
  },[action]);

  const handleSearchSubmit = (action) =>{
    setAction(action)
    setSelectedPage(0)
    if(action === "reset"){
       setSearchText("");
       setSearchType("");
      _fetchProducts(0, null, filterData)
    }
    if(searchText.length > 2 && action !== "reset"){
      _fetchProducts(0, null,filterData)
    }
  }

  const addFilters = () =>{
    let product_ids = []
    let farm_ids = []
    let mitra_ids = []

    if(selectedProduct !== undefined){
        product_ids.push(selectedProduct.id)
    }
    if(selectedFarm !== undefined){
        farm_ids.push(selectedFarm.id)
    }
    if(selectedMitra !== undefined){
        mitra_ids.push(selectedMitra.id)
    }
    setSelectedPage(0)
    setFilterData({product_ids, farm_ids, mitra_ids})
    _fetchProducts(0, null, {product_ids, farm_ids, mitra_ids})
  }

  const resetFilter = () =>{
    setShowFilter(false);
    _fetchProducts(selectedPage, null, {})
    setFilterData({})
    setSelectedProduct()
    setSelectedFarm()
    setSelectedMitra()
  }

  function _getStatus(id) {
    let data = "";
    data = metaData?.consumablesRequestStatuses?.filter((e) => e.id === id);
    return data && data[0] && data[0]?.status;
  }

  function _getDeliveryStatus(id) {
    let data = "";
    data = metaData?.consumablesDeliveryTypes?.filter((e) => e.id === id);
    return data && data[0] && data[0]?.delivery_type;
  }
  // console.log('');

  return (
    <div className="h-[90%]">
      {showFilter ? (
        <DashboardFilterModal
          hideModal={() => setShowFilter(false)}
          resetFilter={resetFilter}
          addFilters={addFilters}
          selectedProduct={selectedProduct}
          selectedFarm={selectedFarm}
          selectedMitra={selectedMitra}
          setSelectedProduct={setSelectedProduct}
          setSelectedFarm={setSelectedFarm}
          setSelectedMitra={setSelectedMitra}
        />
      ) : null}

    {showGeneratePo ? (
        <GenerateRequestModal
          hideModal={() => {setShowGeneratePo(false)}}
          metaData={metaData}
          generatePoBody={generatePoBody}
        />
      ) : null}

      {showLogData ? (
        <RequestLogsModal
          hideModal={() => { setShowLogData(false) }}
          logData={logData}
        />
      ) : null}

      <div className="h-[12%]">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className="w-[100%]">
            <TableSearchComp
              onChange={(text) => setSearchText(text)}
              openFilter={() => setShowFilter(true)}
              sortBy={true}
              sortType={sortType}
              sortValue={sortValue}
              handleSortBy={(item) => setSortValue(item)}
              searchText={searchText}
              handleSearchSubmit={handleSearchSubmit}
              searchTypeValues={metaData?.searchableFeaturesWithOptions?.consumable_req_list}
              searchType={searchType}
              onSearchChangeType={(item) => setSearchType(item)} 
            />
          </div>
        </div>
      </div>
      <div className="h-[83%] overflow-auto scrollbar-hide mt-5">
        {tableDataList?.map((data, i) => {
        return (
          <div onClick={()=>setSelectedIndex(i)}  id="myDiv" className={`box-border h-[${(showMore && selectedIndex ==i) ? 'auto' : '130px'}] w-full mt-2 p-2 border-2 border-slate-200 rounded-xl`}>
                  <div className="flex flex-row w-full">
                    <div className="basis-1/3 ml-5 mt-0 ">
                    <p className="text-[14px] font-bold">
                      {data?.request_number}
                    </p>
                    {data?.consumables?.slice(0,(showMore && selectedIndex ==i) ? data?.consumables?.length:5)?.map((item)=>{
                      return(
                        <p className="text-[10px] font-bold">
                          {item?.sku?.name} : {item?.req_quantity}
                        </p>
                      )
                    })}
                    {/* {
                      data?.consumables?.slice(3,data?.length)?.length>0 &&
                    <div onClick={()=>{}} className="w-30 h-5 bg-red-300 ml-3 ">
                      <p className="text-[14px] font-bold">{`${data?.consumables?.slice(3,data?.length)?.length} More`}</p>
                    </div>
                    } */}
                  </div>
                  <div class="w-0.5 h-12 bg-gray-300"></div>
                  <div className="basis-1/6 ml-5 mt-0">
                    <p className="text-[12px] font-bold">
                      {data?.farm?.farm_name}
                    </p>
                    <p className="text-[10px] font-bold">
                      {data?.farm?.odoo_vendor_name}
                    </p>
                  </div>
                  <div class="w-0.5 h-12 bg-gray-300 ml-3"></div>
                  <div className="basis-1/4 ml-5 mt-0">
                    <p className="text-[12px] font-bold">
                      {data?.rab_info?.rab_name}
                    </p>
                    <p className="text-[10px] font-bold">
                      {data?.rab_info?.product?.product_name}
                    </p>
                    
                  </div>
                  <div class="w-0.5 h-10 bg-gray-300 ml-3"></div>
                  <div className="basis-1/1 ml-5 mt-0">
                  <div class="w-1/1 flex justify-end">
                        <button  type="submit" class={`text-[12px] w-[150px] font-[600]  text-center bg-[#fff] border ${colorObject[data?.current_state_id]}`}>
                        {_getStatus(data?.current_state_id)}
                        </button>
                        <button type="submit" onClick={()=>{handleLogdata(data)}} class="justify-center w-[50px] text-[12px] p-1 ml-3  items-center rounded-sm cursor-pointer h-[25px] border bg-[#E7F2EE] border-gray-400 ">
                            Log
                        </button>
                    </div>
                    <p className="text-[10px] font-bold mt-1">
                    Delivery Method : {_getDeliveryStatus(data?.delivery_type_id)}
                    </p>
                    {data?.delivery_warehouse_name && <p className="text-[10px] font-bold">
                    Source : {data?.delivery_warehouse_name}
                    </p>}
                    <p className="text-[10px] font-bold">
                    {data?.mr_number}
                    </p>
                    <p className="text-[10px] text-[#808080] italic p-1">
                    Expected delivery at {getDateFormatMoment(data?.expected_date)} 
                    </p>
                  {data?.current_state_id === 1 && <div
                    onClick={()=>{handlePoDetails(data)}} 
                    className=" text-sm bg-[#3EB049] w-[210px] text-white p-1 text-center rounded-md border border-[#3EB049] cursor-pointer"
                    >
                    Request
                  </div>}
                  </div>
                  </div> 
                  {
                    data?.consumables?.slice(5,data?.length)?.length>0 &&
                    <div onClick={()=>{setShowMore(!showMore)}} className="max-w-[250px] h-5 ml-3 cursor-pointer">
                      <p className="text-[13px] font-bold  text-gray-600">{`${(showMore && selectedIndex ==i) ? 'hide' : `${data?.consumables?.slice(5,data?.length)?.length} view more` } consumables`}</p>
                    </div>
                  }
            </div>
           );
          })}
        </div>
      <div className="flex justify-end items-center h-[10%] mt-5 text-sm px-5">
        {totalPage !== undefined ? (
          <TablePaginationComp
            totalPages={totalPage}
            active={selectedPage}
            selectedPage={(p) => {
              setSelectedPage(p);
              _fetchProducts(p, null, filterData);
            }}
            totalItems={totalItems}
          />
        ) : null}
      </div>
    </div>
  );
};
