import React, { useEffect, useState } from "react";
import DropDownComp from "../components/DropDownComp";
import { _asyncGetAllContact, _asyncGetAllFarmInfo, _asyncGetAllProductList, _asyncGetPCPSIC, _asyncGetWarehouses } from "../api";
import closeImg from "../images/FarmPipeLine/close.png";
import { DatePickerComp } from "../components/DatePickerComp";
import { _convertCommaSaparate, getDateFormatMoment } from "../config/localdata";
import SearchDropDownComp from "../components/SearchDropDownComp";

export const TransferStatusModal = ({
  hideModal,
  data,
  metaData
}) => {
  
  const [farmInfo, setfarmInfo] = useState()
  const [sourceName, setsourceName] = useState('')
  const [destName, setdestName] = useState('')
  const [transferStatusList, settransferStatusList] = useState([])
  function getStatusById(id) {
    for (let i = 0; i < metaData?.allowedInternalTransferStatus.length; i++) {
        if (metaData?.allowedInternalTransferStatus[i].id == id) {
            return metaData?.allowedInternalTransferStatus[i]?.status_name;
        }
    }
    // If ID is not found, return null or handle it as per your requirement
    return null;
  }

  function getFarmById(id) {
    for (let i = 0; i < metaData?.allowedInternalTransferStatus.length; i++) {
        if (metaData?.allowedInternalTransferStatus[i].id == id) {
            return metaData?.allowedInternalTransferStatus[i]?.status_name;
        }
    }
    // If ID is not found, return null or handle it as per your requirement
    return null;
  }

  async function _getAllFarmInfo(farm_id) {
    let location_ids = JSON.parse(await localStorage.getItem('location_ids'));
    console.log("location_ids--------------->>",location_ids)
    console.log("Datasss00-->>",data)
    settransferStatusList(data?.harvests)
    let src_location = location_ids.filter((item,index)=>{return data?.src_location_id ==item?.o_id})
    setsourceName(src_location[0].display_name)
    let dest_location = location_ids.filter((item,index)=>{return data?.dest_location_id ==item?.o_id})
    setdestName(dest_location[0].display_name)
  
  }
  useEffect(() => {
    _getAllFarmInfo()
  }, []);
  
  return (
    <>
      <div className="flex overflow-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center">
        <div className="w-[70%] h-[70%] relative mt-[3%] mb-[3%]">
          {/*content*/}
          <div className="h-full first-letter:rounded-lg shadow-lg relative flex flex-col w-full outline-none focus:outline-none rounded-lg">
            <div className="relative p-10 overflow-auto pt-3 bg-white rounded-t-lg h-full">
              <img
                className="float-right w-[30px] h-[30px] mt-3"
                src={closeImg}
                alt="closeImg"
                onClick={() => {
                  hideModal();
                }}
              />
              <div className="basis-1/2 ml-1 mt-2">
                  <p className="text-[18px] font-[600]">
                    {data?.odoo_request_name}
                    
                  </p>
                  <div className="w-[10%]  mt-3">
                    <p className="text-[10px] p-0 font-[600] text-white text-center bg-[#3EB049]">
                      {/* {_getHarvestClasses(data?.harvest_class_id)} */}
                      {getStatusById(data?.request_state)}
                    </p>
                  </div>
                  <p className="text-gray text-[10px] font-[600]  mt-3">
                  Schedule Date {getDateFormatMoment(data?.schedule_date)}
                   
                  </p>
               
                </div>
              <div className="">
                <p className="text-black text-[12px] mt-2 mr-3 font-bold">
                 {data?.odoo_vendor_name}
                </p>
              </div>
              <div className="">
                <p className="text-black text-[12px] mt-2 mr-3 font-bold">
                 {data?.operation_type_name}
                </p>
              </div>
              <div className="mt-5 grid grid-rows-6"></div>
              {/* <div className="mt-4 grid grid-cols-6">
                <div>
                  <p className="font-bold">Contact</p>
                </div>
                <div>
                  <DropDownComp
                    listData={contactList}
                    active={contacts}
                    type={"contact_name"}
                    onSelected={(item) => setContacts(item)}
                    customStyles={
                      "rounded-lg justify-between text-black text-[10px] h-[7vh] ml-5"
                    }
                    customMenuStyle={
                      "relative inline-block text-left w-[200px] px-3"
                    }
                  />
                </div>
              </div>
              <div className="mt-3 grid grid-cols-5">
                <div>
                  <p className="font-bold">Schedule Date</p>
                </div>
                <div>
                  <DatePickerComp
                    onSelected={(item) => {
                      setArrivalDate(item);
                    }}
                    value={arrivalDate}
                    allowPastDate={true}
                  />
                </div>
              </div> */}

              <div className=" rounded-lg border border-gray-500  mt-4">
                <div className="flex w-full bg-[#D7EFD9] h-[7vh] rounded-lg border">
                  <div className="w-[40%] h-[7vh] flex justify-center items-center font-bold">
                    <p>PC/PSIC</p>
                  </div>
                  <div className="w-[20%] h-[7vh] flex justify-center items-center font-bold">
                    <p>Lot/Serial Number</p>
                  </div>
                  <div className="w-[20%] h-[7vh] flex justify-center items-center font-bold">
                    <p>Quantity</p>
                  </div>
                  <div className="w-[20%] h-[7vh] flex justify-center items-center font-bold">
                    <p>UoM</p>
                  </div>
                </div>
              {transferStatusList?.map((obj,i) => {
                  return (
                    <ProductCard key={obj?.id} index={i} obj={obj} />
                  );
                })}

                {/* {productListArray?.map((obj,i) => {
                  return (
                    <ProductCard handleChange={handleChange} listData={productsList} key={obj?.id} index={i} obj={obj} />
                  );
                })} */}

                {/* <div className="flex justify-center mt-[15px] mb-[15px]">
                  <p
                    onClick={() => {
                      let arr = [...productListArray];
                      arr.push({
                        product:{},
                        qty:null
                      });
                      setProductListArray(arr);
                    }}
                    className="bg-[#3EB049] text-white px-4 border border-[#3EB049] rounded-full cursor-pointer text-[37px]"
                  >
                    +
                  </p>
                </div> */}
              </div>

              <div className="mt-12 grid grid-cols-4">
                <div>
                  <p className="font-bold">Source : {sourceName}</p>
                </div>
              
              </div>
              <div className="mt-5 grid grid-cols-2 flex">
                <div>
                  <p className="font-bold">Destination : {destName}</p>
                </div>
              
                </div>
              </div>
             
            </div>
            {/*footer*/}
           
          </div>
        </div>
    
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>{" "}
    </>
  );
};



const ProductCard = ({handleChange,listData,index,obj}) => {
  const [selected, setSelected] = useState({ name: "Select.." })
  return(
    <div className="flex w-full  mt-[2px] h-[7vh]">
                      <div className="w-[40%] h-[7vh] flex justify-center items-center">
                      <p>{'['+obj?.pc_psic_info_internal_transfer?.code+']'+obj?.pc_psic_info_internal_transfer?.name}</p>
                      </div>
                      <div className="w-[20%] h-[7vh] flex justify-center items-center">
                       <p>....</p>
                      </div>
                      <div className="w-[20%] h-[7vh] flex justify-center items-center">
                       <p>{obj?.harvest_amount}</p>
                      </div>
                      <div className="w-[20%] h-[7vh] flex justify-center items-center">
                        <p>{obj?.pc_psic_info_internal_transfer?.uom}</p>
                      </div>
                    </div>
  )
}