import React, { useState } from "react";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import ReactPaginate from "react-paginate";
import DropDownComp from "./DropDownComp";

export const TablePaginationComp = ({
  active,
  selectedPage,
  setRowsPerPage,
  totalItems,
  showPerPageDropdown,
  pages
}) => {
  const [rowsPerPage, setRowPerPage] = useState(10);
  const [rowsInfoPerPage, setRowInfoPerPage] = useState();
  const rowPagePer = [
    { id: 10, name: "10" },
    { id: 25, name: "25" },
    { id: 50, name: "50" },
    { id: 100, name: "100" },
  ];
  const offset = active * rowsPerPage;

  const handlePageClick = (data) => {
    selectedPage(data.selected);
  };
  const handleRowsPerPageChange = (event) => {
    setRowInfoPerPage(event);
    setRowsPerPage(event.id);
    setRowPerPage(parseInt(event.id));
    handlePageClick({ selected: 0 });
  };
  return (
    <>
      {/* <PagesJSX /> */}
      <div className="flex w-full">
        <div className="w-[100%] flex">
          <span>
            Showing {totalItems === 0 ? 0 : offset + 1} -{" "}
            {totalItems > offset + rowsPerPage
              ? offset + rowsPerPage
              : totalItems}{" "}
            of {totalItems}
          </span>
        </div>
        <div>
          <ReactPaginate
            breakLabel="..."
            previousLabel={
              <div className="px-2 cursor-pointer py-2 bg-gray-100 mr-2 rounded-md flex justify-center items-center">
                <AiOutlineLeft />
              </div>
            }
            pageClassName={"px-2 p-1"}
            nextLabel={
              <div className="px-2 cursor-pointer py-2 bg-gray-100 ml-2 rounded-md flex justify-center items-center">
                <AiOutlineRight />
              </div>
            }
            pageCount={pages||Math.ceil(totalItems / rowsPerPage)}
            initialPage={active}
            containerClassName={"flex"}
            activeClassName={
              "flex justify-center items-center mr-1 mt-1 px-2 w-5 h-5 rounded-md cursor-pointer bg-[#3EB049] text-white"
            }
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={1}
            forcePage={active}
            renderOnZeroPageCount={null}
          />
          {showPerPageDropdown ? (
            <span
              style={{
                marginLeft: "-100px",
                marginTop: "-35px",
                display: "block",
              }}
            >
              <DropDownComp
                listData={rowPagePer}
                active={rowsInfoPerPage || rowPagePer[0]}
                type={"sort_by"}
                onSelected={(item) => {
                  handleRowsPerPageChange(item);
                }}
                customStyles={
                  "rounded-lg justify-between text-black h-[0.8rem] text-[10px]"
                }
                customMenuStyle={
                  "relative inline-block text-left w-[100px]  px-5"
                }
              />
            </span>
          ) : null}
        </div>
      </div>
    </>
  );
};
