import React, { useState, useEffect } from "react";
import { PageLayoutComp } from "./PageLayoutComp";
import { IoIosArrowRoundBack } from "react-icons/io";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import submitted from "../images/submitted.png";
import unsubmitted from "../images/unsubmitted.png";
import { ImageSliderModal } from "../Models/ImageSlider";

import { _fetchMetaData } from "../api";

import { getDateFormatMoment } from "../config/localdata";

const WeeklyReportCropDetail = ({
  handleShowDetail,
  detailPageData,
  weekData,
  farmsData,
  cropWiseProductInfo,
}) => {

  
  const farmChunksSubmtted = [];
  for (let i = 0; i < farmsData?.submitted_farms?.length; i += 4) {
    farmChunksSubmtted.push(farmsData?.submitted_farms.slice(i, i + 4));
  }

  const farmChunksUnSubmtted = [];
  for (let i = 0; i < farmsData?.unsubmitted_farms?.length; i += 4) {
    farmChunksUnSubmtted.push(farmsData?.unsubmitted_farms.slice(i, i + 4));
  }

  const [isVegitative, setIsvegitative] = useState(false);
  const generalReport = detailPageData?.detailed_report[1];
  const vegitativeReport = detailPageData?.detailed_report[2];
  const generativeReport = detailPageData?.detailed_report[3];
  const [metaData, setMetaData] = useState();

  const [showCropReport, setShowCropReport] = useState(false);
  const [images, setImages] = useState([]);

  useEffect(() => {
    _fetchData();
  }, []);

  console.log("detailPageDatadetailPageData", generalReport?.report_created_at)

  const handleImgClick = (item) => {
    setImages(item);
    setShowCropReport(true);
  };

  async function _fetchData() {
    let metaData = await _fetchMetaData();
    setMetaData(metaData);
  }

  const getMetadataName = (id) => {
    let data = "";
    data = metaData?.weeklyReportSubTypes?.filter((e) => e.id == id);
    return data && data[0] && data[0]?.sub_type_name;
  };

  const getUIWithDetails = (
    mt,
    subTypeId,
    notes,
    pictures,
    details,
    rowIndex = 0
  ) => {
    return (
      //${rowIndex%2==0 ? `bg-white`:``}
      <div className={`bg-white py-2 px-4 mt-2 rounded-lg`}>
        <div className={`mt-${mt}`}>
          <p className="text-sm font-bold text-green">
            {getMetadataName(subTypeId)}
          </p>
        </div>
        <div className="flex mt-2 ">
          <div className="w-1/2 pr-4">
            <h2 className="text-sm font-semibold mb-2">Notes</h2>
            <p className="text-xs">{notes}</p>
          </div>
          <div className="w-1/2 pr-4">
            <h2 className="font-semibold mb-2">Pictures</h2>
            <div className="flex gap-3 overflow-x-auto">
              {pictures?.map((pic) => {
                return (
                  <img
                    className="w-[5.625rem] h-[5.625rem] rounded-lg "
                    key={pic?.id}
                    src={pic?.doc_link}
                    onClick={() => {
                      handleImgClick(pictures);
                    }}
                  />
                );
              })}
            </div>
          </div>
        </div>

        {details ? (
          details.issue_mitigation_notes ? (
            <>
              <div>
                <div className="mt-2">
                  <p className="text-sm font-bold">Mitigation</p>
                </div>
                <div className="flex mt-2 ">
                  <div className="w-1/2 pr-4">
                    <h2 className="text-sm font-semibold mb-2">Notes</h2>
                    <p className="text-xs">{details?.issue_mitigation_notes}</p>
                  </div>
                </div>
              </div>
            </>
          ) : details.is_rain_reported !== null ? (
            <>
              <div>
                <div className="mt-2">
                  <p className="text-sm font-bold">
                    {!!details.is_rain_reported ? "Rained" : "No Rain"}
                  </p>
                </div>
              </div>
            </>
          ) : details.affected_crops_info !== null ? (
            <>
              <div>
                <div className="flex mt-2 ">
                  <div className="w-1/2 pr-4">
                    <p className="text-xs">
                      {details?.affected_crops_info}% Crops Affected
                    </p>
                  </div>
                </div>
              </div>
            </>
          ) : details.dosage_info !== null ? (
            <>
              <div>
                <div className="mt-2">
                  <p className="text-sm font-bold">Dosage</p>
                </div>
                <div className="flex mt-2 ">
                  <div className="w-1/2 pr-4">
                    <p className="text-xs">{details?.dosage_info}</p>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <></>
          )
        ) : (
          <></>
        )}
      </div>
    );
  };

  const getUIWithoutNotes = (mt, subTypeId, reportData, rowIndex = 0) => {
    const fertilizers = reportData?.fertilizers_used;
    const details = reportData?.weekly_report_detail;
    return (
      <div className={`bg-white py-2 px-4 mt-2 rounded-lg `}>
        <div className={`mt-${mt}`}>
          <p className="text-sm font-bold text-green">
            {getMetadataName(subTypeId)}
          </p>
        </div>
        {details && details.is_rain_reported !== null ? (
          <>
            <div>
              <div className="mt-2">
                <p className="text-sm font-bold">
                  {!!details.is_rain_reported ? "Rained" : "No Rain"}
                </p>
              </div>
            </div>
          </>
        ) : fertilizers && fertilizers.length ? (
          <div className="flex mt-2 ">
            <div className="w-1/2 pr-4">
              {fertilizers.map((fr, index) => {
                const item = fr.item_info;
                const displayData =
                  item?.bom_data?.display_name || item?.sku?.name;
                return (
                  <p className="text-xs">
                    {index + 1}. {displayData}
                  </p>
                );
              })}
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  };

  const getUIforYieldEstimation = (mt, subTypeId, reportData, rowIndex = 0) => {
    const fertilizers = reportData?.future_harvests;
    return (
      <div className={`bg-white py-2 px-4 mt-2 rounded-lg`}>
        <div className={`mt-${mt}`}>
          <p className="text-sm font-bold text-green">
            {getMetadataName(subTypeId)}
          </p>
        </div>
        <div className="flex mt-2 ">
          <div className="w-1/2 pr-4">
            <h2 className="text-sm font-semibold mb-2">Notes</h2>
            <p className="text-xs">{reportData?.notes}</p>
          </div>
        </div>
        {fertilizers && fertilizers.length ? (
          <div className="flex mt-2 ">
            <div className="w-1/2 pr-4">
              {fertilizers.map((item) => {
                console.log('item------>>>>>',item);
                return (
                <div className="mb-4">
                    <p className="text-xs">
                    <span className="font-bold">Week {item?.future_week_number}</span>  ( {item?.week_date_range} )
                  </p>
                    <p className="text-xs">
                    Harvest Target : {item?.total_exp_harvest}
                  </p>
                   <p className="text-xs">
                   Yield Estimation : {item?.harvest_estimate} {item?.harvest_uom}
                 </p>
                </div>
                );
              })}
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  };

  const getVegitativeReport = () => {
    const selectedProductVegReport = vegitativeReport;
    const reports =
      selectedProductVegReport && selectedProductVegReport["reports"];
    console.log(`reports----->>>`);
    console.log(reports);
    if (reports && Object.keys(reports).length) {
      return Object.keys(reports).map((reportSubTypeId, index) => {
        const reportData = reports[reportSubTypeId];
        const reportDetails = reportData?.weekly_report_detail;
        const mt = index == 0 ? 2 : 8;
        return ![2, 9].includes(+reportSubTypeId)
          ? getUIWithDetails(
              mt,
              reportSubTypeId,
              reportData?.notes,
              reportData?.pictures,
              reportDetails,
              index
            )
          : getUIWithoutNotes(mt, reportSubTypeId, reportData, index);
      });
    } else {
      return <p>No Reports Available!</p>;
    }
  };
  const getGenerativeReport = () => {
    const selectedProductVegReport = generativeReport;
    const reports =
      selectedProductVegReport && selectedProductVegReport["reports"];
    console.log(`reports----->>>`);
    console.log(reports);
    if (reports && Object.keys(reports).length) {
      return Object.keys(reports).map((reportSubTypeId, index) => {
        const reportData = reports[reportSubTypeId];
        const reportDetails = reportData?.weekly_report_detail;
        const mt = index == 0 ? 2 : 8;
        return ![9, 8].includes(+reportSubTypeId)
          ? getUIWithDetails(
              mt,
              reportSubTypeId,
              reportData?.notes,
              reportData?.pictures,
              reportDetails,
              index
            )
          : +reportSubTypeId === 8
          ? getUIforYieldEstimation(mt, reportSubTypeId, reportData, index)
          : getUIWithoutNotes(mt, reportSubTypeId, reportData, index);
      });
    } else {
      return <p>No Reports Available!</p>;
    }
  };

  return (
    <div className="h-[100%] ">
      {showCropReport ? (
        <ImageSliderModal
          hideModal={() => {
            setShowCropReport(false);
          }}
          images={images}
        />
      ) : null}
      <div className="flex justify-between mb-2">
        <p className="text-2xl pt-2 font-bold ">Weekly Report Detail</p>

        <div className="flex">
          <span className="bg-white border w-[240px] border-[#808080] py-2 px-4 pr-8 rounded-[10px] ">
            {" "}
            <p className="font-bold inline ">
              Week :&nbsp;
              <span className="inline-element">
                {weekData?.week_number}
              </span>{" "}
              &nbsp;&nbsp;&nbsp;{" "}
            </p>
            <span className=" font-medium text-gray-500   text-sm">
              {" "}
              {weekData?.week_date_range}
            </span>
          </span>
          <div class="w-0.5 ml-2 mr-2 bg-[#D0D0D0]"></div>
          <span className=" bg-white border border-[#808080] py-2 px-4 pr-8 rounded-[10px] ">
            <p className="font-bold inline ">
              Year :&nbsp;
              <span className="inline-element">
                {weekData?.year_number}
              </span>{" "}
              &nbsp;&nbsp;&nbsp;{" "}
            </p>
          </span>
        </div>

        <div
          onClick={() => handleShowDetail(null)}
          className="flex cursor-pointer"
        >
          <IoIosArrowRoundBack size={22} color={"green"} className="mt-2" />
          <p className="text-green text-md mt-2 cursor-pointer font-bold">
            Back to List
          </p>
        </div>
      </div>

      <div className=" rounded-2xl  bg-white p-2 ">
       <div className="divide-y divide-emerald-200 "> 
        <div className=" rounded-md">
          <div className="p-2 ">
            <div className="flex gap-x-4 h-[20%] ">
              <div className="  w-[10%] h-[40%] flex  bg-[#D7EFD9] rounded-md mx-2 my-auto ">
                <img
                  src={cropWiseProductInfo?.product_image}
                  className=" mix-blend-multiply rounded-md  my-auto p-3  "
                ></img>
              </div>

              <div className="flex h-[20%] flex-col p-2  w-full">
                <p className="font-semibold mb-2 ">
                  {cropWiseProductInfo?.product_name} (
                  {cropWiseProductInfo?.production_code})
                </p>
                <p className="font-bold mb-2">Farms</p>
                <div className="flex flex-wrap  gap-x-6 gap-y-4 w-full ">
                  {farmChunksSubmtted.map((chunk) => (
                    <div className="flex flex-col text-xs break-all ">
                      {chunk.map((farm) => (
                        <div className="mb-[0.1rem] flex items-center">
                          <img
                            className="w-4 h-4"
                            src={submitted}
                            style={{ display: "inline", marginRight: "0.1rem" }}
                          />
                          <span>{farm?.farm_name}</span>
                        </div>
                      ))}
                    </div>
                  ))}
                  {farmChunksUnSubmtted.map((chunk) => (
                    <div className="flex flex-col text-xs break-all ">
                      {chunk.map((farm) => (
                        <div className="mb-[0.1rem] flex items-center">
                          <img
                            className="w-4 h-4"
                            src={unsubmitted}
                            style={{ display: "inline", marginRight: "0.1rem" }}
                          />
                          <span>{farm?.farm_name}</span>
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div className="px-4 pt-5">
          <p className="text-lg font-semibold">Farm General Report</p>
        </div>
      </div> 
        <div className="flex mt-2 px-4 ">
          <div className="w-1/2 pr-4">
            <h2 className="font-semibold mb-2">Notes</h2>
            <p className="break-all">{generalReport?.notes}</p>
          </div>
          <div className="w-1/2 pr-4">
            <h2 className="font-semibold mb-2">Pictures</h2>
            <div className="flex gap-3 overflow-x-auto">
              {generalReport?.pictures?.map((pic) => {
                return (
                  <img
                    className="w-[90px] h-[90px] rounded-[10px] "
                    key={pic?.id}
                    src={pic?.doc_link}
                    onClick={() => {
                      handleImgClick(generalReport?.pictures);
                    }}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>

      <div className="mt-3  rounded-2xl ">
        <div className="rounded-2xl">
          <div className="flex justify-between w-full m-1 p-3 bg-white rounded-lg">
            <div className="flex font-semibold"><p>{detailPageData?.summary?.farm_info?.farm_name}</p></div>

            <div className="flex ">
              <button
                type="button"
                onClick={() => setIsvegitative(true)}
                className={`${
                  isVegitative
                    ? "bg-[#40AE49] text-white z-20"
                    : "bg-gray-200 text-gray-800 -z-10"
                } px-6 py-2 border border-gray-300 rounded-lg `}
                style={{
                  zIndex: isVegitative ? 2 : 1,
                  marginRight: "-1rem",
                }}
              >
                Vegetative Phase
              </button>
              <button
                type="button"
                onClick={() => setIsvegitative(false)}
                className={`${
                  !isVegitative
                    ? "bg-[#40AE49] text-white z-20"
                    : " bg-gray-200 text-gray-800 -z-10"
                } px-6 py-2 border border-gray-300 rounded-lg `}
                style={{ zIndex: !isVegitative ? 2 : 1 }}
              >
                Generative Phase
              </button>
            </div>

            <div className="flex">
            <p className="text-[#808080]">
              Submitted at{" "}
              {getDateFormatMoment(
                generalReport?.report_created_at,
                true
              )}
            </p>
            </div>
          </div>
        </div>
        <div className="bg-[#E7F2EE]  p-2">
          {isVegitative ? getVegitativeReport() : getGenerativeReport()}
        </div>
      </div>
    </div>
  );
};

export default WeeklyReportCropDetail;
