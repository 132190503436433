import React from "react";
import { ESIGNATURE_TB_COL } from "../tables";
import { useNavigate } from "react-router-dom";
import { getDateFormatMoment } from "../localdata";
import { _asyncGetTaskDocument } from "../../api";

export const ESignatureTable = ({
  Data,
  isComplete,
  token,
  metaData,
  totalPage,
  totalItems,
  active,
  onPageChange,
  docStatus,
}) => {
  let colums = ESIGNATURE_TB_COL;
  const location = useNavigate();

  const getESignDocType = (id) => {
    const docTypeArr = metaData?.eSignDocTypes || [];
    let docType = docTypeArr?.find((doc) => doc?.id == id) || "";
    return docType?.type_name || "";
  };

  const getESignType = (id) => {
    const docTypeArr = metaData?.eSignTypes || [];
    let docType = docTypeArr?.find((doc) => doc?.id == id) || "";
    return docType?.type_name || "";
  };
  console.log("================================",Data)
  async function _getDocument(data) {
    const response = await _asyncGetTaskDocument(data?.id);
    const linkSource = `data:application/pdf;base64,${response}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = data?.doc_name;
    downloadLink.click();

    //const arr = base64ToArrayBuffer(response);
    //setTaskDocument(arr);
  }
  

  return (
    <div className="">
      <div className="h-[7%]">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        ></div>
      </div>
      <div className="h-[80%] min-h-[80%] overflow-auto scrollbar-hide mt-5">
        <table className="w-full">
          <thead>
            <tr className="sticky top-0">
              {colums.map((col, i) => {
                if (col.Header === "ID") {
                  return <td key={`tfhk-${i}`}></td>;
                }
                if (
                  col.Header === "Actions" &&
                  docStatus === "pending_on_others"
                ) {
                  return <td></td>;
                }
                if (col.Header === "Last Update At" && !isComplete) {
                  return <td></td>;
                }

                return (
                  <td
                    className="text-sm text-center p-[0px] m-[0px] bg-white"
                    key={`tfhk-${i}`}
                  >
                    <div className="flex   py-2 border-gray-200 border-b-[1px]">
                      <p className="text-sm text-[#3EB049]">{col.Header}</p>
                    </div>
                  </td>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {Data.map((data, i) => {
            
              return (
                <tr key={`row-${i}`} className="border-gray-200  border-b-[1px]">
                  <td key={`tbfc-${i}`} className="py-2">
                    <p className="text-base font-bold ml-2 ">
                      {data?.doc_name}
                    </p>
                    <p className="text-xs ml-2 ">
                      {getESignType(data?.esign_type_id)} : {data?.total_pages +" Pages"}
                    </p>
                    <n />
                  </td>
                  <td key={`tbfc-${i}`} className="py-2 flex ">
                    <p className="text-sm ">
                      {getESignDocType(data?.esign_doc_type_id)}
                    </p>
                  </td>
                  <td key={`tbfc-${i}`} className="py-2">
                    <p className="text-sm ">
                      {data?.created_by?.email}
                    </p>
                    <p className="text-xs mt-2 ">
                      {getDateFormatMoment(data?.created_at, true)}
                    </p>
                  </td>
                  {
                    <td key={`tbfc-${i}`} className="py-2">
                      <p className="text-sm ">
                        {data?.last_updated_by?.email}
                      </p>
                      <p className="text-xs mt-2  cursor-pointer ">
                        {getDateFormatMoment(data?.updated_at, true)}
                      </p>
                    </td>
                  }
                  <td key={`tbfc-${i}`} className="py-2">
                    <p className="text-sm ml-2">
                      {data?.recipients?.length > 0
                        ? data?.recipients
                            ?.map((user) => user?.user_name)
                            ?.join(", ")
                        : ""}
                    </p>
                  </td>
                  <td key={`tbfc-${i}`} className="py-2">
                    {isComplete ? (
                      <div
                        className="bg-green100  place-content-center ml-3 flex   p-1 rounded-md border  cursor-pointer"
                        onClick={() => {
                          _getDocument(data);
                        }}
                      >
                        <img
                          src={require("../../images/downloads.png")}
                          className="w-[20px] h-[20px] self-center items-center text-center  "
                        />
                      </div>
                    ) : (
                      docStatus === "pending_on_me" && (
                        data?.is_sign_pending_on_vida ?
                          <div className={`rounded-md my-0 mx-auto w-[55%] p-1 text-center items-center bg-yellow-100`}>
                          <p className={`text-sm font-semibold p-1 text-yellow-500`}>Pending at Vida</p>
                        </div>:
                        <div className="bg-green100 p-1 cursor-pointer rounded-md border">
                          { (data.esign_type_id === 2) ? <p
                            className="text-sm text-white text-center cursor-pointer"
                            onClick={() => {
                              location(
                                "/farm-pipeline/e-materai-placing/" + data?.id
                              );
                            }}
                          >
                            E-Materai
                          </p> : <p
                            className="text-sm text-white text-center cursor-pointer"
                            onClick={() => {
                              location(
                                "/farm-pipeline/e-sign-review/" + data?.id
                              );
                            }}
                          >
                            E-Sign
                          </p>}
                        </div>
                      )
                    )}
                  </td>
                  <td key={`tbfc-${i}`} className="py-2">
                    <p className="text-sm text-center"></p>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="flex justify-end items-center h-[10%] text-sm px-5"></div>
    </div>
  );
};
