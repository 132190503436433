import { Navigate, Route, Routes } from "react-router-dom";
import React, { useEffect } from "react";
import './pdf-worker';

import LoginPage from "./pages/Login";
import { Farms } from "./pages/Admin/Farms";
import { Products } from "./pages/Admin/Products";
import { MitraEnquiry } from "./pages/Admin/MitraEnquiry";
import { ContactEnquiry } from "./pages/Admin/ContactEnquiry";
import { Users } from "./pages/Admin/Users";
import { Archived } from "./pages/Admin/Archived";

import { PlantingDashboard } from "./pages/Planting/Dashboard";
import { PlantingList } from "./pages/Planting/PlantingList";
import { HarvestList } from "./pages/Planting/HarvestList";

import { DosingMachine } from "./pages/IotDevices/DosingMachine";
import { WeatherStation } from "./pages/IotDevices/WeatherStation";

import { CropTracker } from "./pages/CropTracker";
import { CropTrackerLogBook } from "./pages/CropTrackerLogBook";


import { InternalTransfer } from "./pages/DraftRequest/InternalTransfer";
import { TransferStatus } from "./pages/DraftRequest/TransferStatus";
import { DraftHarvestList } from "./pages/DraftRequest/DraftHarvestList";
import { DraftPOList } from "./pages/DraftRequest/DraftPOList";
import { DraftBillList } from "./pages/DraftRequest/DraftBillList";


import { RABList } from "./pages/RAB/RABList";
import { Request } from "./pages/RAB/Request";
import { Inventory } from "./pages/RAB/Inventory";

import * as Sentry from "@sentry/react";
import { getToken } from "firebase/messaging";
import { messaging } from "./firebase";
import { _asyncRegisterNotification } from "./api";

import { CreateRab } from "./components/createRab";
import { InventoryDetails } from "./components/InventoryDetails";
import { CreateNewMitraFarm } from "./components/createNewMitraFarm";
import { PreviewRab } from "./components/PreviewRab";
import { WeeklyReport } from "./config/tables/weeklyReport/index.js";


import { MerchandiseTracker } from "./pages/MerchandiseTracker/index.js";
import { MerchandiseMapping } from "./pages/MerchandiseMapping/index.js";
import { DailyReportDetail } from "./pages/DailyReportDetail/index.js";
import { ProductDetail } from "./pages/ProductDetail/index.js";
import { ESignature } from "./config/tables/eSignature/index.js";
import { AddNewESignature } from "./components/AddNewESignature.js";
import { ESignatureUpload } from "./components/ESignatureUpload.js";
import { ESignatureReview } from "./components/ESignatureReview.js";
import { ESignatureUploadRND } from "./components/ESignatureUploadRND.js";
import { ESignatureHomePage } from "./config/tables/eSignature/ESignatureHomePage.js";
import { PhoneNumberVerification } from "./config/tables/eSignature/PhoneNumberVerification.js";
import { EMateraiUpload } from "./components/EMateraiUpload.js";
import BomMasterDataList from "./pages/RAB/BOM/BomMasterDataList.js";
import BomProductDetail from "./components/BomProductDetail.js";
import BillMaterials from "./pages/RAB/BOM/BillMaterials.js";
import PlantingDetails from "./pages/Planting/PlantingDetails.js";
import { WeatherStationReport } from "./components/WeatherStationReport.js";
import { Career } from "./pages/Admin/Career.js";
import { AddNewJobOpening } from "./components/AddNewJobOpening.js";

function App() {
  const CurrentPage = () => {
    let user = JSON.parse(localStorage.getItem("user_data"));

    async function requestPermission() {
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        const token = await getToken(messaging, {
          vapidKey: process.env.REACT_APP_VAPIDKEY,
        });
        const res = await _asyncRegisterNotification({ token });
      } else if (permission === "denied") {
        alert("You denied for the notifications");
      }
    }

    useEffect(() => {
      requestPermission();
    }, []);

    if (user) {
      return <Farms />;
    } else {
      return <LoginPage />;
    }
  };

  function AdminPageRedirect() {
    return <Navigate to="/farm-pipeline/farms" />;
  }

  function PlantingRedirect() {
    return <Navigate to="/planting/dashboard" />;
  }

  function IoTDevicesPageRedirect() {
    return <Navigate to="/iot-devices/dosing-machine" />;
  }

  function DraftRequestRedirect() {
    return <Navigate to="/draft-request/draft-harvest-list" />;
  }

  function RABRedirect() {
    return <Navigate to="/budget-plan-rab/rab-list" />;
  }

  // function MerchandiseRedirect(){
  //   return <Navigate to="/merchandise-tracker" />;

  // }
  return (
    <Routes>
      {/* admin routes */}
      <Route path="/" element={<CurrentPage />} />
      <Route path="/farm-pipeline" element={<AdminPageRedirect />} />
      <Route path="/farm-pipeline/farms" element={<Farms />} />
      <Route path="/farm-pipeline/products" element={<Products />} />
      <Route path="/farm-pipeline/mitra-enquiry" element={<MitraEnquiry />} />
      <Route path="/farm-pipeline/contact-enquiry" element={<ContactEnquiry />} />
      <Route path="/farm-pipeline/career" element={<Career />} />
      <Route path="/farm-pipeline/new-job-opening" element={<AddNewJobOpening />} />


      <Route path="/farm-pipeline/users" element={<Users />} />
      <Route path="/farm-pipeline/archived" element={<Archived />} />
      <Route path="/farm-pipeline/farm-weekly-report" element={<WeeklyReport />} />
      <Route path="/farm-pipeline/e-sign-dashboard" element={<ESignature />} />
      <Route path="/farm-pipeline/e-sign-task-creation" element={<AddNewESignature />} />
      {/* <Route path="/farm-pipeline/e-sign-placing-2/:id" element={<ESignatureUpload/>}/> */}
      <Route path="/farm-pipeline/e-sign-placing/:id" element={<ESignatureUploadRND />} />
      <Route path="/farm-pipeline/e-materai-placing/:id" element={<EMateraiUpload />} />
      <Route path="/farm-pipeline/e-sign-start-kyc" element={<ESignatureHomePage />} />

      <Route path="/farm-pipeline/e-sign-phone-verification" element={<PhoneNumberVerification />} />
      <Route path="/farm-pipeline/e-sign-review/:id" element={<ESignatureReview />} />
      <Route path="/farm-pipeline/create-new-mitra-farm" element={<CreateNewMitraFarm />} />
      {/* Planting routes */}
      <Route path="/planting" element={<PlantingRedirect />} />
      <Route path="/planting/dashboard" element={<PlantingDashboard />} />
      <Route path="/planting/planting-list" element={<PlantingList />} />

      <Route path="/planting/planting-list/planting-details/:id" element={<PlantingDetails />} />
      <Route path="/planting/planting-list/planting-details" element={<PlantingDetails />} />

      <Route path="/planting/harvest-list" element={<HarvestList />} />

      {/* iot devices routes */}
      <Route path="/iot-devices" element={<IoTDevicesPageRedirect />} />
      <Route path="/iot-devices/dosing-machine" element={<DosingMachine />} />
      <Route path="/iot-devices/weather-station" element={<WeatherStation />} />
      {/* <Route path="/iot-devices/weather-station-report" element={<WeatherStationReport/>} /> */}
      <Route path="/iot-devices/weather-station-report/:id" element={<WeatherStationReport />} />

      {/* Crops Tracker routes */}
      <Route path="/crops-tracker" element={<CropTracker />} />
      <Route path="/crops-tracker/crops-tracker-log-book/:id" element={<CropTrackerLogBook />} />


      {/* Draft Request routes */}
      <Route path="/draft-request" element={<DraftRequestRedirect />} />
      <Route path="/draft-request/draft-harvest-list" element={<DraftHarvestList />} />
      <Route path="/draft-request/po" element={<DraftPOList />} />
      <Route path="/draft-request/bill" element={<DraftBillList />} />
      <Route path="/draft-request/internal-transfer" element={<InternalTransfer />} />
      <Route path="/draft-request/transfer-status" element={<TransferStatus />} />


      {/* RAB routes */}
      <Route path="/budget-plan-rab" element={<RABRedirect />} />
      <Route path="/budget-plan-rab/rab-list" element={<RABList />} />
      <Route path="/budget-plan-rab/bom-master-data-list" element={<BomMasterDataList />} />
      <Route path="/budget-plan-rab/bill-materials/:id" element={<BillMaterials />} />
      <Route path="/budget-plan-rab/bill-materials" element={<BillMaterials />} />

      <Route path="/budget-plan-rab/request-list" element={<Request />} />
      <Route path="/budget-plan-rab/inventory-list" element={<Inventory />} />
      <Route path="/budget-plan-rab-create" element={<CreateRab />} />
      <Route path="/budget-plan-rab-preview/:id" element={<PreviewRab />} />
      <Route path="/budget-plan-rab-create/:id" element={<CreateRab />} />
      <Route path="/budget-plan-rab/inventory/:id" element={<InventoryDetails />} />
      <Route path="/budget-plan-product-detail/:id" element={<BomProductDetail />} />
      <Route path="/budget-plan-product-detail" element={<BomProductDetail />} />

      {/* Merchandise Tracker routes */}
      <Route path="/merchandise-tracker" element={<MerchandiseTracker />} />
      <Route path="/merchandise-tracker/merchandiser_mapping" element={<MerchandiseMapping />} />
      <Route path="/merchandise-tracker/daily-report-detail/:id" element={<DailyReportDetail />} />
      <Route path="/merchandise-tracker/product-detail/:id" element={<ProductDetail />} />



    </Routes>
  );
}

export default Sentry.withProfiler(App);