import React from "react";
import { BsCheck2 } from "react-icons/bs";

export const SuccessfullMesg = ({ label, tick = true, onSubmit, type }) => {
  let labelStyle = "text-[#40AE49]";
  if (type === "weatherStation") {
    labelStyle = "text-black";
  }


  return (
    <>
      <div className="flex overflow-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center">
        <div className="relative mt-[3%] mb-[3%]">
          {/*content*/}
          <div className="h-full first-letter:rounded-lg shadow-lg relative flex flex-col w-full outline-none focus:outline-none border-black border-[1px] rounded-lg">
            <div className="relative pl-6 pr-6 overflow-auto pt-3 bg-white rounded-t-lg h-full">
              <p
                className={`${labelStyle} text-[18px] font-[450px] text-center mb-5 mt-3`}
              >

                {label}
              </p>
            </div>
            {/*footer*/}
            <div className="flex justify-center py-6 rounded-b-lg bg-white w-full">
              <div className="flex w-[360px] justify-center">
                {tick ? (
                  <BsCheck2 className="text-[50px] text-[#40AE49]" />

                ) : (
                  <p
                    onClick={() => onSubmit()}
                    className="text-white bg-[#40AE49] border-[#3EB049] border mr-10 px-5 py-1 rounded-full cursor-pointer"
                  >
                    Okay
                  </p>
                )}
              </div>
              <div />
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>{" "}
    </>
  );
};
