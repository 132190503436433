import React, { useEffect, useState } from "react";
import { PLANT_TB_COL } from "..";
import {_asyncGetAllPlanting,_asyncPlantingLogs, axiosClient} from "../../../api";
import { TableSearchComp } from "../../../components/TableSearchComp";
import { TablePaginationComp } from "../../../components/TablePaginationComp";
import { useNavigate } from "react-router-dom";
import { PlantFilterModal } from "../../../Models/PlantFilterModal";
import { getDateFormatMoment, getDateFormatMomentWithoutYear } from "../../localdata";
import { BsFillCaretDownFill, BsArchiveFill, BsArchive } from "react-icons/bs";
import { ConfirmationModal } from "../../../Models/ConfirmationModal";
import { toast } from "react-toastify";
import { PlantingLogsModal } from "../../../Models/PlantingLogs";
import { FiFileText } from "react-icons/fi";
import { HiInformationCircle } from "react-icons/hi2";

import DropDownComp from "../../../components/DropDownComp";
import { IoIosArrowForward } from "react-icons/io";



export const TablePlants = ({
  metaData,
  archived = false,
  token,
  refreshTab
}) => {
  const size = 10;
  let colums = PLANT_TB_COL;
  const location = useNavigate();
  const configFetchRef = React.useRef(false);
  const [totalPage, setTotalPage] = useState();
  const [totalItems, setTotalItems] = useState();

  const [selectedPage, setSelectedPage] = useState(0);
  const [tableDataList, setTableDataList] = useState([]);
  const [allFarmsData, setAllFarmsData] = useState([]);
   const [showFilter, setShowFilter] = useState(false);
   const [togglePlFilter, setTogglePlFilter] = useState(false);
   const [filterData, setFilterData] = useState({});

   const  [csvData, setCsvData] = useState([]);
   const [allSelectedForArchive, updateAllSelected] = useState(false);
  const [someSelectedForArchive, updateSomeSelected] = useState(false);
  const [setRowsPerPage, setSetRowsPerPage] = useState(0);

  const [showNotesBox, setShowNotesBox] = useState();


  const handleNotesChange = (data) =>{
    setShowNotesBox(data?.id)
  }



   // filter data
  const [selectedProduct, setSelectedProduct] = useState();
  const [selectedFarm, setSelectedFarm] = useState();
  const [selectedStatus, setSelectedStatus] = useState();
  const [selectedMitra, setSelectedMitra] = useState();
  const [selectedTarget, setSelectedTarget] = useState();
  const [slectedPlantingDate, setSlectedPlantingDate] = useState([null, null]);
  const [slectedExpectedHarvestDate, setSlectedExpectedHarvestDate] = useState([null, null]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const [logData, setLogData] = useState();

  
  const [searchText, setSearchText] = useState('');
  const [action, setAction] = useState("");
  const [searchType, setSearchType] = useState('');

  const [showLogData, setShowLogData] = useState(false);

  let user = JSON.parse(localStorage.getItem("user_data"));


  useEffect(() => {
    if (configFetchRef.current) return;
    configFetchRef.current = true;
    _fetchPlant(selectedPage);
  }, [refreshTab]);

  useEffect(() => {
    const allSelected =
      tableDataList && tableDataList.length
        ? tableDataList.every((ele) => ele.isSelectedForArchive === true)
        : false;
    const allUnSelected =
      allSelected === false
        ? tableDataList && tableDataList.length
          ? tableDataList.every((ele) => ele.isSelectedForArchive === false)
          : true
        : false;
    updateAllSelected(allSelected);
    updateSomeSelected(!allUnSelected);
  }, [tableDataList]);

  useEffect(() => {
    _fetchPlant(selectedPage, filterData);
    }, [togglePlFilter]);

  function _checkArchivePermission() {
   if (!user?.permissions?.delete_planting_data) {
      alert("Don't have Delete Permission");
      return;
    }
    setShowConfirmModal(true);
  }

  const colorObject = {
    2:'bg-[#0094FF]',
    1:'bg-[#808080]',
    3:'bg-[#40AE49]',
  }

  const colorObject1 = {
    2:'bg-[#F491A3]',
    1:'bg-[#F491A3]',
    3:'bg-[#F491A3]',
  }

  const resetFilter = () =>{
    setShowFilter(false);
    setFilterData({})
    _fetchPlant(selectedPage, {});
    setSelectedProduct()
    setSelectedFarm()
    setSelectedStatus()
    setSelectedMitra()
    setSelectedTarget();
    setSlectedPlantingDate([null, null]);
    setSlectedExpectedHarvestDate([null, null]);
  }

  const handleLogs = async (item) =>{
    let res = await _asyncPlantingLogs(item?.id)
    if(res?.length > 0){
      setLogData(res)
      setShowLogData(true)
    }
 }
 function handleEditButton(item) {
  location("/planting/planting-list/planting-details/" + item?.id);
}
  const addFilters = () =>{
    let product_ids = []
    let farm_ids = []
    let planting_status_ids = []
    let mitra_ids = []
    let target_harvests = [];
    let planting_date_range = [];
    let expected_harvest_date_range = [];

    if(selectedProduct !== undefined){
        product_ids.push(selectedProduct.id)
    }
    if(selectedFarm !== undefined){
        farm_ids.push(selectedFarm.id)
    }
    if(selectedStatus !== undefined){
        planting_status_ids.push(selectedStatus.id)
    }
    if(selectedMitra !== undefined){
        mitra_ids.push(selectedMitra.id)
    }
    if(selectedTarget !== undefined){
        target_harvests.push(selectedTarget)
    }

    if(slectedPlantingDate !== undefined){
      let startDate = new Date(slectedPlantingDate[0]);
      let endDate = new Date(slectedPlantingDate[1]);
      let s = (startDate.getMonth() +1) + "/" + startDate.getDate()  + "/" + startDate.getFullYear();
      let e = (endDate.getMonth() +1) + "/" + endDate.getDate()  + "/" + endDate.getFullYear();
        s !== '1/1/1970' &&  planting_date_range.push(s);
        e !== '1/1/1970' && planting_date_range.push(e);
    }

    if(slectedExpectedHarvestDate !== undefined){
      let startDate = new Date(slectedExpectedHarvestDate[0]);
      let endDate = new Date(slectedExpectedHarvestDate[1]);
      let s = (startDate.getMonth() +1) + "/" + startDate.getDate()  + "/" + startDate.getFullYear();
      let e = (endDate.getMonth() +1) + "/" + endDate.getDate()  + "/" + endDate.getFullYear();
      s !== '1/1/1970' &&  expected_harvest_date_range.push(s);
      e !== '1/1/1970' &&   expected_harvest_date_range.push(e);
    }
    _applyFilters({product_ids, farm_ids, planting_status_ids, mitra_ids, target_harvests, planting_date_range, expected_harvest_date_range})
   // applyFilters({product_ids, farm_ids, planting_status_ids, mitra_ids, target_harvests, planting_date_range, expected_harvest_date_range})
  }

  const _fetchPlant = async (page, filterData) => {   
    let data = {
      page: page+1,
      size: setRowsPerPage > 10 ? setRowsPerPage : size,
      ...filterData,
    }
    if(searchType?.search_by_key && searchText){
      data[searchType?.search_by_key] = searchText;
    }
    if(togglePlFilter){
      data = {...data,fetch_start_delayed:true}
    }
    let res = await _asyncGetAllPlanting(data, location);
    let { displayData, totalPage, totalItems } = res;
    setTableDataList(
      displayData.map((d) => {
        d.isSelectedForArchive = false;
        return d;
      })
    );
    setAllFarmsData(
      displayData.map((d) => {
        d.isSelectedForArchive = false;
        return d;
      })
    );
    setTotalPage(totalPage);
    setTotalItems(totalItems);
    let params = {
      page: 1,
      size:totalPage*size
    }
    let response = await _asyncGetAllPlanting(params, location);
    let flatData = [];
    response?.displayData.map((item)=>{
      flatData.push(flattenObject(item));
    })
    setCsvData(flatData);
  }

  const flattenObject = (input) => {
    let result = {};
    for (const key in input) {
     if (!input.hasOwnProperty(key)) {
       continue;
     } 
     if (typeof input[key] === "object" &&!Array.isArray(input[key])) {
           var subFlatObject = flattenObject(input[key]);
           for (const subkey in subFlatObject) {
               result[key + "_" + subkey] = subFlatObject[subkey];
           }
       } else {
           result[key] = input[key];
       }
     }
      delete result.id;
      delete result.mitra_id;
      delete result.farm_id;
      delete result.farm_is_mitra_farm;
      delete result.product_id;
      //delete result.mitra_admin_role_ids;
      delete result.mitra_staff_type_id;
      delete result.is_harvest_completed
      delete result.activity_updated_at
      result.planting_date = getDateFormatMoment(result.planting_date, true);
      result.actual_planting_date = getDateFormatMoment(result.actual_planting_date, true);
      result.expected_harvest_date = getDateFormatMoment(result.expected_harvest_date, true);
     return result;
   }

   const handleSearchSubmit = (action) => {
    setAction(action)
    setSelectedPage(0)
    if (action === "reset") {
      setSearchText("");
      setSearchType("");
      _fetchPlant(0, null, filterData)
    }
    if (searchText.length > 2 && action !== "reset") {
      _fetchPlant(0, null, filterData)
    }
  }

  useEffect(() => {
    _fetchPlant(selectedPage);
  }, [setRowsPerPage]);


  useEffect(()=>{
    _fetchPlant(selectedPage)
  },[action])

  function _filterData(text) {
    let newArray = allFarmsData.filter((item) =>
      item.farm_name.toLowerCase().includes(text.toLowerCase())
    );
    setTableDataList(newArray);
  }

  function toggleFilter(event) {
    setTogglePlFilter(event?.target?.checked)
  }

  async function _applyFilters(items) {
    setShowFilter(false);
    setFilterData({...items})
    let data = {
      page: selectedPage,
      size:size,
      ...items
    }
    let res = await _asyncGetAllPlanting(data, location);
    let { displayData, totalPage, totalItems } = res;
    setTableDataList(
      displayData.map((d) => {
        d.isSelectedForArchive = false;
        return d;
      })
    );
    setAllFarmsData(
      displayData.map((d) => {
        d.isSelectedForArchive = false;
        return d;
      })
    );
    setTotalPage(totalPage);
    setTotalItems(totalItems);
  }

  const confirmBeforeArchive = async () => {
    await doArchive();
  };

  const doArchive = async () => {
    try {
      const body = [];
      tableDataList.forEach((item) => {
        if (item.isSelectedForArchive === true) {
          body.push(item.id);
        }
      });
      if (body.length > 0) {
        const apiResult = await axiosClient.put('admin/planting-activities/archive', body, {
          headers: { token },
        });
        if (apiResult && apiResult.status === 200) {
          toast.success(`Planting Archived Successfully!`,
            {
              autoClose: 1400,
            }
          );
          setTimeout(() => {
            window.location.reload();
          }, 1500);
        }
      }
    } catch (error) {
      console.log(`Something went wrong when archiving plants -->>> `);
      console.log(error);
    }
  };

  return (
    <div className={`${archived ? "h-[85%]" : "h-[100%]"}`}>
       {showConfirmModal ? (
        <ConfirmationModal
          label={`Do you really want to ${
            archived ? "un archive" : "archive"
          } this?`}
          hideModal={() => setShowConfirmModal(false)}
          onConfirm={() => {
            setShowConfirmModal(false);
            confirmBeforeArchive();
          }}
        />
      ) : null}

      {showLogData ? (
        <PlantingLogsModal
          hideModal={() => {setShowLogData(false)}}
          logData={logData}
        />
      ) : null}



      {showFilter ? (
        <PlantFilterModal
          metaData={metaData}
          filterData={filterData}
          hideModal={() => setShowFilter(false)}
          applyFilters={(items) => _applyFilters(items)}
          resetFilter={resetFilter}
          addFilters={addFilters}
          selectedProduct={selectedProduct}
          selectedFarm={selectedFarm}
          selectedStatus={selectedStatus}
          selectedMitra={selectedMitra}
          selectedTarget={selectedTarget}
          slectedPlantingDate={slectedPlantingDate}
          slectedExpectedHarvestDate={slectedExpectedHarvestDate}
          setSelectedProduct={setSelectedProduct}
          setSelectedFarm={setSelectedFarm}
          setSelectedStatus={setSelectedStatus}
          setSelectedMitra={setSelectedMitra}
          setSelectedTarget={setSelectedTarget}
          setSlectedPlantingDate={setSlectedPlantingDate}
          setSlectedExpectedHarvestDate={setSlectedExpectedHarvestDate}
        />
      ) : null}
      <div className="h-[100%]">
        <div className="h-[10%]">
            <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <input
              type="checkbox"
              checked={allSelectedForArchive}
              className="mt-4"
              onChange={() => {
                if (tableDataList && tableDataList.length)
                  setTableDataList(
                    tableDataList.map((tData) => {
                      tData.isSelectedForArchive = !allSelectedForArchive;
                      return tData;
                    })
                  );
              }}
            ></input>
            <div className="ml-5 mt-4">
              {someSelectedForArchive === true ? (
                <BsArchiveFill
                  size={15}
                  onClick={() => _checkArchivePermission()}
                />
              ) : (
                <BsArchive size={15} />
              )}
            </div>
            <div className="w-[95%] ml-3 mt-4">
              <TableSearchComp
                onChange={(text) => setSearchText(text)}
                openFilter={() => setShowFilter(true)}
                handleSearchSubmit={handleSearchSubmit}
                openToggleFilter={(e) => toggleFilter(e)}
                showToggleBtn={true}
                toggleBtnName='Planting Delay'
                showCSV={true}
                csvData={csvData}
                searchText={searchText}
                searchType={searchType}
                onSearchChangeType={(item) => setSearchType(item)}
                fileName={'Planting_data.csv'}
                searchTypeValues={metaData?.searchableFeaturesWithOptions?.planting_list}
              />
            </div>
          </div>
        </div>
        <div className={`h-[80%] overflow-auto scrollbar-hide mt-5`}>
          <table className="w-full">
            <thead>
              <tr className="sticky top-0 z-[30]">
                <td></td>
                {colums.map((col, idx) => {
                  if (col?.Header === "FARM ID") {
                    return;
                  }
                  return (
                    <td className="text-sm bg-white" key={`tfhk-${idx}`}>
                      <div className="flex justify-center items-center py-2 border-gray-200 border-b-[1px] cursor-pointer">
                        <p className="text-sm text-[#3EB049]">{col?.Header}</p>
                        {/* {col.Header !== "FARM ID" && false ? (
                          <BsFillCaretDownFill className="ml-2" size={10} />
                        ) : null} */}
                      </div>
                    </td>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {tableDataList.map((data, i) => {
                return (
                  <tr
                    key={`row-${i}`}
                    className="border-gray-200 border-b-[1px]"
                  >
                    <td>
                       <input
                        type="checkbox"
                        checked={data?.isSelectedForArchive}
                        onChange={() => {
                          const updatedData = [...tableDataList];
                          updatedData[i].isSelectedForArchive =
                            !updatedData[i]?.isSelectedForArchive;
                          setTableDataList(updatedData);
                        }}
                      ></input>
                    </td>
                    {colums.map((col, i) => {
                      // console.log(JSON.stringify(data),'---');
                      if (col.Header === "FARM ID") {
                        return;
                      }
                      if (col.Header === "Plant") {
                        return (
                          <td
                            key={`tbfc-${i}`}
                            className="py-2 text-center text-green"
                            //onClick={() => _checkUpdatePermission(data)}
                          >
                            <p className="border-b-green border-b-[1px]">{data?.product[col?.accessor].toString()}</p>
                            <p className="border-b-green border-b-[1px]">{data?.product['production_code'].toString()}</p>
                          </td>
                        );
                      } 
                      
                      else if (col.Header === "Mitra") {
                        return (
                          <td key={`tbfc-${i}`} className="py-2">
                            <p className="text-sm text-center">
                              {data?.mitra[col?.accessor]}
                            </p>
                          </td>
                        );
                      } 
                      else if (col.Header === "FaaS PIC") {
                        return (
                          <td key={`tbfc-${i}`} className="py-2">
                            <p className="text-sm text-center">
                              {data?.created_by[col?.accessor]}
                            </p>
                          </td>
                        );
                      } 

                      else if (col.Header === "Planting Date") {
                        return (
                          <td key={`tbfc-${i}`} className="py-2">
                            <p className="text-sm text-center">
                            {data?.is_weekly_planting ? (data?.planting_date && data?.end_planting_date ? getDateFormatMomentWithoutYear(data?.planting_date) +'-'+ getDateFormatMoment(data?.end_planting_date): '-'):(data?.planting_date ? getDateFormatMoment(data?.planting_date): '-')}
                            </p>
                          </td>
                        );
                      } 

                      else if (col?.Header === "Actual Planting Date") {
                        return (
                          <td key={`tbfc-${i}`} className="py-2">
                            <p className="text-sm text-center">
                              {data?.actual_planting_date ? getDateFormatMoment(data?.actual_planting_date): '-'}
                            </p>
                          </td>
                        );
                      } 

                      else if (col?.Header === "Expected Harvest Date") {
                        return (
                          <td key={`tbfc-${i}`} className="py-2">
                            <p className="text-sm text-center">
                              {data?.expected_harvest_date ? getDateFormatMoment(data?.expected_harvest_date): '-'}
                            </p>
                          </td>
                        );
                      } 

                      else if (col.Header === "Farm") {
                        return (
                          <td key={`tbfc-${i}`} className="py-2">
                            <p className="text-sm text-center">
                              {data?.farm[col?.accessor]}
                            </p>
                          </td>
                        );
                      } 

                      else if (col?.Header === "Log") {
                        return (
                          <td key={`tbfc-${i}`} className="py-2">
                           <span onClick={()=>
                           handleLogs(data)
                          // handleEditButton()
                          } 
                           className="justify-center p-1 text-sm ml-2 items-center rounded-lg cursor-pointer border border-gray-400">Log</span>
                          </td>
                        );
                      } 
                       
                      else if (col.Header === "RAB") {
                        return (
                          <td key={`tbfc-${i}`} className="py-2 pl-2 text-center text-sm">
                            {data?.rab_id ? <FiFileText color="green" className="cursor-pointer" onClick={()=>location('/budget-plan-rab-preview/'+data?.rab_id)}></FiFileText> :"-"}
                          </td>
                        );
                      } 

                      else if (col.Header === "Notes") {
                        return (
                          <td key={`tbfc-${i}`} className="relative text-center pl-2 text-sm">
                            {data?.comments_on_planting_start ? <HiInformationCircle size="1.5em" color="green" className="-z-50 cursor-pointer" onMouseOver={()=>handleNotesChange(data)} onMouseOut={()=>setShowNotesBox(null)}>
                            </HiInformationCircle> :"-"}
                            {showNotesBox === data?.id && 
                            <div  className="absolute  min-w-[200px] p-2 bg-white border border-black rounded-lg shadow hover:bg-gray-100">
                              <p class="text-start text-[1rem] font-bold text-black text-pretty break-all">{data?.comments_on_planting_start}</p>
                              <div className="flex justify-between mt-[0.25rem]">
                              <p className="text-start text-[12px] text-black">{data?.late_or_early}</p>
                              <p className="text-[12px] text-[#808080]">Expected at {getDateFormatMoment(data?.planting_date)}</p>
                              </div>
                            </div>}
                          </td>
                        );
                      } 


                      else if (col?.Header === "Status") {
                        const foodBar = metaData?.plantingStatus.find(item => item?.id === data?.current_planting_status_id)
                        let color = colorObject[data?.current_planting_status_id];
                        return (
                          <td key={`tbfc-${i}`} className="py-2">
                          {foodBar?.status ? 
                          <p className={`text-[10px] font-[600] text-white text-center ${color} px-3 py-1`}>
                            {foodBar?.status}
                          </p>:<p className="text-sm text-center">-</p>}
                        </td>
                          );
                      } 
                      
                      else if (col?.Header === "Stage") {
                      const foodBar = metaData?.productGrowthStagesV2.find(item => item.id === data?.current_growth_stage_id)
                      let color = colorObject1[data?.current_growth_stage_id];

                        return (
                          <td key={`tbfc-${i}`} className="py-2">
                            {foodBar?.stage_name ?
                            <p className={`text-[10px] font-[600] text-white text-center ${color} px-3 py-1`}>
                              {foodBar?.stage_name}
                            </p>:<p className="text-sm text-center">-</p>}
                          </td>
                        );
                      } else {
                        return (
                          <td key={`tbfc-${i}`} className="py-2">
                            <p
                              title={
                                data[col?.accessor] == null
                                  ? ""
                                  : data[col?.accessor].toString()
                              }
                              className="text-sm text-center"
                            >
                              {data[col?.accessor] == null
                                ? "-"
                                : data[col?.accessor].toString()}
                            </p>
                          </td>
                        );
                      }
                    })}
                      <div className="ml-5 basis-1/1 mt-6">
                    <IoIosArrowForward
                    onClick={()=>handleEditButton(data)}
                    className="cursor-pointer"
                    size={22}
                    />
                  </div>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="flex justify-end items-center h-[10%] text-sm px-5">
          {totalPage !== undefined ? (
            <TablePaginationComp
              totalPages={totalPage}
              active={selectedPage}
              selectedPage={(p) => {
                setSelectedPage(p);
                _fetchPlant(p, filterData);
              }}
              totalItems={totalItems}
              showPerPageDropdown={true}
              setRowsPerPage={(r) => {
                setSetRowsPerPage(r);
              }}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};
