import React, { useEffect, useState } from "react";
import closeImg from "../images/FarmPipeLine/close.png";
import crossImg from "../images/FarmPipeLine/cross.png";
import {
  _asyncGetAllProvince,
  _asyncGetDistrictByAbv,
  _asyncUpdateProductGeneralInfo,
} from "../api";
import SearchDropDownComp from "../components/SearchDropDownComp";

export const FarmFilterModal = ({
  hideModal,
  metaData,
  applyFilters,
  resetFilter,
  filterData
}) => {
  const metaDataDetails = metaData;
  const [provinceList, setProvinceList] = useState([]);
  const [districtList, setDistrictList] = useState([]);

  const [province, setSelectedProvince] = useState(filterData?.province || undefined);
  const [district, setSelectedDistrict] = useState(filterData?.district || undefined);
  const [farm_function, setSelectedFunction] = useState(filterData?.farm_function || undefined);
  const [relation, setSelectedRelation] = useState(filterData?.relation || undefined);
  const [farmTypes, setSelectedFarmType] = useState(filterData?.farmTypes || undefined);



function getFarmTypes(id) {
  let data = "";
  data = metaData?.farmTypes?.filter((e) => e.id == id);
  return data && data[0] && data[0]?.farm_type_name;
}

function farmFunctions(id) {
  let data = "";
  data = metaData?.farmFunctions?.filter((e) => e.id == id);
  return data && data[0] && data[0]?.function;
}


  console.log("filterDatafilterDatafilterData",farmTypes)

  useEffect(() => {
    _fetchData();
  }, []);

  async function _fetchData() {
    let res = await _asyncGetAllProvince();
    setProvinceList(res);
    _fetchDistricts(res[0].abv);
  }

  async function _fetchDistricts(abv) {
    const res = await _asyncGetDistrictByAbv(abv);
    console.log(abv, res);
    setDistrictList(res);
  }

  if (provinceList.length == 0 || districtList.length === 0) return null;

  return (
    <>
      <div className="flex overflow-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-full mt-[3%] ml-[7%] mr-[7%] mb-[3%]">
          {/*content*/}
          <div className="h-full border-0 rounded-lg shadow-lg relative flex flex-col w-full outline-none focus:outline-none">
            <div className="relative p-10 overflow-auto pt-3 bg-white rounded-t-lg h-full">
              <img
                className="float-right w-[30px] h-[30px] mt-3"
                src={closeImg}
                alt="closeImg"
                onClick={() => {
                  hideModal();
                }}
              />
              <p className="font-poppins font-semibold text-[24px] mt-3">
                FILTER
              </p>
              <div class="grid grid-cols-5 gap-6 mt-6">
                <div className=" z-10">
                  <p className="mb-8 font-poppins font-medium ">Province</p>
                  <SearchDropDownComp
                    type="province"
                    list={provinceList}
                    onSelected={(item) => {
                      setSelectedProvince(item?.province);
                      _fetchDistricts(item.abv);
                    }}
                  />
                </div>
                <div>
                  <p className="mb-8 font-poppins font-medium">District</p>
                  <SearchDropDownComp
                    type="district"
                    list={districtList}
                    onSelected={(item) => setSelectedDistrict(item.district)}
                  />
                </div>
                <div>
                  <p className="mb-8 font-poppins font-medium">Farm Type</p>
                  <SearchDropDownComp
                    type="FarmType"
                    list={metaDataDetails.farmTypes}
                    onSelected={(item) => setSelectedFarmType(item?.id)}
                  />
                </div>
                <div>
                  <p className="mb-8 font-poppins font-medium">Ownership</p>
                  <SearchDropDownComp
                    type="relation"
                    list={metaDataDetails?.farmOwnershipTypes}
                    onSelected={(item) => setSelectedRelation(item?.ownership_type)}
                  />
                </div>
                <div>
                  <p className="mb-8 font-poppins font-medium ">Function</p>
                  <SearchDropDownComp
                    type="farmFunctions"
                    list={metaDataDetails.farmFunctions}
                    onSelected={(item) => setSelectedFunction(item?.id)}
                  />
                </div>
              </div>
              <div class="grid grid-cols-5 gap-6 mt-6">
                <div className="flex">
                  {province !== undefined ? (
                    <div className="flex justify-center items-center border border-[#252525] px-3 py-1 rounded-lg">
                      <img
                        className="w-[15px] h-[15px] mr-2"
                        src={crossImg}
                        onClick={()=>setSelectedProvince(undefined)}
                        alt="crossImg"
                      />
                      <p className="text-[14px] font-normal">
                        {province?.toString()}
                      </p>
                    </div>
                  ) : null}
                </div>
                <div className="flex">
                  {district !== undefined ? (
                    <div className="flex justify-center items-center border border-[#252525] px-3 py-1 rounded-lg">
                      <img
                        className="w-[15px] h-[15px] mr-2"
                        src={crossImg}
                        onClick={()=>setSelectedDistrict(undefined)}
                        alt="crossImg"
                      />
                      <p className="text-[14px] font-normal">
                        {district?.toString()}
                      </p>
                    </div>
                  ) : null}
                </div>
                <div className="flex">
                  {farmTypes !== undefined ? (
                    <div className="flex justify-center items-center border border-[#252525] px-3 py-1 rounded-lg">
                      <img
                        className="w-[15px] h-[15px] mr-2"
                        src={crossImg}
                        onClick={()=>setSelectedFarmType(undefined)}
                        alt="crossImg"
                      />
                      <p className="text-[14px] font-normal">
                        {getFarmTypes(farmTypes)}
                      </p>
                    </div>
                  ) : null}
                </div>

                <div className="flex">
                  {relation !== undefined ? (
                    <div className="flex justify-center items-center border border-[#252525] px-3 py-1 rounded-lg">
                      <img
                        className="w-[15px] h-[15px] mr-2"
                        src={crossImg}
                        onClick={()=>setSelectedRelation(undefined)}
                        alt="crossImg"
                      />
                      <p className="text-[14px] font-normal">
                        {relation}
                      </p>
                    </div>
                  ) : null}
                </div>
                <div className="flex">
                  {farm_function !== undefined ? (
                    <div className="flex justify-center items-center border border-[#252525] px-3 py-1 rounded-lg">
                      <img
                        className="w-[15px] h-[15px] mr-2"
                        src={crossImg}
                        onClick={()=>setSelectedFunction(undefined)}
                        alt="crossImg"
                      />
                      <p className="text-[14px] font-normal">
                        {farmFunctions(farm_function)}
                      </p>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="flex items-center p-6 border-t border-solid border-slate-200 rounded-b-lg bg-white w-full">
              <div className="grid grid-cols-2 gap-2 w-full">
                <div></div>
                <div className="justify-end flex">
                  <button
                    className="border border-green text-green mr-10 px-5 py-1 rounded-full hover:text-white hover:bg-green"
                    type="button"
                    onClick={() => resetFilter()}
                  >
                    RESET
                  </button>
                  <button
                    className="bg-[#3EB049] text-white mr-10 px-5 py-1 rounded-full"
                    type="button"
                    onClick={() =>
                      applyFilters({
                        province:province === undefined ? "": province,
                        district:district === undefined ? "": district,
                        farmTypes:farmTypes === undefined ? "" : farmTypes,
                        farm_function: farm_function === undefined ? "" : farm_function,
                        relation: relation === undefined ? "" : relation,
                      })
                    }
                  >
                    APPLY
                  </button>
                </div>
              </div>
              <div />
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>{" "}
    </>
  );
};
