import React, { useState, useEffect } from "react";
import { BsPlus } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { _apiLogout, _fetchMetaData } from "../../api";
import { PageLayoutComp } from "../../components/PageLayoutComp";
import { RABTable } from "../../config/tables/RABTable";
import { InputOtpModal } from "../../Models/InputOtpModal";

export const RABList = () => {
  const location = useNavigate();
  let user = JSON.parse(localStorage.getItem("user_data"));
  const [metaData, setMetaData] = useState();
  const [showGeneratePo, setShowGeneratePo] = useState(false);


  useEffect(() => {
      _fetchData();
  }, []);

  async function _fetchData() {
    let metaData = await _fetchMetaData();
    setMetaData(metaData);
  }

  const routeChange = () => {
    if (!user?.permissions?.create_rab) {
      alert("Don't have Create Permission");
      return;
    }
    location("/budget-plan-rab-create");
  };

  return (
    <div className="w-screen h-screen">
      <PageLayoutComp metaData={metaData} user={user}>
        <div className="h-[100%] bg-[#E6F1ED] p-4">
          <div className="h-[7%]">
            <div className="flex justify-between">
              <p className="text-3xl font-bold mb-0">Budget Plan - RAB</p>
              <div className="flex">
                <div
                  onClick={routeChange}
                  className="flex bg-green100 items-center text-white pl-1 pr-3 rounded-md cursor-pointer"
                >                
                  <BsPlus size={25} />
                  <p className="text-sm">Add RAB</p>
                </div>
              </div>
            </div>
          </div>
          <div className="h-[100%]">
            <div className="h-[calc(100%-50px)] bg-white p-3">
                <RABTable
                  token={user.token}
                  metaData={metaData}
                  />
            </div>
          </div>
        </div>
      </PageLayoutComp>
    </div>
  );
};
