import React, { useEffect } from "react";
import { useState } from "react";
import WeeklyReportDetail from "../../../components/WeeklyReportDetail";
import { PageLayoutComp } from "../../../components/PageLayoutComp";
import {
  _getAvailableWeeksWeeklyReport,
  _getAvailableYearsWeeklyReport,
  _getYearsforWeeklyReport,
  _getWeeksforWeeklyReport,
  _getUnsubmittedFarmWiseWeeklyReport,
  _getFarmWeeklyReportFarmWise,
  _getFarmWiseWeeklyReportAnalyticData,
  _getCropWiseWeeklyReport,
  _getListofProductsForAWeek,
  _getCropWiseWeeklyReportAnalyticData,
  _getCropWiseUnsubmittedWeeklyReport,
} from "../../../api";

import { useNavigate } from "react-router-dom";
import { ImageSliderModal } from "../../../Models/ImageSlider";
import { getDateFormatMoment } from "../../../config/localdata";
import info_icon from "../../../images/info_icon.png";
import { TablePaginationComp } from "../../../components/TablePaginationComp";

import person_img from "../../../images/person.png";
import drop_icon from "../../../images/Drop_icon.png";
import submit_Doc from "../../../images/iconoir_submit-doc.png";
import rain_gray from "../../../images/rain_gray.png";
import { IoIosArrowForward } from "react-icons/io";
import WeeklyReportCropDetail from "../../../components/WeeklyReportCropDetail";

const dataTypes = {
  farm: 1,
  crop: 2,
};

export const WeeklyReport = () => {
  const size = 10;
  const [showDetails, setShowDetails] = useState(false);
  let user = JSON.parse(localStorage.getItem("user_data"));
  const [analyticDataFarmWise, setAnalyticDataFarmWise] = useState([]);
  const [detailPageData, setDetailPageData] = useState({});

  const location = useNavigate();

  const [showCropReport, setShowCropReport] = useState(false);
  const [images, setImages] = useState([]);

  const [availableWeeks, setAvailableWeeks] = useState([]);
  const [availableYears, setAvailableYears] = useState([]);
  const [farmWiseData, setFarmWiseData] = useState([]);
  const [farmWiseUnsubmittedData, setFarmWiseUnsubmittedData] = useState([]);
  const [totalPage, setTotalPage] = useState();
  const [totalItems, setTotalItems] = useState();
  const [selectedPage, setSelectedPage] = useState(0);

  const [cropWiseProductInfo, setCropWiseProductInfo] = useState({});
  const [week, setWeek] = useState();
  const [year, setYear] = useState();

  const [showToolTipFarmWise, setShowToolTipFarmWise] = useState(false);

  const [cropWiseData, setCropWiseData] = useState([]);
  const [cropWiseUnsubmittedData, setCropWiseUnsubmittedData] = useState([]);

  const [selectedCrop, setSelectedCrop] = useState("");
  const [listOfProducts, setListOfProducts] = useState([]);

  /////Updated Codes
  const [farmWise, setFarmWise] = useState(true);
  const [unsubmitted, setUnsubmitted] = useState(false);

  const getAnalyticsData = async (year, week) => {
    let analyticsData = await _getFarmWiseWeeklyReportAnalyticData(year, week);
    setAnalyticDataFarmWise(analyticsData);
  };

  const getFarmWiseListData = async (year, week, p) => {
    let params = {
      size: size,
      page: p + 1,
    };
    let farmData = await _getFarmWeeklyReportFarmWise(year, week, params);
    setFarmWiseData(farmData?.displayData);
    setTotalPage(farmData?.totalPage);
    setTotalItems(farmData?.totalItems);
  };

  const getData = async () => {
    let years = await _getYearsforWeeklyReport();
    setAvailableYears(years);
    let weeks = await _getWeeksforWeeklyReport(years[0]);
    setAvailableWeeks(weeks);
    setWeek(weeks[0]?.week_number);
    setYear(years[0]);
    getAnalyticsData(years[0], weeks[0]?.week_number);
    getFarmWiseListData(years[0], weeks[0]?.week_number, selectedPage);
    getFarmWiseListUnsubmittedData(years[0], weeks[0]?.week_number);
  };

  //Fetch Intial Data, only Farm-Wise
  useEffect(() => {
    getData();
  }, []);

  //Fetch Data as per selected DataType, Week, Year
  useEffect(() => {
    if (!farmWise) {
      //Crops List Data
      getListOfProducts(year, week);
    } else {
      // Farm List Data
      getFarmWiseListData(year, week, selectedPage);
      getAnalyticsData(year, week);
    }
  }, [farmWise, year, week, selectedPage]);

  const changeDataType = (type) => {
    setFarmWise(type == dataTypes.farm);
    setUnsubmitted(false);
  };

  const handleWeekChange = (event) => {
    setWeek(event.target.value);
    setSelectedCrop("");
    // setUnsubmitted(false);
  };

  const handleYearChange = (e) => {
    setYear(e.target.value);
    setSelectedCrop("");
    // setUnsubmitted(false);
  };

  const getFarmWiseListUnsubmittedData = async (year, week) => {
    let farmData = await _getUnsubmittedFarmWiseWeeklyReport(year, week);
    setFarmWiseUnsubmittedData(farmData?.displayData);
  };

  async function getListOfProducts(year, week) {
    const data = await _getListofProductsForAWeek(year, week);
    setListOfProducts(data?.data);
  }

  const handleCropChange = (e) => {
    setSelectedCrop(e.target.value);
    getCropWiseData(e.target.value);
  };

  const getCropWiseData = async (id, p=0) => {
    if (id) {
      let params = {
        size: size,
        page: p + 1,
      };
      const data = await _getCropWiseWeeklyReport(id, year, week, params);
      setCropWiseData(data?.displayData);
      setTotalPage(data?.totalPage);
      setTotalItems(data?.totalItems);
      setCropWiseProductInfo(data?.productData);

      const analyticsData = await _getCropWiseWeeklyReportAnalyticData(
        id,
        year,
        week
      );
      setAnalyticDataFarmWise(analyticsData);
      let farmData = await _getCropWiseUnsubmittedWeeklyReport(id, year, week);
      setCropWiseUnsubmittedData(farmData);
    }
  };

  const ShowFarmWiseData = () => {
    return (
      !farmWiseData ?
      <div className="flex justify-center items-center"><p>No data found</p></div>:
      <>
        {farmWiseData &&
          farmWiseData?.map((data, index) => {
            console.log("data=====", data)
            return (
              <div className={`box-border shadow-sm ${data?.summary?.any_pests_issues && data?.summary?.any_other_issues ? 'bg-[#FEF4F6]' :'bg-[#ECF7ED]'}  rounded-[10px] w-[calc(100%-30px)] mx-2 my-2 mb-3 border-2 border-slate-200 `}>
                <div className="text-black mt-1 mx-2 w-full flex items-center gap-[20%]">
                  <p className="items-center font-bold text-pretty w-[30%]">
                    {data?.summary?.farm_info?.farm_name}
                  </p>
                  <p className="font-extralight w-[60%]">
                    <span className="text-right ">
                      {getDateFormatMoment(
                        data?.summary?.general_report_last_updated,
                        true
                      )}
                    </span>{" "}
                  </p>
                </div>
                <div className="flex flex-row p-2">
                  <div className="basis-1/3 ">
                    <div className="flex items-center">
                      <img
                        src={person_img}
                        className="w-[0.875rem] h-[1.063rem] mr-2"
                      />
                      <p className="text-[#252525] ">
                        {" "}
                        {data?.summary?.general_report_created_by?.user_name}
                      </p>
                    </div>
                    <div className="flex mt-1 ">
                      <img
                        src={drop_icon}
                        className="w-[0.813rem] h-[0.875rem] mr-2 mt-1"
                      />
                      <div className=" ">
                        {data?.products_info.slice(0, 2).map((product) => {
                          return (
                            <p className="text-[#252525] mb-[3px]">
                              {product?.product_name}
                            </p>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="w-0.5 h-16 bg-[#D0D0D0]"></div>

                  <div className="basis-1/5 ml-4">
                    <div className="flex">
                      <img
                        src={submit_Doc}
                        className="mt-1 mr-2 w-[1rem] h-[1rem]"
                      />
                      <p className="  text-[#252525] ">
                        <span className=" font-bold">
                          {data?.summary?.total_crops_reported}
                        </span>
                        /{data?.products_info?.length}
                      </p>
                    </div>
                    <div className="flex mt-1 ">
                      <img
                        src={rain_gray}
                        className=" mr-2 w-[1rem] h-[1rem]"
                      />
                      <p className="text-[#252525]">
                        {data?.summary?.is_rained ? "Rain" : "Not Rain"}
                      </p>
                    </div>
                  </div>
                  <div className="w-0.5 h-16 bg-[#D0D0D0]"></div>
                  <div className="basis-1/5  px-2  ">
                    <div className=" w-full h-full flex  gap-2">
                      <div className="w-full  flex flex-col ">
                        <p>Pest/Disease</p>
                        <p>Issue</p>
                        <p>Affected</p>
                      </div>

                      <div className="flex flex-col ">
                        <p>:</p>
                        <p>:</p>
                        <p>:</p>
                      </div>

                      <div className="w-full flex flex-col justify-start font-bold">
                        <p>{data?.summary?.any_pests_issues ? "Yes" : "No"}</p>
                        <p>{data?.summary?.any_other_issues ? "Yes" : "No"}</p>
                        <p>
                          {parseInt(data?.summary?.veg_mortality_affect) +
                            parseInt(data?.summary?.gen_mortality_affect)}
                          %
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="w-0.5 h-14 bg-[#D0D0D0]"></div>
                  <div>
                  <div className="basis-1/3 ml-10 mt-0 flex overflow-x-auto">
                    {data?.summary?.general_report_pictures ? (
                      data?.summary?.general_report_pictures.map(
                        (item, index) => {
                          return (
                            index < 4 && (
                              <img
                                key={item?.id}
                                className="mr-3 w-[60px] h-[60px] rounded-[10px]"
                                src={item?.doc_link}
                                onClick={() => {
                                  handleImgClick(
                                    data?.summary?.general_report_pictures
                                  );
                                }}
                              />
                            )
                          );
                        }
                      )
                    ) : (
                      <p className="text-[14px] text-center mr-10 ">
                        No Data To Show
                      </p>
                    )}
                  </div>
                  
                  <p className="ml-10 mt-1 text-[15px]">{`Submitted ${data?.summary?.kms_from_farm} km from farm`}</p>
                  </div>

                  <div className="w-0.5 h-14 bg-[#D0D0D0]"></div>

                  <div className="ml-4  mt-4">
                    <IoIosArrowForward
                      onClick={() => handleShowDetail(index)}
                    />
                  </div>
                </div>
              </div>
            );
          })}
      </>
    );
  };
  // Iss scope mai hi changes karne hai  -- akash
  const ShowCropWiseData = () => {
    return (
      !selectedCrop ?
      <div className="flex justify-center items-center"><p>Select Crop to view data</p></div>:
      <>
        {cropWiseData &&
          cropWiseData?.map((data, index) => {
            return (
              <div className={`box-border shadow-sm ${data?.summary?.any_pests_issues && data?.summary?.any_other_issues ? 'bg-[#FEF4F6]' :'bg-[#ECF7ED]'}  rounded-[10px] w-[calc(100%-30px)] mx-2 my-2 mb-3 border-2 border-slate-200`}>
                <div className="text-black mt-1 mx-1 w-full flex items-center gap-[30%]">
                  {/* <p className=" items-center font-bold">
                    {data?.summary?.farm_info?.farm_name}
                  </p> */}
                  <p className="font-extralight mx-auto my-0  ">
                    <span className="text-right ">
                      {getDateFormatMoment(
                        data?.summary?.general_report_last_updated,
                        true
                      )}
                    </span>{" "}
                  </p>
                </div>
                <div className="flex flex-row p-2">
                  <div className="basis-1/3 ">
                    <div className="flex items-center">
                      <div className="bg-[#D7EFD9] rounded-md mr-2 ">
                        <img
                          className="w-[60px] h-[60px] bg-transparent rounded-[10px] mix-blend-multiply"
                          src={cropWiseProductInfo?.product_image}
                        />
                      </div>

                      <div>
                        <p className=" items-center font-bold">
                          {data?.summary?.farm_info?.farm_name}
                        </p>

{/* 
                        <div className="mb-[0.1rem] flex items-center">
                    <img
                      src="https://i.ibb.co/yk0DGr6/your-image.jpg"
                      alt="checking"
                      className="w-4 h-4 "
                      style={{ display: "inline", marginRight: "0.1rem" }}
                    />
                    <span>{farm}</span>
                  </div>
 */}

                        <div className=" flex items-center">
                          <img
                            src={person_img}
                            className="w-[0.875rem] h-[1.063rem] mr-2"
                            style={{ display: "inline" }}
                          />

                          <p className="text-[#252525] ">
                            {" "}
                            {
                              data?.summary?.general_report_created_by
                                ?.user_name
                            }
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-0.5 h-16 bg-[#D0D0D0]"></div>

                  <div className="basis-1/5 ml-4">
                    {/* <div className="flex">
                      <img
                        src={submit_Doc}
                        className="mt-1 mr-2 w-[1rem] h-[1rem]"
                      />
                      <p className="  text-[#252525] ">
                        <span className=" font-bold">
                          {data?.summary?.total_crops_reported}
                        </span>
                        /{data?.products_info?.length}
                      </p>
                    </div> */}



                    <div className=" flex justify-center item-center">
                          <img
                            src={rain_gray}
                            className="w-[1rem] h-[1rem] mr-2"
                            style={{ display: "inline" }}
                          />

                          <p className="text-[#252525] ">
                            {" "}
                            {data?.summary?.is_rained ? "Rain" : "Not Rain"}
                          </p>
                        </div>






                  </div>
                  <div className="w-0.5 h-16 bg-[#D0D0D0]"></div>
                  <div className="basis-1/5  px-2  ">
                    <div className=" w-full h-full flex  gap-2">
                      <div className="w-full  flex flex-col ">
                        <p>Pest/Disease</p>
                        <p>Issue</p>
                        <p>Affected</p>
                      </div>

                      <div className="flex flex-col ">
                        <p>:</p>
                        <p>:</p>
                        <p>:</p>
                      </div>

                      <div className="w-full flex flex-col justify-start font-bold">
                        <p>{data?.summary?.any_pests_issues ? "Yes" : "No"}</p>
                        <p>{data?.summary?.any_other_issues ? "Yes" : "No"}</p>
                        <p>
                          {parseInt(data?.summary?.veg_mortality_affect) +
                            parseInt(data?.summary?.gen_mortality_affect)}
                          %
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="w-0.5 h-14 bg-[#D0D0D0]"></div>
                  <div className="basis-1/3 ml-10 mt-0 flex overflow-x-auto">
                    {data?.summary?.general_report_pictures ? (
                      data?.summary?.general_report_pictures.map(
                        (item, index) => {
                          return (
                            index < 4 && (
                              <img
                                key={item?.id}
                                className="mr-3 w-[60px] h-[60px] rounded-[10px]"
                                src={item?.doc_link}
                                onClick={() => {
                                  handleImgClick(
                                    data?.summary?.general_report_pictures
                                  );
                                }}
                              />
                            )
                          );
                        }
                      )
                    ) : (
                      <p className="text-[14px] text-center mr-10 ">
                        No Data To Show
                      </p>
                    )}
                  </div>

                  <div className="w-0.5 h-14 bg-[#D0D0D0]"></div>

                  <div className="ml-4  mt-4">
                    <IoIosArrowForward
                      onClick={() => handleShowDetail(index)}
                    />
                  </div>
                </div>
              </div>
            );
          })}
      </>
    );
  };

  const ShowCropWiseDetailsData = () => {
    return (
      <WeeklyReportCropDetail
        weekData={availableWeeks.filter((w) => w.week_number == week)[0]}
        handleShowDetail={handleShowDetail}
        detailPageData={detailPageData}
        farmsData={cropWiseUnsubmittedData}
        cropWiseProductInfo={cropWiseProductInfo}
      />
    );
  };

  console.log("analyticDataFarmWise------",analyticDataFarmWise)

  
  const ShowFarmWiseDetailsData = () => {
    return (
      <WeeklyReportDetail
        weekData={availableWeeks.filter((w) => w.week_number == week)[0]}
        handleShowDetail={handleShowDetail}
        detailPageData={detailPageData}
      />
    );
  };

  const handleShowDetail = (val) => {
    if (val !== null) {
      if (!farmWise) {
        setDetailPageData(cropWiseData[val]);
      } else {
        debugger;
        setDetailPageData(farmWiseData[val]);
      }
      setShowDetails(true);
    } else {
      setShowDetails(false);
      setDetailPageData(null);
    }
  };

  const handleImgClick = (item) => {
    setImages(item);
    setShowCropReport(true);
  };

  return (
    <div className="w-screen h-screen">
      <PageLayoutComp user={user}>
        {showCropReport ? (
          <ImageSliderModal
            hideModal={() => {
              setShowCropReport(false);
            }}
            images={images}
          />
        ) : null}

        {!showDetails ? (
          <>
            {/* Top Headers */}
            <div className="text-black">
              <div>
                <p className="text-3xl  font-semibold  pt-2 ">
                  Administration - Farm Weekly Report
                </p>
              </div>
              <div className="mt-2">
                <div className="rounded-xl ">
                  <div className="bg-white flex justify-between p-[20px]">
                    <div className="flex">
                      {/* Farm Crop Sections */}
                      <div className="flex">
                        <button
                          type="button"
                          onClick={() => {
                            changeDataType(dataTypes.farm);
                            setSelectedCrop("")
                            setShowToolTipFarmWise(false)
                            setTotalPage()
                            setTotalItems()
                          }}
                          className={`${
                            farmWise
                              ? "bg-[#40AE49] text-white z-20"
                              : "bg-white text-gray-800 -z-10"
                          } px-6 py-2 border border-gray-300 rounded-lg `}
                          style={{
                            zIndex: farmWise ? 2 : 1,
                            marginRight: "-1rem",
                          }}
                        >
                          Farm
                        </button>
                        <button
                          type="button"
                          onClick={() => {
                            changeDataType(dataTypes.crop);
                            setSelectedCrop("")
                            setShowToolTipFarmWise(false)
                            setTotalPage()
                            setTotalItems()
                          }}
                          className={`${
                            !farmWise
                              ? "bg-[#40AE49] text-white z-20"
                              : "bg-white text-gray-800 -z-10"
                          } px-6 py-2 border border-gray-300 rounded-lg `}
                          style={{ zIndex: !farmWise ? 2 : 1 }}
                        >
                          Crop
                        </button>
                      </div>

                      {/* Crops Dropdown */}
                      {!farmWise ? (
                        <div>
                          <select
                            onChange={handleCropChange}
                            className=" ml-5 border w-[300px] h-[44px]   border-[#808080] py-2 px-4 pr-8 rounded-[10px] "
                            value={selectedCrop}
                          >
                            <option value="" disabled selected>
                              Select Crop{" "}
                            </option>
                            {listOfProducts &&
                              listOfProducts.map((data) => {
                                return (
                                  <option key={data?.id} value={`${data?.id}`}>
                                    {data?.product_name}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      ) : (
                        ""
                      )}

                      {/* Unsubmitted Button 
                      <div>
                        <div className="flex  font-normal justify-center text-sm items-center ml-5  rounded-[8px] border border-gray-[#808080] h-[44px] w-[200px]">
                          Unsubmitted
                          <label className="relative ml-1 inline-flex cursor-pointer">
                            <input
                              type="checkbox"
                              checked={unsubmitted === true ? 1 : 0}
                              className="sr-only peer"
                              onChange={() => setUnsubmitted(!unsubmitted)}
                            />
                            <div className="w-11 h-6 bg-[#E8E9EB] rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-[#3EB049]"></div>
                          </label>
                        </div>
                      </div>*/}
                    </div>

                    {/* Year and Week Selections */}
                    <div>
                      <div className="flex">
                        <select
                          onChange={handleWeekChange}
                          className="flex items-center justify-between border w-[250px] h-[44px] border-[#808080] py-2 px-4 pr-8 rounded-[10px]"
                        >
                          {availableWeeks?.map((week) => {
                            return (
                              <option value={`${week?.week_number}`}>
                                <span>{"Week " + week?.week_number} </span>{" "}
                                <span>:</span>{" "}
                                <span>{week?.week_date_range}</span>
                              </option>
                            );
                          })}
                        </select>
                        <div className="w-0.5 mx-3 bg-[#808080]"></div>
                        <select
                          onChange={handleYearChange}
                          className=" border w-[152px] h-[44px]   border-[#808080] py-2 px-4 pr-8 rounded-[10px] "
                        >
                          {availableYears?.map((year) => {
                            return <option value={`${year}`}>{year}</option>;
                          })}
                        </select>
                      </div>
                    </div>
                  </div>

                  {/* Analysis Headers Data */}
                  {!unsubmitted && (
                    <div className="pb-3 w-full bg-white flex flex-row justify-between">
                      <div
                        className={`ml-2 ${
                          farmWise ? "w-[100%]" : "w-[60%]"
                        } grid grid-cols-3 gap-4`}
                      >
                        <div className="shadow-[0_3px_10px_rgb(0,0,0,0.2)] shadow-gray-300 rounded-xl">
                          <p className="font-semibold text-[1rem] p-5">
                            Report Submitted
                          </p>
                          <div className="flex justify-center items-center">
                          <div className="flex self-center">
                          {
                            (!selectedCrop && !farmWise) || !farmWiseData ? <p className=" text-center font-semibold leading-[4rem] text-[3rem] font-poppins text-[#40AE49]">-</p>:<p className=" text-center  font-semibold leading-[4rem] text-[3rem] font-poppins text-[#40AE49]">
                              {
                                analyticDataFarmWise?.total_farms_reports_submitted
                              }
                              <span
                                className="text-[1rem] text-gray-500 leading-[0.5rem] font-poppins  mr-2"
                              >
                                {analyticDataFarmWise?.total_farms_active_plantings && "/" +
                                  analyticDataFarmWise?.total_farms_active_plantings +
                                  " Farms"}
                              </span>
                            </p> 
                          }
                           {analyticDataFarmWise?.total_farms_active_plantings && farmWise && 
                              <div className="inline">
                                <img onClick={()=>{setShowToolTipFarmWise(!showToolTipFarmWise)}} className="inline relative mt-8" src={info_icon} />
                                {showToolTipFarmWise && 
                                <div className="absolute z-10 w-100 p-4 bg-white border border-lime-400 rounded-lg shadow hover:bg-gray-100">
                                  <p class="mb-2 text-sm font-bold text-black">List farm of uncompleted submit weekly report</p>
                                  <ul class=" space-y-1 text-gray-500 list-disc max-h-40 list-inside overflow-y-scroll">
                                    {farmWiseUnsubmittedData.length > 0 && farmWiseUnsubmittedData?.map(item=>{
                                        return(<li className="text-xs" key={item.farm_name}>{item.farm_name}</li>)
                                      })
                                    }
                                  </ul>
                                </div>}
                              </div>}
                          </div>
                          </div>
                        </div>
                        <div className="shadow-[0_3px_10px_rgb(0,0,0,0.2)] shadow-gray-300 rounded-lg ">
                          <p className="font-semibold text-[1rem] p-5">
                            Number of Pest / Disease
                          </p>
                          <p className="text-center font-semibold leading-[4rem] text-[3rem] font-poppins text-[#40AE49]">
                            {(!selectedCrop && !farmWise) || !farmWiseData ? '-' : analyticDataFarmWise?.total_pest_issues}
                          </p>
                        </div>

                        <div className="shadow-[0_3px_10px_rgb(0,0,0,0.2)] shadow-gray-300 rounded-lg ">
                          <p className="font-semibold text-[1rem] p-5">
                            Number of Issue
                          </p>
                          <p className="text-center font-semibold leading-[4rem] text-[3rem] font-poppins text-[#40AE49]">
                            {(!selectedCrop && !farmWise) || !farmWiseData ? '-':analyticDataFarmWise?.total_other_issues}
                          </p>
                        </div>
                      </div>

                      {!farmWise && (
                        <div className="w-[38%] shadow-[0_3px_10px_rgb(0,0,0,0.2)] shadow-gray-300 rounded-xl">
                          <p className="p-5 text-[1rem] h[50%] font-bold">
                            Yield Estimation
                          </p>
                          
                          {!(!farmWise && (selectedCrop && analyticDataFarmWise && analyticDataFarmWise?.yield_estimation_info && Object.keys(analyticDataFarmWise?.yield_estimation_info).length))
                          ?
                          <p className="font-semibold leading-[4rem] text-[3rem] font-poppins text-[#40AE49] text-center">-</p> :
                          <div className=" m-2 grid grid-cols-4 gap-3">
                            {analyticDataFarmWise &&
                              analyticDataFarmWise?.yield_estimation_info &&
                              Object.keys(
                                analyticDataFarmWise?.yield_estimation_info
                              ).map((key) => {
                                console.log('----key-->>>',key);
                                return (
                                  <div className="p-2 border shadow-gray-300 rounded-xl  w-full h-20">
                                    <p className="">{key}</p>
                                    {
                                      <p className=" font-semibold  text-[1.5rem] font-poppins text-[#40AE49] mt-2 text-center ">
                                        {
                                          analyticDataFarmWise
                                            ?.yield_estimation_info[key]?.harvest_estimate+ analyticDataFarmWise
                                            ?.yield_estimation_info[key]?.harvest_uom
                                        }
                                      </p>
                                    }
                                  </div>
                                );
                              })}
                          </div>}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* // Lists */}
            <div className="relative h-[60%] mt-2 bg-white  rounded-lg">
              <div className="relative max-h-[calc(100%-60px)] p-2 overflow-y-scroll scrollbar-hide">
                {farmWise ? ShowFarmWiseData() : ShowCropWiseData()}
              </div>
            </div>
            
            {farmWise ? (<div className="">
              {totalPage !== undefined ? (
                <TablePaginationComp
                  totalPages={totalPage}
                  active={selectedPage}
                  selectedPage={(p) => {
                    setSelectedPage(p);
                    getFarmWiseListData(year, week, p)
                  }}
                  totalItems={totalItems}
                />
              ) : null}
            </div>):
            (<div className="">
              {totalPage !== undefined ? (
                <TablePaginationComp
                  totalPages={totalPage}
                  active={selectedPage}
                  selectedPage={(p) => {
                    setSelectedPage(p);
                    getCropWiseData(selectedCrop, p)
                  }}
                  totalItems={totalItems}
                />
              ) : null}
            </div>)}
          </>
        ) : (
          <>
            {farmWise ? ShowFarmWiseDetailsData() : ShowCropWiseDetailsData()}
          </>
        )}
      </PageLayoutComp>
    </div>
  );
};
