import React, { useEffect, useState } from "react";
import { PageLayoutComp } from "../../../components/PageLayoutComp";
import { BsPlus, BsSearch } from "react-icons/bs";
import { TableProducts } from "../ProductTable";
import {
  _asyncGetEsignTaskCount,
  _asyncGetEsignTaskList,
  _asyncGetKycInfo,
  _fetchMetaData,
} from "../../../api";
import { ESignatureTable } from "../../ESignatureTable";
import filters_img from "../../../images/FarmPipeLine/filters.png";
import { useNavigate } from "react-router-dom";
import { TablePaginationComp } from "../../../components/TablePaginationComp";
import { data } from "react-phone-code";
import { getDateFormatMoment } from "../../localdata";

let timeoutRef = null;

export const ESignature = () => {
  const location = useNavigate();
  const [isWaiting, setIsWaiting] = useState(true);
  const [metaData, setMetaData] = useState();
  const [totalPage, setTotalPage] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [count, setCount] = useState(0);
  const [list, setList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [doc_sign_status, setDoc_sign_status] = useState("pending_on_me");
  const [kycInfo, setKycInfo] = useState();
  const [page, setPage] = useState(1);
  const [selectedPage, setSelectedPage] = useState(0);
  const [Data, setData] = useState([]);
  const [isComplete, setIsComplete] = useState(false);
  let user = JSON.parse(localStorage.getItem("user_data"));
  const [action, setAction] = useState("");

  async function _fetchData() {
    let metaData = await _fetchMetaData();
    setMetaData(metaData);
  }

  async function _fetchTaskCount() {
    const count = await _asyncGetEsignTaskCount();
    setCount(count);
  }

  async function _fetchKycInfo() {
    const Info = await _asyncGetKycInfo();
    setKycInfo(Info);
  }

  async function _fetchTaskList(search_text, docSign_status, pageNUm) {
    const dataResponse = await _asyncGetEsignTaskList(
      search_text,
      docSign_status,
      pageNUm
    );
    setTotalItems(dataResponse?.data?.totalItems);
    setTotalPage(dataResponse?.data?.last_page);
    setList(dataResponse?.data || {});
  }

  useEffect(() => {
    _fetchTaskCount();
    _fetchData();
    _fetchKycInfo();
    _fetchTaskList(searchText, doc_sign_status, page);
  }, []);

  const handlePress = (type) => {
    if (type === "pending") {
      setIsComplete(false);
      _fetchTaskList(searchText, "pending_on_me", page);
      setDoc_sign_status("pending_on_me");
      setData([]);
    }
    if (type === "complete") {
      setIsComplete(true);
      _fetchTaskList(searchText, "completly_signed", page);
      setDoc_sign_status("completly_signed");
      setData([]);
    }
  };

  const handleSearchSubmit = (action) => {
    setSelectedPage(0);
    if (action === "reset") {
      setSearchText("");
      setAction(action);
      _fetchTaskList("", doc_sign_status, page);
    }
    if (searchText.length > 2 && action !== "reset") {
      _fetchTaskList(searchText, doc_sign_status, page);
    }
  };

  const routeChange = () => {
    location("/farm-pipeline/e-sign-start-kyc");
  };

  function handleAddButton() {
    location("/farm-pipeline/e-sign-task-creation");
  }

  console.log("kycInfokycInfokycInfokycInfo", kycInfo)

  return (
    <div className="w-screen h-screen">
      <PageLayoutComp user={user}>
        <div className="h-[7%]">
          <div className="flex justify-between">
            <p className="text-3xl font-bold mb-0">
              Administration - E Signature
            </p>
            <div className="flex">
              <div
                onClick={() => handleAddButton()}
                className="flex bg-green100 items-center text-white pl-1 pr-3 rounded-md cursor-pointer"
              >
                <BsPlus size={25} />
                <p className="ml-3 text-sm">Add New E-Signature</p>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white w-[100%]  rounded-lg">
          <div className="flex flex-row p-3 justify-between items-center">
            <div>
              <p className="text-1.5xl font-bold mb-0">Dashboard</p>
            </div>
            <div className="flex flex-row justify-between ml-5 w-[65%] pr-3">
              <div className=" flex w-[80%]">
                <div className="border rounded-l-md text-center border-borderColor h-[2.2rem] w-full flex  items-center">
                  {/* <BsSearch className="ml-2" color="#8A9099" /> */}
                  <input
                    className="ml-3 w-full outline-none border-none text-sm "
                    type="text"
                    value={searchText}
                    placeholder="Search document.."
                    onChange={(text) => {
                      setSearchText(text.target.value);
                      // clearTimeout(timeoutRef)
                      // timeoutRef = setTimeout(() => {
                      //   _fetchTaskList(text.target.value,doc_sign_status,page)
                      // }, 1000);
                    }}
                  />
                </div>
                <button
                  type="button"
                  className="flex bg-[#3EB049] px-4 py-1  h-[2.2rem] items-center rounded-r-md shadow-black "
                  onClick={() => handleSearchSubmit("find")}
                >
                  <p className="text-white mr-2 text-sm">
                    {" "}
                    <BsSearch className="text-lg" color="#FFFFFF" />
                  </p>
                </button>
                <button
                  type="button"
                  className="flex bg-[#3EB049] px-4 py-1     h-[2.2rem] items-center rounded-md shadow-black ml-5"
                  onClick={() => handleSearchSubmit("reset")}
                >
                  <p className="text-white mr-2 text-base">Reset</p>
                </button>
              </div>
              {/* <div className="w-[18%] flex flex-row items-center justify-around border border-borderColor h-[2.2rem] rounded-md">
                <img src={filters_img} className="w-4 h-4" />
                <p className="text-sm">Type</p>
                <img
                  src={require("../../../images/down.png")}
                  className="w-4 h-4"
                />
              </div> */}
            </div>
          </div>
          <div className="w-[100%] flex flex-row justify-between mt-3 items-center">
            <div className="w-[65%] flex flex-row p-3">
              <div
                onClick={() => handlePress("pending")}
                className={`cursor-pointer w-[23%]  h-[3.8rem] flex flex-row p-3 bg-${
                  !isComplete ? "green100" : "gray100"
                } rounded-md items-center justify-between`}
              >
                <p
                  className={`text-1.5xl text-${
                    !isComplete ? "white" : "[#808080]"
                  } mb-0`}
                >
                  Pending
                </p>
                <p
                  className={`text-2xl text-${
                    !isComplete ? "white" : "[#808080]"
                  } font-bold mb-0`}
                >
                  {count?.data?.total_pending}
                </p>
              </div>
              <div
                onClick={() => handlePress("complete")}
                className={`cursor-pointer w-[23%] ml-3 h-[3.8rem] flex flex-row p-3 bg-${
                  !isComplete ? "gray100" : "green100"
                } rounded-md items-center justify-between`}
              >
                <p
                  className={`text-1.5xl text-${
                    isComplete ? "white" : "[#808080]"
                  } mb-0`}
                >
                  Complete
                </p>
                <p
                  className={`text-2xl text-${
                    isComplete ? "white" : "[#808080]"
                  } font-bold mb-0`}
                >
                  {count?.data?.total_completed}
                </p>
              </div>
            </div>
            <div className="w-[35%] flex flex-row p-3">
              <div className="w-0.5  bg-gray100 mr-1" />
              <div className="w-[100%]  border p-3 pb-0  border-gray100  rounded-md ml-3">
                {kycInfo?.data?.id ? (
                  <div>
                    <div className="flex flex-row justify-between items-center">
                      <div className="flex flex-row">
                        <p>E-KYC</p>
                        <div
                          className={`ml-3 rounded-md flex p-1 items-center h-[1.5rem] ${
                            kycInfo?.data?.is_kyc_approved
                              ? "bg-[#D7EFD9]"
                              : "bg-yellow-100"
                          }`}
                        >
                          <p
                            className={` text-sm font-semibold px-2  ${
                              kycInfo?.data?.is_kyc_approved
                                ? `text-[#40AE49]  `
                                : `text-yellow-500`
                            }`}
                          >
                            {kycInfo?.data?.is_kyc_approved
                              ? "Valid"
                              : "Pending"}
                          </p>
                        </div>
                      </div>
                      <p className="ml-2 text-xs">
                        valid to :{" "}
                        {getDateFormatMoment(
                          kycInfo?.data?.kyc_expiry_date,
                          true
                        )}
                      </p>
                    </div>
                    <p className="text-xs mt-2">
                      {kycInfo?.data?.id}
                    </p>

                    <div>
                      <p className="text-xs mt-1">
                        VIDA sign certificate Authority
                      </p>
                      {!kycInfo?.data?.is_kyc_approved && <p onClick={() => routeChange()}
                    className=" text-right text-sm font-normal  text-green pb-2 border-b-green underline mt-2 cursor-pointer">Start KYC again</p>}
                    </div>
                  </div>
                ) : (
                  <div>
                    <p className="text-[15px] text-center pt-[16px]">
                      You don't have digital certificate
                    </p>
                    <div
                      className="text-[13px] text-center pt-[13px] text-green border-b-green border-b-[1px] cursor-pointer"
                      onClick={() => routeChange()}
                    >
                      <p>
                        Create Digital Certificate, so you can perform
                        E-signature
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="w-[100%] h-[0.1rem] bg-gray100" />
          {doc_sign_status !== "completly_signed" && (
            <div className="w-[100%] flex flex-row p-3">
              <div
                onClick={() => {
                  _fetchTaskList(searchText, "pending_on_me", page);
                  setDoc_sign_status("pending_on_me");
                }}
                className={`cursor-pointer p-3 bg-${
                  doc_sign_status === "pending_on_me" ? "[#D7EFD9]" : "white"
                } rounded-md`}
              >
                <p>Waiting for me ({count?.data?.total_pending_on_me})</p>
              </div>
              <div
                onClick={() => {
                  _fetchTaskList(searchText, "pending_on_others", page);
                  setDoc_sign_status("pending_on_others");
                }}
                className={`cursor-pointer p-3 ml-10  bg-${
                  doc_sign_status === "pending_on_others"
                    ? "[#D7EFD9]"
                    : "white"
                } rounded-md`}
              >
                <p>
                  Waiting for other ({count?.data?.total_pending_on_others})
                </p>
              </div>
            </div>
          )}
          <div className="h-[100%]">
            <div className=" overflow-y-auto bg-white p-3">
              <ESignatureTable
                Data={list?.data || []}
                // Data={Data}
                isComplete={isComplete}
                docStatus={doc_sign_status}
                token={user.token}
                metaData={metaData}
                totalPage={totalPage}
                totalItems={totalItems}
                active={page}
                onPageChange={(pageNum) => {
                  setPage(pageNum);
                  _fetchTaskList(searchText, doc_sign_status, pageNum);
                }}
              />
              {totalPage !== undefined ? (
                <TablePaginationComp
                  totalPages={totalPage}
                  active={selectedPage}
                  selectedPage={(p) => {
                    setSelectedPage(p);
                    _fetchTaskList(searchText, doc_sign_status, Number(p) + 1);
                  }}
                  totalItems={totalItems}
                />
              ) : null}
            </div>
          </div>
        </div>
      </PageLayoutComp>
    </div>
  );
};
