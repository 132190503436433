import React, { useEffect, useState } from "react";
import { HARVST_TB_COL } from "..";
import {_asyncGetAllHarvest} from "../../../api";
import { TableSearchComp } from "../../../components/TableSearchComp";
import { TablePaginationComp } from "../../../components/TablePaginationComp";
import { BsFillCaretDownFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { HarvestFilterModal } from "../../../Models/HarvestFilterModal";
import { getDateFormatMoment } from "../../localdata";
import { HiInformationCircle } from "react-icons/hi2";


export const TableHarvest = ({
  metaData,
  archive = false,
}) => {
  const size = 10;
  let colums = HARVST_TB_COL;
  const location = useNavigate();
  const configFetchRef = React.useRef(false);
  const [totalPage, setTotalPage] = useState();
  const [totalItems, setTotalItems] = useState();
  const [selectedPage, setSelectedPage] = useState(0);
  const [tableDataList, setTableDataList] = useState([]);
  const [allFarmsData, setAllFarmsData] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [togglePlFilter, setTogglePlFilter] = useState(false);
  const [filterData, setFilterData] = useState({});

  //filter data
  const [selectedProduct, setSelectedProduct] = useState();
  const [selectedFarm, setSelectedFarm] = useState();
  const [selectedStatus, setSelectedStatus] = useState();
  const [selectedType, setSelectedType] = useState();
  const [selectedMitra, setSelectedMitra] = useState();
  const [selectedTarget, setSelectedTarget] = useState();
  const [selectedHoles, setSelectedHoles] = useState();
  const [selectedAmtHarvested, setSelectedAmtHarvested] = useState();
  const [slectedPlantingDate, setSlectedPlantingDate] = useState([null, null]);
  const [slectedExpectedHarvestDate, setSlectedExpectedHarvestDate] = useState([
    null,
    null,
  ]);
  const  [csvData, setCsvData] = useState([]);
   const [action, setAction] = useState("");
   const [searchType, setSearchType] = useState('');
   const [searchText, setSearchText] = useState('');
   const [showNotesBox, setShowNotesBox] = useState();


  const handleNotesChange = (data) =>{
    setShowNotesBox(data?.id)
  }



  const colorObject = {
    2: "bg-[#F491A3]",
    1: "bg-[#D0D0D0]",
    3: "bg-[#40AE49]",
  };

  const colorObject1 = {
    2: "bg-[#F491A3]",
    1: "bg-[#40AE49]",
  };

  useEffect(() => {
    if (configFetchRef.current) return;
    configFetchRef.current = true;
    _fetchPlant(selectedPage);
  }, []);


  useEffect(() => {
    _fetchPlant(selectedPage, filterData);
  }, [togglePlFilter]);

  const addFilters = () => {
    let product_ids = [];
    let farm_ids = [];
    let harvest_status_ids = [];
    let mitra_ids = [];
    let target_harvests = [];
    let actual_planting_date_range = [];
    let actual_harvest_date_range = [];
    let holes = [];
    let total_actual_harvests = [];
    let harvest_class_ids = [];

    if (selectedProduct !== undefined) {
      product_ids.push(selectedProduct.id);
    }
    if (selectedFarm !== undefined) {
      farm_ids.push(selectedFarm.id);
    }
    if (selectedStatus !== undefined) {
      harvest_status_ids.push(selectedStatus.id);
    }
    if (selectedType !== undefined) {
      harvest_class_ids.push(selectedType.id);
    }
    if (selectedMitra !== undefined) {
      mitra_ids.push(selectedMitra.id);
    }
    if (selectedTarget !== undefined) {
      target_harvests.push(selectedTarget);
    }

    if (selectedHoles !== undefined) {
      holes.push(selectedHoles);
    }

    if (selectedAmtHarvested !== undefined) {
      total_actual_harvests.push(selectedAmtHarvested);
    }

    if (slectedPlantingDate !== undefined) {
      let startDate = new Date(slectedPlantingDate[0]);
      let endDate = new Date(slectedPlantingDate[1]);
      let s =
        startDate.getMonth() +
        1 +
        "/" +
        startDate.getDate() +
        "/" +
        startDate.getFullYear();
      let e =
        endDate.getMonth() +
        1 +
        "/" +
        endDate.getDate() +
        "/" +
        endDate.getFullYear();
      s !== "1/1/1970" && actual_planting_date_range.push(s);
      e !== "1/1/1970" && actual_planting_date_range.push(e);
    }

    if (slectedExpectedHarvestDate !== undefined) {
      let startDate = new Date(slectedExpectedHarvestDate[0]);
      let endDate = new Date(slectedExpectedHarvestDate[1]);
      let s =
        startDate.getMonth() +
        1 +
        "/" +
        startDate.getDate() +
        "/" +
        startDate.getFullYear();
      let e =
        endDate.getMonth() +
        1 +
        "/" +
        endDate.getDate() +
        "/" +
        endDate.getFullYear();
      s !== "1/1/1970" && actual_harvest_date_range.push(s);
      e !== "1/1/1970" && actual_harvest_date_range.push(e);
    }
    
    _applyFilters({
      product_ids,
      farm_ids,
      harvest_class_ids,
      harvest_status_ids,
      mitra_ids,
      target_harvests,
      actual_planting_date_range,
      actual_harvest_date_range,
      holes,
      total_actual_harvests,
    });
  };

  async function _fetchPlant(page, filterData) {
    let data = {
      page: page+1,
      size: size,
      ...filterData,
    };
    if(togglePlFilter){
      data = {...data,fetch_harvest_delayed:true}
    }
    if(searchType?.search_by_key && searchText){
      data[searchType?.search_by_key] = searchText;
    }
    let res = await _asyncGetAllHarvest(data, location);
    let { displayData, totalPage, totalItems } = res;
    setTableDataList(
      displayData.map((d) => {
        d.isSelectedForArchive = false;
        return d;
      })
    );
    setAllFarmsData(
      displayData.map((d) => {
        d.isSelectedForArchive = false;
        return d;
      })
    );
    setTotalItems(totalItems);
    setTotalPage(totalPage);

    let params = {
      page: 1,
      size:totalPage*size
    }
    let response = await _asyncGetAllHarvest(params, location);
    let flatData = [];
    response?.displayData.map((item)=>{
      flatData.push(flattenObject(item));
    })
    setCsvData(flatData);
  }

  function toggleFilter(event) {
    setTogglePlFilter(event?.target?.checked)
  }

  const flattenObject = (input) => {
    let result = {};
    for (const key in input) {
     if (!input.hasOwnProperty(key)) {
       continue;
     } 
     if (typeof input[key] === "object" &&!Array.isArray(input[key])) {
           var subFlatObject = flattenObject(input[key]);
           for (const subkey in subFlatObject) {
               result[key + "_" + subkey] = subFlatObject[subkey];
           }
       } else {
           result[key] = input[key];
       }
     }
      delete result.id;
      delete result.mitra_id;
      delete result.farm_id;
      delete result.farm_is_mitra_farm;
      delete result.product_id;
      //delete result.mitra_admin_role_ids;
      delete result.mitra_staff_type_id;
      delete result.is_harvest_completed
      delete result.activity_updated_at
      result.planting_date = getDateFormatMoment(result.planting_date, true);
      result.actual_planting_date = getDateFormatMoment(result.actual_planting_date, true);
      result.expected_harvest_date = getDateFormatMoment(result.expected_harvest_date, true);
      result.harvest_complete_date = getDateFormatMoment(result.harvest_complete_date, true);
      
      //result.actual_planting_date.toLocaleDateString("en-GB", {day: "numeric",month: "long",year: "numeric",}).replace(/ /g, "-");
      //result.expected_harvest_date.toLocaleDateString("en-GB", {day: "numeric",month: "long",year: "numeric",}).replace(/ /g, "-");
     return result;
   }

  function _filterData(text) {
    let newArray = allFarmsData.filter((item) =>
      item.farm_name.toLowerCase().includes(text.toLowerCase())
    );
    setTableDataList(newArray);
  }

  const resetFilter = () => {
    setShowFilter(false);
    setFilterData({});
    _fetchPlant(selectedPage, {});
    setSelectedProduct();
    setSelectedFarm();
    setSelectedStatus();
    setSelectedMitra();
    setSelectedTarget();
    setSlectedPlantingDate([null, null]);
    setSlectedExpectedHarvestDate([null, null]);
  };

  const handleSearchSubmit = (action) => {
    setAction(action)
    setSelectedPage(0)
    if (action === "reset") {
      setSearchText("");
      setSearchType("");
      _fetchPlant(0, null, filterData)
    }
    if (searchText.length > 2 && action !== "reset") {
      _fetchPlant(0, null, filterData)
    }
  }

  useEffect(()=>{
    _fetchPlant(selectedPage, null, filterData)
  },[action])

  async function _applyFilters(items) {
    setShowFilter(false);
    setFilterData({ ...items });
    let data = {
      page: selectedPage,
      size: size,
      ...items,
    };

    let res = await _asyncGetAllHarvest(data, location);
    let { displayData, totalPage, totalItems } = res;
    setTableDataList(
      displayData.map((d) => {
        d.isSelectedForArchive = false;
        return d;
      })
    );
    setAllFarmsData(
      displayData.map((d) => {
        d.isSelectedForArchive = false;
        return d;
      })
    );
    setTotalPage(totalPage);
    setTotalItems(totalItems);
  }

  return (
    <div className={`${archive ? "h-[85%]" : "h-[100%]"}`}>
      {showFilter ? (
        <HarvestFilterModal
          metaData={metaData}
          filterData={filterData}
          hideModal={() => setShowFilter(false)}
          applyFilters={(items) => _applyFilters(items)}
          resetFilter={resetFilter}
          addFilters={addFilters}
          selectedProduct={selectedProduct}
          setSelectedProduct={setSelectedProduct}
          selectedFarm={selectedFarm}
          setSelectedFarm={setSelectedFarm}
          selectedStatus={selectedStatus}
          setSelectedStatus={setSelectedStatus}
          selectedType={selectedType}
          setSelectedType={setSelectedType}
          selectedMitra={selectedMitra}
          setSelectedMitra={setSelectedMitra}
          selectedTarget={selectedTarget}
          setSelectedTarget={setSelectedTarget}
          selectedHoles={selectedHoles}
          setSelectedHoles={setSelectedHoles}
          selectedAmtHarvested={selectedAmtHarvested}
          setSelectedAmtHarvested={setSelectedAmtHarvested}
          slectedPlantingDate={slectedPlantingDate}
          setSlectedPlantingDate={setSlectedPlantingDate}
          slectedExpectedHarvestDate={slectedExpectedHarvestDate}
          setSlectedExpectedHarvestDate={setSlectedExpectedHarvestDate}
        />
      ) : null}
      <div className="h-[100%]">
        <div className="h-[10%]">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {/* <input
              type="checkbox"
              checked={allSelectedForArchive}
              className="mt-4"
              onChange={() => {
                if (tableDataList && tableDataList.length)
                  setTableDataList(
                    tableDataList.map((tData) => {
                      tData.isSelectedForArchive = !allSelectedForArchive;
                      return tData;
                    })
                  );
              }}
            ></input> */}
            {/* <div className="ml-5 mt-4">
              {someSelectedForArchive === true ? (
                <BsArchiveFill
                  size={15}
                  onClick={() => _checkArchivePermission()}
                />
              ) : (
                <BsArchive size={15} />
              )}
            </div> */}
            <div className="w-[100%] mt-4">
              <TableSearchComp
                onChange={(text) => setSearchText(text)}
                openFilter={() => setShowFilter(true)}
                openToggleFilter={(e) => toggleFilter(e)}
                showToggleBtn={true}
                toggleBtnName='Harvest Delay'
                showCSV={true}
                csvData={csvData}
                fileName={'Harvest_data.csv'}
                searchTypeValues={metaData?.searchableFeaturesWithOptions?.harvest_list}
                searchType={searchType}
                handleSearchSubmit={handleSearchSubmit}
                searchText={searchText}
                onSearchChangeType={(item) => setSearchType(item)}
              />
            </div>
          </div>
        </div>
        <div className={`h-[80%] overflow-auto scrollbar-hide mt-5`}>
          <table className="w-full">
            <thead>
              <tr className="sticky top-0">
                <td></td>
                {colums.map((col, i) => {
                  if (col.Header === "FARM ID") {
                    return;
                  }
                  return (
                    <td className="text-sm bg-white" key={`tfhk-${i}`}>
                      <div className="flex justify-center items-center py-2 border-gray-200 border-b-[1px] cursor-pointer">
                        <p title={col.Header == null ? "" : col.Header.toString()} className="text-sm text-[#3EB049]">{col?.Header}</p>
                        {/* {col.Header !== "FARM ID" && false ? (
                          <BsFillCaretDownFill className="ml-2" size={10} />
                        ) : null} */}
                      </div>
                    </td>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {tableDataList.map((data, i) => {
                return (
                  <tr
                    key={`row-${i}`}
                    className="border-gray-200 border-b-[1px]"
                  >
                    <td>
                      {/* <input
                        type="checkbox"
                        checked={data.isSelectedForArchive}
                        onChange={() => {
                          const updatedData = [...tableDataList];
                          updatedData[i].isSelectedForArchive =
                            !updatedData[i].isSelectedForArchive;
                          setTableDataList(updatedData);
                        }}
                      ></input> */}
                    </td>
                    {colums.map((col, i) => {
                      if (col.Header === "FARM ID") {
                        return;
                      }
                      if (col.Header === "Plant") {
                        return (
                          <td
                            key={`tbfc-${i}`}
                            className="py-2 text-center"
                            //onClick={() => _checkUpdatePermission(data)}
                          >
                            <p>{data?.product[col.accessor].toString()}</p>
                            <p>{data?.product["production_code"].toString()}</p>
                          </td>
                        );
                      } else if (col.Header === "Mitra") {
                        return (
                          <td key={`tbfc-${i}`} className="py-2">
                            <p className="text-sm text-center">
                              {data?.mitra[col.accessor]}
                            </p>
                          </td>
                        );
                      } else if (col.Header === "Planting Date") {
                        return (
                          <td key={`tbfc-${i}`} className="py-2">
                            <p className="text-sm text-center">
                              {data?.actual_planting_date ? getDateFormatMoment(data?.actual_planting_date) : "-"}
                            </p>
                          </td>
                        );
                      } else if (col.Header === "Harvest Date") {
                        return (
                          <td key={`tbfc-${i}`} className="py-2">
                            <p className="text-sm text-center">
                              {data?.harvest_complete_date ? getDateFormatMoment(data?.harvest_complete_date) : "-"}
                            </p>
                          </td>
                        );
                      } else if (col.Header === "Expected Harvest Date") {
                        return (
                          <td key={`tbfc-${i}`} className="py-2">
                            <p className="text-sm text-center">
                              {data?.expected_harvest_date ? getDateFormatMoment(data?.expected_harvest_date): "-"}
                            </p>
                          </td>
                        );
                      } else if (col.Header === "Farm") {
                        return (
                          <td key={`tbfc-${i}`} className="py-2">
                            <p className="text-sm text-center">
                              {data?.farm[col.accessor]}
                            </p>
                          </td>
                        );
                      } else if (col.Header === "Performance") {
                        const foodBar =
                          data?.total_actual_harvest &&
                          data?.target_harvest_quantity
                            ? (
                                (data?.total_actual_harvest /
                                  data?.target_harvest_quantity) *
                                100
                              ).toFixed(2)
                            : "-";
                        return (
                          <td key={`tbfc-${i}`} className="py-2">
                            <p className="text-sm text-center">{foodBar}</p>
                          </td>
                        );
                      } else if (col.Header === "Amount Harvested") {
                        return (
                          <td key={`tbfc-${i}`} className="py-2">
                            <p className="text-sm text-center">
                              {data?.total_actual_harvest
                                ? data?.total_actual_harvest
                                : "-"}
                            </p>
                          </td>
                        )
                      } else if (col.Header === "Notes") {
                        return (
                          <td key={`tbfc-${i}`} className="relative text-center pl-2 text-sm">
                            {data?.comments_on_harvest_completion ? <HiInformationCircle size="1.5em" color="green" className="cursor-pointer" onMouseOver={()=>handleNotesChange(data)} onMouseOut={()=>setShowNotesBox(null)}>
                            </HiInformationCircle> :"-"}
                            {showNotesBox === data?.id && 
                            <div  className="absolute z-10 min-w-[200px] p-2 bg-white border border-black rounded-lg shadow hover:bg-gray-100">
                              <p class="text-start text-[1rem] font-bold text-black text-pretty break-all">{data?.comments_on_harvest_completion}</p>
                              <div className="flex justify-between mt-[0.25rem]">
                              <p className="text-start text-[12px] text-black">{data?.late_or_early}</p>
                              <p className="text-[12px] text-[#808080]">Expected at {getDateFormatMoment(data?.harvest_complete_date)}</p>
                              </div>
                            </div>}
                          </td>
                        );
                      } else if (col.Header === "Type") {
                        const foodBar = metaData?.cropHarvestClasses.find(
                          (item) => item.id === data.harvest_class_id
                        );
                        let color = colorObject1[data.harvest_class_id];
                        return (
                          <td key={`tbfc-${i}`} className="py-1">
                            <p
                              className={`text-[10px] font-[600] text-white text-center ${color} px-1 py-1`}
                            >
                              {foodBar?.class_name}
                            </p>
                          </td>
                        );
                      } else if (col.Header === "Status") {
                        const foodBar = metaData?.plantingHarvestStatus.find(
                          (item) => item.id === data.current_harvest_status_id
                        );
                        let color = colorObject[data.current_harvest_status_id];

                        return (
                          <td key={`tbfc-${i}`} className="py-1">
                            <p
                              className={`text-[10px] font-[600] text-white text-center ${color} px-1 py-1`}
                            >
                              {foodBar?.status}
                            </p>
                          </td>
                        );
                      } else {
                        return (
                          <td key={`tbfc-${i}`} className="py-2">
                            <p
                              title={
                                data[col.accessor] == null
                                  ? ""
                                  : data[col.accessor].toString()
                              }
                              className="text-sm text-center"
                            >
                              {data[col.accessor] == null
                                ? "-"
                                : data[col.accessor].toString()}
                            </p>
                          </td>
                        );
                      }
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="flex justify-end items-center h-[10%] text-sm px-5">
          {totalPage !== undefined ? (
            <TablePaginationComp
              totalPages={totalPage}
              active={selectedPage}
              selectedPage={(p) => {
                setSelectedPage(p);
                _fetchPlant(p, filterData);
              }}
              totalItems={totalItems}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};
