import React, { useEffect, useState } from "react";
import DropDownComp from "../components/DropDownComp";
import { _asyncGetAllFarms, _asyncGetMerchandiserUnassigned, _asyncGetSuperMarkets } from "../api";
import closeImg from "../images/FarmPipeLine/close.png";
import SearchDropDownComp from "../components/SearchDropDownComp";
import Multiselect from "multiselect-react-dropdown";

export const GetReportModal = ({
  hideModal,
  supermarketList,
  selectedSuperMarketForReport,
  handleSuperMarketDownloadReport,
  downloadReport,
  months,
  handleMonthDownloadReport,
  selectedMonth

}) => {
    



 


  return (
    <>
      <div className="flex overflow-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center">
        <div className="w-[45%] relative mt-[3%] mb-[3%]">
          {/*content*/}
          <div className="h-full first-letter:rounded-lg shadow-lg relative flex flex-col w-full outline-none focus:outline-none rounded-lg">
            <div className="flex items-center relative pl-6 pr-6 overflow-auto pt-3 bg-white rounded-t-lg h-full">
              <p className="text-[18px] w-full font-bold mb-5 mt-3">
                Get Report
              </p>
              <img
                className="float-right w-[30px] h-[30px]"
                src={closeImg}
                alt="closeImg"
                onClick={() => {
                  hideModal();
                }}
              />
            </div>
            <div className="py-3 rounded-b-lg bg-white w-full px-6"> 

           <div className="flex flex-row"> 
            <div className="flex flex-col">
            <p className="font-semibold mb-2">Supermarket</p>
            <select
              className=" border h-[2.5rem] border-[#808080] py-2 px-4 pr-8 rounded-[10px] "
              value={selectedSuperMarketForReport}
              onChange={handleSuperMarketDownloadReport}>
              <option selected>Select Supermarket</option>
              {supermarketList.map((item) => {
                return (
                  <option value={`${item?.id}`}>{item?.name}</option>
                );
              })}
            </select>
           </div>
           <div className="flex flex-col ml-5">
           <p className="font-semibold mb-2">Month</p>
            <select
              className=" border h-[2.5rem] border-[#808080] py-2 px-4 pr-8 rounded-[10px] "
              value={selectedMonth}
              onChange={handleMonthDownloadReport}>
              <option selected>Select Month</option>
              {months.map((item, index) => {
                return (
                  <option value={index+1}>{item}</option>
                );
              })}
            </select>
           </div>
           </div> 
           <div className="flex justify-end mt-10">
                <p
                  onClick={() => downloadReport()}
                  className="bg-[#3EB049] text-white px-10 py-2 border border-[#3EB049] rounded-md cursor-pointer"
                >
                  Download CSV
                </p>
              </div>
           </div>
           
            
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>{" "}
    </>
  );
};
