import React, { useState, useEffect } from "react";
import back from "../../images/FarmPipeLine/back.png";
import {
  formatDateGetDayOnly,
  getDateFormatMoment,
} from "../../config/localdata";
import { ImageSliderModal } from "../../Models/ImageSlider";
import { _asyncPlantingReportDetails } from "../../api";
import { toast } from "react-toastify";
import { PageLayoutComp } from "../../components/PageLayoutComp";
import { useNavigate, useParams } from "react-router-dom";
import rightArrow from "../../images/Vector.png";

export const CropTrackerLogBook = () => {
  const [showCropReport, setShowCropReport] = useState(false);
  const [images, setImages] = useState([]);
  const [logData, setLogData] = useState([]);
  const location = useNavigate();
  let user = JSON.parse(localStorage.getItem("user_data"));

  const params = useParams();

  const handleImgClick = (item) => {
    setImages(item);
    setShowCropReport(true);
  };

  useEffect(() => {
    fetchDataforLogBook();
  }, []);

  const fetchDataforLogBook = async () => {
    const res = await _asyncPlantingReportDetails(params?.id);
    if (res.length > 0) {
      setLogData(res);
    } else {
      location("/crops-tracker");
      toast.info("No Planting Log Book Report Found");
    }
  };

  const hideModal = () => {
    location("/crops-tracker");
  };

  console.log("logDatalogDatalogData", logData);

  const styleDot = {
    height: "10px",
    width: "10px",
    borderRadius: "50%",
  };

  return (
    <>
      {showCropReport ? (
        <ImageSliderModal
          hideModal={() => {
            setShowCropReport(false);
          }}
          images={images}
        />
      ) : null}
      
      <PageLayoutComp user={user}>
        <div className="h-[100%]">
          <div className="h-[7%]">
            <div className="flex justify-between">
              <p className="text-3xl font-bold mb-0">Log Book</p>
              <div
                className="cursor-pointer"
                onClick={() => {
                  hideModal();
                }}
              >
                <img
                  className="w-[25px] h-[20px] inline mr-1"
                  src={back}
                  alt="back"
                />
                <span className="text-[#40AE49]">Go to Crops Tracker</span>
              </div>
            </div>
          </div>
          <div className="h-[90%]">
            <div className="h-[calc(100%-50px)] bg-[#F9F9F9] p-3 mt-7">
              <div className="relative">
                <div className="h-full first-letter:rounded-lg  relative flex flex-col w-full outline-none focus:outline-none ">
                  <div
                    className="rounded-b-lg bg-[#F9F9F9]"
                    style={{ overflow: "auto", maxHeight: "500px" }}
                  >
                    <div className="w-full">
                      <p className="[font-family:'Poppins-Bold',Helvetica] font-bold text-[#40AE49] p-2 text-xl">
                        {logData[0]?.planting_activity?.rab_info?.rab_name}
                      </p>
                      <p className="[font-family:'Poppins',Helvetica] text-[#252525] ml-5 font-bold p-2 text-[14px]">
                        {logData[0]?.created_by_user?.user_name}
                      </p>
                      <p className="[font-family:'Poppins',Helvetica] text-[#252525] ml-5 font-bold p-2 text-[14px]">
                        {logData[0]?.planting_activity?.farm?.farm_name}
                      </p>
                      <p className="[font-family:'Poppins',Helvetica] text-[#6d6968] ml-5 p-2 text-[14px]">
                        {formatDateGetDayOnly(
                          new Date(logData[0]?.created_at)
                        ) +
                          ", " +
                          getDateFormatMoment(logData[0]?.created_at, true)}
                      </p>
                    </div>

                    <div className=" overflow-x-auto">
                      <div className="ml-5 flex">
                        <span>
                          <div
                            style={{
                              ...styleDot,
                              backgroundColor: "#40AE49",
                              marginBottom: "1rem",
                            }}
                          ></div>
                        </span>
                        {logData?.map((data, index) => {
                          return index !== 1 ? (
                            <span className="flex">
                              <hr
                                style={{
                                  backgroundColor: "#40AE49",
                                  height: "5px",
                                  width: "20.5rem",
                                  marginTop: "1.75px",
                                }}
                              ></hr>
                              <span>
                                <div
                                  style={{
                                    ...styleDot,
                                    backgroundColor: "#40AE49",
                                    marginBottom: "1rem",
                                  }}
                                ></div>
                              </span>
                            </span>
                          ) : null;
                        })}
                      </div>
                      <div className="flex mt-5">
                        {" "}
                        {logData?.reverse().map((data, index) => {
                            return (
                              <div
                                key={index}
                                className={`ml-5 mb-5 mt-1 ${data?.is_growth_stage_change && `h-[140px]`}  w-[20rem] p-1  bg-white rounded-[10px] overflow-hidden shadow-lg flex-shrink-0 flex-grow-0`}
                              >
                                <p className="[font-family:'Poppins-Medium',Helvetica] p-2 font-semibold text-[#40AE49] text-[16px]">
                                  {formatDateGetDayOnly(
                                    new Date(data?.created_at)
                                  ) +
                                    ", " +
                                    getDateFormatMoment(
                                      new Date(data?.created_at),
                                      true
                                    )}
                                </p>

                                {data?.is_growth_stage_change && (
                                  <p className="px-2 text-xs text-black font-semibold">
                                    {data?.prev_growth_stage?.stage_name +
                                      " Stage Changed"}
                                  </p>
                                )}

                                <div className="flex ml-2 mt-2 w-fit items-center justify-center gap-2  ">
                                  {data?.is_growth_stage_change ? (
                                    <>
                                      <div className="w-[120px] py-1  [font-family:'Poppins-Regular',Helvetica] bg-[#D0D0D0] rounded-md border border-solid border-[#808080] font-normal text-[#808080] text-[12px]  text-center">
                                        {data?.prev_growth_stage?.stage_name +
                                          " Stage"}
                                      </div>
                                      <img
                                        src={rightArrow}
                                        className="w-3 h-3 mx-2"
                                      />
                                      <div className="w-[120px] py-1 [font-family:'Poppins-Regular',Helvetica] border border-[#40AE49] rounded-md font-normal text-[#40AE49] text-[12px]  text-center">
                                        {data?.current_growth_stage
                                          ?.stage_name + " Stage"}
                                      </div>
                                    </>
                                  ) : (
                                    <div>
                                      <div className="w-[120px] py-1  px-[2px]  [font-family:'Poppins-Regular',Helvetica] border border-solid border-black font-normal text-black text-[12px]  text-center">
                                        {data?.current_growth_stage
                                          ?.stage_name + " Stage"}
                                      </div>
                                      <p className="[font-family:'Poppins-Regular',Helvetica] ml-2 mt-2 font-medium text-black text-[14px]">
                                        {
                                          data?.planting_activity_stage
                                            ?.stage_name
                                        }
                                      </p>
                                      <p className="[font-family:'Poppins-Regular',Helvetica] ml-2 mt-1  text-black text-[12px]">
                                        {data?.notes}
                                      </p>
                                    </div>
                                  )}
                                </div>

                                {data?.planting_pictures.length !== 0 ? (
                                  <>
                                    <hr className="mt-5"></hr>
                                    <div className="border-[#808080] border h-8 box-border mt-2 px-2 py-1 mx-2 rounded-md w-[calc(100%-10px)]">
                                      <p>{data?.details}</p>
                                    </div>
                                    <div className="flex overflow-auto mt-3">
                                      {data?.planting_pictures.map(
                                        (item, idx) => {
                                          return (
                                            <div key={idx}>
                                              <img
                                                className="p-1 w-[75px] h-[62px] rounded-lg"
                                                alt="Kangkung"
                                                src={item.doc_link}
                                                onClick={() => {
                                                  handleImgClick(
                                                    data?.planting_pictures
                                                  );
                                                }}
                                              />
                                            </div>
                                          );
                                        }
                                      )}
                                    </div>
                                  </>
                                ) : null}
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageLayoutComp>
    </>
  );
};
