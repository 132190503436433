import React, { useEffect, useState } from "react";
import { WeatherComp } from "./WeatherComp";
import { axiosClient, axiosHeader } from "../api";
import { AssignWeatherStationModal } from "../Models/AssignWeatherStationModal";
import { ConfirmationModal } from "../Models/ConfirmationModal";
import { toast } from "react-toastify";
import { SuccessfullMesg } from "../Models/SuccessfullMesg";
import { LogDataComp } from "./LogDataComp";
import loaderImage from "../images/loader_icon.gif";
import { WeatherStationListComp } from "./WeatherStationListComp";

export const WeatherStationComp = () => {
  let user = JSON.parse(localStorage.getItem("user_data"));
  const [weatherStations, setWeatherStations] = useState([]);
  const [selectedStation, setSelectedStation] = useState();
  const [selectedStationData, setSelectedStationData] = useState();
  const [showAssignModal, setShowAssignModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [selectedFarm, setSelectedFarm] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showLogData, setShowLogData] = useState(false);
  const [assign, setAssign] = useState(true);

  useEffect(() => {
    _getWeatherStations();
  }, []);



  const refreshWeatherStation = () =>{
    _getWeatherStations();
  }

  async function _getWeatherStations() {
  
    axiosClient
      .get(`admin/weather_stations?page=1&size=200`, axiosHeader())
      .then((res) => {
        if (res.status === 200) {
          if (res?.data?.data?.data) {
            if (res?.data?.data?.data.length === 0) {
              toast.error("No Weather Stations found");
            } else {
              let stationList = res?.data?.data?.data;
              setWeatherStations(stationList);
              setSelectedStation(stationList[0]);
              console.log(res?.data?.data?.data.length);
              _getWeatherStationData(stationList[0].id);
            }
          }
        }
      });
  }

  async function _assign() {
    if (assign && selectedFarm === undefined) {
      toast.error("Please select farm first");
      return;
    }
    let body = { farm_id: selectedFarm.id }
    let URL = `admin/${assign ? "assign_weather_station" : "unassign_weather_station"}/${selectedStation.id}`;
    console.log("URL", URL);
    axiosClient
      .put(URL,body,axiosHeader())
      .then((res) => {
        if (res?.status === 200) {
          setShowConfirmModal(false);
          setShowSuccessModal(true);
          setTimeout(() => {
            setShowSuccessModal(false);
            window.location.reload();
          }, 2000);
        }
      });
  }

  async function _getWeatherStationData(id) {
    axiosClient
      .get(`admin/weather_station/${id}`, axiosHeader())
      .then((res) => {
        if (res?.status === 200) {
          if (res?.data) {
            setSelectedStationData(res?.data);
          }
        } else {
          console.log("=> error users api:");
        }
      });
  }

  if (selectedStation === undefined || selectedStationData === undefined)
    return (
      <div className="flex h-[100%] justify-center items-center">
        <img src={loaderImage} />
      </div>
    );

  return (
    <div className="h-[100%] z-10">
      {showSuccessModal ? (
        <SuccessfullMesg
          label={`Weather station successfully ${
            assign ? "assigned" : "unassigned"
          }`}
          tick={true}
          type={"weatherStation"}
          onSubmit={() => setShowSuccessModal(false)}
        />
      ) : null}

      {showAssignModal ? (
        <AssignWeatherStationModal
          selectedStation={selectedStation}
          assign={assign}
          hideModal={() => setShowAssignModal(false)}
          onAssign={(item) => {
            setShowAssignModal(false);
            setShowConfirmModal(true);
            setSelectedFarm(item);
          }}
        />
      ) : null}
      {showConfirmModal ? (
        <ConfirmationModal
          label={`Are you sure to ${
            assign ? "assigned" : "unassigned"
          } the weather station
          to the selected mitra and farm ?`}
          label2={"No"}
          label3={"Yes"}
          type="weatherStation"
          hideModal={() => setShowConfirmModal(false)}
          customStyles={true}
          onConfirm={() => {
            _assign();
          }}
        />
      ) : null}
      <div className="flex scrollbar-hide w-full h-[4.5rem] [&>div]:flex-shrink-0 bg-[#D0D0D0] p-2 items-center overflow-x-scroll">
        {weatherStations.map((item) => (
          <WeatherStationListComp
            item={item}
            selectedStation={selectedStation}
            assign={(item, assign) => {
              setSelectedFarm(undefined);
              setAssign(assign);
              if (user?.permissions?.assign_unassign_ws) {
                if(assign){
                  setShowAssignModal(true)
                }else{
                  setShowConfirmModal(true);
                  setSelectedFarm(item?.farm_info[0]);
                }
              } else {
                alert("You don't have access to assign");
              }
            }}
            onSelection={(item) => {
              setSelectedStation(item);
              _getWeatherStationData(item?.id);
            }}
          />
        ))}
      </div>
      {showLogData ? (
        <LogDataComp
          selectedStation={selectedStation}
          selectedStationData={selectedStationData}
          showLogData={() => setShowLogData(false)}
        />
      ) : (
        <WeatherComp
          selectedStation={selectedStation}
          selectedStationData={selectedStationData}
          showLogData={() => setShowLogData(true)}
          refreshWeatherStation={()=>refreshWeatherStation()}
        />
      )}
    </div>
  );
};
