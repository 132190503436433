import React, { useEffect, useState } from "react";
import { TableSearchComp } from "../../../components/TableSearchComp";
import { TablePaginationComp } from "../../../components/TablePaginationComp";
import { TECollapse } from "tw-elements-react";
import { getDateFormatMoment } from "../../localdata";
import { LogDataModal } from "../../../Models/LogDataModal";
import { CropsReportModal } from "../../../Models/CropsReport";
import { _asyncGetAllCropTraker, _asyncPlantingReportDetails } from "../../../api";
import { useNavigate } from "react-router-dom";
import { DashboardFilterModal } from "../../../Models/DashboardFilterModal";
import unavailable from "../../../images/unavailable.png";
import { toast } from "react-toastify";

export const CropTracker = ({ metaData, selectedFilter }) => {
  const size = 10;
  const configFetchRef = React.useRef(false);
  const [totalPage, setTotalPage] = useState();
  const [totalItems, setTotalItems] = useState();
  const [selectedPage, setSelectedPage] = useState(0);
  const [tableDataList, setTableDataList] = useState([]);
  const [logData, setLogData] = useState();
  const [showLogData, setShowLogData] = useState(false);
  const [showCropReport, setShowCropReport] = useState(false);
  const [activeElement, setActiveElement] = useState("");
  const [showFilter, setShowFilter] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState();
  const [selectedFarm, setSelectedFarm] = useState();
  const [selectedMitra, setSelectedMitra] = useState();
  const [filterData, setFilterData] = useState({})
  const [searchText, setSearchText] = useState("")
  const [action, setAction] = useState("");
  const [searchType, setSearchType] = useState('');
  const location = useNavigate();

  const sortType = [
    { id: "1", name: "Latest" },
    { id: "2", name: "Earliest" },
  ];

  const styleDot = {
    height: '10px',
    width: '10px',
    borderRadius: '50%',
    display: 'inline-block'
  }

  const [sortValue, setSortValue] = useState({ id: "3", name: "Sort By" });

  useEffect(() => {
    if (configFetchRef.current) return;
    configFetchRef.current = true;
    _fetchProducts(selectedPage, null, filterData);
  }, []);

  useEffect(() => {
    let sort_order = sortValue?.name === "Earliest" ? "ASC" : "DESC"
    _fetchProducts(selectedPage, sort_order, filterData);
  }, [selectedFilter, sortValue]);

  const handleLogdata = (data) => {
    setLogData(data)
    setShowLogData(true)
  }

  const handleReportData = async (data) => {
    const res = await _asyncPlantingReportDetails(data?.planting_activity_id);
    if (res.length > 0) {
      setLogData(res)
      setShowCropReport(true)
    } else {
      toast.info("No planting report found");
    }
  }

  const handleLogBook = async (data) => {
    if(data?.planting_activity_id){
      const res = await _asyncPlantingReportDetails(data?.planting_activity_id);
      if (res.length > 0) {
        location('/crops-tracker/crops-tracker-log-book/'+data?.planting_activity_id)
      } else {
        toast.info("No Planting Log Book Report Found");
      }
    }
  }

  function _getPlantingStatus(id) {
    let data = "";
    data = metaData?.plantingStatus?.filter((e) => e.id === id);
    return data && data[0] && data[0]?.status;
  }

  function _getPoState(id) {
    let data = "";
    data = metaData?.allowedPOStatus?.filter((e) => e.id == id);
    return data && data[0] && data[0]?.status_name;
  }

  const handleClick = (value) => {
    if (value === activeElement) {
      setActiveElement("");
    } else {
      setActiveElement(value);
    }
  };

  async function _fetchProducts(page, sort_order, item) {
    setShowFilter(false);
    let filter_type = selectedFilter?.filter_type;
    let filter_value = selectedFilter?.value_to_send;
    let params = {
      size: size,
      page: page+1,
      [filter_type]: filter_value,
      ...item
    }
    if(searchType?.search_by_key && searchText){
      params[searchType?.search_by_key] = searchText;
    }
    const res = await _asyncGetAllCropTraker(params, location, sort_order);
    setTableDataList(res?.displayData)
    setTotalPage(res?.totalPage)
    setTotalItems(res?.totalItems)
  }

  const addFilters = () => {
    let product_ids = []
    let farm_ids = []
    let mitra_ids = []

    if (selectedProduct !== undefined) {
      product_ids.push(selectedProduct.id)
    }
    if (selectedFarm !== undefined) {
      farm_ids.push(selectedFarm.id)
    }
    if (selectedMitra !== undefined) {
      mitra_ids.push(selectedMitra.id)
    }
    setFilterData({ product_ids, farm_ids, mitra_ids })
    setSelectedPage(0)
    _fetchProducts(0, null, { product_ids, farm_ids, mitra_ids })
  }

  const resetFilter = () => {
    setShowFilter(false);
    _fetchProducts(selectedPage, null, {})
    setFilterData({})
    setSelectedProduct()
    setSelectedFarm()
    setSelectedMitra()
  }

  useEffect(()=>{
    _fetchProducts(selectedPage, null, filterData)
  },[action])

  const handleSearchSubmit = (action) => {
    setAction(action)
    setSelectedPage(0)
    if (action === "reset") {
      setSearchText("");
      setSearchType("");
      _fetchProducts(0, null, filterData, "")
    }
    if (searchText.length > 2 && action !== "reset") {
      _fetchProducts(0, null, filterData, searchText)
    }
  }

  const getProgressBarPart = (isActive = false, tile, value) => {
    let color = value ? '#40AE49' : '#808080';
    return (<span>
      <div style={{ ...styleDot, backgroundColor: color, maxHeight: '20px' }} >
        <p style={{ 'marginTop': '10px', width: '100px', fontSize: '10px' }}>{tile}</p>
        <p style={{ width: '120px', fontSize: '10px' }}>{value} &nbsp;</p>
      </div>
    </span>
    )
  };

  const getProgressBarPart2 = (isActive = false, tile, value) => {
    let color = value ? '#40AE49' : '#808080';
    return (<span style={{'marginTop': '10px'}}>
      <div style={{ display: 'inline-block', maxHeight: '20px' }} >
        <p style={{  width: '100px', fontSize: '10px' }}>{tile}</p>
        <p style={{ width: '120px', fontSize: '10px' }}>{value} &nbsp;</p>
      </div>
    </span>
    )
  };

  const getProgressBarPart1 = (isActive = false, tile, value) => {
    let color = value ? '#40AE49' : '#808080';
    return (<span>
      <div style={{ ...styleDot, backgroundColor: color, maxHeight: '20px' }} >
        {/* <p style={{ 'marginTop': '10px', width: '100px', fontSize: '10px' }}>{tile}</p>
        <p style={{ width: '120px', fontSize: '10px' }}>{value} &nbsp;</p> */}
      </div>
    </span>
    )
  }

  const getProgressBar = (data) => {
   
    
    let lineWidth = '150px';
    let lineMargin = '4px';
    const dataValue = [
      getDateFormatMoment(data?.planting_date, true),
      getDateFormatMoment(data?.actual_planting_date, true),
      getDateFormatMoment(data?.harvest_info?.harvest_created_at, true),
      getDateFormatMoment(data?.harvest_info?.po_created_at, true),
      getDateFormatMoment(data?.harvest_info?.po_received_at, true),
      getDateFormatMoment(data?.harvest_info?.draft_bill_requested_at, true),
      getDateFormatMoment(data?.harvest_info?.actual_bill_paid_at, true)]
    return (


      <div style={{ width: '100%', marginLeft: '40px', paddingRight: '60px' }}>
        <span>
          <div>
            <span>
              {getProgressBarPart1(true, 'Planned at', dataValue[0])}
              <hr style={{ backgroundColor: dataValue[1] ? '#40AE49' : '#808080', width: lineWidth, marginBottom: lineMargin, height: '3px', display: 'inline-block' }}></hr>
              {getProgressBarPart1(false, 'Planted at', dataValue[1])}
              <hr style={{ backgroundColor: dataValue[2] ? '#40AE49' : '#808080', width: lineWidth, marginBottom: lineMargin, height: '3px', display: 'inline-block' }}></hr>
              {getProgressBarPart1(false, 'Harvested at', dataValue[2])}
              <hr style={{ backgroundColor: dataValue[3] ? '#40AE49' : '#808080', width: lineWidth, marginBottom: lineMargin, height: '3px', display: 'inline-block' }}></hr>
              {getProgressBarPart1(false, 'PO Created at', dataValue[3])}
              <hr style={{ backgroundColor: dataValue[4] ? '#40AE49' : '#808080', width: lineWidth, marginBottom: lineMargin, height: '3px', display: 'inline-block' }}></hr>
              {getProgressBarPart1(false, 'Received at', dataValue[4])}
              <hr style={{ backgroundColor: dataValue[5] ? '#40AE49' : '#808080', width: lineWidth, marginBottom: lineMargin, height: '3px', display: 'inline-block' }}></hr>
              {getProgressBarPart1(false, 'Requested at', dataValue[5])}
              <hr style={{ backgroundColor: dataValue[6] ? '#40AE49' : '#808080', width: lineWidth, marginBottom: lineMargin, height: '3px', display: 'inline-block' }}></hr>
              {getProgressBarPart1(false, 'Paid at', dataValue[6])}

            </span>
            <br />
            <span>
              {getProgressBarPart2(true, 'Planned at', dataValue[0])}
              <span style={{ marginLeft: '40px' }}></span>
              {getProgressBarPart2(false, 'Planted at', dataValue[1])}
              <span style={{ marginLeft: '40px' }}></span>
              {getProgressBarPart2(false, 'Harvested at', dataValue[2])}
              <span style={{ marginLeft: '40px' }}></span>
              {getProgressBarPart2(false, 'PO Created at', dataValue[3])}
              <span style={{ marginLeft: '40px' }}></span>
              {getProgressBarPart2(false, 'Received at', dataValue[4])}
              <span style={{ marginLeft: '40px' }}></span>
              {getProgressBarPart2(false, 'Requested at', dataValue[5])}
              <span style={{ marginLeft: '40px' }}></span>
              {getProgressBarPart2(false, 'Paid at', dataValue[6])}
            </span>
          </div>

          {/* {getProgressBarPart(true, 'Planned at', dataValue[0])}
          <hr style={{ backgroundColor: dataValue[1] ? '#40AE49' : '#808080', width: lineWidth, marginBottom: lineMargin, height: '3px', display: 'inline-block' }}></hr>
          {getProgressBarPart(false, 'Planted at', dataValue[1])}
          <hr style={{ backgroundColor: dataValue[2] ? '#40AE49' : '#808080', width: lineWidth, marginBottom: lineMargin, height: '3px', display: 'inline-block' }}></hr>
          {getProgressBarPart(false, 'Harvested at', dataValue[2])}
          <hr style={{ backgroundColor: dataValue[3] ? '#40AE49' : '#808080', width: lineWidth, marginBottom: lineMargin, height: '3px', display: 'inline-block' }}></hr>
          {getProgressBarPart(false, 'PO Created at', dataValue[3])}
          <hr style={{ backgroundColor: dataValue[4] ? '#40AE49' : '#808080', width: lineWidth, marginBottom: lineMargin, height: '3px', display: 'inline-block' }}></hr>
          {getProgressBarPart(false, 'Received at', dataValue[4])}
          <hr style={{ backgroundColor: dataValue[5] ? '#40AE49' : '#808080', width: lineWidth, marginBottom: lineMargin, height: '3px', display: 'inline-block' }}></hr>
          {getProgressBarPart(false, 'Requested at', dataValue[5])}
          <hr style={{ backgroundColor: dataValue[6] ? '#40AE49' : '#808080', width: lineWidth, marginBottom: lineMargin, height: '3px', display: 'inline-block' }}></hr>
          {getProgressBarPart(false, 'Paid at', dataValue[6])} */}

          <div className="float-right">
            <span onClick={() => { handleLogdata(data?.log_data) }} style={{ height: '8px', marginLeft: '35px' }}
              className="justify-center p-2  items-center mt-4 rounded-lg cursor-pointer h-[25px] border border-gray-400"
            >Log
            </span>
            {!data?.is_mitra_farm && <span onClick={() => { handleLogBook(data) }} style={{ height: '8px', marginLeft: '5px' }}
              className="justify-center p-2 items-center mt-4 rounded-lg cursor-pointer h-[25px] border border-gray-400"
            >Log Book
            </span>
            }
            {data?.is_mitra_farm && 
             <span onClick={() => { handleReportData(data) }} style={{ height: '8px', marginLeft: '5px' }}
                className="justify-center p-2 items-center mt-4 rounded-lg cursor-pointer h-[25px] border border-gray-400"
              >Report
              </span>
            }
          </div>
        </span>
      </div>)
  }

  return (
    <div className="h-[100%]">
      {showLogData ? (
        <LogDataModal
          hideModal={() => { setShowLogData(false) }}
          logData={logData}
        />
      ) : null}

      {showCropReport ? (
        <CropsReportModal
          hideModal={() => { setShowCropReport(false) }}
          logData={logData}
        />
      ) : null}

      {showFilter ? (
        <DashboardFilterModal
          hideModal={() => setShowFilter(false)}
          resetFilter={resetFilter}
          addFilters={addFilters}
          selectedProduct={selectedProduct}
          selectedFarm={selectedFarm}
          selectedMitra={selectedMitra}
          setSelectedProduct={setSelectedProduct}
          setSelectedFarm={setSelectedFarm}
          setSelectedMitra={setSelectedMitra}
        />
      ) : null}

      <div className="h-[7%]">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className="w-[100%]">
            <TableSearchComp
              onChange={(text) => setSearchText(text)}
              openFilter={() => setShowFilter(true)}
              sortBy={true}
              sortType={sortType}
              sortValue={sortValue}
              handleSortBy={(item) => setSortValue(item)}
              searchText={searchText}
              handleSearchSubmit={handleSearchSubmit}
              searchTypeValues={metaData?.searchableFeaturesWithOptions?.crops_tracker}
              searchType={searchType}
              onSearchChangeType={(item) => setSearchType(item)}
            />
          </div>
        </div>
      </div>
      <div className="h-[73%] overflow-auto scrollbar-hide mt-5">
        {tableDataList?.map((data, i) => {
          return (
            <div id="accordionExample">
              <div className="">
                <h2 className="mb-0" id="headingOne">
                  <button
                    className={`${activeElement === data?.planting_activity_id + i &&
                      `text-primary [box-shadow:inset_0_-1px_0_rgba(229,231,235)]`
                      } group relative flex items-center bg-white px-5 py-4 text-left text-base text-neutral-800 transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none box-border h-28 w-full mt-2 p-4 border-2 border-slate-100 rounded-xl`}
                    type="button"
                    onClick={() => handleClick(data?.planting_activity_id + i)}
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    <div className="flex flex-row w-[88%]">
                      <img
                        src={data?.product_image || unavailable}
                        className="w-[65px] h-[65px] text-sm text-[#0090FF] underline cursor-pointer text-center mt-3"
                      />

                      <div className="basis-1/4 ml-10">
                        <p className="text-[14px] font-bold w-[120%]">
                          {data?.product_name}
                        </p>
                        <p className="text-[12px] font-[600]">
                          {data?.production_code}
                        </p>
                        <div className="w-[38%]">
                          <p className="text-[10px] p-0 font-[600] text-black text-center bg-[#fff] border border-[#808080]">
                            {_getPlantingStatus(data?.current_planting_status_id)}
                          </p>
                        </div>
                        <p className="text-gray text-[10px] font-[600]">
                          Planned at {getDateFormatMoment(data?.planting_date)}
                        </p>
                      </div>
                      <div className="ml-14 basis-1/4 mt-2">
                        <p className="text-[16px] font-[800]">
                          {data?.farm_name}
                        </p>
                        <p className=" text-[12px] font-[600]">{data?.user_name}</p>
                        <p className=" text-gray text-[8px] font-[600]">
                          {data?.harvest_info?.harvest_batch_id}
                        </p>
                        <p className=" text-gray text-[8px] font-[600]">
                          {data?.planting_batch_id}
                        </p>
                      </div>

                      <div className="ml-14 basis-1/4 mt-2">
                        {data?.harvest_info?.po_id ? <p className="text-black font-bold text-[12px]">
                          <b>PO</b>
                        </p> : null}
                        {data?.harvest_info?.draft_bill_id ? <p className=" text-black text-[12px] font-bold">
                          Bill
                        </p> : null}
                        <p className=" text-black text-[12px] font-bold">
                          {_getPoState(data?.harvest_info?.po_state)}
                        </p>
                      </div>

                      <div className="ml-14 basis-1/4 mt-2">
                        {data?.harvest_info?.po_id ?
                          <p className="text-black font-bold text-[12px]">
                            <b>{data?.harvest_info?.po_name}</b>
                          </p>
                          : null}
                        {data?.harvest_info?.draft_bill_id ?
                          <p className=" text-gray text-[12px] font-[600]">
                            {data?.harvest_info?.draft_bill_name}
                          </p> : null}
                      </div>
                    </div>
                    <div className="w-[10%]">
                      <p className="text-black text-[16px] font-bold">
                        {data?.total_actual_harvest + ' ' + data?.target_harvest_uom}
                      </p>
                    </div>
                    <span
                      className={`${activeElement === data?.planting_activity_id + i
                        ? `rotate-[-180deg] -mr-1`
                        : `rotate-0 fill-[#212529] dark:fill-white`
                        } ml-auto h-5 w-5 shrink-0 fill-[#336dec] transition-transform duration-200 ease-in-out motion-reduce:transition-none dark:fill-blue-300`}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="h-6 w-6"
                        color="#40AE49"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                        />
                      </svg>
                    </span>
                  </button>
                  {<TECollapse
                    show={activeElement === data?.planting_activity_id + i}
                    className="!mb-7 !rounded-b-none !shadow-none">
                    {getProgressBar(data)}
                  </TECollapse>}
                </h2>
              </div>
            </div>
          );
        })}
      </div>
      <div className="flex justify-end items-center h-[10%] mt-5 text-sm px-5">
        {totalPage !== undefined ? (
          <TablePaginationComp
            totalPages={totalPage}
            active={selectedPage}
            selectedPage={(p) => {
              setSelectedPage(p);
              _fetchProducts(p, "DESC", filterData);
            }}
            totalItems={totalItems}
          />
        ) : null}
      </div>
    </div>
  );
};
