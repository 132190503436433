import React, { useEffect, useState } from 'react'
import { IoIosArrowRoundBack } from 'react-icons/io'
import { useNavigate, useParams } from 'react-router-dom'
import { PageLayoutComp } from '../../components/PageLayoutComp';
import { PlantingLogsModal } from '../../Models/PlantingLogs';
import { _asyncPlantingDetails, _asyncPlantingLogs, _fetchMetaData } from '../../api';
import moment from 'moment';

function PlantingDetails() {
    const location = useNavigate();
    const {id} = useParams()
  const [showSaveBOMModal, setShowSaveBOMModal] = useState(false);
  const [plantingDetails, setPlantingDetails] = useState({})
  const [plantingStatus, setPlantingStatus] = useState('')
  const [plantingStage, setPlantingStage] = useState('')
  const [logData, setLogData] = useState();


    function handleBackButton() {
        location("/planting/planting-list");
      }

      useEffect(() => {
        if (id) {
          getPlantingDetails()
          
        }
      }, [id])

      useEffect(() => {
        if (plantingDetails) {
          gteMetaData()
        }
      }, [plantingDetails])
      
      const gteMetaData = async()=>{
          let metaData = await _fetchMetaData();
          let status = metaData?.plantingStatus?.find(key=>key?.id == plantingDetails?.planting_info?.current_planting_status_id)
          let stage =  metaData?.productGrowthStagesV2?.find(key =>key?.id ==plantingDetails?.planting_info?.current_growth_stage_id)
    
          setPlantingStatus(status?.status)
          setPlantingStage(stage?.stage_name)

      }

      const getPlantingDetails = async() =>{
        let response = await _asyncPlantingDetails(id)
        setPlantingDetails(response?.data?.data)
        renderTable(response?.data?.data?.skus)
        
      }

      const handleEditButton = async () =>{
        let res = await _asyncPlantingLogs(id)
        if(res?.length > 0){
          setLogData(res)
          setShowSaveBOMModal(true)
        }
     }
    function renderTable(data) {
      const tableBody = document.getElementById('sku-table-body');
      tableBody.innerHTML = ''; // Clear existing rows
      data.forEach((item, index) => {
        const row = document.createElement('tr');
        row.innerHTML = `
          <td class="py-2 px-4 border-b text-[11px]">${index + 1}</td>
          <td class="py-2 px-4 border-b text-[11px]">${item.sku_info?.name}</td>
          <td class="py-2 px-4 border-b text-[11px]">${item.product_qty}</td>
          <td class="py-2 px-4 border-b text-[11px]">${item.consumed_qty}</td>
          <td class="py-2 px-4 border-b text-[11px]">${item.sku_info?.uom}</td>
        `;
        tableBody.appendChild(row);
      });
    }

  return (
    <div className="w-screen h-screen">
    <PageLayoutComp>
      <div className="h-[100%] bg-[#E6F1ED] p-4">
      {showSaveBOMModal ?  (
        <PlantingLogsModal
        logData={logData}
        hideModal={()=>{setShowSaveBOMModal(false)}}
        />
    ) : null }
        <div className="h-[7%]">
          <div className="flex justify-between">
            <p className="text-3xl font-bold mb-0">{plantingDetails?.planting_info?.product?.product_name} ({plantingDetails?.planting_info?.product?.production_code})</p>
            <div className="flex">
              <div
                onClick={handleBackButton}
                className="flex cursor-pointer"
              >                
                 <IoIosArrowRoundBack size={22} color={"green"} className="mt-2" />
              <p className="text-green text-md mt-2 cursor-pointer font-bold">
                Back to List
              </p>
              </div>
            </div>
          </div>
        </div>
      
<div class="grid grid-cols-1 md:grid-cols-2 rounded-lg bg-white h-[80vh]">
    {/* <!-- Details Section --> */}
    <div class="p-6 shadow-md ">
        <div class="flex justify-between items-center mb-6">
            <h2 class="text-xl font-bold">Details</h2>
            <button class="px-3 py-1 bg-gray-200 rounded-lg border border-black"
            onClick={()=>handleEditButton()}>Log</button>
        </div>
        <div class="">
        <div className='flex'>
        <div className='space-y-4 w-[50%]'>
          <div class="">
               <p className='font-semibold'>Farm</p>
               <p>{plantingDetails?.planting_info?.farm?.farm_name}</p>
            </div>
           
            <div class="">
               <p className='font-semibold'>Mitra</p>
               <p>{plantingDetails?.planting_info?.mitra?.user_name}</p>
            </div>

            <div class="">
               <p className='font-semibold'>Supply Ops Executive</p>
             {plantingDetails?.planting_info?.farm?.faas_pics?.map((obj)=>{
              return(
                <p>{obj?.user_name}</p>
              )
             })}
           
            </div>
          </div>

         <div className='space-y-4'>
         <div class="">
               <p className='font-semibold'>Product</p>
               <p>{plantingDetails?.planting_info?.product?.product_name}</p>
            </div>
            <div class="">
               <p className='font-semibold'>Holes</p>
               <p>{plantingDetails?.planting_info?.area_qty || '-'}</p>
            </div>
            <div class="">
               <p className='font-semibold'>Target ({plantingDetails?.planting_info?.target_harvest_uom})</p>
               <p>{plantingDetails?.planting_info?.target_harvest_quantity}</p>
            </div>
         </div>
        </div>
            
        <div className='flex border-t mt-16 pt-4'>
        <div className='space-y-4 w-[50%]'>
          <div class="">
               <p className='font-semibold'>Planting Date</p>
               {/* <p>12 August 2024</p> */}
               <p>{moment(plantingDetails?.planting_info?.planting_date).format('DD MMMM YYYY')}</p>

            </div>
           
            <div class="">
               <p className='font-semibold'>Actual Planting Date</p>
               <p>{moment(plantingDetails?.planting_info?.actual_planting_date).format('DD MMMM YYYY')}</p>
            </div>

            <div class="">
               <p className='font-semibold'>Expected Harvest Date</p>
               <p>{moment(plantingDetails?.planting_info?.expected_harvest_date).format('DD MMMM YYYY')}</p>
            </div>
          </div>

         <div className='space-y-4'>
         <div class="">
               <p className='font-semibold'>Stage</p>
               <p>{plantingStage}</p>
            </div>
            <div class="">
               <p className='font-semibold'>Status</p>
               <p>{plantingStatus}</p>
            </div>
           
         </div>
        </div>
            
        </div>
    </div>

    {/* <!-- SKU Used Section --> */}
    <div class=" p-6 shadow-md bg-gray-100 h-[80vh] overflow-scroll">
        <h2 class="text-xl font-bold mb-3">SKU Used</h2>
        <table class="min-w-full bg-white rounded-lg overflow-hidden scrollbar-hide h-auto max-h-60vh">
      <thead className='bg-green100'>
        <tr>
          <th class="text-white pt-[5px] pb-[5px] text-[11px] border-b-2 border-gray-200">#</th>
          <th class="text-white pt-[5px] pb-[5px] text-[11px] border-b-2 border-gray-200">Item / Consumable</th>
          <th class="text-white pt-[5px] pb-[5px] text-[11px] border-b-2 border-gray-200">Quantity</th>
          <th class="text-white pt-[5px] pb-[5px] text-[11px] border-b-2 border-gray-200">Quantity used</th>
          <th class="text-white pt-[5px] pb-[5px] text-[11px] border-b-2 border-gray-200">UoM</th>
        </tr>
      </thead>
      <tbody id="sku-table-body">
    
          </tbody>
    </table>

    </div>
</div>

      </div>
    </PageLayoutComp>
  </div>
  )
}

export default PlantingDetails