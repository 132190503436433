import React, { useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import offlineWeather from "../images/offlineWeather.png";


export const WeatherStationListComp = ({
  item,
  selectedStation,
  onSelection,
  assign,
}) => {
  const [showAssign, setShowAssign] = useState(false);
  // let showAssign = false;
  // if (selectedStation?.identifier === item?.identifier) {
  //   showAssign = true;
  // }
  return (
    <div
      onClick={() => onSelection(item)}
      key={item?.id}
      className={`flex-none z-0 w-2/3 md:w-1/3 mr-3 cursor-pointer items-center justify-between ${
        selectedStation?.identifier === item?.identifier
          ? "bg-[#40AE49] text-white"
          : "bg-white text-[#40AE49]"
      } mr-3 py-2 rounded-md shadow-sm`}
    >
      <div className="flex justify-between items-center z-0 relative ">
        {showAssign ? (
          <div className="bg-white rounded-md w-[150px] shadow-md  py-1 px-2 text-black absolute right-4 z-50 text-sm">
            <p
              onClick={() => {
                setShowAssign(false);
                assign(item, true);
              }}
              className="hover:bg-gray-100 p-1"
            >
              Assign
            </p>
            {item?.farm_info[0] && <p
              onClick={() => {
                setShowAssign(false);
                assign(item, false);
              }}
              className="hover:bg-gray-100 p-1"
            >
              Un Assign
            </p>}
          </div>
        ) : null}
        <p className={`text-[16px] font-semibold ml-10`}>
          Weather Station {item?.identifier}
        </p>
        {!item?.is_active && (
          <img src={offlineWeather} className="w-5 h-5 ml-12" />
        )}
        <BsThreeDotsVertical
          onClick={() => {
            setShowAssign(!showAssign);
          }}
        />
      </div>
    </div>
  );
};
