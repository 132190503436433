import React, { useEffect, useState } from "react";
import { TableSearchComp } from "../../../components/TableSearchComp";
import { TablePaginationComp } from "../../../components/TablePaginationComp";
import { _asyncCancelPO, _asyncGetAllConsumablesInventory, _asyncGetAllConsumablesRequests } from "../../../api";
import { useNavigate } from "react-router-dom";
import { DashboardFilterModal } from "../../../Models/DashboardFilterModal";
import { IoIosArrowForward } from 'react-icons/io';
import { toast } from "react-toastify";


export const InventoryLists = ({ metaData,selectedFilter}) => {
  const size = 10;
  const configFetchRef = React.useRef(false);
  const [totalPage, setTotalPage] = useState();
  const [totalItems, setTotalItems] = useState();
  const [selectedPage, setSelectedPage] = useState(0);
  const [tableDataList, setTableDataList] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState();
  const [selectedFarm, setSelectedFarm] = useState();
  const [selectedMitra, setSelectedMitra] = useState();
  const [filterData, setFilterData] = useState({})
  const location = useNavigate();
  const [searchText, setSearchText] = useState("")
  const [searchType, setSearchType] = useState('');
  const [action, setAction] = useState("");
  const [showMore, setShowMore] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState(0)

  const sortType = [
    { id: "1", name: "Latest" },
    { id: "2", name: "Earliest" },
  ];

  const colorObject = {
    1:'border-[#F491A3] text-[#F491A3]',
    2:'border-[#252525] text-[#252525]',
    3:'border-[#252525] text-[#252525]',
    4:'border-[#252525] text-[#252525]',
    5:'border-[#252525] text-[#252525]',
    6:'border-[#252525] text-[#252525]',
    7:'border-[#252525] text-[#252525]',
    8:'border-[#252525] text-[#252525]',
    9:'border-[#252525] text-[#252525]',
    10:'border-[#252525] text-[#252525]',
    11:'border-[#252525] text-[#252525]',
    12:'border-[#40AE49] text-[#40AE49]',
  }

  const [sortValue, setSortValue] = useState({id: "3",name: "Sort By"});

  useEffect(() => {
    if (configFetchRef.current) return;
    configFetchRef.current = true;
    _fetchProducts(selectedPage, null, filterData);
  }, []);


  useEffect(() => {
    let sort_order = sortValue?.name === "Earliest" ? "ASC":"DESC"
    _fetchProducts(selectedPage, sort_order, filterData);
  }, [selectedFilter, sortValue]);



const handleRequestDetails = (item) =>{
  if(item?.consumable_requests.length > 0){
    location('/budget-plan-rab/inventory/'+item?.id)
  }else{
    toast.info("No requests are available");
  }
}

  async function _fetchProducts(page, sort_order, item) {
    setShowFilter(false);
    let params = {
      size:size,
      page:page+1,
      ...item
    }
    if(searchType?.search_by_key && searchText){
      params[searchType?.search_by_key] = searchText;
    }
    const res = await _asyncGetAllConsumablesInventory(params,location, sort_order);
    setTableDataList(res?.displayData)
    setTotalPage(res?.totalPage)
    setTotalItems(res?.totalItems)
  }
  
  useEffect(()=>{
    _fetchProducts(selectedPage, null, filterData)
  },[action]);

  const handleSearchSubmit = (action) =>{
    setAction(action)
    setSelectedPage(0)
    if(action === "reset"){
       setSearchText("");
       setSearchType("");
      _fetchProducts(0, null, filterData)
    }
    if(searchText.length > 2 && action !== "reset"){
      _fetchProducts(0, null,filterData)
    }
  }

  const addFilters = () =>{
    let product_ids = []
    let farm_ids = []
    let mitra_ids = []

    if(selectedProduct !== undefined){
        product_ids.push(selectedProduct.id)
    }
    if(selectedFarm !== undefined){
        farm_ids.push(selectedFarm.id)
    }
    if(selectedMitra !== undefined){
        mitra_ids.push(selectedMitra.id)
    }
    setSelectedPage(0)
    setFilterData({product_ids, farm_ids, mitra_ids})
    _fetchProducts(0, null, {product_ids, farm_ids, mitra_ids})
  }

  const resetFilter = () =>{
    setShowFilter(false);
    _fetchProducts(selectedPage, null, {})
    setFilterData({})
    setSelectedProduct()
    setSelectedFarm()
    setSelectedMitra()
  }

  return (
    <div className="h-[100%]">
      {showFilter ? (
        <DashboardFilterModal
          hideModal={() => setShowFilter(false)}
          resetFilter={resetFilter}
          addFilters={addFilters}
          selectedProduct={selectedProduct}
          selectedFarm={selectedFarm}
          selectedMitra={selectedMitra}
          setSelectedProduct={setSelectedProduct}
          setSelectedFarm={setSelectedFarm}
          setSelectedMitra={setSelectedMitra}
        />
      ) : null}

      <div className="h-[10%]">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className="w-[100%] mt-4">
            <TableSearchComp
              onChange={(text) => setSearchText(text)}
              openFilter={() => setShowFilter(true)}
              sortBy={true}
              sortType={sortType}
              sortValue={sortValue}
              handleSortBy={(item) => setSortValue(item)}
              searchText={searchText}
              handleSearchSubmit={handleSearchSubmit}
              searchTypeValues={metaData?.searchableFeaturesWithOptions?.consumable_inventory_list}
              searchType={searchType}
              onSearchChangeType={(item) => setSearchType(item)} 
            />
          </div>
        </div>
      </div>
      <div className="h-[95%] overflow-auto scrollbar-hide mt-5">
        {tableDataList?.map((data, i) => {
        return (
             <div onClick={()=>setSelectedIndex(i)}  id="myDiv" className={`box-border h-[${(showMore && selectedIndex ==i) ? 'auto' : '130px'}] w-full mt-2 p-2 border-2 border-slate-200 rounded-xl`}>
                  <div className="flex flex-row w-full">
                    <div className="basis-1/2 ml-10 mt-0">
                    <p className="text-[14px] text-[#40AE49] font-bold">
                      {data?.rab_name}
                    </p>
                    <p className="text-[10px] font-bold">
                      {data?.product?.product_name}
                    </p>
                    <p className="text-[10px] font-bold">
                      {data?.farm?.farm_name}
                    </p>
                    <p className="text-[10px] font-bold">
                      {data?.farm?.odoo_vendor_name}
                    </p>
                  </div>
                  <div class="w-0.5 h-12 bg-gray-300"></div>
                  <div className="basis-1/2 ml-10 mt-0">
                    <p className="text-[14px] mr-10 font-bold">
                    Consumables
                    </p>
                    {data?.consumables_inventory_info?.slice(0,(showMore && selectedIndex ==i) ? data?.consumables_inventory_info?.length:3)?.map((item)=>{
                      return(
                        item?.is_bom ?
                        <p className="text-[10px] font-bold w-full">
                          {item?.bom_data?.name} : <span className="text-[12px] text-[#000] font-bold ml-3">{item?.product_qty ? item?.product_qty:0} / {item?.total_qty}</span>
                        </p>
                        :
                         <p className="text-[10px] font-bold w-full">
                         {item?.sku?.name} : <span className="text-[12px] text-[#000] font-bold ml-3">{item?.req_quantity ? item?.req_quantity:0} / {item?.total_qty}</span>
                       </p>
                      )
                    })}
                    {
                      data?.consumables_inventory_info?.slice(3,data?.length)?.length > 0 &&
                    <div onClick={()=>{setShowMore(!showMore)}} className="max-w-[250px] h-5 ml-3 cursor-pointer">
                      <p className="text-[13px] font-bold  text-gray-600">{`${(showMore && selectedIndex ==i) ? 'hide' : `${data?.consumables_inventory_info?.slice(3,data?.length)?.length} view more` } consumables`}</p>
                    </div>
                    }
                  </div>
                 
                  <div class="w-0.5 h-12 bg-gray-300"></div>
                  <div className="basis-1/6 ml-10 mt-0">
                    <p className="text-[10px] font-bold text-[#808080]">
                    Total Request Number : {data?.consumable_requests?.length}
                    </p>
                  </div>
                  <div className="ml-8 basis-1/1 mt-4">
                    <IoIosArrowForward
                    onClick={()=>handleRequestDetails(data)}
                    />
                  </div>
                  </div> 
            </div>
           );
          })}
        </div>
      <div className="flex justify-end items-center h-[10%] mt-5 text-sm px-5">
        {totalPage !== undefined ? (
          <TablePaginationComp
            totalPages={totalPage}
            active={selectedPage}
            selectedPage={(p) => {
              setSelectedPage(p);
              _fetchProducts(p, null, filterData);
            }}
            totalItems={totalItems}
          />
        ) : null}
      </div>
    </div>
  );
};
