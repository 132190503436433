import React from "react";

export const DiscardBOMModal = ({
  hideModal,
  onConfirm,
}) => {
  
  let mainDiv = "w-[100%] px-5 bg-white";
  let btnYesStyle= "border border-[#40AE49] text-[#40AE49] border-[2px] text-[14px] font-[450px] text-center px-5 py-2 w-[100px] rounded-full ml-5";
  let btnNoStyle = "border border-[#F491A3] text-[#F491A3] border-[2px] text-[14px] font-[450px] text-center px-5 py-2 w-[100px] rounded-full";
  
  return (
    <>
      <div className="flex overflow-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center">
        <div className="flex justify-center ml-[20%]">
          {/*content*/}
          <div
            className={`h-full bg-white w-[85%] first-letter:rounded-lg shadow-lg relative flex flex-col ${mainDiv} outline-none focus:outline-none  rounded-lg`}
          >
            {/* <div className="relative pl-6 pr-6 overflow-auto pt-3 bg-white rounded-t-lg h-full">
            </div> */}
            <div className="relative pl-6 pr-6 overflow-auto pt-3 bg-white rounded-t-lg ">
              <p className="text-[15px] text-center mb-5 mt-3"> Are you sure want to cancel and discard changes ?</p>
            </div>

            <div className="flex justify-center items-center py-6 rounded-b-lg bg-white w-full">
              <div>
                <button
                  className={btnNoStyle}
                  type="button"
                  onClick={() => hideModal()}
                >
                 No
                </button>
                <button
                  className={btnYesStyle}
                  type="button"
                  onClick={() => onConfirm()}
                >
                 Yes
                </button>
              </div>
              <div />
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>{" "}
    </>
  );
};
