import React, { useEffect, useState } from "react";
import closeImg from "../images/FarmPipeLine/close.png";
import crossImg from "../images/FarmPipeLine/cross.png";
import {
    _apiFetchUsers,
    _asyncGetAllFarms,
  _asyncGetAllProducts,
  _asyncGetMitras,
} from "../api";
import SearchDropDownComp from "../components/SearchDropDownComp";
import AsyncSelect from 'react-select/async';


let timeoutRef = null

export const RabFilterModal = ({
  filterData,
  hideModal,
  metaData,
  resetFilter,
  addFilters,
  selectedStatus,
  setSelectedStatus, 
  selectedMitra,
  setSelectedMitra,
  selectedUsed,
  setSelectedUsed,
  selectedProduct, 
  setSelectedProduct,
  selectedFarm,
  setSelectedFarm
}) => {
  const metaDataDetails = metaData;
  const [allFarms, updateAllFarms] = useState([]);
  const [allProducts, updateAllProducts] = useState([]);
  const [allMitras, updateAallMitras] = useState([]);
  const typesOfUsed = [{name:'Used',typeValue:true},{name:'Un-Used',typeValue:false}]
  let user = JSON.parse(localStorage.getItem("user_data"));
  const [searchProduct, setSearchProduct] = useState("");


  const _getGetAllProducts = async (name = null) => {
    let params = {
      product_name: name
    }
    const data = await _asyncGetAllProducts(params)
    if (!data) return;
    updateAllProducts(data);
   
  }

  const handleSelectChange = (item) => {
    setSelectedProduct(item);
  };

  const handleSelectSearch = (item) => {
    setSearchProduct(item);
  };

  const loadOptions = (searchValue, callBack) => {
    clearTimeout(timeoutRef);
    timeoutRef = setTimeout(async () => {
      let params = {
        product_name: searchValue,
      };
      const data = await _asyncGetAllProducts(params);
      if (!data) return;
      updateAllProducts(data);
      callBack(data);
    }, 1000);
  };

  async function _getGetAllMitras() {
    const data = await _asyncGetMitras();
    if (!data) return;
      updateAallMitras([...data]);
  }

  async function _getGetAllFarms() {
    const data = await _asyncGetAllFarms();
    if (!data) return;
      updateAllFarms([...data]);
  }  

  useEffect(() => {
    _getGetAllProducts();
    _getGetAllFarms();
    if(user.role.role !== "Guest" )
    {_getGetAllMitras();}
  },[]);

  


  if (allProducts.length == 0 || allFarms.length === 0) return null;

  return (
    <>
      <div className="flex overflow-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-full mt-[3%] ml-[7%] mr-[7%] mb-[3%]">
          {/*content*/}
          <div className="h-full border-0 rounded-lg shadow-lg relative flex flex-col w-full outline-none focus:outline-none">
            <div className="relative p-10 overflow-auto pt-3 bg-white rounded-t-lg h-full">
              <img
                className="float-right w-[30px] h-[30px] mt-3"
                src={closeImg}
                alt="closeImg"
                onClick={() => {
                  hideModal();
                }}
              />
              <p className="font-poppins font-semibold text-[24px] mt-3">
                FILTER
              </p>
              <div class="grid grid-cols-5 gap-6 mt-6">
                <div className=" z-10">
                  <p className="mb-8 font-poppins font-medium ">Plant</p>
                  {/* <SearchDropDownComp
                    type="product_name"
                    list={allProducts}
                    onSelected={(item) => {setSelectedProduct(item)}}
                  /> */}
                  <AsyncSelect
                        cacheOptions
                        value={selectedProduct}
                        placeholder="Search Product"
                        inputValue={searchProduct}
                        isClearable={true}
                        filterOption={false}
                        loadOptions={loadOptions}
                        getOptionLabel={(allProducts) => allProducts.product_name}
                        getOptionValue={(allProducts) => allProducts.id}
                        defaultOptions={allProducts}
                        onInputChange={handleSelectSearch}
                        onChange={handleSelectChange}
                      />
                </div>
                <div>
                  <p className="mb-8 font-poppins font-medium">Farm</p>
                  <SearchDropDownComp
                    type="farm"
                    list={allFarms}
                    onSelected={(item) => setSelectedFarm(item)}
                  />
                </div>
                <div>
                  <p className="mb-8 font-poppins font-medium">Status</p>
                  <SearchDropDownComp
                    type="state"
                    list={metaDataDetails.rabStates}
                    onSelected={(item) => setSelectedStatus(item)}
                  />
                </div>

               {user.role.role !== "Guest" &&  <div>
                  <p className="mb-8 font-poppins font-medium">Mitra</p>
                  <SearchDropDownComp
                    type="mitra"
                    list={allMitras}
                    onSelected={(item) => setSelectedMitra(item)}
                  />
                </div>}

                <div>
                  <p className="mb-8 font-poppins font-medium">Used For Planting</p>
                  <SearchDropDownComp
                    type="used"
                    list={typesOfUsed}
                    onSelected={(item) => setSelectedUsed(item)}
                  />
                </div>

              </div>
              <div class="grid grid-cols-5 gap-6 mt-6">
                <div className="flex">
                  {selectedProduct ? (
                    <div className="flex justify-center items-center border border-[#252525] px-3 py-1 rounded-lg">
                      <img
                        className="w-[15px] h-[15px] mr-2"
                        src={crossImg}
                        onClick={() => {setSelectedProduct(undefined)}}
                        alt="crossImg"
                      />
                      <p className="text-[14px] font-normal">
                        {selectedProduct?.product_name.toString()}
                      </p>
                    </div>
                  ) : null}
                </div>
                <div className="flex">
                  {selectedFarm !== undefined ? (
                    <div className="flex justify-center items-center border border-[#252525] px-3 py-1 rounded-lg">
                      <img
                        className="w-[15px] h-[15px] mr-2"
                        src={crossImg}
                        onClick={() => {setSelectedFarm(undefined)}}
                        alt="crossImg"
                      />
                      <p className="text-[14px] font-normal">
                        {selectedFarm?.farm_name.toString()}
                      </p>
                    </div>
                  ) : null}
                </div>
                <div className="flex">
                  {selectedStatus !== undefined ? (
                    <div className="flex justify-center items-center border border-[#252525] px-3 py-1 rounded-lg">
                      <img
                        className="w-[15px] h-[15px] mr-2"
                        src={crossImg}
                        alt="crossImg"
                        onClick={() => {setSelectedStatus(undefined)}}
                      />
                      <p className="text-[14px] font-normal">
                        {selectedStatus.state.toString()}
                      </p>
                    </div>
                  ) : null}
                </div>
                <div className="flex">
                  {selectedMitra !== undefined ? (
                    <div className="flex justify-center items-center border border-[#252525] px-3 py-1 rounded-lg">
                      <img
                        className="w-[15px] h-[15px] mr-2"
                        src={crossImg}
                        alt="crossImg"
                        onClick={() => {setSelectedMitra(undefined)}}
                      />
                      <p className="text-[14px] font-normal">
                        {selectedMitra.user_name.toString()}
                      </p>
                    </div>
                  ) : null}
                </div>
                <div className="flex">
                  {selectedUsed !== undefined ? (
                    <div className="flex justify-center items-center border border-[#252525] px-3 py-1 rounded-lg">
                      <img
                        className="w-[15px] h-[15px] mr-2"
                        src={crossImg}
                        alt="crossImg"
                        onClick={() => {setSelectedUsed(undefined)}}
                      />
                      <p className="text-[14px] font-normal">
                        {selectedUsed.name.toString()}
                      </p>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="flex items-center p-6 border-t border-solid border-slate-200 rounded-b-lg bg-white w-full">
              <div className="grid grid-cols-2 gap-2 w-full">
                <div></div>
                <div className="justify-end flex">
                  <button
                    className="border border-green text-green mr-10 px-5 py-1 rounded-full hover:text-white hover:bg-green"
                    type="button"
                    onClick={() => resetFilter()}
                  >
                    RESET
                  </button>
                  <button
                    className="bg-[#3EB049] text-white mr-10 px-5 py-1 rounded-full"
                    type="button"
                    onClick={addFilters}>
                    APPLY
                  </button>
                </div>
              </div>
              <div />
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>{" "}
    </>
  );
};