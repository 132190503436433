import React, { useState } from "react";
import { BsPlus } from "react-icons/bs";
import { TableUsers } from "../../config/tables/UserTable";
import { _apiLogout, _fetchMetaData } from "../../api";
import { PageLayoutComp } from "../../components/PageLayoutComp";
import { AddNewUserModal } from "../../Models/AddNewUserModal";
import { useEffect } from "react";

export const Users = () => {
  let user = JSON.parse(localStorage.getItem("user_data"));
  const [metaData, setMetaData] = useState();
  const [showAddNewUserM, setshowAddNewUserM] = useState(false);

  useEffect(()=>{
    _fetchData();
  },[])

  async function _fetchData() {
    let metaData = await _fetchMetaData();
    setMetaData(metaData);
  }

  function handleAddButton() {
    if (!user?.permissions?.create_user) {
      alert("Don't have Create Permission");
      return;
    }
    setshowAddNewUserM(true)
  }

  return (
    <div className="w-screen h-screen">
      {showAddNewUserM === true ?
          <AddNewUserModal
            token={user.token}
            metaData={metaData}
            hideModal={() =>setshowAddNewUserM(false)}
          />:null
        }
      <PageLayoutComp metaData={metaData} user={user}>
        <div className="h-[100%]">
          <div className="h-[7%]">
            <div className="flex justify-between">
              <p className="text-3xl font-bold mb-0">
                Administration - Users
                </p>
                <div className="flex">
                  <div
                    onClick={() => handleAddButton()}
                    className="flex bg-green100 items-center text-white pl-1 pr-3 rounded-md cursor-pointer"
                  >
                    <BsPlus size={25} />
                    <p className="ml-3 text-sm">Add New User</p>
                  </div>
                </div>
            </div>
          </div>
          <div className="h-[100%]">
            <div className="h-[calc(100%-50px)] bg-white p-3">
                <TableUsers
                  token={user.token}
                  metaData={metaData}
                  refreshTab={showAddNewUserM}
                />
            </div>
          </div>
        </div>
      </PageLayoutComp>
    </div>
  );
};