import React, { useEffect, useState } from "react";
import {_asyncCancelPO, _asyncGetWarehouses, _asyncPORerequest, _updateInspectionInfo } from "../api";
import closeImg from "../images/FarmPipeLine/close.png";
import { formatDate, _convertCommaSaparate } from "../config/localdata";
import { CancelReasonModal } from "./CancelReasonModal";
import { ConfirmationModal } from "./ConfirmationModal";
import { SuccessfullMesg } from "./SuccessfullMesg";
import { toast } from "react-toastify";

export const CancelPOModal = ({
  hideModal,
  metaData,
  generatePoBody
}) => {
const [showGeneratePo, setShowGeneratePo] = useState(false);
const [cancelReason, setCancelReason] = useState('');
const [showConfirmModal, setShowConfirmModal] = useState(false);
const [showSuccessModal, setShowSuccessModal] = useState(false);
const [showConfirmModalPrice, setShowConfirmModalPrice] = useState(false);
const [showSuccessModalPrice, setShowSuccessModalPrice] = useState(false);
const [items, setItems] = useState(generatePoBody?.inspection_info);

const [reRequestFlag, setRerequestFlag] = useState(false);


const colorObject = {
  1:'border-[#40AE49] bg-[#40AE49]',
  3:'border-[#12312B] bg-[#12312B]',
  4:'border-[#F491A3] bg-[#F491A3]',
  2:'border-[#F491A3] bg-[#F491A3]',
  5:'border-[#40AE49] bg-[#40AE49]',
}

function _getPlantingStatus(id) {
    let data = "";
    data = metaData?.allowedPOStatus?.filter((e) => e.id == id);
    return data && data[0] && data[0]?.status_name;
}
const cancelPo = () =>{
    setShowGeneratePo(true)
}
const cancelPoWithReason = () =>{
    setShowGeneratePo(false)
    setShowConfirmModal(true);
    console.log(cancelReason, generatePoBody)
 }

 const handleItemChange = (index, key, value) => {
  if(value !== "" && isNaN(value)){
    return;
  }
  if(value < 0){
    return;
  }
  setItems((prevItems) => {
    const updatedItems = [...prevItems];
    updatedItems[index] = { ...updatedItems[index], [key]: value }
    return updatedItems;
  });
};

 const reCreatePo = () =>{
  setShowConfirmModal(true);
  setRerequestFlag(true)
}

 const _cancelPO = async () => {
    let body = {"cancel_reason":cancelReason}
    const apiResponse = reRequestFlag ? await _asyncPORerequest(generatePoBody?.po_id): await _asyncCancelPO(generatePoBody?.po_id, body)
    if (apiResponse === 1) {
      setShowSuccessModal(true)
      setShowConfirmModal(false)
      setTimeout(() => {
        window.location.reload();
      }, 600);
      return;
    }
    toast.error(apiResponse);
    return;
  };


  const _updatePoPrice = async () => {
   let body = items.map(({ id, unit_price }) => ({ id, unit_price }));
    const apiResponse =  await _updateInspectionInfo(body)
    if (apiResponse === 1) {
      setShowSuccessModalPrice(true)
      setShowConfirmModalPrice(false)
      setTimeout(() => {
        window.location.reload();
      }, 600);
      return;
    }
    toast.error(apiResponse);
    return;
  };

  const grandTotal = items?.reduce((n, item) => n + (item?.accepted_qty * item?.unit_price),0);

  return (
    <>
      <div className="flex overflow-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center">
      {showGeneratePo ? (
        <CancelReasonModal
          hideModal={() => {setShowGeneratePo(false)}}
          generatePoBody={generatePoBody}
          setCancelReason={setCancelReason}
          cancelReason={cancelReason}
          cancelPoWithReason={cancelPoWithReason}
        />
      ) : null}


      {showSuccessModal ? (<SuccessfullMesg label={`PO ${reRequestFlag ? 'Requested':'Canceled'} `} />) : null}
        {showConfirmModal ? (
          <ConfirmationModal
            label={`Are you sure to ${reRequestFlag ? 're-request':'cancel'} PO ${generatePoBody?.po_name} ?`}
            label2="No"
            type="planting"
            customStyles={true}
            hideModal={() => setShowConfirmModal(false)}
            onConfirm={() => {
              setShowConfirmModal(false);
              _cancelPO();
            }}
          />
        ) : null}


      {showSuccessModalPrice ? (<SuccessfullMesg label={`PO Updated Successfully`} />) : null}
        {showConfirmModalPrice ? (
          <ConfirmationModal
            label={`Are you sure to update unit price ?`}
            label2="No"
            type="planting"
            customStyles={true}
            hideModal={() => setShowConfirmModalPrice(false)}
            onConfirm={() => {
              setShowConfirmModalPrice(false);
              _updatePoPrice();
            }}
          />
        ) : null}

        <div className="w-[70%] h-[55%] relative mt-[3%] mb-[3%]">
          {/*content*/}
          <div className="h-full first-letter:rounded-lg shadow-lg relative flex flex-col w-full outline-none focus:outline-none rounded-lg">
          <div className="relative p-10 overflow-auto pt-3 bg-white rounded-t-lg h-full">
              <img
                className="float-right w-[30px] h-[30px] mt-3"
                src={closeImg}
                alt="closeImg"
                onClick={() => {
                  hideModal();
                }}
              />
              <div className="mt-6">
                 <p className="font-semibold text-[15px] text-black">{generatePoBody?.po_name}</p>
                 <div className="w-[90px] mt-2">
                    <p className={`text-[10px] p-1 font-[800] text-white text-center border ${colorObject[generatePoBody?.po_state]} `}>
                      {_getPlantingStatus(generatePoBody?.po_state)}
                    </p>
                    </div>
                </div>
                <div className="mt-5 grid grid-rows-6">

                </div>
                <div className="flex flex-row justify-between">
                    <div className="">
                    <p className="text-black text-[15px] mt-2 mr-3">Vendor Name: {generatePoBody?.odoo_vendor_name}</p>
                  </div>
                <div className="flex">
                    <p className="text-black text-[15px] mt-2 mr-3">
                        Expected Arrival Date : {formatDate(new Date(generatePoBody?.expected_arrival_date), true,false)}
                      </p>
                  </div>
                </div>
                <div className="flex rounded-lg border border-gray-500 px-3 mt-4">
            {items.length > 0 ?
            <table style={{width:'90%', textAlign: 'center'}}>
            <tr>
                <th style={{backgroundColor: '#fff',color: 'black',textAlign: 'center', padding:'7px'}}>Product PO</th>
                <th style={{backgroundColor: '#fff',color: 'black',textAlign: 'center', padding:'7px' }}>Quantity</th>
                <th style={{backgroundColor: '#fff',color: 'black',textAlign: 'center', padding:'7px' }}>Acceptance</th>
                <th style={{backgroundColor: '#fff',color: 'black',textAlign: 'center', padding:'7px' }}>UoM</th>
                <th style={{backgroundColor: '#fff',color: 'black',textAlign: 'center', padding:'7px' }}>Unit Price</th>
                <th style={{backgroundColor: '#fff',color: 'black',textAlign: 'center', padding:'7px' }}>Price</th>
	        </tr>
            {items?.map((item, index)=>{
                return(
                <tr key={item.product_id}>
                <td  style={{textAlign: 'center',padding:'7px', backgroundColor: 'white'}}>{item?.display_name}</td>
                <td  style={{textAlign: 'center',padding:'7px', backgroundColor: 'white'}}>{item?.quantity}</td>
                <td  style={{textAlign: 'center',padding:'7px', backgroundColor: 'white'}}>{item?.accepted_qty}</td>
                <td  style={{textAlign: 'center',padding:'7px', backgroundColor: 'white'}}>{item?.uom}</td>

                {/* <td  style={{textAlign: 'center',padding:'7px', backgroundColor: 'white'}}>{_convertCommaSaparate(item?.unit_price)}</td> */}

              <td  style={{textAlign: 'center',padding:'7px', backgroundColor: 'white'}}>
                <input
                    type="text"
                    value={item.unit_price}
                    onChange={(e) => handleItemChange(index,"unit_price", e.target.value=parseInt(e.target.value) || 0)}
                    className="border border-gray-700 w-[70px] text-center"
                  />
              </td>
                <td  style={{textAlign: 'center',padding:'7px', backgroundColor: 'white'}}>{_convertCommaSaparate(item?.accepted_qty * item?.unit_price) || 0}</td>
            </tr>
                )
            })}
        </table>
          :
          <table style={{width:'90%', textAlign: 'center'}}>
            <tr>
                <th style={{backgroundColor: '#fff',color: 'black',textAlign: 'center', padding:'7px'}}>PC/PSIC</th>
                <th style={{backgroundColor: '#fff',color: 'black',textAlign: 'center', padding:'7px' }}>Code</th>
                <th style={{backgroundColor: '#fff',color: 'black',textAlign: 'center', padding:'7px' }}>Harvest Batch</th>
                <th style={{backgroundColor: '#fff',color: 'black',textAlign: 'center', padding:'7px' }}>Quantity</th>
                {generatePoBody?.po_state !== 1 && <th style={{backgroundColor: '#fff',color: 'black',textAlign: 'center', padding:'7px' }}>Received</th>}
                <th style={{backgroundColor: '#fff',color: 'black',textAlign: 'center', padding:'7px' }}>UoM</th>
                <th style={{backgroundColor: '#fff',color: 'black',textAlign: 'center', padding:'7px' }}>Unit Price</th>
                <th style={{backgroundColor: '#fff',color: 'black',textAlign: 'center', padding:'7px' }}>Price</th>
	        </tr>
            {generatePoBody?.harvests?.map((item, index)=>{
                return(
                <tr key={item.product_id}>
                <td  style={{textAlign: 'center',padding:'7px', backgroundColor: 'white'}}>{item?.pc_psic_info?.name}</td>
                <td  style={{textAlign: 'center',padding:'7px', backgroundColor: 'white'}}>{item?.production_code}</td>
                <td  style={{textAlign: 'center',padding:'7px', backgroundColor: 'white'}}>{item?.harvest_batch_id}</td>
                <td  style={{textAlign: 'center',padding:'7px', backgroundColor: 'white'}}>{_convertCommaSaparate(item?.harvest_amount)}</td>
                {generatePoBody?.po_state !== 1 && <td  style={{textAlign: 'center',padding:'7px', backgroundColor: 'white'}}>{_convertCommaSaparate(item?.received_harvest_amount)}</td>}
                <td  style={{textAlign: 'center',padding:'7px', backgroundColor: 'white'}}>Kg</td>
                <td  style={{textAlign: 'center',padding:'7px', backgroundColor: 'white'}}>{_convertCommaSaparate(item?.decided_unit_price_for_po)}</td>
                <td  style={{textAlign: 'center',padding:'7px', backgroundColor: 'white'}}>{(generatePoBody?.po_state !== 1 ? _convertCommaSaparate(item?.total_received_harvest_price):_convertCommaSaparate(item?.total_harvest_price)) || 0}</td>
            </tr>
                )
            })}
        </table>}
        </div>
        <div className="float-right mr-32 mt-3">
        {generatePoBody?.po_state === 5 ? <p className="font-bold">Total: Rp. {_convertCommaSaparate(grandTotal)}</p>:
        <p className="font-bold">Total: Rp. {generatePoBody?.po_state !== 1 ? _convertCommaSaparate(generatePoBody?.total_actual_cost_for_received_po) : _convertCommaSaparate(generatePoBody?.total_cost_for_po)}</p>}
        </div>
        <div className="mt-5 grid grid-cols-5">
        <div>
        <p className="font-bold">Deliver to :</p> 
        </div>
        <div className="ml-6 w-[200px]">{generatePoBody?.odoo_warehouse_name}</div>
    </div>
      <div className="mt-5 grid grid-cols-5">
      <div>
        <p className="font-bold">Purchase Method :</p>
        </div>
        <div className="">
            {generatePoBody?.purchase_method == 1 ? "Received (Abras)":"Accept (Super)"}
      </div>
      </div>
            </div>
            {/*footer*/}
            <div className="py-4 rounded-b-lg bg-white w-full px-10">
              {generatePoBody?.po_state === 1 && <div className="flex justify-end">
                <p
                  onClick={() => cancelPo()}
                  className="bg-[#F491A3] text-white px-6 py-1 border border-[#F491A3] rounded-lg cursor-pointer">
                  Cancel PO
                </p>
              </div>}
              {generatePoBody?.po_state === 4 && <div className="flex justify-end">
                <p
                  onClick={() => reCreatePo()}
                  className="bg-[#40AE49] text-white px-6 py-1 border border-[#40AE49] rounded-lg cursor-pointer">
                  Re-Create PO
                </p>
              </div>}
            
              {generatePoBody?.po_state === 5 && <div className="flex justify-end">
                <p
                  onClick={() => setShowConfirmModalPrice(true)}
                  className="bg-[#40AE49] text-white px-6 py-1 border border-[#40AE49] rounded-lg cursor-pointer">
                  Update PO
                </p>
              </div>}
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>{" "}
    </>
  );
};
