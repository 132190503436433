import React, { useEffect, useState } from "react";
import DropDownComp from "../components/DropDownComp";
import { _apiFetchFarms, axiosClient, axiosHeader } from "../api";
import closeImg from "../images/FarmPipeLine/close.png";
import SearchDropDownComp from "../components/SearchDropDownComp";

export const AssignWeatherStationModal = ({
  hideModal,
  selectedStation,
  onAssign
}) => {
  const [tableDataList, setTableDataList] = useState([]);
  const [selectedItem, setSelectedItem] = useState();

  useEffect(() => {
    _getData();
  }, [selectedStation]);

  async function _getData() {
    let data = {
      page:1,
      size:100,
      archived:false
    }
    let res = await _apiFetchFarms(data);
    let { displayData, totalPage } = res;
    let allFarmsList = displayData.map((d) => {
      d.isSelectedForArchive = false;
      return d;
    });
    setTableDataList(allFarmsList);
    setSelectedItem(allFarmsList[0]);
  }

  if (selectedItem === undefined) return;

  return (
    <>
      <div className="flex overflow-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center">
        <div className="w-[35%] relative mt-[3%] mb-[3%]">
          {/*content*/}
          <div className="h-full first-letter:rounded-lg shadow-lg relative flex flex-col w-full outline-none focus:outline-none rounded-lg">
            <div className="flex items-center relative pl-6 pr-6 overflow-auto pt-3 bg-white rounded-t-lg h-full">
              <p className="text-[18px] w-full font-[450px] text-center mb-5 mt-3">
                Assign Weather Station {selectedStation?.identifier}
              </p>
              <img
                className="float-right w-[30px] h-[30px]"
                src={closeImg}
                alt="closeImg"
                onClick={() => {
                  hideModal();
                }}
              />
            </div>
            {/*footer*/}
            <div className="py-6 rounded-b-lg bg-white w-full px-20">
              <p className="mb-3">Farm</p>

              <SearchDropDownComp
                list={tableDataList}
                type={"farm"}
                onSelected={(i) => setSelectedItem(i)}
              />
              <div className="flex justify-end mt-5">
                <p
                  onClick={() => onAssign(selectedItem)}
                  className="text-[#3EB049] px-10 py-2 border border-[#3EB049] rounded-full cursor-pointer"
                >
                  Assign
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>{" "}
    </>
  );
};
