import React, { useEffect, useState } from "react";
import { _apiLogout, _fetchMetaData } from "../../api";
import { CropsTracker } from "../../components/CropsTracker";
import { PageLayoutComp } from "../../components/PageLayoutComp";

export const CropTracker = () => {
  let user = JSON.parse(localStorage.getItem("user_data"));
  const [metaData, setMetaData] = useState();

  useEffect(() => {
    _fetchData();
  },[]);

  async function _fetchData() {
    let metaData = await _fetchMetaData();
    setMetaData(metaData);
  }

  return (
    <div className="w-screen h-screen">
      <PageLayoutComp metaData={metaData} user={user}>
        <div className="h-[100%]">
          <div className="h-[7%]">
            <div className="flex justify-between">
              <p className="text-3xl font-bold mb-0">Crops Tracker</p>
            </div>
          </div>
          <div className="h-[100%]">
            <div className="h-[calc(100%-50px)] bg-white p-3">
                <CropsTracker />
            </div>
          </div>
        </div>
      </PageLayoutComp>
    </div>
  );
};
