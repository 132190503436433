import React, { useState } from "react";
import { BsPlus } from "react-icons/bs";
import { TableFarms } from "../../config/tables/FarmTable";
import { _apiLogout, _fetchMetaData } from "../../api";
import { useNavigate } from "react-router-dom";
import { PageLayoutComp } from "../../components/PageLayoutComp";
import { useEffect } from "react";

export const Farms = () => {
  const location = useNavigate();
  let user = JSON.parse(localStorage.getItem("user_data"));
  const [metaData, setMetaData] = useState();
  

  useEffect(()=>{
    _fetchData();
  },[])

  async function _fetchData() {
    let metaData = await _fetchMetaData();
    setMetaData(metaData);
  }

  function handleAddButton() {
    if (!user?.permissions?.create_farm) {
      alert("Don't have Create Permission");
      return;
    }
    localStorage.setItem("editMitraFarm", false);
    localStorage.setItem("selectedfarmIdForEdit", false);
    location("/farm-pipeline/create-new-mitra-farm");
  }

  return (
    <div className="w-screen h-screen">
      <PageLayoutComp metaData={metaData} user={user}>
        <div className="h-[100%]">
          <div className="h-[7%]">
            <div className="flex justify-between">
              <p className="text-3xl font-bold mb-0">
                Administration - Farms
                </p>
                <div className="flex">
                  <div
                    onClick={() => handleAddButton()}
                    className="flex bg-green100 items-center text-white pl-1 pr-3 rounded-md cursor-pointer"
                  >
                    <BsPlus size={25} />
                    <p className="ml-3 text-sm">Add New Mitra Farm</p>
                  </div>
                </div>
            </div>
          </div>
          <div className="h-[100%]">
            <div className="h-[calc(100%-50px)] bg-white p-3">
                <TableFarms
                  token={user.token}
                  metaData={metaData}
                />
            </div>
          </div>
        </div>
      </PageLayoutComp>
    </div>
  );
};
