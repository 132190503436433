import React, { useEffect, useState } from "react";
import { TableSearchComp } from "../../../components/TableSearchComp";
import { TablePaginationComp } from "../../../components/TablePaginationComp";
import { getDateFormatMoment } from "../../localdata";
import { _asyncGetAllBill } from "../../../api";
import { useLocation } from "react-router-dom";
import { DashboardFilterModal } from "../../../Models/DashboardFilterModal";
import { IoIosArrowForward } from "react-icons/io";
import { GenerateBillModal } from "../../../Models/GenerateBillModal";

export const Bills = ({ metaData, selectedFilter }) => {
  const size = 10;
  const configFetchRef = React.useRef(false);
  const [totalPage, setTotalPage] = useState();
  const [totalItems, setTotalItems] = useState();
  const [selectedPage, setSelectedPage] = useState(0);
  const [tableDataList, setTableDataList] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState();
  const [selectedFarm, setSelectedFarm] = useState();
  const [selectedMitra, setSelectedMitra] = useState();
  const [filterData, setFilterData] = useState({});

  const [showGeneratePo, setShowGeneratePo] = useState(false);
  const [generatePoBody, setGeneratePoBody] = useState(null);

  const [allSelectedFor, updateAllSelected] = useState(false);
  const [someSelected, updateSomeSelected] = useState(false);
  const [isViewEditModalOpened, setViewEditModalOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchType, setSearchType] = useState("");
  const [action, setAction] = useState("");
  const [setRowsPerPage, setSetRowsPerPage] = useState(0);

  const location = useLocation();
  let user = JSON.parse(localStorage.getItem("user_data"));

  const sortType = [
    { id: "1", name: "Latest" },
    { id: "2", name: "Earliest" },
  ];

  const colorObject = {
    5: "border-[#40AE49]",
    2: "border-[#12312B]",
    1: "border-[#F491A3]",
    4: "border-[#F491A3]",
    3: "border-[#12312B]",
  };

  const [sortValue, setSortValue] = useState({ id: "3", name: "Sort By" });

  useEffect(() => {
    if (configFetchRef.current) return;
    configFetchRef.current = true;
    _fetchProducts(selectedPage, null, filterData);
  }, []);

  useEffect(() => {
    const allSelected =
      tableDataList && tableDataList.length
        ? tableDataList.every((ele) => ele.isSelectedForPO === true)
        : false;
    const allUnSelected =
      allSelected === false
        ? tableDataList && tableDataList.length
          ? tableDataList.every((ele) => ele.isSelectedForPO === false)
          : true
        : false;
    updateAllSelected(allSelected);
    updateSomeSelected(!allUnSelected);
  }, [tableDataList]);

  const generatePO = () => {
    if (!user?.permissions?.generate_bill) {
      alert("Don't have Generate Bill Permission");
      return;
    }

    const body = [];
    tableDataList.forEach((item) => {
      if (item.isSelectedForPO === true) {
        body.push(item);
      }
    });

    if (body.length > 0) {
      setGeneratePoBody(body);
      setShowGeneratePo(true);
    }
  };

  useEffect(() => {
    let sort_order = sortValue?.name === "Earliest" ? "ASC" : "DESC";
    _fetchProducts(selectedPage, sort_order, filterData);
  }, [selectedFilter, sortValue]);

  useEffect(() => {
    _fetchProducts(selectedPage);
  }, [setRowsPerPage]);

  function _getPlantingStatus(id) {
    let data = "";
    data = metaData?.allowedBillStatus?.filter((e) => e.id == id);
    return data && data[0] && data[0]?.status_name;
  }

  async function _fetchProducts(page, sort_order, item) {
    setShowFilter(false);
    let params = {
      size: setRowsPerPage > 10 ? setRowsPerPage : size,
      page: page + 1,
      bill_states: selectedFilter?.id !== "" ? [selectedFilter?.id] : null,
      ...item,
    };
    if (searchType?.search_by_key && searchText) {
      params[searchType?.search_by_key] = searchText;
    }
    const res = await _asyncGetAllBill(params, location, sort_order);
    setTableDataList(res?.displayData);
    setTotalPage(res?.totalPage);
    setTotalItems(res?.totalItems);
  }

  const addFilters = () => {
    let product_ids = [];
    let farm_ids = [];
    let mitra_ids = [];

    if (selectedProduct !== undefined) {
      product_ids.push(selectedProduct.id);
    }
    if (selectedFarm !== undefined) {
      farm_ids.push(selectedFarm.id);
    }
    if (selectedMitra !== undefined) {
      mitra_ids.push(selectedMitra.id);
    }
    setFilterData({ product_ids, farm_ids, mitra_ids });
    setSelectedPage(0);
    _fetchProducts(0, null, { product_ids, farm_ids, mitra_ids });
  };

  useEffect(() => {
    _fetchProducts(selectedPage, null, filterData);
  }, [action]);

  const handleSearchSubmit = (action) => {
    setAction(action);
    setSelectedPage(0);
    if (action === "reset") {
      setSearchText("");
      setSearchType("");
      _fetchProducts(0, null, filterData);
    }
    if (searchText.length > 2 && action !== "reset") {
      _fetchProducts(0, null, filterData);
    }
  };

  const resetFilter = () => {
    setShowFilter(false);
    _fetchProducts(selectedPage, null, {});
    setFilterData({});
    setSelectedProduct();
    setSelectedFarm();
    setSelectedMitra();
  };

  const handlePoDetails = (item) => {
    if (item.bill_state === 1) {
      return;
    }
    const body = [];
    body.push(item);
    setGeneratePoBody(body);
    setShowGeneratePo(true);
  };

  return (
    <div className="h-[100%]">
      {showGeneratePo ? (
        <GenerateBillModal
          hideModal={() => {
            setShowGeneratePo(false);
          }}
          metaData={metaData}
          generatePoBody={generatePoBody}
          //submitPo={(obj) => submitPo(obj)}
        />
      ) : null}

      {showFilter ? (
        <DashboardFilterModal
          hideModal={() => setShowFilter(false)}
          resetFilter={resetFilter}
          addFilters={addFilters}
          selectedProduct={selectedProduct}
          selectedFarm={selectedFarm}
          selectedMitra={selectedMitra}
          setSelectedProduct={setSelectedProduct}
          setSelectedFarm={setSelectedFarm}
          setSelectedMitra={setSelectedMitra}
        />
      ) : null}

      <div className="h-[10%]">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "2rem",
          }}
        >
          <div className="w-[100%]">
            <TableSearchComp
              onChange={(text) => setSearchText(text)}
              openFilter={() => setShowFilter(true)}
              sortBy={true}
              sortType={sortType}
              sortValue={sortValue}
              handleSortBy={(item) => setSortValue(item)}
              searchText={searchText}
              handleSearchSubmit={handleSearchSubmit}
              searchTypeValues={
                metaData?.searchableFeaturesWithOptions?.bill_list
              }
              searchType={searchType}
              onSearchChangeType={(item) => setSearchType(item)}
            />
          </div>
        </div>
      </div>

      <div className="h-[3%]">
        <div className="flex py-2 float-right">
          <p
            onClick={generatePO}
            className="bg-[#3EB049] text-white m-2 px-2 py-2 flex-end  border-[#3EB049] rounded-xl cursor-pointer"
          >
            {" "}
            Generate Bill
          </p>
        </div>
      </div>

      <div className="h-[100%] overflow-auto scrollbar-hide mt-16">
        {tableDataList?.map((data, i) => {
          return (
            <div className="box-border mt-3  w-full p-3 border-2 border-slate-200 rounded-xl">
              <div className="flex flex-row w-full">
                <input
                  type="checkbox"
                  disabled={data.bill_state !== 1}
                  checked={data.isSelectedForPO}
                  onChange={() => {
                    const updatedData = [...tableDataList];
                    updatedData[i].isSelectedForPO =
                      !updatedData[i].isSelectedForPO;
                    setTableDataList(updatedData);
                  }}
                />
                <div className="basis-1/3 ml-2 mt-0">
                  <p className="text-[0.8rem] font-bold break-all">{data?.bill_name}</p>
                  <p className="text-balance text-wrap text-gray text-[0.75rem] font-[600]">
                    Created at {getDateFormatMoment(data?.bill_created_at)}
                  </p>

                  <div className="w-[50%]">
                    <p className={`text-balance text-[0.75rem] p[0.5rem] font-[800] w[100%] text-black text-center bg-[#fff] border ${
                        colorObject[data?.bill_state]
                      }`}>
                      {_getPlantingStatus(data?.bill_state)}
                    </p>
                    </div>
                </div>
                <div className="mx-[1rem] basis-1/4 ">
                  <p className="break-all mr-2 text-balance text-[1rem] font-[800]">{data?.farm_name}</p>
                  <p className=" text-[0.75rem] font-[600]">{data?.user_name}</p>
                </div>

                <div className="ml-[1rem] basis-1/4">
                  {data?.po_s.map((item) => {
                    return (
                      <p className="text-black font-bold text-[0.75rem]">
                        <b>{item?.po_name}</b>
                      </p>
                    );
                  })}
                </div>
                {data?.po_s.map((items) => {
                  return (
                    <>
                      <div className="ml-8 basis-1/3 mt-0">
                        {items?.harvests.map((item) => {
                          return (
                            <p className="text-black font-bold text-[0.75rem]">
                              <b>{item?.product_name}</b>
                            </p>
                          );
                        })}
                      </div>
                      <div className="ml-[1rem] basis-1/4 ">
                        {items?.harvests.map((item) => {
                          return (
                            <p className="text-black font-bold text-[12px]">
                              <b>{item?.production_code}</b>
                            </p>
                          );
                        })}
                      </div>
                    </>
                  );
                })}
                <div className="flex items-center">
                  <IoIosArrowForward onClick={() => handlePoDetails(data)} />
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="flex justify-end items-center h-[10%] mt-5 text-sm px-5">
        {totalPage !== undefined ? (
          <TablePaginationComp
            totalPages={totalPage}
            active={selectedPage}
            selectedPage={(p) => {
              setSelectedPage(p);
              _fetchProducts(p, null, filterData);
            }}
            totalItems={totalItems}
            showPerPageDropdown={true}
            setRowsPerPage={(r) => {
              setSetRowsPerPage(r);
            }}
          />
        ) : null}
      </div>
    </div>
  );
};
