import React, { useEffect, useState } from "react";
import { FARM_PIPELINE_TB_COL } from "..";
import ImgHydro from "../../../images/FarmPipeLine/typeOne.png";
import ImgOpenField from "../../../images/FarmPipeLine/sun.png";
import ImgSoilless from "../../../images/FarmPipeLine/typeTwo.png";
import {_apiFetchFarms, axiosClient, _asyncEnableRAB} from "../../../api";
import { TableSearchComp } from "../../../components/TableSearchComp";
import { TablePaginationComp } from "../../../components/TablePaginationComp";
import { BsFillCaretDownFill, BsArchiveFill, BsArchive } from "react-icons/bs";
import { toast } from "react-toastify";
import { EditFarmModal } from "../../../Models/EditFarmModal";
import { ConfirmationModal } from "../../../Models/ConfirmationModal";
import { useNavigate } from "react-router-dom";
import { FarmFilterModal } from "../../../Models/FarmFilterModal";

export const TableFarms = ({
  metaData,
  archived = false,
  token
}) => {
  const size = 10;
  let colums = FARM_PIPELINE_TB_COL;
  const location = useNavigate();
  const configFetchRef = React.useRef(false);
  const [totalPage, setTotalPage] = useState();
  const [totalItems, setTotalItems] = useState();
  const [selectedPage, setSelectedPage] = useState(0);
  const [tableDataList, setTableDataList] = useState([]);
  const [searchText, setSearchText] = useState("")
  const [allFarmsData, setAllFarmsData] = useState([]);
  const [allSelectedForArchive, updateAllSelected] = useState(false);
  const [someSelectedForArchive, updateSomeSelected] = useState(false);
  const [isViewEditModalOpened, setViewEditModalOpen] = useState(false);
  const [selectedFarmDetails, setSelectedFarmDetails] = useState(null);
  const [selectedFarmIdForModalView, updateSelectedFarmIdForModalView] =
    useState(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [searchType, setSearchType] = useState('');
  const [action, setAction] = useState("");
  const [filterData, setFilterData] = useState({});

  let user = JSON.parse(localStorage.getItem("user_data"));


  useEffect(() => {
    if (configFetchRef.current) return;
    configFetchRef.current = true;
    _fetchFarms(selectedPage,filterData, searchText);
  }, []);

  useEffect(() => {
    const allSelected =
      tableDataList && tableDataList.length
        ? tableDataList.every((ele) => ele.isSelectedForArchive === true)
        : false;
    const allUnSelected =
      allSelected === false
        ? tableDataList && tableDataList.length
          ? tableDataList.every((ele) => ele.isSelectedForArchive === false)
          : true
        : false;
    updateAllSelected(allSelected);
    updateSomeSelected(!allUnSelected);
  }, [tableDataList]);

  useEffect(() => {
    if (!isViewEditModalOpened) {
      updateSelectedFarmIdForModalView(null);
    }
  }, [isViewEditModalOpened]);

  async function _fetchFarms(page, filterData={}, searchText = "") {   //If not work then going to edit this
    let data = {
      page: page+1,
      size:size,
      ...filterData,
      archived,
    }
    if(searchType?.search_by_key && searchText){
      data[searchType?.search_by_key] = searchText;
    }
    if(searchText !== ''){
      data.search_text = searchText;
      data.search_by = 'Farm Name';
      delete data.page;
      delete data.size;
    }
    let res = await _apiFetchFarms(data, location);
    let { displayData, totalPage, totalItems } = res;
    setTableDataList(
      displayData.map((d) => {
        d.isSelectedForArchive = false;
        return d;
      })
    );
    setAllFarmsData(
      displayData.map((d) => {
        d.isSelectedForArchive = false;
        return d;
      })
    );
    setTotalPage(totalPage);
    setTotalItems(totalItems)
  }

  const enableRAB = async (id, rab) =>{
    let body = {
      enable_rab:rab
    }
    let data = await _asyncEnableRAB(id, body);
    if(data.status === 200 && data.statusText === "OK"){
      _fetchFarms(selectedPage);
    }
  }
  useEffect(()=>{
    _fetchFarms(selectedPage, null, filterData)
  },[action]);

  const handleSearchSubmit = (action) =>{
    setSelectedPage(0);
    if(action === "reset"){
        setSearchText("");
       setSearchType("");
      _fetchFarms(selectedPage, filterData, "")
    }
    if(searchText.length > 2 && action !== "reset"){
      _fetchFarms(selectedPage, filterData, searchText)
    }
  }
  

  

  const doArchive = async () => {
    try {
      const body = [];
      tableDataList.forEach((item) => {
        if (item.isSelectedForArchive === true) {
          body.push(item.id);
        }
      });
      if (body.length > 0) {
        let apiURL = "";
        if (archived) {
          apiURL = "admin/unarchive-farms";
        } else {
          apiURL = "admin/archive-farms";
        }

        const apiResult = await axiosClient.put(apiURL, body, {
          headers: { token },
        });
        if (apiResult && apiResult.status === 200) {
          toast.success(
            `Data ${archived ? "Un Archived" : "Archived"} Successfully!`,
            {
              autoClose: 1400,
            }
          );
          setTimeout(() => {
            window.location.reload();
          }, 1500);
        }
      }
    } catch (error) {
      console.log(`Something went wrong when archiving farms -->>> `);
      console.log(error);
    }
  };

  function _checkArchivePermission() {
    if (!user?.permissions?.delete_farm) {
      alert("Don't have Delete Permission");
      return;
    }
    setShowConfirmModal(true);
  }

  function _checkUpdatePermission(data) {
    openViewEditFarmInfoModal(data);
  }

  const confirmBeforeArchive = async () => {
    await doArchive();
  };

  async function openViewEditFarmInfoModal(selectedFarmData) {
    updateSelectedFarmIdForModalView(selectedFarmData);
    axiosClient
      .get(`admin/farm/${selectedFarmData.id}`, { headers: { token } })
      .then((res) => {
        if (res.status === 200 && res.data && res.data.data) {
          setSelectedFarmDetails(res.data.data);
          localStorage.setItem("selectedfarmIdForEdit", JSON.stringify(selectedFarmData.id))
          localStorage.setItem("editMitraFarm", true)
          localStorage.setItem("editMitraFarmData", JSON.stringify(res.data.data))
          location('/farm-pipeline/create-new-mitra-farm')
          //setViewEditModalOpen(true);
        } else {
          toast.error("No data found for the selected farm!");
        }
      });
  }

  function _filterData(text) {
    let newArray = allFarmsData.filter((item) =>
      item.farm_name.toLowerCase().includes(text.toLowerCase())
    );
    setTableDataList(newArray);
  }

  async function _applyFilters(items) {
    setShowFilter(false);
    console.log(items);
    setFilterData(items)
    let res = await _apiFetchFarms({
      size,
      selectedPage,
      archived,
      ...items
      },
      location,
    );
    let { displayData, totalPage, totalItems } = res;
    setTableDataList(
      displayData.map((d) => {
        d.isSelectedForArchive = false;
        return d;
      })
    );
    setAllFarmsData(
      displayData.map((d) => {
        d.isSelectedForArchive = false;
        return d;
      })
    );
    setTotalPage(totalPage);
    setTotalItems(totalItems);
  }

  return (
    <div className={`${archived ? "h-[85%]" : "h-[100%]"}`}>
      {showConfirmModal ? (
        <ConfirmationModal
          label={`Do you really want to ${
            archived ? "un archive" : "archive"
          } this?`}
          hideModal={() => setShowConfirmModal(false)}
          onConfirm={() => {
            setShowConfirmModal(false);
            confirmBeforeArchive();
          }}
        />
      ) : null}
      {isViewEditModalOpened === true && selectedFarmDetails !== null ? (
        <EditFarmModal
          token={token}
          hideModal={() => {
            setViewEditModalOpen(false);
            _fetchFarms(selectedPage);
          }}
          selectedFarmData={selectedFarmDetails}
          metaData={metaData}
          visible={isViewEditModalOpened}
        />
      ) : null}
      {showFilter ? (
        <FarmFilterModal
          metaData={metaData}
          filterData={filterData}
          hideModal={() => setShowFilter(false)}
          applyFilters={(items) => _applyFilters(items)}
          resetFilter={() => {
            setShowFilter(false);
            setFilterData({});
            _fetchFarms(selectedPage, {});
          }}
        />
      ) : null}
      <div className="h-[100%] mt-4">
        <div className="h-[10%]">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <input
              type="checkbox"
              checked={allSelectedForArchive}
              className="mt-4"
              onChange={() => {
                if (tableDataList && tableDataList.length)
                  setTableDataList(
                    tableDataList.map((tData) => {
                      tData.isSelectedForArchive = !allSelectedForArchive;
                      return tData;
                    })
                  );
              }}
            ></input>
            <div className="ml-5 mt-4">
              {someSelectedForArchive === true ? (
                <BsArchiveFill
                  size={15}
                  onClick={() => _checkArchivePermission()}
                />
              ) : (
                <BsArchive size={15} />
              )}
            </div>
            <div className="w-[100%] ml-5">
              <TableSearchComp
              onChange={(text) => setSearchText(text)}
              openFilter={() => setShowFilter(true)}
              searchText={searchText}
              handleSearchSubmit={handleSearchSubmit}
              showTypeDropDown = {!archived}
              searchTypeValues={metaData?.searchableFeaturesWithOptions?.admin_farm_list}
              searchType={searchType}
              onSearchChangeType={(item) => setSearchType(item)} 
            />
            </div>
          </div>
        </div>
        <div className={`min-h-[80%] max-h-[80%] overflow-auto scrollbar-hide mt-5`}>
        <table className="w-full text-sm text-left">
        <thead class="text-sm text-gray-700 uppercase bg-gray-50">
            <tr>
        {FARM_PIPELINE_TB_COL.map((item) =>{
          return(
            <th scope="col" className="px-1 py-1 text-[#3EB049]">
              {item}
            </th>
          )
        })}
        </tr>
        </thead>
        <tbody>

          {tableDataList.map((data, i)=>{

            const picList=data?.pics.map((item)=>{
              return item.user_name;
            })
            const picString=picList.join(", ");

            return(
            <tr class="odd:bg-white  even:bg-gray-50  border-b">
                <td class="p-4">
                <input
                  type="checkbox"
                  checked={data.isSelectedForArchive}
                  onChange={() => {
                    const updatedData = [...tableDataList];
                    updatedData[i].isSelectedForArchive =
                      !updatedData[i].isSelectedForArchive;
                    setTableDataList(updatedData);
                  }}
                ></input>
                </td>
                <td className="p-4 text-sm text-[#0090FF] underline cursor-pointer" onClick={() => _checkUpdatePermission(data)}>
                    {data?.farm_name}
                </td>
                <td className="p-4">
                    {data?.contact_name}
                </td>
                <td className="p-4">
                    {picString}
                </td>
                <td className="flex p-4">
                  {data?.farm_types?.map((item, i) => {
                    return (
                      <div key={`tbfImg-${i}`}>
                        {item.farm_type_name === "Hydroponics" ? (
                          <img
                            className="w-[20px] h-[20px]"
                            alt="avatar"
                            src={ImgHydro}
                          />
                        ) : item.farm_type_name === "Soilless" ? (
                          <img
                            className="w-[20px] h-[20px]"
                            alt="avatar"
                            src={ImgSoilless}
                          />
                        ) : item.farm_type_name === "Open Field" ? (
                          <img
                            className="w-[20px] h-[20px]"
                            alt="avatar"
                            src={ImgOpenField}
                          />
                        ) : null}
                      </div>
                    );
                  })}
                </td>
                <td className="p-4">
                    {data?.farm_loc_province}
                </td>
                <td className="p-4">
                    {data?.farm_loc_district}
                </td>
                <td className="p-4">
                    {data?.relation}
                </td>
                
                <td key={`tbfc-${i+data?.id}`} className="">
                  <label className="relative inline-flex cursor-pointer">
                  <input
                  type="checkbox"
                  checked={data?.is_rab_enabled == true ? 1:0}
                  className="sr-only peer"
                  onChange={() => enableRAB(data?.id, !data?.is_rab_enabled)}/>
                <div className="w-11 h-6 bg-[#E8E9EB] rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-[#3EB049]"></div>
                  </label>
              </td>
            </tr>
            )
          })}
        </tbody>
        </table>
        </div>
        <div className="flex justify-end items-center h-[10%] text-sm px-5">
          {totalPage !== undefined ? (
            <TablePaginationComp
              totalPages={totalPage}
              active={selectedPage}
              selectedPage={(p) => {
                setSelectedPage(p);
                _fetchFarms(p, filterData);
              }}
              totalItems={totalItems}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};
