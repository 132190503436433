import React from "react";
import { BsCheck2 } from "react-icons/bs";
import loaderImage from "../images/loader_icon.gif";

export const LoaderModal = () => {
 

  return (
    <>
      <div className="flex overflow-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center">
        <div className="relative mt-[3%] mb-[3%]">
          {/*content*/}
          <div className="h-full first-letter:rounded-lg relative flex flex-col w-full outline-none focus:outline-none rounded-lg">
            <div className="relative pl-6 pr-6 overflow-auto pt-3 bg-transparent rounded-t-lg h-full">
            <img src={loaderImage} />
            </div>
            
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>{" "}
    </>
  );
};
