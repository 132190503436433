import React, { useEffect, useState } from "react";
import { USERS_TB_COL, USERS_TB_COL_BELEAF } from "..";
import { BsFillCaretDownFill, BsArchiveFill, BsArchive } from "react-icons/bs";
import { _apiFetchUsers, axiosClient } from "../../../api";
import { TableSearchComp } from "../../../components/TableSearchComp";
import { TablePaginationComp } from "../../../components/TablePaginationComp";
import { toast } from "react-toastify";
import { ConfirmationModal } from "../../../Models/ConfirmationModal";
import { UserEditModal } from "../../../Models/UserEditModal";

export const TableUsers = ({ archive, token, refreshTab, metaData }) => {
  const size = 10;
  const configFetchRef = React.useRef(false);
  const [totalPage, setTotalPage] = useState();
  const [totalItems, setTotalItems] = useState();
  const [selectedPage, setSelectedPage] = useState(0);
  const [tableDataList, setTableDataList] = useState([]);
  const [allUsersData, setAllUsersData] = useState([]);
  const [allSelectedForArchive, updateAllSelected] = useState(false);
  const [someSelectedForArchive, updateSomeSelected] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [checkBeleafe, setCheckBeleafe] = useState(true);
  const [searchText, setSearchText] = useState("")
  const [action, setAction] = useState("");


  const [showUserDetails, setShowUserDetails] = useState(false);
  const [userBody, setUserBody] = useState(null);
  let user = JSON.parse(localStorage.getItem("user_data"));



  useEffect(()=>{
    _fetchUserData(selectedPage, checkBeleafe)
  },[action]);

  const handleSearchSubmit = (action) =>{
    setAction(action)
    setSelectedPage(0)
    if(action === "reset"){
       setSearchText("");
       _fetchUserData(0,checkBeleafe)
    }
    if(searchText.length > 2 && action !== "reset"){
      _fetchUserData(0, checkBeleafe)
    }
  }



  useEffect(() => {
    if (configFetchRef.current) return;
    configFetchRef.current = true;
    _fetchUserData(selectedPage, checkBeleafe);
  }, []);

  useEffect(() => {
    _fetchUserData(selectedPage, checkBeleafe);
  }, [refreshTab, checkBeleafe, showUserDetails]);

  const handleUserTypeSelect = (checked) => {
    setCheckBeleafe(checked);
    setSelectedPage(0);
    _fetchUserData(0, checked);
  };

  useEffect(() => {
    const allSelected =
      tableDataList && tableDataList.length
        ? tableDataList.every((ele) => ele.isSelectedForArchive === true)
        : false;
    const allUnSelected =
      allSelected === false
        ? tableDataList && tableDataList.length
          ? tableDataList.every((ele) => ele.isSelectedForArchive === false)
          : true
        : false;
    updateAllSelected(allSelected);
    updateSomeSelected(!allUnSelected);
  }, [tableDataList]);

  async function _fetchUserData(page, check) {
    const res = await _apiFetchUsers(size, page + 1, archive, check, searchText);
    setTableDataList(res.tableDataList);
    setAllUsersData(res.allUsersData);
    setTotalPage(res.totalPage);
    setTotalItems(res.totalItems);
  }

  const doArchive = async () => {
    try {
      const body = [];
      tableDataList.forEach((item) => {
        if (item.isSelectedForArchive === true) {
          body.push(item.id);
        }
      });
      if (body.length > 0) {
        let apiURL = "";
        if (archive) {
          apiURL = "admin/unarchive-users";
        } else {
          apiURL = "admin/archive-users";
        }
        const apiResult = await axiosClient.put(apiURL, body, {
          headers: { token },
        });
        if (apiResult && apiResult.status === 200) {
          toast.success(
            `User ${archive ? "Un Archived" : "Archived"} Successfully!`,
            {
              autoClose: 1400,
            }
          );
          setTimeout(() => {
            window.location.reload();
          }, 1500);
        }
      }
    } catch (error) {}
  };

  function _checkArchivePermission() {
    if (!user?.permissions?.delete_user) {
      alert("Don't have Delete Permission");
      return;
    }
    setShowConfirmModal(true);
  }

  const confirmBeforeArchive = async () => {
    await doArchive();
  };

  // function _filterData(text) {
  //   let newArray = allUsersData.filter((item) =>
  //     item.user_name.toLowerCase().includes(text.toLowerCase())
  //   );
  //   setTableDataList(newArray);
  // }

  const _getUserTableCol = () => {
    if (checkBeleafe) {
      return USERS_TB_COL_BELEAF;
    } else {
      return USERS_TB_COL;
    }
  };

  const handleUserDetails = (item) => {
    if (item) {
      setUserBody(item);
      setShowUserDetails(true);
    }
  };

  const getRoleByRoleIds = (str) => {
    let roleArray = [];
    if (checkBeleafe) {
      roleArray = metaData?.adminRoles;
      return roleArray && roleArray.filter((item) => item.id === str)[0]?.role;
    } else {
      roleArray = metaData?.userRoles;
    }
  };

  return (
    <div className={`${archive ? "h-[85%]" : "h-[100%]"}`}>
      {showUserDetails ? (
        <UserEditModal
          hideModal={() => {
            setShowUserDetails(false);
          }}
          metaData={metaData}
          checkBeleafe={checkBeleafe}
          userBody={userBody}
        />
      ) : null}

      {showConfirmModal ? (
        <ConfirmationModal
          label={`Do you really want to ${
            archive ? "un archive" : "archive"
          } this?`}
          hideModal={() => setShowConfirmModal(false)}
          onConfirm={() => {
            setShowConfirmModal(false);
            confirmBeforeArchive();
          }}
        />
      ) : null}
      <div className="h-[14%]">
        <div className="mt-3 flex">
          <div>
            <input
              type="checkbox"
              checked={checkBeleafe}
              name="BeleafOS User"
              onChange={(e) => {
                handleUserTypeSelect(e.target.checked);
              }}
            />
            <label className="text-[16px] font-normal ml-2">
              BeleafOS User
            </label>
          </div>
          <div className="ml-10">
            <input
              type="checkbox"
              name="MitraOS User"
              checked={!checkBeleafe}
              onChange={(e) => {
                handleUserTypeSelect(!e.target.checked);
              }}
            />
            <label className="text-[16px] font-normal ml-2">MitraOS User</label>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <input
            type="checkbox"
            checked={allSelectedForArchive}
            className="mt-4"
            onChange={() => {
              if (tableDataList && tableDataList.length)
                setTableDataList(
                  tableDataList.map((tData) => {
                    tData.isSelectedForArchive = !allSelectedForArchive;
                    return tData;
                  })
                );
            }}
          ></input>
          <div className="ml-5 mt-4">
            {someSelectedForArchive === true ? (
              <BsArchiveFill
                size={15}
                onClick={() => _checkArchivePermission()}
              />
            ) : (
              <BsArchive size={15} />
            )}
          </div>
          <div className="w-[100%] ml-5 mt-4">
            <TableSearchComp
              handleSearchSubmit={handleSearchSubmit}
              onChange={(text) => setSearchText(text)}
              showFilterBtn={false}
              searchText={searchText}
              showTypeDropDown={false}
            />
          </div>
        </div>
      </div>
      <div className="h-[71%] overflow-auto scrollbar-hide mt-5">
        <table className="w-full">
          <thead>
            <tr className="sticky top-0">
              <td></td>
              {_getUserTableCol().map((col, i) => {
                if (col.Header === "USER ID") {
                  return;
                }
                return (
                  <td
                    className="text-sm text-center p-[0px] m-[0px] bg-white"
                    key={`tfhk-${i}`}
                  >
                    <div className="flex justify-center items-center py-2 border-gray-200 border-b-[1px]">
                      <p className="text-sm text-[#3EB049]">{col.Header}</p>
                      {/* {col.Header !== "FARM ID" ? (
                        <BsFillCaretDownFill className="ml-2" size={10} />
                      ) : null} */}
                    </div>
                  </td>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {tableDataList.map((data, i) => {
              return (
                <tr key={`row-${i}`} className="border-gray-200 border-b-[1px]">
                  <td>
                    <input
                      type="checkbox"
                      checked={data.isSelectedForArchive}
                      onChange={() => {
                        const updatedData = [...tableDataList];
                        updatedData[i].isSelectedForArchive =
                          !updatedData[i].isSelectedForArchive;
                        setTableDataList(updatedData);
                      }}
                    ></input>
                  </td>
                  {_getUserTableCol().map((col, i) => {
                    if (col.Header === "USER ID") {
                      return;
                    }
                    // else if (
                    //   col.Header === "NAME" ||
                    //   col.Header === "EMAIL"
                    // ) {
                    //   return (
                    //     <td
                    //       key={`tbfc-${i}`}
                    //       className="text-sm text-[#0090FF] underline cursor-pointer"
                    //     >
                    //       <p
                    //         title={
                    //           data[col.accessor] == null
                    //             ? ""
                    //             : data[col.accessor].toString()
                    //         }
                    //         className="text-sm text-center"
                    //       >
                    //         {data[col.accessor] == null
                    //           ? ""
                    //           : data[col.accessor].toString()}
                    //       </p>
                    //     </td>
                    //   );
                    // }
                    else if (col.Header === "NAME") {
                      return (
                        <td key={`tbfc-${i}`} className="py-2 max-w-[150px]">
                          <p
                            className="text-sm  text-center text-[#0090FF] underline cursor-pointer"
                            onClick={() => handleUserDetails(data)}
                          >
                            {data[col.accessor]}
                          </p>
                        </td>
                      );
                    } else if (col.Header === "ROLE") {
                      return (
                        <td key={`tbfc-${i}`} className="py-2 max-w-[150px]">
                          <p className="text-sm  text-center">
                            {getRoleByRoleIds(data[col.accessor])}
                          </p>
                        </td>
                      );
                    } else {
                      return (
                        <td key={`tbfc-${i}`} className="py-2">
                          <p
                            title={
                              data[col.accessor] == null
                                ? ""
                                : data[col.accessor].toString()
                            }
                            className="text-sm text-center"
                          >
                            {data[col.accessor] == null
                              ? ""
                              : data[col.accessor].toString()}
                          </p>
                        </td>
                      );
                    }
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="flex justify-end items-center h-[10%] text-sm px-5">
        {totalItems !== undefined ? (
          <TablePaginationComp
            active={selectedPage || 0}
            selectedPage={(p) => {
              setSelectedPage(p);
              _fetchUserData(p, checkBeleafe);
            }}
            totalItems={totalItems}
          />
        ) : null}
      </div>
    </div>
  );
};
