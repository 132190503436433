import { useState, useEffect, Fragment } from "react";
import { toast } from "react-toastify";
import { InputFieldComp } from "../components/InputFieldComp";
import DropDownComp from "../components/DropDownComp";
import closeImg from "../images/FarmPipeLine/close.png";
import { BsPlus } from "react-icons/bs";
import { Combobox, Transition } from "@headlessui/react";
import { _asyncGetUserFarmsByStaffTypeId, _asyncAddNewUser, _asyncGetUserRoleSubordinates, _asyncGetUserRoles } from "../api";
import { RiDeleteBin5Line } from "react-icons/ri";
import {CheckIcon, ChevronUpDownIcon, ChevronDownIcon} from "@heroicons/react/20/solid";
import SearchDropDownComp from "../components/SearchDropDownComp";
import Multiselect from "multiselect-react-dropdown";

export const AddNewUserModal = ({ hideModal, metaData, token }) => {
  const userStaffTypesList = metaData.userStaffTypes;
  const [userRolesList, setUserRolesList] = useState();
  const [userRolesListMitra, setUserRolesListMitra] = useState(metaData.userRoles);
  const [selectedStaffTypeId, updateStaffTypeId] = useState("1");
  const [username, updateUserName] = useState("");
  const [contactNumber, updateContactNumber] = useState("");
  const [email, updateEmail] = useState("");
  const [nik, updateNIK] = useState("");
  const [farmRoleInfo, updateFarmRoleInfo] = useState([
   { selectedFarm: null, roleId: userRolesListMitra[0].id },
  ]);
  const [canDelete, updateDeleteFlag] = useState(farmRoleInfo.length > 1);
  const [allFarms, updateAllFarms] = useState([]);
  const [query, setQuery] = useState("");
  const [listOfRoles, setListOfRoles] = useState(0);
  const [showRoles, setShowRoles] = useState(false);
  const [subordinates, setSubordinates] = useState([]);
  const [selectedSubordinates, setSelectedSubordinates] = useState([]);
  const [showSubordinates, setShowSubordinates] = useState(false);
  const [isMerchandiseUser, setIsMerchandiseUser] = useState(false);

  const filteredFarms =
    query === ""
      ? allFarms
      : allFarms.filter((farm) => {
          return farm.farm_name.toLowerCase().includes(query.toLowerCase());
        });

  async function _getUserFarms() {
    const data = await _asyncGetUserFarmsByStaffTypeId(selectedStaffTypeId);
    if (!data) return;
    setQuery("");
    updateAllFarms([...data]);
  }

  async function _getUserRoles() {
    const data = await _asyncGetUserRoles();
    if (!data) return;
    setUserRolesList([...data]);
  }

  

  const handleUserOnChange = async (item) =>{
    setListOfRoles(parseInt(item.id+''))
    setShowSubordinates(item.can_have_sub_ordinates);
    if(item?.can_have_sub_ordinates){
      let res  = await _asyncGetUserRoleSubordinates(item.id)
      setSubordinates(res);
    }
  }

  const handleSubordinatesOnChange = (event) =>{
    let userList = [];
      event.map((item) => {
        userList.push(item);
      });
    setSelectedSubordinates(userList);
  }

  useEffect(() => {
    updateFarmRoleInfo([{ selectedFarm: null, roleId: userRolesListMitra[0].id }]);
    updateDeleteFlag(false);
    _getUserFarms();
    _getUserRoles();
  }, [selectedStaffTypeId]);

const TabsUI = () => {
    let styling = "bg-white cursor-pointer rounded-t-2xl px-4 text-[#3EB049]";
    return (
      <ul className="flex">
        <li>
          <div className={`${styling}`} style={{}}>
            {/* <li> */}
            <p className={"p-4 text-[15px] font-normal font-poppins"}>
              Add New
            </p>
            {/* </li> */}
          </div>
        </li>
      </ul>
    );
  };

  const getFarmRoleUIRow = (index) => {
    return (
      <div className="mt-3 grid grid-cols-3 gap-2">
        <div>
          <div className="flex items-center">
            <p className="pl-1 pb-1 text-[#8A9099] text-sm">Farm Name</p>
            <p className="text-red-500">*</p>
          </div>
          <div>
            <Combobox
              value={
                farmRoleInfo[index].selectedFarm
                  ? farmRoleInfo[index].selectedFarm.farm_name
                  : ""
              }
              onChange={(data) => {
                console.log(data);
                updateFarmRoleInfo((prevValue) => {
                  const newValue = [...prevValue];
                  newValue[index].selectedFarm = data;
                  return newValue;
                });
              }}
            >
              <div>
                <div className="relative w-full cursor-default overflow-hidden bg-white text-left border border-gray-300 rounded-lg focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
                  <Combobox.Input
                    className="h-[48px] w-full border-none outline-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                    onChange={(event) => setQuery(event.target.value)}
                    placeholder="Type farm name..."
                  />
                  <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronUpDownIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </Combobox.Button>
                </div>
                <Transition
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                  afterLeave={() => setQuery("")}
                >
                  <Combobox.Options className="mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    {filteredFarms.length === 0 && query !== "" ? (
                      <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                        Nothing found.
                      </div>
                    ) : (
                      filteredFarms.map((farm) => (
                        <Combobox.Option
                          key={farm.id}
                          value={farm}
                          className={({ active }) =>
                            `relative cursor-default select-none py-2 pl-10 pr-4 ${
                              active
                                ? "bg-teal-600 text-white"
                                : "text-gray-900"
                            }`
                          }
                        >
                          {({ selected, active }) => (
                            <>
                              <span
                                className={`block truncate ${
                                  selected ? "font-medium" : "font-normal"
                                }`}
                              >
                                {farm.farm_name}
                              </span>
                              {selected ? (
                                <span
                                  className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                    active ? "text-white" : "text-teal-600"
                                  }`}
                                >
                                  <CheckIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Combobox.Option>
                      ))
                    )}
                  </Combobox.Options>
                </Transition>
              </div>
            </Combobox>
          </div>
        </div>
        <div>
          <div className="flex">
            <p className="text-[#8A9099] text-sm">Role</p>
            <p className="text-red-500">*</p>
          </div>

          <DropDownComp
            listData={userRolesListMitra}
            type={"role"}
            onSelected={(item) => {
              updateFarmRoleInfo((prevRoles) => {
                const updated = [...prevRoles];
                updated[index].roleId = item.id;
                return updated;
              });
            }}
            customStyles={
              "rounded-md justify-between text-[#3E4347] text-[14px]"
            }
            customMenuStyle={"relative inline-block text-left w-full"}
          />
        </div>
        {canDelete === true ? (
          <div
            onClick={() => {
              if (farmRoleInfo.length > 1) {
                updateFarmRoleInfo((prevRoles) => {
                  const updated = [...prevRoles];
                  updated.splice(index, 1);
                  updateDeleteFlag(updated.length > 1);
                  return updated;
                });
              }
            }}
            className="flex ml-2 text-red-400 cursor-pointer items-end"
          >
            <RiDeleteBin5Line size={20} />
          </div>
        ) : null}
      </div>
    );
  };

  const submitAndAddNewUser = async () => {

    if (!selectedStaffTypeId) {
      toast.error(`Please select a staff type.`);
      return;
    }
    if (!username) {
      toast.error(`Please enter a valid user name.`);
      return;
    }
    if (!contactNumber) {
      toast.error(`Please enter a valid contact number.`);
      return;
    }

    let body = {};
    if (selectedStaffTypeId === "1") {
      body = {
        user_name: username,
        contact_number: contactNumber,
        email: email,
        role_id: listOfRoles,
      };
      if(selectedSubordinates.length > 0){
        body.subordinate_ids = selectedSubordinates.map(({ id }) => id)
      }
    }

    if (selectedStaffTypeId === "2" && isMerchandiseUser) {
      if (!nik) {
        toast.error(`Please enter a valid nik number.`);
        return;
      }
      body = {
        user_name: username,
        contact_number: contactNumber,
        is_merchandiser: isMerchandiseUser,
        nik: nik,
      };
    }
    if (!isMerchandiseUser && selectedStaffTypeId === "2") {
      if (!nik) {
        toast.error(`Please enter a valid nik number.`);
        return;
      }
      if (!farmRoleInfo || farmRoleInfo.length === 0) {
        toast.error(
          `Please provide the farms info for the user being created.`
        );
        return;
      }
      const farms = [];
      const alreadyTaken = {};
      for (let i = 0; i < farmRoleInfo.length; i++) {
        const selectedFarm = farmRoleInfo[i].selectedFarm;
        if (!selectedFarm) {
          toast.error(`Please enter valid farm info in row ${i + 1}`);
          return;
        }
        if (!alreadyTaken[selectedFarm.id]) {
          alreadyTaken[selectedFarm.id] = true;
          farms.push({
            farm_id: selectedFarm.id,
            role_id: farmRoleInfo[i].roleId,
          });
        } else {
          toast.error(
            `The same farm (${selectedFarm.farm_name}) is selected more than once. This is not allowed.`
          );
          return;
        }
      }
      if (farms.length === 0) {
        toast.error(
          `Please provide the farms info for the user being created.`
        );
        return;
      }
      body = {
        user_name: username,
        contact_number: contactNumber,
        nik: nik,
        farms: farms,
      };
    }
    const apiResponse = await _asyncAddNewUser(body, selectedStaffTypeId);
    if (apiResponse === 1) {
      toast.success("User added Sucessfully.");
      hideModal();
      return;
    }
    toast.error(apiResponse);
    return;
  };

  return (
    <>
      <div className="flex overflow-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-full mt-[3%] ml-[20%] mr-[20%] mb-[3%]">
          {/*content*/}
          <div className="h-full border-0 rounded-lg shadow-lg relative flex flex-col w-full outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-start justify-between border-slate-200 rounded-t">
              <TabsUI />
            </div>
            {/*body*/}
            <div className="relative pl-6 pr-6 overflow-auto pt-3 bg-white rounded-tr-lg h-full">
              <img
                className="float-right w-[30px] h-[30px]"
                src={closeImg}
                alt="closeImg"
                onClick={() => hideModal()}
              />
              <p className="text-lg mb-5">Add New User</p>
              <>
                <p className="text-sm mb-2 ml-2">Staff Status</p>
                <div className="flex">
                  <div className="flex border border-gray-300 px-1 py-1 rounded-full">
                    {userStaffTypesList.map((item, i) => {
                      return (
                        <div
                          key={i}
                          onClick={() =>updateStaffTypeId(item.id)}
                          className={`${
                            selectedStaffTypeId === item.id
                              ? "bg-[#3EB049] text-white"
                              : ""
                          } px-3 py-2 rounded-full cursor-pointer text-sm`}
                        >
                          <p>{item.staff_type}</p>
                        </div>
                      );
                    })}
                  </div>
                  {selectedStaffTypeId === "2" && <label className="ml-3 mt-3 relative inline-flex cursor-pointer">
                  <input
                  type="checkbox"
                  checked={isMerchandiseUser}
                  className="sr-only peer"
                  onChange={() => setIsMerchandiseUser(!isMerchandiseUser)}
                  />
                  
                <div className="w-11 h-6 bg-[#E8E9EB] rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-[#3EB049]"></div>
                    <p className="ml-2">Merchandise user</p>
                
                  </label>}
                </div>
                <div className="mt-3 grid grid-cols-2 gap-2">
                  <div>
                    <InputFieldComp
                      label={"Name"}
                      compulsory={true}
                      placeholder={"User Name"}
                      value={username}
                      onChange={(text) => updateUserName(text)}
                    />
                  </div>
                  <div>
                    <InputFieldComp
                      label={"Contact Number"}
                      compulsory={true}
                      placeholder={"08765432167"}
                      value={contactNumber}
                      onChange={(text) => updateContactNumber(text)}
                    />
                  </div>
                </div>

                {selectedStaffTypeId === "1" ? (
                  <div className="mt-3 grid grid-cols-2 gap-2">
                    <div>
                    <div className="mt-1">
                      <InputFieldComp
                        label={"Email"}
                        compulsory={true}
                        placeholder={"john.doe@beleaf.co.id"}
                        value={email}
                        onChange={(text) => updateEmail(text)}
                      />
                      </div>
                    </div>
                    <div>
                      <div>
                        <div className="flex">
                          <p className="text-[14px] text-black">Role</p>
                          <p className="text-red-500">*</p>
                        </div>
                      <SearchDropDownComp
                        list={userRolesList || []}
                        type={"role"}
                        onSelected={(item) => handleUserOnChange(item)}
                        customStyles={"rounded-md justify-between text-[#3E4347] text-[14px]"}
                        customMenuStyle={"relative inline-block text-left w-full"}
                      />
                      </div>
                    </div>
                    {showSubordinates ? 
                    <div>
                      <div>
                        <div className="flex">
                          <p className="text-[14px] text-black">Subordinates</p>
                          {/* <p className="text-red-500">*</p> */}
                        </div>
                     
                            <div className="w-full">
                            <Multiselect
                              displayValue="user_name"
                              chipColor="#40AE49"
                              selectedValues={selectedSubordinates || []}
                              options={subordinates || []}
                              onSelect={handleSubordinatesOnChange}
                              onRemove={handleSubordinatesOnChange}
                              showCheckbox
                              style={{chips:{background: "#40AE49" }}}
                            />
                          </div>
                      </div>
                    </div>:null}
                  </div>
                ) : null}
                <div className=" mt-3">
                  {selectedStaffTypeId === "2" ? (
                    <InputFieldComp
                      label={"NIK"}
                      compulsory={true}
                      placeholder={"30134567894566"}
                      value={nik}
                      onChange={(text) => updateNIK(text)}
                    />
                  ) : null}
                </div>

                {!isMerchandiseUser && selectedStaffTypeId === "2" &&
                farmRoleInfo &&
                farmRoleInfo.length
                  ? farmRoleInfo.map((data, index) => {
                      return getFarmRoleUIRow(index);
                    })
                  : null}

                {!isMerchandiseUser && selectedStaffTypeId === "2" ? (
                  <div
                    className="flex mt-10 cursor-pointer text-green bg-gray100/20 justify-center items-center py-2"
                    onClick={() => {
                      for (let i = 0; i < farmRoleInfo.length; i++) {
                        const info = farmRoleInfo[i];
                        if (!info.selectedFarm) {
                          toast.error(
                            `Please provide farm name in row ${i + 1}`
                          );
                          return;
                        }
                        if (!info.roleId) {
                          toast.error(`Please select a role in row ${i + 1}`);
                          return;
                        }
                      }
                      updateFarmRoleInfo((prevRoles) => {
                        const newRoles = [...prevRoles];
                        newRoles.push({
                          selectedFarm: null,
                          roleId: userRolesList[0].id,
                        });
                        updateDeleteFlag(newRoles.length > 1);
                        return newRoles;
                      });
                    }}
                  >
                    <p className="mr-2">Add Additional Farm</p>
                    <BsPlus size={22} />
                  </div>
                ) : null}
              </>
            </div>
            {/*footer*/}
            <div className="flex items-center p-6 rounded-b-lg bg-white w-full">
              <div className="grid grid-cols-2 gap-2 w-full">
                <div></div>
                <div className="justify-end flex">
                  <button
                    className="text-[#3EB049] border border-[#3EB049] mr-10 px-5 py-1 rounded-full"
                    type="button"
                    onClick={() => submitAndAddNewUser()}
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};
