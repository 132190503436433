import React, { useEffect, useState } from "react";
import {_fetchMetaData } from "../api";
import { RequestLists } from "../config/tables/RequestList";

export const RequestList = () => {
  let user = JSON.parse(localStorage.getItem("user_data"));
  const [selectedFilter, setSelectedFilter] = useState({id:'', status:'All'});
  const [metaData, setMetaData] = useState();

  useEffect(() => {
    _fetchData()
  }, []);

  async function _fetchData() {
    let metaData = await _fetchMetaData();
    setMetaData(metaData);
    
  }

  return (
    <div className="h-[80%]">
      <div className="bg-[#D0D0D0] scrollbar-hide pl-2 py-2 flex flex-no-wrap overflow-x-scroll scrolling-touch items-start mb-8">
        {metaData?.consumablesRequestStatuses && [{id: '', status: "All"}, ...metaData?.consumablesRequestStatuses]?.map((item) => {
          return (
            <div
              onClick={() => {
                setSelectedFilter(item)
              }}
              key={item?.status}
              className={`flex-none cursor-pointer items-center justify-between ${
                selectedFilter?.status === item?.status
                  ? "bg-[#40AE49] text-white"
                  : "bg-[#808080] text-[#D0D0D0]"
              } mr-4 py-2 w-[150px] ml-3 px-2 rounded-xl shadow-sm`}
            >
              <div style={{textAlign:'center'}}>
                <p className={`text-[16px] font-semibold`}>
                  {item?.status}
                </p>
              </div>
            </div>
          );
        })}
      </div>
      <RequestLists
        metaData={metaData}
        selectedFilter={selectedFilter}
        />
    </div>
  );
};
