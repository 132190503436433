import React, { useEffect, useState } from "react";
import { CAREER_TB_COL, USERS_TB_COL, USERS_TB_COL_BELEAF } from "..";
import { _apiFetchUsers, _asyncDeleteJobs, axiosClient, axiosHeader } from "../../../api";
import { TableSearchComp } from "../../../components/TableSearchComp";
import { TablePaginationComp } from "../../../components/TablePaginationComp";
import { toast } from "react-toastify";
import { ConfirmationModal } from "../../../Models/ConfirmationModal";
import { UserEditModal } from "../../../Models/UserEditModal";
import deleteIcon from "../../../images/deleteIcon.png";

export const TableCareer = ({ archive, token, refreshTab, metaData }) => {
  const size = 10;
  const configFetchRef = React.useRef(false);
  const [totalPage, setTotalPage] = useState();
  // const [selectedIds, setSelectedIds] = useState([]);
  const [deletedIds, setDeletedIds] = useState([]);
  const [totalItems, setTotalItems] = useState([]);
  const [selectedPage, setSelectedPage] = useState(0);
  const [tableDataList, setTableDataList] = useState([]);
  const [allUsersData, setAllUsersData] = useState([]);
  const [allSelectedForArchive, updateAllSelected] = useState(false);
  const [someSelectedForArchive, updateSomeSelected] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [checkBeleafe, setCheckBeleafe] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [action, setAction] = useState("");
  const [sortValue, setSortValue] = useState({ id: "3", name: "Sort By" });
  console.log("itemmmmm",sortValue);
  const [jobList, setJobList] = useState([]);
  const [isChecked, setIsChecked] = useState(false)

  const sortType = [
    { id: "1", name: "Latest" },
    { id: "2", name: "Earliest" },
  ];

  const [showUserDetails, setShowUserDetails] = useState(false);
  const [userBody, setUserBody] = useState(null);
  let user = JSON.parse(localStorage.getItem("user_data"));

  // useEffect(() => {
  //   _fetchUserData( checkBeleafe);
  // }, [action]);

  useEffect(() => {
    _fetchJobList(0);
  }, []);

  const _fetchJobList = async (page,search) => {
    try {
      const response = await fetch(
        `https://api.beleafmanagement.io/rest/career/jobs?page=${page+1}&size=10&sort_order=${sortValue?.id=='1'?'ASC':'DESC'}&search_text=${search===''?search:searchText}`
      );
      const json = await response.json();
      console.log("masterrrrr0987890---", json?.data);
      setTotalItems(json?.data?.totalItems);
      setJobList(json?.data?.data);
      setTotalPage(json?.data?.last_page)
    } catch (error) {
      console.error(error);
    }
  };

  const handleSearchSubmit = (action) => {
    setAction(action);
    setSelectedPage(0);
    if (action === "reset") {
      setSearchText("");
      _fetchJobList(0,'');
    }
    if (searchText.length > 2 && action !== "reset") {
      _fetchJobList(0);
    }
  };

  // useEffect(() => {
  //   if (configFetchRef.current) return;
  //   configFetchRef.current = true;
  //   _fetchUserData(checkBeleafe);
  // }, []);

  // useEffect(() => {
  //   _fetchUserData( checkBeleafe);
  // }, [refreshTab, checkBeleafe, showUserDetails]);

  // const handleUserTypeSelect = (checked) => {
  //   setCheckBeleafe(checked);
  //   setSelectedPage(0);
  //   _fetchUserData(0, checked);
  // };

  useEffect(() => {
    const allSelected =
      tableDataList && tableDataList?.length
        ? tableDataList.every((ele) => ele.isSelectedForArchive === true)
        : false;
    const allUnSelected =
      allSelected === false
        ? tableDataList && tableDataList.length
          ? tableDataList.every((ele) => ele.isSelectedForArchive === false)
          : true
        : false;
    updateAllSelected(allSelected);
    updateSomeSelected(!allUnSelected);
  }, [tableDataList]);

  async function _fetchUserData(page, check) {
    const res = await _apiFetchUsers(
      size,
      page + 1,
      archive,
      check,
      searchText
    );
    setTableDataList(res.tableDataList);
    setAllUsersData(res.allUsersData);
    // setTotalPage(res.totalPage);
    // setTotalItems(res.totalItems);
  }


  function _checkArchivePermission() {
    if (!user?.permissions?.delete_user) {
      alert("Don't have Delete Permission");
      return;
    }
    setShowConfirmModal(true);
  }

  const confirmBeforeArchive = async () => {
    let Token = axiosHeader();
    Token = Token?.headers?.token;
  
    if (Token) {
      console.log('Authorization token is missing',Token);
    }
  
    let body = {
      job_ids: deletedIds,
    };
  
   await fetch('https://api.beleafmanagement.io/rest/career/jobs', {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'token': Token 
      },
      body: JSON.stringify(body)
    })
    .then(response => {
      if (!response.ok) {
        return response.text().then(text => {
          toast.info('Network response was not ok: ' + response?.status + ' ' + response?.statusText + ' ' + text);
        });
      }
      return response.json(); // or response.text() if you expect plain text
    })
    .then(data => {
      toast.info(data?.message)
      _fetchJobList(0)
      setIsChecked(false)
    })
    .catch(error => {
      console.error('There was a problem with the fetch operation:', error);
    });
  };
  

  // function _filterData(text) {
  //   let newArray = allUsersData.filter((item) =>
  //     item.user_name.toLowerCase().includes(text.toLowerCase())
  //   );
  //   setTableDataList(newArray);
  // }

  const _getUserTableCol = () => {
    return CAREER_TB_COL;
  };



  // console.log(JSON.stringify(jobList),'--------');

 

  return (
    <div className={`${archive ? "h-[85%]" : "h-[100%]"}`}>
      {showUserDetails ? (
        <UserEditModal
          hideModal={() => {
            setShowUserDetails(false);
          }}
          metaData={metaData}
          checkBeleafe={checkBeleafe}
          userBody={userBody}
        />
      ) : null}

      {showConfirmModal? (
        <ConfirmationModal
          label={`Do you really want to Delete this`}
          hideModal={() => setShowConfirmModal(false)}
          onConfirm={() => {
            setShowConfirmModal(false);
            confirmBeforeArchive();
          }}
          label2="No"
          label3="Yes"
        />
      ) : null}
      <div className="h-[14%]">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
      
          <div className="ml-5 mt-4">
          
            <img
              className="h-[3vh] w-[100%] mt-[5%] ml-[2%] cursor-pointer"
              src={deleteIcon}
              onClick={() =>{
                if (deletedIds?.length>0) {
                  _checkArchivePermission()
                }
              }}
              alt="Delete Icon"
              
            />
          </div>
          <div className="w-[100%] ml-5 mt-4">
            <TableSearchComp
              onChange={(text) => setSearchText(text)}
              openFilter={() => {}}
              handleSearchSubmit={handleSearchSubmit}
              sortType={sortType}
              sortValue={sortValue}
              handleSortBy={(item) => setSortValue(item)}
              searchText={searchText}
              sortBy={true}
              // searchTypeValues={
              //   metaData?.searchableFeaturesWithOptions?.planting_list
              // }
            />
          </div>
        </div>
      </div>
      <div className="h-[71%] overflow-auto scrollbar-hide mt-5">
        <table className="w-full">
          <thead>
            <tr className="sticky top-0">
              <td></td>
              {_getUserTableCol().map((col, i) => {
                if (col.Header === "USER ID") {
                  return;
                }
                return (
                  <td
                    className="text-sm text-center p-[0px] m-[0px] bg-white"
                    key={`tfhk-${i}`}
                  >
                    <div className="flex justify-center items-center py-2 border-gray-200 border-b-[1px]">
                      <p className="text-sm text-[#3EB049]">{col.Header}</p>
                      {/* {col.Header !== "FARM ID" ? (
                        <BsFillCaretDownFill className="ml-2" size={10} />
                      ) : null} */}
                    </div>
                  </td>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {jobList?.map((data, i) => {
              
              return (
                <tr key={`row-${i}`} className="border-gray-200 border-b-[1px]">
                  <td>
                    <input
                      type="checkbox"
                      checked={!isChecked ? isChecked : data.isSelectedForArchive}
                      onChange={(event) => {
                        setIsChecked(true)
                        let arr = [...deletedIds];
                        let isFilterApplied = arr.some(
                          (obj) => obj === data?.id
                        );
                        if (isFilterApplied && !event.target.checked) {
                          let index = arr.findIndex((obj) => obj === data?.id);
                          arr.splice(index, 1);
                        } else {
                          arr.push(data?.id);
                        }
                        setDeletedIds(arr);
                      }}
                    ></input>
                  </td>
                  {_getUserTableCol().map((col, i) => {
                    if (col.Header === "USER ID") {
                      return;
                    } else if (col.Header === "JOB POSITION") {
                      return (
                        <td key={`tbfc-${i}`} className="py-2 max-w-[150px]">
                          <p className="text-sm  text-center ">
                            {data?.job_position}
                          </p>
                        </td>
                      );
                    } else if (col.Header === "PLACEMENT") {
                      return (
                        <td key={`tbfc-${i}`} className="py-2 max-w-[150px]">
                          <p className="text-sm  text-center">
                            {data?.job_location?.name}
                          </p>
                        </td>
                      );
                    } else if (col.Header === "TEAM") {
                      return (
                        <td key={`tbfc-${i}`} className="py-2 max-w-[150px]">
                          <p className="text-sm  text-center">
                            {data?.department?.name}
                          </p>
                        </td>
                      );
                    } else {
                      return (
                        <td key={`tbfc-${i}`} className="py-2">
                          <p
                            title={
                              data[col.accessor] == null
                                ? "iuix"
                                : data[col.accessor].toString()
                            }
                            className="text-sm text-center"
                          >
                            {data[col.accessor] == null
                              ? ""
                              : data?.experience?.name.toString()}
                          </p>
                        </td>
                      );
                    }
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="flex justify-end items-center h-[10%] text-sm px-5">
        {totalItems !== undefined ? (
          <TablePaginationComp
          pages={totalPage}
            active={selectedPage || 0}
            selectedPage={(p) => {
              setSelectedPage(p);
              _fetchJobList(p);
            }}
            totalItems={totalItems}
          />
        ) : null}
      </div>
    </div>
  );
};
