import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { _asyncGetMerchandiserDetails, _fetchMetaData } from "../../api";
import { PageLayoutComp } from "../../components/PageLayoutComp";
import { getDateFormatMoment } from "../../config/localdata";
import { ReactComponent as CalenderIcon } from "../../../src/images/ICONS/Date.svg";
import { IoIosArrowRoundBack } from "react-icons/io";
import { ImageSliderModal } from "../../Models/ImageSlider";
import { DatePickerComp } from "../../components/DatePickerComp";



export const DailyReportDetail = () => {
  
  const location = useNavigate();
  let user = JSON.parse(localStorage.getItem("user_data"));
  const params = useParams();
  const [metaData, setMetaData] = useState();
  const [data, setData] = useState();
  const [showCropReport, setShowCropReport] = useState(false);
  const [images, setImages] = useState([]);
  const [reportDate,setReportDate] = useState(
    (new Date().getMonth() +1) + "/" + new Date().getDate()  + "/" + new Date().getFullYear()
  );

  async function _fetchInventoryDetails() {
       let data = {
      report_date: reportDate
    };
    const res = await _asyncGetMerchandiserDetails(params?.id,data);
    setData(res);
  }

  const handleImgClick = (item) => {
    setImages(item);
    setShowCropReport(true);
  };

  const  createGoogleMapsLink = (latitude, longitude)=> {
    const href = `https://www.google.com/maps/?q=${latitude},${longitude}`;
    return href;
  }

  const handleDetails = () =>{
      location('/merchandise-tracker')
  }

  useEffect(() => {
    _fetchData();
    _fetchInventoryDetails();
  }, [reportDate]);

  useEffect(() => {
    _fetchData();
    _fetchInventoryDetails();
  }, []);

  async function _fetchData() {
    let metaData = await _fetchMetaData();
    setMetaData(metaData);
  }

  return (
    <div className="w-screen h-screen">
      <PageLayoutComp metaData={metaData} user={user}>
      {showCropReport ? (
          <ImageSliderModal
            hideModal={() => {
              setShowCropReport(false);
            }}
            images={images}
          />
        ) : null}
        <div className=" h-[100%] bg-[#E6F1ED] p-4">
          <div className="h-[7%]">
            <div className="flex justify-between">
              <p className="text-3xl font-bold mb-0">
                Daily Report -
                <span className="ml-2">{data?.data?.super_market?.name}</span>
              </p>

              <div
                 onClick={handleDetails}
                className="flex cursor-pointer bg-green px-4 rounded-md"
              >
                <IoIosArrowRoundBack
                  size={20}
                  color={"white"}
                  className="mt-2"
                  
                />
                <p className="text-white text-md mt-1.5 cursor-pointer font-bold">
                  Back to List
                </p>
              </div>
            </div>
          </div>
          <div className="relative h-[100%]">
            <div className="  h-[calc(99%-6%)] bg-white p-3">
              <div className="bg-white h-full rounded-md p-5 ">
                <div className="flex item-center justify-between ">
                  <div>
                    <p className="text-[#40AE49] font-semibold text-2xl ">
                      Daily Report
                    </p>
                  </div>
                  <div>
                    <div className="flex bg-[#F9F9F9] flex-row justify-center items-center rounded-md border-2 border-[#808080]   gap-9">       
                      <DatePickerComp
                          onSelected={(e) => {setReportDate(e)}}
                          value={reportDate}
                          allowFutureDate={false}
                        />
                    </div>
                  </div>
                </div>
               {data?.data ? ( <div className="data">
                <div className="mt-2">
                  <p className="text-[#808080]">
                    {getDateFormatMoment(data?.data?.submitted_at, true)}
                  </p>
                </div>

                <div className="mt-5">
                  <p className="font-light">Merchandiser</p>
                  <h2 className="font-bold text-xl">
                    {data?.data?.submitted_by_merchandiser?.user_name}
                  </h2>
                </div>

                <div className="mt-5">
                  <p className="font-light">Report Coordinate</p>
                  <div className="flex flex-row mt-2">
                    <p className="font-semibold">
                      {data?.data?.latitude}, {data?.data?.longitude}
                    </p>
                    <a
                      className="ml-4 mt-[-0.5rem] text-center rounded-md text-white p-2 bg-green"
                      href={createGoogleMapsLink(
                        data?.data?.latitude,
                        data?.data?.longitude
                      )}
                      target="_blank"
                    >
                      Go to Maps
                    </a>
                  </div>
                </div>

                <div className="w-full flex flex-row mt-10">
                  <div className="w-[60%] ">
                    <p className="font-semibold">Notes</p>
                    <p className="pr-[10rem] mt-2">{data?.data?.notes}</p>
                  </div>
                  <div className="w-[40%]">
                    <p className="font-semibold">Pictures</p>
                    <div className="flex flex-row space-x-5 mt-2 overflow-x-auto">
                      {data?.data?.pictures ? (
                        data?.data?.pictures.map((imgdata, index) => {
                          return (
                            index < 4 && (
                              <img
                                key={imgdata?.id}
                                className="mr-3 w-[60px] h-[60px] rounded-[10px]"
                                src={imgdata?.doc_link}
                                onClick={() => {
                                  handleImgClick(data?.data?.pictures)
                                }}
                              />
                            )
                          );
                        })
                      ) : (
                        <p className="text-[1rem] text-center mr-10 ">
                          No Pictures available
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                </div>) : 
                <div className="flex font-bold text-xl h-[100%] justify-center items-center ">No report found for the selected date.</div>}                                
              </div>
            </div>
          </div>
        </div>
      </PageLayoutComp>
    </div>
  );
};
