import React from "react";
export const InputFieldComp = ({
  label,
  placeholder,
  onChange,
  extraStyle = "h-[40px]",
  compulsory = false,
  disabled = false,
  image,
  error = false,
  value = "",
  type = "text",
  maxLength = null 
}) => {
  return (
    <div className="h-full">
      {label === undefined ? null : (
        <>
          <div className="flex items-center">
            {image ? (
              <img
                alt="beleafe"
                className="float-right w-[25px] h-[25px] mr-2"
                src={image}
              />
            ) : null}
            <p className="text-sm text-black mb-2">{label}</p>
            {compulsory ? <p className="text-red-500">*</p> : null}
          </div>
        </>
      )}
      <div className={`${extraStyle} flex items-center`}>
        <input
          className="flex w-full h-[50px] py-4 border rounded-lg border-gray-300 items-center px-2 font-medium shadow-sm hover:bg-gray-50 focus:outline-0"
          type={type}
          value={value}
          maxLength={maxLength}
          placeholder={placeholder.toString()}
          disabled={disabled}
          onChange={(text) => {
            onChange(text.target.value);
          }}
        />
      </div>
      {error ? (
        <p className="text-red-400 text-sm ml-2">Field Can not be Empty</p>
      ) : null}
    </div>
  );
};
