export const ADMININSTRATION_FARMS = require("../images/ICONS/farming.png");
export const ADMININSTRATION_PRODUCTS = require("../images/ICONS/products.png");
export const ADMININSTRATION_USERS = require("../images/ICONS/users.png");
export const ADMININSTRATION_ARCHIVED = require("../images/ICONS/archived.png");
export const ADMININSTRATION_CAREER = require("../images/ICONS/career_archive.png");
export const ADMININSTRATION_FARM_WEEKLY_REPORT = require("../images/ICONS/weekly_report.png");
export const PLANTING_DASHBOARD = require("../images/ICONS/dashboard.png");
export const PLANTING_PLANTING_LIST = require("../images/ICONS/planting_list.png");
export const PLANTING_HARVEST_LIST = require("../images/ICONS/harvest_list.png");
export const IOT_DOSING_MACHINE = require("../images/ICONS/dosing_machine.png");
export const IOT_WEATHER_STATION = require("../images/ICONS/weather_station.png");
export const DRAFT_HARVESTED = require("../images/ICONS/draft_harvested.png");
export const DRAFT_REQUEST_PO = require("../images/ICONS/po.png");
export const DRAFT_REQUEST_BILL = require("../images/ICONS/bill.png");
export const BUDGET_PLAN_RAB = require("../images/ICONS/budget-planning.png");
export const BUDGET_REQUEST_LIST = require("../images/ICONS/request_list.png");
export const BUDGET_REQUEST_INVENTORY = require("../images/ICONS/inventory.png");
export const ADMIN_USER = require("../images/ICONS/user.png");
export const PLANTING = require("../images/ICONS/planting.png");
export const DEVICE = require("../images/ICONS/Device.png");
export const CROPS_TRACKER = require("../images/ICONS/cropsTracker.png");
export const DRAFT = require("../images/ICONS/draft.png");
export const INTERNAL = require("../images/ICONS/internal.png");
export const RAB = require("../images/ICONS/budget_plan_rab.png");
export const TRANSFER = require("../images/ICONS/transfer.png");
export const STATUS = require("../images/ICONS/status.png");
export const MERCHANDISE = require("../images/ICONS/merchandise.png");
export const MERCHANDISE_MAPPING = require("../images/ICONS/merchandiser_mapping.png");
export const ESIGNATURE = require("../images/e-sign.png");