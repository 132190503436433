import React, { useEffect, useState } from "react";
import {
  _asyncAddNewRAB,
  _fetchMetaData,
  _asyncGetAllProvince,
  _asyncGetDistrictByAbv,
  _updateDocs,
  _apiGetVendors,
  _asyncAddNewFarm,
  _asyncGetFaasPics,
  _asyncGetRnDPics,
  _asyncGetFarmDocsData,
  _asyncUpdateFarm,
  _asyncGetWarehouses,
  _apiGetUnassignedWarehouses,
} from "../api";
import { InputFieldComp } from "./InputFieldComp";
import SearchDropDownComp from "./SearchDropDownComp";
import { toast } from "react-toastify";
import { SuccessfullMesg } from "../Models/SuccessfullMesg";
import { ConfirmationModal } from "../Models/ConfirmationModal";
import { PageLayoutComp } from "./PageLayoutComp";
import { useNavigate, useParams } from "react-router-dom";
import { DocumentsTab } from "../Models/DocumentsTab";
import { GoogleMapComp } from "../config/GoogleMaps";
import DeleteButton from "../images/Delete.png";
import Blank from "../images/blank.png";
import AddButton from "../images/AddButton.png";
import Multiselect from "multiselect-react-dropdown";
import { AppUsersTab } from "../Models/AppUsersTab";

export const CreateNewMitraFarm = () => {
  let user = JSON.parse(localStorage.getItem("user_data"));
  let selectedfarmIdForEdit = JSON.parse(
    localStorage.getItem("selectedfarmIdForEdit")
  );
  let editMitraFarm = JSON.parse(localStorage.getItem("editMitraFarm"));
  let editMitraFarmData = JSON.parse(localStorage.getItem("editMitraFarmData"));
  const location = useNavigate();
  const params = useParams();
  const [metaData, setMetaData] = useState();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showConfirmModalBack1, setShowConfirmModalBack1] = useState(false);
  const isApiFetched = React.useRef(false);
  const ownerShipTypesList = metaData?.farmOwnershipTypes;
  const [internalFarmTypes, setInternalFarmTypes] = useState([]);
  const [provinceList, setProvinceList] = useState([]);
  const [selectedProvince, setSelectedProvince] = useState();
  const [districtList, setDistrictList] = useState([]);
  const [selectedDistrict, setSelectedDistrict] = useState();
  const [farmName, setFarmName] = useState({ value: "", error: false });
  const [vendorList, setVendorList] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState();
  const [internalFarmType, setInternalFarmType] = useState();
  const [docs, setDocs] = useState([]);
  const [faasPicList, setFaasPicList] = useState([]);
  const [rnDPicList, setRnDPicList] = useState([]);
  const [selectedFaasPic, setSelectedFaasPic] = useState();
  const [selectedRnDPic, setSelectedRnDPic] = useState();
  const [allWarehouse, updateAllWarehouse] = useState([]);
  const [warehouse, setWarehouse] = useState();
  const [farmOwnerShip, setFarmOwnerShip] = useState(
    metaData?.farmOwnershipTypes[0]
  );
  const [farmAddress, setFarmAddress] = useState({
    value: "",
    error: false,
  });
  // const [companyName, setCompanyName] = useState({
  //   value: "",
  //   error: false,
  // });
  const [farmContactName, setFarmContactName] = useState({
    value: "",
    error: false,
  });
  const [farmContactNumber, setFarmContactNumber] = useState({
    value: "",
    error: false,
  });

  const [farmLatLong, setFarmLatLong] = useState({
    lat: -6.592029870620922,
    lng: 106.80485196658653,
  });

  const [docsData, setDocsData] = useState({
    agreement_letter_pks_validity: new Date(),
    agreement_letter_pks: "",
    land_certificate_validity: new Date(),
    land_certificate: "",
    is_owner_married: false,
    business_certificate: "",
    family_doc_kk: "",
    ktp_id_card: "",
    spouse_id_card: "",
  });
  const [newFarm, setNewFarm] = useState();
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const [Hydroponics, setHydroponics] = useState([{}]);
  const [Soilless, setSoilless] = useState([{}]);
  const [OpenField, setOpenField] = useState([{}]);

  const handleAddOpenField = () => {
    const item = {};
    setOpenField([...OpenField, item]);
  };

  const handleRemoveOpenField = (idx) => {
    const tempRows = [...OpenField];
    if (tempRows.length === 1) {
      return;
    }
    tempRows.splice(idx, 1);
    setOpenField(tempRows);
  };

  const updateOpenField = (e, idx, column) => {
    let fieldValue = e;
    let fieldName = column;
    if (
      fieldName === "farmed_area" ||
      fieldName === "farmed_area" ||
      fieldName === "irrigated_area"
    ) {
      fieldValue = parseFloat(fieldValue) || 0;
    }
    const tempRows = [...OpenField];
    const tempObj = OpenField[idx];
    tempObj[fieldName] = fieldValue;
    tempRows[idx] = tempObj;
    setOpenField(tempRows);
  };

  const handleAddHydroponics = () => {
    const item = {};
    setHydroponics([...Hydroponics, item]);
  };

  const handleFaasOnChange = (event) => {
    let userList = [];
    event.map((item) => {
      userList.push(item);
    });
    setSelectedFaasPic(userList);
  };

  const handleRnDOnChange = (event) => {
    let userList = [];
    event.map((item) => {
      userList.push(item);
    });
    setSelectedRnDPic(userList);
  };

  const handleRemoveHydroponics = (idx) => {
    const tempRows = [...Hydroponics];
    if (tempRows.length === 1) {
      return;
    }
    tempRows.splice(idx, 1);
    setHydroponics(tempRows);
  };

  const updateHydroponics = (e, idx, column) => {
    let fieldValue = e;
    let fieldName = column;
    if (fieldName === "floor_area" || fieldName === "greenhouse_holes") {
      fieldValue = parseFloat(fieldValue) || 0;
    }
    const tempRows = [...Hydroponics];
    const tempObj = Hydroponics[idx];
    tempObj[fieldName] = fieldValue;
    tempRows[idx] = tempObj;
    setHydroponics(tempRows);
  };

  const handleAddSoilless = () => {
    const item = {};
    setSoilless([...Soilless, item]);
  };

  const setFarmOwnerShipFunc = (item) => {
    setFarmOwnerShip(item);
    if (item?.id == 1) {
      setInternalFarmType();
      console.log('0000000----->>>',item);
    }
  };

  const handleRemoveSoilless = (idx) => {
    const tempRows = [...Soilless];
    if (tempRows.length === 1) {
      return;
    }
    tempRows.splice(idx, 1);
    setSoilless(tempRows);
  };

  const updateSoilless = (e, idx, column) => {
    let fieldValue = e;
    let fieldName = column;
    if (fieldName === "floor_area" || fieldName === "greenhouse_holes") {
      fieldValue = parseFloat(fieldValue) || 0;
    }
    const tempRows = [...Soilless];
    const tempObj = Soilless[idx];
    tempObj[fieldName] = fieldValue;
    tempRows[idx] = tempObj;
    setSoilless(tempRows);
  };

  async function _fetchVendors() {
    let list = await _apiGetVendors();
    if (list.length > 0) {
      let temp = { id: null, partner_name: "Select Vendor" };
      setVendorList([temp, ...list]);
    }
  }

  async function _getGetAllWarehouse() {
    const data = await _apiGetUnassignedWarehouses();
    if (!data) return;
    updateAllWarehouse([...data]);
  }

  async function _fetchFaasPic() {
    if (editMitraFarm) {
      let data = {
        farm_id: editMitraFarmData?.id,
      };
      let list = await _asyncGetFaasPics(data);
      if (list.length > 0) {
        let assigned = [];
        let unassigned = [];
        list.map((item) =>
          item.is_assigned ? assigned.push(item) : unassigned.push(item)
        );
        setFaasPicList([...unassigned, ...assigned]);
        setSelectedFaasPic(assigned);
      }
    } else {
      let list = await _asyncGetFaasPics();
      if (list.length > 0) {
        setFaasPicList(list);
      }
    }
  }

  async function _fetchRnDPic() {
    if (editMitraFarm) {
      let data = {
        farm_id: editMitraFarmData?.id,
      };
      let list = await _asyncGetRnDPics(data);
      if (list.length > 0) {
        let assigned = [];
        let unassigned = [];
        list.map((item) =>
          item.is_assigned ? assigned.push(item) : unassigned.push(item)
        );
        setRnDPicList([...unassigned, ...assigned]);
        setSelectedRnDPic(assigned);
      }
    } else {
      let list = await _asyncGetRnDPics();
      if (list.length > 0) {
        setRnDPicList(list);
      }
    }
  }

  useEffect(() => {
    if (!isApiFetched.current) {
      _getProvince();
      _fetchVendors();
      _getGetAllWarehouse();
      isApiFetched.current = true;
    }
  });

  async function _getProvince() {
    const data = await _asyncGetAllProvince();
    if (!data) return;
    let temp = { abv: null, province: "Select Province" };
    //setProvinceList([temp, ...data]);
    setProvinceList([...data]);
    //setSelectedProvince(data[0]);
    _getDistrictByAbv(data[0].abv);
  }

  async function _getDistrictByAbv(abv) {
    const data = await _asyncGetDistrictByAbv(abv);
    if (!data) return;

    //setSelectedDistrict(data[0]);
    let temp = { location_code: null, abv: null, district: "Select District" };
    //setDistrictList([temp,...data]);
    setDistrictList([...data]);
  }

  async function _fetchData() {
    let metaData = await _fetchMetaData();
    setMetaData(metaData);
    setFarmOwnerShip(metaData?.farmOwnershipTypes[0]);
    let internalFarmTypes = metaData?.internalFarmTypes;
    setInternalFarmTypes(internalFarmTypes);
    if (
      editMitraFarmData?.ownership_type &&
      metaData?.farmOwnershipTypes &&
      editMitraFarm
    ) {
      let fileredItem = metaData?.farmOwnershipTypes.filter(
        (ite) => ite.id == editMitraFarmData.ownership_type
      );
      setFarmOwnerShip(...fileredItem);
    }
  }

  useEffect(() => {
    if (editMitraFarm) {
      _fetchDocs();
    }
  }, [editMitraFarmData?.id]);

  async function _fetchDocs() {
    let data = await _asyncGetFarmDocsData(editMitraFarmData?.id);
    setDocs(data);
  }

  useEffect(() => {
    selectedfarmIdForEdit = JSON.parse(
      localStorage.getItem("selectedfarmIdForEdit")
    );
    editMitraFarm = JSON.parse(localStorage.getItem("editMitraFarm"));
    editMitraFarmData = JSON.parse(localStorage.getItem("editMitraFarmData"));
    if (editMitraFarm) {
      setDataForEdit();
    }
    _fetchData();
    _fetchFaasPic();
    _fetchRnDPic();
  }, []);

  const setDataForEdit = () => {
    setInternalFarmType(editMitraFarmData?.internal_farm_type);
    setFarmName({
      value: editMitraFarmData?.farm_name,
      error: false,
    });
    setSelectedVendor({
      partner_name: editMitraFarmData?.odoo_vendor_name,
      id: editMitraFarmData?.odoo_vendor_id,
    });

    setWarehouse({
      warehouse_name: editMitraFarmData?.odoo_warehouse_name,
      id: editMitraFarmData?.odoo_warehouse_id,
    });

    setFarmContactName({
      value: editMitraFarmData?.contact_name,
      error: false,
    });
    setFarmContactNumber({
      value: editMitraFarmData?.contact_number,
      error: false,
    });
    setSelectedProvince({ province: editMitraFarmData?.farm_loc_province });
    setSelectedDistrict({ district: editMitraFarmData?.farm_loc_district });
    setFarmAddress({
      value: editMitraFarmData?.farm_loc_address,
      error: false,
    });
    setFarmLatLong({
      lat: Number(editMitraFarmData?.farm_loc_lat),
      lng: Number(editMitraFarmData?.farm_loc_long),
    });
    if (editMitraFarmData.facility["1"]) {
      let greenHouseArray = [];
      editMitraFarmData?.facility["1"]?.facility_info.forEach((element) => {
        greenHouseArray.push({
          id: element.id,
          greenhouse_name: element.greenhouse_name,
          floor_area: element.floor_area,
          greenhouse_holes: element.greenhouse_holes,
          dosing_type_id: element.dosing,
          dosing_type_name: element.dosing.type_name,
        });
      });
      setHydroponics(greenHouseArray);
    }
    if (editMitraFarmData.facility["3"]) {
      let openFieldArray = [];
      editMitraFarmData?.facility["3"]?.facility_info.forEach((element) => {
        openFieldArray.push({
          id: element.id,
          farmable_area: element.farmable_area,
          farmed_area: element.farmed_area,
          irrigation_type_id: element.irrigation,
          irrigation_type_name: element.irrigation.type_name,
          irrigated_area: element.irrigated_area,
        });
      });
      setOpenField(openFieldArray);
    }
    if (editMitraFarmData.facility["2"]) {
      let soillesArray = [];
      editMitraFarmData?.facility["2"]?.facility_info.forEach((element) => {
        soillesArray.push({
          id: element.id,
          greenhouse_name: element.greenhouse_name,
          floor_area: element.floor_area,
          greenhouse_holes: element.greenhouse_holes,
          dosing_type_id: element.dosing,
          dosing_type_name: element.dosing.type_name,
        });
      });
      setSoilless(soillesArray);
    }
  };

  const onChangeValue = (event) => {
    setDocsData({
      ...docsData,
      is_owner_married: event.target.defaultValue === "true" ? true : false,
    });
  };

  const handleFileChange = (event) => {
    setDocsData({ ...docsData, [event.target.name]: event.target.files[0] });
  };

  const dateAgreementLetterValidityChange = (event) => {
    setDocsData({ ...docsData, agreement_letter_pks_validity: event });
  };
  const dateLandCertificateValidityChange = (event) => {
    setDocsData({ ...docsData, land_certificate_validity: event });
  };

  const onSubmitClick = () => {
    if (!user?.permissions?.update_farm) {
      alert("Don't have Update Permission");
      return;
    }
    // if (farmOwnerShip.id == 2 && companyName.value === "") {
    //     //setCompanyName({ ...companyName, error: true });
    //     toast.error("Please enter company name", {autoClose: 2000});
    //     return;
    //   }

    if (farmName.value === "") {
      toast.error("Please enter farm name", { autoClose: 2000 });
      //setFarmName({ ...farmName, error: true });
      return;
    }

    if (selectedVendor === undefined && warehouse === undefined) {
      toast.error("Please select link data", { autoClose: 2000 });
      //setFarmName({ ...farmName, error: true });
      return;
    }

    if (farmContactName.value === "") {
      toast.error("Please enter contact name", { autoClose: 2000 });
      //setFarmContactName({ ...farmContactName, error: true });
      return;
    }

    if (farmContactNumber.value === "") {
      toast.error("Please enter contact number", { autoClose: 2000 });
      //setFarmContactNumber({ ...farmContactNumber, error: true });
      return;
    }

    if (!selectedProvince?.province) {
      toast.error("Please select province", { autoClose: 2000 });
      //setFarmAddress({ ...farmAddress, error: true });
      return;
    }

    if (!selectedDistrict?.district) {
      toast.error("Please select district", { autoClose: 2000 });
      //setFarmAddress({ ...farmAddress, error: true });
      return;
    }

    if (farmAddress.value === "") {
      toast.error("Please enter address", { autoClose: 2000 });
      //setFarmAddress({ ...farmAddress, error: true });
      return;
    }

    //+++++++++++++++++++++

    // if (docsData?.ktp_id_card === "") {
    //   toast.error("Please upload ID Card (KTP) document", {autoClose: 2000});
    //   return;
    // }
    // if (docsData.is_owner_married && docsData?.spouse_id_card === "") {
    //   toast.error("Please upload Spouse ID Card (KTP) document", {autoClose: 2000});
    //   return;
    // }
    // if (docsData?.family_doc_kk === "") {
    //   toast.error("Please upload Family Document (KK) document", {autoClose: 2000});
    //   return;
    // }

    // if (docsData?.business_certificate === "") {
    //   toast.error("Please upload Business Certificate (Surat Keterangan Usaha) document", {autoClose: 2000});
    //   return;
    // }

    // if (docsData?.agreement_letter_pks === "") {
    //   toast.error("Please upload Agreement Letter (PKS) document", {autoClose: 2000});
    //   return;
    // }

    // if (docsData?.agreement_letter_pks_validity === "") {
    //   toast.error("Please select Agreement Letter (PKS) validity date ", {autoClose: 2000});
    //   return;
    // }

    // if (docsData?.land_certificate === "") {
    //   toast.error("Please upload Land Certificate (Surat Kepemilikan Lahan / Surat Sewa Lahan) document", {autoClose: 2000});
    //   return;
    // }

    // if (docsData?.land_certificate_validity === "") {
    //   toast.error("Please select Land Certificate validity date ", {autoClose: 2000});
    //   return;
    // }
    setShowConfirmModal(true);
  };

  function isEmpty(obj) {
    for (var prop in obj) {
      if (obj.hasOwnProperty(prop)) return false;
    }
    return true;
  }

  const _addDocsData = async () => {
    let faasPic = [];
    if (selectedFaasPic?.length > 0) {
      faasPic = selectedFaasPic.map(({ id }) => id);
    }
    console.log("faasPicfaasPic", faasPic);
    let rnDPic = [];
    if (selectedRnDPic?.length > 0) {
      rnDPic = selectedRnDPic.map(({ id }) => id);
    }
    console.log("faasPicfaasPic", rnDPic);

    let facility = {};
    Hydroponics.forEach((item) => {
      if (!isEmpty(item)) {
        item.dosing_type_id = item?.dosing_type_id?.id;
      }
    });
    Soilless.forEach((item) => {
      if (!isEmpty(item)) {
        item.dosing_type_id = item?.dosing_type_id?.id;
      }
    });
    OpenField.forEach((item) => {
      if (!isEmpty(item)) {
        item.irrigation_type_id = item?.irrigation_type_id?.id;
      }
    });

    let HydroponicsArray = Hydroponics.filter(
      (value) => Object.keys(value).length !== 0
    );
    let SoillessArray = Soilless.filter(
      (value) => Object.keys(value).length !== 0
    );
    let OpenFieldArray = OpenField.filter(
      (value) => Object.keys(value).length !== 0
    );

    if (HydroponicsArray.length > 0) {
      facility["1"] = HydroponicsArray;
    }
    if (SoillessArray.length > 0) {
      facility["2"] = SoillessArray;
    }
    if (OpenFieldArray.length > 0) {
      facility["3"] = OpenFieldArray;
    }
    let formData = new FormData();
    formData.append("facility", JSON.stringify(facility));
    // formData.append('company_name', companyName.value);
    formData.append("contact_name", farmContactName.value);
    formData.append("contact_number", farmContactNumber.value);
    formData.append("farm_name", farmName.value);
    formData.append("ownership_type", +farmOwnerShip.id);
    formData.append("province", selectedProvince.province);
    formData.append("district", selectedDistrict.district);
    formData.append("lat", farmLatLong.lat);
    formData.append("long", farmLatLong.lng);
    formData.append("address", farmAddress.value);

    if (farmOwnerShip?.ownership_type === "Internal") {
      formData.append("warehouse_name", warehouse?.warehouse_name);
      formData.append("warehouse_id", warehouse?.id);
    } else {
      formData.append("vendor_name", selectedVendor?.partner_name);
      formData.append("vendor_id", selectedVendor?.id);
    }
    if (farmOwnerShip?.ownership_type === "Internal") {
      if (rnDPic.length > 0) {
        rnDPic.map((item) => {
          formData.append("pic_ids[]", item);
        });
      }
      if (internalFarmType) {
        formData.append("internal_farm_type_id", internalFarmType?.id);
      }
    } else {
      if (faasPic.length > 0) {
        faasPic.map((item) => {
          formData.append("pic_ids[]", item);
        });
      }
    }
    formData.append("spouse_id_card", docsData?.spouse_id_card);
    formData.append("agreement_letter_pks", docsData?.agreement_letter_pks);
    if (docsData?.agreement_letter_pks !== "") {
      formData.append(
        "agreement_letter_pks_validity",
        docsData?.agreement_letter_pks_validity
      );
    }
    formData.append("land_certificate", docsData?.land_certificate);
    if (docsData?.land_certificate !== "") {
      formData.append(
        "land_certificate_validity",
        docsData?.land_certificate_validity
      );
    }
    formData.append("is_owner_married", docsData?.is_owner_married);
    formData.append("business_certificate", docsData?.business_certificate);
    formData.append("family_doc_kk", docsData?.family_doc_kk);
    formData.append("ktp_id_card", docsData?.ktp_id_card);
    if (editMitraFarm) {
      let res = await _asyncUpdateFarm(selectedfarmIdForEdit, formData);
      if (
        (res.status === 201 && res.statusText === "Created") ||
        (res.status === 200 && res.statusText === "OK")
      ) {
        setShowSuccessModal(true);
        setTimeout(() => {
          location("/farm-pipeline");
        }, 1000);
      }
    } else {
      let res = await _asyncAddNewFarm(formData);
      if (
        (res.status === 201 && res.statusText === "Created") ||
        (res.status === 200 && res.statusText === "OK")
      ) {
        setShowSuccessModal(true);
        setTimeout(() => {
          location("/farm-pipeline");
        }, 1000);
      }
    }
  };

  // const onBackButtonEvent = (e) => {
  //   // e.preventDefault();
  //   setShowConfirmModalBack1(true);
  // };

  // useEffect(() => {
  //   window.history.pushState(null, null, window.location.pathname);
  //   window.addEventListener("popstate", () => onBackButtonEvent());
  //   return () => {
  //     window.removeEventListener("popstate", () => onBackButtonEvent());
  //   };
  // }, []);

  const handleMapClick = (e) => {
    setFarmLatLong({
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    });
  };

  return (
    <PageLayoutComp metaData={metaData} user={user}>
      <div className="h-[100%] bg-[#E6F1ED] p-4">
        {showSuccessModal ? (
          <SuccessfullMesg label={`Farm Successfully Created`} />
        ) : null}

        {showConfirmModal ? (
          <ConfirmationModal
            label={`Are you sure want to Create this Mitra Farm ?`}
            label2="No"
            type="planting"
            customStyles={true}
            hideModal={() => setShowConfirmModal(false)}
            onConfirm={() => {
              setShowConfirmModal(false);
              _addDocsData();
            }}
          />
        ) : null}

        {showConfirmModalBack1 ? (
          <ConfirmationModal
            label={`Are you sure want to go back ?`}
            label2="No"
            type="planting"
            customStyles={true}
            hideModal={() => setShowConfirmModalBack1(false)}
            onConfirm={() => {
              setShowConfirmModalBack1(false);
              location("/farm-pipeline");
            }}
          />
        ) : null}
        <div className="h-[7%]">
          <div className="flex justify-between">
            <p className="text-3xl font-bold mb-0">
              {editMitraFarm ? "Edit Mitra Farm" : "Add New Mitra Farm"}{" "}
            </p>
          </div>
        </div>
        <div className="h-[90%]">
          <div className="flex bg-[#E6F1ED] h-[50px]">
            <div className="flex bg-white text-[#3EB049] px-7 py-2 justify-between items-center rounded-tl-lg rounded-tr-3xl cursor-pointer">
              <p className="text-[15px] font-normal">
                {editMitraFarm ? "Edit Mitra Farm" : "Add New Mitra Farm"}
              </p>
            </div>
          </div>
          <div className="h-[calc(100%-50px)] bg-white p-3">
            <div class="h-full w-full rounded-[8px] overflow-scroll">
              <div className="">
                <div className="bg-white p-5">
                  <div>
                    <p className="text-lg mb-2 ml-2 text-black">
                      <b>Ownerships</b>
                    </p>
                    <div className="flex">
                      <div className="flex border border-gray-300 px-1 py-1 rounded-full">
                        {ownerShipTypesList?.map((item, i) => {
                              console.log('item.ownership_type----',metaData?.farmOwnershipTypes);
                          return (
                            <div
                              key={i}
                              onClick={() => setFarmOwnerShipFunc(item)}
                              className={`${
                                farmOwnerShip === item
                                  ? "bg-[#3EB049] text-white"
                                  : ""
                              } px-3 py-2 rounded-full cursor-pointer text-sm`}
                            >
                              <p>{item.ownership_type}</p>
                            </div>
                          );
                        })}
                      </div>
                      {farmOwnerShip?.ownership_type === "Internal" ? (
                        <div class="h-12 ml-20 relative flex items-center">
                          <p class="text-sm text-black mr-2">Type</p>
                          <SearchDropDownComp
                            class="flex-grow"
                            type="internal_farm_type"
                            active={internalFarmType || []}
                            list={internalFarmTypes}
                            onSelected={(item) => {
                              setInternalFarmType(item);
                            }}
                          />
                        </div>
                      ) : null}
                    </div>
                    {/*                   
                      {farmOwnerShip?.ownership_type === "Company" ? (
                      <div className="mt-5 grid grid-cols-2 gap-2">
                        <div className="mt-3">
                          <InputFieldComp
                            label={"Company Name"}
                            compulsory={true}
                            placeholder={"Company Name"}
                            value={companyName.value}
                            error={companyName.error}
                            onChange={(text) =>
                              setCompanyName({
                                ...companyName,
                                value: text,
                                error: false,
                              })
                            }
                          />
                        </div>
                      </div>
                      ) : null} */}
                    <div className="mt-5 grid grid-cols-2 gap-2">
                      {farmOwnerShip?.ownership_type === "Internal" ? (
                        <div className="mt-3 relative">
                          <p className="text-sm text-black">
                            RnD PIC <span className="text-red-500">*</span>
                          </p>
                          <Multiselect
                            displayValue="user_name"
                            chipColor="#40AE49"
                            selectedValues={selectedRnDPic || []}
                            options={rnDPicList || []}
                            onSelect={handleRnDOnChange}
                            onRemove={handleRnDOnChange}
                            showCheckbox
                            style={{ chips: { background: "#40AE49" } }}
                          />
                        </div>
                      ) : (
                        <div className="mt-3 relative">
                          <p className="text-sm text-black">
                            FaaS PIC <span className="text-red-500">*</span>
                          </p>
                          <Multiselect
                            displayValue="user_name"
                            chipColor="#40AE49"
                            selectedValues={selectedFaasPic || []}
                            options={faasPicList || []}
                            onSelect={handleFaasOnChange}
                            onRemove={handleFaasOnChange}
                            showCheckbox
                            style={{ chips: { background: "#40AE49" } }}
                          />
                        </div>
                      )}
                    </div>
                    <div className="mt-5 grid grid-cols-2 gap-2">
                      <div className="mt-3">
                        <InputFieldComp
                          compulsory={true}
                          value={farmName.value}
                          label={"Farm Name"}
                          placeholder={"Farm Name"}
                          error={farmName.error}
                          onChange={(text) =>
                            setFarmName({
                              ...farmName,
                              value: text,
                              error: false,
                            })
                          }
                        />
                      </div>
                      <div className="mt-3 relative">
                        <p className="text-sm text-black">
                          {farmOwnerShip?.ownership_type === "Internal"
                            ? "Link Farm Data To Odoo"
                            : "Link To Mitra Data Odoo"}{" "}
                          <span className="text-red-500">*</span>
                        </p>
                        {farmOwnerShip?.ownership_type === "Internal" ? (
                          <SearchDropDownComp
                            list={allWarehouse}
                            active={warehouse || []}
                            type={"warehouse_name"}
                            onSelected={(item) => {
                              setWarehouse(item);
                            }}
                          />
                        ) : (
                          <SearchDropDownComp
                            list={vendorList}
                            type="partner_name"
                            active={selectedVendor || []}
                            onSelected={(item) => {
                              setSelectedVendor(item);
                            }}
                          />
                        )}
                      </div>
                    </div>
                    <div className="mt-5 grid grid-cols-2 gap-2">
                      <div>
                        <InputFieldComp
                          label={"Farm Contact Name"}
                          compulsory={true}
                          placeholder={"Carnegie Trihandono"}
                          value={farmContactName.value}
                          error={farmContactName.error}
                          onChange={(text) =>
                            setFarmContactName({
                              ...farmContactName,
                              value: text,
                              error: false,
                            })
                          }
                        />
                      </div>
                      <div>
                        <InputFieldComp
                          label={"Farm Contact Number"}
                          compulsory={true}
                          maxLength={15}
                          placeholder={"+62 81239045004"}
                          value={farmContactNumber.value}
                          error={farmContactNumber.error}
                          onChange={(text) => {
                            if (/^[0-9+]*$/.test(text)) {
                              setFarmContactNumber({
                                ...farmContactNumber,
                                value: text,
                                error: false,
                              });
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <hr className="mt-10" />
                  <p className="text-lg mb-2 ml-2 mt-3 text-black">
                    <b>Farm Location and Facilities</b>
                  </p>
                  <>
                    <div className="mt-5 grid grid-cols-2 gap-2">
                      <div className="mt-2 relative">
                        <p className="text-[#8A9099] text-sm">
                          Province<span className="text-red-500">*</span>
                        </p>
                        <SearchDropDownComp
                          list={provinceList || []}
                          active={selectedProvince || []}
                          type={"province"}
                          onSelected={(item) => {
                            setSelectedProvince(item);
                            _getDistrictByAbv(item.abv);
                          }}
                          customStyles={
                            "rounded-md justify-between text-[#3E4347] text-[14px]"
                          }
                          customMenuStyle={
                            "relative inline-block text-left w-full"
                          }
                        />
                      </div>
                      <div className="mt-2 relative">
                        <p className="text-[#8A9099] text-sm">
                          District<span className="text-red-500">*</span>
                        </p>
                        <SearchDropDownComp
                          list={districtList || []}
                          active={selectedDistrict || []}
                          onSelected={(item) => setSelectedDistrict(item)}
                          type={"district"}
                          customStyles={
                            "rounded-md justify-between text-[#3E4347] text-[14px]"
                          }
                          customMenuStyle={
                            "relative inline-block text-left w-full"
                          }
                        />
                      </div>
                    </div>
                    <div className="flex mt-5">
                      <p className="text-[#8A9099] text-sm">Farm Address</p>
                      <p className="text-red-500">*</p>
                    </div>
                    <div>
                      <InputFieldComp
                        placeholder={"Farm Address"}
                        error={farmAddress.error}
                        value={farmAddress.value}
                        onChange={(text) =>
                          setFarmAddress({
                            ...farmAddress,
                            value: text,
                            error: false,
                          })
                        }
                      />
                    </div>
                    <div className="flex mt-5">
                      <p className="text-[#8A9099] text-sm">
                        Latitude/ Longitude
                      </p>
                      <p className="text-red-500">*</p>
                    </div>
                    <div>
                      <InputFieldComp
                        value={farmLatLong.lat + ", " + farmLatLong.lng}
                        placeholder={"6.5414° S, 106.7456° E"}
                        onChange={(text) => console.log(text)}
                      />
                    </div>
                    <div className="mt-5">
                      <GoogleMapComp
                        location={farmLatLong}
                        handleMapClick={handleMapClick}
                        //mapLoaded={(bol) => console.log("BIL", bol)}
                      />
                    </div>
                  </>

                  <hr className="mt-10" />
                  <p className="text-lg mb-2 ml-2 mt-3 text-black">
                    <b>Hydroponics</b>
                  </p>
                  {Hydroponics.map((item, idx) => (
                    <div className={`flex space-x-4`} key={idx}>
                      <div className="mt-3 grid grid-cols-4 gap-4" key={idx}>
                        <div>
                          <InputFieldComp
                            label={idx === 0 && "Greenhouse Name"}
                            type="text"
                            value={Hydroponics[idx]["greenhouse_name"]}
                            compulsory={false}
                            placeholder={"Greenhouse Name"}
                            onChange={(e) =>
                              updateHydroponics(e, idx, "greenhouse_name")
                            }
                          />
                        </div>
                        <div>
                          <InputFieldComp
                            label={idx === 0 && `Floor Area (Meter)`}
                            type="text"
                            value={Hydroponics[idx]["floor_area"]}
                            maxLength={8}
                            compulsory={false}
                            placeholder={"Floor Area"}
                            onChange={(e) =>
                              updateHydroponics(e, idx, "floor_area")
                            }
                          />
                        </div>
                        <div>
                          <InputFieldComp
                            label={idx === 0 && `Number of Holes`}
                            type="text"
                            value={Hydroponics[idx]["greenhouse_holes"]}
                            maxLength={8}
                            compulsory={false}
                            placeholder={"Floor Area"}
                            onChange={(e) =>
                              updateHydroponics(e, idx, "greenhouse_holes")
                            }
                          />
                        </div>
                        <div className="">
                          {idx === 0 && (
                            <p className="text-sm text-black">Dosing</p>
                          )}
                          <div className="relative">
                            <SearchDropDownComp
                              list={metaData?.farmDosingTypes || []}
                              label={"Select"}
                              active={
                                Hydroponics[idx]["dosing_type_id"] || "select"
                              }
                              width="w-[180px]"
                              type={"type_name"}
                              onSelected={(item) =>
                                updateHydroponics(item, idx, "dosing_type_id")
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div>
                        {idx !== 0 && (
                          <img
                            className={idx === 0 ? `mt-12` : `mt-6`}
                            alt="Delete button"
                            src={DeleteButton}
                            onClick={() => handleRemoveHydroponics(idx)}
                          />
                        )}

                        {idx === 0 && (
                          <img className="mt-12 h-5 w-8" alt="" src={Blank} />
                        )}
                      </div>
                    </div>
                  ))}
                  <div className="flex mt-5 justify-center">
                    <img
                      className="w-[32px] h-[32px]"
                      alt="Add button"
                      src={AddButton}
                      onClick={() => handleAddHydroponics()}
                    />
                  </div>

                  <hr className="mt-10" />
                  <p className="text-lg mb-2 ml-2 mt-3 text-black">
                    <b>Soilless</b>
                  </p>
                  {Soilless.map((item, idx) => (
                    <div className={`flex space-x-4`} key={idx}>
                      <div className="mt-3 grid grid-cols-4 gap-4" key={idx}>
                        <div>
                          <InputFieldComp
                            label={idx === 0 && "Greenhouse Name"}
                            type="text"
                            value={Soilless[idx]["greenhouse_name"]}
                            compulsory={false}
                            placeholder={"Greenhouse Name"}
                            onChange={(e) =>
                              updateSoilless(e, idx, "greenhouse_name")
                            }
                          />
                        </div>
                        <div>
                          <InputFieldComp
                            label={idx === 0 && `Floor Area (Meter)`}
                            type="text"
                            value={Soilless[idx]["floor_area"]}
                            maxLength={8}
                            compulsory={false}
                            placeholder={"Floor Area"}
                            onChange={(e) =>
                              updateSoilless(e, idx, "floor_area")
                            }
                          />
                        </div>
                        <div>
                          <InputFieldComp
                            label={idx === 0 && `Number of Holes`}
                            type="text"
                            value={Soilless[idx]["greenhouse_holes"]}
                            maxLength={8}
                            compulsory={false}
                            placeholder={"Floor Area"}
                            onChange={(e) =>
                              updateSoilless(e, idx, "greenhouse_holes")
                            }
                          />
                        </div>
                        <div className="">
                          {idx === 0 && (
                            <p className="text-sm text-black">Dosing</p>
                          )}
                          <div className="relative">
                            <SearchDropDownComp
                              list={metaData?.farmDosingTypes || []}
                              label={"Select"}
                              active={
                                Soilless[idx]["dosing_type_id"] || "select"
                              }
                              width="w-[180px]"
                              type={"type_name"}
                              onSelected={(item) =>
                                updateSoilless(item, idx, "dosing_type_id")
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div>
                        {idx !== 0 && (
                          <img
                            className={idx === 0 ? `mt-12` : `mt-6`}
                            alt="Delete button"
                            src={DeleteButton}
                            onClick={() => handleRemoveSoilless(idx)}
                          />
                        )}

                        {idx === 0 && (
                          <img className="mt-12 h-5 w-8" alt="" src={Blank} />
                        )}
                      </div>
                    </div>
                  ))}
                  <div className="flex mt-5 justify-center">
                    <img
                      className="w-[32px] h-[32px]"
                      alt="Add button"
                      src={AddButton}
                      onClick={() => handleAddSoilless()}
                    />
                  </div>

                  <hr className="mt-10" />
                  <p className="text-lg mb-2 ml-2 mt-3 text-black">
                    <b>Open Field</b>
                  </p>

                  {OpenField.map((item, idx) => (
                    <div className={`flex space-x-4`} key={idx}>
                      <div className="mt-3 grid grid-cols-4 gap-4" key={idx}>
                        <div>
                          <InputFieldComp
                            label={idx === 0 && "Farmable Land Area (Meter)"}
                            type="text"
                            value={OpenField[idx]["farmable_area"]}
                            compulsory={false}
                            placeholder={"Farmable Land Area"}
                            onChange={(e) =>
                              updateOpenField(e, idx, "farmable_area")
                            }
                          />
                        </div>
                        <div>
                          <InputFieldComp
                            label={idx === 0 && `Farmed Land Area (Meter)`}
                            type="text"
                            value={OpenField[idx]["farmed_area"]}
                            maxLength={8}
                            compulsory={false}
                            placeholder={"Farmed Land Area"}
                            onChange={(e) =>
                              updateOpenField(e, idx, "farmed_area")
                            }
                          />
                        </div>
                        <div className="">
                          {idx === 0 && (
                            <p className="text-sm text-black">Irigation</p>
                          )}
                          <div className="relative">
                            <SearchDropDownComp
                              list={metaData?.farmIrrigationTypes || []}
                              label={"Select"}
                              active={
                                OpenField[idx]["irrigation_type_id"] || "select"
                              }
                              width="w-[180px]"
                              type={"type_name"}
                              onSelected={(item) =>
                                updateOpenField(item, idx, "irrigation_type_id")
                              }
                            />
                          </div>
                        </div>
                        <div>
                          <InputFieldComp
                            label={idx === 0 && `Land Area Irigation (Meter)`}
                            type="text"
                            value={OpenField[idx]["irrigated_area"]}
                            maxLength={8}
                            compulsory={false}
                            placeholder={"Irrigated Area"}
                            onChange={(e) =>
                              updateOpenField(e, idx, "irrigated_area")
                            }
                          />
                        </div>
                      </div>
                      <div>
                        {idx !== 0 && (
                          <img
                            className={idx === 0 ? `mt-12` : `mt-6`}
                            alt="Delete button"
                            src={DeleteButton}
                            onClick={() => handleRemoveOpenField(idx)}
                          />
                        )}

                        {idx === 0 && (
                          <img className="mt-12 h-5 w-8" alt="" src={Blank} />
                        )}
                      </div>
                    </div>
                  ))}
                  <div className="flex mt-5 justify-center">
                    <img
                      className="w-[32px] h-[32px]"
                      alt="Add button"
                      src={AddButton}
                      onClick={() => handleAddOpenField()}
                    />
                  </div>

                  <hr className="mt-10" />
                  <p className="text-lg mb-2 ml-2 mt-3 text-black">
                    <b>Documents</b>
                  </p>
                  {editMitraFarm ? (
                    <>
                      <DocumentsTab
                        docsData={docsData}
                        onChangeValue={onChangeValue}
                        handleFileChange={handleFileChange}
                        dateAgreementLetterValidityChange={
                          dateAgreementLetterValidityChange
                        }
                        dateLandCertificateValidityChange={
                          dateLandCertificateValidityChange
                        }
                        showSuccessModal={showSuccessModal}
                        showConfirmModal={showConfirmModal}
                        setShowConfirmModal={setShowConfirmModal}
                        _addDocsData={_addDocsData}
                        docs={docs}
                      />
                    </>
                  ) : (
                    <>
                      <DocumentsTab
                        docsData={docsData}
                        onChangeValue={onChangeValue}
                        handleFileChange={handleFileChange}
                        dateAgreementLetterValidityChange={
                          dateAgreementLetterValidityChange
                        }
                        dateLandCertificateValidityChange={
                          dateLandCertificateValidityChange
                        }
                        showSuccessModal={showSuccessModal}
                        _addDocsData={_addDocsData}
                      />
                    </>
                  )}
                  {editMitraFarm ? (
                    <>
                      <AppUsersTab
                        token={true}
                        selectedFarm={editMitraFarmData}
                      />
                    </>
                  ) : null}
                </div>
              </div>

              <div
                onClick={() =>{
                  if(user.role.role === "Guest"){
                    toast.info("You do not have permission to update farm.")
                  }
                  else{
                    onSubmitClick();
                  }
                }}
                className={`flex float-right ${ (user.role.role !== "Guest") ? "bg-[#40AE49]" : "bg-slate-400"} text-white  mt-5 mb-5 pl-3 pr-3 mr-14 rounded-full cursor-pointer`}
              >
                <p className="text-sm p-2">
                  {editMitraFarm ? "Update" : "Create"}
                </p>
              </div>
              <div
                onClick={() => setShowConfirmModalBack1(true)}
                className="flex float-right border border-[#40AE49] text-[#40AE49] mb-5 mt-5 pl-3 mr-10 pr-3 ml-14 rounded-full cursor-pointer"
              >
                <p className="text-sm p-2">Back</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageLayoutComp>
  );
};
