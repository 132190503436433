import React, { useEffect, useRef, useState } from "react";
import {
  _asyncGetAllDosingMachine,
  _assignDMtoFarm,
  _asyncGetDosingMachineReading,
  _asyncGetDosingMachineAlarms,
  _asyncGetDosingMachineScheduler,
} from "../api";
import { getDateFormatMoment } from "../config/localdata";
import GaugeChart from "react-gauge-chart";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { AssignFarmModal } from "../Models/AssignFarmModal";
import { ConfirmationModal } from "../Models/ConfirmationModal";
import { toast } from "react-toastify";
import { SuccessfullMesg } from "../Models/SuccessfullMesg";
import { ChartOnModal } from "../Models/ChartOnModal";
import filters_img from "../images/FarmPipeLine/filters.png";
import DropDownComp from "./DropDownComp";
import { DosingMachineListComp } from "./DosingMachineListComp";
import { IoIosArrowRoundBack } from "react-icons/io";

import moment from "moment";
import { SchedulerFilterModal } from "../Models/SchedulerFilters";

export const DosingMachineComp = ({ metaData }) => {
  const [logData, setLogData] = useState(false);
  let user = JSON.parse(localStorage.getItem("user_data"));
  const startRef = useRef();
  const endRef = useRef();
  const [showFilter, setShowFilter] = useState(false);

  const [selectedDays, setSelectedDays] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(null);

  const [assignUnassign, setAssignUnassign] = useState(true);
  const [showAssignModal, setShowAssignModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showChartOnModal, setShowChartOnModal] = useState(false);
  const [dosingList, setDosingList] = useState([]);
  const [schedulerList, setSchedulerList] = useState([]);
  const [dosingAlarmList, setDosingAlarmList] = useState([]);
  const [selectedDosing, setSelectedDosing] = useState();
  const [assignDosing, setAssignDosing] = useState({});
  const [realTimeData, setRealTimeData] = useState({});

  const [showScheluePage, setShowScheluePage] = useState(false);

  const [graphData, setGraphData] = useState([]);
  const [filterValue, setFilterValue] = useState({
    id: "1",
    name: "Last 15 Minutes",
    filter_type: "minutes",
    time_amount: 15,
  });

  const [chartOptions, setChartOptions] = useState({});

  const [ecOptions, setEcOptions] = useState({
    title: {
      text: null,
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      categories: [],
    },
    series: [
      {
        data: [],
      },
    ],
    legend: {
      enabled: false,
    },
    plotOptions: { series: { marker: { enabled: false } } },
    id: 1,
    name: "EC",
  });

  const [phOptions, setPhOptions] = useState({
    title: {
      text: null,
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      categories: [],
    },
    series: [
      {
        data: [],
      },
    ],
    legend: {
      enabled: false,
    },
    plotOptions: { series: { marker: { enabled: false } } },
    id: 1,
    name: "pH",
  });

  const [salinityOptions, setSalinityOptions] = useState({
    title: {
      text: null,
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      categories: [],
    },
    series: [
      {
        data: [],
      },
    ],
    legend: {
      enabled: false,
    },
    plotOptions: { series: { marker: { enabled: false } } },
    id: 1,
    name: "Salinity",
  });

  const [waterOptions, setWaterOptions] = useState({
    title: {
      text: null,
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      categories: [],
    },
    series: [
      {
        data: [],
      },
    ],
    legend: {
      enabled: false,
    },
    plotOptions: { series: { marker: { enabled: false } } },
    id: 1,
    name: "Water Level",
  });

  const [tempOptions, setTempOptions] = useState({
    title: {
      text: null,
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      categories: [],
    },
    series: [
      {
        data: [],
      },
    ],
    legend: {
      enabled: false,
    },
    plotOptions: { series: { marker: { enabled: false } } },
    id: 1,
    name: "Temperature",
  });

  const allTime = [
    {
      id: "1",
      name: "Last 15 Minutes",
      filter_type: "minutes",
      time_amount: 15,
    },
    {
      id: "2",
      name: "Last 30 Minutes",
      filter_type: "minutes",
      time_amount: 30,
    },
    {
      id: "3",
      name: "Last 45 Minutes",
      filter_type: "minutes",
      time_amount: 45,
    },
    { id: "4", name: "Last 1 Hours", filter_type: "hours", time_amount: 1 },
    { id: "5", name: "Last 3 Hours", filter_type: "hours", time_amount: 3 },
    { id: "6", name: "Last 6 Hours", filter_type: "hours", time_amount: 6 },
    { id: "7", name: "Last 12 Hours", filter_type: "hours", time_amount: 12 },
    { id: "8", name: "Last 24 Hours", filter_type: "hours", time_amount: 24 },
  ];

  async function _fetchData() {
    let data = await _asyncGetAllDosingMachine();
    if (data?.displayData[0]?.id) {
      setDosingList(data?.displayData);
      _fetchDosingById(data?.displayData[0]?.id);
      setSelectedDosing(data?.displayData[0]);
      let dataAlarms = await _asyncGetDosingMachineAlarms(
        data?.displayData[0]?.id
      );
      setDosingAlarmList(dataAlarms);
    }
  }

  async function _fetchDosingById(id, params = null) {
    if (logData && params) {
      let data = await _asyncGetDosingMachineReading(id, params);
      const newEcOptions = { ...ecOptions };
      const newphOptions = { ...phOptions };
      const newTempOptions = { ...tempOptions };
      const newSalinityOptions = { ...salinityOptions };
      const newWaterOptions = { ...waterOptions };

      newEcOptions.series[0].data = data?.result?.map((item) =>
        Number(item.ec)
      );
      newphOptions.series[0].data = data?.result?.map((item) =>
        Number(item.ph)
      );
      newSalinityOptions.series[0].data = data?.result?.map((item) =>
        Number(item.salinity)
      );
      newTempOptions.series[0].data = data?.result?.map((item) =>
        Number(item.water_temp)
      );
      newWaterOptions.series[0].data = data?.result?.map((item) =>
        Number(item.water_level)
      );

      newEcOptions.xAxis.categories = data?.result?.map((item) =>
        moment(item?.currDate_updated).format("HH:mm")
      );
      newphOptions.xAxis.categories = data?.result?.map((item) =>
        moment(item?.currDate_updated).format("HH:mm")
      );
      newSalinityOptions.xAxis.categories = data?.result?.map((item) =>
        moment(item?.currDate_updated).format("HH:mm")
      );
      newWaterOptions.xAxis.categories = data?.result?.map((item) =>
        moment(item?.currDate_updated).format("HH:mm")
      );
      newTempOptions.xAxis.categories = data?.result?.map((item) =>
        moment(item?.currDate_updated).format("HH:mm")
      );

      setEcOptions({ ...newEcOptions });
      setPhOptions({ ...newphOptions });
      setTempOptions({ ...newTempOptions });
      setSalinityOptions({ ...newSalinityOptions });
      setWaterOptions({ ...newWaterOptions });
      setGraphData(data?.result);
    } else {
      if (id) {
        let data = await _asyncGetDosingMachineReading(id);
        setRealTimeData(data?.real_time_readings);
        let dataAlarms = await _asyncGetDosingMachineAlarms(id);
        setDosingAlarmList(dataAlarms);
      }
    }
  }

  useEffect(() => {
    _fetchData();
  }, []);

  useEffect(() => {
    if (logData) {
      let params = {
        filter_type: filterValue.filter_type,
        time_amount: filterValue.time_amount,
      };
      _fetchDosingById(selectedDosing?.id, params);
    } else {
      _fetchDosingById(selectedDosing?.id);
    }
  }, [logData, filterValue]);

  const onChartClick = (name) => {
    const newEcOptions = { ...ecOptions };
    const newphOptions = { ...phOptions };
    const newTempOptions = { ...tempOptions };
    const newSalinityOptions = { ...salinityOptions };
    const newWaterOptions = { ...waterOptions };
    if (name === "EC") {
      newEcOptions.series[0].data = graphData?.map((item) => Number(item.ec));
      newEcOptions.name = name;
      newEcOptions.xAxis.categories = graphData?.map((item) =>
        moment(item?.currDate_updated).format("HH:mm")
      );
      setChartOptions({ ...newEcOptions });
    } else if (name === "pH") {
      newphOptions.series[0].data = graphData?.map((item) => Number(item.ph));
      newphOptions.name = name;
      newphOptions.xAxis.categories = graphData?.map((item) =>
        moment(item?.currDate_updated).format("HH:mm")
      );
      setChartOptions({ ...newphOptions });
    } else if (name === "Salinity") {
      newSalinityOptions.series[0].data = graphData?.map((item) =>
        Number(item.salinity)
      );
      newSalinityOptions.name = name;
      newSalinityOptions.xAxis.categories = graphData?.map((item) =>
        moment(item?.currDate_updated).format("HH:mm")
      );
      setChartOptions({ ...newSalinityOptions });
    } else if (name === "Temperature") {
      newTempOptions.series[0].data = graphData?.map((item) =>
        Number(item.water_temp)
      );
      newTempOptions.name = name;
      newTempOptions.xAxis.categories = graphData?.map((item) =>
        moment(item?.currDate_updated).format("HH:mm")
      );
      setChartOptions({ ...newTempOptions });
    } else if (name === "Water Level") {
      newWaterOptions.series[0].data = graphData?.map((item) =>
        Number(item.water_level)
      );
      newWaterOptions.name = name;
      newWaterOptions.xAxis.categories = graphData?.map((item) =>
        moment(item?.currDate_updated).format("HH:mm")
      );
      setChartOptions({ ...newWaterOptions });
    }
    setShowChartOnModal(true);
  };

  const styles = {
    dial: {
      display: "inline-block",
      width: `240px`,
      height: `auto`,
      color: "#000",
      border: "0.5px solid #fff",
      padding: "2px",
    },
  };

  async function _assign() {
    let body = {
      farm_id: assignDosing?.farm_id,
      facility_id: assignDosing?.facility_id,
    };
    let data = await _assignDMtoFarm(assignDosing?.dm_id, body, assignUnassign);
    if (data?.message === "Success") {
      setShowSuccessModal(true);
    }
    setTimeout(() => {
      setShowSuccessModal(false);
      window.location.reload();
    }, 2000);
    setAssignDosing({});
    setShowConfirmModal(false);
    _fetchData();
  }

  async function _unAssign() {
    let data = await _assignDMtoFarm(selectedDosing?.id, null, assignUnassign);
    if (data?.message === "Success") {
      setShowSuccessModal(true);
    }
    setTimeout(() => {
      setShowSuccessModal(false);
    }, 2000);
    setAssignDosing({});
    setShowConfirmModal(false);
    _fetchData();
  }

  const selectDosingMachine = (item) => {
    if (logData) {
      let params = {
        filter_type: filterValue.filter_type,
        time_amount: filterValue.time_amount,
      };
      _fetchDosingById(item?.id, params);
    } else {
      _fetchDosingById(item?.id);
    }
    setSelectedDosing(item);
  };

  const selectScheduler = async (id) => {
    let res = await _asyncGetDosingMachineScheduler(id);
    if (res.length > 0) {
      setSchedulerList(res);
      setShowScheluePage(true);
    } else {
      setShowScheluePage(false);
    }
  };

  const onDaysClick = (days) => {
    const arr = [...selectedDays];
    const newId = days;
    if (!arr.includes(newId)) {
      arr.push(newId);
    } else {
      arr.splice(arr.indexOf(newId), 1);
    }
    setSelectedDays(arr);
    console.log(arr);
  };

  const onStatusClick = (status) => {
    setSelectedStatus(status);
  };

  const resetFilter = async () => {
    setShowFilter(false);
    setSelectedStatus(null);
    setSelectedDays([]);
    let res = await _asyncGetDosingMachineScheduler(selectedDosing?.id);
    if (res.length > 0) {
      setSchedulerList(res);
      setShowFilter(false);
    }
  };

  const addFilters = async () => {
    let is_enabled = null;
    if (selectedStatus === "inactive") {
      is_enabled = false;
    }
    if (selectedStatus === "active") {
      is_enabled = true;
    }
    let params = {
      is_enabled,
    };
    if (selectedDays.length > 0) {
      selectedDays.map((item) => {
        params[item] = true;
      });
    }
    let res = await _asyncGetDosingMachineScheduler(selectedDosing?.id, params);
    if (res.length > 0) {
      setSchedulerList(res);
      setShowFilter(false);
    }
  };

  const assign = (selectedItem, selectedFarm, selectedGreenHouse) => {
    if (selectedFarm === undefined) {
      toast.error("Please select farm first");
      return;
    } else {
      let data = {
        facility_id: selectedGreenHouse?.id,
        farm_id: selectedFarm?.id,
        dm_id: selectedItem?.id,
      };
      setAssignDosing(data);
      setShowConfirmModal(true);
      setShowAssignModal(false);
    }
  };

  // const onStartChange = () => {
  //   // used the following line as i'm using time as well, but it's not obvious that
  //   // you need to click it to close the pickup calendar
  //   startRef.current.setOpen(false);
  //   endRef.current.setOpen(true);
  // };

  return (
    <div className="h-[100%]">
      <p
        style={{
          fontFamily: "Poppins",
          fontWeight: "bold",
          fontSize: "21px",
          padding: 10,
        }}
      >
        Dosing Machine Lists
      </p>
      {showSuccessModal ? (
        <SuccessfullMesg
          label={`Dosing machine succesfully ${
            assignUnassign ? "assign" : "unassign"
          }`}
          tick={true}
          onSubmit={() => setShowSuccessModal(false)}
        />
      ) : null}

      {showFilter ? (
        <SchedulerFilterModal
          hideModal={() => setShowFilter(false)}
          resetFilter={resetFilter}
          addFilters={addFilters}
          selectedDays={selectedDays}
          selectedStatus={selectedStatus}
          onDaysClick={(e) => onDaysClick(e)}
          onStatusClick={(e) => onStatusClick(e)}
        />
      ) : null}

      {showChartOnModal ? (
        <ChartOnModal
          chartOptions={chartOptions}
          name={"test"}
          hideModal={() => setShowChartOnModal(false)}
        />
      ) : null}
      {showAssignModal ? (
        <AssignFarmModal
          assignUnassign={assignUnassign}
          selectedDosing={selectedDosing}
          hideModal={() => setShowAssignModal(false)}
          onAssign={(selectedItem, selectedFarm, selectedGreenHouse) =>
            assign(selectedItem, selectedFarm, selectedGreenHouse)
          }
        />
      ) : null}
      {showConfirmModal ? (
        <ConfirmationModal
          label={`Are you sure to ${
            assignUnassign ? "assign" : "unassign"
          } the dosing machine to the selected farm and greenhouse ?`}
          label2={"No"}
          label3={"Yes"}
          hideModal={() => setShowConfirmModal(false)}
          onConfirm={assignUnassign ? _assign : _unAssign}
        />
      ) : null}
      <div className="flex scrollbar-hide w-full [&>div]:flex-shrink-0 bg-[#D0D0D0] p-2 items-center overflow-x-scroll">
        {dosingList.map((item, index) => {
          return (
            <DosingMachineListComp
              item={item}
              onSelection={(item) => selectDosingMachine(item)}
              onScheduleClick={() => selectScheduler(item?.id)}
              selectedDosing={selectedDosing}
              assigned={(item, bol) => {
                if (bol === true) setShowAssignModal(true);
                if (bol === false) setShowConfirmModal(true);
                setAssignUnassign(bol);
              }}
            />
          );
        })}
      </div>
      {showScheluePage ? (
        <div class="relative mt-5">
          <p className="text-[18px] p-3 float-left font-bold">Schedule List</p>
          <div className="flex float-right p-3">
            <IoIosArrowRoundBack size={25} color={"green"} className="mt-2" />
            <p
              onClick={() => setShowScheluePage(false)}
              className="text-green mt-2 cursor-pointer underline"
            >
              Go to Dashboard
            </p>
            <div
              onClick={() => setShowFilter(true)}
              className="flex justify-center items-center ml-5 rounded-lg cursor-pointer h-[39px] border border-gray-200 px-8"
            >
              <img src={filters_img} className="w-5 h-5" />
            </div>
          </div>

          <div className="shadow-sm shadow-gray-500 rounded-lg p-5 h-[300px]">
            <div className="w-full overflow-y-scroll scrollbar-hide h-[210px]">
              <table className="w-full text-sm text-left">
                <thead class="text-sm text-gray-700 uppercase bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-1 py-1 text-green text-center"
                    >
                      #
                    </th>
                    <th
                      scope="col"
                      className="px-1 py-1 text-green text-center"
                    >
                      Start Time
                    </th>
                    <th
                      scope="col"
                      className="px-1 py-1 text-green text-center"
                    >
                      End Time
                    </th>
                    <th
                      scope="col"
                      className="px-1 py-1 text-green text-center"
                    >
                      Duration
                    </th>
                    <th
                      scope="col"
                      className="px-1 py-1 text-green text-center"
                    >
                      Days
                    </th>
                    <th scope="col" className="px-1 py-1 text-green">
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {schedulerList.map((item, idx) => {
                    return (
                      <tr class="odd:bg-white  even:bg-gray-50  border-b">
                        {/* <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                    Apple MacBook Pro 17"
                </th> */}
                        <td class="px-1 py-1 text-center">{idx + 1}</td>
                        <td class="px-1 py-1 text-center">
                          {moment(item?.start_time, "HH:mm").format("HH:mm")}
                        </td>
                        <td class="px-1 py-1 text-center">
                          {moment(item?.end_time, "HH:mm").format("HH:mm")}
                        </td>
                        <td class="px-1 py-1 text-center">
                          {item.duration} {"Minutes"}
                        </td>
                        <td class="px-2 py-2 text-center">
                          <p
                            className={`w-7 h-7 rounded-full inline-flex items-center justify-center text-white text-xs ${
                              item?.monday ? "bg-[#40AE49]" : "bg-[#808080]"
                            }`}
                          >
                            MO
                          </p>
                          <p
                            className={`w-7 h-7 ml-2 rounded-full inline-flex items-center justify-center text-white text-xs ${
                              item?.tuesday ? "bg-[#40AE49]" : "bg-[#808080]"
                            }`}
                          >
                            TU
                          </p>
                          <p
                            className={`w-7 h-7 ml-2 rounded-full inline-flex items-center justify-center text-white text-xs ${
                              item?.wednesday ? "bg-[#40AE49]" : "bg-[#808080]"
                            }`}
                          >
                            WE
                          </p>
                          <p
                            className={`w-7 h-7 ml-2 rounded-full inline-flex items-center justify-center text-white text-xs ${
                              item?.thursday ? "bg-[#40AE49]" : "bg-[#808080]"
                            }`}
                          >
                            TH
                          </p>
                          <p
                            className={`w-7 h-7 ml-2 rounded-full inline-flex items-center justify-center text-white text-xs ${
                              item?.friday ? "bg-[#40AE49]" : "bg-[#808080]"
                            }`}
                          >
                            FR
                          </p>
                          <p
                            className={`w-7 h-7 ml-2 rounded-full inline-flex items-center justify-center text-white text-xs ${
                              item?.saturday ? "bg-[#40AE49]" : "bg-[#808080]"
                            }`}
                          >
                            SA
                          </p>
                          <p
                            className={`w-7 h-7 ml-2 rounded-full inline-flex items-center justify-center text-white text-xs ${
                              item?.sunday ? "bg-[#40AE49]" : "bg-[#808080]"
                            }`}
                          >
                            SU
                          </p>
                        </td>
                        <td className="">
                          <div
                            className={`flex w-[72px] h-[32px]  ${
                              item?.is_enabled ? "bg-[#40AE49]" : "bg-[#808080]"
                            } text-white p-2 rounded-sm cursor-pointer`}
                          >
                            <p className="text-xs ml-1">
                              {item?.is_enabled ? "Active" : "Inactive"}
                            </p>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      ) : (
        <div className="mt-3 h-[60%] overflow-auto scrollbar-hide">
          <div className="grid grid-cols-2 mt-1 mb-5">
            <div className="flex justify-end items-center">
              <button
                type="button"
                className={
                  !logData
                    ? `flex bg-[#808080] w-[120px] h-[40px] items-center rounded-md shadow-black ml-5`
                    : `flex bg-[#fff] w-[120px] h-[40px] border items-center rounded-md shadow-black ml-5`
                }
                onClick={() => setLogData(false)}
              >
                <p
                  className={
                    !logData
                      ? `w-full text-white text-sm`
                      : `w-full text-black text-sm`
                  }
                >
                  Realtime
                </p>
              </button>
              <button
                type="button"
                className={
                  !logData
                    ? `flex bg-[#fff] border w-[120px] h-[40px] items-center rounded-md shadow-black ml-5`
                    : `flex bg-[#808080] w-[120px] h-[40px] items-center rounded-md shadow-black ml-5`
                }
                onClick={() => setLogData(true)}
              >
                <p
                  className={
                    !logData
                      ? `w-full text-black text-center text-sm`
                      : `w-full text-white text-center text-sm`
                  }
                >
                  Log Data
                </p>
              </button>
            </div>
            <div className="flex justify-end">
              {/* <div className="flex w-1/2 justify-center items-center">
              <div>
                <DatePicker
                  className="w-[20px] h-[0px]"
                  ref={startRef}
                  onChange={onStartChange}
                  //placeholderText="Select date"
                />
              </div>
            </div> */}
              {/* <DatePicker ref={endRef} /> */}
              {logData && (
                <DropDownComp
                  custom={true}
                  listData={allTime}
                  active={filterValue}
                  type={"name"}
                  onSelected={(item) => {
                    // if (item.farm_name === "Custom") {
                    //   startRef.current.setOpen(true);
                    // }
                    setFilterValue(item);
                  }}
                  customStyles={
                    "rounded-md justify-between text-[#3E4347] text-[14px]"
                  }
                  customMenuStyle={"relative inline-block text-left w-1/2"}
                />
              )}
            </div>
          </div>

          <div className="flex mt-5">
            <div style={styles.dial}>
              <div className="flex items-center justify-center">
                <p className="flex bg-[#24352F] px-4 py-2 rounded-md shadow-black text-white text-sm">
                  pH
                </p>
              </div>
              {!logData ? (
                <div style={{ position: "relative" }}>
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      position: "absolute",
                      top: 0,
                      left: 0,
                    }}
                  >
                    <GaugeChart
                      id="gauge-chart6"
                      animate={true}
                      nrOfLevels={15}
                      colors={["#F491A3", "#F491A3", "#F491A3"]}
                      percent={realTimeData?.ph ? realTimeData.ph / 14 : 0}
                      hideText={true}
                      needleColor="#345243"
                    />
                  </div>
                  <span
                    style={{
                      fontFamily: "Poppins",
                      color: "#40AE49",
                      fontWeight: "900",
                      marginLeft: "46%",
                      marginTop: "10%",
                      zIndex: 9,
                      position: "absolute",
                    }}
                  >
                    {realTimeData?.ph}
                  </span>
                  <div
                    style={{
                      zIndex: 9,
                      width: "100%",
                      position: "absolute",
                      marginTop: "38%",
                      paddingLeft: "15%",
                      paddingRight: "15%",
                    }}
                  >
                    <span>0</span>
                    <span
                      style={{
                        marginLeft: "37%",
                        fontSize: "15px",
                        fontWeight: "900",
                      }}
                    >
                      pH
                    </span>
                    <span style={{ marginLeft: "37%" }}>14</span>
                  </div>
                </div>
              ) : (
                <div onClick={() => onChartClick("pH")}>
                  <HighchartsReact
                    containerProps={{ className: "h-[180px]" }}
                    highcharts={Highcharts}
                    options={phOptions}
                  />
                </div>
              )}
            </div>
            <div style={styles.dial}>
              <div className="flex items-center justify-center">
                <p className="flex bg-[#24352F] px-4 py-2 rounded-md shadow-black text-white text-sm">
                  EC
                </p>
              </div>
              {!logData ? (
                <div style={{ position: "relative" }}>
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      position: "absolute",
                      top: 0,
                      left: 0,
                    }}
                  >
                    <GaugeChart
                      id="gauge-chart6"
                      animate={true}
                      nrOfLevels={15}
                      colors={["#F491A3", "#F491A3", "#F491A3"]}
                      percent={realTimeData?.ec ? realTimeData.ec / 5 : 0}
                      hideText={true}
                      needleColor="#345243"
                    />
                  </div>
                  <span
                    style={{
                      fontFamily: "Poppins",
                      color: "#40AE49",
                      fontWeight: "900",
                      marginLeft: "44%",
                      marginTop: "10%",
                      zIndex: 9,
                      position: "absolute",
                    }}
                  >
                    {realTimeData?.ec}
                  </span>
                  <div
                    style={{
                      zIndex: 9,
                      width: "100%",
                      position: "absolute",
                      marginTop: "39%",
                      paddingLeft: "15%",
                      paddingRight: "15%",
                    }}
                  >
                    <span>0</span>
                    <span
                      style={{
                        marginLeft: "27%",
                        fontSize: "15px",
                        fontWeight: "900",
                      }}
                    >
                      mS/cm
                    </span>
                    <span style={{ marginLeft: "28%" }}>5</span>
                  </div>
                </div>
              ) : (
                <div onClick={() => onChartClick("EC")}>
                  <HighchartsReact
                    containerProps={{ className: "h-[180px]" }}
                    highcharts={Highcharts}
                    options={ecOptions}
                  />
                </div>
              )}
            </div>
            <div style={styles.dial}>
              <div className="flex items-center justify-center">
                <p className="flex bg-[#24352F] px-4 py-2 rounded-md shadow-black text-white text-sm">
                  Salinity
                </p>
              </div>

              {!logData ? (
                <div style={{ position: "relative" }}>
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      position: "absolute",
                      top: 0,
                      left: 0,
                    }}
                  >
                    <GaugeChart
                      id="gauge-chart6"
                      animate={true}
                      nrOfLevels={15}
                      colors={["#F491A3", "#F491A3", "#F491A3"]}
                      percent={
                        realTimeData?.salinity
                          ? realTimeData.salinity / 4000
                          : 0
                      }
                      hideText={true}
                      needleColor="#345243"
                    />
                  </div>
                  <span
                    style={{
                      fontFamily: "Poppins",
                      color: "#40AE49",
                      fontWeight: "900",
                      marginLeft: "38%",
                      marginTop: "12%",
                      zIndex: 9,
                      position: "absolute",
                    }}
                  >
                    {realTimeData?.salinity}
                  </span>
                  <div
                    style={{
                      zIndex: 9,
                      width: "100%",
                      position: "absolute",
                      marginTop: "39%",
                      paddingLeft: "15%",
                      paddingRight: "15%",
                    }}
                  >
                    <span>0</span>
                    <span
                      style={{
                        marginLeft: "33%",
                        fontSize: "15px",
                        fontWeight: "900",
                      }}
                    >
                      ppm
                    </span>
                    <span style={{ marginLeft: "25%" }}>4000</span>
                  </div>
                </div>
              ) : (
                <div onClick={() => onChartClick("Salinity")}>
                  <HighchartsReact
                    containerProps={{ className: "h-[180px]" }}
                    highcharts={Highcharts}
                    options={salinityOptions}
                  />
                </div>
              )}
            </div>
            <div style={styles.dial}>
              <div className="flex items-center justify-center">
                <p className="flex bg-[#24352F] px-4 py-2 rounded-md shadow-black text-white text-sm">
                  Water Level
                </p>
              </div>
              {!logData ? (
                <div style={{ position: "relative" }}>
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      position: "absolute",
                      top: 0,
                      left: 0,
                    }}
                  >
                    <GaugeChart
                      id="gauge-chart6"
                      animate={true}
                      nrOfLevels={15}
                      colors={["#F491A3", "#F491A3", "#F491A3"]}
                      percent={
                        realTimeData?.water_level
                          ? realTimeData.water_level / 150
                          : 0
                      }
                      hideText={true}
                      needleColor="#345243"
                    />
                  </div>
                  <span
                    style={{
                      fontFamily: "Poppins",
                      color: "#40AE49",
                      fontWeight: "900",
                      marginLeft: "46%",
                      marginTop: "10%",
                      zIndex: 9,
                      position: "absolute",
                    }}
                  >
                    {realTimeData?.water_level}
                  </span>
                  <div
                    style={{
                      zIndex: 9,
                      width: "100%",
                      position: "absolute",
                      marginTop: "39%",
                      paddingLeft: "15%",
                      paddingRight: "15%",
                    }}
                  >
                    <span>0</span>
                    <span
                      style={{
                        marginLeft: "40%",
                        fontSize: "15px",
                        fontWeight: "900",
                      }}
                    >
                      %
                    </span>
                    <span style={{ marginLeft: "35%" }}>150</span>
                  </div>
                </div>
              ) : (
                <div onClick={() => onChartClick("Water Level")}>
                  <HighchartsReact
                    containerProps={{ className: "h-[180px]" }}
                    highcharts={Highcharts}
                    options={waterOptions}
                  />
                </div>
              )}
            </div>
            <div style={styles.dial}>
              <div className="flex items-center justify-center">
                <p className="flex bg-[#24352F] px-4 py-2 rounded-md shadow-black text-white text-sm">
                  Temperature
                </p>
              </div>
              {!logData ? (
                <div style={{ position: "relative" }}>
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      //position: 'absolute',
                      top: 0,
                      left: 0,
                    }}
                  >
                    <GaugeChart
                      id="gauge-chart6"
                      animate={true}
                      nrOfLevels={15}
                      colors={["#F491A3", "#F491A3", "#F491A3"]}
                      percent={
                        realTimeData?.water_temp
                          ? realTimeData.water_temp / 50
                          : 0
                      }
                      hideText={true}
                      needleColor="#345243"
                    />
                  </div>
                  <span
                    style={{
                      fontFamily: "Poppins",
                      color: "#40AE49",
                      fontWeight: "900",
                      marginLeft: "40%",
                      marginTop: "-34%",
                      zIndex: 9,
                      position: "absolute",
                    }}
                  >
                    {realTimeData?.water_temp}
                  </span>
                  <div
                    style={{
                      zIndex: 9,
                      width: "100%",
                      position: "absolute",
                      marginTop: "-5%",
                      paddingLeft: "15%",
                    }}
                  >
                    <span>0</span>
                    <span
                      style={{
                        marginLeft: "22%",
                        fontSize: "15px",
                        fontWeight: "900",
                      }}
                    >
                      Celcius
                    </span>
                    <span style={{ marginLeft: "20%" }}>50</span>
                  </div>
                </div>
              ) : (
                <div onClick={() => onChartClick("Temperature")}>
                  <HighchartsReact
                    containerProps={{ className: "h-[180px]" }}
                    highcharts={Highcharts}
                    options={tempOptions}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="grid grid-cols-2 gap-4 mt-8 p-3">
            <div className="shadow-sm shadow-gray-500 rounded-lg p-5 h-[200px]">
              <div className="overflow-y-scroll scrollbar-hide h-[170px]">
                <p className="font-bold text-[16px] text-black">Alarm Log</p>
                {dosingAlarmList?.map((item, index) => {
                  return (
                    <div
                      key={item?.identifier + index}
                      className="flex space-x-12"
                    >
                      <p className="text-[14px] font-bold">
                        {item?.identifier}
                      </p>

                      <p className="text-[14px] w-[130px]">
                        {item?.alarm_type?.replace(/_/g, " ")}
                      </p>

                      <p className="text-[14px]">
                        {getDateFormatMoment(item?.created_date, true)}
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="shadow-sm shadow-gray-500 rounded-lg p-5 h-[200px]">
              <div className="overflow-y-scroll scrollbar-hide h-[170px]">
                <p className="font-bold text-[16px] text-black">
                  Device Status
                </p>
                {dosingList.map((item, index) => {
                  return (
                    <div
                      key={item?.identifier}
                      className="flex mt-2 justify-between"
                    >
                      <div>
                        <p className="text-[14px]">
                          {item?.identifier}{" "}
                          {item?.farm_info?.farm_name
                            ? item?.farm_info?.farm_name
                            : ""}
                        </p>
                      </div>
                      <div>
                        <p
                          className={`text-[10px] font-[600] text-white text-center ${
                            item?.is_active ? "bg-[#40AE49]" : "bg-[#F491A3]"
                          } px-3 py-1`}
                        >
                          {item?.is_active ? "online" : "offline"}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
