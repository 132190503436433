import React, { useEffect, useState } from 'react'
import { PageLayoutComp } from '../../../components/PageLayoutComp'
import { BsPlus } from 'react-icons/bs'
import { IoIosArrowRoundBack } from 'react-icons/io'
import { useNavigate, useParams } from 'react-router-dom'
// import  bx_edit from "../src/images/bx_edit.png"
import bx_edit from "../../../../src/images/bx_edit.png";
import { _asyncGetBOMProductDetail } from '../../../api'

function BillMaterials() {
  const {id} = useParams()
    const location = useNavigate();
    const [bomDetail, setBomDetail] = useState([])


    function handleBackButton() {
        location("/budget-plan-rab/bom-master-data-list");
      }


      function handleEditButton() {
        location("/budget-plan-product-detail/"+id);
      }


      async function _fetchBOMDetail() {
        // console.log(JSON.stringify(id),'-----id----');
        let Data = await _asyncGetBOMProductDetail(id);
        setBomDetail(Data?.data)
      }

      useEffect(() => {
        _fetchBOMDetail()
      }, [])

  return (
    <div className="w-screen h-screen">
    <PageLayoutComp>
      <div className="h-[100%] bg-[#E6F1ED] p-4">
        <div className="h-[7%]">
          <div className="flex justify-between">
            <p className="text-3xl font-bold mb-0">Bill of Materials - {bomDetail?.pc_info?.name} ({bomDetail?.pc_info?.default_code})</p>
            <div className="flex">
              <div
                onClick={handleBackButton}
                className="flex cursor-pointer"
              >                
                 <IoIosArrowRoundBack size={22} color={"green"} className="mt-2" />
              <p className="text-green text-md mt-2 cursor-pointer font-bold">
                Back to List
              </p>
              </div>
            </div>
          </div>
        </div>
        <div className="h-[100%]">
          <div className="p-6 mt-2 bg-white shadow rounded-lg max-h-[92%] overflow-y-auto">
      <div className="mb-4 flex justify-between w-full">
     <div className='flex w-full mb-8'>
     <div className='w-[50%]'>
       <div className='flex space-x-10 mb-2'>
        <p className="text-sm font-bold w-[17%]">Product   :  </p> 
       <p className='text-sm'> ({bomDetail?.pc_info?.default_code})
        </p>
        </div>
        <div className='flex space-x-10 mb-2'>
        <p className="text-sm font-bold w-[17%]">Product MPC  :  </p> 
       <p className='text-sm'> ({bomDetail?.mpc?.production_code})
        </p>
        </div>
     </div>
         <div className='w-[50%]'>     
        <div className="mb-2 space-x-10 flex">
          <p className='text-sm font-bold w-[17%]'>Reference : </p>
          <p className='text-sm'> {bomDetail?.ref}</p>
        </div>
        <div className="text-sm space-x-10 flex">
          <p className="text-sm font-bold w-[17%]">Quantity  :</p>
           <p className='="text-sm'>{bomDetail?.product_qty} Kg</p>
        </div>
         </div>
       </div>
       <div className='bg-green w-[9%] flex justify-center items-center rounded-lg h-8 cursor-pointer'
       onClick={()=>handleEditButton()}>
        <img
        className='h-[3vh] w-[20%]'
        src={bx_edit}/>
        <p className='text-white ml-2'>Edit</p>
       </div>
      </div>
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="border-gray-200 border-b-[2px]">
            <tr>
              <th scope="col" className="px-6 py-3 text-left text-xs font-bold text-green100 uppercase tracking-wider">
                SKU
              </th>
              <th scope="col" className="px-6 py-3 text-left text-sm font-bold text-green100  tracking-wider">
                Quantity
              </th>
              <th scope="col" className="px-6 py-3 text-left text-sm font-bold text-green100  tracking-wider">
                UoM
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {bomDetail?.sku?.map((item, index) => (
              <tr key={index}>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{item?.sku_info?.name}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{item?.product_qty}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{item?.sku_info?.uom}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
          </div>
      </div>
    </PageLayoutComp>
  </div>
  )
}

export default BillMaterials