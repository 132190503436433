import React, { useEffect, useState } from "react";
import { BOM_TB_COL, RAB_TB_COL } from "..";
import { _asyncGetAllRab, _asyncGetBOMProductList,} from "../../../api";
import { TableSearchComp } from "../../../components/TableSearchComp";
import { TablePaginationComp } from "../../../components/TablePaginationComp";
import { useNavigate } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";

function BomDataTable({metaData, archive = false}) {
  // console.log('metaData?.searchableFeaturesWithOptions---->>',metaData?.searchableFeaturesWithOptions);
    const size = 10;
    let colums = BOM_TB_COL;
    const location = useNavigate();
    const [totalPage, setTotalPage] = useState();
    const [selectedPage, setSelectedPage] = useState(1);
     const [showFilter, setShowFilter] = useState(false);
     const [action, setAction] = useState("");
     const [searchText, setSearchText] = useState("")
     const [totalItems, setTotalItems] = useState();
     const [searchType, setSearchType] = useState('');
    //  console.log('.....>>>>......',searchType);
     const [sortValue, setSortValue] = useState({id: "3",name: "Sort By"});
     const [bomList, setBomList] = useState([]);
     let user = JSON.parse(localStorage.getItem("user_data"));
    

     const sortType = [
        { id: "1", name: "Latest" },
        { id: "2", name: "Earliest" },
      ];
    
    
      async function _fetchBOM(page,size = 10, sort_order = 'ASC',search=searchText) {
        let Data = await _asyncGetBOMProductList(page,size,sort_order,search);
        console.log('responce data -->',JSON.stringify(Data?.data));
        if (Data) {
          setTotalItems(Data?.data?.totalItems)
          setTotalPage(Data?.data?.last_page)
          setBomList(Data?.data?.data);
        }
      }

      useEffect(() => {
      _fetchBOM(selectedPage);
      }, [])
      
      useEffect(() => {
        let sort_order = sortValue?.name === "Earliest" ? "ASC":"DESC"
        _fetchBOM(selectedPage,10, sort_order,searchText);
      }, [sortValue]);
    



const handleSearchSubmit = (action) => {
  setSelectedPage(0);
  setAction(action)
  if (action === "reset") {
    setSearchText("");
    _fetchBOM("")
  }
  if (action !== "reset") {
    _fetchBOM(0,10,'ASC',searchText)
  }
}

const handleNav = (item) => {
  location("/budget-plan-rab/bill-materials/"+item?.id)
}


const TableRow = ({ data }) => {
  return (
    <tr className="border-gray-200 border-b-[1px]">
      <td className="py-2">
        <p className="text-sm flex pl-[49px] items-center">
          {data?.pc_info?.name}  ({data?.pc_info?.default_code})
        </p>
      </td>
      <td className="py-2">
        <p className="text-sm flex items-center pl-[49px]">
          {data?.ref}
        </p>
      </td>
      <td className="py-2">
        <p className="text-sm flex items-center pl-[49px]">
          {data?.type}
        </p>
      </td>
      <td className="py-2">
        <div className="flex justify-center">
          <IoIosArrowForward
           onClick={()=>handleNav(data)}
           className="cursor-pointer"
           size={22}/>
        </div>
      </td>
    </tr>
  );
};
  return (
    <div className={`${archive ? "h-[85%]" : "h-[100%]"}`}>
  

    <div className="h-[100%]">
      <div className="h-[10%]">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className="w-[100%] mt-4">
            <TableSearchComp
            onChange={(text) => setSearchText(text)}
            openFilter={() => setShowFilter(true)}
            sortBy={true}
            sortType={sortType}
            sortValue={sortValue}
            handleSortBy={(item) => {
              setSortValue(item)
            }}
            searchText={searchText}
            handleSearchSubmit={handleSearchSubmit}
            searchTypeValues={
              metaData?.searchableFeaturesWithOptions?.bom_pc_list
            }
            searchType={searchType}
            onSearchChangeType={(item) => setSearchType(item)}
          />
          </div>
        </div>
      </div>
      <div className={`h-[80%] overflow-auto scrollbar-hide mt-5`}>
        <table className="w-full">
          <thead>
            <tr className="sticky">
              
              {colums.map((col, i) => {
                return (
                  <td className="text-sm bg-white">
                    <div className="flex items-center py-2 pl-[49px] border-gray-200 border-b-[1px] cursor-pointer ">
                      <p className=" text-md font-bold text-[#3EB049]">{col.Header}</p>
                    </div>
                  </td>
                );
              })}
            </tr>
          </thead>
          <tbody>
          {bomList?.map((item, index) => (
          <TableRow key={index} data={item} />
        ))}
          </tbody>
        </table>
      </div>
      <div className="flex justify-end items-center h-[10%] text-sm px-5">
        {totalPage !== undefined ? (
          <TablePaginationComp
            totalPages={totalPage}
            active={selectedPage}
            selectedPage={(p) => {
              setSelectedPage(p);
              _fetchBOM(p)
            }}
            totalItems={totalItems}
          />
        ) : null}
      </div>
    </div>
  </div>
  )
}
export default BomDataTable