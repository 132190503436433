import moment from "moment";
import React, { useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import calendarImg from "../images/FarmPipeLine/calendar.png";

export const DatePickerComp = ({ onSelected, value = new Date(), allowPastDate = true, allowFutureDate=true, disabled=false}) => {
  const [dateTime, setDateTime] = React.useState("MM/DD/YYYY");
  const [isDateSelected, setDateSelected] = React.useState(false);

  useEffect(()=>{
    let date = new Date(value)
   setDateTime((date.getMonth() +1) + "/" + date.getDate()  + "/" + date.getFullYear())
  },[value])

  let dt = new Date();
  dt.setDate(dt.getDate());
  const CustomDatePickerUI = React.forwardRef(({ value, onClick }, ref) => (
    <button className="flex w-full" onClick={onClick} ref={ref}>
      <img className="w-[18px] h-[18px]" src={calendarImg} alt="calendar" />
      <p
        className={`${
          isDateSelected ? "text-[#000000]" : "text-[#979797]"
        } text-sm ml-3`}
      >
        {dateTime}
      </p>
    </button>
  ));

  return (
    <>
      <div className="flex w-full h-[45px] items-center px-2 border border-gray-300 rounded-lg hover:bg-gray-50">
        <DatePicker
          className="w-full border-none focus:border-transparent"
          onChange={(date) => {
            let d = (date.getMonth() +1) + "/" + date.getDate()  + "/" + date.getFullYear();
            setDateTime(d);
            onSelected(d);
            setDateSelected(true);
          }}
          minDate={allowPastDate ? undefined :dt}
          maxDate={allowFutureDate ? undefined: dt}
          disabled={disabled}
          customInput={<CustomDatePickerUI />}
        />
      </div>
    </>
  );
};
