import axios from "axios";
import { data } from "react-phone-code";
import { toast } from "react-toastify";
export const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

export const axiosClientProd = axios.create({
  baseURL: process.env.REACT_APP_PROD_URL,
});

export const GET_PROVINCE = "provinces";
export const GET_DISTRICT = "districts?province=";
export const CREATE_LEAD = "dashboard/createlead";
export const SUBMIT_SITE_SURVEY_INFO =
  "dashboard/submitSiteSurveyInfo?lead_code=";
export const GET_SITE_SURVEY_INFO = "dashboard/siteSurveyInfo?lead_code=";
export const SUBMIT_PRELIM_CONTACT_INFO =
  "dashboard/submitPrelimContactInfo?lead_code=";
export const SUBMIT_DUE_DILIGENCE =
  "dashboard/submitDueDiligenceInfo?lead_code=";
export const SUBMIT_CONTRACT_INFO = "dashboard/submitContractInfo?lead_code=";
export const GET_USER_FARMS = "admin/userfarms?staff_type_id=";
export const ADD_NEW_PRODUCT = "admin/new_product";
export const UPDATE_PRODUCT = "admin/product";
export const PRODUCT_CULTIVATION = "admin/product-cultivation/";
export const LOGOUT = "admin/logout";
export const GET_PRODUCT = "admin/products_v2?page=1&size=50";
export const GET_FARMS = "admin/farms?page=1&size=1000";
export const GET_MITRAS = "admin/top-farm-user/";
export const ADD_GET_PLANTING = "admin/planting";
export const ADD_GET_JOB = 'career/jobs'
export const ADD_GET_CROP_TRACKER = "admin/crops_tracking";
export const GET_DOSING_MACHINE = "admin/dosing_machines?page=1&size=100";
export const GET_HARVEST = "admin/harvests";

export const axiosHeader = () => {
  let token = JSON.parse(localStorage.getItem("user_data"))?.token;
  const headers = {
    token,
  };
  return {
    headers: headers,
  };
};

export const _asyncGetAllProvince = async () => {
  const response = await axiosClient.get(GET_PROVINCE);
  if (response) {
    if (response.status === 200 && response.statusText === "OK") {
      if (response.data.length > 0) {
        return response.data;
      }
    }
  }
};

export const _asyncGetNotifications = async () => {
  const response = await axiosClient.get("admin/notifications", axiosHeader());
  if (response) {
    if (response.status === 200 && response.statusText === "OK") {
      if (response.data.data.length > 0) {
        return response.data.data;
      }
    }
  }
};

export const _asyncGetUnreadTotalNotifications = async () => {
  const response = await axiosClient.get(
    "admin/notifications/total_unread",
    axiosHeader()
  );
  if (response) {
    if (response.status === 200 && response.statusText === "OK") {
      if (response.data) {
        return response.data;
      }
    }
  }
};

export const _asyncGetEsignTaskCount = async () => {
  const response = await axiosClient.get(
    "admin/esign/tasks_count",
    axiosHeader()
  );
  if (response) {
    if (response.status === 200 && response.statusText === "OK") {
      if (response.data) {
        return response.data;
      }
    }
  }
};

export const _asyncSendOtp = async (body) => {
  try {
    const response = await axiosClient.post(
      "admin/guest_login", body,
      axiosHeader()
    );
    if (response) {
      if (response.status === 201 && response.statusText === "Created") {
        if (response.data) {
          return response.data;
        }
      }
    }
  } catch (err) {
    if (err.response.status) {
      if (
        err.response.status === 403 &&
        err.response.data.error === "invalid_token"
      ) {
        alert("Session Expired. Sign in again");
        localStorage.clear();
        window.location.href = "/";
      } else {
        toast.info(err.response.data.message);
      }
    }
    return false
  }
};
export const _asyncDeleteJobs = async (body) => {

  try {
    const response = await axiosClient.delete(
      "career/jobs",
      body,
      axiosHeader()
    );
    if (response) {
      if (response?.status === 201 && response?.status === 201) {
        return response?.data;
      }
    }
  } catch (err) {
    if (err?.response?.status) {
      if (
        err?.response?.status === 403 &&
        err?.response?.data?.error === "invalid_token"
      ) {
        alert("Session Expired. Sign in again");
        localStorage.clear();
        window.location.href = "/";
      } else {
        toast.info(err?.response?.data?.message);
      }
    }
    return false
  }
};

export const _asyncVerifyGuestOtp = async (body) => {
  try {
    const response = await axiosClient.post(
      "admin/verify_login_otp", body,
      axiosHeader()
    );
    if (response) {
      if (response.status === 201) {
        if (response.data) {
          return response.data;
        }
      }
    }

  } catch (err) {
    if (err.response.status) {
      if (
        err.response.status === 403 &&
        err.response.data.error === "invalid_token"
      ) {
        alert("Session Expired. Sign in again");
        localStorage.clear();
        window.location.href = "/";
      } else {
        toast.info(err.response.data.message);
      }
    }
  }
};

export const _asyncGetKycInfo = async () => {
  try {
    const response = await axiosClient.get(
      "admin/esign/kyc_info",
      axiosHeader()
    );
    if (response) {
      if (response.status === 200 && response.statusText === "OK") {
        if (response.data) {
          return response.data;
        }
      }
    }

  } catch (err) {
    if (err.response.status) {
      if (
        err.response.status === 403 &&
        err.response.data.error === "invalid_token"
      ) {
        alert("Session Expired. Sign in again");
        localStorage.clear();
        window.location.href = "/";
      } else {
        toast.info(err.response.data.message);
      }
    }
  }
};
export const _asyncGetWeatherReportRequest = async (id) => {
  try {
    const response = await axiosClient.get(
      `admin/weather_report_requests/${id}`,
      axiosHeader()
    );
    if (response) {
      if (response.status === 200 && response.statusText === "OK") {
        if (response.data) {
          return response.data;
        }
      }
    }

  } catch (err) {
    if (err.response.status) {
      if (
        err.response.status === 403 &&
        err.response.data.error === "invalid_token"
      ) {
        alert("Session Expired. Sign in again");
        localStorage.clear();
        window.location.href = "/";
      } else {
        toast.info(err.response.data.message);
      }
    }
  }
};

export const _asyncGetBOMProductList = async (page, size, sort_order, search) => {

  try {
    const response = await axiosClient.get(
      `admin/bom/list?page=${page}&size=${size}&sort_order=${sort_order}&search_text=${search}`,
      axiosHeader()
    );
    if (response) {
      if (response.status === 200 && response.statusText === "OK") {
        if (response.data) {
          return response.data;
        }
      }
    }
  } catch (err) {
    if (err.response.status) {
      if (
        err.response.status === 403 &&
        err.response.data.error === "invalid_token"
      ) {
        alert("Session Expired. Sign in again");
        localStorage.clear();
        window.location.href = "/";
      } else {
        toast.info(err.response.data.message);
      }
    }
  }
};
export const _asyncGetBOMProductDetail = async (product_id) => {
  try {
    const response = await axiosClient.get(
      `admin/bom/details/${product_id}`,
      axiosHeader()
    );
    if (response) {
      if (response.status === 200 && response.statusText === "OK") {
        if (response.data) {
          return response.data;
        }
      }
    }
  } catch (err) {
    if (err.response.status) {
      if (
        err.response.status === 403 &&
        err.response.data.error === "invalid_token"
      ) {
        alert("Session Expired. Sign in again");
        localStorage.clear();
        window.location.href = "/";
      } else {
        toast.info(err.response.data.message);
      }
    }
  }
};
export const _asyncGetBOMWeatherReport = async (id) => {
  try {
    const response = await axiosClient.get(
      `admin/weather_report/${id}`,
      axiosHeader()
    );
    if (response) {
      if (response.status === 200 && response.statusText === "OK") {
        if (response.data) {
          return response.data;
        }
      }
    }
  } catch (err) {
    if (err.response.status) {
      if (
        err.response.status === 403 &&
        err.response.data.error === "invalid_token"
      ) {
        alert("Session Expired. Sign in again");
        localStorage.clear();
        window.location.href = "/";
      } else {
        toast.info(err.response.data.message);
      }
    }
  }
};
export const _asyncGetPCsBOM = async (product_id) => {
  try {
    const response = await axiosClient.get(
      "admin/bom/pcs?page=1&size=10&sort_order=ASC",
      axiosHeader()
    );
    if (response) {
      if (response.status === 200 && response.statusText === "OK") {
        if (response.data) {
          return response.data;
        }
      }
    }
  } catch (err) {
    if (err.response.status) {
      if (
        err.response.status === 403 &&
        err.response.data.error === "invalid_token"
      ) {
        alert("Session Expired. Sign in again");
        localStorage.clear();
        window.location.href = "/";
      } else {
        toast.info(err.response.data.message);
      }
    }
  }
};
export const _asyncGetEsignTaskList = async (search_text = '', pending = 'pending_on_me', page = '1') => {
  try {
    console.log(`Fetching tasks with search_text: ${search_text}, pending: ${pending}, page: ${page}`);
    const response = await axiosClient.get(
      `admin/esign/tasks?page=${page}&size=10&search_text=${search_text}${pending ? `&doc_sign_status=${pending}` : ''}`,
      axiosHeader()
    );
    if (response) {
      if (response.status === 200 && response.statusText === "OK") {
        if (response.data) {
          console.log('API response:', response.data);
          return response.data;
        }
      }
    }

  } catch (err) {
    if (err.response.status) {
      if (
        err.response.status === 403 &&
        err.response.data.error === "invalid_token"
      ) {
        alert("Session Expired. Sign in again");
        localStorage.clear();
        window.location.href = "/";
      } else {
        toast.info(err.response.data.message);
      }
    }
  }
};

export const _asyncUpdateBOM = async (body, id) => {
  try {
    const response = await axiosClient.put(
      `admin/bom/${id}`,
      body,
      axiosHeader()
    );
    return response
  } catch (err) {
    if (err.response.status) {
      if (
        err.response.status === 403 &&
        err.response.data.error === "invalid_token"
      ) {
        alert("Session Expired. Sign in again");
        localStorage.clear();
        window.location.href = "/";
      } else {
        toast.info(err.response.data.message);
      }
    }
  }
};
export const _asyncCreateNewBOM = async (body) => {
  // console.log(body,'=-=-=body-=-=_asyncCreateNewBOM');
  try {
    const response = await axiosClient.post(
      'admin/bom',
      body,
      axiosHeader()
    );
    return response
  } catch (err) {
    toast.info(err?.response?.data?.error);
    // console.log(err.response.data.error,'-=-=-=-=-err-=-=',err);
    if (err.response.status) {
      if (
        err.response.status === 403 &&
        err.response.data.error === "invalid_token"
      ) {
        alert("Session Expired. Sign in again");
        localStorage.clear();
        window.location.href = "/";
      } else {
        toast.info(err.response.data.message);
      }
    }
  }
};
export const _asyncCreateWeatherReport = async (id, body) => {
  try {
    const response = await axiosClient.post(
      `admin/request_weather_report/${id}`,
      body,
      axiosHeader()
    );
    return response
  } catch (err) {
    toast.info(err?.response?.data?.error);
    if (err.response.status) {
      if (
        err.response.status === 403 &&
        err.response.data.error === "invalid_token"
      ) {
        alert("Session Expired. Sign in again");
        localStorage.clear();
        window.location.href = "/";
      } else {
        toast.info(err.response.data.message);
      }
    }
  }
};

export const _asyncReadNotifications = async (body) => {
  try {
    const response = await axiosClient.put(
      `admin/notifications/mark_read`,
      body,
      axiosHeader()
    );
    if (response) {
      if (
        response.status === 200 &&
        response.statusText === "OK" &&
        response.error == null
      ) {
        return 1;
      } else {
        return response.error;
      }
    }
  } catch (err) {
    if (err.response.status) {
      if (
        err.response.status === 403 &&
        err.response.data.error === "invalid_token"
      ) {
        alert("Session Expired. Sign in again");
        localStorage.clear();
        window.location.href = "/";
      } else {
        toast.info(err.response.data.message);
      }
    }
  }
};

export const _asyncGetDistrictByAbv = async (abv) => {
  const URL = GET_DISTRICT + "" + abv;
  const response = await axiosClient.get(URL, axiosHeader());
  if (response) {
    if (response.status === 200 && response.statusText === "OK") {
      if (response.data.length > 0) {
        return response.data;
      }
    }
  }
};

export async function asynGetSiteSurveyInfo(leadCode) {
  let URL = GET_SITE_SURVEY_INFO + "" + leadCode;
  axiosClient.get(URL, axiosHeader()).then((res) => {
    if (res.status === 200 && res.statusText === "OK") {
      if (!res.data.lead_code) {
        return null;
      } else {
        return res.data;
      }
    } else {
      toast.error("API Error Occured");
    }
  });
}

export const _asyncGetUserFarmsByStaffTypeId = async (staff_type_id) => {
  try {
    const response = await axiosClient.get(
      GET_USER_FARMS + "" + staff_type_id,
      axiosHeader()
    );
    if (response) {
      if (response.status === 200 && response.statusText === "OK") {
        if (response.data && response.data.length > 0) {
          return response.data;
        }
      }
    }
  } catch (err) {
    if (err.response.status) {
      if (
        err.response.status === 403 &&
        err.response.data.error === "invalid_token"
      ) {
        alert("Session Expired. Sign in again");
        localStorage.clear();
        window.location.href = "/";
      } else {
        toast.info(err.response.data.message);
      }
    }
  }
};

export const _asyncGetAllMasterData = async () => {
  try {
    const response = await axiosClient.get(
      "career/master_data",
      axiosHeader()
    );
    if (response) {
      if (response.status === 200 && response.status === 201) {
        return response.data;
      }
    }
  } catch (err) {
    if (err.response.status) {
      if (
        err.response.status === 403 &&
        err.response.data.error === "invalid_token"
      ) {
        alert("Session Expired. Sign in again");
        localStorage.clear();
        window.location.href = "/";
      } else {
        toast.info(err.response.data.message);
      }
    }
  }
};

export const _asyncGetUserRoleSubordinates = async (role_id) => {
  try {
    const response = await axiosClient.get(
      "admin/role_subordinates/" + role_id,
      axiosHeader()
    );
    if (response) {
      if (response.status === 200 && response.statusText === "OK") {
        if (response.data && response.data.length > 0) {
          return response.data;
        }
      }
    }
  } catch (err) {
    if (err.response.status) {
      if (
        err.response.status === 403 &&
        err.response.data.error === "invalid_token"
      ) {
        alert("Session Expired. Sign in again");
        localStorage.clear();
        window.location.href = "/";
      } else {
        toast.info(err.response.data.message);
      }
    }
  }
};

export const _asyncAddNewUser = async (body, staffTypeId) => {
  try {
    let url = staffTypeId === "2" ? "admin/mitra_user" : "admin/admin_user";
    const response = await axiosClient.post(url, body, axiosHeader());
    if (response) {
      if (response.status === 201 && response.statusText === "Created") {
        return 1;
      } else {
        return response.error;
      }
    }
  } catch (err) {
    if (err.response.status) {
      if (
        err.response.status === 403 &&
        err.response.data.error === "invalid_token"
      ) {
        alert("Session Expired. Sign in again");
        localStorage.clear();
        window.location.href = "/";
      } else {
        toast.info(err.response.data.message);
      }
    }
  }
};

export const _asyncSubmitPOBill = async (body) => {
  try {
    const response = await axiosClient.post(
      "admin/po/bill",
      body,
      axiosHeader()
    );
    if (response) {
      if (response.status === 201 && response.statusText === "Created") {
        return 1;
      } else {
        return response.error;
      }
    }
  } catch (err) {
    if (err.response.status) {
      if (
        err.response.status === 403 &&
        err.response.data.error === "invalid_token"
      ) {
        alert("Session Expired. Sign in again");
        localStorage.clear();
        window.location.href = "/";
      } else {
        toast.info(err.response.data.message);
      }
    }
  }
};

export const _asyncGeneratePO = async (body) => {
  try {
    const response = await axiosClient.post(
      "admin/crops/harvested/po",
      body,
      axiosHeader()
    );
    if (response) {
      if (response.status === 201) {
        return 1;
      } else {
        return response.error;
      }
    }
  } catch (err) {
    if (err.response.status) {
      if (
        err.response.status === 403 &&
        err.response.data.error === "invalid_token"
      ) {
        alert("Session Expired. Sign in again");
        localStorage.clear();
        window.location.href = "/";
      } else {
        toast.info(err.response.data.message);
      }
    }
  }
};

export const _asyncAddNewPlant = async (body) => {
  try {
    const response = await axiosClient.post(
      "admin/planting/bulk",
      body,
      axiosHeader()
    );
    if (response) {
      if (response.status === 201 && response.statusText === "Created") {
        toast.success("Plant added Sucessfully.");
        return 1;
      } else {
        toast.error(response.error);
        //return response.error;
      }
    }
  } catch (err) {
    if (err.response.status) {
      if (
        err.response.status === 403 &&
        err.response.data.error === "invalid_token"
      ) {
        alert("Session Expired. Sign in again");
        localStorage.clear();
        window.location.href = "/";
      } else {
        toast.info(err.response.data.error);
      }
    }
  }
};

export const _asyncAddNewProduct = async (body) => {
  try {
    const response = await axiosClient.post(
      ADD_NEW_PRODUCT,
      body,
      axiosHeader()
    );
    if (response.data.error) {
      toast.error(response.data.error);
      return;
    }
    if (response) {
      if (response.status === 201) {
        return 1;
      } else {
        return response.error;
      }
    }
  } catch (err) {
    if (err.response.status) {
      if (
        err.response.status === 403 &&
        err.response.data.error === "invalid_token"
      ) {
        alert("Session Expired. Sign in again");
        localStorage.clear();
        window.location.href = "/";
      } else {
        toast.info(err.response.data.message);
      }
    }
  }
};

export const _asyncUpdateProductGeneralInfo = async (product_code, body) => {
  try {
    const response = await axiosClient.put(
      `${UPDATE_PRODUCT}/${product_code}`,
      body,
      axiosHeader()
    );
    if (response) {
      if (
        response.status === 200 &&
        response.statusText === "OK" &&
        response.error == null
      ) {
        return 1;
      } else {
        return response.error;
      }
    }
  } catch (err) {
    if (err.response.status) {
      if (
        err.response.status === 403 &&
        err.response.data.error === "invalid_token"
      ) {
        alert("Session Expired. Sign in again");
        localStorage.clear();
        window.location.href = "/";
      } else {
        toast.info(err.response.data.message);
      }
    }
  }
};

export const _asyncCancelPO = async (id, body) => {
  try {
    const response = await axiosClient.put(
      `admin/po/cancel/${id}`,
      body,
      axiosHeader()
    );
    if (response) {
      if (
        response.status === 200 &&
        response.statusText === "OK" &&
        response.error == null
      ) {
        return 1;
      } else {
        return response.error;
      }
    }
  } catch (err) {
    if (err.response.status) {
      if (
        err.response.status === 403 &&
        err.response.data.error === "invalid_token"
      ) {
        alert("Session Expired. Sign in again");
        localStorage.clear();
        window.location.href = "/";
      } else {
        toast.info(err.response.data.message);
      }
    }
  }
};

export const _asyncEnableRAB = async (id, body) => {
  try {
    const response = await axiosClient.put(
      `admin/farm/enable_rab/${id}`,
      body,
      axiosHeader()
    );
    if (response) {
      if (
        response.status === 200 &&
        response.statusText === "OK" &&
        response.error == null
      ) {
        toast.success(
          `RAB ${body.enable_rab ? `enabled` : `disabled`} successfully`,
          {
            autoClose: 2000,
          }
        );
        return response;
      } else {
        return response.error;
      }
    }
  } catch (err) {
    if (err.response.status) {
      if (
        err.response.status === 403 &&
        err.response.data.error === "invalid_token"
      ) {
        alert("Session Expired. Sign in again");
        localStorage.clear();
        window.location.href = "/";
      } else {
        toast.info(err.response.data.message);
      }
    }
  }
};

export const _asyncPORerequest = async (id) => {
  try {
    const response = await axiosClient.put(
      `admin/po/rerequest/${id}`,
      {},
      axiosHeader()
    );
    if (response) {
      if (
        response.status === 200 &&
        response.statusText === "OK" &&
        response.error == null
      ) {
        return 1;
      } else {
        return response.error;
      }
    }
  } catch (err) {
    if (err.response.status) {
      if (
        err.response.status === 403 &&
        err.response.data.error === "invalid_token"
      ) {
        alert("Session Expired. Sign in again");
        localStorage.clear();
        window.location.href = "/";
      } else {
        toast.info(err.response.data.message);
      }
    }
  }
};

export const _asyncNewJob = async (data) => {
  try {
    const response = await axiosClient.post(
      `career/job`,
      data,
      axiosHeader()
    );
    // console.log('lklklklk----',response);
    // return response
    if (response) {
      if (response.status === 201 || response.status === 200) {

        return response?.data;
      } else {
        return response.error;
      }
    }
  } catch (err) {
    if (err.response.status) {
      if (
        err.response.status === 403 &&
        err.response.data.error === "invalid_token"
      ) {
        alert("Session Expired. Sign in again");
        localStorage.clear();
        window.location.href = "/";
      } else {
        toast.info(err.response.data.message);
      }
    }
  }
};

export const _asyncConsumablesRequest = async (data) => {
  try {
    const response = await axiosClient.post(
      `admin/consumables/request/odoo`,
      data,
      axiosHeader()
    );
    if (response) {
      if (response.status === 201 || response.status === 200) {
        return 1;
      } else {
        return response.error;
      }
    }
  } catch (err) {
    if (err.response.status) {
      if (
        err.response.status === 403 &&
        err.response.data.error === "invalid_token"
      ) {
        alert("Session Expired. Sign in again");
        localStorage.clear();
        window.location.href = "/";
      } else {
        toast.info(err.response.data.message);
      }
    }
  }
};

export const _asyncRegisterNotification = async (body) => {
  try {
    const response = await axiosClient.put(
      `admin/register_for_notification`,
      body,
      axiosHeader()
    );
    if (response) {
      if (
        response.status === 200 &&
        response.statusText === "OK" &&
        response.error == null
      ) {
        return 1;
      } else {
        return response.error;
      }
    }
  } catch (err) { }
};

export const _login = async (data, location) => {
  try {
    const res = await axiosClient.post("admin/login", data);
    if (res.status === 201) {
      toast.success("Successfully logged in");
      let user = res.data;
      localStorage.setItem("user_data", JSON.stringify(user));
      if (user?.role?.role === "IoT Engineer") {
        location("/iot-devices");
      } else if (
        user?.role?.id === 6 ||
        user?.role?.id === 5 ||
        user?.role?.id === 4
      ) {
        location("/budget-plan-rab");
      } else if (user?.role?.id === 15) {
        location("/merchandise-tracker");
      } else if (user?.role?.id === 24) {
        location("/farm-pipeline/mitra-enquiry");
      } else {
        location("/planting");
      }
    }
  } catch (err) {
    if (err.response.status) {
      if (
        err.response.status === 403 &&
        err.response.data.error === "invalid_token"
      ) {
        alert("Session Expired. Sign in again");
        localStorage.clear();
        location("/");
      } else {
        toast.info(err.response.data.message);
      }
    }
  }
};

export const _fetchMetaData = async () => {
  try {
    const res = await axiosClient.get("metadata");
    if (res.status === 200) {
      localStorage.setItem("metadata", JSON.stringify(res.data.data));
      return res.data.data;
    }
  } catch (err) {
    if (err.response.status) {
      if (
        err.response.status === 403 &&
        err.response.data.error === "invalid_token"
      ) {
        alert("Session Expired. Sign in again");
        localStorage.clear();
        window.location.href = "/";
      } else {
        toast.info(err.response.data.message);
      }
    }
  }
};

export const _apiFetchFarms = async (data, location) => {
  try {
    const config = axiosHeader();
    config.params = {
      ...data,
    };
    const res = await axiosClient.get(`admin/farms`, config);
    if (res.status === 200) {
      if (res.data.data.data.length === 0) {
        //toast.info(err.response.data.message);
      }
      return {
        displayData: res.data.data.data,
        totalPage: res.data.data.last_page,
        totalItems: res.data.data.totalItems,
      };
    }
  } catch (err) {
    if (err.response.status) {
      if (
        err.response.status === 403 &&
        err.response.data.error === "invalid_token"
      ) {
        alert("Session Expired. Sign in again");
        localStorage.clear();
        location("/");
      } else {
        toast.info(err.response.data.message);
      }
    }
  }
};

export const _apiFetchProduct = async () => {
  try {
    const res = await axiosClient.get(
      `admin/products?size=10000`,
      axiosHeader()
    );
    if (res.status === 200) {
      if (res.data.data.data.length === 0) {
        toast.info("No products found");
      }
      return res.data.data.data;
    }
  } catch (err) {
    if (err.response.status) {
      if (err.response.status === 403 && err.response.data.error === "invalid_token") {
        alert("Session Expired. Sign in again");
        localStorage.clear();
        window.location.href = "/";
      } else {
        toast.info(err.response.data.message);
      }
    }
  }
};

export const _apiGetVendors = async () => {
  let vendorList = [];
  try {
    const res = await axiosClient.get(
      `admin/unassigned-vendors`,
      axiosHeader()
    );
    if (res.status === 200) {
      if (res.data.data.length === 0) {
        toast.info("No vendor found");
      }
    }
    vendorList = res.data.data;
    return vendorList;
  } catch (err) {
    if (err.response.status) {
      if (
        err.response.status === 403 &&
        err.response.data.error === "invalid_token"
      ) {
        alert("Session Expired. Sign in again");
        localStorage.clear();
        window.location.href = "/";
      } else {
        //toast.info(err.response.data.message);
      }
    }
  }
};

export const _apiGetUnassignedWarehouses = async () => {
  let vendorList = [];
  try {
    const res = await axiosClient.get(
      `admin/unassigned-warehouses`,
      axiosHeader()
    );
    if (res.status === 200) {
      if (res.data.data.length === 0) {
        toast.info("No warehouse found");
      }
    }
    vendorList = res.data.data;
    return vendorList;
  } catch (err) {
    if (err.response.status) {
      if (
        err.response.status === 403 &&
        err.response.data.error === "invalid_token"
      ) {
        alert("Session Expired. Sign in again");
        localStorage.clear();
        window.location.href = "/";
      } else {
        //toast.info(err.response.data.message);
      }
    }
  }
};

export const _addNewFarm = async (mitra, token, data) => {
  try {
    const res = await axiosClient.post("admin/new_farm", data, {
      headers: {
        mitra,
        token,
      },
    });

    if (res.status === 201) {
      if (res.data.error) {
        toast.error(res.data.message, {
          autoClose: 2000,
        });
        return;
      }

      if (res.data) {
        toast.success("Farm Create Successfully", {
          autoClose: 2000,
        });
      }

      return res.data;
    }
  } catch (err) {
    if (err.response.status) {
      if (
        err.response.status === 403 &&
        err.response.data.error === "invalid_token"
      ) {
        alert("Session Expired. Sign in again");
        localStorage.clear();
        window.location.href = "/";
      } else {
        toast.info(err.response.data.message);
      }
    }
  }
};

export const _updateFarm = async (mitra, token, id, data) => {
  try {
    const res = await axiosClient.put(`admin/farm/${id}`, data, {
      headers: {
        mitra,
        token,
      },
    });

    if (res.status === 201) {
      if (res.data.error) {
        toast.error(res.data.message, {
          autoClose: 2000,
        });
        return;
      }

      if (res.data) {
        toast.success("Farm updated Successfully", {
          autoClose: 2000,
        });
      }

      return res.data;
    }
  } catch (err) {
    if (err.response.status) {
      if (
        err.response.status === 403 &&
        err.response.data.error === "invalid_token"
      ) {
        alert("Session Expired. Sign in again");
        localStorage.clear();
        window.location.href = "/";
      } else {
        toast.info(err.response.data.message);
      }
    }
  }
};

export const _updateDocs = async (id, data) => {
  try {
    const res = await axiosClient.put(
      `admin/farm_docs/${id}`,
      data,
      axiosHeader()
    );
    if (res.status === 200 && res.statusText === "OK") {
      //toast.success("Docs updated Successfully", {autoClose: 2000});
      return res;
    }
  } catch (err) {
    if (err.response.data.message) {
      toast.error(err.response.data.message, {
        autoClose: 2000,
      });
      return;
    }
  }
};

export const _asyncAddNewFarm = async (body) => {
  try {
    const config = axiosHeader();
    config.headers.mitra = true;
    const response = await axiosClient.post("admin/farm", body, config);
    if (response) {
      if (response.status === 201 && response.statusText === "Created") {
        toast.success("Farm Created Successfully", {
          autoClose: 2000,
        });
        return response;
      } else {
        return response.error;
      }
    }
  } catch (err) {
    if (err.response.data.message) {
      toast.error(err.response.data.message, {
        autoClose: 2000,
      });
      return;
    }
  }
};

export const _asyncUpdateFarm = async (id, body) => {
  try {
    const config = axiosHeader();
    config.headers.mitra = true;
    const response = await axiosClient.put(`admin/farm/${id}`, body, config);
    if (response) {
      if (response.status === 200) {
        toast.success("Farm Updated Successfully", {
          autoClose: 2000,
        });
        return response;
      } else {
        return response.error;
      }
    }
  } catch (err) {
    if (err.response.data.message) {
      toast.error(err.response.data.message, {
        autoClose: 2000,
      });
      return;
    }
  }
};

export const _apiFetchUsers = async (size, page, archive, checked, searchText) => {
  try {
    let url = checked ? "admin_users" : "mitra_users";
    let finalUrl = '';
    if (searchText) {
      finalUrl = `admin/${url}?size=${size}&page=${page}&archived=${archive}&user_name=${searchText}`
    } else {
      finalUrl = `admin/${url}?size=${size}&page=${page}&archived=${archive}`
    }
    const res = await axiosClient.get(
      finalUrl,
      axiosHeader()
    );
    if (res.status === 200 && res.statusText === "OK") {
      if (res.data.data.data.length === 0) {
        //toast.info("No users found");
        return {
          tableDataList: [],
          allUsersData: [],
          totalPage: [],
        };
      }
      const displayData = res.data.data.data;
      let tableDataList = displayData.map((d) => {
        d.isSelectedForArchive = false;
        return d;
      });
      let allUsersData = displayData.map((d) => {
        d.isSelectedForArchive = false;
        return d;
      });
      let totalPage = res.data.data.last_page;
      let totalItems = res.data.data.totalItems;
      return {
        tableDataList,
        allUsersData,
        totalPage,
        totalItems,
      };
    }
  } catch (err) {
    if (err.response.status) {
      if (
        err.response.status === 403 &&
        err.response.data.error === "invalid_token"
      ) {
        alert("Session Expired. Sign in again");
        localStorage.clear();
        window.location.href = "/";
      } else {
        toast.info(err.response.data.message);
      }
    }
  }
};

export const _apiLogout = async (location) => {
  try {
    await axiosClient.put(LOGOUT, "", axiosHeader());
    localStorage.clear();
    location("/");
  } catch (err) {
    localStorage.clear();
    location("/");
    console.log(err.response);
  }
};

export const _asyncGetAllProducts = async (data) => {
  try {
    const config = axiosHeader();
    config.params = {
      ...data
    };
    const res = await axiosClient.get(GET_PRODUCT, config);
    if (res.status === 200) {
      if (res.data.data.data.length === 0) {
        // toast.info("No products found");
      }
      return res.data.data.data;
    }
  } catch (err) {
    if (err.response.status) {
      if (
        err.response.status === 403 &&
        err.response.data.error === "invalid_token"
      ) {
        alert("Session Expired. Sign in again");
        localStorage.clear();
        window.location.href = "/";
      } else {
        toast.info(err.response.data.message);
      }
    }
  }
};



export const _asyncGetAllPlanting = async (
  data,
  location,
  sort_order = "ASC"
) => {
  try {
    const config = axiosHeader();
    config.params = {
      ...data,
      sort_order,
    };
    const res = await axiosClient.get(ADD_GET_PLANTING, config);
    if (res.status === 200) {
      if (res.data.data.data.length === 0) {
        //toast.info("No plant found");
      }
      return {
        displayData: res.data.data.data,
        totalPage: res.data.data.last_page,
        totalItems: res.data.data.totalItems,
      };
    }
  } catch (err) {
    if (err.response.status) {
      if (
        err.response.status === 403 &&
        err.response.data.error === "invalid_token"
      ) {
        alert("Session Expired. Sign in again");
        localStorage.clear();
        window.location.href = "/";
      } else {
        toast.info(err.response.data.message);
      }
    }
  }
};

export const _asyncGetAllCropTraker = async (
  data,
  location,
  sort_order = "ASC"
) => {
  try {
    const config = axiosHeader();
    config.params = {
      ...data,
      sort_order,
    };
    const res = await axiosClient.get(ADD_GET_CROP_TRACKER, config);
    if (res.status === 200) {
      if (res.data.data.length === 0) {
        //toast.info("No crops tracker found");
      }
      return {
        displayData: res.data.data,
        totalPage: res.data.last_page,
        totalItems: res.data.totalItems,
      };
    }
  } catch (err) {
    if (err.response.status) {
      if (
        err.response.status === 403 &&
        err.response.data.error === "invalid_token"
      ) {
        alert("Session Expired. Sign in again");
        localStorage.clear();
        location("/");
      } else {
        toast.info(err.response.data.message);
      }
    }
  }
};

export const _asyncGetAllPO = async (data, location, sort_order = "ASC") => {
  try {
    const config = axiosHeader();
    config.params = {
      ...data,
      sort_order,
    };
    const res = await axiosClient.get("admin/po", config);
    if (res.status === 200) {
      if (res.data.data.length === 0) {
        //toast.info("No crops tracker found");
      }
      return {
        displayData: res.data.data,
        totalPage: res.data.last_page,
        totalItems: res.data.totalItems,
      };
    }
  } catch (err) {
    if (err.response.status) {
      if (
        err.response.status === 403 &&
        err.response.data.error === "invalid_token"
      ) {
        alert("Session Expired. Sign in again");
        localStorage.clear();
        location("/");
      } else {
        toast.info(err.response.data.message);
      }
    }
  }
};

export const _asyncGetAllConsumablesRequests = async (
  data,
  location,
  sort_order = "ASC"
) => {
  try {
    const config = axiosHeader();
    config.params = {
      ...data,
      sort_order,
    };
    const res = await axiosClient.get("admin/consumables/requests", config);
    if (res.status === 200) {
      if (res.data.data.data.length === 0) {
        //toast.info("No crops tracker found");
      }
      return {
        displayData: res.data.data.data,
        totalPage: res.data.data.last_page,
        totalItems: res.data.data.totalItems,
      };
    }
  } catch (err) {
    if (err.response.status) {
      if (
        err.response.status === 403 &&
        err.response.data.error === "invalid_token"
      ) {
        alert("Session Expired. Sign in again");
        localStorage.clear();
        location("/");
      } else {
        toast.info(err.response.data.message);
      }
    }
  }
};

export const _asyncGetAllConsumablesInventory = async (
  data,
  location,
  sort_order = "ASC"
) => {
  try {
    const config = axiosHeader();
    config.params = {
      ...data,
      sort_order,
    };
    const res = await axiosClient.get("admin/consumables/inventory", config);
    if (res.status === 200) {
      if (res.data.data.data.length === 0) {
        //toast.info("No crops tracker found");
      }
      return {
        displayData: res.data.data.data,
        totalPage: res.data.data.last_page,
        totalItems: res.data.data.totalItems,
      };
    }
  } catch (err) {
    if (err.response.status) {
      if (
        err.response.status === 403 &&
        err.response.data.error === "invalid_token"
      ) {
        alert("Session Expired. Sign in again");
        localStorage.clear();
        location("/");
      } else {
        toast.info(err.response.data.message);
      }
    }
  }
};

export const _asyncGetAllBill = async (data, location, sort_order = "ASC") => {
  try {
    const config = axiosHeader();
    config.params = {
      ...data,
      sort_order,
    };
    const res = await axiosClient.get("admin/po/bills", config);
    if (res.status === 200) {
      if (res.data.data.length === 0) {
        //toast.info("No crops tracker found");
      }
      return {
        displayData: res.data.data,
        totalPage: res.data.last_page,
        totalItems: res.data.totalItems,
      };
    }
  } catch (err) {
    if (err.response.status) {
      if (
        err.response.status === 403 &&
        err.response.data.error === "invalid_token"
      ) {
        alert("Session Expired. Sign in again");
        localStorage.clear();
        location("/");
      } else {
        toast.info(err.response.data.message);
      }
    }
  }
};

export const _asyncGetAllCropHarvested = async (
  data,
  location,
  sort_order = "ASC"
) => {
  try {
    const config = axiosHeader();
    config.params = {
      ...data,
      sort_order,
    };
    const res = await axiosClient.get("admin/crops/harvested", config);
    if (res.status === 200) {
      if (res.data.data.length === 0) {
        //toast.info("No crops tracker found");
      }
      return {
        displayData: res.data.data,
        totalPage: res.data.last_page,
        totalItems: res.data.totalItems,
      };
    }
  } catch (err) {
    if (err.response.status) {
      if (
        err.response.status === 403 &&
        err.response.data.error === "invalid_token"
      ) {
        alert("Session Expired. Sign in again");
        localStorage.clear();
        location("/");
      } else {
        toast.info(err.response.data.message);
      }
    }
  }
};
export const _asyncGetAllInternalTransferHarvested = async (
  data,
  location,
  sort_order = "ASC"
) => {
  try {
    const config = axiosHeader();
    config.params = {
      ...data,
      sort_order,
    };
    const res = await axiosClient.get(
      `admin/internal-transfer/crops/harvested?`,
      config
    );
    if (res.status === 200) {
      if (res.data.data.length === 0) {
        //toast.info("No crops tracker found");
      }
      return {
        displayData: res.data.data,
        totalPage: res.data.last_page,
        totalItems: res.data.totalItems,
      };
    }
  } catch (err) {
    if (err.response.status) {
      if (
        err.response.status === 403 &&
        err.response.data.error === "invalid_token"
      ) {
        alert("Session Expired. Sign in again");
        localStorage.clear();
        location("/");
      } else {
        toast.info(err.response.data.message);
      }
    }
  }
};
export const _asyncGetAllInternalTransferStatus = async (
  data,
  location,
  sort_order = "ASC"
) => {
  try {
    const config = axiosHeader();
    config.params = {
      ...data,
      sort_order,
    };
    const res = await axiosClient.get(`admin/internal-transfer`, config);
    if (res.status === 200) {
      if (res.data.data.length === 0) {
        //toast.info("No crops tracker found");
      }
      return {
        displayData: res.data.data,
        totalPage: res.data.last_page,
        totalItems: res.data.totalItems,
      };
    }
  } catch (err) {
    if (err.response.status) {
      if (
        err.response.status === 403 &&
        err.response.data.error === "invalid_token"
      ) {
        alert("Session Expired. Sign in again");
        localStorage.clear();
        location("/");
      } else {
        toast.info(err.response.data.message);
      }
    }
  }
};

export const _asyncGetAllDosingMachine = async () => {
  try {
    const config = axiosHeader();
    const res = await axiosClient.get(GET_DOSING_MACHINE, config);
    if (res.status === 200) {
      if (res.data.data.data.length === 0) {
        //toast.info("No dosing found");
      }
      return {
        displayData: res.data.data.data,
        totalPage: res.data.data.last_page,
        totalItems: res.data.data.totalItems,
      };
    }
  } catch (err) {
    if (err.response.status) {
      if (
        err.response.status === 403 &&
        err.response.data.error === "invalid_token"
      ) {
        alert("Session Expired. Sign in again");
        localStorage.clear();
        window.location.href = "/";
      } else {
        toast.info(err.response.data.message);
      }
    }
  }
};

export const _asyncGetDosingMachineAlarms = async (id) => {
  try {
    const config = axiosHeader();
    config.params = {
      page: 1,
      size: 50,
    };
    const res = await axiosClient.get(
      `admin/dosing_machine_alarms/${id}`,
      config
    );
    if (res.status === 200) {
      if (res.data?.data?.data?.length === 0) {
        //toast.info("No dosing alarm found");
      }
      return res?.data?.data?.data;
    }
  } catch (err) {
    if (err.response.status) {
      if (
        err.response.status === 403 &&
        err.response.data.error === "invalid_token"
      ) {
        alert("Session Expired. Sign in again");
        localStorage.clear();
        window.location.href = "/";
      } else {
        toast.info(err.response.data.message);
      }
    }
  }
};

export const _asyncGetDosingMachineScheduler = async (id, params) => {
  try {
    const config = axiosHeader();
    config.params = {
      ...params,
    };
    const res = await axiosClient.get(
      `admin/dosing_machine_schedules/${id}`,
      config
    );
    if (res.status === 200) {
      if (res.data?.data?.length === 0) {
        toast.info("No dosing machine scheduler found");
      }
      return res.data.data;
    }
  } catch (err) {
    if (err.response.status) {
      if (
        err.response.status === 403 &&
        err.response.data.error === "invalid_token"
      ) {
        alert("Session Expired. Sign in again");
        localStorage.clear();
        window.location.href = "/";
      } else {
        toast.info(err.response.data.message);
      }
    }
  }
};

export const _asyncGetDosingMachineReading = async (id, data) => {
  console.log(id, "id==>");
  try {
    const config = axiosHeader();
    config.params = {
      ...data,
    };
    const res = await axiosClient.get(
      `admin/dosing_machine_readings/${id}`,
      config
    );
    if (res.status === 200) {
      if (!res.data) {
        toast.info("No dosing found");
      }
      return res.data;
    }
  } catch (err) {
    if (err.response.status) {
      if (
        err.response.status === 403 &&
        err.response.data.error === "invalid_token"
      ) {
        alert("Session Expired. Sign in again");
        localStorage.clear();
        window.location.href = "/";
      } else {
        toast.info(err.response.data.message);
      }
    }
  }
};

export const _assignDMtoFarm = async (id, data, bol) => {
  try {
    const res = await axiosClient.put(
      `admin/${bol ? "assign_dosing_machine" : "unassign_dosing_machine"
      }/${id}`,
      data,
      axiosHeader()
    );
    if (res.status === 200) {
      if (res.data.error) {
        toast.error(res.data.message, {
          autoClose: 2000,
        });
        return;
      }
      if (res.data) {
        toast.success(
          `Dosing machine succesfully ${bol ? "assingned" : "un assigned"}`,
          {
            autoClose: 2000,
          }
        );
      }
      return res.data;
    }
  } catch (err) {
    if (err.response.data.message) {
      toast.error(err.response.data.message, {
        autoClose: 2000,
      });
      return;
    }
  }
};

export const _asyncGetAllFarms = async (facility_needed = null) => {
  try {
    const newUrl = facility_needed ? GET_FARMS + facility_needed : GET_FARMS;
    const res = await axiosClient.get(newUrl, axiosHeader());
    if (res.status === 200) {
      if (res.data.data.data.length === 0) {
        //toast.info("No farms found");
      }
      return res.data.data.data;
    }
  } catch (err) {
    if (err.response.status) {
      if (
        err.response.status === 403 &&
        err.response.data.error === "invalid_token"
      ) {
        alert("Session Expired. Sign in again");
        localStorage.clear();
        window.location.href = "/";
      } else {
        toast.info(err.response.data.message);
      }
    }
  }
};

export const _asyncGetAllSKU = async (size = 10000) => {
  try {
    const res = await axiosClient.get(`admin/product_skus?page=1&size=${size}`, axiosHeader());
    if (res.status === 200) {
      if (res.data.data.length === 0) {
        toast.info("No SKU found");
      }
      return res.data.data;
    }
  } catch (err) {
    if (err.response.status) {
      if (err.response.status === 403 && err.response.data.error === "invalid_token") {
        alert("Session Expired. Sign in again");
        localStorage.clear();
        window.location.href = "/";
      } else {
        toast.info(err.response.data.message);
      }
    }
  }
};

export const _asyncGetFaasPics = async (data = null) => {
  try {
    const config = axiosHeader();
    config.params = {
      ...data,
    };
    const res = await axiosClient.get("admin/faas_pics", config);
    if (res.status === 200) {
      if (res.data.data.length === 0) {
        toast.info("No faas pics found");
      }
      return res.data.data;
    }
  } catch (err) {
    if (err.response.status) {
      if (
        err.response.status === 403 &&
        err.response.data.error === "invalid_token"
      ) {
        alert("Session Expired. Sign in again");
        localStorage.clear();
        window.location.href = "/";
      } else {
        //toast.info(err.response.data.message);
      }
    }
  }
};

export const _asyncGetRnDPics = async (data = null) => {
  try {
    const config = axiosHeader();
    config.params = {
      ...data,
    };
    const res = await axiosClient.get("admin/rnd_pics", config);
    if (res.status === 200) {
      if (res.data.data.length === 0) {
        toast.info("No faas pics found");
      }
      return res.data.data;
    }
  } catch (err) {
    if (err.response.status) {
      if (
        err.response.status === 403 &&
        err.response.data.error === "invalid_token"
      ) {
        alert("Session Expired. Sign in again");
        localStorage.clear();
        window.location.href = "/";
      } else {
        //toast.info(err.response.data.message);
      }
    }
  }
};

export const _asyncGetMitrasByFarm = async (id) => {
  try {
    const res = await axiosClient.get(GET_MITRAS + id, axiosHeader());
    if (res.status === 200) {
      if (!res.data.data) {
        toast.info("No Mitra found");
      }
      return res.data.data;
    }
  } catch (err) {
    if (err.response.status) {
      if (
        err.response.status === 403 &&
        err.response.data.error === "invalid_token"
      ) {
        alert("Session Expired. Sign in again");
        localStorage.clear();
        window.location.href = "/";
      } else {
        toast.info(err.response.data.message);
      }
    }
  }
};

export const _asyncGetPCPSIC = async (data) => {
  try {
    const config = axiosHeader();
    config.params = {
      ...data,
    };
    console.log("data params---", data);
    const res = await axiosClient.get("admin/products/pc_psic", config);
    if (res.status === 200) {
      if (!res.data.data) {
        toast.info("No pc psic found");
      }
      return res.data.data;
    }
  } catch (err) {
    if (err.response.status) {
      if (
        err.response.status === 403 &&
        err.response.data.error === "invalid_token"
      ) {
        alert("Session Expired. Sign in again");
        localStorage.clear();
        window.location.href = "/";
      } else {
        toast.info(err.response.data.message);
      }
    }
  }
};

export const _asyncGetMitras = async () => {
  try {
    const res = await axiosClient.get(
      "admin/mitra_users?size=1000&page=1&archived=false",
      axiosHeader()
    );
    if (res.status === 200) {
      if (!res.data.data.data) {
        toast.info("No Mitra found");
      }
      return res.data.data.data;
    }
  } catch (err) {
    if (err.response.status) {
      if (
        err.response.status === 403 &&
        err.response.data.error === "invalid_token"
      ) {
        alert("Session Expired. Sign in again");
        localStorage.clear();
        window.location.href = "/";
      } else {
        toast.info(err.response.data.message);
      }
    }
  }
};

export const _asyncGetUserRoles = async () => {
  try {
    const res = await axiosClient.get("admin/user/roles", axiosHeader());
    if (res.status === 200) {
      if (!res.data) {
        toast.info("No user role found");
      }
      return res.data;
    }
  } catch (err) {
    if (err.response.status) {
      if (
        err.response.status === 403 &&
        err.response.data.error === "invalid_token"
      ) {
        alert("Session Expired. Sign in again");
        localStorage.clear();
        window.location.href = "/";
      } else {
        toast.info(err.response.data.message);
      }
    }
  }
};

export const _asyncGetWarehouses = async () => {
  try {
    const res = await axiosClient.get("admin/po/warehouses", axiosHeader());
    if (res.status === 200) {
      if (!res.data.data) {
        toast.info("No warehouses found");
      }
      return res.data.data;
    }
  } catch (err) {
    if (err.response.status) {
      if (
        err.response.status === 403 &&
        err.response.data.error === "invalid_token"
      ) {
        alert("Session Expired. Sign in again");
        localStorage.clear();
        window.location.href = "/";
      } else {
        toast.info(err.response.data.message);
      }
    }
  }
};

export const _asyncSubmitInternalTransfer = async (body) => {
  try {
    const config = axiosHeader();
    const res = await axiosClient.post(
      "admin/internal-transfer/submit",
      body,
      config
    );
    if (res.status === 201 || res.status === 200) {
      if (!res.data) {
        toast.info("No data found");
      }
      return res.data;
    }
  } catch (err) {
    if (err.response.status) {
      if (
        err.response.status === 403 &&
        err.response.data.error === "invalid_token"
      ) {
        alert("Session Expired. Sign in again");
        localStorage.clear();
        window.location.href = "/";
      } else {
        toast.info(err.response.data.message);
      }
    }
  }
};

export const _asyncGetAllContact = async () => {
  try {
    const res = await axiosClient.get(
      "admin/internal-transfer/contacts",
      axiosHeader()
    );
    if (res.status === 200) {
      if (!res.data.data) {
        toast.info("No contacts found");
      }
      return res.data.data;
    }
  } catch (err) {
    if (err.response.status) {
      if (
        err.response.status === 403 &&
        err.response.data.error === "invalid_token"
      ) {
        alert("Session Expired. Sign in again");
        localStorage.clear();
        window.location.href = "/";
      } else {
        toast.info(err.response.data.message);
      }
    }
  }
};
export const _asyncGetAllProductList = async () => {
  try {
    const res = await axiosClient.get(
      "admin/product_skus?size=2000",
      axiosHeader()
    );
    if (res.status === 200) {
      if (!res.data.data) {
        toast.info("No products found");
      }
      return res.data.data;
    }
  } catch (err) {
    if (err.response.status) {
      if (
        err.response.status === 403 &&
        err.response.data.error === "invalid_token"
      ) {
        alert("Session Expired. Sign in again");
        localStorage.clear();
        window.location.href = "/";
      } else {
        toast.info(err.response.data.message);
      }
    }
  }
};
export const _asyncGetAllFarmInfo = async (farm_id) => {
  try {
    const res = await axiosClient.get(
      `admin/internal-transfer/farm_info/${farm_id}`,
      axiosHeader()
    );
    if (res.status === 200) {
      if (!res.data) {
        toast.info("No farms found");
      }
      return res.data;
    }
  } catch (err) {
    if (err.response.status) {
      if (
        err.response.status === 403 &&
        err.response.data.error === "invalid_token"
      ) {
        alert("Session Expired. Sign in again");
        localStorage.clear();
        window.location.href = "/";
      } else {
        toast.info(err.response.data.message);
      }
    }
  }
};

export const _asyncGetAllHarvest = async (
  data,
  location,
  sort_order = "ASC"
) => {
  try {
    const config = axiosHeader();
    config.params = {
      ...data,
      sort_order,
    };
    const res = await axiosClient.get(GET_HARVEST, config);
    if (res.status === 200) {
      if (res.data.data.data.length === 0) {
        toast.info("No harvest found");
      }
      return {
        displayData: res.data.data.data,
        totalPage: res.data.data.last_page,
        totalItems: res.data.data.totalItems,
      };
    }
  } catch (err) {
    if (err.response.status) {
      if (
        err.response.status === 403 &&
        err.response.data.error === "invalid_token"
      ) {
        alert("Session Expired. Sign in again");
        localStorage.clear();
        window.location.href = "/";
      } else {
        toast.info(err.response.data.message);
      }
    }
  }
};

export const _asyncGetDashboardData = async (data) => {
  try {
    const config = axiosHeader();
    config.params = {
      ...data,
    };
    const res = await axiosClient.get("admin/planting/dashboard", config);
    if (res.status === 200) {
      if (!res.data) {
        toast.info("No data found");
      }
      return res.data;
    }
  } catch (err) {
    if (err.response.status) {
      if (
        err.response.status === 403 &&
        err.response.data.error === "invalid_token"
      ) {
        alert("Session Expired. Sign in again");
        localStorage.clear();
        window.location.href = "/";
      } else {
        toast.info(err.response.data.message);
      }
    }
  }
};

export const _asyncGetFarmDocsData = async (id) => {
  try {
    const res = await axiosClient.get(`/admin/farm_docs/${id}`, axiosHeader());
    if (res.status === 200) {
      if (!res.data) {
        toast.info("No data found");
      }
      return res.data;
    }
  } catch (err) {
    if (err.response.status) {
      if (
        err.response.status === 403 &&
        err.response.data.error === "invalid_token"
      ) {
        alert("Session Expired. Sign in again");
        localStorage.clear();
        window.location.href = "/";
      } else {
        toast.info(err.response.data.message);
      }
    }
  }
};

export const _asyncGetDocDownload = async (id) => {
  try {
    const res = await axiosClient.get(
      `/admin/farm_doc/download/${id}`,
      axiosHeader()
    );
    if (res.status === 200) {
      if (!res.data) {
        toast.info("No data found");
      }
      return res;
    }
  } catch (err) {
    if (err.response.status) {
      if (
        err.response.status === 403 &&
        err.response.data.error === "invalid_token"
      ) {
        alert("Session Expired. Sign in again");
        localStorage.clear();
        window.location.href = "/";
      } else {
        toast.info(err.response.data.message);
      }
    }
  }
};

export const _asyncGetPaymentProofDownload = async (id) => {
  try {
    const res = await axiosClient.get(
      `/admin/consumables/download/payment_proof/${id}`,
      axiosHeader()
    );
    if (res.status === 200) {
      if (!res.data) {
        toast.info("No data found");
      }
      return res;
    }
  } catch (err) {
    if (err.response.status) {
      if (
        err.response.status === 403 &&
        err.response.data.error === "invalid_token"
      ) {
        alert("Session Expired. Sign in again");
        localStorage.clear();
        window.location.href = "/";
      } else {
        toast.info(err.response.data.message);
      }
    }
  }
};

export const _asyncAddNewRAB = async (body) => {
  try {
    const response = await axiosClient.post("admin/rab", body, axiosHeader());
    if (response) {
      if (response.status === 201 && response.statusText === "Created") {
        toast.success("RAB Created Successfully", {
          autoClose: 2000,
        });
        return response;
      } else {
        return response.error;
      }
    }
  } catch (err) {
    if (err.response.data.message) {
      toast.error(err.response.data.message, {
        autoClose: 2000,
      });
      return;
    }
  }
};

export const _asyncRABCalculationPreview = async (body, isInternal = false) => {
  try {
    const url = isInternal
      ? "internal_rab_calculation_preview"
      : "rab_calculation_preview";
    const response = await axiosClient.post(
      `admin/${url}`,
      body,
      axiosHeader()
    );
    if (response) {
      if (response.status === 201 && response.statusText === "Created") {
        return response.data;
      } else {
        return response.error;
      }
    }
  } catch (err) {
    if (err.response.status) {
      if (
        err.response.status === 403 &&
        err.response.data.error === "invalid_token"
      ) {
        alert("Session Expired. Sign in again");
        localStorage.clear();
        window.location.href = "/";
      } else {
        toast.info(err.response.data.message);
      }
    }
  }
};

export const _asyncGetAllRab = async (data, sort_order = "DESC") => {
  try {
    const config = axiosHeader();
    config.params = {
      ...data,
      sort_order,
    };
    const res = await axiosClient.get("admin/rab/list", config);
    if (res.status === 200) {
      if (res.data.data.length === 0) {
        toast.info("No RAB found");
      }
      return {
        displayData: res.data.data,
        totalPage: res.data.last_page,
        totalItems: res.data.totalItems,
      };
    }
  } catch (err) {
    if (err.response.status) {
      if (
        err.response.status === 403 &&
        err.response.data.error === "invalid_token"
      ) {
        alert("Session Expired. Sign in again");
        localStorage.clear();
        window.location.href = "/";
      } else {
        toast.info(err.response.data.message);
      }
    }
  }
};

export const _asyncGetAllRabForPlanting = async (data, sort_order = "ASC") => {
  try {
    const config = axiosHeader();
    config.params = {
      ...data,
      sort_order,
    };
    const res = await axiosClient.get("admin/rabs-to-link", config);
    if (res.status === 200) {
      if (res.data.data.length === 0) {
        toast.info("No RAB found");
      }
      return {
        displayData: res.data.data,
        totalPage: res.data.last_page,
        totalItems: res.data.totalItems,
      };
    }
  } catch (err) {
    if (err.response.status) {
      if (
        err.response.status === 403 &&
        err.response.data.error === "invalid_token"
      ) {
        alert("Session Expired. Sign in again");
        localStorage.clear();
        window.location.href = "/";
      } else {
        toast.info(err.response.data.message);
      }
    }
  }
};

export const _asyncGetRabLogs = async (id) => {
  try {
    const res = await axiosClient.get("admin/rab_logs/" + id, axiosHeader());
    if (res.status === 200) {
      if (!res.data.data) {
        toast.info("No RAB Logs found");
      }
      return res.data.data;
    }
  } catch (err) {
    if (err.response.status) {
      if (
        err.response.status === 403 &&
        err.response.data.error === "invalid_token"
      ) {
        alert("Session Expired. Sign in again");
        localStorage.clear();
        window.location.href = "/";
      } else {
        toast.info(err.response.data.message);
      }
    }
  }
};


export const _asyncGetUserSubordinates = async (id) => {
  try {
    const res = await axiosClient.get(
      "admin/user_subordinates/" + id,
      axiosHeader()
    );
    if (res.status === 200) {
      if (!res.data) {
        //toast.info("No user subordinates found");
      }
      return res.data;
    }
  } catch (err) {
    if (err.response.status) {
      if (
        err.response.status === 403 &&
        err.response.data.error === "invalid_token"
      ) {
        alert("Session Expired. Sign in again");
        localStorage.clear();
        window.location.href = "/";
      } else {
        //toast.info(err.response.data.message);
      }
    }
  }
};

export const _asyncGetRabDetails = async (id) => {
  try {
    const res = await axiosClient.get("admin/rab/" + id, axiosHeader());
    if (res.status === 200) {
      if (!res.data.data) {
        toast.info("No RAB found");
      }
      return res.data;
    }
  } catch (err) {
    if (err.response.status) {
      if (
        err.response.status === 403 &&
        err.response.data.error === "invalid_token"
      ) {
        alert("Session Expired. Sign in again");
        localStorage.clear();
        window.location.href = "/";
      } else {
        toast.info(err.response.data.message);
      }
    }
  }
};

export const _asyncPlantingReportDetails = async (id) => {
  try {
    const res = await axiosClient.get(
      "admin/planting_report/" + id,
      axiosHeader()
    );
    if (res.status === 200) {
      if (!res.data.data.data) {
        toast.info("No planting report found");
      }
      return res.data.data.data;
    }
  } catch (err) {
    if (err.response.status) {
      if (
        err.response.status === 403 &&
        err.response.data.error === "invalid_token"
      ) {
        alert("Session Expired. Sign in again");
        localStorage.clear();
        window.location.href = "/";
      } else {
        toast.info(err.response.data.message);
      }
    }
  }
};

export const _asyncPlantingLogs = async (id) => {
  try {
    //let id = 'de9e8428-a63e-4d2f-8e63-d4cc67809cf5';
    const res = await axiosClient.get(
      "admin/planting_logs/" + id,
      axiosHeader()
    );
    if (res.status === 200) {
      if (!res.data.data) {
        toast.info("No planting logs found");
      }
      return res.data.data;
    }
  } catch (err) {
    if (err.response.data.message) {
      toast.error(err.response.data.message, {
        autoClose: 2000,
      });
      return;
    }
  }
};

export const _asyncPlantingDetails = async (id) => {
  try {
    //let id = 'de9e8428-a63e-4d2f-8e63-d4cc67809cf5';
    const res = await axiosClient.get(
      `admin/planting_details/${id}`,
      axiosHeader()
    );
    return res
  } catch (err) {
    if (err.response.data.message) {
      toast.error(err.response.data.message, {
        autoClose: 2000,
      });
      return;
    }
  }
};


export const _asyncConsumablesLogs = async (id) => {
  try {
    //let id = 'de9e8428-a63e-4d2f-8e63-d4cc67809cf5';
    const res = await axiosClient.get(
      "admin/consumables/request/logs/" + id,
      axiosHeader()
    );
    if (res.status === 200) {
      if (!res.data.data) {
        toast.info("No planting logs found");
      }
      return res.data.data;
    }
  } catch (err) {
    if (err.response.data.message) {
      toast.error(err.response.data.message, {
        autoClose: 2000,
      });
      return;
    }
  }
};

export const _updateRabDocs = async (id, data) => {
  try {
    const res = await axiosClient.put(
      `admin/rab_doc/${id}`,
      data,
      axiosHeader()
    );
    if (res.status === 200 && res.statusText === "OK") {
      //toast.success("Docs updated Successfully", {autoClose: 2000});
      return res;
    }
  } catch (err) {
    if (err.response.data.message) {
      toast.error(err.response.data.message, {
        autoClose: 2000,
      });
      return;
    }
  }
};

export const _updateRabStatus = async (id, status, data) => {
  try {
    const res = await axiosClient.put(
      `admin/rab_state/${id}/${status}`,
      data,
      axiosHeader()
    );
    if (res.status === 200 && res.statusText === "OK") {
      toast.success(res.data.message, {
        autoClose: 2000,
      });
      return res;
    }
  } catch (err) {
    if (err.response.data.message) {
      toast.error(err.response.data.message, {
        autoClose: 2000,
      });
      return;
    }
  }
};

export const _updateRabRate = async (id, data) => {
  try {
    const res = await axiosClient.put(
      `admin/rab/rate/${id}`,
      data,
      axiosHeader()
    );
    if (res.status === 200 && res.statusText === "OK") {
      //toast.success(res.data.message, {autoClose: 2000});
      return res;
    }
  } catch (err) {
    if (err.response.data.message) {
      toast.error(err.response.data.message, {
        autoClose: 2000,
      });
      return;
    }
  }
};

export const _updateUserDetails = async (id, data, checkBeleafe) => {
  try {
    let url = checkBeleafe ? "admin_user" : "mitra_user";
    const res = await axiosClient.put(
      `admin/${url}/${id}`,
      data,
      axiosHeader()
    );
    if (res.status === 200 && res.statusText === "OK" && res.error == null) {
      return 1;
    }
  } catch (err) {
    if (err.response.data.message) {
      toast.error(err.response.data.message, {
        autoClose: 2000,
      });
      return;
    }
  }
};

export const _updateRabData = async (id, data) => {
  try {
    const res = await axiosClient.put(`admin/rab/${id}`, data, axiosHeader());
    if (res.status === 200 && res.statusText === "OK") {
      //toast.success(res.data.message, {autoClose: 2000});
      return res;
    }
  } catch (err) {
    if (err.response.data.message) {
      toast.error(err.response.data.message, {
        autoClose: 2000,
      });
      return;
    }
  }
};

export const _updateInspectionInfo = async (data) => {
  try {
    const response = await axiosClient.put(
      `admin/po/inspection_info`,
      data,
      axiosHeader()
    );
    if (response) {
      if (
        response.status === 200 &&
        response.statusText === "OK" &&
        response.error == null
      ) {
        return 1;
      } else {
        return response.error;
      }
    }
  } catch (err) {
    if (err.response.status) {
      if (
        err.response.status === 403 &&
        err.response.data.error === "invalid_token"
      ) {
        alert("Session Expired. Sign in again");
        localStorage.clear();
        window.location.href = "/";
      } else {
        toast.info(err.response.data.message);
      }
    }
  }
};

export const _asyncGetRabDocDownload = async (id) => {
  try {
    const res = await axiosClient.get(
      `/admin/rab_doc/download/${id}`,
      axiosHeader()
    );
    if (res.status === 200) {
      if (!res.data) {
        toast.info("No data found");
      }
      return res;
    }
  } catch (err) {
    //(err.response);
  }
};

export const _asyncGetBOMProducts = async (product_code) => {
  try {
    const res = await axiosClient.get(
      `/admin/product/boms?product_code=${product_code}`,
      axiosHeader()
    );
    if (res.status === 200) {
      if (!res.data) {
        toast.info("No data found");
      }
      return res?.data;
    }
  } catch (err) {
    //(err.response);
  }
};

//1
export const _getYearsforWeeklyReport = async () => {
  try {
    const res = await axiosClient.get(
      `admin/weekly-report/years`,
      axiosHeader()
    );
    if (res.status === 200) {
      if (!res.data) {
        toast.info("No data found");
      }
      // console.log(res?.data)
      return res?.data;
    }
  } catch (err) {
    //(err.response);
  }
};

//2
export const _getWeeksforWeeklyReport = async (year) => {
  try {
    const res = await axiosClient.get(
      `admin/weekly-report/weeks/${year}`,
      axiosHeader()
    );
    if (res.status === 200) {
      if (!res.data) {
        toast.info("No data found");
      }
      // console.log(res?.data)
      return res?.data;
    }
  } catch (err) {
    //(err.response);
  }
};

//3 farmwise + cropwise + unsubmitted
export const _getFarmWeeklyReportFarmWise = async (year, week, data) => {
  try {
    const config = axiosHeader();
    config.params = {
      ...data
    };
    const res = await axiosClient.get(
      `admin/weekly-report/farm-wise/${year}/${week}`,
      config
    );
    if (res.status === 200) {
      if (!res.data) {
        toast.info("No data found");
      }
      return {
        displayData: res.data.data,
        totalPage: res.data.last_page,
        totalItems: res.data.totalItems,
      };
    }
  } catch (err) {
    //(err.response);
  }
};

//4
export const _getFarmWiseWeeklyReportAnalyticData = async (year, week) => {
  try {
    const res = await axiosClient.get(
      `admin/weekly-report/farm-wise/analysis_data/${year}/${week}`,
      axiosHeader()
    );
    if (res.status === 200) {
      if (!res.data) {
        toast.info("No data found");
      }
      // console.log(res?.data)
      return res?.data;
    }
  } catch (err) {
    //(err.response);
  }
};

export const _getUnsubmittedFarmWiseWeeklyReport = async (year, week) => {
  try {
    const res = await axiosClient.get(
      `admin/weekly-report/farms/unsubmitted/${year}/${week}`,
      axiosHeader()
    );
    if (res.status === 200) {
      if (!res.data) {
        toast.info("No data found");
      }
      return {
        displayData: res.data.data,
        totalPage: res.data.last_page,
        totalItems: res.data.totalItems,
      };
    }
  } catch (err) {
    //(err.response);
  }
};
export const _getCropWiseWeeklyReport = async (id, year, week, data) => {
  try {
    const config = axiosHeader();
    config.params = {
      ...data
    };
    const res = await axiosClient.get(
      `admin/weekly-report/crop-wise/${id}/${year}/${week}`,
      config
    );
    if (res.status === 200) {
      if (!res.data) {
        toast.info("No data found");
      }
      return {
        displayData: res.data.farm_data,
        productData: res.data.product_data,
        totalPage: res.data.last_page,
        totalItems: res.data.totalItems,
      };
    }
  } catch (err) {
    //(err.response);
  }
};

export const _getCropWiseUnsubmittedWeeklyReport = async (id, year, week) => {
  try {
    const res = await axiosClient.get(
      `admin/weekly-report/crop-wise/farms_info/${id}/${year}/${week}`,
      axiosHeader()
    );
    if (res.status === 200) {
      if (!res.data) {
        toast.info("No data found");
      }
      console.log("crop wise data aaya kya ????", res?.data)
      return res?.data;
    }
  } catch (err) {
    //(err.response);
  }
};

export const _getCropWiseWeeklyReportAnalyticData = async (id, year, week) => {
  try {
    const res = await axiosClient.get(
      `admin/weekly-report/crop-wise/analysis_data/${id}/${year}/${week}`,
      axiosHeader()
    );
    if (res.status === 200) {
      if (!res.data) {
        toast.info("No data found");
      }
      console.log("crop wise data aaya kya ????", res?.data)
      return res?.data;
    }
  } catch (err) {
    //(err.response);
  }
};

export const _getListofProductsForAWeek = async (year, week) => {
  try {
    const res = await axiosClient.get(
      `admin/weekly-report/reported_crops/${year}/${week}`,
      axiosHeader()
    );
    if (res.status === 200) {
      if (!res.data) {
        toast.info("No data found");
      }
      // console.log(res?.data)
      return res?.data;
    }
  } catch (err) {
    //(err.response);
  }
};

export const _asyncGetSuperMarkets = async (data) => {
  try {
    const config = axiosHeader();
    config.params = {
      ...data
    };
    const res = await axiosClient.get("admin/merchandiser/super_markets", config);
    if (res.status === 200) {
      if (!res.data) {
        //toast.info("No user subordinates found");
      }
      return {
        displayData: res.data.data,
        totalPage: res.data.last_page,
        totalItems: res.data.totalItems,
      };
    }
  } catch (err) {
    if (err.response.status) {
      if (
        err.response.status === 403 &&
        err.response.data.error === "invalid_token"
      ) {
        alert("Session Expired. Sign in again");
        localStorage.clear();
        window.location.href = "/";
      } else {
        //toast.info(err.response.data.message);
      }
    }
  }
};

export const _asyncGetMerchandiserProducts = async (id, data) => {
  try {
    const config = axiosHeader();
    config.params = {
      ...data
    };
    const res = await axiosClient.get(`admin/merchandiser/products/${id}`, config);
    if (res.status === 200) {
      if (!res.data) {
        //toast.info("No user subordinates found");
      }
      return res.data;
    }
  } catch (err) {
    if (err.response.status) {
      if (
        err.response.status === 403 &&
        err.response.data.error === "invalid_token"
      ) {
        alert("Session Expired. Sign in again");
        localStorage.clear();
        window.location.href = "/";
      } else {
        //toast.info(err.response.data.message);
      }
    }
  }
};

export const _asyncGetMerchandiserLogs = async (id) => {
  try {
    const res = await axiosClient.get("admin/merchandiser/logs/" + id, axiosHeader());
    if (res.status === 200) {
      if (!res.data.data) {
        toast.info("No RAB Logs found");
      }
      return res.data.data;
    }
  } catch (err) {
    if (err.response.status) {
      if (
        err.response.status === 403 &&
        err.response.data.error === "invalid_token"
      ) {
        alert("Session Expired. Sign in again");
        localStorage.clear();
        window.location.href = "/";
      } else {
        toast.info(err.response.data.message);
      }
    }
  }
};

export const _asyncGetMitraEnquiryList = async ({ page, size, sort_order, search_text }) => {
  try {
    const res = await axiosClient.get(`mitra_enquiry?page=${page}&size=${size}&search_text=${search_text || ''}`);
    if (res.status === 200) {
      if (!res.data.data) {
        toast.info("No Mitra Enquiry Found");
      }
      return res.data.data;
    }
  } catch (err) {
    if (err.response.status) {
      if (
        err.response.status === 403 &&
        err.response.data.error === "invalid_token"
      ) {
        alert("Session Expired. Sign in again");
        localStorage.clear();
        window.location.href = "/";
      } else {
        toast.info(err.response.data.message);
      }
    }
  }
};
export const _asyncGetContactEnquiryList = async ({ page, size, sort_order, search_text }) => {
  try {
    const res = await axiosClient.get(`contact_enquiry?page=${page}&size=${size}&search_text=${search_text}`);
    if (res.status === 200) {
      if (!res.data.data) {
        toast.info("No Contact Enquiry Found");
      }
      return res.data.data;
    }
  } catch (err) {
    if (err.response.status) {
      if (
        err.response.status === 403 &&
        err.response.data.error === "invalid_token"
      ) {
        alert("Session Expired. Sign in again");
        localStorage.clear();
        window.location.href = "/";
      } else {
        toast.info(err.response.data.message);
      }
    }
  }
};
export const _asyncGetMerchandiserUnassigned = async (id) => {
  try {
    const res = await axiosClient.get("admin/merchandiser/unassigned/" + id, axiosHeader());
    if (res.status === 200) {
      if (!res.data) {
        //toast.info("No data found");
      }
      return res.data;
    }
  } catch (err) {
    if (err.response.status) {
      if (
        err.response.status === 403 &&
        err.response.data.error === "invalid_token"
      ) {
        alert("Session Expired. Sign in again");
        localStorage.clear();
        window.location.href = "/";
      } else {
        toast.info(err.response.data.message);
      }
    }
  }
};

export const _asyncGetSMCsvData = async (data) => {
  try {
    const config = axiosHeader();
    config.params = {
      ...data
    };
    const res = await axiosClient.get(`admin/merchandiser/sm_csv_data`, config);
    if (res.status === 200) {
      if (!res.data) {
        //toast.info("No user subordinates found");
      }
      return res?.data?.data;
    }
  } catch (err) {
    if (err.response.status) {
      if (
        err.response.status === 403 &&
        err.response.data.error === "invalid_token"
      ) {
        alert("Session Expired. Sign in again");
        localStorage.clear();
        window.location.href = "/";
      } else {
        //toast.info(err.response.data.message);
      }
    }
  }
};

export const _asyncGetMerchandiserDetails = async (id, data) => {
  try {
    const config = axiosHeader();
    config.params = {
      ...data
    };
    const res = await axiosClient.get("admin/merchandiser/daily_report/" + id, config);
    if (res.status === 200) {
      if (!res.data) {
        //toast.info("No data found");
      }
      return res.data;
    }
  } catch (err) {
    if (err.response.status) {
      if (
        err.response.status === 403 &&
        err.response.data.error === "invalid_token"
      ) {
        alert("Session Expired. Sign in again");
        localStorage.clear();
        window.location.href = "/";
      } else {
        toast.info(err.response.data.message);
      }
    }
  }
};

export const _asyncGetMerchandiserProductDetails = async (id, data) => {
  try {
    const config = axiosHeader();
    config.params = {
      ...data
    };
    const res = await axiosClient.get("admin/merchandiser/product_detail/" + id, config);
    if (res.status === 200) {
      if (!res.data) {
        //toast.info("No data found");
      }
      return res?.data?.data;
    }
  } catch (err) {
    if (err.response.status) {
      if (
        err.response.status === 403 &&
        err.response.data.error === "invalid_token"
      ) {
        alert("Session Expired. Sign in again");
        localStorage.clear();
        window.location.href = "/";
      } else {
        toast.info(err.response.data.message);
      }
    }
  }
};

export const _asyncGetOdooProductList = async (data) => {
  try {
    const config = axiosHeader();
    config.params = {
      ...data
    };
    const res = await axiosClient.get("admin/products_v2/", config);
    if (res.status === 200) {
      if (!res.data) {
        //toast.info("No data found");
      }
      return res;
    }
  } catch (err) {
    if (err.response.status) {
      if (
        err.response.status === 403 &&
        err.response.data.error === "invalid_token"
      ) {
        alert("Session Expired. Sign in again");
        localStorage.clear();
        window.location.href = "/";
      } else {
        toast.info(err.response.data.message);
      }
    }
  }
};

export const _asyncMerchandiserAssignUnassign = async (id, body) => {
  try {
    const response = await axiosClient.put(
      `admin/merchandiser/assign-unassign/${id}`,
      body,
      axiosHeader()
    );
    if (response) {
      if (
        response.status === 200 &&
        response.statusText === "OK" &&
        response.error == null
      ) {
        toast.success(response?.data?.message, { autoClose: 2000 }
        );
        return response;
      } else {
        return response.error;
      }
    }
  } catch (err) {
    if (err.response.status) {
      if (
        err.response.status === 403 &&
        err.response.data.error === "invalid_token"
      ) {
        alert("Session Expired. Sign in again");
        localStorage.clear();
        window.location.href = "/";
      } else {
        toast.info(err.response.data.message);
      }
    }
  }
};



export const _asyncGetRecipientList = async (id) => {
  try {
    const config = axiosHeader();

    const res = await axiosClient.get("admin/esign/recipients/" + id, config);
    if (res.status === 200) {
      if (!res.data) {
        //toast.info("No data found");
      }
      return res?.data?.data;
    }
  } catch (err) {
    if (err.response.status) {
      if (
        err.response.status === 403 &&
        err.response.data.error === "invalid_token"
      ) {
        alert("Session Expired. Sign in again");
        localStorage.clear();
        window.location.href = "/";
      } else {
        toast.info(err.response.data.message);
      }
    }
  }
};

export const _asyncGetEMateraiDocTypes = async (data) => {
  try {
    const config = axiosHeader();
    config.params = {
      ...data
    };
    const res = await axiosClient.get(`admin/esign/ematerai_doc_types`, config);
    if (res.status === 200) {
      if (!res.data) {
        //toast.info("No user subordinates found");
      }
      return res?.data?.data;
    }
  } catch (err) {
    if (err.response.status) {
      if (
        err.response.status === 403 &&
        err.response.data.error === "invalid_token"
      ) {
        alert("Session Expired. Sign in again");
        localStorage.clear();
        window.location.href = "/";
      } else {
        //toast.info(err.response.data.message);
      }
    }
  }
};


export const _asynceSignDocUpload = async (body) => {
  try {
    const response = await axiosClient.post("admin/esign/doc_upload", body, axiosHeader());
    if (response) {
      if (response.status === 201 && response.statusText === "Created") {
        return response;
      } else {
        return response.error;
      }
    }
  } catch (err) {
    if (err.response.data.message) {
      toast.error(err.response.data.message, {
        autoClose: 2000,
      });
      return;
    }
  }
};

export const _asyncGetTaskDetails = async (id) => {
  try {
    const config = axiosHeader();
    const res = await axiosClient.get("admin/esign/task_info/" + id, config);
    if (res.status === 200) {
      if (!res.data) {
        toast.info("No data found");
      }
      return res.data;
    }
  } catch (err) {
    if (err.response.status) {
      if (
        err.response.status === 403 &&
        err.response.data.error === "invalid_token"
      ) {
        alert("Session Expired. Sign in again");
        localStorage.clear();
        window.location.href = "/";
      } else {
        toast.info(err.response.data.message);
      }
    }
  }
};

export const _asyncGetTaskRecipients = async (id) => {
  try {
    const config = axiosHeader();
    const res = await axiosClient.get("admin/esign/task_recipients/" + id, config);
    if (res.status === 200) {
      if (!res.data) {
        toast.info("No data found");
      }
      return res.data;
    }
  } catch (err) {
    if (err.response.status) {
      if (
        err.response.status === 403 &&
        err.response.data.error === "invalid_token"
      ) {
        alert("Session Expired. Sign in again");
        localStorage.clear();
        window.location.href = "/";
      } else {
        toast.info(err.response.data.message);
      }
    }
  }
};


export const _asyncGetTaskDocument = async (id) => {
  try {
    const config = axiosHeader();
    const res = await axiosClient.get("admin/esign/doc/" + id, config);
    if (res.status === 200) {
      if (!res.data) {
        toast.info("No data found");
      }
      return res.data;
    }
  } catch (err) {
    if (err.response.status) {
      if (
        err.response.status === 403 &&
        err.response.data.error === "invalid_token"
      ) {
        alert("Session Expired. Sign in again");
        localStorage.clear();
        window.location.href = "/";
      } else {
        toast.info(err.response.data.message);
      }
    }
  }
};

export const _asyncGetProvidedSignature = async () => {
  try {
    const config = axiosHeader();
    const res = await axiosClient.get("admin/esign/provided_sign", config);
    if (res.status === 200) {
      if (!res.data) {
        toast.info("No data found");
      }
      return res.data;
    }
  } catch (err) {
    if (err.response.status) {
      if (
        err.response.status === 403 &&
        err.response.data.error === "invalid_token"
      ) {
        alert("Session Expired. Sign in again");
        localStorage.clear();
        window.location.href = "/";
      } else if (
        err.response.status === 400 &&
        err.response.data.error === "Bad Request"
      ) {
      }
      else {
        toast.info(err.response.data.message);
      }
    }
  }
};


export const _asyncRequestOtp = async (body = {}, action = "kyc") => {
  try {
    //const url = action === "kyc"  ? "kyc": "sign";
    const response = await axiosClient.post(
      `admin/esign/init/${action}`,
      body,
      axiosHeader()
    );
    if (response) {
      if (response.status === 201) {
        toast.success(response?.data?.message);
        return response.data;
      } else {
        return response.error;
      }
    }
  } catch (err) {
    if (err.response.status) {
      if (
        err.response.status === 403 &&
        err.response.data.error === "invalid_token"
      ) {
        alert("Session Expired. Sign in again");
        localStorage.clear();
        window.location.href = "/";
      } else {
        toast.info(err.response.data.message);
      }
    }
  }
};


export const _asyncVerifyOtp = async (body) => {
  try {
    const response = await axiosClient.post(
      "admin/esign/verify_otp_and_proceed",
      body,
      axiosHeader()
    );
    if (response) {
      if (response.status === 201 && response.statusText === "Created") {
        return response;
      } else {
        return response.error;
      }
    }
  } catch (err) {
    if (err.response.status) {
      if (
        err.response.status === 403 &&
        err.response.data.error === "invalid_token"
      ) {
        alert("Session Expired. Sign in again");
        localStorage.clear();
        window.location.href = "/";
      } else {
        toast.info(err.response.data.message);
      }
    }
  }
};

export const _asyncAssignSignaturePosition = async (id, body) => {
  try {
    const response = await axiosClient.put(
      `admin/esign/assign_sign_positions/${id}`,
      body,
      axiosHeader()
    );
    if (response) {
      if (
        response.status === 200 &&
        response.statusText === "OK" &&
        response.error == null
      ) {
        return response;
      } else {
        return response.error;
      }
    }
  } catch (err) {
    if (err.response.status) {
      if (
        err.response.status === 403 &&
        err.response.data.error === "invalid_token"
      ) {
        alert("Session Expired. Sign in again");
        localStorage.clear();
        window.location.href = "/";
      } else {
        toast.info(err.response.data.message);
      }
    }
  }
};


export const _asyncDoEmaterai = async (id, body) => {
  try {
    const response = await axiosClient.put(
      `admin/esign/do_ematerai/${id}`,
      body,
      axiosHeader()
    );
    if (response) {
      if (
        response.status === 200 &&
        response.statusText === "OK" &&
        response.error == null
      ) {
        return response;
      } else {
        return response.error;
      }
    }
  } catch (err) {
    if (err.response.status) {
      if (
        err.response.status === 403 &&
        err.response.data.error === "invalid_token"
      ) {
        alert("Session Expired. Sign in again");
        localStorage.clear();
        window.location.href = "/";
      } else {
        toast.info(err.response.data.message);
      }
    }
  }
};

export const _asyncSendEsignDocument = async (id, body) => {
  try {
    const response = await axiosClient.put(
      `admin/esign/doc_upload/${id}`,
      body,
      axiosHeader()
    );
    if (response) {
      if (
        response.status === 200 &&
        response.statusText === "OK" &&
        response.error == null
      ) {
        return response;
      } else {
        return response.error;
      }
    }
  } catch (err) {
    if (err.response.status) {
      if (
        err.response.status === 403 &&
        err.response.data.error === "invalid_token"
      ) {
        alert("Session Expired. Sign in again");
        localStorage.clear();
        window.location.href = "/";
      } else {
        toast.info(err.response.data.message);
      }
    }
  }
};

