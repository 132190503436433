import { createSlice } from "@reduxjs/toolkit";

export const counterSlice = createSlice({
  name: "counter",
  initialState: {
    value: 0,
    navIcon: false,
  },
  reducers: {
    showHideNavIcon: (state) => {
      state.navIcon = !state.navIcon;
    },
  },
});

// Action creators are generated for each case reducer function
export const { showHideNavIcon } = counterSlice.actions;
export default counterSlice.reducer;
