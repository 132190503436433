import React from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";

const Accordion = ({ item, onClick, isActive }) => {
  const appStates = useSelector((state) => state.appStoredData);

  const navigate = useNavigate();
  const location = useLocation();

  const handleParentClick = () => {
    navigate(item.link);
  };

  return (
    <div className={`accordion-item cursor-pointer `}>
      <div
        className={`accordion-title flex p-3 ${
          location.pathname === item.link ||
          location.pathname.includes(item.link)
            ? "bg-[#E6F1ED]"
            : ""
        }`}
        onClick={handleParentClick}
      >
        <img
          className={`w-[20px] h-[20px] ${appStates.navIcon ? "mr-5" : ""}`}
          src={item.icon}
        />
        {!appStates.navIcon ? (
          <div className={"flex px-2 w-full justify-between items-center"}>
            <span>{item?.label}</span>
            <span
              onClick={onClick}
              className={`${
                !isActive
                  ? `rotate-[-180deg] -mr-1 mt-2`
                  : `rotate-0 fill-[#212529] dark:fill-white `
              } ml-auto h-3 w-3 shrink-0 fill-[#336dec] transition-transform duration-200 ease-in-out motion-reduce:transition-none dark:fill-blue-300`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="h-5 w-5"
                color="#40AE49"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                />
              </svg>
            </span>
          </div>
        ) : (
          <></>
        )}
      </div>

      {!appStates.navIcon && isActive && (
        <>
          <div className="accordion-content ml-5 mt-1">
            {item?.submenus?.map((submenu_item) => (
              <Link
                to={
                  submenu_item.link === location.pathname
                    ? ""
                    : submenu_item.link
                }
                className={`flex items-center gap-1 mx-6 py-1 px-1 ${
                  location.pathname == submenu_item.link ? "bg-[#E6F1ED]" : ""
                }`}
              >
                {submenu_item?.icon && (
                  <img
                    className={`w-[18px] h-[18px]${
                      appStates.navIcon ? "mr-5" : ""
                    }`}
                    src={submenu_item.icon}
                  />
                )}

                {submenu_item.name}
              </Link>
            ))}
          </div>
          <hr className="my-2 shadow-md" />
        </>
      )}
    </div>
  );
};

export default Accordion;
