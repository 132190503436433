import React, { useEffect, useState } from "react";
import DropDownComp from "../components/DropDownComp";
import { axiosClient, axiosHeader } from "../api";
import closeImg from "../images/FarmPipeLine/close.png";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

export const ChartOnModal = ({
  hideModal,
  chartOptions
}) => {

  return (
    <>
      <div className="flex overflow-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center">
        <div className="w-[65%] relative mt-[3%] mb-[3%]">
          {/*content*/}
          <div className="h-full first-letter:rounded-lg shadow-lg relative flex flex-col w-full outline-none focus:outline-none rounded-lg">
            <div className="flex items-center relative pl-6 pr-6 overflow-auto pt-3 bg-white rounded-t-lg h-full">
              <p className="text-[18px] w-full font-[450px] mb-5 mt-3">
              {chartOptions?.name}
              </p>
              <img
                className="float-right w-[30px] h-[30px]"
                src={closeImg}
                alt="closeImg"
                onClick={() => {
                  hideModal();
                }}
              />
            </div>
            {/*footer*/}
            <div className="py-6 rounded-b-lg bg-white w-full px-20">
            <HighchartsReact
            //containerProps={{ className: "h-[300px]" }}
            highcharts={Highcharts}
            options={chartOptions}
          />
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>{" "}
    </>
  );
};
