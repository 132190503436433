import React, { useEffect, useState } from "react";
import windImage from "../images/wind.png";
import directionImage from "../images/wind_dir.png";
import rainfallImage from "../images/rainfall.png";
import pressureImage from "../images/pressure.png";
import humidityImage from "../images/humidity.png";
import uvImage from "../images/uv.png";
import { MitraListTable } from "../config/tables/MitraList";
import { convertTZ, formatDate } from "../config/localdata";
import { HighchartsReact } from "highcharts-react-official";
import Highcharts from "highcharts";
import { FiRefreshCcw } from "react-icons/fi";
import offlineWeather from "../images/offlineWeather.png";
import { HiDownload } from "react-icons/hi";
import { useNavigate } from "react-router-dom/dist";
import weatherStationGreen from "../images/weather-station-green.png";
import moment from "moment";

export const WeatherComp = ({
  selectedStation,
  selectedStationData,
  showLogData,
  refreshWeatherStation,
}) => {
  const [selectedDay, setSelectedDay] = useState(
    selectedStationData?.weather_info?.future_dates[0]
  );
  // console.log('select---data---->>>',JSON.stringify(selectedDay));
  const [hours, setHours] = useState([]);
  const [hoursValues, setHoursValues] = useState([]);
  const location = useNavigate();

  useEffect(() => {
    setSelectedDay(selectedStationData?.weather_info?.future_dates[0]);
    let hourList = [];
    let hourValueList = [];
    selectedStationData?.weather_info?.future_dates[0]?.hour.forEach(
      (element) => {
        let time = element?.time.toString().trim().split(" ");
        hourList.push(time[1]);
        hourValueList.push(element?.temp_c);
      }
    );
    setHours(hourList);
    setHoursValues(hourValueList);
  }, [selectedStationData]);

  const handleDayChange = (index) => {
    let hourList = [];
    let hourValueList = [];
    selectedStationData?.weather_info?.future_dates[index]?.hour.forEach(
      (element) => {
        let time = element?.time.toString().trim().split(" ");
        hourList.push(time[1]);
        hourValueList.push(element?.temp_c);
      }
    );
    setHours(hourList);
    setSelectedDay(selectedStationData?.weather_info?.future_dates[index]);
    setHoursValues(hourValueList);
    // console.log(JSON.stringify(selectedStationData?.weather_info?.future_dates),'----------');
  };
  const formatTime = (unixTimestamp) => {
    const dateObj = new Date(unixTimestamp * 1000); // Convert seconds to milliseconds
    const hours = String(dateObj.getUTCHours()).padStart(2, '0');
    const minutes = String(dateObj.getUTCMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`;
  };
  useEffect(() => {
    let hourList = [];
    let hourValueList = [];
    let dayDate = new Date(selectedDay?.date);
    if (formatDate(dayDate) === formatDate(new Date())) {
      _sortTodayHours(selectedDay);
    } else {
      selectedDay?.hour.forEach((element) => {
        let time = element?.time.toString().trim().split(" ");
        hourList.push(time[1]);
        hourValueList.push(element?.temp_c);
      });
      setHours(hourList);
      setHoursValues(hourValueList);
    }
  }, [selectedDay]);

  function _sortTodayHours(item) {
    let todaysDate = convertTZ(new Date(), "Asia/Jakarta");
    let array = [];
    let hourValueList = [];
    let hourList = [];
    let add = false;
    item?.hour.forEach((element) => {
      let time = element?.time.toString().trim().split(" ");
      let hourTime = element.time.toString().trim().split(" ");
      let hour =
        todaysDate.getHours() > 9
          ? todaysDate.getHours()
          : "0" + todaysDate.getHours();
      let hourTimeHour = hourTime[1].split(":");

      if (hour.toString().trim() === hourTimeHour[0].toString().trim()) {
        add = true;
      }

      if (add) {
        array.push(time[1]);
        let temp = 0;
        if (element?.temp_c !== undefined) {
          temp = element?.temp_c;
        } else {
          temp = element?.avgtemp_c;
        }
        hourValueList.push(temp);
      }
    });

    let sortedArray = [...array];

    if (sortedArray.length > 0) {
      setHours(sortedArray);
      setHoursValues(hourValueList);
    }
  }


  const DataValues = ({ icon, label, value }) => {
    return (
      <div className="flex">
        <img
          className="w-[30px] h-[30px] object-contain"
          src={icon}
          alt="beleaf_images"
        />
        <div className="ml-5">
          <p className="text-[#40AE49] text-[16px]">{value}</p>
          <p className="text-[#808080] text-sm">{label}</p>
        </div>
      </div>
    );
  };

  const CurrentDayData = ({ data }) => {
    return (
      <div class="grid-2 gap-2 mb-3">
        <div className="flex items-center">
          <DataValues
            icon={data[0]?.icon}
            label={data[0]?.label}
            value={data[0]?.value}
          />
        </div>
        <div>
          <DataValues
            icon={data[1]?.icon}
            label={data[1]?.label}
            value={data[1]?.value}
          />
        </div>
      </div>
    );
  };

  function secondsToHms(seconds) {
    var hours = Math.floor(seconds / 3600);
    var minutes = Math.floor((seconds % 3600) / 60);
    var remainingSeconds = seconds % 60;
  
    var formattedTime = "";
  
    if (hours > 0) {
      formattedTime += hours.toString().padStart(2, "0") + " Hours ";
    }
  
    formattedTime += minutes.toString().padStart(2, "0") + " Minutes ";
    // formattedTime += remainingSeconds.toString().padStart(2, "0");
  
    return formattedTime;
  }
  

  const PieChartComp = () => {
    const chartOptions = {
      credits: {
        enabled: false,
      },
      title: {
        text: "Weather Forecast",
      },
      plotOptions: {
        line: {
          dataLabels: {
            enabled: true,
          },
          enableMouseTracking: false,
        },
      },
      legend: {
        enabled: false,
      },

      xAxis: {
        title: {
          text: "Hours",
        },
        categories: hours,
      },
      yAxis: {
        title: {
          text: "Temperature",
        },
      },

      series: [
        {
          name: "Temp",
          data: hoursValues,
          color: "#40AE49",
        },
      ],
    };

    return (
      <div className="w-full">
        <HighchartsReact
          containerProps={{ className: "h-[350px] w-[100%]" }}
          highcharts={Highcharts}
          options={chartOptions}
        />
      </div>
    );
  };

  return (
    <div className="mt-3 h-[88%]  overflow-auto scrollbar-hide ">
      <div
        onClick={() => {
          location("/iot-devices/weather-station-report/" + (selectedStation?.id||''));
        }}
        className="bg-[#D7EFD9] float-right cursor-pointer flex items-center justify-center gap-2 border text-xs font-bold border-[#9FD7A4] w-fit px-6 py-2 rounded-xl"
      >
        <HiDownload />
        <p>Get Report</p>
      </div>
      <div className="flex justify-between w-full p-2 items-center">
        {selectedStationData.weather_info.future_dates.map((item, index) => {
          return (
            <div
              key={item.date}
              onClick={() => handleDayChange(index)}
              className={`items-center justify-between ${
                selectedDay?.date === item?.date
                  ? "bg-[#40AE49] text-white"
                  : "bg-white"
              } mr-3 py-4 px-5 rounded-3xl shadow-gray-300 shadow-md cursor-pointer`}
            >
              <p className="text-[14px] text-center">
                {index === 0
                  ? "Today's Average"
                  : formatDate(new Date(item.date), false)}
              </p>
              <div className="flex justify-center">
                <img
                  className="w-[64px] h-[64px]  mx-3 my-2"
                  src={item.day.condition?.icon}
                  alt="beleaf_images"
                />
              </div>
              <p className="text-[14px] font-bold text-center">
                {item.day.avgtemp_c}°C , {item.day.avghumidity}% RH
              </p>
            </div>
          );
        })}
      </div>
      <div className="mt-3 rounded-2xl border border-gray-600 shadow-gray-300 shadow-sm p-5">
        <p className="text-center text-[21px] font-medium">
          {formatDate(new Date(selectedDay.date), true, true)}
        </p>
        <div
          title="Click to see realtime data"
          style={{ float: "right", marginRight: "10px", marginTop: "-25px" }}
          onClick={() => refreshWeatherStation()}
          className="flex gap-2 border-2 cursor-pointer bg-[#F9F9F9] border-black rounded-md p-2"
        >
          <FiRefreshCcw size={25} /> Refresh
        </div>
        {!selectedStation?.is_active && (
          <div
            title="Click to see realtime data"
            className="flex"
            style={{ float: "left", marginTop: "-25px" }}
          >
            <img src={offlineWeather} className="w-5 h-5" />{" "}
            <p className="text-[#F491A3] ml-2">Device Offline</p>
          </div>
        )}

        <div class="weather-big mt-5">
          <div className="current-weather mb-5 mx-10 justify-center items-center">
            <div className=" flex  justify-evenly  items-center">
              <div className="location">
                <div className="flex">
                  <img src={weatherStationGreen}></img>
                  <div className="text-green flex flex-col justify-center text-xs">
                    <p>View</p>
                    <p className="font-semibold"> Weather Station</p>
                    <p>Location on Maps</p>
                  </div>
                </div>
                <div onClick={()=> window.open(`https://www.google.com/maps/?q=${selectedStationData?.weather_info?.location?.lat},${selectedStationData?.weather_info?.location?.lon}`, "_blank")} className="button w-full text-center cursor-pointer text-xs py-1 rounded-md bg-green text-white ">
                  Go to Maps
                </div>
              </div>
              <div className="icon-and-condition">
                <div className="flex justify-center mt-3">
                  <img
                    className="w-[145px] h-[145px]"
                    src={selectedDay.day.condition?.icon}
                    alt="beleaf_images"
                  />
                </div>
                <p className="text-[24px] text-center font-normal text-[#252525]">
                  {selectedDay.day.condition?.text}
                </p>
              </div>
              <div className="temp-details text-[#808080] flex flex-col items-start">
                <p className="text-green font-bold text-center mt-3">
                  {selectedDay.day.avgtemp_c}° C, {selectedDay.day.avghumidity}%
                  RH
                </p>
                <p>Current Temprature & Humidity</p>
                <div className="mt-3 text-[0.85rem]">
                  <p>
                    <span className="font-semibold">Min Temp :</span> {selectedDay?.day?.calculations?.min_temp?.temp}°C at {formatTime(selectedDay?.day?.calculations?.min_temp?.time)}
                  </p>
                  <p>
                    <span className="font-semibold">Max Temp :</span>{selectedDay?.day?.calculations?.max_temp?.temp}°C  at {formatTime(selectedDay?.day?.calculations?.max_temp?.time)}
                  </p>

                  <p className="mt-2">
                    <span className="font-semibold">Min Hum :</span>{selectedDay?.day?.calculations?.min_hum?.hum}% RH  at {formatTime(selectedDay?.day?.calculations?.min_hum?.time)} 
                  </p>
                  <p>
                    <span className="font-semibold">Max Hum :</span> {selectedDay?.day?.calculations?.max_humidity?.hum}% RH at {formatTime(selectedDay?.day?.calculations?.max_humidity?.time)}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className=" items-center">
            <div className="w-full flex justify-evenly">
              <CurrentDayData
                data={[
                  {
                    icon: rainfallImage,
                    label: "Rainfall",
                    value: `${
                      selectedDay?.day?.totalprecip_mm === undefined
                        ? "0"
                        : selectedDay?.day?.totalprecip_mm
                    } mm`,
                  },
                  {
                    icon: pressureImage,
                    label: "Pressure",
                    value: `${selectedDay?.day?.pressure_in} hPa`,
                  },
                ]}
              />-
              <CurrentDayData
                data={[
                  {
                    icon: windImage,
                    label: "Current Wind Speed",
                    value: `${selectedDay?.day?.maxwind_kph} km/h`,
                  },
                  {
                    icon: windImage,
                    label: "Average Wind Speed",
                    value: `${selectedDay?.day?.calculations?.avg_wind_speed_kph || '-'} km/h` ,
                  },
                ]}
              />
              <CurrentDayData
                data={[
                  {
                    icon: windImage,
                    label: "Current Wind Direction",
                    value: `${selectedDay?.day.wind_degree}’ / ${selectedDay?.day?.wind_dir}`,
                  },
                  {
                    icon: directionImage,
                    label: "Wind Direction Modality",
                    value: `${selectedDay?.day?.calculations?.wind_direction_modality?.wind_degree || '-'} / ${selectedDay?.day?.calculations?.wind_direction_modality?.wind_direction || '-'}`,
                  },
                ]}
              />
              <CurrentDayData
                data={[
                  {
                    icon: rainfallImage,
                    label: "Wind Direction at Max Speed",
                    value: `${
                      !selectedDay?.day?.calculations?.max_wind_speed?.wind_degree 
                        ? "0"
                        : selectedDay?.day?.calculations?.max_wind_speed?.wind_degree
                    } /${selectedDay?.day?.calculations?.max_wind_speed?.wind_direction + ` (${selectedDay?.day?.calculations?.max_wind_speed?.wind_velocity} km/h at ${moment(selectedDay?.day?.calculations?.max_wind_speed?.created_date_timestamp).format('HH:mm A')})` || '-'} `,
                  },
                  {
                    icon: pressureImage,
                    label: "Sunshine Duration Today",
                    value: `${secondsToHms(selectedDay?.day?.calculations?.total_sunshine_seconds||0)} `,
                  },
                ]}
              />
              <CurrentDayData
                data={[
                  {
                    icon: humidityImage,
                    label: "Visible Light",
                    value: `${selectedDay?.day?.avgvis_km==='N/A' ? selectedDay?.day?.avgvis_km : selectedDay?.day?.avgvis_km +' Lux'}`,
                  },
                  {
                    icon: uvImage,
                    label: "UV Index",
                    value: `${selectedDay?.day?.uv}`,
                  },
                ]}
              />
            </div>
          </div>
          {!selectedStation?.is_active && (
            <p className="text-center w-[200%] text-[#F491A3]">
              *Device Offline, the Weather Station display is from API which is
              not realtime and not updated
            </p>
          )}
        </div>
      </div>
      <div>
        <p className="font-semibold mt-3 text-[21px]">
          Weather Forecast (24 Hours)
        </p>
        <div className="items-center justify-center mt-3 rounded-2xl border border-gray-600  shadow-sm p-3">
          <PieChartComp />
         <div className="flex items-center justify-center gap-3">
         <div className=" flex flex-col items-end">
          <p className="text-xs">Minimum Temperature</p>
          <p className="font-bold"> {selectedDay?.day?.calculations?.min_temp?.temp}°C</p>
          </div>
          <div>
          <p className="text-xs">Maximum Temperature</p>
          <p className="font-bold"> {selectedDay?.day?.calculations?.max_temp?.temp}°C</p>
          </div>
         </div>
        </div>
      </div>
      <div className="p-2 mt-3">
        <div className="flex items-center justify-center bg-white rounded-2xl shadow-md py-3">
          <p>Want to see weather log ?</p>
          <p
            onClick={() => showLogData()}
            className="bg-[#40AE49] text-white px-5 py-1 rounded-full ml-5 text-sm cursor-pointer"
          >
            Log Data
          </p>
        </div>
      </div>
      <div>
        <p className="font-semibold mt-3">Mitra Lists</p>
        <div className="mt-3 rounded-2xl border border-gray-600 h-[300px] shadow-sm px-2">
          <MitraListTable selectedStation={selectedStation} />
        </div>
      </div>
    </div>
  );
};
