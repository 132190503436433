import React, { useEffect, useState } from "react";
import { TableSearchComp } from "../../../components/TableSearchComp";
import { TablePaginationComp } from "../../../components/TablePaginationComp";
import { getDateFormatMoment } from "../../localdata";
import { _asyncCancelPO, _asyncGetAllPO } from "../../../api";
import { useLocation } from "react-router-dom";
import { DashboardFilterModal } from "../../../Models/DashboardFilterModal";
import { IoIosArrowForward } from 'react-icons/io';
import { CancelPOModal } from "../../../Models/CancelPOModal";




export const POs = ({ metaData,selectedFilter}) => {
  const size = 10;
  const configFetchRef = React.useRef(false);
  const [totalPage, setTotalPage] = useState();
  const [totalItems, setTotalItems] = useState();
  const [selectedPage, setSelectedPage] = useState(0);
  const [tableDataList, setTableDataList] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState();
  const [selectedFarm, setSelectedFarm] = useState();
  const [selectedMitra, setSelectedMitra] = useState();
  const [filterData, setFilterData] = useState({})
  const location = useLocation();
  const [searchText, setSearchText] = useState("")
  const [action, setAction] = useState("");
  const [searchType, setSearchType] = useState('');

  const [showGeneratePo, setShowGeneratePo] = useState(false);
  const [generatePoBody, setGeneratePoBody] = useState(null);

  const sortType = [
    { id: "1", name: "Latest" },
    { id: "2", name: "Earliest" },
  ];

  const colorObject = {
    4:'border-[#F491A3]',
    3:'border-[#12312B]',
    1:'border-[#40AE49]',
    2:'border-[#F491A3]',
    5:'border-[#40AE49]',
  }

  const [sortValue, setSortValue] = useState({id: "3",name: "Sort By"});

  useEffect(() => {
    if (configFetchRef.current) return;
    configFetchRef.current = true;
    _fetchProducts(selectedPage, null, filterData);
  }, []);



  useEffect(() => {
    let sort_order = sortValue?.name === "Earliest" ? "ASC":"DESC"
    _fetchProducts(selectedPage, sort_order, filterData);
  }, [selectedFilter, sortValue]);


function _getPlantingStatus(id) {
  let data = "";
  data = metaData?.allowedPOStatus?.filter((e) => e.id == id);
  return data && data[0] && data[0]?.status_name;
}


const handlePoDetails = (item) =>{
    if(item){
      setGeneratePoBody(item)
      setShowGeneratePo(true)
    }
}

  async function _fetchProducts(page, sort_order, item) {
    setShowFilter(false);
    let params = {
      size:size,
      page:page+1,
      po_status:selectedFilter?.id !== '' ? [selectedFilter?.id]:null,
      
      ...item
    }
    if(searchType?.search_by_key && searchText){
      params[searchType?.search_by_key] = searchText;
    }
    const res = await _asyncGetAllPO(params,location, sort_order);
    setTableDataList(res?.displayData)
    setTotalPage(res?.totalPage)
    setTotalItems(res?.totalItems)
  }
  
   useEffect(()=>{
    _fetchProducts(selectedPage, null, filterData)
  },[action])

  const handleSearchSubmit = (action) =>{
    setAction(action)
    setSelectedPage(0)
    if(action === "reset"){
       setSearchType("");
       setSearchText("");
      _fetchProducts(0, null, filterData, "")
    }
    if(searchText.length > 2 && action !== "reset"){
      _fetchProducts(0, null,filterData)
    }
  }

  const addFilters = () =>{
    let product_ids = []
    let farm_ids = []
    let mitra_ids = []

    if(selectedProduct !== undefined){
        product_ids.push(selectedProduct.id)
    }
    if(selectedFarm !== undefined){
        farm_ids.push(selectedFarm.id)
    }
    if(selectedMitra !== undefined){
        mitra_ids.push(selectedMitra.id)
    }
    setFilterData({product_ids, farm_ids, mitra_ids})
    setSelectedPage(0)
    _fetchProducts(0, null, {product_ids, farm_ids, mitra_ids})
  }

  const resetFilter = () =>{
    setShowFilter(false);
    _fetchProducts(selectedPage, null, {})
    setFilterData({})
    setSelectedProduct()
    setSelectedFarm()
    setSelectedMitra()
  }

  return (
    <div className="h-[100%]">
      {showFilter ? (
        <DashboardFilterModal
          hideModal={() => setShowFilter(false)}
          resetFilter={resetFilter}
          addFilters={addFilters}
          selectedProduct={selectedProduct}
          selectedFarm={selectedFarm}
          selectedMitra={selectedMitra}
          setSelectedProduct={setSelectedProduct}
          setSelectedFarm={setSelectedFarm}
          setSelectedMitra={setSelectedMitra}
        />
      ) : null}

    {showGeneratePo ? (
        <CancelPOModal
          hideModal={() => {setShowGeneratePo(false)}}
          metaData={metaData}
          generatePoBody={generatePoBody}
        />
      ) : null}

      <div className="h-[7%]">
        <div
          style={{
            display: "flex flex-col",
            
          }}
        >
          <div className="w-[100%]">
            <TableSearchComp
              onChange={(text) => setSearchText(text)}
              openFilter={() => setShowFilter(true)}
              sortBy={true}
              sortType={sortType}
              sortValue={sortValue}
              handleSortBy={(item) => setSortValue(item)}
              searchText={searchText}
              handleSearchSubmit={handleSearchSubmit}
              searchTypeValues={metaData?.searchableFeaturesWithOptions?.po_list}
              searchType={searchType}
              onSearchChangeType={(item) => setSearchType(item)}
            />
          </div>
        </div>
      </div>
      <div className="h-[95%]  overflow-auto scrollbar-hide mt-4">
        {tableDataList?.map((data, i) => {
        return (
          <div className="box-border w-full mt-2 p-[0.75rem] border-2 border-slate-200 rounded-xl">
                  <div className="mx-[0.8rem] flex flex-row w-full  justify-around ">
                    <div className="basis-1/3">
                    <p className="break-words text-balance text-[1rem] font-bold">
                      {data?.po_name}
                    </p>
                    <p className=" text-balance text-wrap text-gray text-[0.75rem] font-[600]">
                    Created at {getDateFormatMoment(data?.po_created_at)}
                    </p>
                    <div className="w-[40%]">
                    <p className={`text-balance text-[0.75rem] p[0.5rem] font-[800] w[100%] text-black text-center bg-[#fff] border ${colorObject[data?.po_state]}`}>
                      {_getPlantingStatus(data?.po_state)}
                    </p>
                    </div>
                  </div>
                  <div className=" mx-[1rem] basis-1/4 ">
                    <p className=" break-all mr-2 text-balance text-[1rem] font-[800]">
                      {data?.farm_name}
                    </p>
                    <p className="break-all mr-2 text-balance  font-[800] text-[0.75rem] ">{data?.user_name}</p>
                    <p className=" text-balance text-wrap text-gray text-[0.70rem] ">
                    Expected Arrival Date
                    </p>
                    <p className=" text-balance text-wrap text-gray text-[0.75rem] font-[600]">{getDateFormatMoment(data?.expected_arrival_date)}</p>
                  </div>

                  <div className="ml-[1rem] basis-1/4 ">
                    {data?.harvests.map((item)=>{
                      return(<p className="text-black font-bold text-[0.75rem]">
                      <b>{item?.planting_batch_id}</b>
                      <b>{item?.harvest_batch_id}</b>
                      </p>)
                    })}
                  </div>
                  
                  <div className="ml-[1rem] basis-1/3">
                    {data?.harvests.map((item)=>{
                      return(<p className="text-black font-bold text-[0.75rem]">
                      <b>{item?.product_name}</b>
                      </p>)
                    })}
                  </div>
                  <div className="ml-[1rem] basis-1/5 ">
                    {data?.harvests.map((item)=>{
                      return(<p className="text-black font-bold text-[0.75rem]">
                      <b>{item?.production_code}</b>
                      </p>)
                    })}
                  </div>
                  <div className="mr-[0.5rem] basis-1/1 flex items-center">
                    <IoIosArrowForward
                    onClick={()=>handlePoDetails(data)}
                    />
                  </div>
                  </div>
            </div>
           );
          })}
        </div>
      <div className="flex justify-end items-center h-[15%] text-sm px-5">
        {totalPage !== undefined ? (
          <TablePaginationComp
            totalPages={totalPage}
            active={selectedPage}
            selectedPage={(p) => {
              setSelectedPage(p);
              _fetchProducts(p, null, filterData);
            }}
            totalItems={totalItems}
          />
        ) : null}
      </div>
    </div>
  );
};
