import React, { useEffect, useState } from "react";
import { _asyncGetRabDetails } from "../api";
import closeImg from "../images/FarmPipeLine/close.png";
import { AiOutlineUpload } from "react-icons/ai";
import stamp from "../images/stamp.png";

export const RabStatusModal = ({ hideModal, rabData, onStatusClick }) => {
  let user = JSON.parse(localStorage.getItem("user_data"));

  return (
    <>
      <div className="flex overflow-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center">
        <div className="w-[55%] h-[30%] relative mt-[3%] mb-[3%]">
          {/*content*/}
          <div style={{'overflow':'hidden'}} className="first-letter:rounded-lg shadow-lg relative flex flex-col w-full outline-none focus:outline-none rounded-lg">
            <div className="relative p-10 pt-3 bg-white rounded-t-lg h-[250px]">
            <div className="">
            <img
                className="float-right w-[30px] h-[30px] ml-1 mt-0"
                src={closeImg}
                alt="closeImg"
                onClick={() => {
                  hideModal();
                }}
              />
              <div className="w-[357px] h-[21px] mt-7">
                <p className="h-[21px] [font-family:'Poppins-Bold',Helvetica] font-bold text-[#252525] text-[14px] tracking-[0] leading-[normal]">
                  {rabData.data?.rab_name}
                </p>
              </div>
              <div
                className={`relative w-[300px] h-[36px] mt-5`}
                style={{backgroundColor:rabData.data?.current_rab_state?.highlight_color}}
              >
                <div className={`absolute w-full h-[18px] top-[9px] [font-family:'Poppins-Regular',Helvetica] font-normal ${rabData?.data.current_rab_state?.state === 'Created' ? 'text-black':'text-white'} text-[12px] text-center tracking-[0] leading-[normal]`}>
                  {rabData?.data.current_rab_state?.state +
                    " " +
                    rabData?.data?.last_state_updated_by_admin?.user_name || rabData?.data?.last_state_updated_by_mitra?.user_name}
                </div>
              </div>

              <div className="absolute grid grid-rows-1 grid-flow-col gap-10 mt-20 z-30">
                <div>
                  <p
                    onClick={() => onStatusClick("preview")}
                    className="bg-[#F491A3] text-white px-5 py-1  rounded-lg cursor-pointer"
                  >
                    Preview
                  </p>
                </div>

                {rabData?.rab_doc_info?.id ? 
                <div>
                <p
                  onClick={() => onStatusClick('download')}
                  className="bg-[#0094FF] text-white px-4 py-1  rounded-lg cursor-pointer">
                  Download
                </p>
              </div>:
                
                (rabData?.data?.current_state_id !== 2 && user.role.role !== "Guest" &&
                <div>
                <AiOutlineUpload color="white"  style={{'position':'absolute', marginTop:'7px', marginLeft:'7px'}} />
                <p
                  onClick={() => onStatusClick('upload')}
                  className="bg-[#0094FF] text-white px-8 py-1 rounded-lg cursor-pointer">
                  Upload
                </p>
              </div>)}

              {/* <div>
                <p
                  onClick={() => onStatusClick('approve')}
                  className="bg-[#40AE49] text-white px-8 py-1  rounded-lg cursor-pointer">
                  Approve
                </p>
              </div> */}
              
              {[2,3,7,10].includes(rabData?.data?.current_state_id) && (user.role.role !== "Guest") &&
                <div >
                <p
                  onClick={() => onStatusClick('revise')}
                  className="bg-[#12312B] text-white px-5 py-1  rounded-lg cursor-pointer">
                  Revise
                </p>
              </div>
              }

                {[1,4,10].includes(rabData?.data?.current_state_id) && (user.role.role !== "Guest") &&
                <div >
                <p
                  onClick={() => onStatusClick('editRevision')}
                  className="bg-[#12312B] text-white px-5 py-1  rounded-lg cursor-pointer">
                  Edit RAB
                </p>
              </div>
              }
              {/* {rabData?.data?.current_state_id === 1 &&
                <div className="w-[120px]">
                <p
                  onClick={() => onStatusClick('draft')}
                  className="bg-[#12312B] text-white px-5 py-1 rounded-lg cursor-pointer">
                  Edit Draft
                </p>
              </div>
              } */}
              <div>
                <p
                  onClick={() => onStatusClick('logs')}
                  className="bg-[#808080] text-white px-5 py-1 rounded-lg cursor-pointer">
                  Log
                </p>
              </div>
              </div>
            </div>
              <img
                style={{
                  position:'absolute',
                  right:-50,
                  bottom:-50,
                  opacity: .70,
                  zIndex:0,
                  width: "250px",
                  height: "220px",
                }}
                alt="Stamp"
                src={stamp}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>{" "}
    </>
  );
};
