import React, { useState } from "react";
import { BsSearch } from "react-icons/bs";
import filters_img from "../images/FarmPipeLine/filters.png";
import csv_img from "../images/csv.png";
import { CSVLink, CSVDownload } from "react-csv";
import DropDownComp from "./DropDownComp";

export const TableSearchComp = ({
  onChange,
  openFilter,
  showToggleBtn = false,
  toggleBtnName = "",
  openToggleFilter,
  showCSV = false,
  csvData,
  fileName,
  sortBy = false,
  sortType,
  sortValue,
  handleSortBy,
  searchText,
  handleSearchSubmit,
  showFilterBtn = true,
  searchType,
  onSearchChangeType,
  searchTypeValues = [],
  showTypeDropDown = true,
}) => {

  return (
    <div className="flex bg-white space-around w-full  ">
      <div className="w-full flex-grow-[0.4]">
        <div className=" flex w-full">
          {showTypeDropDown && (
            <DropDownComp
              listData={searchTypeValues}
              active={searchType}
              type={"searchType"}
              onSelected={onSearchChangeType}
              customStyles={
                "h-[2.8rem] rounded-l-md border-borderColor border-r-0 justify-between  text-black text-[1.5rem]"
              }
              customMenuStyle={
                "relative h-[2.8rem] inline-block text-left w-[12rem] "
              }
            />
          )}
          <div className="border text-center border-borderColor h-[2.8rem] w-full flex  items-center">
            <BsSearch className="ml-2" color="#8A9099" />
            <input
              className="ml-3 w-full outline-none border-none text-sm "
              type="text"
              value={searchText}
              placeholder="Type Something"
              onChange={(text) => {
                onChange(text.target.value);
              }}
            />
          </div>
          <button
            type="button"
            className="flex bg-[#3EB049] px-4 py-1  h-[2.8rem] items-center rounded-r-md shadow-black "
            onClick={() => handleSearchSubmit("find")}
          >
            <p className="text-white mr-2 text-sm">
              {" "}
              <BsSearch className="text-lg" color="#FFFFFF" />
            </p>
          </button>
        </div>

        {/* <div className="w-[100%] "> */}
        {/* <div className="border border-borderColor h-[2.8rem] w-full flex-grow flex  items-center">
          <BsSearch className="ml-2" color="#8A9099" />
          <input
            className="w-full  outline-none border-none text-sm "
            type="text"
            value={searchText}
            placeholder="Type Something"
            onChange={(text) => {
              onChange(text.target.value);
            }}
          />
        </div>
        <button
          type="button"
          className="flex bg-[#3EB049] px-4 py-1  h-[2.8rem] items-center rounded-r-md shadow-black "
          onClick={() => handleSearchSubmit("find")}
        >
          <p className="text-white mr-2 text-sm">{"🔍"}</p>
        </button> */}
        {/* </div> */}
      </div>
      <div className="">
        {/* <button
          type="button"
          className="flex bg-[#24352F] px-4 py-2 items-center rounded-md shadow-black ml-5"
          onClick={() => handleSearchSubmit("find")}
        >
          <p className="text-white mr-2 text-sm">Search</p>
        </button> */}
        <button
          type="button"
          className="flex bg-[#3EB049] px-4 py-1     h-[2.8rem] items-center rounded-md shadow-black ml-5"
          onClick={() => handleSearchSubmit("reset")}
        >
          <p className="text-white mr-2 text-base">Reset</p>
        </button>
      </div>
      {showFilterBtn && (
        <div
          onClick={() => openFilter()}
          className="flex justify-center   items-center ml-5 rounded-lg cursor-pointer h-[39px] border border-gray-200 px-8"
        >
          <img src={filters_img} className="w-5 h-5" />
        </div>
      )}
      {showToggleBtn && (
        <div className="flex justify-center text-sm items-center ml-5 rounded-lg h-[39px] border border-gray-200 w-[400px]">
          {toggleBtnName}
          <label className="relative ml-1 inline-flex cursor-pointer">
            <input
              type="checkbox"
              className="sr-only peer"
              onChange={(item) => openToggleFilter(item)}
            />
            <div className="w-11 h-6 bg-[#E8E9EB] rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-[#3EB049]"></div>
          </label>
        </div>
      )}
      {sortBy && (
        <DropDownComp
          listData={sortType}
          active={sortValue}
          type={"sort_by"}
          onSelected={(item) => {
            handleSortBy(item);
          }}
          customStyles={"rounded-lg justify-between text-black text-[0.5rem]"}
          customMenuStyle={"relative inline-block text-left w-[280px] text-[1rem] px-2 mr-2"}
        />
      )}
      {showCSV && (
        <div className="flex flex-shrink-0 justify-center items-center ml-5 rounded-lg cursor-pointer h-[39px] border border-gray-200 px-8">
          <CSVLink data={csvData} filename={fileName} asyncOnClick={true}>
            <img src={csv_img} className="w-9 h-9" />
          </CSVLink>
        </div>
      )}
    </div>
  );
};
