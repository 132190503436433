import React, { useEffect, useState } from "react";
import {
  _asyncGetAllCropHarvested,
  _asyncGetAllCropTraker,
  _asyncGeneratePO,
  _asyncGetAllInternalTransferHarvested,
  _asyncGetAllInternalTransferStatus,
} from "../api";
import ArrowButton from "../images/Vector.png";
import { TableSearchComp } from "./TableSearchComp";
import { TablePaginationComp } from "./TablePaginationComp";
import { toast } from "react-toastify";
import { LogDataModal } from "../Models/LogDataModal";
import { ConfirmationModal } from "../Models/ConfirmationModal";
import { getDateFormatMoment } from "../config/localdata";
import { SuccessfullMesg } from "../Models/SuccessfullMesg";
import { DashboardFilterModal } from "../Models/DashboardFilterModal";
import { useNavigate } from "react-router-dom";
import { TransferStatusModal } from "../Models/TransferStatusModal";

export const TransferStatusHarvest = ({ metaData }) => {
  const size = 10;
  const configFetchRef = React.useRef(false);
  const [totalPage, setTotalPage] = useState();
  const [totalItems, setTotalItems] = useState();
  const [selectedPage, setSelectedPage] = useState(0);
  const [setRowsPerPage, setSetRowsPerPage] = useState(0);
  const [tableDataList, setTableDataList] = useState([]);
  const [allSelectedFor, updateAllSelected] = useState(false);
  const [someSelected, updateSomeSelected] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [visible, setVisible] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [generatePoBody, setGeneratePoBody] = useState(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [submitOodoData, setSubmitOodoData] = useState({});
  const [showSuccessModal, setShowSuccessModal] = useState(false);;

  const [logData, setLogData] = useState();
  const [showLogData, setShowLogData] = useState(false);

  const [selectedProduct, setSelectedProduct] = useState();
  const [selectedFarm, setSelectedFarm] = useState();
  const [selectedMitra, setSelectedMitra] = useState();
  const [filterData, setFilterData] = useState({});
  const [sortValue, setSortValue] = useState({ id: "3", name: "Sort By" });

  const [searchText, setSearchText] = useState("");
  const [action, setAction] = useState("");
  const [searchType, setSearchType] = useState('');

  const location = useNavigate();
  let user = JSON.parse(localStorage.getItem("user_data"));

  const sortType = [
    { id: "1", name: "Latest" },
    { id: "2", name: "Earliest" },
  ];

  const addFilters = () => {
    let product_ids = [];
    let farm_ids = [];
    let mitra_ids = [];

    if (selectedProduct !== undefined) {
      product_ids.push(selectedProduct.id);
    }
    if (selectedFarm !== undefined) {
      farm_ids.push(selectedFarm.id);
    }
    if (selectedMitra !== undefined) {
      mitra_ids.push(selectedMitra.id);
    }
    setFilterData({ product_ids, farm_ids, mitra_ids });
    setSelectedPage(0);
    _fetchProducts(0, null, { product_ids, farm_ids, mitra_ids });
  };

  useEffect(()=>{
    _fetchProducts(selectedPage, null, filterData)
  },[action])

  const resetFilter = () => {
    setShowFilter(false);
    _fetchProducts(selectedPage, null, {});
    setFilterData({});
    setSelectedProduct();
    setSelectedFarm();
    setSelectedMitra();
  };

  const handleSearchSubmit = (action) => {
    setSelectedPage(0);
    setAction(action)
    if (action === "reset") {
      setSearchText("");
      setSearchType("");
      _fetchProducts(0, null, filterData)
    }
    if (searchText.length > 2 && action !== "reset") {
      _fetchProducts(0, null, filterData)
    }
  }

  useEffect(() => {
    if (configFetchRef.current) return;
    configFetchRef.current = true;
    _fetchProducts(selectedPage);
  }, []);

  useEffect(() => {
    _fetchProducts(selectedPage);
  }, [setRowsPerPage]);

  useEffect(() => {
    let sort_order = sortValue?.name === "Earliest" ? "ASC" : "DESC";
    _fetchProducts(selectedPage, sort_order, filterData);
  }, [sortValue]);

  const handleLogdata = (data) => {
    setLogData(data);
    setShowLogData(true);
  };

  useEffect(() => {
    const allSelected =
      tableDataList && tableDataList.length
        ? tableDataList.every((ele) => ele.isSelectedForPO === true)
        : false;
    const allUnSelected =
      allSelected === false
        ? tableDataList && tableDataList.length
          ? tableDataList.every((ele) => ele.isSelectedForPO === false)
          : true
        : false;
    updateAllSelected(allSelected);
    updateSomeSelected(!allUnSelected);
  }, [tableDataList]);

  const _addOodoData = async () => {
    const apiResponse = await _asyncGeneratePO(submitOodoData);
    if (apiResponse === 1) {
      setShowSuccessModal(true);
      setShowConfirmModal(false);
      setSubmitOodoData({});
      setTimeout(() => {
        window.location.reload();
      }, 600);
      return;
    }
    toast.error(apiResponse);
    return;
  };


  async function _fetchProducts(page, sort_order, item) {
    setShowFilter(false);
    let params = {
      size: setRowsPerPage > 10 ? setRowsPerPage : size,
      page: page + 1,
      ...item,
    };
    if(searchType?.search_by_key && searchText){
      params[searchType?.search_by_key] = searchText;
    }
    const res = await _asyncGetAllInternalTransferStatus(params, location, sort_order);
    setTableDataList(res?.displayData);
    setTotalPage(res?.totalPage);
    setTotalItems(res?.totalItems);
  }
  function getStatusById(id) {
    for (let i = 0; i < metaData?.allowedInternalTransferStatus.length; i++) {
        if (metaData?.allowedInternalTransferStatus[i].id == id) {
            return metaData?.allowedInternalTransferStatus[i]?.status_name;
        }
    }
    return null;
}

  return (
    <div className="h-[100%]">
      {showSuccessModal ? (
        <SuccessfullMesg label={`Success submit data to Odoo`} />
      ) : null}

      {showConfirmModal ? (
        <ConfirmationModal
          label={`Are you sure to send this data to generate PO ?`}
          label2="No"
          type="planting"
          customStyles={true}
          hideModal={() => setShowConfirmModal(false)}
          onConfirm={() => {
            setShowConfirmModal(false);
            _addOodoData();
          }}
        />
      ) : null}

      {showLogData ? (
        <LogDataModal
          hideModal={() => {
            setShowLogData(false);
          }}
          logData={logData}
        />
      ) : null}

      {modalData!==null? (
        <TransferStatusModal
          hideModal={() => {
            setModalData(null);
          }}
          data={modalData}
          metaData={metaData}
        />
      ) : null}

      {showFilter ? (
        <DashboardFilterModal
          hideModal={() => setShowFilter(false)}
          resetFilter={resetFilter}
          addFilters={addFilters}
          selectedProduct={selectedProduct}
          selectedFarm={selectedFarm}
          selectedMitra={selectedMitra}
          setSelectedProduct={setSelectedProduct}
          setSelectedFarm={setSelectedFarm}
          setSelectedMitra={setSelectedMitra}
        />
      ) : null}

      <div className="h-[10%]">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className="w-[100%] mt-4">
          <TableSearchComp
              onChange={(text) => setSearchText(text)}
              openFilter={() => setShowFilter(true)}
              sortBy={true}
              sortType={sortType}
              sortValue={sortValue}
              handleSortBy={(item) => setSortValue(item)}
              searchText={searchText}
              handleSearchSubmit={handleSearchSubmit}
              searchTypeValues={metaData?.searchableFeaturesWithOptions?.internal_transfers_list}
              onSearchChangeType={(item) => setSearchType(item)}
              searchType={searchType}
            />
          </div>
        </div>
      </div>
     
      <div className="h-[70%] overflow-auto scrollbar-hide mt-2">
        {tableDataList.map((data, i) => {
          return (
            <div className="flex w-[100%] box-border h-18 w-full mt-2 p-4 border-2 border-slate-10 rounded-xl">
             

              <div className="flex flex-row w-[100%]">
               

                <div className="basis-1/2 ml-5 mt-2">
                  <p className="text-[12px] font-[600]">
                    {data?.odoo_request_name}
                  </p>
                  <p className="text-gray text-[10px] font-[600]">
                    Created at {getDateFormatMoment(data?.schedule_date)}
                  </p>
                  <div className="w-[70%]">
                    <p className="text-[10px] p-0 font-[600] text-black text-center bg-[#fff] border border-[#808080]">
                      {getStatusById(data?.request_state)}
                    </p>
                  </div>
                </div>
              </div>

              <div className="basis-1/2 mt-2 mr-10">
              <p className="text-[16px] font-[800]">{data?.farm_name}</p>
                <p className=" text-[12px] font-[600]">{data?.user_name}</p>
              </div>
              {
                data?.harvests?.map((obj)=>{
                  return(
                    <div className="basis-1/2 mt-2">
                    <p className=" text-gray text-[12px] font-[600]">
                        {obj?.planting_batch_id}
                      </p>
                    
                    </div>
                  )
                })
              }
            
              {
                data?.harvests?.map((obj)=>{
                  return(

              <div className="basis-1/2 mt-2">
              <p className="text-[14px] font-bold">{obj?.product_name}</p>
               
              </div>
                  )
                })
              }
              {
                data?.harvests?.map((obj)=>{
                  return(
                    <div className="basis-1/2 mt-2 mr-10">
                    <p className=" text-[12px] font-[600]">{obj?.production_code}</p>
                  </div>
                  )
                })
              }
            

         <img
         onClick={()=>setModalData(data)}
         className=" py-2 rounded-xl cursor-pointer w-[10px] h-[5vh] mt-[12px]"
         src={ArrowButton} 
         />
            </div>
          );
        })}
      </div>
      <div className="flex justify-end items-center h-[10%] text-sm px-5">
        {totalPage !== undefined ? (
          <TablePaginationComp
            totalPages={totalPage}
            active={selectedPage}
            setRowsPerPage={(r) => {
              setSetRowsPerPage(r);
            }}
            selectedPage={(p) => {
              setSelectedPage(p);
              _fetchProducts(p,null, filterData, searchText, searchType)
            }}
            totalItems={totalItems}
            showPerPageDropdown={true}
          />
        ) : null}
      </div>
    </div>
  );
};
