import React, { useEffect, useState } from "react";
import closeImg from "../images/FarmPipeLine/close.png";
import {
    _apiFetchUsers,
    _asyncGetAllFarms,
  _asyncGetMitras,
} from "../api";


export const SchedulerFilterModal = ({
  hideModal,
  resetFilter,
  addFilters,
  selectedDays,
  selectedStatus,
  onDaysClick,
  onStatusClick

}) => {

  console.log("+++++++++selectedDays+++++++++++",selectedDays)

  return (
    <>
      <div className="flex overflow-auto fixed inset-0 z-50 outline-none focus:outline-none items-center justify-center">
        <div className="relative w-[400px] h-[500px]">
          {/*content*/}
          <div className="h-full border-0 rounded-lg shadow-lg relative flex flex-col w-full outline-none focus:outline-none">
            <div className="relative p-10 overflow-auto pt-3 bg-white rounded-t-lg h-full">
              <img
                className="float-right w-[30px] h-[30px] mt-3"
                src={closeImg}
                alt="closeImg"
                onClick={() => {hideModal()}}
              />
              <p className="font-poppins font-semibold text-[24px] mt-3">
                FILTER
              </p>
              <div class="grid grid-cols-2 gap-8 mt-6">
                <div className=" z-10">
                  <p className="mb-8 font-poppins font-medium ">Days</p>
                  <div className={`w-[136px] h-[28px] ${selectedDays?.includes('monday') ? 'bg-[#9FD7A4]':'bg-[#F4F4F4]'} mt-2 mb-2 p-1`} onClick={()=>onDaysClick('monday')}>
                    <div className="font-normal text-black text-[12px] text-center">
                        Monday
                    </div>
                  </div>

                  <div className={`w-[136px] h-[28px] ${selectedDays?.includes('tuesday') ? 'bg-[#9FD7A4]':'bg-[#F4F4F4]'} mt-2 mb-2 p-1`} onClick={()=>onDaysClick('tuesday')}>
                    <div className="font-normal text-black text-[12px] text-center">
                        Tuesday
                    </div>
                  </div>
                  <div className={`w-[136px] h-[28px] ${selectedDays?.includes('wednesday') ? 'bg-[#9FD7A4]':'bg-[#F4F4F4]'} mt-2 mb-2 p-1`} onClick={()=>onDaysClick('wednesday')}>
                    <div className="font-normal text-black text-[12px] text-center">
                        Wednesday
                    </div>
                  </div>
                  <div className={`w-[136px] h-[28px] ${selectedDays?.includes('thursday') ? 'bg-[#9FD7A4]':'bg-[#F4F4F4]'} mt-2 mb-2 p-1`} onClick={()=>onDaysClick('thursday')}>
                    <div className="font-normal text-black text-[12px] text-center">
                        Thursday
                    </div>
                  </div>
                  <div className={`w-[136px] h-[28px] ${selectedDays?.includes('friday') ? 'bg-[#9FD7A4]':'bg-[#F4F4F4]'} mt-2 mb-2 p-1`} onClick={()=>onDaysClick('friday')}>
                    <div className="font-normal text-black text-[12px] text-center">
                        Friday
                    </div>
                  </div>
                  <div className={`w-[136px] h-[28px] ${selectedDays?.includes('saturday') ? 'bg-[#9FD7A4]':'bg-[#F4F4F4]'} mt-2 mb-2 p-1`} onClick={()=>onDaysClick('saturday')}>
                    <div className="font-normal text-black text-[12px] text-center">
                        Saturday
                    </div>
                  </div>
                  <div className={`w-[136px] h-[28px] ${selectedDays?.includes('sunday') ? 'bg-[#9FD7A4]':'bg-[#F4F4F4]'} mt-2 mb-2 p-1`} onClick={()=>onDaysClick('sunday')}>
                    <div className="font-normal text-black text-[12px] text-center">
                        Sunday
                    </div>
                  </div>
                  
                </div>
                <div>
                  <p className="mb-8 font-poppins font-medium">Status</p>
                  <div className={`w-[136px] h-[28px] ${selectedStatus === 'active' ? 'bg-[#9FD7A4]':'bg-[#F4F4F4]'} mt-2 mb-2 p-1`} onClick={()=>onStatusClick('active')}>
                    <div className="font-normal text-black text-[12px] text-center">
                        Active
                    </div>
                  </div>
                  <div className={`w-[136px] h-[28px]  ${selectedStatus === 'inactive' ? 'bg-[#9FD7A4]':'bg-[#F4F4F4]' } mt-2 mb-2 p-1`} onClick={()=>onStatusClick('inactive')}>
                    <div className="font-normal text-black text-[12px] text-center">
                        Inactive
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex items-center p-6 border-t border-solid border-slate-200 rounded-b-lg bg-white w-full">
              <div className="grid grid-cols-2 gap-2 w-full">
                <div></div>
                <div className="justify-end flex">
                  <button
                    className="border border-green text-green mr-10 px-5 py-1 rounded-full hover:text-white hover:bg-green"
                    type="button"
                    onClick={() => resetFilter()}
                  >
                    RESET
                  </button>
                  <button
                    className="bg-[#3EB049] text-white mr-10 px-5 py-1 rounded-full"
                    type="button"
                    onClick={addFilters}>
                    APPLY
                  </button>
                </div>
              </div>
              <div />
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>{" "}
    </>
  );
};
