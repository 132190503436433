import React, { useEffect, useState } from "react";
import DropDownComp from "../components/DropDownComp";
import { _asyncGetAllFarms } from "../api";
import closeImg from "../images/FarmPipeLine/close.png";
import SearchDropDownComp from "../components/SearchDropDownComp";

export const AssignFarmModal = ({
  hideModal,
  selectedDosing,
  onAssign,
  assignUnassign,
}) => {
  const [selectedItem, setSelectedItem] = useState(selectedDosing);
  const [allFarms, updateAllFarms] = useState([]);
  const [allGreenHouse, updateAllGreenHouse] = useState([]);
  const [selectedFarm, setSelectedFarm] = useState();
  const [selectedGreenHouse, setSelectedGreenHouse] = useState();

  const handleFarmChange = (item) => {
    setSelectedFarm(item);
    let temp = [{ id: "", greenhouse_name: "Select Greenhouse" }];
    if (item?.facilities?.length > 0) {
      updateAllGreenHouse([...temp, ...item?.facilities]);
    } else {
      updateAllGreenHouse([...temp]);
    }
  };

  const handleGreenHouseChange = (item) => {
    setSelectedGreenHouse(item);
  };

  useEffect(() => {
    _getGetAllFarms();
  }, []);

  async function _getGetAllFarms() {
    const data = await _asyncGetAllFarms("&facility_needed=true");
    if (!data) return;
    let temp = [{ id: "", farm_name: "Select Farm" }];
    updateAllFarms([...temp, ...data]);
  }

  return (
    <>
      <div className="flex overflow-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center">
        <div className="w-[35%] relative mt-[3%] mb-[3%]">
          {/*content*/}
          <div className="h-full first-letter:rounded-lg shadow-lg relative flex flex-col w-full outline-none focus:outline-none rounded-lg">
            <div className="flex items-center relative pl-6 pr-6 overflow-auto pt-3 bg-white rounded-t-lg h-full">
              <p className="text-[18px] w-full font-[450px] text-center mb-5 mt-3">
                {assignUnassign ? "Assign" : "Un Assign"}{" "}
                {selectedItem?.identifier}
              </p>
              <img
                className="float-right w-[30px] h-[30px]"
                src={closeImg}
                alt="closeImg"
                onClick={() => {
                  hideModal();
                }}
              />
            </div>
            {/*footer*/}
            <div className="py-6 rounded-b-lg bg-white w-full px-20">
              <DropDownComp
                listData={allFarms}
                label="Farm"
                lableStyle={"text-[20px] text-black mb-2"}
                active={allFarms && allFarms[0]}
                type={"farm_name"}
                onSelected={(item) => {
                  handleFarmChange(item);
                }}
                customStyles={
                  "rounded-md justify-between text-[#3E4347] text-[14px]"
                }
                customMenuStyle={"relative inline-block text-left w-full"}
              />
              <DropDownComp
                listData={allGreenHouse}
                label="Greenhouse"
                lableStyle={"text-[20px] text-black mt-5"}
                active={allGreenHouse && allGreenHouse[0]}
                type={"greenhouse_name"}
                onSelected={(item) => {
                  handleGreenHouseChange(item);
                }}
                customStyles={
                  "rounded-md justify-between text-[#3E4347] text-[14px]"
                }
                customMenuStyle={"relative inline-block text-left w-full"}
              />
              <div className="flex justify-end mt-10">
                <p
                  onClick={() =>
                    onAssign(selectedItem, selectedFarm, selectedGreenHouse)
                  }
                  className="bg-[#3EB049] text-white px-10 py-2 border border-[#3EB049] rounded-full cursor-pointer"
                >
                  {assignUnassign ? "Assign" : "Un Assign"}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>{" "}
    </>
  );
};
