import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { _asyncGetMerchandiserProductDetails, _fetchMetaData } from "../../api";
import { PageLayoutComp } from "../../components/PageLayoutComp";
import { formatDateGetDayOnly, getDateFormatMoment } from "../../config/localdata";
import { ReactComponent as CalenderIcon } from "../../../src/images/ICONS/Date.svg";
import { IoIosArrowRoundBack } from "react-icons/io";
import { ImageSliderModal } from "../../Models/ImageSlider";
import { DatePickerComp } from "../../components/DatePickerComp";


export const ProductDetail = () => {
  
  const location = useNavigate();
  let user = JSON.parse(localStorage.getItem("user_data"));
  const params = useParams();
  const [metaData, setMetaData] = useState();
  const [data, setData] = useState();
  const [showCropReport, setShowCropReport] = useState(false);
  const [images, setImages] = useState([]);
  const [reportDate,setReportDate] = useState(
    (new Date().getMonth() +1) + "/" + new Date().getDate()  + "/" + new Date().getFullYear()
  );

  console.log("asfasdfsadf+++++++++++", data);


  const handleImgClick = (item) => {
    setImages(item);
    setShowCropReport(true);
  };

  async function _fetchInventoryDetails() {
    let data = {
      report_date: reportDate
    };
    const res = await _asyncGetMerchandiserProductDetails(params?.id,data);
    setData(res);
  }

  const handleDetails = () =>{
      location('/merchandise-tracker')
  }
  useEffect(() => {
    _fetchData();
    _fetchInventoryDetails();
  }, [reportDate]);

  useEffect(() => {
    _fetchData();
    _fetchInventoryDetails();
  }, []);

  async function _fetchData() {
    let metaData = await _fetchMetaData();
    setMetaData(metaData);
  }

  return (
    <div className="w-screen h-screen">
      <PageLayoutComp metaData={metaData} user={user}>
      {showCropReport ? (
          <ImageSliderModal
            hideModal={() => {
              setShowCropReport(false);
            }}
            images={images}
          />
        ) : null}
        <div className=" h-[100%] bg-[#E6F1ED] p-4">
          <div className="h-[7%]">
            <div className="flex justify-between">
              <p className="text-3xl font-bold mb-0">
                Merchandise Tracker - Product Detail
              </p>
              <div
                 onClick={handleDetails}
                className="flex cursor-pointer bg-green px-4 rounded-md"
              >
                <IoIosArrowRoundBack
                  size={20}
                  color={"white"}
                  className="mt-2"
                  
                />
                <p className="text-white text-md mt-1.5 cursor-pointer font-bold">
                  Back to List
                </p>
              </div>
            </div>
          </div>
          <div className="relative h-[100%]">
            <div className="h-[calc(99%-6%)] bg-white p-3">
            <div className="bg-white rounded-md  p-5">
            <div className="flex flex-row p-2 justify-between">
            <div className="flex flex-row p-2">
          <div className="ml-4 mt-[-1rem]">
          <img
            src={data?.product_info?.final_product?.image_url}
            className="w-[7rem] h-[7rem] object-fill rounded-md"
          ></img>
        </div>
        <div className="ml-[4rem] ">
          <h1 className="text-[#40AE49] font-medium text-xl">
            {data?.product_info?.final_product?.name}
          </h1>
          <div className="flex gap-3">
            <div className="flex flex-col">
              <p>Stock</p>
              <p>Supermarket</p>
            </div>
            <div className="flex flex-col">
              <p>:</p>
              <p>:</p>
            </div>
            <div className=" flex flex-col justify-start font-bold">
              <p>{data?.product_info?.product_qty}</p>
              <p>{data?.product_info?.super_market?.name}</p>
            </div>
          </div>
        </div>
        </div>
        <div className="">
          <div className="flex bg-[#F9F9F9] flex-row justify-center items-center rounded-md border-2 border-[#808080]  ">
          <DatePickerComp
           onSelected={(e) => {setReportDate(e)}}
           value={reportDate}
           allowFutureDate={false}
          />
          </div>
        </div>
        </div>
       <div className={`max-h-[400px] overflow-auto scrollbar`}>
       {data?.damage_info.length > 0 ? data?.damage_info?.map(item =>{
         return(<div>
          <div className="flex flex-row border-b-2 p-2 border-gray-200">
          </div>
          <div className="p-6 font-semibold">
            <p className="mb-2">Damage Detail</p>
            <p className="font-light">
              {" "}
              <span>{formatDateGetDayOnly(new Date(item?.submitted_at)) +', '+ getDateFormatMoment(item?.submitted_at, true)}</span>      
            </p>
          </div>
          <div className="w-full flex flex-row mt-10">
            <div className="w-[15%] ml-5">
              <p className="font-bold">Damage Amount</p>
              <p className="pr-[10rem] mt-2 text-[#F491A3]">{item?.damage_qty}</p>
            </div>
            <div className="w-[50%] ml-5">
              <p className="font-bold">Notes</p>
              <p className="pr-[10rem] mt-2">
               {item?.notes}
              </p>
            </div>
            <div className="w-[40%]">
              <p className="font-bold">Pictures</p>
              <div className="flex flex-row space-x-5 mt-2 overflow-x-auto">
                {item?.pictures ? (
                  item?.pictures.map((data, index) => {
                    return (
                      index < 4 && (
                        <img
                          key={data?.id}
                          className="mr-3 w-[60px] h-[60px] rounded-[10px]"
                          src={data?.doc_link}
                          onClick={() => {
                            handleImgClick(item?.pictures)
                          }}
                        />
                      )
                    );
                  })
                ) : (
                  <p className="text-[1rem] text-center mr-10 ">
                    No Pictures available
                  </p>
                )}
              </div>
              </div>
            </div>
           </div>)
       }): (<div className="flex font-bold text-xl h-[100%] justify-center items-center">No damage stock details available for selected date.</div>)}
       </div>
      </div>
            </div>
          </div>
        </div>
      </PageLayoutComp>
    </div>
  );
};
