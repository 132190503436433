import React, { Fragment, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import * as ICONS from "../constants/IMAGES_ICONS";

import Accordion from "./Acordion";
import { findIndexByLink, findParentMenu } from "../utils/utils";

export const SideNavComp = () => {
  const location = useLocation();

  const appStates = useSelector((state) => state.appStoredData);

  let user = JSON.parse(localStorage.getItem("user_data"));
  const [activeBarActive, setActiveBarActive] = useState(-1);
  const [sideNavList, setSideNavList] = useState([]);

  const sideMenuTabs = {
    administration: {
      id: 1,
      label: "Administration",
      link: "/farm-pipeline",
      icon: ICONS.ADMIN_USER,
      submenus: [
        {
          id: 1,
          name: "Farms",
          link: "/farm-pipeline/farms",
          icon: ICONS.ADMININSTRATION_FARMS,
          allowedPermissions: [
            "read_farm_list",
            "create_farm",
            "update_farm",
            "delete_farm",
          ],
        },
        {
          id: 2,
          name: "Products",
          link: "/farm-pipeline/products",
          icon: ICONS.ADMININSTRATION_PRODUCTS,
          allowedPermissions: [
            "read_product_list",
          ],
        },
        {
          id: 3,
          name: "Users",
          link: "/farm-pipeline/users",
          icon: ICONS.ADMININSTRATION_USERS,
          allowedPermissions: [
            "read_user_list",
            "create_user",
            "update_user",
            "delete_user",
          ],
        },
        {
          id: 4,
          name: "Archived",
          link: "/farm-pipeline/archived",
          icon: ICONS.ADMININSTRATION_ARCHIVED,
          allowedPermissions: [
            "read_archived_lists",
            "restore_archived_data",
          ],
        },
        {
          id: 5,
          name: "Farm Weekly Report",
          link: "/farm-pipeline/farm-weekly-report",
          icon: ICONS.ADMININSTRATION_FARM_WEEKLY_REPORT,
          allowedPermissions: [
            "read_weekly_report_list"
          ],
        },
        {
          id: 6,
          name: "E-Signature",
          link: "/farm-pipeline/e-sign-dashboard",
          icon: ICONS.ESIGNATURE,
          allowedPermissions: [
            "read_esign_data",
            "update_esign_data"
          ],
        },
        {
          id: 7,
          name: "Career",
          link: "/farm-pipeline/career",
          icon: ICONS.ADMININSTRATION_CAREER,
          allowedPermissions: [
            "read_web_data",
          ],
        },
        {
          id: 8,
          name: "Mitra Enquiry",
          link: "/farm-pipeline/mitra-enquiry",
          icon: ICONS.ADMININSTRATION_CAREER,
          allowedPermissions: [
            "read_web_data"
          ],
        },
        {
          id: 9,
          name: "Contact Enquiry",
          link: "/farm-pipeline/contact-enquiry",
          icon: ICONS.ADMININSTRATION_CAREER,
          allowedPermissions: [
            "read_web_data"
          ],
        },

      ],
      allowedPermissions: [
        "read_farm_list",
        "read_web_data",
        "create_farm",
        "update_farm",
        "delete_farm",
        "toggle_rab",
        "read_product_list",
        "read_user_list",
        "create_user",
        "update_user",
        "delete_user",
        "read_archived_lists",
        "restore_archived_data",
      ],
    },
    planting: {
      id: 2,
      label: "Planting",
      link: "/planting",
      icon: ICONS.PLANTING,
      allowedPermissions: [
        "read_planting_dashboard",
        "read_planting_list",
        "create_planting_data",
        "delete_planting_data",
        "read_harvest_lista",
      ],
      submenus: [
        {
          id: 1,
          name: "Dashboard",
          link: "/planting/dashboard",
          icon: ICONS.PLANTING_DASHBOARD,
          allowedPermissions: [
            "read_planting_dashboard",
          ],
        },
        {
          id: 2,
          name: "Planting List",
          link: "/planting/planting-list",
          icon: ICONS.PLANTING_PLANTING_LIST,
          allowedPermissions: [
            "read_planting_list",
            "create_planting_data",
            "delete_planting_data",
          ],
        },
        {
          id: 3,
          name: "Harvest List",
          link: "/planting/harvest-list",
          icon: ICONS.PLANTING_HARVEST_LIST,
          allowedPermissions: [
            "read_harvest_lista",
          ],
        },
      ],
    },
    iot: {
      id: 3,
      label: "IoT Devices",
      link: "/iot-devices",
      icon: ICONS.DEVICE,
      allowedPermissions: [
        "read_dosing_machine_list",
        "assign_unassign_dm",
        "read_weather_station_list",
        "assign_unassign_ws",
      ],
      submenus: [
        {
          id: 1,
          name: "Dosing Machine",
          link: "/iot-devices/dosing-machine",
          icon: ICONS.IOT_DOSING_MACHINE,
        },
        {
          id: 2,
          name: "Weather Station",
          link: "/iot-devices/weather-station",
          icon: ICONS.IOT_WEATHER_STATION,
        },
      ],
    },
    cropsTracker: {
      id: 4,
      label: "Crops Tracker",
      link: "/crops-tracker",
      icon: ICONS.CROPS_TRACKER,
      allowedPermissions: ["read_crops_tracker_list"],
    },
    draft: {
      id: 5,
      label: "Draft Request",
      link: "/draft-request",
      icon: ICONS.DRAFT,
      allowedPermissions: [
        "read_draft_harvested_list",
        "generate_po",
        "generate_bill",
        "read_po_list",
        "read_bill_list",
        "read_internal_transfer_list",
        "create_internal_transfer",
      ],
      submenus: [
        {
          id: 1,
          name: "Draft(Harvested)",
          link: "/draft-request/draft-harvest-list",
          icon: ICONS.DRAFT_HARVESTED,
          allowedPermissions: [
            "read_draft_harvested_list",
          ],
        },
        {
          id: 2,
          name: "PO",
          link: "/draft-request/po",
          icon: ICONS.DRAFT_REQUEST_PO,
          allowedPermissions: [
            "generate_po",
            "read_po_list",
          ],
        },
        {
          id: 3,
          name: "Bill",
          link: "/draft-request/bill",
          icon: ICONS.DRAFT_REQUEST_BILL,
          allowedPermissions: [
            "generate_bill",
            "read_bill_list",
          ],
        },
        {
          id: 4,
          name: "Internal Transfer",
          label: "Internal Transfer",
          link: "/draft-request/internal-transfer",
          icon: ICONS.TRANSFER,
          allowedPermissions: [
            "read_internal_transfer_list",
            "create_internal_transfer",
          ],
        },
        {
          id: 5,
          name: "Transfer Status",
          label: "Transfer Status",
          link: "/draft-request/transfer-status",
          icon: ICONS.STATUS,
          allowedPermissions: [
            "read_internal_transfer_list",
            "create_internal_transfer",
          ],
        },

      ],
    },
    rab: {
      id: 6,
      label: "Budget Plan RAB",
      link: "/budget-plan-rab",
      icon: ICONS.RAB,
      allowedPermissions: [
        "read_rab_list",
        "create_rab",
        "update_rab",
        "update_rab_rate",
        "approve_rab",
        "revise_rab",
        "read_consumable_request_list",
        "read_consumable_inventory_list",
      ],
      submenus: [
        {
          id: 1,
          name: "Budget Plan - RAB",
          link: "/budget-plan-rab/rab-list",
          icon: ICONS.BUDGET_PLAN_RAB,
          allowedPermissions: [
            "read_rab_list",
            "create_rab",
            "update_rab",
            "update_rab_rate",
            "approve_rab",
            "revise_rab",
          ],
        },
        {
          id: 2,
          name: "Request List",
          link: "/budget-plan-rab/request-list",
          icon: ICONS.BUDGET_REQUEST_LIST,
          allowedPermissions: [
            "read_consumable_request_list",
          ],
        },

        {
          id: 3,
          name: "Inventory",
          link: "/budget-plan-rab/inventory-list",
          icon: ICONS.BUDGET_REQUEST_INVENTORY,
          allowedPermissions: [
            "read_consumable_inventory_list",
          ],
        },
        {
          id: 4,
          name: "BOM Master Data",
          link: "/budget-plan-rab/bom-master-data-list",
          icon: ICONS.BUDGET_REQUEST_INVENTORY,
          allowedPermissions: [
            "read_consumable_inventory_list",
          ],
        },
      ],
    },
    merchandise: {
      id: 7,
      label: "Merchandise Tracker",
      link: "/merchandise-tracker",
      icon: ICONS.MERCHANDISE,
      allowedPermissions: [
        "read_merchandiser_data",
        "update_merchandiser_data"
      ],
      submenus: [
        {
          id: 1,
          name: "Merchandiser Mapping",
          link: "/merchandise-tracker/merchandiser_mapping",
          icon: ICONS.MERCHANDISE_MAPPING,
          allowedPermissions: [
            "read_merchandiser_data",
            "update_merchandiser_data"
          ],
        },
      ],
    },
  };

  useEffect(() => {
    const parentMenuOpenIndex = findParentMenu(location.pathname, sideNavList);
    setActiveBarActive(parentMenuOpenIndex);
  }, [location.pathname, sideNavList]);

  useEffect(() => {
    getUserPermissions();
  }, []);

  const isTabPermissionAllowed = (tabName) => {
    if (!sideMenuTabs[tabName]) {
      return false;
    }
    return sideMenuTabs[tabName].allowedPermissions.some(
      (feature) => !!user.permissions[feature]
    );
  };

  const isSubMenuPermissionAllowed = (allowedPermissions) => {
    return allowedPermissions.some(
      (feature) => !!user.permissions[feature]
    );
  };

  const getUserPermissions = () => {
    const sortedKeys = Object.keys(sideMenuTabs).sort(
      (key1, key2) => sideMenuTabs[key1].id - sideMenuTabs[key2].id
    );

    const tabs = sortedKeys
      .filter((key) => !!isTabPermissionAllowed(key))
      .map((key) => {
        if (sideMenuTabs[key].submenus && sideMenuTabs[key].submenus.length) {
          const subMenu = sideMenuTabs[key].submenus;
          const filtteredMenu = subMenu.filter(sm => {
            if (sm?.allowedPermissions && sm?.allowedPermissions.length > 0) {
              return !!isSubMenuPermissionAllowed(sm.allowedPermissions);
            } else {
              return true;
            }
          });
          sideMenuTabs[key].submenus = filtteredMenu;
        }
        return sideMenuTabs[key];
      });
    if (tabs.length) {
      setSideNavList(tabs);
    }
  };

  const handleAccordionClick = (event, index) => {
    event.stopPropagation();
    setActiveBarActive((val) => (val === index ? -1 : index));
  };

  return (
    <div className={`h-full ${!appStates.navIcon ? "w-[18%]" : "w-[5%]"}`}>
      <div className="h-full py-5 pl-3">
        {sideNavList.map((item, index) => {
          return (
            <Fragment key={index}>
              {item.submenus && item.submenus.length > 0 ? (
                <Accordion
                  isActive={activeBarActive === index}
                  onClick={(event) => handleAccordionClick(event, index)}
                  item={item}
                />
              ) : (
                <Link
                  to={item.link == location.pathname ? "" : item.link}
                  key={item.id}
                >
                  <div
                    className={`flex items-center p-3 ${(location.pathname.substring(0, 14) || location.pathname) === item.link ? "bg-[#E6F1ED]" : ""
                      }`}
                  >
                    <img
                      className={`w-[20px] h-[20px] ${appStates.navIcon ? "mr-5" : ""
                        }`}
                      src={item.icon}
                    />
                    {!appStates.navIcon ? (
                      <p className="ml-2">{item.label}</p>
                    ) : null}
                  </div>
                </Link>
              )}
            </Fragment>
          );
        })}
      </div>
    </div>
  );
};
