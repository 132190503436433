import React, { useEffect, useRef, useState } from "react";
import { PageLayoutComp } from "./PageLayoutComp";
import { BsDot } from "react-icons/bs";
import { Document, Page, pdfjs } from "react-pdf";
import standardImage from "../images/usergdaa.png";
import { useNavigate, useParams } from "react-router-dom";
import {
  _asyncAssignSignaturePosition,
  _asyncGetKycInfo,
  _asyncGetProvidedSignature,
  _asyncGetTaskDetails,
  _asyncGetTaskDocument,
  _asyncGetTaskRecipients,
  _asyncRequestOtp,
  _asyncVerifyOtp,
} from "../api";
import { isEmpty } from "lodash";
import { toast } from "react-toastify";
import { ConfirmationModal } from "../Models/ConfirmationModal";
import { InputOtpModal } from "../Models/InputOtpModal";
import { SuccessfullMesg } from "../Models/SuccessfullMesg";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import useMeasure from "react-use-measure";
import { LoaderModal } from "../Models/LoaderModal";
import * as htmlToImage from "html-to-image";

export function ESignatureReview() {
  let user = JSON.parse(localStorage.getItem("user_data"));
  const [selectedEsign, setSelectedEsign] = useState("standard");
  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [taskDetails, setTaskDetails] = useState();
  const [taskDocument, setTaskDocument] = useState();
  const [file, setFile] = useState();
  const [base64Img, setBase64Img] = useState();
  const [ph, setPh] = useState(user?.contact_number);
  const inputRefs = useRef([]);
  const [inputValues, setInputValues] = useState(Array(6).fill(""));
  const [otp, setotp] = useState("");
  const [showOTPModal, setShowOTPModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const location = useNavigate();
  const [ref, bounds] = useMeasure({ scroll: true, polyfill: ResizeObserver });
  const [positionData, setPositionData] = useState([]);
  const inputRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [addFrameFlag, setAddFrameFlag] = useState(true);

  const [kycInfo, setKycInfo] = useState();

  async function _fetchKycInfo() {
    const Info = await _asyncGetKycInfo();
    setKycInfo(Info);
  }

  const handleInputChange = (index, event) => {
    const newValues = [...inputValues];
    newValues[index] = event.target.value;
    setInputValues(newValues);
    const inputLength = event.target.value.length;
    if (inputLength === 1 && index < inputRefs.current.length - 1) {
      inputRefs.current[index + 1].focus();
    } else if (inputLength === 0 && index > 0) {
      inputRefs.current[index - 1].focus();
    }
    const concatenatedString = newValues.join("");
    setotp(concatenatedString);
  };

  const onConfirm = async () => {
    if (otp.length === 6) {
      setIsLoading(true);
      const response = await _asyncVerifyOtp({ otp });
      setIsLoading(false);
      if (response.status === 201) {
        setShowOTPModal(false);
        setShowSuccessModal(true);
        setTimeout(() => {
          setShowSuccessModal(false);
          location("/farm-pipeline/e-sign-dashboard");
        }, 2000);
      }
    }
  };

  const submitOtpDeatails = async () => {
    let body = {};
    body.task_id = params?.id;
    body.sign_type = selectedEsign;

    if (selectedEsign === "provided") {
      if (isEmpty(file)) {
        toast.error("Please upload signature file.", { autoClose: 2000 });
        return;
      }
      body.sing_image = base64Img;
    }
    await _asyncRequestOtp(body, "sign");
  };

  const params = useParams();

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  useEffect(() => {
    _getTaskDetails(params?.id);
    _getDocument(params?.id);
    _getRecipientList(params?.id);
    _getProvidedSign();
    _fetchKycInfo();
  }, []);

  async function _getTaskDetails(id) {
    const data = await _asyncGetTaskDetails(id);
    if (!data) return;
    setTaskDetails(data);
  }

  async function _getRecipientList(id) {
    const data = await _asyncGetTaskRecipients(id);
    const parsedData = data?.data
      .filter((item) => item.email === user?.email)
      .flatMap((item) => {
        const metadata = JSON.parse(item.metadata);
        return Object.entries(metadata.e_sign_positions).map(
          ([page, position]) => ({
            x: position.x,
            y: position.y,
            height: position.height,
            width: position.width,
            user_name: item.user_name,
            role: item.role,
            page: parseInt(page, 10),
          })
        );
      });
    setPositionData(parsedData);
    console.log("setPositionData", data);
  }

  function base64ToArrayBuffer(base64) {
    var binaryString = atob(base64);
    var bytes = new Uint8Array(binaryString.length);
    for (var i = 0; i < binaryString.length; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
  }

  async function _getDocument(id) {
    const response = await _asyncGetTaskDocument(id);
    const arr = base64ToArrayBuffer(response);
    setTaskDocument(arr);
  }

  async function _getProvidedSign() {
    const response = await _asyncGetProvidedSignature();
    if (response) {
      var buffer = base64ToArrayBuffer(response);
      var imgFile = URL.createObjectURL(
        new Blob([buffer], { type: "image/png" })
      );
      console.log("getProvidedSign", imgFile);
      setFile(imgFile);
      setAddFrameFlag(false);
    }

    setBase64Img(response);
  }

  const handleChange = (value) => {
    setSelectedEsign(value);
  };

  function handleFileChange(e) {
    // console.log(e.target.files);
    setAddFrameFlag(true);
    setFile(URL.createObjectURL(e.target.files[0]));
    convertToBase64(e.target.files[0]);
  }

  const convertToBase64 = (selectedFile) => {
    const reader = new FileReader();

    reader.readAsDataURL(selectedFile);

    reader.onload = () => {
      const data = reader.result.split("data:image/png;base64,");
      //console.log("called: ", data[1]);
      setBase64Img(data[1]);
    };
  };
  console.log("base64", base64Img);

  const createImg = () => {
    var node = document.getElementById("signimg");
    htmlToImage
      .toPng(node)
      .then(function (dataUrl) {
        const base64 = dataUrl.split(",")[1];
        setBase64Img(base64);
      })
      .catch(function (error) {
        console.error("oops, something went wrong!", error);
      });
  };

  return (
    <div>
      <PageLayoutComp user={user}>
        <div className="h-[100%] w-full">
          {showOTPModal ? (
            <InputOtpModal
              inputRefs={inputRefs}
              requestAgain={submitOtpDeatails}
              onConfirm={onConfirm}
              handleInputChange={handleInputChange}
              label4={ph}
              hideModal={() => {
                setShowOTPModal(false);
              }}
            />
          ) : null}
          {isLoading && <LoaderModal />}
          {showSuccessModal ? (
            <SuccessfullMesg
              label={`Successfully performed digital signature.`}
            />
          ) : null}

          <div className="h-10">
            <div className="flex justify-between">
              <p className="text-3xl font-bold mb-0 ml-5">E-Signature-Review</p>
            </div>
          </div>

          <div className="m-4   flex  justify-center rounded-lg overflow-hidden">
            <div className="pdf-viewer w-[80%]  bg-[#f9f9f9]  h-[100%] overflow-hidden">
              <div className="w-full flex gap-5 pt-2 pl-4 pb-4 items-center bg-white">
                <p className="">{taskDetails?.doc_name}</p>
                <div className="flex  items-center text-[#808080]">
                  <BsDot />
                  <p>{taskDetails?.total_pages} Pages</p>
                </div>
              </div>

              <div className="document rounded-xl w-[50%] ml-[25%] mr-[25%] my-0 items-center justify-center">
                <div className="reference" ref={inputRef}>
                  <div className="reference" id="refid" ref={ref}>
                    {positionData.map((res) => {
                      let isShowing = "hidden";
                      if (res.page === pageNumber) {
                        isShowing = "visible";
                      }
                      return (
                        <div
                          className="border-2 z-10 flex flex-col items-center relative justify-center border-green p-1 bg-[#9FD7A4] text-sm"
                          style={{
                            height: res.height,
                            width: res.width,
                            visibility: isShowing,
                            left: res.x + bounds.left + window.scrollX + "px",
                            top:
                              bounds.bottom -
                              res.y +
                              window.scrollY -
                              47.2 +
                              "px",
                            zIndex: 10,
                            position: "absolute",
                          }}
                        >
                          <p className="text-xs">{res.user_name}</p>
                          <p className="text-[0.6rem]">({res.role})</p>
                        </div>
                      );
                    })}
                    {/* 
                    <div className="text-xs mt-2 ml-1" style={
                      {  zIndex: 10,
                        position: "absolute",}
                    }>
                      <p>BeleafOS_Signature_{params?.id}</p>
                    </div> */}
                    <Document
                      file={taskDocument}
                      onLoadSuccess={onDocumentLoadSuccess}
                    >
                      <Page
                        size={"A4"}
                        pageNumber={pageNumber}
                        renderAnnotationLayer={false}
                        renderTextLayer={false}
                      />
                    </Document>
                  </div>
                </div>
              </div>
              <div className=" mt-2 p-3 flex items-center justify-center">
                <div className="">
                  <nav aria-label="Page navigation example ">
                    <ul class="flex items-center -space-x-px h-8 text-sm">
                      <li>
                        <button
                          onClick={() => {
                            if (pageNumber > 1) {
                              setPageNumber((prev) => --prev);
                            }
                          }}
                          class="flex items-center justify-center px-3 h-8 ms-0 leading-tight text-gray-500 bg-[#E6F1ED] border border-e-0 border-gray-300 rounded-s-lg hover:bg-gray-100  "
                        >
                          <span class="sr-only">Previous</span>
                          <svg
                            class="w-2.5 h-2.5 rtl:rotate-180"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 6 10"
                          >
                            <path
                              stroke="currentColor"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M5 1 1 5l4 4"
                            />
                          </svg>
                        </button>
                      </li>

                      <li>
                        <p
                          aria-current="page"
                          class="z-10 flex items-center justify-center p-2 h-8 leading-tight text-white border border-green-300 bg-green "
                        >
                          {pageNumber}
                          <span className=" ml-2 mr-2"> of </span> {numPages}
                        </p>
                      </li>

                      <li>
                        <button
                          onClick={() => {
                            if (pageNumber < numPages) {
                              setPageNumber((prev) => prev + 1);
                            }
                          }}
                          class="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-[#E6F1ED] border border-gray-300 rounded-e-lg"
                        >
                          <span class="sr-only">Next</span>
                          <svg
                            class="w-2.5 h-2.5 rtl:rotate-180"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 6 10"
                          >
                            <path
                              stroke="currentColor"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="m1 9 4-4-4-4"
                            />
                          </svg>
                        </button>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
            <div className=" side-panel w-[23%]  bg-white  flex flex-col justify-between">
              <div>
                <p className="font-semibold p-2 pb-4">Digital Signature</p>
                {/* <div className="w-full p-2 bg-[#F1F1F1]">Review Sign</div> */}
                <div className="signature-box  border-b-2 border-[#D0D0D0]">
                  {/* <div className=" p-5 pl-2 w-full">
                    <p className="bg-green p-3 w-12 text-center flex text-white  rounded-lg justify-center items-center">
                      1
                    </p>
                  </div> */}
                  <div className="w-full p-2 bg-[#F1F1F1]">e-Sign type</div>
                  <div className="flex p-3 gap-3">
                    <div className="flex  items-center h-5">
                      <input
                        id="helper-radio"
                        aria-describedby="helper-radio-text"
                        type="radio"
                        value="standard"
                        checked={selectedEsign === "standard"}
                        onChange={() => handleChange("standard")}
                        className="w-4 h-4 text-green bg-green-100 checked:bg-green-500 border-green-300 focus:ring-lime-500   "
                      />
                    </div>
                    <div className="ms-2 text-sm">
                      <label
                        htmlFor="helper-radio"
                        className="font-medium text-gray-900"
                      >
                        Standard
                      </label>
                      <p
                        id="helper-radio-text"
                        className="text-xs font-normal text-gray-500"
                      >
                        {selectedEsign === "standard" && (
                          <img src={standardImage}></img>
                        )}
                      </p>
                    </div>
                  </div>
                  <div className="flex p-3 gap-3 ">
                    <div className="flex  items-center h-5 ">
                      <input
                        id="helper-radio"
                        aria-describedby="helper-radio-text"
                        type="radio"
                        value="provided"
                        checked={selectedEsign === "provided"}
                        onChange={() => handleChange("provided")}
                        className="w-4 h-4 text-green bg-green-100 checked:bg-green-500 border-green-300 focus:ring-lime-500   "
                      />
                    </div>
                    <div className="ms-2 text-sm ">
                      <label
                        htmlFor="helper-radio"
                        className="font-medium text-gray-900"
                      >
                        Provided
                      </label>
                    </div>
                  </div>
                  {selectedEsign === "provided" && (
                    <div className="flex flex-col items-center justify-center">
                      <p
                        id="helper-radio-text"
                        className="text-xs font-normal text-gray-500"
                      >
                        <input
                          className=" bg-slate-200 rounded-lg my-2   file:rounded-l-lg  w-[90%] mx-auto  block  text-sm text-slate-500  file:py-3 file:px-4  file:border-0 file:text-xs  file:bg-green100  file:text-white hover:file:bg-green100   "
                          id="file_input"
                          type="file"
                          accept="image/png"
                          onChange={(e) => {
                            handleFileChange(e);
                            setTimeout(() => {
                              createImg();
                            }, 1000);
                          }}
                          name="ktp_id_card"
                        />
                      </p>
                      {addFrameFlag && file ? (
                        <div className=" ml-2">
                          <div
                            id="signimg"
                            className="flex flex-col items-center justify-center gap-2 rounded-md border-2 border-black w-[150px]"
                          >
                            <p className="text-black mt-1 text-xs font-bold">
                              E-Sign by Vida
                            </p>
                            <img src={file}></img>
                            <p className="text-xs">
                              {kycInfo?.data?.id.split("-").pop()}
                            </p>
                          </div>
                        </div>
                      ) : (
                        <img src={file}></img>
                      )}

                      <p className="ml-4  mt-2 text-xs font-poppins font-thin">
                        *PNG File, Max. 10MB
                      </p>
                    </div>
                  )}
                </div>
              </div>
              <div className="">
                <p className="text-xs w-[90%] font-poppins font-light  mx-auto mb-[20%]">
                  Click Sign to perform digital sign. OTP will send to your
                  mobile number or email. Make sure you input correct OTP.
                </p>
                <p
                  onClick={() => {
                    setShowOTPModal(true);
                    submitOtpDeatails();
                  }}
                  className="text-sm flex bg-green100 items-center mb-5 text-white p-2 m-2 justify-center rounded-md cursor-pointer"
                >
                  Sign
                </p>
              </div>
            </div>
          </div>
        </div>
      </PageLayoutComp>
    </div>
  );
}
