import React, { useEffect, useState } from "react";
import {_asyncGetWarehouses } from "../api";
import DropDownComp from "../components/DropDownComp";
import closeImg from "../images/FarmPipeLine/close.png";

export const EditRateModal = ({
  hideModal,
  setRate,
  submitRate
}) => {

    const [rateArr, setRateArr] = useState([
        {name:"2.15%"},
        {name:"3.40%"},
        {name:"4.00%"},
        {name:"6.50%"},
        {name:"5.85%"},
        {name:"9.60%"},
        {name:"7.45%"},
        {name:"12.45%"},
        {name:"8.80%"},
        {name:"15.05%"},
        {name:"11.15%"},
        {name:"18.65%"},
    ])

  return (
    <>
      <div className="flex overflow-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center">
        <div className="w-[25%] h-[22%] relative mt-[1%]">
          {/*content*/}
          <div className="h-full first-letter:rounded-lg shadow-lg relative flex flex-col w-full outline-none focus:outline-none rounded-lg">
          <div className="relative p-10 overflow-auto pt-3 bg-white rounded-t-lg h-full">
              <img
                className="float-right w-[30px] h-[30px] mt-0"
                src={closeImg}
                alt="closeImg"
                onClick={() => {
                  hideModal();
                }}
              />
              <div className="mt-6">
                 <p className="text-[15px] font-bold text-black">Edit Rate</p>
                </div>
                <div className="mt-0">
                <DropDownComp
                listData={rateArr}
                active={rateArr[0]}
                onSelected={(item) => {setRate(item)}}
                type={"name"}
                customStyles={"rounded-md justify-between text-[#3E4347] text-[14px]"}
                customMenuStyle={"relative inline-block text-left w-full"}
                />
                </div>
                
            </div>
            {/*footer*/}
            <div className="py-2 rounded-b-lg bg-white w-full px-2">
              <div className="flex justify-end">
                <p
                  onClick={() => submitRate()}
                  className="bg-[#40AE49] text-white px-6 py-1 rounded-full cursor-pointer">
                  Confirm
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>{" "}
    </>
  );
};
